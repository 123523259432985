import { memo, useCallback, useMemo, useState } from "react";
import { Box, ButtonGroup, CircularProgress } from "@mui/material";
import { ConnectedReduxModuleProps } from "core";
import { AlertBox } from "elementTypes/common/AlertBox";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { UploadZone } from "elementTypes/common/UploadZone";
import { ASTERISK_SYMBOL } from "elementTypes/common/utils";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { ReduxModule } from "./reduxModule";

import { useMultipleFileInputTranslation } from "./translation";
import { MultipleFileDialogType, MultipleFileInput } from "./types";

const StorageMultipleFileInput = memo<
  ConnectedReduxModuleProps<ReduxModule, MultipleFileInput>
>(
  ({
    required,
    loading,
    element: {
      i18n,
      config: { accept },
    },
    metadata,
    metadataError,
    errors,
    upload,
    clear,
    clearAll,
    fetchMetadata,
    disabled,
  }) => {
    const { reload, close, uploadTitle, clearAllLabel, filesLabel } =
      useMultipleFileInputTranslation();
    const [modal, setModal] = useState<MultipleFileDialogType | null>(null);

    const closeModal = () => setModal(null);

    const openUploadModal = () => setModal(MultipleFileDialogType.UPLOAD);

    const handleSubmit = (successFiles: File[] | null) => {
      upload(successFiles);
      fetchMetadata();
    };

    const handleDeleteFile = useCallback(
      (fileName: string) => {
        clear(fileName);
        fetchMetadata();
      },
      [clear, fetchMetadata],
    );

    const filesCountLabel = useMemo(
      () => `${metadata?.length} ${filesLabel}`,
      [filesLabel, metadata],
    );

    const commonDialogProps = {
      open: Boolean(modal),
      handleClose: closeModal,
      submitTitle: undefined,
      fullWidth: true,
      keepMounted: false,
    };

    const dialogProps = {
      [MultipleFileDialogType.UPLOAD]: {
        ...commonDialogProps,
        title: (
          <Box display="flex" justifyContent="space-between" width="100%">
            {uploadTitle}{" "}
            <IconButton
              tooltip={reload}
              icon="refresh"
              onClick={fetchMetadata}
            />
          </Box>
        ),
        cancelTitle: close,
        maxWidth: "md" as const,
        children: (
          <>
            <UploadZone
              multiple={true}
              value={metadata}
              onSubmit={handleSubmit}
              onDelete={handleDeleteFile}
              onUpdate={fetchMetadata}
              accept={accept?.length ? { [accept as string]: [] } : {}}
              disabled={loading}
            />
            {errors && <AlertBox message={String(errors)} />}
          </>
        ),
      },
    };

    const deleteButton =
      !required &&
      (metadata ? (
        <Button tooltip={clearAllLabel} onClick={clearAll} style={{ flex: 0 }}>
          <MuiIcon icon="delete_outline" />
        </Button>
      ) : (
        <Button onClick={clearAll} style={{ flex: 0 }}>
          <MuiIcon icon="delete_outline" color="disabled" />
        </Button>
      ));

    const linkOrError = metadataError && (
      <Button
        tooltip={reload}
        color="error"
        label={metadataError}
        onClick={fetchMetadata}
      />
    );

    return (
      <>
        <ButtonGroup disabled={disabled} fullWidth>
          <Button
            disabled={disabled}
            tooltip={uploadTitle}
            startIcon={
              loading ? (
                <CircularProgress size="1em" />
              ) : (
                <MuiIcon icon={metadata ? "check" : "cloud_upload"} />
              )
            }
            style={{ flex: linkOrError ? 0 : 1 }}
            onClick={openUploadModal}
            color={errors ? "error" : "primary"}
          >
            {i18n.label}
            {metadata ? filesCountLabel : null}
            {required && ASTERISK_SYMBOL}
          </Button>
          {linkOrError}
          {deleteButton}
        </ButtonGroup>
        {modal && <DialogWrapper {...dialogProps[modal]} />}
      </>
    );
  },
);

StorageMultipleFileInput.displayName = "StorageMultipleFileInput";

export default StorageMultipleFileInput;
