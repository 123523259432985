import { ComponentType } from "react";
import { PropTypes } from "@mui/material";
import { ThemeOptions } from "@mui/material/styles";
import * as t from "io-ts";
import { JSONSchema6 } from "json-schema";
import { Key } from "path-to-regexp";

import { types } from "../runtime-typing";

import { IElementModel, TElementModelWithPosition } from "./element";
import { Translation } from "./i18n";

export type LayoutDefinition = Partial<{
  cookieBanner?: {
    active: boolean;
    message?: string;
    buttonText?: string;
  };

  footer?: {
    text?: string;
  };

  headerButtons?: {
    color?: PropTypes.Color;
  };

  /**
   * which logo to display (and where it should go on click)
   * if no logo path is set, uses the standard cypex svg
   */
  logo?: {
    path?: string;
    url?: string;
    fileName?: string;
  };

  menu?: {
    hidden?: boolean;
    defaultClosed?: boolean;
    width?: string;
  };

  /**
   * the material-ui theme options to use
   */
  theme: ThemeOptions;

  toolbar?: {
    variant?: "dense" | "regular";
  };

  /**
   * custom CSS styles that should be injected into the app at runtime
   */
  customStyles?: string;
}>;

export interface ILayout {
  name: string;
  definition: LayoutDefinition;
}

export type I18n = {
  i18n: {
    label: string;
    icon?: string;
  };
};
export type ITranslatedI18n<Keys extends string> = {
  i18n: Translation<Keys>;
};

export interface IMenuItem extends ITranslatedI18n<"label" | "icon"> {
  id: string;
  menu: IMenuItem[];
  externalLink: string | null;
  pageId: string | null;
  order: number;
  submenuType?: "divider" | "collapsible";
}

export type ITranslatedMenuItem = Omit<IMenuItem, keyof ITranslatedI18n<any>> &
  I18n;

export interface IPageParam {
  name: string;
  type?: string;
}

export type IPageParams = Record<string, IPageParam>;

export interface IPage extends ITranslatedI18n<"label"> {
  id: string;
  default: boolean;
  url: string;
  params: IPageParams;
  element: IElementModel | TElementModelWithPosition;
  /**
   * this expression must not prefixed with the expression prefix
   * @example
   * null
   * 5
   * location.queries.identifier
   */
  identifierLabelExpression?: string;
}

export type QueryToPagesMappingValue = {
  overview?: string;
  edit?: string;
  details?: string;
  create?: string;
};

export type Definition = {
  i18n: Translation<"label" | "description">;
  layout: ILayout;
  menu: IMenuItem[];
  pages: Record<string, IPage>;
  queryToPagesMapping?: Record<string, QueryToPagesMappingValue>;
  parserVersion: string;
};

/**
 * TODO:
 * Make this type io-ts, move it to the api service and check the app metadata upon loading.
 */
export type IAppMetadata = {
  ui: IUiDetail;
  release: {
    id: string;
    name: string;
    createdAt: Date;
    definition: Definition;
    description?: string;
    createdBy?: { id: string };
  };
};

export type IUiDetail = {
  id: string;
  name: string;
  role: string;
  i18n: Translation<"label" | "description">;
};

export interface IPublishedRelease {
  id: string;
  versionName: string;
}

export type IUi = IUiDetail & {
  i18n: Translation<"label" | "description">;
  publishedRelease: IPublishedRelease;
  lastUpdated: Date;
  // the user who created this version
  lastUpdatedBy?: { id: string };
};

export interface IAdminUi extends IUiDetail {
  i18nPublished: Translation<"label" | "description">;
  i18nLatest: Translation<"label" | "description">;
  publishedRelease: IPublishedRelease;
  lastUpdated: Date;
  // the user who created this version
  lastUpdatedBy?: { id: string };
}

export interface IUiReleaseOverview {
  id: string;
  name: string;
  createdAt: Date;
  published: boolean;
  savePoint: {
    id: string;
  };
  description?: string;
  createdBy?: { id: string };
}

export interface IUiSavePoint {
  id: string;
  createdAt: Date;
  i18n: Translation<"label">;
  description: string;
  createdBy?: { id: string };
}

export type LayoutProps<T = Record<string, unknown>> = {
  appMetadata: IAppMetadata;
} & T;

export type ILayoutComponent<T = Record<string, unknown>> = ComponentType<
  LayoutProps<T>
>;

export type ILayouts<T = Record<string, unknown>> = {
  [name: string]: ILayoutComponent<T>;
};

export type TCompiledRoute = [RegExp, Key[], string, string];
export type TStaticCompiledRoute = {
  pattern: RegExp;
  keys: Key[];
  id: string;
  auth: boolean;
  isAdmin: boolean;
};

export interface IApiError {
  hint: string | null;
  details: string;
  code: string;
  message: string;
}

export const IApiError = types.interface({
  hint: types.nullable(types.string()),
  details: types.nullable(types.string()),
  code: types.string(),
  message: types.string(),
});

export const GeneralTypes = t.keyof({
  text: null,
  number: null,
  dateTime: null,
  date: null,
  time: null,
  json: null,
  boolean: null,
  fallback: null,
  geo: null,
});

export type GeneralTypes = t.TypeOf<typeof GeneralTypes>;

export const GeneralType = t.type({
  type: GeneralTypes,
  isArray: t.boolean,
});

export type GeneralType = t.TypeOf<typeof GeneralType>;

export interface IObjectViewField extends ITranslatedI18n<"title"> {
  name: string;
  type: string;
  nullable: boolean;
  generalType: GeneralType;
}

export interface IObjectView extends ITranslatedI18n<"title"> {
  id: string;
  name: string;
  object: {
    name: string;
    stateField?: string;
    module: {
      name: string;
    } & ITranslatedI18n<"title">;
  } & ITranslatedI18n<"title">;
  fields: IObjectViewField[];
  identifyingField?: IObjectViewField;
  identifyingLabel?: IObjectViewField;
  jsonSchema?: JSONSchema6;
  code?: string;
}

// Common type for react-window FixedList
// used  through the whole app to display items as a virtualized list
export type IFixedRow<IFixedItem> = {
  data: IFixedItem[];
  style: any;
  index: number;
};
