import { EditorLayout } from "core/editor/EditorLayout/container";
import { AdminLayout } from "staticPages/admin/components/AdminLayout";
import { StaticPagesLayout } from "staticPages/common/Layout";

import ChildrenComponent from "./ChildrenComponent";
import { SessionLayoutProps } from "./types";

export const determineContent = (props: SessionLayoutProps) => {
  const {
    appMetadata,
    children,
    isStaticPage,
    isAdminPage,
    isLoggedIn,
    isAdmin,
    isEditModeOn,
  } = props;

  if (isStaticPage) {
    if (isAdminPage) {
      return <AdminLayout>{children}</AdminLayout>;
    } else if (isLoggedIn) {
      return appMetadata ? (
        <ChildrenComponent appMetadata={appMetadata}>
          {children}
        </ChildrenComponent>
      ) : (
        <StaticPagesLayout>{children}</StaticPagesLayout>
      );
    }
    return children;
  } else if (appMetadata) {
    const component = (
      <ChildrenComponent appMetadata={appMetadata}>
        {children}
      </ChildrenComponent>
    );
    // in current version only isAdmin has access to EditorLayout
    return isAdmin && isEditModeOn ? (
      <EditorLayout>{component}</EditorLayout>
    ) : (
      component
    );
  }
  return children; // Default case if none of the conditions match
};
