import { ChangeEvent, ComponentProps, memo, useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import isBoolean from "lodash/isBoolean";
import { buildCustomExpressionValue, getBooleanExpressionValue } from "core";
import {
  IconAutocomplete,
  Section,
  useElementEditorContext,
} from "core/editor";

import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import { IconNameType } from "elementTypes/common/MuiIcon";
import { UntransformedClipboardCopyButtonConfig } from "../../types";
import { useClipboardCopyButtonEditorTranslation } from "../translation";

export const DisplayComponent = memo(() => {
  const { displaySectionTitle, disabledLabel } =
    useClipboardCopyButtonEditorTranslation();
  const {
    elementModel: {
      config,
      config: { icon, disabled },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedClipboardCopyButtonConfig>();

  const changeIcon = (
    newIcon: UntransformedClipboardCopyButtonConfig["icon"],
  ) => changeConfigValue("icon", newIcon);

  const wrappedChangePath = useCallback(
    (newValue: string) => {
      changeConfigValue("disabled", newValue);
    },
    [changeConfigValue],
  );

  const handleToggleMode = (isExpression: boolean) => {
    const nextVal = isExpression
      ? getBooleanExpressionValue(String(disabled))
      : buildCustomExpressionValue(String(disabled));
    changeConfigValue("disabled", nextVal);
  };

  const disabledNonExpressionEditor: ComponentProps<
    typeof CustomExpressionEditor
  >["nonExpressionEditor"] = useCallback(
    ({ value, onChange }: NonExpressionEditorProps<boolean>) => {
      const handleChange = (
        _event: ChangeEvent<HTMLInputElement>,
        checked: boolean,
      ) => onChange(checked);

      return (
        <FormControlLabel
          control={
            <Switch
              checked={isBoolean(value) ? value : value === "true"}
              onChange={handleChange}
            />
          }
          label={disabledLabel}
        />
      );
    },
    [disabledLabel],
  );

  return (
    <Section title={displaySectionTitle} wrapped={true}>
      <IconAutocomplete
        label="Icon"
        value={icon as IconNameType}
        onChange={changeIcon}
      />
      <CustomExpressionEditor
        value={String(disabled)}
        config={config}
        label={disabledLabel}
        onChange={wrappedChangePath}
        onToggleMode={handleToggleMode}
        nonExpressionEditor={disabledNonExpressionEditor}
      />
    </Section>
  );
});
