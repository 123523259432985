import { ConnectedProps, connect } from "react-redux";
import { actions as sessionActions } from "core/session/reduxModule/actions";
import { selectors as sessionSelectors } from "core/session/reduxModule/selectors";
import { buildMapStateToProps } from "core/utils/react-redux";

import Component from "./component";

const mapStateToProps = buildMapStateToProps({
  uiOptions: sessionSelectors.uiOptions,
});

const connector = connect(mapStateToProps, sessionActions);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Component);
