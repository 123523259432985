import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
