import { Suspense, lazy, useCallback, useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClientProvider } from "@tanstack/react-query";
import { enUS } from "date-fns/locale";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { SnackbarProvider } from "common/notistack";
import { ElementTypesProvider } from "core/context/ElementTypesContext";

import { Notifier, buildStore, createRouter } from "./core";
import { SessionProvider } from "./core/session";
import { Layout } from "./core/session/Layout";
import {
  availableElementTypes,
  elementTypes,
  getElementType,
} from "./elementTypes";
import { LoadingComponent } from "./layouts/common/Loading";
import ThemeContainer from "./layouts/common/ThemeContainer";
import { queryClient } from "./queryClient";
import { buildServices } from "./services";
import { routes as staticRoutes } from "./staticPages/routes";

import "typeface-roboto";

const RouterError = lazy(() =>
  import("./layouts/common/RouterError").then((module) => ({
    default: module.RouterError,
  })),
);
const RouterLoading = lazy(() =>
  import("./layouts/common/RouterLoading").then((module) => ({
    default: module.RouterLoading,
  })),
);
const RouterNotFound = lazy(() =>
  import("./layouts/common/RouterNotFound").then((module) => ({
    default: module.RouterNotFound,
  })),
);

type CypexLocale = Locale | typeof import("date-fns/locale");

const services = buildServices();
const { Router, service: routerService } = createRouter(staticRoutes);

const store = buildStore(getElementType, routerService, {
  services,
});
const locale = navigator.language;

const App = () => {
  const [currentLocale, setLocale] = useState<CypexLocale>(enUS);

  const fetchLocale = async (loc: string) => {
    try {
      const dynamicLocale = await import("date-fns/locale");
      return dynamicLocale[loc as keyof typeof dynamicLocale];
    } catch (error) {
      return null;
    }
  };

  const updateLocale = useCallback(async () => {
    try {
      for (const language of navigator.languages) {
        const existingLocale = await fetchLocale(language);
        if (existingLocale) {
          setLocale(existingLocale);
          break;
        }
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    updateLocale();
  }, [updateLocale]);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <SessionProvider>
          <IntlProvider locale={locale}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={currentLocale}
            >
              <ThemeContainer>
                <CssBaseline enableColorScheme />
                <SnackbarProvider>
                  <Suspense fallback={<Fallback />}>
                    <ElementTypesProvider
                      value={{
                        getElementType,
                        elementTypes,
                        availableElementTypes,
                      }}
                    >
                      <Layout>
                        <Router
                          errorComponent={RouterError}
                          notFoundComponent={RouterNotFound}
                          loadingComponent={RouterLoading}
                        />
                      </Layout>
                    </ElementTypesProvider>
                    <Notifier />
                  </Suspense>
                </SnackbarProvider>
              </ThemeContainer>
            </LocalizationProvider>
          </IntlProvider>
        </SessionProvider>
      </Provider>
    </QueryClientProvider>
  );
};

const Fallback = () => (
  <div
    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
  >
    <LoadingComponent />
  </div>
);

export default App;
