import { memo } from "react";
import { Box, FormLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import { Tip } from "common/elements/Tip";

import { RenderProps } from "elementTypes/common/HookForm";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";
import { useRoles } from "queries/admin";
import { getRoleOptions } from "queries/admin/utils";
import { Autocomplete } from "../../../../../elementTypes/common/Autocomplete";

type PermissionProps = {
  acl?: string[];
  name?: string;
};

// Component must be used inside HookForm ONLY
// TODO: update translation
export const PermissionAutocomplete = memo<PermissionProps>(
  ({ acl = [], name }) => {
    const fieldName = (name ?? "acl") as never;
    const { control } = useHookFormContext();

    const { data: roleOptions, isInitialLoading } = useRoles({
      select: getRoleOptions,
    });

    const renderComponent = ({
      field: { ref, ...inputField },
    }: RenderProps) => {
      return (
        <Autocomplete
          isMulti={true}
          options={roleOptions}
          disabled={!roleOptions || isInitialLoading}
          label={"Roles"}
          isLoading={false}
          innerRef={ref}
          isClearable
          {...inputField}
        />
      );
    };

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        gap={1}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          gap={1}
          alignItems={"center"}
        >
          <FormLabel component="div">{"Permission Settings"}</FormLabel>
          <Tip
            title=""
            text="Select one or more roles that are allowed to perform this action. Leave empty to allow access to all roles."
          />
        </Box>
        <Controller
          render={renderComponent}
          control={control}
          name={fieldName}
          defaultValue={acl as any}
        />
      </Box>
    );
  },
);
