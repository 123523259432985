import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";

export function buildActions(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
) {
  const types = {};

  const changeValue = (value: object) =>
    dataSource.changeFieldValue(fieldPath, value);

  const actions = {
    changeValue,
  };

  return {
    actions,
    types,
  };
}

export type Actions = ReturnType<typeof buildActions>;
