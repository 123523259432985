import { memo, useMemo } from "react";
import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { DEFAULT_LANGUAGE_CODE, Translation } from "core";
import { useRouterParamByName } from "core/router/reduxModule/utils";
import { useSessionContext } from "core/session";
import { getTranslatedText } from "core/utils/element-utils";
import { LoadingComponent } from "layouts/common/Loading";
import {
  QueryKeys,
  useCreateCustomQuery,
  useEditCustomQuery,
  useGeneratedQuery,
  useQueryGroups,
} from "queries/admin";
import { getApiError } from "queries/utils";
import { RoutePaths } from "staticPages/routes";
import { useQueryGroupsList, useRoute } from "utils/hooks";
import { useSnackbar } from "utils/hooks/useSnackbar";
import { DatabasePanelType } from "../components/types.ts";
import { usePermissionContext } from "../context/databasePanelPermission/PermissionContext.utils.ts";

import { useERDTranslation } from "../erd/translation";

import { CustomQueryForm } from "./customQueryForm";
import { CustomQueryResponse, UICustomQueryForm } from "./types";

export const CustomQuery = memo(() => {
  const translation = useERDTranslation();

  const { permissions, setPermissions } = usePermissionContext();
  const { language } = useSessionContext();

  const viewName = useRouterParamByName("queryName", "");
  const route = useRoute();
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();

  const showError = (error: unknown) => {
    const msg = getApiError(error);
    showSnackbar(msg, "error");
  };

  const handleProcessSuccess = (msg: string) => {
    showSnackbar(msg, "success");
    queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchQueries] });
    queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchQueryGroups] });

    route("push", RoutePaths.Database, { dbPanel: DatabasePanelType.queries });
  };

  const { data: queryGroups } = useQueryGroups();

  const { findQueryGroupByKey } = useQueryGroupsList({
    queryGroups: queryGroups ?? [],
  });

  const customQueryData = useGeneratedQuery(
    { viewName },
    {
      enabled: !!viewName,
      onSuccess: (data: CustomQueryResponse) => {
        const nextPermissions = data.permissions.reduce(
          (res, permission) => ({
            ...res,
            [permission.grantee]: permission.privileges,
          }),
          {} as Record<string, string[]>,
        );
        setPermissions(nextPermissions);
      },
    },
  );

  const createQuery = useCreateCustomQuery({
    onSuccess: () => {
      handleProcessSuccess(translation.createQuerySuccessMsg);
    },
    onError: showError,
    mutationKey: ["CreateCustomQuery"],
  });

  const editQuery = useEditCustomQuery({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.fetchGeneratedQuery],
      });
      handleProcessSuccess(translation.editQuerySuccessMsg);
    },
    onError: showError,
    mutationKey: ["EditCustomQuery"],
  });

  const queryId = Number(customQueryData?.data?.id);

  const query = useMemo<UICustomQueryForm | undefined>(() => {
    return customQueryData.data
      ? {
          name: customQueryData.data.viewName,
          title: getTranslatedText(
            language,
            customQueryData.data.i18n,
            "title",
          ) as string,
          code: customQueryData.data.code,
          identifyingColumn: customQueryData.data?.identifyingColumn,
          queryGroupId: findQueryGroupByKey("queries", queryId)?.id ?? null,
        }
      : undefined;
  }, [customQueryData.data, language, findQueryGroupByKey, queryId]);

  const handleSave = async (dataWithWrongType: Record<string, unknown>) => {
    const data = dataWithWrongType as {
      name: string;
      title: string;
      code: string;
      identifyingColumn?: string;
      queryGroupId: number | null;
    };

    const title = data.title.trim();

    const i18n = {
      [DEFAULT_LANGUAGE_CODE]: {
        title,
      },
      [language.code]: {
        title,
      },
    } as Translation<"title">;

    const { queryGroupId } = data;

    if (!customQueryData.data) {
      createQuery.mutate({
        viewName: data.name,
        code: data.code,
        permissions: Object.entries(permissions).map(
          ([grantee, privileges]) => ({
            grantee,
            privileges,
          }),
        ),
        identifyingColumn: data.identifyingColumn ?? null,
        i18n,
        queryGroupId,
      });
    } else {
      editQuery.mutate({
        viewName: customQueryData.data.viewName,
        code: data.code,
        i18n,
        permissions: Object.entries(permissions).map(
          ([grantee, privileges]) => ({
            grantee,
            privileges,
          }),
        ),
        identifyingColumn: data.identifyingColumn ?? null,
        queryGroupId,
      });
    }
  };

  return (
    <Box width="100%" minHeight="100%">
      {viewName ? (
        customQueryData.isSuccess && query ? (
          <CustomQueryForm
            onSave={handleSave}
            data={query}
            queryGroups={queryGroups ?? []}
            key={viewName}
          />
        ) : (
          <LoadingComponent />
        )
      ) : (
        <CustomQueryForm onSave={handleSave} queryGroups={queryGroups ?? []} />
      )}
    </Box>
  );
});
