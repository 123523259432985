import { PropsFromConnector } from "core";
import { connectElement } from "core/utils/react-redux";

import Component from "./component";
import { ReduxModule } from "./reduxModule";
import { DataDisplay } from "./types";

const connector = connectElement<ReduxModule, DataDisplay>();

export type Props = PropsFromConnector<typeof connector>;

export default connector(Component);
