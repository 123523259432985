import { ReactNode, memo, useMemo } from "react";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import { default as MuiTableRow, TableRowProps } from "@mui/material/TableRow";

import { useTableContext } from "./context";

type Props = {
  children: ReactNode[] | ReactNode;
  rowId: string | number;
  rowProps?: TableRowProps;
  cellProps?: TableCellProps;
};

export const TableRow = memo<Props>(
  ({ children, rowId, rowProps, cellProps }) => {
    const { alignment } = useTableContext();
    const cells = useMemo(
      () =>
        Array.isArray(children) ? (
          children.map((child, i) => (
            <TableCell
              key={`${rowId}-TableCell-${i}`}
              align={alignment[i]}
              {...cellProps}
            >
              {child}
            </TableCell>
          ))
        ) : (
          <TableCell
            key={`${rowId}-TableCell-0`}
            align={alignment[0]}
            {...cellProps}
          >
            {children}
          </TableCell>
        ),
      [children, cellProps, rowId, alignment],
    );

    return <MuiTableRow {...rowProps}>{cells}</MuiTableRow>;
  },
);
