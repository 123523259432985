import { useCallback } from "react";
import {
  buildCustomExpressionValue,
  getExpression,
  isCustomExpression,
} from "core";

type UseCustomExpressionReturnOnChange = (params: {
  value?: string;
  isExpression?: boolean;
}) => void;

interface UseCustomExpressionReturn {
  /**
   * whether or not the value is an expression
   */
  isExpression: boolean;

  /**
   * the value without the expression tag prefix
   */
  value: string;

  /**
   * function to update value and isExpression
   */
  onChange: UseCustomExpressionReturnOnChange;

  /**
   * function to update the value
   */
  onValueChange: (value: string) => void;

  /**
   * function to toggle whether the string is an expression or not
   */
  toggleIsExpression: () => void;
}

/**
 *
 * Helper hook to make editing customExpression values easier.
 * Used in editor element config components.
 *
 * The hook parses the customExpression and allows the user to update the value
 * and toggle whether the string is an expression or not
 *
 *
 * @param {string} value the customExpression value
 * @param callback the callback function
 *
 * @returns {UseCustomExpressionReturn}
 *
 * @example
 * const {value, isExpression} = useCustomExpression(
 *   value,
 *   handleChange,
 * );
 */
export function useCustomExpression(
  value: string,
  callback: (value: string) => void,
): UseCustomExpressionReturn {
  const isExpression = isCustomExpression(value);
  const valueWithoutTag = isExpression ? getExpression(value) : value;

  const handleChange = useCallback(
    ({
      value: newValue,
      isExpression: newIsExpression,
    }: {
      value?: string;
      isExpression?: boolean;
    }) => {
      if (newIsExpression ?? isExpression) {
        callback(buildCustomExpressionValue(newValue ?? valueWithoutTag));
      } else {
        callback(newValue ?? valueWithoutTag);
      }
    },
    [callback, isExpression, valueWithoutTag],
  );

  const handleValueChange = useCallback(
    (newValue: string) => handleChange({ value: newValue }),
    [handleChange],
  );

  const toggleIsExpression = useCallback(
    () => handleChange({ isExpression: !isExpression }),
    [handleChange, isExpression],
  );

  return {
    isExpression,
    value: valueWithoutTag,
    onChange: handleChange,
    onValueChange: handleValueChange,
    toggleIsExpression,
  };
}
