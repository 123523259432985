import { memo } from "react";
import { ConnectedReduxModuleProps, Element } from "core";

import { ReduxModule } from "./reduxModule";
import { AdvancedConditional } from "./types";

type Props = ConnectedReduxModuleProps<ReduxModule, AdvancedConditional>;

const AdvancedConditionalComponent = memo<Props>(({ element, visible }) => {
  const {
    children: {
      content: {
        elements: [trueElement, falseElement],
      },
    },
    props: elementProps,
  } = element;

  const getComponent = () => {
    try {
      const innerElement = visible ? trueElement : falseElement;
      if (innerElement) {
        return <Element element={innerElement} elementProps={elementProps} />;
      }
      return null;
    } catch (error) {
      return null;
    }
  };
  const component = getComponent();

  return component;
});

AdvancedConditionalComponent.displayName = "AdvancedConditionalComponent";

export default AdvancedConditionalComponent;
