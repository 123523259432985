import { ChangeEvent, ComponentProps, memo, useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { isBoolean } from "lodash";
import {
  PageSelector,
  Section,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import {
  IPage,
  buildCustomExpressionValue,
  getBooleanExpressionValue,
} from "core/types";

import { InternalLinkFieldConfig } from "../types";

import { ConfigField } from "./ConfigField";
import { useInternalLinkFieldEditorTranslation } from "./translation";

export const Display = memo(() => {
  const { configTitle } = useEditorTranslation();

  const { isButtonLabel, pageSelectorLabel, labelLabel, disabledInputLabel } =
    useInternalLinkFieldEditorTranslation();
  const {
    elementModel: {
      config,
      config: {
        disabled = false,
        isButton = false,
        linkTo,
        linkTo: { params },
      },
    },
    changeConfigValue,
  } = useElementEditorContext<InternalLinkFieldConfig>();

  const toggleIsButton = (_ev: any, nextIsButton: boolean) =>
    changeConfigValue("isButton", nextIsButton);

  const handlePageChange = (p: IPage, newParams: any) => {
    changeConfigValue("linkTo", {
      ...linkTo,
      pageId: p.id,
      params: newParams,
    });
  };

  const handleDisabledChange = (value: unknown) =>
    changeConfigValue("disabled", value);

  const handleToggleModeDisabled = (isExpression: boolean) => {
    const nextVal = isExpression
      ? getBooleanExpressionValue(String(disabled))
      : buildCustomExpressionValue(String(disabled));
    handleDisabledChange(nextVal);
  };

  const disabledNonExpressionEditor: ComponentProps<
    typeof CustomExpressionEditor
  >["nonExpressionEditor"] = useCallback(
    ({ value, onChange }: NonExpressionEditorProps<boolean>) => {
      const handleValueChange = (
        _event: ChangeEvent<HTMLInputElement>,
        checked: boolean,
      ) => onChange(checked);
      return (
        <FormControlLabel
          control={
            <Switch
              checked={isBoolean(value) ? value : value === "true"}
              onChange={handleValueChange}
            />
          }
          label={disabledInputLabel}
        />
      );
    },
    [disabledInputLabel],
  );

  return (
    <>
      <Section title={labelLabel} wrapped={true}>
        <ConfigField name="label" />
      </Section>
      <Section title={configTitle} wrapped={true}>
        <PageSelector
          pageId={(linkTo as any).pageId}
          config={config}
          params={params as any}
          onChange={handlePageChange}
          label={pageSelectorLabel}
        />
        <CustomExpressionEditor
          value={String(disabled)}
          config={config}
          nonExpressionEditor={disabledNonExpressionEditor}
          onToggleMode={handleToggleModeDisabled}
          onChange={handleDisabledChange}
          label={disabledInputLabel}
          forceFlexChange
          isBoolean
        />
      </Section>
      <Section title={"Styling"} wrapped={true}>
        <FormControlLabel
          control={<Switch checked={isButton} onChange={toggleIsButton} />}
          label={isButtonLabel}
        />
      </Section>
    </>
  );
});
