import { memo } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useElementEditorContext } from "core/editor";

import { FormConfig } from "../../types";
import { useEditorFormTranslation } from "../translation";

export const HideBackButton = memo(() => {
  const {
    elementModel: {
      config: { hideBackButton = false },
    },
    changeConfigValue,
  } = useElementEditorContext<FormConfig>();
  const { hideBackButtonLabel } = useEditorFormTranslation();

  const handleHideBackButtonInputChange = () =>
    changeConfigValue("hideBackButton", !hideBackButton);

  return (
    <FormControlLabel
      control={
        <Switch
          data-testid="hide-back-button"
          checked={hideBackButton}
          onChange={handleHideBackButtonInputChange}
        />
      }
      label={hideBackButtonLabel}
    />
  );
});
