import Component from "./component";
import { HtmlDisplayConfig, HtmlDisplayTranslationKeys } from "./types";

export default {
  name: "default_html_display",
  component: Component,
  editable: true,
  configType: HtmlDisplayConfig,
  translationKeys: HtmlDisplayTranslationKeys,
};
