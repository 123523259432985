import { buildCustomExpressionValue } from "core";
import { IDefaultElement } from "core/types/element";
import { UntransformedMetaQueryConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedMetaQueryConfig> = {
  config: {
    dataSource: {
      queryName: "",
    },
    filter: buildCustomExpressionValue("null"),
    initialLoad: true,
    loadOnChange: true,
  },
};
