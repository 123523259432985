import { types } from "core/runtime-typing";

interface StorageFileGroup {
  name: string;
  acl: string[] | null;
}

/**
 * TODO:
 * We can make StorageFileGroup be an io-ts type and have a io-ts-to-selector-types helper function, to avoid duplication
 */
const storageFileGroupType = types.interface({
  name: types.string(),
  acl: types.nullable(types.array(types.string())),
});

interface StorageTypeGroup {
  typeName: string;
}

/**
 * TODO:
 * We can make StorageTypeGroup be an io-ts type and have a io-ts-to-selector-types helper function, to avoid duplication
 */
const storageTypeGroupType = types.interface({
  typeName: types.string(),
});

export type StorageFileMetadata = {
  id: string;
  fileName: string;
  hash: string;
  fileGroup: StorageFileGroup;
  typeGroup: StorageTypeGroup;
  realName: string;
  fileType: string;
  createdAt: Date;
  acl?: string[] | null;
};

/**
 * TODO:
 * We can make StorageFileMetadata be an io-ts type and have a io-ts-to-selector-types helper function, to avoid duplication
 */
export const storageFileMetadataType = types.interface({
  fileName: types.string(),
  hash: types.string(),
  fileGroup: storageFileGroupType,
  typeGroup: storageTypeGroupType,
  realName: types.string(),
  fileType: types.string(),
  acl: types.nullable(types.array(types.string())),
});

export const storageMultipleFileMetadataType = types.interface({
  body: types.array(storageFileMetadataType),
  fileNames: types.array(types.string()),
});
