import { handleActions } from "core/utils/redux";

import { types } from "./actions";
import { IState } from "./types";

const INITIAL_STATE: IState = {
  config: null,
  error: null,
  loading: true,
};

export const reducer = handleActions<IState>(INITIAL_STATE, {
  [types.LOAD_LOGIN_CONFIG_SUCCESS]: (state, action) => ({
    ...state,
    config: action.payload.value,
    error: null,
    loading: false,
  }),
  [types.LOAD_LOGIN_CONFIG_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error,
    loading: false,
  }),
});
