import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  root: {
    padding: 16,
  },
  dividerFooter: {
    marginBottom: 16,
    marginTop: 8,
  },
}));

export default useStyles;
