import {
  ChangeEvent,
  MouseEvent,
  SyntheticEvent,
  createRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { GridSearchIcon } from "@mui/x-data-grid";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { DragDropContext } from "react-beautiful-dnd";
import { FixedSizeList } from "react-window";
import { Translation } from "core";
import { useSessionContext } from "core/session";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import { IAutocompleteValue } from "elementTypes/common/Autocomplete/types.ts";
import IconButton from "elementTypes/common/IconButton";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import { IsTrialTooltipTitle } from "elementTypes/common/TrialLabel";
import { useAdminContext } from "staticPages/admin/context";
import { useDatabaseTranslation } from "staticPages/admin/pages/modelBuilder/translation.ts";
import { getListWithIndex } from "staticPages/admin/pages/modelBuilder/workflow/utils.ts";
import { RoutePaths } from "staticPages/routes";
import {
  useDebouncedState,
  useLocation,
  usePrevious,
  useRoute,
} from "utils/hooks";
import { useDimensions } from "utils/hooks/useDimensions.ts";
import { DialogType } from "../../context/database/consts.ts";
import { useERDContext } from "../../context/database/ERDContext.utils.ts";
import { usePermissionContext } from "../../context/databasePanelPermission/PermissionContext.utils.ts";
import { useQueriesPanelContext } from "../../context/queriesPanel/QueriesPanelContext.utils.ts";
import { useQueryFilterContext } from "../../context/queryFilter/QueryFilterContext.utils.ts";
import { useNotAutogeneratedTables } from "../../erd/utils.ts";
import { EmptyData } from "../EmptyData.tsx";
import { useStyles } from "../styles.ts";
import {
  ModalContentType,
  QueryGroup,
  QueryObject,
  UIQueryGroups,
} from "../types.ts";
import { QueryTypeFilterOptions, QueryViewOptions } from "../types.ts";
import { Dialog } from "./components/Dialog.tsx";
import { QueryRow } from "./components/tree/VirtualizedQuery.tsx";
import { ITEM_SIZE, TDialogProps, TQueriesList, TTreeProps } from "./types.ts";
import { useQueryFetch } from "./utils/useQueryFetch.ts";
import { useRoles } from "./utils/useRoles.ts";
import { useTrees } from "./utils/useTrees.tsx";

// TODO: move QueriesPanel to index, and leave a list here: divide functionality and component itself

export const QueriesPanel = memo(() => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const route = useRoute();
  const { language } = useSessionContext();
  const { pathname } = useLocation();

  const {
    queriesSearchValue,
    setQueriesSearchValue,
    expandedQueryGroupList,
    setExpandedQueryGroupList,
  } = useAdminContext();

  const {
    queries,
    showActions,
    isLoading,
    queryGroups,
    queryGroupsUtils: {
      searchQueryGroups,
      searchQueries,
      queryGroupsByType,
      findQueryGroupByKey,
    },
    modalProps: { modal, setModalOpen, handleCloseModal, handleOpenModal },
    queryProps: {
      queryType,
      setQueryType,
      queryView,
      setQueryView,
      fetchQueryProps,
    },
  } = useQueriesPanelContext();

  const { permissions } = usePermissionContext();

  const { classes, cx } = useStyles();

  const listRef = createRef<FixedSizeList>();

  const [cachedSearchValue, cachedHandleSearch] = useDebouncedState(
    queriesSearchValue,
    setQueriesSearchValue,
  );
  const [cachedExpandedQueryGroupList, cachedHandleExpandedQueryGroupList] =
    useDebouncedState(expandedQueryGroupList, setExpandedQueryGroupList);

  const {
    successDelete,
    successUpdate,
    successDeleteQueryGroup,
    emptyQueryGroupTitle,
    ...dbTranslation
  } = useDatabaseTranslation();

  const handleOpenCustomQuery = () =>
    route("push", RoutePaths.DatabaseCustomQuery);
  const handleOpenMenu = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const { roleFilter, onRoleFilterChange } = useQueryFilterContext();

  const {
    handleRefreshClick,
    rolesListItems,
    isLoading: isRolesLoading,
  } = useRoles();

  const {
    deleteQuery,
    deleteQueryGroup,
    editQueryGroup,
    createQueryGroup,
    saveDefaultQuery,
    deleteQueries,
  } = useQueryFetch(fetchQueryProps);
  const { onModalOpen } = useERDContext();

  const isCreateQueryDisabled = pathname === RoutePaths.DatabaseCustomQuery;
  const title = <Typography variant="h6">{dbTranslation.queries}</Typography>;

  const handleQueryTypeChange = (_: any, value: any) => setQueryType(value);

  const toggleQueryView = () =>
    setQueryView((prevValue) =>
      prevValue === QueryViewOptions.list
        ? QueryViewOptions.group
        : QueryViewOptions.list,
    );

  const handleDelete = (name: string) => deleteQuery({ name });

  const handleOpenAllDefaultQueries = () => {
    onModalOpen(DialogType.generateDefaultQueries)();
    handleCloseMenu();
  };

  const handleDeleteAllQueriesClick = () => {
    setModalOpen({
      type: ModalContentType.queriesDelete,
      queries: queries?.map((query) => query.name),
    });
    handleCloseMenu();
  };

  const handleDeleteAllQueries = () =>
    !!modal?.queries?.length && deleteQueries({ viewNames: modal.queries });

  const handleSearchChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => cachedHandleSearch(e.target.value);

  const handleToggle = useCallback(
    (_: SyntheticEvent, nodeIds: string[]) => {
      cachedHandleExpandedQueryGroupList(nodeIds);
    },
    [cachedHandleExpandedQueryGroupList],
  );

  const handleExpandClick = () => {
    const nextVal = cachedExpandedQueryGroupList.length
      ? []
      : [...Object.keys(queryGroupsByType ?? {})];
    cachedHandleExpandedQueryGroupList(nextVal);
  };

  const handleEditQueryGroupName = ({ i18n }: Record<string, any>) => {
    const queryGroup = findQueryGroupByKey(
      "id",
      modal?.selectedQueryGroup?.id ?? null,
    );

    if (queryGroup) {
      editQueryGroup({
        id: queryGroup.id,
        i18n: i18n as Translation<"label">,
      });
    }

    handleCloseModal();
  };

  const handleCreateQueryGroup = (data: Record<string, any>) =>
    createQueryGroup({
      i18n: data?.i18n as Translation<"label">,
    });

  const handleEditQuery = (data: Record<string, any>) => {
    if (!modal?.selected) {
      return;
    }

    const queryTitle = data.title.trim();
    const updatedQuery = {
      viewName: modal.selected.name,
      permissions: Object.entries(permissions).map(([grantee, privileges]) => ({
        grantee,
        privileges,
      })),
      i18n: {
        [language.code]: {
          title: queryTitle,
        },
      } as Translation<"title">,
      queryGroupId: data.queryGroupId,
    };

    saveDefaultQuery(updatedQuery);
  };

  const handleDeleteQueryGroup = () =>
    deleteQueryGroup({ id: Number(modal!.selectedQueryGroup!.id!) });

  const { ref: boxRef, ...size } = useDimensions();
  const prevQueries = usePrevious(queries ?? []);

  useEffect(() => {
    if (queries.length > prevQueries.length && listRef.current) {
      const scrollToQuery = getListWithIndex(
        prevQueries,
        queries,
      ) as QueryObject & { index: number };
      if (scrollToQuery) {
        listRef.current.scrollToItem(scrollToQuery.index);
      }
    }
  }, [queries, prevQueries, listRef]);

  const queriesByType = useMemo(
    () =>
      queryType
        ? queries.filter((q) =>
            queryType === QueryTypeFilterOptions.default
              ? q.autogenerated
              : !q.autogenerated,
          )
        : queries,
    [queries, queryType],
  );

  const filteredQueries = useMemo(
    () =>
      cachedSearchValue.trim()
        ? searchQueries({
            queriesByType,
            searchValue: cachedSearchValue,
            queryGroups: queryGroups ?? ([] as QueryGroup[]),
            queryType,
          })
        : queriesByType,
    [cachedSearchValue, queriesByType, queryGroups, queryType, searchQueries],
  );

  const filteredQueryGroups = useMemo(
    () =>
      cachedSearchValue.trim()
        ? searchQueryGroups({
            queryGroupData: queryGroupsByType ?? ({} as UIQueryGroups),
            searchValue: cachedSearchValue,
            queryType,
          })
        : queryGroupsByType,
    [cachedSearchValue, queryGroupsByType, queryType, searchQueryGroups],
  );

  const typeButtons = Object.values(QueryTypeFilterOptions).map(
    (queryTypeItem) => (
      <ToggleButton key={`${queryTypeItem}-query-type`} value={queryTypeItem}>
        {dbTranslation[`${queryTypeItem}QueriesLabel`]}
      </ToggleButton>
    ),
  );

  const handleQueryRoleChange = (value: IAutocompleteValue) =>
    onRoleFilterChange(value as string | undefined);

  const openCreateQueryGroupDialog = () => {
    setModalOpen({
      type: ModalContentType.createQueryGroup,
    });
    handleCloseMenu();
  };

  const handleTreeEditClick = useCallback(
    (queryGroup?: QueryGroup) => (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      handleOpenModal(ModalContentType.editQueryGroup, undefined, queryGroup);
    },
    [handleOpenModal],
  );

  const handleTreeDeleteClick = useCallback(
    (queryGroup?: QueryGroup) => (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      handleOpenModal(ModalContentType.deleteQueryGroup, undefined, queryGroup);
    },
    [handleOpenModal],
  );

  const QueriesList = memo<TQueriesList>(({ listQueries }) => {
    const height =
      size?.height ?? ITEM_SIZE * Math.min(listQueries.length ?? 0, 10);

    return (
      <FixedSizeList
        ref={listRef}
        height={height}
        itemCount={listQueries.length}
        itemSize={ITEM_SIZE}
        width="100%"
        itemData={listQueries}
        itemKey={(index, data) => data[index].id}
      >
        {(props) => <QueryRow {...props} type="list" />}
      </FixedSizeList>
    );
  });

  const treeProps: TTreeProps = {
    filteredQueries,
    filteredQueryGroups: filteredQueryGroups ?? ({} as UIQueryGroups),
    handleTreeDeleteClick,
    handleTreeEditClick,
  };

  const { treeItems, onDragEnd, onDragStart } = useTrees(treeProps);

  const queriesView = useMemo(
    () =>
      queryView === QueryViewOptions.list ? (
        <QueriesList listQueries={filteredQueries} />
      ) : (
        <SimpleTreeView
          expandedItems={cachedExpandedQueryGroupList}
          {...((filteredQueries.length ||
            Object.keys(filteredQueryGroups).length >= 1) && {
            onExpandedItemsChange: handleToggle,
          })}
        >
          {treeItems}
        </SimpleTreeView>
      ),
    [
      queryView,
      QueriesList,
      filteredQueries,
      cachedExpandedQueryGroupList,
      filteredQueryGroups,
      handleToggle,
      treeItems,
    ],
  );

  const dialogProps: TDialogProps = {
    findQueryGroupByKey,
    handleClose: handleCloseModal,
    handleCreateQueryGroup,
    handleEditQuery,
    handleEditQueryGroupName,
    handleQueryDelete: handleDelete,
    handleQueriesDelete: handleDeleteAllQueries,
    handleDeleteQueryGroup,
    language,
    modal,
    queryGroups: queryGroups ?? [],
  };
  const filteredTables = useNotAutogeneratedTables();

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Card
        variant="outlined"
        className={cx(classes.cardHeight, classes.card, classes.scrollableCard)}
      >
        <CardHeader
          disableTypography
          avatar={
            <Box display="flex">
              <IconButton
                icon={
                  queryView === QueryViewOptions.group ? "reorder" : "schema"
                }
                tooltip={
                  queryView === QueryViewOptions.list
                    ? dbTranslation.groupViewLabel
                    : dbTranslation.listViewLabel
                }
                onClick={toggleQueryView}
                edge="start"
                processing={isLoading}
              />
            </Box>
          }
          title={<IsTrialTooltipTitle title={title} />}
          classes={{
            root: classes.cardHeader,
            action: classes.queryHeaderAction,
            avatar: classes.queryHeaderAvatar,
          }}
          action={
            <Box display="flex" alignItems="center">
              {queryView === QueryViewOptions.group && (
                <IconButton
                  disabled={!queryGroups?.length}
                  icon={
                    cachedExpandedQueryGroupList.length
                      ? "unfold_less"
                      : "unfold_more"
                  }
                  tooltip={
                    cachedExpandedQueryGroupList.length
                      ? dbTranslation.collapseAllTooltip
                      : dbTranslation.expandAllTooltip
                  }
                  onClick={handleExpandClick}
                />
              )}
              {showActions && (
                <>
                  <IconButton
                    onClick={handleOpenMenu}
                    icon="playlist_add"
                    tooltip={dbTranslation.showMenuTitle}
                    color="primary"
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{
                      mt: 0,
                    }}
                  >
                    <MenuItem
                      onClick={handleOpenCustomQuery}
                      disabled={isCreateQueryDisabled}
                    >
                      {dbTranslation.createCustomQueryTitle}
                    </MenuItem>
                    <MenuItem
                      {...(!Object.values(filteredTables ?? {})?.length
                        ? {
                            disabled: true,
                          }
                        : {
                            onClick: handleOpenAllDefaultQueries,
                          })}
                    >
                      {dbTranslation.generateAllQueriesTitle}
                    </MenuItem>
                    <MenuItem
                      onClick={handleDeleteAllQueriesClick}
                      disabled={!queries?.length}
                    >
                      {dbTranslation.deleteQueriesTitle}
                    </MenuItem>
                    <MenuItem onClick={openCreateQueryGroupDialog}>
                      {dbTranslation.createQueryGroupTitle}
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Box>
          }
        />
        <Stack
          p={1}
          direction="column"
          borderTop="1px solid"
          borderColor="divider"
        >
          <ToggleButtonGroup
            size="small"
            value={queryType}
            exclusive={true}
            onChange={handleQueryTypeChange}
            color="primary"
            fullWidth
          >
            {typeButtons}
          </ToggleButtonGroup>
        </Stack>
        <Stack
          p={1}
          borderTop="1px solid"
          borderColor="divider"
          gap={1}
          direction="column"
        >
          <TextField
            InputProps={{ endAdornment: <GridSearchIcon /> }}
            fullWidth
            variant="standard"
            value={cachedSearchValue}
            size="small"
            placeholder={dbTranslation.searchLabel}
            onChange={handleSearchChange}
          />
          <Autocomplete
            options={rolesListItems}
            label={dbTranslation.filterByRoleTitle}
            disabled={!rolesListItems.length}
            onChange={handleQueryRoleChange}
            value={roleFilter}
            startAdornment={
              <>
                {isRolesLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <IconButton
                    size="small"
                    icon="refresh"
                    tooltip={dbTranslation.generateRefreshTooltip}
                    onClick={handleRefreshClick}
                  />
                )}
              </>
            }
          />
        </Stack>
        <Divider />
        {filteredQueries.length || filteredQueryGroups.emptyGroups?.length ? (
          <Box
            {...{ ref: boxRef }}
            className={cx({
              [classes.treeStyle]: queryView === QueryViewOptions.group,
              [classes.listStyle]: queryView === QueryViewOptions.list,
            })}
          >
            {queriesView}
          </Box>
        ) : (
          <EmptyData
            title={"No queries found"}
            onClick={handleOpenCustomQuery}
          />
        )}
        <Dialog {...dialogProps} />
      </Card>
    </DragDropContext>
  );
});
