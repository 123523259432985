import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Data Display",
      description: "Retrieve and display a value",
    },
    de: {
      title: "Datenanzeige",
      description: "Hole und zeige einen Wert an",
    },
  },
  minSize: {
    width: 2,
    height: 2,
  },
  defaultSize: {
    width: 3,
    height: 3,
  },
};
