import { createUseTranslation } from "core/session/translation";

export const numberInputEditorTranslation = {
  en: {
    stylingTitle: "Styling",
    labelLabel: "Label",
    startAdornment: "Start Adornment",
    endAdornment: "End Adornment",
    iconLabel: "Icon",
    textLabel: "Text",
    applyTitle: "Apply to",
    precisionInputLabel: "Precision",
    prefixInputLabel: "Prefix",
    suffixInputLabel: "Suffix",
    formatInputLabel: "Format",
    thousandSeparatorInputLabel: "Thousand Separator",
    formatHelperText:
      "Use # in the place where numbers should be rendered at. I.e: ##### ## #",
  },
};

export const useNumberInputEditorTranslation = createUseTranslation(
  numberInputEditorTranslation,
);
