import { GeneratedFunction } from "queries/admin/types";
import { fuseFn } from "staticPages/admin/pages/modelBuilder/components/utils";

export const placeholderStart = "CREATE OR REPLACE FUNCTION cypex_generated.";
export const placeholder = `${placeholderStart}sum(a integer, b integer)
  RETURNS integer AS $$
    SELECT a + b;
  $$ LANGUAGE SQL IMMUTABLE;
`;

export const regexPattern =
  /^CREATE(\s+OR\s+REPLACE)?\s+FUNCTION\s+cypex_generated\./gi;

export function findMismatch(pattern: RegExp, str: string) {
  const patternChars = Array.from(pattern.source);
  const strChars = Array.from(str);

  const mismatchIndex = patternChars.findIndex((char, index) => {
    return char.toLowerCase() !== strChars[index].toLowerCase();
  });

  return {
    mismatchIndex,
    patternChar: patternChars[mismatchIndex],
    strChar: strChars[mismatchIndex],
  };
}

export function checkDifference(str: string) {
  const match = regexPattern.exec(str);

  return {
    matched: !!match,
    matchDetails: findMismatch(regexPattern, str),
  };
}

export const searchData = (
  functions: GeneratedFunction[] = [],
  search: string,
) => {
  return fuseFn(functions, [{ name: `name`, weight: 1 }])
    .search(`'${search}`)
    .map((obj) => obj.item);
};
