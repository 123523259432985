import { ChangeEvent, memo, useCallback } from "react";
import { TextField } from "@mui/material";
import { Section, useElementEditorContext } from "core/editor";

import { UntransformedGeoJSONFieldConfig } from "../../types";
import { useDefaultGeoJSONFieldEditorTranslation } from "../translation";

export const LayerUrl = memo(() => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedGeoJSONFieldConfig>();

  const t = useDefaultGeoJSONFieldEditorTranslation();

  const handleTileLayerUrlChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue("tileLayerUrl", e.target.value || null),
    [changeConfigValue],
  );

  return (
    <Section title={t.basicTitle} wrapped={true}>
      <TextField
        value={config.tileLayerUrl}
        onChange={handleTileLayerUrlChange}
        label={t.tileLayerUrlLabel}
        helperText={t.tileLayerUrlDescription}
      />
    </Section>
  );
});
