import { connect } from "react-redux";
import { IElementComponentProps } from "core";
import { connectErrorHandlerUtils } from "core/utils/react-redux";

import Component from "./component";
import { ColorField } from "./types";

const mapStateToProps = (
  state: any,
  { element }: IElementComponentProps<Record<string, unknown>, ColorField>,
) => ({
  value: element.config.value(state),
  width: element.config.width,
  height: element.config.height,
  showLabel: element.config.showLabel,
});

export default connect(
  connectErrorHandlerUtils.enhanceMapStateToProps(mapStateToProps),
)(connectErrorHandlerUtils.enhanceComponent(Component));
