import { memo } from "react";
import Icon from "@mui/icons-material/GitHub";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import Markdown from "react-markdown";

import { gitInfo } from "utils/getGitInfo";

import { isDevMode } from "utils/other";
import useStyles from "../styles";

export const AdminFooter = memo(() => {
  const {
    classes: { footer },
  } = useStyles();

  const isDev = isDevMode();

  return (
    <Box component="footer" className={footer}>
      {isDev ? (
        <Chip
          label={gitInfo.GIT_BRANCH}
          size="small"
          color="success"
          icon={<Icon />}
        />
      ) : (
        <div />
      )}
      <Markdown>
        CYPEX is a product developed by
        [CYBERTEC](https://www.cybertec-postgresql.com/).
      </Markdown>
      <CopyToClipboard text={gitInfo.GIT_TAG ?? gitInfo.GIT_COMMIT}>
        <Tooltip title={`${gitInfo.GIT_DATE} - ${gitInfo.GIT_COMMIT}`}>
          <Chip
            label={gitInfo.GIT_TAG ?? gitInfo.GIT_COMMIT}
            color="secondary"
            size="small"
          />
        </Tooltip>
      </CopyToClipboard>
    </Box>
  );
});
