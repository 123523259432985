import { lazy, memo } from "react";
import { dissoc } from "ramda";
import { IElement, getExpression } from "core";
import { useEditorTranslation, useElementEditorContext } from "core/editor";
import Button from "elementTypes/common/Button";
import { TableHeaderCell } from "elementTypes/default_table_header_cell/types";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { useElement, usePage } from "utils/hooks";

import { useTableEditorTranslation } from "../../../translation";
import { TableChildren, UntransformedTableConfig } from "../../../types";

import { generateColumnName } from "../../utils";
import { IGenerateColumn } from "./ColumnEditor";
import { IColumn } from "./Columns";
import { useColumnsContext } from "./ColumnsContext";

/**
 * get the config property containing the value expression
 */
const elementTypeToValueConfigPropertyMapping = (type: string) =>
  ({
    default_text_field: "text",
    default_markdown_text_field: "text",
    storage_image: "path",
    default_array_text_field: "values",
    default_external_link_field: "link",
  })[type] || "value";

const ColumnDialogContent = withLazyLoading(
  lazy(() => import("./ColumnDialogContent")),
  true,
);
const HiddenFieldComponent = withLazyLoading(
  lazy(() => import("./HiddenFieldComponent")),
  true,
);

export const ColumnDialog = memo(() => {
  const { createColumnTitle, editColumnTitle } = useTableEditorTranslation();
  const { cancelButton, createButton, deleteButton, updateButton } =
    useEditorTranslation();
  const {
    elementModel,
    elementModel: {
      children: {
        header: { elements: headerColumns },
        body: { elements: bodyColumns },
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedTableConfig, TableChildren>();
  const {
    elementTypes,
    columnDetails,
    currentView,
    hidden,
    language,
    updateChildren,
    setColumnDetails,
    deleteColumn,
    generateColumn,
  } = useColumnsContext();
  const page = usePage();
  const { getElementTypeFromField } = useElement();

  const elementTypesDialogConfig = Object.keys(elementTypes).map((name) => {
    const et = elementTypes[name];
    return { name, i18n: et.editorMetadata?.i18n };
  });

  function handleClose() {
    setColumnDetails(null);
  }

  function validateColumnName(columnName: string) {
    return !headerColumns.some(
      (col: IElement) => col.name === `table_header_column-${columnName}`,
    );
  }

  function onCustomExpressionChange(nextExpression: string) {
    return (
      columnDetails &&
      setColumnDetails({
        ...columnDetails,
        isHidden: nextExpression as string,
      })
    );
  }
  function handleDeleteClick() {
    if (columnDetails) {
      deleteColumn(columnDetails?.index);
      handleClose();
    }
  }

  const columnIndex = headerColumns.findIndex(
    (col) => col.name === columnDetails?.name,
  );

  function onSubmit(data: Record<string, any>) {
    const isExpression = !!getExpression(columnDetails?.isHidden ?? "").trim()
      .length;
    const isHidden = isExpression ? columnDetails?.isHidden : null;

    if (columnDetails?.isNew) {
      const field = currentView?.fields.find((f) => f.name === data.fieldName);

      const elementType = field
        ? getElementTypeFromField(
            field,
            elementModel,
            "default_table",
            "field",
            {
              column: 1,
              row: 1,
              width: 1,
              height: 1,
            },
          )
        : null;
      const commonProps = {
        ...data,
        position: {
          column: columnDetails.position.column,
          row: 1,
          width: 1,
          height: 1,
        },
      };

      const element =
        elementType?.type === data.elementType
          ? ({ ...elementType, ...commonProps } as IGenerateColumn)
          : {
              name: data.name,
              type: data.elementType,
              config: {},
              ...commonProps,
            };

      generateColumn(element);
      changeConfigValue("hidden", [...hidden, isHidden]);
      handleClose();
    } else {
      const body = {
        ...bodyColumns[columnIndex],
        config: { ...bodyColumns[columnIndex].config },
      };

      const headerElements = (headerColumns as IColumn[]).map((col, index) => {
        if (index !== columnIndex) {
          return col;
        }

        const colConfig = !(data.width === "auto")
          ? { ...col.config, width: "1px" }
          : (dissoc("width", col.config) as TableHeaderCell["config"]);

        return {
          ...col,
          name: generateColumnName(data.name),
          i18n: { ...col.i18n, [language.code]: { label: data.label } },
          config: {
            ...colConfig,
            dataSource: {
              ...colConfig.dataSource,
              fieldName: data.fieldName ?? "",
              sortable: data.sortable,
            },
            align: data.align,
          },
        };
      });

      const bodyElements = [...bodyColumns];

      const oldElementTypeName = body.type.name;
      const newElementTypeName = data.elementType;
      if (oldElementTypeName !== newElementTypeName) {
        // map current config to new config
        const oldBody = bodyColumns[columnIndex];

        const oldValueProperty =
          elementTypeToValueConfigPropertyMapping(oldElementTypeName);
        const newValueProperty =
          elementTypeToValueConfigPropertyMapping(newElementTypeName);
        body.config = {
          [newValueProperty]: oldBody.config[oldValueProperty],
        };

        body.type = { name: newElementTypeName };

        // set default config and i18n for that element
        const newElementType = elementTypes[newElementTypeName];
        body.config = {
          ...newElementType.defaultElement?.config,
          ...body.config,
        };
        body.i18n = { ...newElementType.defaultElement?.i18n, ...body.i18n };

        bodyElements[columnIndex] = body;

        updateChildren(elementModel, bodyElements, page!, "body");
      }

      updateChildren(elementModel, headerElements, page!, "header");
      if (isHidden !== hidden[columnIndex]) {
        changeConfigValue("hidden", [
          ...hidden.map((hiddenField, hiddenIndex) =>
            hiddenIndex === columnIndex ? isHidden : hiddenField,
          ),
        ]);
      }

      handleClose();
    }
  }

  const currentElementTypeName = columnIndex
    ? bodyColumns[columnIndex]?.type.name
    : undefined;

  return (
    <DialogWrapper
      isForm={true}
      keepMounted={false}
      open={Boolean(columnDetails)}
      title={columnDetails?.isNew ? createColumnTitle : editColumnTitle}
      submitTitle={columnDetails?.isNew ? createButton : updateButton}
      cancelTitle={cancelButton}
      handleClose={handleClose}
      handleSubmit={onSubmit}
      submitDisabled={true}
      subActions={
        <>
          {!columnDetails?.isNew && (
            <Button
              label={deleteButton}
              color="error"
              onClick={handleDeleteClick}
            />
          )}
        </>
      }
    >
      {columnDetails && (
        <ColumnDialogContent
          {...columnDetails}
          elementTypes={elementTypesDialogConfig}
          isNameUnique={validateColumnName}
          fieldNames={currentView?.fields}
          elementModel={elementModel}
          defaultElementTypeName={currentElementTypeName}
        >
          <HiddenFieldComponent
            value={columnDetails.isHidden}
            onChange={onCustomExpressionChange}
            config={columnDetails.config}
          />
        </ColumnDialogContent>
      )}
    </DialogWrapper>
  );
});
