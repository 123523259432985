import { handleActions } from "core/utils/redux";

import { IState, Types } from "./types";

export function buildReducer(types: Types, INITIAL_STATE: IState) {
  return handleActions<IState>(INITIAL_STATE, {
    [types.VALUE_CHANGE]: (state, action: any) => ({
      ...state,
      value: action.payload.value,
    }),
    [types.SET_ERROR]: (state, action: any) => ({
      ...state,
      errors: action.payload.error,
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [types.TOUCH_CHANGE]: (state, action: any) => ({
      ...state,
      touched: true,
    }),
  });
}
