import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  text: {
    width: "100%",
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      filter: "brightness(1.2)",
    },
  },
  button: {
    width: "100%",
    height: "100%",
  },
  disabled: {
    cursor: "not-allowed",
    color: theme.palette.grey[500],
  },
}));

export default useStyles;
