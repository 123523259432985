import { Suspense, memo, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Toolbar from "@mui/material/Toolbar";
import { ImgProps, useImage } from "react-image";
import { IElementComponentProps } from "core";
import { FILE_STORAGE_PREFIX } from "services/api/constants";

import IconButton from "../common/IconButton";

import { MapStateToProps } from "./container";
import { useStyles } from "./style";
import { useStorageImageTranslation } from "./translation";
import { StorageImage } from "./types";

type IProps = IElementComponentProps<Record<string, unknown>, StorageImage> &
  MapStateToProps;

const StorageImageComponent = memo<IProps>(
  ({
    path: nullablePath = "",
    element: {
      config: {
        variant = "none",
        isWrapped = false,
        fullSizeOnClick = false,
        isUsingFileStorage = false,
      },
    },
  }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const translation = useStorageImageTranslation();
    const {
      classes: { root, ...imageClasses },
      cx,
    } = useStyles();
    const imageClass = imageClasses[variant];
    const handleImageClick = () => {
      if (fullSizeOnClick) {
        setOpenDialog(true);
      }
    };
    const handleDialogClose = () => setOpenDialog(false);
    let src = nullablePath ?? "";

    if (src === "") {
      return null;
    }

    if (isUsingFileStorage) {
      src = FILE_STORAGE_PREFIX + src;
    }

    const imgComponent = (
      <Suspense>
        <ImageComponent
          key={src}
          src={src}
          className={cx(imageClass, {
            [imageClasses.pointerFullScreen]: fullSizeOnClick,
          })}
          onClick={handleImageClick}
        />
      </Suspense>
    );

    return (
      <>
        {isWrapped ? (
          <Paper variant="outlined" className={root}>
            {imgComponent}
          </Paper>
        ) : (
          <div className={root}>{imgComponent}</div>
        )}

        <Dialog
          fullScreen
          open={openDialog}
          onClose={handleDialogClose}
          keepMounted={false}
        >
          <AppBar position="relative" elevation={0}>
            <Toolbar variant="dense">
              <Box flexGrow={1} />
              <IconButton
                edge="end"
                icon="close"
                tooltip={translation.closeText}
                color="inherit"
                onClick={handleDialogClose}
                aria-label="close"
              />
            </Toolbar>
          </AppBar>
          <ImageComponent
            key={src}
            src={src}
            className={imageClasses.fullScreenImage}
          />
        </Dialog>
      </>
    );
  },
);

const ImageComponent = memo<ImgProps>(({ alt, className, src, onClick }) => {
  const { src: imgSrc, error, isLoading } = useImage({ srcList: src });

  if (isLoading) {
    return <LoaderComponent />;
  }

  if (error) {
    return null;
  }

  return (
    <Box
      component="img"
      alt={alt}
      src={imgSrc}
      className={className}
      onClick={onClick}
    />
  );
});

const LoaderComponent = memo(() => {
  const {
    classes: { stretch, skeletonWrapper },
    cx,
  } = useStyles();

  return (
    <div
      className={cx(stretch, skeletonWrapper)}
      data-cypress-selector="loader-component"
    >
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height="100%"
      />
    </div>
  );
});

export default StorageImageComponent;
