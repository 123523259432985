import { createUseTranslation } from "core/session/translation";

// **do not indent markdown**:
const mde = `
Database permissions are of great importance and are usually assigned at the query level to ensure that tables remain mostly unchanged.

In **CYPEX**, Application generated by default rendering is customized for a specific Role, allowing you to visually control who can perform operations on each query.

Note that in some cases, a trigger will be needed to handle insertions. 

##### Therefore, read-only queries, such as reports, should only have **SELECT** permissions.
`;

const erdTranslations = {
  en: {
    actionCreateNodeTooltip: "Add Object",
    actionEditNodeTooltip: "Edit existing object",
    cancelTitle: "Close",
    columnSelectTooltip: "Select All in the Column",
    createQuerySuccessMsg: "Query Successfully Created",
    createQueriesSuccessMsg: "All Queries Successfully Created",
    editQuerySuccessMsg: "Query Successfully Edited",
    createWorkflowLabel: "Create Workflow",
    defineKeyLookupsTitle: "Default Lookup for",
    previewTableDataTitle: "Preview data of",
    disableHistory: "Disable History Tracking",
    enableHistory: "Enable History Tracking",
    foreignKeyTooltip: "Foreign Key",
    generateQueryTitle: "Generate Default Query",
    generateAllQueriesTitle: "Generate Default Queries",
    generateAllQueriesSubTitle:
      "For the following tables default queries will be generated:",
    auditingEnabled: "Auditing was enabled",
    auditingDisabled: "Auditing was disabled",
    keyLookupSelectColumn: "Default Lookup column",
    createWorklowLabel: "Create Workflow",
    menuGenerateQuery: "Generate Default Query",
    menuWorkflowSetup: "Workflow",
    menuWorkflowViewer: "Workflow",
    menuKeyLookup: "Default Lookup",
    submitTitle: "Save",
    transitionsToAllLabel: "Create Transitions to All",
    transitionToAllWarning:
      "We recommend selecting a column with no more than 15 unique values to ensure optimal performance and clarity in the workflow visualisation. The library used for displaying the workflow UI may experience difficulties when handling a large number of transitions, leading to a less intuitive user experience.",
    removePreviousWorkDataWarning:
      "The state column is removed or renamed on the underlying table, the workflow data might be still present. Update the workflow to use the new column name automatically or remove the existing workflow and create a new one from scratch",
    workflowDialogTitle: "Workflow",
    workflowTooltip: "Workflow configured",
    workflowBrokenTooltip:
      "The workflow is configured for the column '@columnName', which not exists. Please verify the configuration.",
    updateWorkflowBtn: "Update Workflow",
    removeWorkflowBtn: "Remove and Recreate Workflow",
    customCreateQueryTitle: "Create Query",
    customEditQueryTitle: "Edit Query",
    queryStatementLabel: "Statement",
    userColumnTitle: "User Role",
    rowSelectTooltip: "Select All in the Row",
    basicQueryTabTitle: "Basic Query",
    setLookupColumn: "Set as Default Lookup column",
    primaryKeyTooltip: "Primary Key",
    notNullTooltip: "Not NULL",
    uniqueTooltip: "Unique",
    aliasTooltip: "Alias",
    queryTitleLabel: "Title",
    queryNameLabel: "Name",
    auditTableTitle: "Auditing",
    auditTableDisable: "Disable",
    auditTableEnable: "Enable",
    menuTableDetails: "Table details",
    TableDetailsTitle: "Details of table",
    ViewDetailsTitle: "Details of view",
    ColumnDetails: "Column details",
    PreviewData: "Data preview",
    permissionTableTitle: "Permission Table",
    permissionTableSearchTitle: "Role Search",
    historyTrackingConfiguredTitle: "History Tracking configured",
    defaultLookupColumnTooltip: "Default Lookup column",
    defaultLookupConfiguredTooltip: "Default Lookup configured",
    helperAlertTitle:
      "Select the Workflow Column with an appropriate data type:",
    emptyHelperAlertTitle:
      "No Suitable Columns Found for Workflow Configuration in Current Table",
    helperAlertText:
      "\n - Usually, a text or varchar column is used to represent states as strings.\n - Ensure that the data type can accommodate all possible states and is suitable for your application's needs.\n - Make sure the default value of the column is one of the valid workflow states.\n - Columns with constraints are not allowed as they may conflict with workflow states.",
    workflowSelectTooltip:
      "Please choose the column that represents the workflow state for this process. The selected column will determine the current state of the process. It is important to note that the workflow column must have a default value, which serves as the initial state of the process. The default state is applied when a record is created or when no specific state is selected during a transition. Ensure that the selected column is appropriately configured to meet your workflow requirements",

    permissionsTooltipTitle: "Set Proper Permissions",
    permissionsTooltipText: mde,
  },
  es: {
    actionCreateNodeTooltip: "Agregar objeto",
    actionEditNodeTooltip: "Editar objeto asociado",
    cancelTitle: "Cerrar",
    createWorklowLabel: "Crear Workflow",
    menuGenerateQuery: "Generar Defecto Consulta",
    menuWorkflowSetup: "Configurar Workflow",
    menuWorkflowViewer: "Visualizador Workflow",
    submitTitle: "Guardar",
    transitionsToAllLabel: "Todas las transisiones posibles",
    workflowDialogTitle: "Workflow",
    setLookupColumn: "Configurar columna Lookup por defecto",
    defineKeyLookupsTitle: "Lookup por defecto para",
    previewTableDataTitle: "Previsualización de los datos de",
    keyLookupSelectColumn: "Columna Lookup por defect",
    disableHistory: "Deshabilitar registro de cambios",
    enableHistory: "Habilitar registro de cambios",
    auditTableTitle: "Auditoria",
    auditTableDisable: "Disable",
    auditTableEnable: "Enable",
    menuTableDetails: "Detalles adicionales",
    TableDetailsTitle: "Detalles de la tabla",
    ColumnDetails: "Columnas",
    PreviewData: "Vista previa",
  },
};

export const useERDTranslation = createUseTranslation(erdTranslations);

export const useKeyLookupTranslation = createUseTranslation({
  en: {
    selectText: "Select the",
    defaultLookupColumnText: "Default Lookup column",
    descriptionText:
      "that should be used in foreign key resolutions by default.",
    recommendationLabel: "Recommendation",
    useText: "Use a",
    uniqueLabel: "unique",
    humanReadableText: "and human readable",
    textLabel: "text",
    columnLabel: "column.",
  },
});
