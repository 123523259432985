import { memo } from "react";
import Typography from "@mui/material/Typography";
import { IElementComponentProps } from "core";

import { CustomTextField } from "./types";

type IProps = {
  text: string | null;
} & IElementComponentProps<{}, CustomTextField>;

const DefaultCustomTextField = memo<IProps>(
  ({
    element: {
      config: { style },
    },
    text,
  }) => <Typography {...style}>{text}</Typography>,
);

export default DefaultCustomTextField;
