import { format as formatDate } from "date-fns";
import memoize from "lodash/memoize";
import { stringToDateObject } from "utils/string.ts";

export const buildValue = (value: string | Date | null) => {
  if (typeof value === "string") {
    return stringToDateObject(value);
  }
  return value;
};

export const memoizeFormat = (v: Date | null) =>
  memoize((formatString: string) =>
    v === null ? null : formatDate(v, formatString),
  );
