import { connect } from "react-redux";

import { actions, selectors } from "../../reduxModule";

import Component from "./component";

export const mapStateToProps = (state: any) => ({
  viewList: selectors.viewList(state),
  error: selectors.errors(state)?.["viewListError"],
});

export const ViewAutocomplete = connect(mapStateToProps, actions)(Component);
