import { IRawElementType } from "core";

import { EChartsTranslationKeys } from "../common/Echarts";

import Component from "./container";
import {
  EchartsLineChartEditor,
  defaultElement,
  editorMetadata,
} from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import { LineChartConfig, selectorTypes } from "./types";
import { getQueriesFromConfig } from "./utils";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "echarts_line_chart",
  component: Component,
  editable: true,
  configType: LineChartConfig,
  translationKeys: EChartsTranslationKeys,
  defaultElement,
  editorComponent: EchartsLineChartEditor,
  editorMetadata,
  selectorTypes,
  getQueriesFromConfig,
};

export * from "./reduxModule/types";

export default elementType;
