import { createUseTranslation } from "core/session/translation";

export const translation = {
  en: {
    configSectionTitle: "Value",
    stylingSectionTitle: "Formatting",
    isWrappedLabel: "Has background and border",
    fullSizeLabel: "Show full size image on click",
    isUsingFileStorageLabel: "Path is from CYPEX File Storage",
    chooseFile: "Choose a file",
    saveErrorMessage: "File was not uploaded. Error:",
    saveSuccessMessage: "File successfully uploaded",
    selectFileButton: "Select file",
  },
};

export const useEditorStorageImageTranslation =
  createUseTranslation(translation);
