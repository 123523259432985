import { ChangeEvent, memo, useMemo } from "react";
import { Box, FormControl, FormLabel, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslator } from "core/session/translation";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import { CodeBlock } from "elementTypes/common/CodeBlock";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";
import { useHookFormError } from "utils/hooks/useHookFormError";
import { QueryGroup } from "../../components/types";

import { useCustomQueryTranslation } from "../../customQuery/translation";
import { useDatabaseTranslation } from "../../translation";
import { PermissionTable } from "../QueryBuilder";
import { useERDTranslation } from "../translation";
import { UIBasicQueryForm } from "../types";
import { handleNameValidate, handleTitleValidate, titleToName } from "../utils";

type Props = {
  data: UIBasicQueryForm;
  editAction?: true;
  queryGroups?: QueryGroup[];
  definition_code?: string;
};

export const BasicQuery = memo<Props>(
  ({ data, editAction, queryGroups, definition_code }) => {
    const { control, setValue } = useHookFormContext();
    const { queryGroupLabel } = useDatabaseTranslation();
    const { noQueryGroupSelected } = useCustomQueryTranslation();
    const { translate } = useTranslator();

    const getErrorMessage = useHookFormError();

    const handleTitleChange =
      (cb: (ev: ChangeEvent<HTMLInputElement>) => void) =>
      (ev: ChangeEvent<HTMLInputElement>) => {
        if (!editAction) {
          setValue("name", titleToName(ev.target.value));
        }
        cb(ev);
      };

    const translation = useERDTranslation();

    const options = useMemo(
      () =>
        queryGroups?.map((qg) => ({
          label: translate(qg.i18n).label ?? "",
          value: qg.id,
        })),
      [translate, queryGroups],
    );

    return (
      <Box display="flex" flexDirection="column" gap={1.5}>
        <FormControl fullWidth>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label={translation.queryTitleLabel}
                helperText={getErrorMessage(error)}
                autoFocus
                {...field}
                error={Boolean(error)}
                onChange={handleTitleChange(field.onChange)}
              />
            )}
            name="title"
            control={control}
            defaultValue={data.title}
            rules={{
              required: true,
              validate: handleTitleValidate,
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label={translation.queryNameLabel}
                helperText={getErrorMessage(error)}
                disabled
                {...field}
                error={Boolean(error)}
              />
            )}
            name="name"
            control={control}
            defaultValue={data.tableName}
            rules={{
              required: true,
              validate: handleNameValidate,
            }}
          />
        </FormControl>
        {queryGroups && (
          <FormControl fullWidth>
            <Controller
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  error={getErrorMessage(error)}
                  label={queryGroupLabel}
                  innerRef={ref}
                  placeholder={noQueryGroupSelected}
                  options={options}
                  isClearable
                />
              )}
              name="queryGroupId"
              control={control}
              defaultValue={data.queryGroupId}
            />
          </FormControl>
        )}
        <PermissionTable />
        {definition_code ? (
          <>
            <FormLabel>Definition</FormLabel>
            <CodeBlock
              value={definition_code}
              isCopy
              language="sql"
            ></CodeBlock>
          </>
        ) : null}
      </Box>
    );
  },
);
