import { IStaticRouteConfig } from "core";

import { RoutePaths } from "../../../routes";
import { DatabasePageComponent } from "./component";
import { route as CustomQueryPageRoute } from "./customQuery";
import { route as WorkflowPageRoute } from "./workflow";

export const route: IStaticRouteConfig = {
  Component: DatabasePageComponent,
  auth: true,
  isAdmin: true,
  routes: {
    [RoutePaths.DatabaseWorkflow]: WorkflowPageRoute,
    [RoutePaths.DatabaseCustomQuery]: CustomQueryPageRoute,
    [RoutePaths.DatabaseCustomQueryGet]: CustomQueryPageRoute,
  },
};
