import {
  ComponentProps,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  memo,
  useEffect,
  useState,
} from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent, { DialogContentProps } from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";

import { IconNameType } from "elementTypes/common/MuiIcon";
import Button from "../../../common/Button";
import { FormOptions, HookForm } from "../../../common/HookForm";
import { useHookFormContext } from "../../../common/HookForm/utils";
import IconButton from "../../../common/IconButton";

import { DialogWrapperProvider } from "./DialogWrapperContext";
import { useStyles } from "./style";
import { useDialogTranslation } from "./translation";

type OwnProps = {
  paperClass?: string;
  actionsClass?: string;
  title: ReactNode;
  formOptions?: FormOptions;
  draggable?: boolean;
  disableBackdropClick?: boolean;
  // if DialogContent is a form
  isForm?: boolean;
  // if form dynamically changed
  hasChanges?: boolean;
  contentProps?: DialogContentProps;
};

type DialogButtons = {
  cancelTitle?: string;
  submitTitle?: string;
  submitDisabled?: boolean;
  processing?: boolean;
  submitButtonProps?: ComponentProps<typeof Button>;
  cancelIcon?: IconNameType;
};

type DialogContentText = {
  contentText?: string | ReactNode;
};
type HookFormSubmit = {
  handleSubmit: (data: Record<string, any>) => void;
};

type DefaultSubmit = {
  handleSubmit?: (e: MouseEvent) => void;
};

type DialogEvents = {
  handleClose: (
    event: Record<string, unknown>,
    reason?: "backdropClick" | "escapeKeyDown",
  ) => void;
} & (HookFormSubmit | DefaultSubmit);

export type DialogWrapperProps = Omit<DialogProps, "title"> &
  DialogButtons &
  DialogEvents &
  OwnProps &
  DialogContentText & {
    subActions?: ReactNode;
  };

export const PaperComponent = (props: PaperProps & PropsWithChildren) => (
  <Draggable
    handle="#draggable-dialog-title"
    cancel={'[class*="MuiDialogContent-root"]'}
  >
    <Paper {...props} />
  </Draggable>
);

export const DialogWrapper = memo<DialogWrapperProps>(
  ({
    open,
    children,
    submitDisabled,
    submitTitle,
    cancelTitle,
    contentText,
    title,
    paperClass,
    actionsClass,
    isForm = false,
    formOptions,
    keepMounted = false,
    subActions,
    draggable,
    processing,
    hasChanges,
    disableBackdropClick,
    handleClose,
    handleSubmit,
    submitButtonProps,
    contentProps,
    cancelIcon,
    ...rest
  }) => {
    const {
      classes: {
        root,
        content,
        formClass,
        handlerIcon,
        displayCenter,
        title: titleClass,
      },
    } = useStyles();
    const { moveTooltip } = useDialogTranslation();

    const handleDialogClose = (
      event: Record<string, unknown>,
      reason: "backdropClick" | "escapeKeyDown",
    ) => {
      if (!(reason === "backdropClick" && disableBackdropClick)) {
        handleClose(event, reason);
      }
    };

    const contentDialog = (
      <>
        <DialogTitle
          id="dialog-title"
          className={`${displayCenter} ${titleClass}`}
        >
          {draggable && (
            <>
              <IconButton
                icon="zoom_out_map"
                id="draggable-dialog-title"
                tooltip={moveTooltip}
                className={handlerIcon}
                disableRipple={true}
                placement="top"
              />
            </>
          )}
          {title}
        </DialogTitle>
        <DialogContent
          className={content}
          id="dialog-content"
          {...contentProps}
        >
          {contentText && (
            <DialogContentText
              sx={{ pb: 1 }}
              component={typeof contentText === "string" ? "p" : "div"}
            >
              {contentText}
            </DialogContentText>
          )}
          <DialogWrapperProvider value={{ onSubmit: handleSubmit! }}>
            {children}
          </DialogWrapperProvider>
        </DialogContent>
        <DialogActions className={actionsClass} id="dialog-actions">
          {cancelTitle && (
            <Button
              label={cancelTitle}
              onClick={handleClose}
              color="secondary"
              iconRight={cancelIcon}
            />
          )}
          {subActions}
          {submitTitle &&
            (isForm ? (
              <SubmitButton
                label={submitTitle}
                disabled={submitDisabled}
                processing={processing}
                hasChanges={hasChanges}
                buttonProps={submitButtonProps}
              />
            ) : (
              <Button
                label={submitTitle}
                onClick={handleSubmit}
                disabled={submitDisabled}
                processing={processing}
                color="primary"
                {...submitButtonProps}
              />
            ))}
        </DialogActions>
      </>
    );

    return (
      <Dialog
        {...rest}
        open={open}
        onClose={handleDialogClose}
        aria-labelledby={draggable ? "draggable-dialog-title" : "dialog-title"}
        className={root}
        classes={{ paperScrollPaper: paperClass }}
        keepMounted={keepMounted}
        PaperComponent={draggable ? PaperComponent : undefined}
        TransitionProps={{
          mountOnEnter: true,
          timeout: 0,
        }}
      >
        {isForm ? (
          <HookForm
            className={formClass}
            onSubmit={handleSubmit as (data: Record<string, any>) => void}
            formOptions={formOptions}
          >
            {contentDialog}
          </HookForm>
        ) : (
          contentDialog
        )}
      </Dialog>
    );
  },
);

type SubmitProps = {
  label: string;
  disabled?: boolean;
  processing?: boolean;
  hasChanges?: boolean;
  buttonProps?: ComponentProps<typeof Button>;
};

const SubmitButton = memo<SubmitProps>(
  ({ label, disabled, processing, hasChanges, buttonProps }) => {
    const {
      formState: { isSubmitting, isDirty },
    } = useHookFormContext();

    const [isDisabled, setDisabled] = useState<boolean>(disabled || false);
    /*
     * Will set `isDisabled` to false after a user interacted with any of the inputs.
     */
    useEffect(() => {
      if (!isDirty !== isDisabled) {
        if (disabled === false && !isDirty) {
          setDisabled(false);
        } else {
          setDisabled(!isDirty);
        }
      }
    }, [isDirty]);

    /*
     * During form submitting will set `isDisabled` to true.
     */

    useEffect(() => {
      setDisabled(isSubmitting);
    }, [isSubmitting]);

    /*
     * Update `isDisabled` value only if disabled prop is defined
     * and the prop has been changed.
     */
    useEffect(() => {
      if (disabled !== undefined && disabled !== isDisabled) {
        setDisabled(disabled);
      }
    }, [disabled]);

    return (
      <Button
        label={label}
        disabled={hasChanges ? false : isDisabled}
        processing={processing}
        color="primary"
        type="submit"
        {...buttonProps}
      />
    );
  },
);
