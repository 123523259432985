import { createUseTranslation } from "core/session/translation";

export const echartsTranslation = {
  en: {
    saveAsImageTooltip: "Save As Image",
    dataViewTooltip: "Data View",
    restoreTooltip: "Clear Selection",
    dataZoomTooltip: "Area zooming",
    dataZoomBackTooltip: "Restore area zooming",
    magicTypeTooltip: "Magic Type",
    backgroundColorLabel: "Background Color",
    titleTextStyleColorLabel: "Title Color",
    legendTextStyleColorLabel: "Legend Color",
    themeTitle: "Theme",
    defaultBtn: "Default",
    customBtn: "Custom",
    colorsTitle: "Colors",
    addColorTooltip: "Add Color",
    toolboxTitle: "Toolbox",
    doubleClickTooltip: "Thema: '**'. Double click to customize",
    deleteTooltip: "Delete",
    magicTypeLineTooltip: "Switch to Line Chart",
    magicTypeBarTooltip: "Switch to Bar Chart",
    magicTypeStackTooltip: "Stack",
    magicTypeTiledTooltip: "Tile",
    legendShowLabel: "Legend Setup",
    topLabel: "Top",
    endLabel: "End",
    bottomLabel: "Bottom",
    startLabel: "Start",
    hiddenLabel: "Hide Legend",
    verticalLabel: "Vertical",
    horizontalLabel: "Horizontal",
    placementLabel: "Placement",
    orientationLabel: "Orientation",
    legendLabelsLabel: "Legend Labels",
    editLegendLabel: "Legend for",
    cancelButton: "Cancel",
    editButton: "Edit",
    deleteWarningMessage: "Are you sure you want to delete the legend label?",
    configErrorTitle: "Config Error",
    errorTitle: "Error",
    queryNameError: "Query Name is required",
    limitTitle: "Data Display Limit",
    limitLabel: "Limit To:",
    limitItemLabel: "items",
  },
};

export const useEchartsTranslation = createUseTranslation(echartsTranslation);
