import { IDefaultElement } from "core/types/element";
import { MultipleFileInputConfig } from "../types";

export const defaultElement: IDefaultElement<MultipleFileInputConfig> = {
  i18n: {
    en: {
      label: "",
    },
  },
  config: {
    accessGroupName: "public",
    typeGroupName: "image",
  },
};
