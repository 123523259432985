import { fold, left } from "fp-ts/Either";
import { pipe } from "fp-ts/lib/function";
import * as t from "io-ts";
import { PathReporter } from "io-ts/lib/PathReporter";

export type ErrorHint = string | number | symbol;

export type ErrorsReport<T = ErrorHint> = {
  key: string;
  hint: string;
  hintKey: T;
  actual: unknown;
};

function isValidNumber(str: string): boolean {
  const num = parseFloat(str);
  return !isNaN(num);
}

// Utility function to extract the error path
const getKeyPath = (context: t.Context): string =>
  context.reduce((accumulator, current, index) => {
    const isIntersectionOrUnion =
      current.type instanceof t.IntersectionType ||
      current.type instanceof t.UnionType;
    // Skip the first empty key or any indices of Intersection/Union types

    const keyInvalid = !current.key?.length || isValidNumber(current.key);

    if (index === 0 || (isIntersectionOrUnion && keyInvalid)) {
      return accumulator;
    }
    const keyPath = `${accumulator}${accumulator ? "." : ""}${current.key}`;
    return keyPath;
  }, "");

// Utility function to process errors
export const processErrors = (errors: t.Errors): Record<string, ErrorsReport> =>
  errors.reduce<Record<string, ErrorsReport>>((acc, error) => {
    const key = getKeyPath(error.context);
    const lastContext = error.context[error.context.length - 1];

    acc[key] = {
      key,
      hintKey: lastContext?.key ?? "",
      hint: error.message ?? lastContext?.type.name ?? "",
      actual: lastContext?.actual,
    };
    return acc;
  }, {});

export const errorsReport = <A>(
  v: t.Validation<A>,
): Record<string, ErrorsReport> =>
  pipe(
    v,
    fold(processErrors, () => ({}) as Record<string, ErrorsReport>),
  );

export const reportWithPath = <A>(v: t.Validation<A>) => {
  return pipe(
    v,
    fold(
      (errors) => PathReporter.report(left(errors)).join("\n"),
      () => "",
    ),
  );
};
