import { IDefaultElement } from "core/types";

import { ContainerConfig } from "../types";

export const defaultElement: IDefaultElement<ContainerConfig> = {
  children: {
    content: {
      elements: [],
    },
  },
};
