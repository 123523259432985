import Component from "./component";
//import { /*DefaultButtonGroupEditor, */ defaultElement } from "./editor";
import { DefaultButtonGroupChildren, DefaultButtonGroupConfig } from "./types";

export default {
  //defaultElement,
  name: "default_button_group",
  component: Component,
  // editable: true,
  configType: DefaultButtonGroupConfig,
  childrenType: DefaultButtonGroupChildren,
  // editorComponent: DefaultButtonGroupEditor,
};
