import { useStyles } from "./styles";

{
  // Previous styles, now we are using CI colors
  // https://cybertec.atlassian.net/wiki/spaces/MAR/pages/2082045953/Corporate+Design#Farben
  /* <style type="text/css">
      .st0{fill:#41404A;}
      .st1{fill:#4EC1EC;}
    </style> */
}

export const VertikalLogo = () => {
  const {
    classes: { st0, st1 },
    cx,
  } = useStyles();
  return (
    <svg
      version="1.1"
      viewBox="0 0 763.1 546.16"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
    >
      <path
        d="M566.05 327.7L436.3 228.44l-42.4 31.5 91.23 67.76zM264.5 163.85L326.66 210l42.78-32.73-16.24-12.42L566.05 0h-78.9L338.4 109.48z"
        className={cx(st0, "animate")}
      />
      <path
        d="M197.05 0L326.3 100.1l42.84-31.52L275.94 0zm200.92 173.98l-59.02 45.16-141.9 108.56h80.92L498.6 163.85l-61.55-45.3-43.47 33.67 16.33 12.64z"
        className={cx(st1, "animate")}
      />
      <path
        d="M18.35 433.43C6.1 445.1 0 460.43 0 479.45c0 19.14 6.18 35 18.54 47.54s27.98 18.83 46.87 18.83h73.4V523.2H65.4c-12.42 0-22.66-3.87-30.7-11.6s-12.07-17.8-12.07-30.24c0-12.55 4.02-22.82 12.07-30.8s18.3-11.98 30.7-11.98h73.4v-22.63H65.4c-19.14 0-34.83 5.83-47.06 17.5zm141.1-17.5l66.18 86.52v43.36h22.44v-42.97l64.46-86.9h-29.28l-45.83 63.9-48.87-63.9zm286.3 12.37c-9.64-8.24-21.74-12.36-36.32-12.36h-81.47V545.8h22.63V438.56h62.45c7.48 0 13.75 2.16 18.83 6.47 5.07 4.3 7.6 10.14 7.6 17.5s-2.54 13.25-7.6 17.68c-5.07 4.44-11.35 6.66-18.83 6.66h-36.54v22.44h32.92c14.58 0 26.68-4.06 36.32-12.17 10.4-8.75 15.6-20.22 15.6-34.42.01-14.32-5.2-25.8-15.58-34.42zm126.97 40.3h-95.2v77.2h112.2v-22.63h-89.6v-31.94h72.58zm-10.5-30.04h27.47v-22.63H477.5v22.63h22.62zM763.1 546l-55.06-56.38-15.76 16.8 38.3 39.58zm-70.55-90.13l15.72 16.54 54.83-56.48h-31.57z"
        className={st0}
      />
      <path
        d="M669.8 481.3l-63.26 64.87h32.24L700 480.9l-62.05-65.28h-31.43z"
        className={st1}
      />
    </svg>
  );
};
