import { memo } from "react";
import classNames from "classnames";
import Markdown from "react-markdown";
import { LayoutProps } from "core";

import useStyles from "./styles";
import { useFooterTranslation } from "./translations";

export const DEFAULT_MENU_WIDTH = "240px";
export const DEFAULT_TOOLBAR_VARIANT = "dense";

type IProps = {
  openState: boolean;
  layout?: LayoutProps["appMetadata"]["release"]["definition"]["layout"];
};

export const Footer = memo<IProps>(({ openState, layout }) => {
  const menuWidth = layout?.definition.menu?.width ?? DEFAULT_MENU_WIDTH;
  const { classes } = useStyles({ menuWidth });

  const footerClassNames = classNames(classes.footer, classes["content-left"], {
    [classes.contentShift]: openState,
    [classes["contentShift-left"]]: openState,
  });

  const { defaultFooterText } = useFooterTranslation();

  const textSource = layout?.definition.footer?.text ?? defaultFooterText;

  return (
    <footer className={footerClassNames}>
      <Markdown>{textSource}</Markdown>
    </footer>
  );
});

Footer.displayName = "Footer";
