import Component from "./container";
import {
  AdvancedConditionalEditor,
  defaultElement,
  editorMetadata,
} from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import {
  AdvancedConditionalChildren,
  AdvancedConditionalConfig,
  advancedConditionalSelectors,
} from "./types";

export default {
  name: "advanced_conditional",
  component: Component,
  editable: true,
  configType: AdvancedConditionalConfig,
  childrenType: AdvancedConditionalChildren,
  reduxModuleFactory,
  defaultElement,
  editorMetadata,
  editorComponent: AdvancedConditionalEditor,
  selectorTypes: advancedConditionalSelectors,
};
