import { ValidationErrorObject } from "utils/io-ts/validate";
import {
  GetModule,
  IElement,
  IElementModel,
  IElementProps,
  IElementType,
  IReduxModule,
  IReduxModuleContext,
} from "../types";

type State = {
  element: IElement | null;
  elementModel: IElementModel | null;
  elementProps: IElementProps;
  module: IReduxModule | null;
  childrenReduxModuleContext: IReduxModuleContext | null;
  elementType: IElementType | null;
  error: null | Error | ValidationErrorObject;
  getModule: GetModule | null;
  unmount: (() => void) | null;
};

type UpdateElementAction = {
  type: "updateElement";
  payload: {
    element: IElement;
    elementProps: IElementProps;
    elementModel: IElementModel;
    module: IReduxModule | null;
    childrenReduxModuleContext: IReduxModuleContext;
    elementType: IElementType;
    getModule: GetModule;
    unmount: () => void;
  };
};

type ErrorAction = {
  type: "error";
  payload: {
    error: any;
    elementModel: IElementModel;
    elementProps: IElementProps;
    elementType: IElementType | null;
  };
};

type Action = UpdateElementAction | ErrorAction;

export const initialState: State = {
  elementModel: null,
  element: null,
  elementProps: {},
  unmount: null,
  module: null,
  childrenReduxModuleContext: null,
  getModule: null,
  elementType: null,
  error: null,
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "updateElement":
      return {
        ...state,
        ...action.payload,
        error: null,
      };
    case "error":
      return {
        ...state,
        error: action.payload.error,
        elementModel: action.payload.elementModel,
        elementProps: action.payload.elementProps,
        elementType: action.payload.elementType,
        unmount: null,
      };
    default:
      throw new Error(`No type defined for action ${(action as Action).type}`);
  }
}
