import { FC } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Handle, NodeProps } from "reactflow";

import { IconNameType, MuiIcon } from "elementTypes/common/MuiIcon";
import { hexToHSLA } from "utils/colors";

export const CustomNode: FC<
  NodeProps<{
    label: string;
    icon: IconNameType;
    iconPosition: "left" | "right";
    href: string;
    sourcePosition?: null;
    targetPosition?: null;
  }>
> = (props) => {
  const theme = useTheme();
  const boxShadow = hexToHSLA(theme.palette.primary.main, 0.3);

  return (
    <Card
      sx={{
        display: "flex",
        width: 220,
        height: 110,
        borderRadius: 3,
        boxShadow: `0 4px 8px ${boxShadow}`,
        overflow: "hidden",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          transform: "translateY(-2%)",
          boxShadow: `0 8px 16px ${boxShadow}`,
        },
      }}
      elevation={4}
    >
      <CardActionArea
        href={props.data.href}
        target="_blank"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            flexDirection:
              props.data.iconPosition === "left" ? "row-reverse" : "row",
          }}
        >
          <Typography
            component="h3"
            variant="subtitle1"
            sx={{ color: "text.secondary" }}
          >
            {props.data.label}
          </Typography>
          <MuiIcon icon={props.data.icon} fontSize="large" color="primary" />
        </CardContent>
      </CardActionArea>
      {/* Handle for connecting edges */}
      {props.sourcePosition && props.data.sourcePosition !== null && (
        <Handle
          type="source"
          position={props.sourcePosition}
          style={{ background: theme.palette.grey[500] }}
          isValidConnection={(connection) =>
            connection.target !== connection.source
          }
        />
      )}
      {props.targetPosition && props.data.targetPosition !== null && (
        <Handle
          type="target"
          position={props.targetPosition}
          style={{ background: theme.palette.grey[500] }}
        />
      )}
    </Card>
  );
};
