import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Container",
      description: "",
    },
    de: {
      description: "",
    },
  },
  defaultSize: {
    width: 4,
    height: 3,
  },
};
