import { CSSProperties, memo } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import IconButton from "elementTypes/common/IconButton";

import { useTableEditorTranslation } from "../../../translation";

import { Reference } from "./References";

type Props = {
  onClick: (params: Reference) => void;
  onDelete: (name: string) => void;
  style?: CSSProperties;
} & Reference;

export const ReferenceItem = memo<Props>(
  ({ style, onClick, onDelete, ...props }) => {
    const { editReferenceTooltip, deleteReferenceTooltip } =
      useTableEditorTranslation();
    const { fieldName, targetView, referencedColumnName } = props;
    const handleEditClick = () => onClick(props as Reference);
    const handleDelete = () => onDelete(fieldName);

    return (
      <ListItem divider style={style}>
        <ListItemText
          primary={fieldName}
          primaryTypographyProps={{ variant: "subtitle2", noWrap: true }}
          secondaryTypographyProps={{ color: "primary" }}
          secondary={
            <>
              {targetView}.
              <Typography color="secondary" component="span">
                {referencedColumnName}
              </Typography>
            </>
          }
        />

        <ListItemIcon>
          <IconButton
            icon="delete_outline"
            color="error"
            onClick={handleDelete}
            tooltip={deleteReferenceTooltip}
          />
          <IconButton
            icon="edit"
            onClick={handleEditClick}
            tooltip={editReferenceTooltip}
            edge="end"
          />
        </ListItemIcon>
      </ListItem>
    );
  },
);
