import { memo, useMemo } from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { IMenuItem, LayoutDefinition } from "core";
import { useSessionContext } from "core/session";

import { Logo } from "../Logo/Logo";

import { GenerateMenuList } from "./MenuList";

export type MenuProps = {
  openState: boolean;
  uiName: string;
  menu: IMenuItem[];
  classes: any;
  menuItemSelected: string;
  logo?: LayoutDefinition["logo"];
};

type MenuList = Pick<MenuProps, "menu" | "uiName" | "menuItemSelected"> & {
  className?: string;
};

export const MenuList = memo<MenuList>(
  ({ className, menu, uiName, menuItemSelected }) => {
    const { language } = useSessionContext();
    return (
      <div className={className}>
        {GenerateMenuList(menu, uiName, menuItemSelected, language)}
      </div>
    );
  },
);

export const Menu = memo<MenuProps>(
  ({ classes, logo, menu, openState, uiName, menuItemSelected }) => {
    const currentMenu = useMemo(
      () => menu.sort((a, b) => a.order - b.order),
      [menu],
    );

    return (
      <Drawer
        variant="persistent"
        anchor={"left"}
        open={openState}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div>
          <div className={classes.drawerHeader}>
            {logo && (
              <a
                href={logo.url}
                className={classes.drawerLogo}
                target="_blank"
                rel="noreferrer"
              >
                {logo?.path || logo?.fileName ? null : <Logo />}
              </a>
            )}
            <Divider />
          </div>
          <MenuList
            className={classes.rootMenu}
            menu={currentMenu}
            uiName={uiName}
            menuItemSelected={menuItemSelected}
          />
        </div>
      </Drawer>
    );
  },
);
