import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Grid",
      description: "Contains and aligns other elements",
    },
    de: {
      description: "Beinhaltet andere elemente",
    },
  },
  defaultSize: {
    width: 12,
    height: 3,
  },
};
