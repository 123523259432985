import { IDefaultElement } from "core/types/element";

import { UntransformedNumberInputConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedNumberInputConfig> = {
  i18n: {
    en: {
      label: "",
    },
  },
};
