import { memo, useCallback, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import IconButton from "elementTypes/common/IconButton";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { Role } from "queries/admin/types";
import { getApiError } from "queries/utils";
import { RoutePaths } from "staticPages/routes";
import { useSnackbar } from "utils/hooks";

import Button from "../../../../elementTypes/common/Button";
import { LoadingComponent } from "../../../../layouts/common/Loading";
import { useDeleteRole, useRoles } from "../../../../queries/admin";
import { TableRow } from "../../common";
import { Align, Table } from "../../common/components/Table";
import useStyles from "../../styles";

import { useRolePagesTranslation } from "./translation";

type Row = Role & { handleDelete: (roleName: string | null) => void };

const Row = memo<Row>(({ name, isAdmin, handleDelete }) => {
  const t = useRolePagesTranslation();
  const {
    classes: { inlineAndEndFlex },
  } = useStyles();

  const onDelete = () => handleDelete(name);

  return (
    <TableRow key={name} rowId={name}>
      <Typography>{name}</Typography>
      {isAdmin ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <MuiIcon color="info" icon="gpp_good" fontSize="medium" />
        </Box>
      ) : (
        <span />
      )}
      <Grid className={inlineAndEndFlex}>
        <IconButton
          icon="delete_outline"
          color="error"
          tooltip={t.deleteTooltip}
          onClick={onDelete}
        />
      </Grid>
    </TableRow>
  );
});

export const RolesPage = memo(() => {
  const { data, isInitialLoading, isFetching, error, refetch } = useRoles();

  const t = useRolePagesTranslation();
  const showSnackbar = useSnackbar();

  const [deleteInfo, setDeleteInfo] = useState<string | null>(null);

  const deleteRole = useDeleteRole({
    onSuccess: () => {
      refetch();
      showSnackbar(t.deletedMessage, "success");
    },
    onError: (err) => {
      const msg = getApiError(err);
      showSnackbar(msg, "error");
    },
  });

  const handleDeleteRole = useCallback(() => {
    if (deleteInfo) {
      deleteRole.mutate({ roleName: deleteInfo });
    }
    setDeleteInfo(null);
  }, [deleteInfo, deleteRole]);

  const handleCloseDialog = () => {
    setDeleteInfo(null);
  };

  const rows = useMemo(
    () =>
      data?.map((role) => (
        <Row
          key={role.name}
          handleDelete={setDeleteInfo}
          name={role.name}
          isAdmin={role.isAdmin}
        />
      )),
    [data],
  );

  const tableHeaders: { align: Align; title: string; name: string }[] = [
    { title: t.tableNameLabel, align: "left" as Align },
    { title: t.isAdmin, align: "center" as Align },
    { title: t.tableActionLabel, align: "right" as Align },
  ].map((config) => ({
    ...config,
    name: config.title.toLowerCase(),
  }));

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">{t.pageTitle}</Typography>
        <Button
          color="primary"
          href={RoutePaths.RoleCreate}
          iconLeft="add"
          label={t.createBtnLabel}
        />
      </Box>
      <Box>
        {isInitialLoading ? (
          <LoadingComponent />
        ) : (
          <Table
            alignment={tableHeaders}
            rows={rows}
            headers={tableHeaders}
            onDataReload={refetch}
            loading={isFetching}
            error={error?.message}
          />
        )}
      </Box>
      <DialogWrapper
        open={!!deleteInfo}
        title={t.dialogTitle}
        contentText={`${t.dialogContentText} ${deleteInfo}?`}
        submitTitle={t.DeleteButton}
        handleSubmit={handleDeleteRole}
        cancelTitle={t.CancelButton}
        handleClose={handleCloseDialog}
        fullWidth={true}
      />
    </Box>
  );
});
