import { createUseTranslation } from "core/session/translation";

export const arrayTextInputEditorTranslation = {
  en: {
    stylingTitle: "Styling",
    labelLabel: "Label",
  },
};

export const useArrayTextInputEditorTranslation = createUseTranslation(
  arrayTextInputEditorTranslation,
);
