import { ElementGroup, IRawElementType } from "core";

import Component from "./container";
import { ColorInputEditor, defaultElement, editorMetadata } from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import {
  ColorInputConfig,
  ColorInputTranslationKeys,
  colorInputSelectors,
} from "./types";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_color_input",
  group: ElementGroup.Inputs,
  editable: true,
  component: Component,
  configType: ColorInputConfig,
  selectorTypes: colorInputSelectors,
  translationKeys: ColorInputTranslationKeys,
  editorComponent: ColorInputEditor,
  defaultElement,
  editorMetadata,
};

export default elementType;
