import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Pie Chart",
      description: "Visualizing data with a pie chart",
    },
    de: {
      title: "Tortendiagramm",
      description: "Daten in einem Tortendiagramm visualisieren",
    },
  },
  defaultSize: {
    width: 4,
    height: 4,
  },
};
