export enum Sizes {
  large = "large",
  medium = "medium",
  small = "small",
}

export enum Variants {
  contained = "contained",
  outlined = "outlined",
}
