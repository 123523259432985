import { stringToDateObject } from "./string";

export function getDateValue(
  value: string | Date | null | undefined,
): Date | null {
  return value
    ? typeof value === "string"
      ? stringToDateObject(value)
      : value
    : null;
}

export function getTotalTimezoneOffset(date: Date, timezone: number) {
  const timezoneOffset = date.getTimezoneOffset() + timezone * 60;
  // in milliseconds
  return timezoneOffset * 60 * 1000;
}
