import { connect } from "react-redux";

import { selectors } from "../reduxModule";

import { SessionProvider as SPComponent } from "./component";

export const mapStateToProps = (state: any) => ({
  currentLanguage: selectors.currentLanguage(state),
});

export const SessionProvider = connect(mapStateToProps)(SPComponent);
