import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";

import { getDisabledValueFromConfig } from "elementTypes/common/utils";
import { TextInput } from "../../types";

export function buildSelectors(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
  element: TextInput,
) {
  const value = dataSource.createFieldValueSelector(fieldPath, {
    defaultValue: null,
  });

  const errors = dataSource.createFieldErrorSelector(fieldPath);

  const touched = dataSource.createFieldTouchedSelector(fieldPath);

  const disabled = (state?: any) =>
    dataSource.isReadOnly || getDisabledValueFromConfig(state, element.config);

  const required = () =>
    dataSource.createFieldRequiredSelector(fieldPath) ??
    element.config.nullable === false;

  return {
    value,
    errors,
    touched,
    disabled,
    required,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
