import { memo, useState } from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Controller } from "react-hook-form";
import { DEFAULT_LANGUAGE_CODE, Language, Translation } from "core";
import { TranslationEditor } from "core/editor";
import { useSessionContext } from "core/session";
import BackButton from "elementTypes/common/BackButton";
import Button from "elementTypes/common/Button";
import { useHookFormContext } from "elementTypes/common/HookForm/utils.ts";
import { StyledTypography } from "elementTypes/common/StyledTypography";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher.tsx";
import { useAppEdition } from "queries/admin";
import { PAGES_LIMIT } from "services/api/constants.ts";
import { useCommonStaticPagesTranslation } from "staticPages/admin/pages/apps/translation.ts";
import { RoutePaths } from "staticPages/routes";
import useStyles from "../styles.ts";
import { IUIEditForm, TForm } from "../types.ts";
import { GeneratePages } from "./GeneratePages.tsx";

const defaultI18n = {
  [DEFAULT_LANGUAGE_CODE]: { label: "", description: "" },
};

export const EditAppForm = memo<TForm>(({ uiMetadata, uiName }) => {
  const translation = useCommonStaticPagesTranslation();
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);
  const {
    classes: { root },
  } = useStyles();

  const { data: isEnterprise } = useAppEdition();

  const { release } = uiMetadata;

  const {
    control,
    formState: { isSubmitting, isValid },
    setValue,
    watch,
  } = useHookFormContext<IUIEditForm>();

  const changeTranslation =
    (cb: (value: Translation<string>) => void) =>
    (value: Translation<string>) => {
      setValue("i18n", value as Translation<"label" | "description">);
      cb(value);
    };

  const pagesNumber = Object.keys(
    uiMetadata?.release?.definition.pages ?? {},
  ).length;

  const pagesLimited = !isEnterprise && pagesNumber >= PAGES_LIMIT;

  // TODO: please, fix me
  // There is a need to refactor Translation component inside hook forms everywhere
  // We can use nesting input names to edit translation, e.g:
  // <Controller
  //   render={({ field }) => (
  //    <TranslationEditor
  //      ...field
  //      ...
  //    />
  //   )}
  //   name=`i18n.${language.code}.label`
  //   control={control}
  // />

  if (release) {
    const i18n = watch("i18n") ?? release?.definition?.i18n;
    return (
      <>
        <Box display="flex" alignItems="center" gap={1} pb={1}>
          <BackButton isIcon href={RoutePaths.Apps} />
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h5">{translation.editAppTitle}</Typography>
            {uiName && (
              <StyledTypography
                text={` — ${uiName}`}
                color="primary"
                fitContent
                typographyProps={{
                  variant: "h5",
                }}
                tooltip={translation.readonlyUITooltip}
                boxProps={{
                  marginLeft: "auto",
                }}
              />
            )}
          </Box>
        </Box>

        <Paper variant="outlined" className={root}>
          {/* TODO: uncomment when uiName is editable */}
          {/* <Grid item={true} xs={12}>
              <TextField
                name="name"
                disabled={true}
                label="Application name"
                fullWidth={true}
                value={uiName ?? ""}
                size="small"
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Grid> */}
          <Stack direction="row" width="100%" spacing={3}>
            <Stack direction="column" spacing={1} flex="1">
              <FormControl component="fieldset" fullWidth={true}>
                <FormLabel component="label">
                  <Typography variant="caption">
                    {translation.languageLabel}
                  </Typography>
                </FormLabel>

                <LanguageSwitch
                  language={lang}
                  changeLanguage={setLang}
                  colorVariant="dark"
                  fullWidth={true}
                />
              </FormControl>
              <Controller
                render={({ field: { ref, onChange, ...inputProps } }) => (
                  <TranslationEditor
                    translationKey="label"
                    i18n={i18n}
                    label="Label"
                    language={lang}
                    {...inputProps}
                    inputRef={ref}
                    changeTranslation={changeTranslation(onChange)}
                  />
                )}
                name="i18n"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={release?.definition?.i18n}
              />
            </Stack>
            <Stack flex="2">
              <Controller
                render={({
                  field: { ref, onChange, ...inputProps },
                  fieldState: { error },
                }) => (
                  <TranslationEditor
                    translationKey="description"
                    i18n={i18n}
                    label="Description"
                    language={lang}
                    {...inputProps}
                    inputRef={ref}
                    markdown
                    changeTranslation={changeTranslation(onChange)}
                    error={Boolean(error)}
                  />
                )}
                name="i18n"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={release?.definition?.i18n ?? defaultI18n}
              />
            </Stack>
          </Stack>
          <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
          <GeneratePages uiMetadata={uiMetadata} isEnterprise={isEnterprise} />
          <Divider sx={{ my: 1.5 }} />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              disabled={isSubmitting}
              href={RoutePaths.Apps}
              label="Cancel"
              color="primary"
            />
            <Button
              color="secondary"
              disabled={isSubmitting || !isValid || pagesLimited}
              processing={isSubmitting}
              iconRight="forward"
              type="submit"
              label="Edit"
            />
          </Box>
        </Paper>
      </>
    );
  } else {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }
});
