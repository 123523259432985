import { IRawElementType } from "core";

import Component from "./container";
import { NumberFieldEditor, defaultElement, editorMetadata } from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import { NumberFieldConfig, numberFieldSelectors } from "./types";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_number_field",
  component: Component,
  editable: true,
  configType: NumberFieldConfig,
  defaultElement,
  editorComponent: NumberFieldEditor,
  editorMetadata,
  selectorTypes: numberFieldSelectors,
};

export default elementType;
