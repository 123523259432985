import { Theme } from "@mui/material";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  topMargin: {
    marginTop: theme.spacing(),
  },
  controlClass: {
    flexDirection: "row",
    marginBottom: theme.spacing(),

    "& [class*='MuiFormLabel-root']": {
      marginRight: theme.spacing(),
    },
  },
}));
