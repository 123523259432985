import { createTypeSafeContext } from "utils/createTypeSafeContext";

import { TableHeaderCell } from "../default_table_header_cell/types";

import { IOrderIndex, OrderIndexed } from "./reduxModule";
import { IFilterGroup } from "./toolsPanel";
import { TableMetadata } from "./types";

export interface ITableContext {
  orderIndexed: OrderIndexed;
  getColOrder: (
    orderIndexed: OrderIndexed,
    col: TableHeaderCell,
  ) => IOrderIndex | null;
  changeOrder: (name: string, params: { multi: boolean }) => void;
  changeFilter: (filter: IFilterGroup) => void;
  metadata: TableMetadata;
  error: string | null;
  identifyingColumnName?: string;
  stateColumnName?: string;
  disableSort?: boolean;
}

const { Provider, Consumer, useTypeSafeContext } =
  createTypeSafeContext<ITableContext>();

export const useTableContext = useTypeSafeContext;
export const TableContextProvider = Provider;
export const TableContextConsumer = Consumer;
