import { IReduxModuleFactoryArgs } from "core";

import { Tabs } from "../types";

import { buildActions } from "./actions";
import { buildReducer } from "./reducer";
import { buildSelectors } from "./selectors";

export function reduxModuleFactory({
  path,
  element,
}: IReduxModuleFactoryArgs<Tabs>) {
  const scope = path.join(".");

  const { actions, types } = buildActions(scope);
  const reducer = buildReducer(types, element);
  const selectors = buildSelectors(path);

  return {
    actions,
    reducer,
    selectors,
  };
}

export * from "./types";

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
