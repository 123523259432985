import { memo } from "react";
import { Section, useElementEditorContext } from "core/editor";
import ActionConfigEditor from "elementTypes/common/ActionConfig";
import { ActionConfigsType } from "elementTypes/common/ActionConfig/types";
import { Spacer } from "elementTypes/common/Spacer";

import { UntransformedActionButtonConfig } from "../../types";
import { useActionButtonEditorTranslation } from "../translation";

export const ConfigComponent = memo(() => {
  const {
    elementModel: {
      config,
      config: { onTrigger },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedActionButtonConfig>();
  const { onActionTitle } = useActionButtonEditorTranslation();

  const handleActionChange = (newValue: ActionConfigsType) =>
    changeConfigValue("onTrigger", newValue);

  return (
    <Section title={onActionTitle} wrapped={true}>
      <Spacer vertical={1} />
      <ActionConfigEditor
        onActionConfigsChange={handleActionChange}
        actionConfigs={onTrigger as ActionConfigsType}
        elementConfig={config}
      />
    </Section>
  );
});
