import { parse } from "fp-ts/Json";
import { isRight } from "fp-ts/lib/Either";
import * as t from "io-ts";
import moment from "moment";
import { ErrorsReport } from "utils/io-ts/errorReporter";
import { lodash } from "utils/libs/lodash";

import { DECODE_UTILS, EXPRESSION_TAG } from "../constants";
import { ILocation } from "../router/reduxModule";
import { getDidYouMean } from "../utils/didYouMean";
import { getElementDisplay, getTranslatedText } from "../utils/element-utils";
import { getOrThrow } from "../utils/io-ts";

import { IElement, IElementModel, IElementProps } from "./element";
import { DEFAULT_LANGUAGE_CODE, Language } from "./i18n";
import { GetModule, IReduxModule } from "./redux";
import { IPage } from ".";

type GetCurrentLanguage = (state: any) => Language;

const EXPRESSION_TAG_LEN = EXPRESSION_TAG.length;

const VALIDATION_MSG = "Access to property";

export function isCustomExpression(o: unknown): o is string {
  return typeof o === "string" && o.startsWith(EXPRESSION_TAG);
}

export function getExpression<T = string>(value: string) {
  return value.slice(EXPRESSION_TAG_LEN) as T;
}

export function getPureExpression(value: string) {
  return value.slice(EXPRESSION_TAG_LEN).replace(/"/g, "");
}

export const parseQueryValue = (value: string) => {
  const res = parse(value);
  return isRight(res) ? res.right : value;
};

export function buildCustomExpressionValue(
  value: string | number | boolean | null,
): string {
  return EXPRESSION_TAG + String(value);
}

export const customExpression = <C extends t.Mixed>(codec: C) => {
  type T = t.TypeOf<C>;
  type TE = (state: any) => T;
  return new t.Type<TE, string, unknown>(
    "CustomExpression",
    (u): u is TE => isCustomExpression(u) || isRight(codec.decode(u)),
    (i: unknown, context: t.Context) => {
      const {
        props,
        getModule,
        getCurrentLanguage,
        element,
        location,
        page,
        id,
      } = (context[0].actual as any)[DECODE_UTILS];

      if (isCustomExpression(i)) {
        return validateExpression(
          getExpression(i),
          props,
          getModule,
          getCurrentLanguage,
          element,
          location,
          page,
          id,
          codec,
          context,
        );
      }
      const result = codec.validate(i, context);
      return isRight(result) ? t.success(() => i as T) : result;
    },
    codec.encode,
  );
};

/**
 * TODO:
 * see if the selector can track selector dependencies and return a cached value.
 * DONT TRY TO CACHE THE MODULE PROXY BECAUSE MODULES CAN HAVE THE SAME ID BUT CHANGE.
 */

// used to determine whether a module proxy is somehow part of the expression result
const IS_MODULE_PROXY = Symbol();

type TReducedLocation = Pick<ILocation, "pathname" | "queries">;

export function getCustomExpressionScopeLocation(
  location: ILocation,
): TReducedLocation {
  const queries = Object.keys(location.queries).reduce((result, key) => {
    const value = parseQueryValue(location.queries[key]);
    return {
      ...result,
      [key]: value,
    };
  }, {});

  return {
    pathname: location.pathname,
    queries,
  };
}

function DETECT_USAGE(libraryName: string) {
  return new Proxy(
    {},
    {
      get: (target, prop) => {
        const errorMessage = `
          ${VALIDATION_MSG} '${String(prop)}' of '${libraryName}' is not allowed. 
          Target: ${JSON.stringify(target)}
        `;
        throw new Error(errorMessage);
      },
    },
  );
}

function validateExpression<C extends t.Mixed>(
  value: string,
  props: IElementProps,
  getModule: GetModule,
  getCurrentLanguage: GetCurrentLanguage,
  element: IElement,
  fullLocation: ILocation,
  page: IPage,
  id: string,
  codec: C,
  context: t.Context,
) {
  type T = t.TypeOf<C>;

  const location = getCustomExpressionScopeLocation(fullLocation);

  const isFunction = isRight(codec.decode(() => null));

  if (!isFunction) {
    // see if the value can be cached
    // do not attempt this if the codec is a function
    // reason: the function body could use the elements - elements is a proxy
    // throwing an error on `get`
    // but as the function will be called by the element itself, the error
    // cannot be handled here
    try {
      const staticValue = getPropsOnlyValue(value, props, location);

      const result = codec.validate(staticValue, context);
      return isRight(result) ? t.success(() => staticValue as T) : result;
    } catch (error) {
      const msg = (error as Error).message?.toString();
      const cleanedMessage = msg.replace(/\s+/g, " ").trim();
      if (!cleanedMessage.startsWith(VALIDATION_MSG)) {
        throw error;
      }
    }
  }

  const funcBody = `'use strict'; return ${value}`;
  const expressionFunc = new Function(
    "props",
    "element",
    "elements",
    "location",
    "i18n",
    "_",
    "page",
    "moment",
    funcBody,
  );

  return t.success((state: any) => {
    const elementModule = getModule(id);
    const elementProxy = elementModule
      ? createModuleProxy(state, elementModule)
      : EMPTY_MODULE_PROXY;

    const val = expressionFunc(
      props,
      elementProxy,
      createElementsProxy(state, getModule, fullLocation),
      location,
      createI18nProxy(state, element, getCurrentLanguage),
      lodash,
      createPageProxy(page),
      moment,
    );

    // check if val contains element module
    // this most likely happens while editing a custom expression
    // if not caught early, this can potentially lead to infinite loops
    if (containsModule(val)) {
      throw new Error("Value must not contain module.");
    }

    const validation = codec.validate(val, context);

    return getOrThrow(validation, (errors: Record<string, ErrorsReport>) => {
      const message = `Invalid config value in expression return value for ${getElementDisplay(element)}.
            The whole config is:\n${JSON.stringify(element.config)}
          `;
      return {
        name: "Validation Error",
        message,
        errors,
      };
    });
  });
}

function getPropsOnlyValue(
  value: string,
  props: IElementProps,
  location: TReducedLocation,
) {
  const funcBody = `'use strict'; return ${value}`;
  return new Function(
    "props",
    "element",
    "elements",
    "location",
    "i18n",
    "_",
    "page",
    "moment",
    funcBody,
  )(
    props,
    DETECT_USAGE("element"),
    DETECT_USAGE("elements"),
    location,
    DETECT_USAGE("i18n"),
    DETECT_USAGE("lodash"),
    DETECT_USAGE("page"),
    DETECT_USAGE("moment"),
  );
}

export function createElementsProxy(
  state: any,
  getModule: GetModule,
  location: ILocation,
) {
  return new Proxy(
    {},
    {
      get: (_, prop) => {
        if (prop === Symbol.toStringTag) {
          return () => "Elements";
        }
        if (prop === "toJSON") {
          return () => "{}";
        }
        if (prop === "length") {
          return 0;
        }
        const module = getModule(prop.toString(), { location });
        return module ? createModuleProxy(state, module) : EMPTY_MODULE_PROXY;
      },
    },
  );
}

const EMPTY_MODULE_PROXY = new Proxy(
  {},
  {
    // TODO: make modules have a reference to the element, so we can say the module's element ID in the exception
    get: (_, prop) => {
      throw new Error(
        `Cannot read selector "${prop.toString()}". Module has no selectors`,
      );
    },
  },
);

function createModuleProxy(state: any, module: IReduxModule) {
  // TODO: make modules have a reference to the element, so we can say the module's element ID in the exception
  const keys = Object.keys(module.selectors || {});
  return new Proxy(
    {},
    {
      get: (_, prop) => {
        if (prop === Symbol.toStringTag) {
          return () => "Module";
        }
        if (prop === "toJSON") {
          return () => "{}";
        }
        if (prop === "length") {
          return Object.keys(module.selectors || {}).length;
        }
        if (prop === IS_MODULE_PROXY) {
          return true;
        }
        const selector = (module.selectors || {})[prop.toString()];
        if (!selector) {
          const didYouMean = getDidYouMean(
            Object.keys(module.selectors || {}),
            prop.toString(),
          );
          throw new Error(
            `Module has no selector named "${prop.toString()}".${didYouMean}`,
          );
        }
        return selector(state);
      },
      ownKeys: () => keys,
    },
  );
}

function createI18nProxy<Key extends keyof any>(
  state: any,
  element: IElementModel<any, any, Key>,
  getCurrentLanguage: GetCurrentLanguage,
) {
  if (!Object.keys(element.i18n).length) {
    return EMPTY_I18N_PROXY;
  }
  return new Proxy({}, {
    get: (_, prop: Key) => {
      if (prop === Symbol.toStringTag) {
        return () => "I18N";
      }
      if (prop === "toJSON") {
        return () => "{}";
      }
      if (prop === "length") {
        return Object.keys(element.i18n || {}).length;
      }
      const currentLanguage = getCurrentLanguage(state);
      const value = getTranslatedText(currentLanguage, element.i18n, prop);
      if (value === undefined) {
        const didYouMean = getDidYouMean(
          Object.keys(element.i18n[DEFAULT_LANGUAGE_CODE] || {}),
          prop.toString(),
        );
        throw new Error(
          `Element has no translation key "${prop.toString()}".${didYouMean}`,
        );
      }
      return value;
    },
  } as ProxyHandler<{}>);
}

const EMPTY_I18N_PROXY = new Proxy(
  {},
  {
    get: (_, prop) => {
      throw new Error(
        `Cannot read i18n key "${prop.toString()}". Element has no translations`,
      );
    },
  },
);

const EMPTY_PAGE_PROXY = new Proxy(
  {},
  {
    get: (_, prop) => {
      throw new Error(`Page has no key "${prop.toString()}".`);
    },
  },
);

function createPageProxy(page: IPage | null) {
  if (!Object.keys(page ?? {}).length) {
    return EMPTY_PAGE_PROXY;
  }

  return new Proxy(
    {},
    {
      get: (_, prop) => {
        if (prop === Symbol.toStringTag) {
          return () => "Page";
        }
        if (prop === "toJSON") {
          return () => "{}";
        }
        if (prop === "length") {
          return Object.keys(page || {}).length;
        }
        const value = (page ?? {})[prop.toString() as keyof IPage];

        if (!value) {
          const didYouMean = getDidYouMean(
            Object.keys(page || {}),
            prop.toString(),
          );
          throw new Error(`Page has no key "${prop.toString()}".${didYouMean}`);
        }

        if (["id", "loadedAt"].includes(prop.toString())) {
          return value;
        } else {
          throw new Error(`Page has no key "${prop.toString()}"`);
        }
      },
    },
  );
}

/**
 * check if a value contains an element module
 *
 * does not support recursive data structures!
 *
 * TODO check if this works fine for all cases
 */
export function containsModule(value: unknown): boolean {
  if (typeof value !== "object" || value === null) {
    return false;
  } else {
    if (Array.isArray(value)) {
      return value.some(containsModule);
    } else {
      if (value[IS_MODULE_PROXY as keyof typeof value]) {
        return true;
      } else {
        return Object.values(value).some(containsModule);
      }
    }
  }
}

export const getBooleanExpressionValue = (
  value?: string | null,
): boolean | undefined => {
  if (!value) {
    return undefined;
  }

  switch (getExpression(value)) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return undefined;
  }
};
