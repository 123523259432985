import { IDefaultElement, buildCustomExpressionValue } from "core";

import { UntransformedGeoJSONFieldConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedGeoJSONFieldConfig> =
  {
    config: {
      value: buildCustomExpressionValue("null"),
    },
  };
