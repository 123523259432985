import ReactFlow, { Position } from "reactflow";
import { RoutePaths } from "staticPages/routes";
import { AnimatedSVGEdge } from "./components/AnimatedSVGEdge";
import { CustomNode } from "./components/Node";

// Initial nodes positioned in a chessboard-like layout
const initialNodes = [
  {
    id: "data",
    type: "customNode",
    position: { x: -300, y: 50 },
    data: {
      label: "Ensure Database & Tables Are Ready",
      icon: "storage",
      iconPosition: "left",
      targetPosition: null,
      href: RoutePaths.Database,
    },
    sourcePosition: Position.Right,
    draggable: false,
  },
  {
    id: "roles",
    type: "customNode",
    position: { x: 100, y: 150 },
    data: {
      label: "Create Roles",
      icon: "admin_panel_settings",
      iconPosition: "right",
      href: RoutePaths.RoleCreate,
    },
    sourcePosition: Position.Bottom,
    targetPosition: Position.Top,
    draggable: false,
  },
  {
    id: "queries",
    type: "customNode",
    position: { x: -300, y: 250 },
    data: {
      label: "Create Queries",
      icon: "code",
      iconPosition: "left",
      href: RoutePaths.DatabaseCustomQuery,
    },
    sourcePosition: Position.Bottom,
    targetPosition: Position.Top,
    draggable: false,
  },
  {
    id: "application",
    type: "customNode",
    position: { x: 100, y: 350 },
    data: {
      label: "Generate Application",
      icon: "app_shortcut",
      iconPosition: "right",
      sourcePosition: null,
      href: RoutePaths.AppsGenerate,
    },
    targetPosition: Position.Left,
    draggable: false,
  },
];

// Defining the edges to create the animation flow between nodes
const initialEdges = [
  {
    id: "data->roles",
    source: "data",
    target: "roles",
    type: "animatedSvg",
    data: { delay: 1 },
  },
  {
    id: "roles->queries",
    source: "roles",
    target: "queries",
    type: "animatedSvg",
    data: { delay: 5 },
  },
  {
    id: "queries->application",
    source: "queries",
    target: "application",
    type: "animatedSvg",
    data: { delay: 9 },
  },
];

const EDGE_TYPES = {
  animatedSvg: AnimatedSVGEdge,
};

const NODE_TYPES = {
  customNode: CustomNode,
};

const WorkflowDashboard = () => {
  return (
    <div style={{ height: 500 }}>
      <ReactFlow
        nodes={initialNodes}
        edges={initialEdges}
        nodeTypes={NODE_TYPES}
        edgeTypes={EDGE_TYPES}
        zoomOnScroll={false}
        zoomOnPinch={false}
        panOnDrag={false}
        nodesDraggable={false}
        nodesConnectable={false}
        zoomOnDoubleClick={false}
        panOnScroll={false}
        fitView
      />
    </div>
  );
};

export default WorkflowDashboard;
