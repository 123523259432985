import { IRawElementType } from "core";

import Component from "./container";
import { BoolFieldEditor, defaultElement, editorMetadata } from "./editor";
import { BoolFieldConfig } from "./types";

const elementType: IRawElementType = {
  name: "default_bool_field",
  component: Component,
  editable: true,
  configType: BoolFieldConfig,
  defaultElement,
  editorComponent: BoolFieldEditor,
  editorMetadata,
};

export default elementType;
