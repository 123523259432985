import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";

export function buildActions(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
) {
  const types = {};

  const remove = () => dataSource.removeSubForm(fieldPath);

  const actions = {
    remove,
  };

  return { types, actions };
}

type R = ReturnType<typeof buildActions>;
export type Actions = R["actions"];
export type Types = R["types"];
