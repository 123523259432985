import { createUseTranslation } from "core/session/translation";

export const metaQueryEditorTranslation = {
  en: {
    filterLabel: "Filter",
  },
};

export const useMetaQueryEditorTranslation = createUseTranslation(
  metaQueryEditorTranslation,
);
