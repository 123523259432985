import {
  additionalInformation,
  appMetadata,
  currentLanguage,
  sessionError as error,
  initialAppMetadata,
  isAdmin,
  isLoggedIn,
  loadAppMetadata,
  loggingIn,
  notifications,
  queryToPagesMapping,
  queryUsedForPages,
  token,
  ui,
  uiList,
  uiOptions,
} from "../../selectors";

export const selectors = {
  queryUsedForPages,
  queryToPagesMapping,
  uiOptions,
  appMetadata,
  initialAppMetadata,
  ui,
  uiList,
  loadAppMetadata,
  token,
  error,
  loggingIn,
  isLoggedIn,
  notifications,
  currentLanguage,
  isAdmin,
  additionalInformation,
} as const;
