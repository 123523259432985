import { UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";
import AdminService from "services/admin";

import { UserForm } from "../../staticPages/admin/pages/users/pages/create/types";
import { EditUserForm } from "../../staticPages/admin/pages/users/pages/edit/types";
import { User } from "../../staticPages/admin/pages/users/types";
import {
  Query,
  useAuthenticatedMutation,
  useAuthenticatedQuery,
} from "../utils";

import { QueryKeys } from "./queryKeys";

const services = AdminService.getInstance();

export const fetchUsers = {
  queryKey: QueryKeys.fetchUsers,
  queryFn: services.getAllUsers,
};

export const fetchUser: Query<User, { userId: string }> = {
  queryKey: QueryKeys.fetchUser,
  queryFn: services.getUser,
};

export const useUsers = () => useAuthenticatedQuery(fetchUsers);

export const useUser = (
  params: { userId: string },
  config?: UseQueryOptions<User, Error, User>,
) => useAuthenticatedQuery(fetchUser, params, config);

export const useDeleteUser = (
  options: UseMutationOptions<unknown, unknown, { userId: string }>,
) => useAuthenticatedMutation(services.deleteUser, options);

export const useSaveUser = (
  options: UseMutationOptions<unknown, unknown, UserForm>,
) => useAuthenticatedMutation(services.saveUser, options);

export const useEditUser = (
  options: UseMutationOptions<
    unknown,
    unknown,
    { userId: string; user: EditUserForm }
  >,
) => useAuthenticatedMutation(services.editUser, options);
