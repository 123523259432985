import { memo, useLayoutEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import ReactEChartsCore from "echarts-for-react/lib/core";

import echarts from "./EchartsImports";
import { defaultTheme, defaultThemes } from "./theme";
import { useEchartsTranslation } from "./translation";
import { EchartsProps } from "./types";

const magicTypeTypes = {
  bar: ["stack", "line", "bar"],
  scatter: ["line", "bar"],
  line: ["stack", "line", "bar"],
  pie: [],
  custom: [],
} as const;

export type TMagicType = keyof typeof magicTypeTypes;

const fixedOptions = {
  style: { height: "100%", width: "100%" },
  notMerge: true,
  lazyUpdate: true,
} as const;

const dataZoom = [
  { id: "dataZoomX", type: "slider", xAxisIndex: [0], filterMode: "filter" },
  { id: "dataZoomY", type: "slider", yAxisIndex: [0], filterMode: "empty" },
];

const legendConfig = {
  end: { right: 0 },
  top: { top: 0 },
  bottom: { bottom: 0 },
  start: { left: 0 },
  hidden: { show: false },
};

const Echarts = memo<EchartsProps<TMagicType>>(
  ({
    className,
    option,
    elementId,
    toolbox = {},
    themeName,
    chartType,
    theme: customTheme,
    legend,
    selectValue,
    ...rest
  }) => {
    const { palette } = useTheme();
    const [animation, setAnimation] = useState(true);
    const translation = useEchartsTranslation();

    const theme = useMemo(
      () =>
        defaultThemes.find((dTheme) => dTheme.themeName === themeName)?.theme ??
        customTheme ??
        defaultTheme.theme,
      [themeName, customTheme],
    );

    useLayoutEffect(() => {
      setTimeout(() => setAnimation(false), 1500);
    }, []);

    const features = useMemo(
      () => ({
        id: elementId,
        show: !!Object.keys(toolbox)?.length,
        feature: {
          ...toolbox,
          saveAsImage: {
            show: !!toolbox.saveAsImage?.show,
            title: translation.saveAsImageTooltip,
          },
          dataView: {
            show: !!toolbox.dataView?.show,
            title: translation.dataViewTooltip,
            textareaBorderColor: palette.divider,
            textColor: palette.text.primary,
            buttonColor: palette.primary.main,
          },
          restore: {
            show: !!toolbox.restore?.show,
            title: translation.restoreTooltip,
          },
          // dataZoom doesn't really work, so if it shown `dataZoom` will be added to the option
          dataZoom: {
            show: false,
            // title: {
            //   zoom: translation.dataZoomTooltip,
            //   back: translation.dataZoomBackTooltip,
            // },
          },
          magicType: {
            show:
              !["pie", "donut"].includes(chartType) &&
              !!toolbox.magicType?.show,
            type: magicTypeTypes[chartType],
            title: {
              line: translation.magicTypeLineTooltip,
              bar: translation.magicTypeBarTooltip,
              stack: translation.magicTypeStackTooltip,
              tiled: translation.magicTypeTiledTooltip,
            },
          },
          // to add a custom tool:
          // myCustomTool: {
          //   show: true,
          //   title: "custom extension method",
          //   icon: "image://https://echarts.apache.org/en/images/favicon.png",
          //   onclick: function () {
          //     alert("myToolHandler");
          //   },
          // },
        },
      }),
      [translation, palette, toolbox, elementId, chartType],
    );

    const options = useMemo(
      () => ({
        ...option,
        toolbox: features,
        legend: {
          ...legendConfig[legend?.placement ?? "start"],
          orient: legend?.orient ?? "horizontal",
        },
        ...(!!toolbox.dataZoom?.show &&
          !["pie", "donut"].includes(chartType) && {
            dataZoom,
          }),
        animation,
      }),
      [option, features, toolbox.dataZoom?.show, chartType, legend, animation],
    );

    // force remount on option change
    // rerender with option change is buggy
    // e.g. turning pie chart label off and on will lead to broken labels
    // this is an ugly fix, there may be a better option
    // TODO investigate
    const key = JSON.stringify(options);

    const events = {
      click: ({ data }: { data: Record<string, unknown> }) => {
        selectValue?.(data);
      },
      restore: () => {
        selectValue?.(null);
      },
    };

    return (
      <ReactEChartsCore
        echarts={echarts}
        {...rest}
        option={options}
        className={`echarts ${className ?? ""}`}
        {...fixedOptions}
        key={key}
        theme={theme}
        // All events: https://echarts.apache.org/en/api.html#events
        onEvents={events}
      />
    );
  },
);

export default Echarts;
