import { ComponentType, FC, Suspense, memo } from "react";
import { LoadingComponent } from "layouts/common/Loading";

export const withLazyLoading = <P extends Record<string, any>>(
  Component: ComponentType<P>,
  hideLoader: boolean = false,
  CustomLoader?: FC,
): FC<P> => {
  const LazyLoadedComponent: FC<P> = (props) => (
    <Suspense
      fallback={
        hideLoader ? null : CustomLoader ? (
          <CustomLoader />
        ) : (
          <LoadingComponent />
        )
      }
    >
      <Component {...props} />
    </Suspense>
  );

  return memo(LazyLoadedComponent);
};
