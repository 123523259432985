import { assocPath } from "ramda";
import { connect } from "react-redux";
import { selectors as editorSelectors } from "core/editor/reduxModule";
import { selectors as sessionSelectors } from "core/session/reduxModule";

import Component from "./component";

const getUpdatedAppMetadata = (state: any) => {
  const appMetadata = sessionSelectors.appMetadata(state);
  const updatedLayoutDefinition =
    editorSelectors.updatedLayoutDefinition(state);
  return updatedLayoutDefinition
    ? assocPath(
        ["definition", "layout", "definition"],
        updatedLayoutDefinition,
        appMetadata,
      )
    : appMetadata;
};

const mapStateToProps = (state: any) => ({
  appMetadata: getUpdatedAppMetadata(state),
  language: sessionSelectors.currentLanguage(state),
});

export default connect(mapStateToProps)(Component);
