import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

import { Placement } from "./index";

type Props = {
  isLabel: boolean;
  bgColor: string;
  placement: Placement;
};

export const useStyles = makeStyles<Props>()(
  (theme: Theme, { isLabel, bgColor, placement }) => ({
    colorControl: {
      minWidth: "70px",
      width: "100%",
      height: "30px",
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      outline: "none !important",
      marginRight: isLabel && placement === "end" ? theme.spacing() : 0,
      marginLeft: isLabel && placement === "start" ? theme.spacing() : 0,
      backgroundColor: bgColor,
      cursor: "pointer",
    },
    labelControl: {
      margin: 0,
      padding: `0 ${theme.spacing(0.1)}`,
    },
    fullHeight: {
      height: "100%",
    },
  }),
);
