import { createUseTranslation } from "core/session/translation";

export const repositoryConfigurationTranslation = {
  en: {
    title: "Repository Configuration",
    initialStatusTitle: "No Repository configured yet!",
    save: "Save",
    test: "Test connection",
    userName: "Username",
    personalAccessToken: "Personal Access Token",
    repositoryPath:
      "Repository Full Path (Example: cybertec-postgresql/cypex_extensions)",
    repositoryName: "Title",
    repositoryBaseUrl: "Base URL",
    repositoryBranch: "Repository Branch",
    personalAccessTokenHelper:
      "How to create a personal access token on GitHub",
    personalAccessTokenHelpUrl:
      "https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/creating-a-personal-access-token#creating-a-token",
    saveSuccessMessage: "Configuration saved successfully",
    saveErrorMessage: "Error when trying to save repository configuration",
    testSuccessMessage: "Connected Successfully. Please, Save",
    testWarningMessage: "Connection Failed",
    testErrorMessage: "Error when trying to test repository configuration",
    newRepository: "Add",
    editConfigurationTitle: "Edit Configuration",
    newConfigurationTitle: "New Configuration",
    deleteSuccessMessage: "Configuration deleted successfully",
    deleteRepositoryConfirmationTitle: "Delete Configuration",
    deleteRepositoryConfirmationText:
      "Are you sure want to delete this configuration?",
    deleteButton: "Delete",
    cancelButton: "Cancel",
    createTooltip: "Edit",
    deleteTooltip: "Delete",
    contentTooltip: "View Repository Content",
    generateRefreshTooltip: "Get Repository Branches",
  },
};

export const useRepositoryConfigurationTranslation = createUseTranslation(
  repositoryConfigurationTranslation,
);
