import { createAction, createActionTypeScoper } from "core/utils/redux";

import { constants } from "./constants";

const scopeActionType = createActionTypeScoper(constants.MODULE_NAME);

export const types = {
  REDIRECT: scopeActionType("REDIRECT"),
};

export const actions = {
  redirect: createAction(types.REDIRECT),
};
