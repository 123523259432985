import { ChangeEvent, memo, useCallback } from "react";
import {
  Box,
  FormLabel,
  MenuItem,
  TextField,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useElementEditorContext } from "core/editor";
import { useEditorTranslation } from "core/editor/translation";

const variantOptions = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "subtitle1",
  "subtitle2",
  "body1",
  "caption",
  "button",
  "overline",
];

const fontVariantOptions = variantOptions.map((currentFontVariant) => (
  <MenuItem key={currentFontVariant} value={currentFontVariant}>
    <Typography variant={currentFontVariant as TypographyProps["variant"]}>
      {currentFontVariant}
    </Typography>
  </MenuItem>
));

export const VariantSelector = memo(() => {
  const {
    elementModel: {
      config: { variant = "body1" },
    },
    changeConfigValue,
  } = useElementEditorContext();

  const { variantLabel } = useEditorTranslation();

  const handleChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue("variant", value),
    [changeConfigValue],
  );

  return (
    <Box>
      <FormLabel component="p">{variantLabel}</FormLabel>
      <TextField select onChange={handleChange} value={variant} fullWidth>
        {fontVariantOptions}
      </TextField>
    </Box>
  );
});
