import { createUseTranslation } from "core/session";

export const useViewsTableTranslation = createUseTranslation({
  en: {
    queriesLabel: "Queries",
    nameLabel: "Name",
    modalLabel: "Query Details",
    showDetailsLabel: "Show Query Details",
    titleLabel: "Title",
    clearSelectionLabel: "Clear Selection",
    refreshLabel: "Refresh",
    closeLabel: "Close",
    searchLabel: "Search",
    selectedQueriesLabel: "Count of Queries Selected",
    actionsLabel: "Actions",
  },
});

export const useFieldsTableTranslation = createUseTranslation({
  en: {
    nameLabel: "Name",
    typeLabel: "Type",
    isArrayLabel: "Array",
    isNullableLabel: "Nullable",
  },
});
