import { memo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useSessionContext } from "core/session";
import { QueryKeys, useCreateState } from "queries/admin";
import { getApiError } from "queries/utils";
import { useLocation } from "utils/hooks";
import { useSnackbar } from "utils/hooks/useSnackbar";

import { DEFAULT_LANGUAGE_CODE } from "../../../../../../core";
import { useHandleSelectElement } from "../utils";

import { StateForm } from "./StateForm";
import { useStateFormTranslations } from "./StateForm/translation";
import { UIStateForm } from "./StateForm/types";
import { StateCreateAPIData } from "./types";

const CreateState = () => {
  const { success } = useStateFormTranslations();
  const showSnackbar = useSnackbar();
  const {
    language: { code: languageCode },
  } = useSessionContext();
  const queryClient = useQueryClient();
  const setSelectedElement = useHandleSelectElement();

  const createState = useCreateState({
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
    onSuccess: () => {
      showSnackbar(success, "success");
      queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchWorkflow] });
      setSelectedElement(null);
    },
  });

  const location = useLocation();

  const onSubmit = (data: Record<string, unknown>) => {
    const {
      queries: { schema, table },
    } = location;
    const { state, title, shortDescription, isEditable, acl } =
      data as UIStateForm;
    const translated = {
      [languageCode]: { title, shortDescription },
      [DEFAULT_LANGUAGE_CODE]: { title, shortDescription },
    };
    const payload: StateCreateAPIData = {
      state,
      i18n: translated,
      acl: isEditable ? acl ?? [] : null,
    };

    createState.mutate({
      schema,
      table,
      stateData: payload,
    });
  };

  return <StateForm onSubmit={onSubmit} />;
};

const MemoizedCreateState = memo(CreateState);

export default MemoizedCreateState;
