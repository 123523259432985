import { memo } from "react";
import InputLabel from "@mui/material/InputLabel";
import { buildCustomExpressionValue } from "core";
import { ColorSelect, useElementEditorContext } from "core/editor";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";

import { IColor } from "core/editor/types";
import {
  DEFAULT_CONFIG,
  colorItems,
} from "elementTypes/default_geojson_field/constants";
import {
  GeoJSONSelected,
  TPathOptions,
  UntransformedGeoJSONFieldConfig,
  styleFunctionTyping,
} from "../../../types";
import { useDefaultGeoJSONFieldEditorTranslation } from "../../translation";
import { hasSelection } from "../../utils";

export const PathOptions = memo(() => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedGeoJSONFieldConfig>();

  const withSelection = hasSelection(
    config?.selected as unknown as GeoJSONSelected,
  );

  const t = useDefaultGeoJSONFieldEditorTranslation();

  const value = config.styleFunction ?? DEFAULT_CONFIG.styleFunction;

  const handleChange = (nextvalue: string | TPathOptions) => {
    changeConfigValue("styleFunction", nextvalue);
  };

  const handleSelectedChange = (nextColor?: string) => {
    changeConfigValue("selected", {
      ...(config.selected ?? {}),
      pathColor: { color: nextColor },
    });
  };

  const nonExpressionEditor = ({
    value: colorVal,
    onChange,
  }: NonExpressionEditorProps) => {
    const color = (colorVal as { color?: IColor }).color ?? "";
    const onColorChange = (nextValue: string) =>
      onChange({ color: nextValue } as any);

    return (
      <>
        <ColorSelect
          colorItems={colorItems}
          defaultValue={DEFAULT_CONFIG.styleFunction.color}
          value={color}
          onChange={onColorChange}
          background
        />
        {withSelection ? (
          <>
            <InputLabel sx={{ py: 1 }}>{t.selectedPropertyLabel}</InputLabel>
            <ColorSelect
              colorItems={colorItems}
              defaultValue={DEFAULT_CONFIG.styleFunction.color}
              value={config.selected?.pathColor?.color ?? ""}
              onChange={handleSelectedChange}
              background
            />
          </>
        ) : null}
      </>
    );
  };

  return (
    <CustomExpressionEditor
      value={value as any}
      label={t.stylingFunctionLabel}
      labelTooltip={t.stylingFunctionDescription}
      config={config}
      onChange={handleChange}
      additionalScope={styleFunctionTyping}
      nonExpressionEditor={nonExpressionEditor}
      onToggleMode={(isExpression) => {
        if (isExpression) {
          handleChange(DEFAULT_CONFIG.styleFunction);
        } else {
          handleChange(buildCustomExpressionValue(`(feature) => {return {}}`));
          if (config.selected) {
            handleSelectedChange(undefined);
          }
        }
      }}
    />
  );
});
