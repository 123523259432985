import * as t from "io-ts";
import { getViewRowType } from "core/editor/types";
import { types } from "core/runtime-typing";
import { IElement, TypeFactory } from "core/types";
import { UntransformedConfig } from "core/types/react";

import {
  EChartsTranslationKeys,
  EchartsContainerMultiConfig,
} from "../common/Echarts";

export const LineChartConfig = t.intersection([
  EchartsContainerMultiConfig,
  t.partial({
    hideLabel: t.boolean,
    hideLegend: t.boolean, // TODO: remove with app migration
    hideYAxis: t.boolean,
    hideXAxis: t.boolean,
    isArea: t.boolean,
  }),
]);

export type LineChartConfig = t.TypeOf<typeof LineChartConfig>;
export type UntransformedLineChartConfig = UntransformedConfig<LineChartConfig>;

export type LineChart = IElement<LineChartConfig, {}, EChartsTranslationKeys>;

const selectedRowType: TypeFactory<LineChartConfig> = (params) =>
  getViewRowType({ isNullable: true })(params);

export const selectorTypes = {
  loading: types.boolean(),
  data: types.any(),
  error: types.nullable(types.string()),
  selected: selectedRowType,
};
