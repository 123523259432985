import { memo } from "react";
import { Box, InputLabel, ToggleButtonGroup } from "@mui/material";

import { useElementEditorContext } from "core/editor";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";

import { EmojiAutocomplete } from "core/editor/common/EmojiAutocomplete";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import {
  DEFAULT_CONFIG,
  featureFunctionDefault,
} from "elementTypes/default_geojson_field/constants";
import {
  GeoJSONSelected,
  UntransformedGeoJSONFieldConfig,
  markerLabelVariant,
  tooltipFunctionTyping,
} from "../../../types";
import { useDefaultGeoJSONFieldEditorTranslation } from "../../translation";
import { hasSelection } from "../../utils";
import { ViewColumnOrTextEditor } from "../Tooltip/Tooltip";

export const MarkerDisplayText = memo(() => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedGeoJSONFieldConfig>();

  const t = useDefaultGeoJSONFieldEditorTranslation();
  const variants = Object.keys(markerLabelVariant.keys);
  const variant = config.markerLabelVariant ?? variants[0];

  const withSelection = hasSelection(
    config?.selected as unknown as GeoJSONSelected,
  );

  const value =
    config.markerDisplayTextFunction ??
    DEFAULT_CONFIG.markerDisplayTextFunction ??
    "";

  const handleChange = (nextvalue: string) => {
    changeConfigValue("markerDisplayTextFunction", nextvalue);
  };

  const handleSelectedChange = (nextLabel?: string) => {
    changeConfigValue("selected", {
      ...(config.selected ?? {}),
      markerLabel: nextLabel,
    });
  };

  const handleVariantChange = (_: unknown, nextVariant: string) =>
    nextVariant && changeConfigValue("markerLabelVariant", nextVariant);

  const viewName = config?.dataSource?.viewName ?? "";

  const btns = variants.map((option: string) => (
    <ToggleButton key={option} value={option} fullWidth>
      {option}
    </ToggleButton>
  ));

  const nonExpressionEditor = (props: NonExpressionEditorProps) => {
    return (
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <ToggleButtonGroup
          exclusive
          onChange={handleVariantChange}
          value={variant}
          fullWidth
          size="small"
        >
          {btns}
        </ToggleButtonGroup>
        {variant === "icon" ? (
          <>
            <EmojiAutocomplete
              value={props.value as string}
              onChange={props.onChange}
            />
            {withSelection ? (
              <>
                <InputLabel>{t.selectedPropertyLabel}</InputLabel>
                <EmojiAutocomplete
                  value={config.selected?.markerLabel ?? ""}
                  onChange={handleSelectedChange}
                />
              </>
            ) : null}
          </>
        ) : (
          <>
            <ViewColumnOrTextEditor
              {...{ ...props, viewName, label: t.propertyLabel }}
            />
            {withSelection ? (
              <>
                <InputLabel>{t.selectedPropertyLabel}</InputLabel>
                <ViewColumnOrTextEditor
                  {...{
                    value: config.selected?.markerLabel ?? "",
                    onChange: handleSelectedChange,
                    viewName,
                    label: t.propertyLabel,
                  }}
                />
              </>
            ) : null}
          </>
        )}
      </Box>
    );
  };

  return (
    <CustomExpressionEditor
      value={value}
      label={t.markerDisplayTextFunctionLabel}
      labelTooltip={t.markerDisplayTextFunctionDescription}
      config={config}
      onChange={handleChange}
      additionalScope={tooltipFunctionTyping}
      nonExpressionEditor={nonExpressionEditor}
      onToggleMode={(isExpression) => {
        if (isExpression) {
          handleChange("");
        } else {
          handleChange(featureFunctionDefault(value));
          if (config.selected) {
            handleSelectedChange(undefined);
          }
        }
      }}
    />
  );
});
