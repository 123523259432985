import { buildCustomExpressionValue } from "core";
import { IDefaultElement } from "core/types/element";

import { UntransformedMarkdownFieldConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedMarkdownFieldConfig> =
  {
    config: {
      text: buildCustomExpressionValue("i18n.text"),
      htmlAllowed: false,
    },
    i18n: {
      en: {
        text: "Hello **world** 🚀",
      },
    },
  };
