import { chalk } from "./chalk";
import { dark } from "./dark";
import { essos } from "./essos";
import { halloween } from "./halloween";
import { infographic } from "./infographic";
import { macarons } from "./macarons";
import { purplePassion } from "./purplePassion";
import { roma } from "./roma";
import { shine } from "./shine";
import { vintage } from "./vintage";
import { walden } from "./walden";
import { westeros } from "./westeros";
import { wonderland } from "./wonderland";

export const defaultThemes = [
  westeros,
  chalk,
  vintage,
  wonderland,
  macarons,
  shine,
  halloween,
  dark,
  essos,
  walden,
  infographic,
  roma,
  purplePassion,
];
export const defaultTheme = westeros; //westeros
export type DefaultTheme = (typeof defaultThemes)[0];
