import { memo, useMemo, useState } from "react";
import { InputAdornment, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { DEFAULT_LANGUAGE_CODE, Language, Translation } from "core";
// point directly to the exporting module or reconfigure "output.manualChunks" to ensure these modules end up in the same chunk.
import { TranslationEditor } from "core/editor/common/TranslationEditor";
import { actions as routerActions } from "core/router/reduxModule";
import { useSessionContext } from "core/session";

import BackButton from "elementTypes/common/BackButton";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";
import { useRoles } from "queries/admin";
import { useCreateApp } from "queries/admin/appData";
import { getApiError } from "queries/utils";
import { RoutePaths } from "staticPages/routes";
import { useSnackbar } from "utils/hooks/useSnackbar";

import { useCommonStaticPagesTranslation } from "../../translation";

import { UploadZoneInput } from "./components";
import useStyles from "./styles";

export const CreateAppsPage = memo(() => {
  const {
    classes: { root, footer, dividerFooter },
  } = useStyles();
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues: {
      role: "",
      name: "",
      i18n: {
        [DEFAULT_LANGUAGE_CODE]: {
          label: "",
          description: "",
        },
      },
      file: null,
    },
  });

  const defaultI18n = {
    [DEFAULT_LANGUAGE_CODE]: { description: "", label: "" },
  } as Translation<"label" | "description">;

  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();
  const translation = useCommonStaticPagesTranslation();

  const rolesList = useRoles();

  const i18n = watch("i18n") ?? defaultI18n;
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);

  const createApp = useCreateApp({
    onSuccess: () => {
      showSnackbar(translation.createAppMessage, "success");
      dispatch(routerActions.goBack());
    },
    onError: (error: unknown) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const submit = async (data: Record<string, any>) => {
    if (data.file === null) {
      return;
    }
    const text = await data.file.text();

    createApp.mutate({
      name: data.name.trim(),
      role: data.role,
      i18n: data.i18n,
      definition: JSON.parse(text),
    });
  };

  const handleRefreshClick = () => rolesList.refetch();

  const items = useMemo(
    () =>
      rolesList.data?.map(({ name }) => (
        <MenuItem key={name} value={name}>
          {name}
        </MenuItem>
      )),
    [rolesList.data],
  );

  if (!rolesList.data) {
    return null;
  }

  const changeTranslation =
    (cb: (value: Translation<string>) => void) =>
    (value: Translation<string>) => {
      setValue("i18n", value);
      cb(value);
    };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box display="flex" alignItems="center" gap={1}>
        <BackButton isIcon href={RoutePaths.Apps} />
        <Typography variant="h5">{translation.importAppTitle}</Typography>
      </Box>
      <Paper variant="outlined" className={root}>
        <Grid container spacing={1}>
          <Grid item={true} xs={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth={true}
                  label="Application name"
                  size="small"
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          icon="help_outline"
                          size="small"
                          tooltip="Once the app name is created, it's read-only. The unique identifier for the app  will be visible in the URL"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              control={control}
              name="name"
              rules={{
                required: true,
              }}
              defaultValue=""
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Stack direction="column" width="100%" spacing={1}>
              <LanguageSwitch
                language={lang}
                changeLanguage={setLang}
                colorVariant="dark"
                fullWidth={true}
              />
              <Controller
                render={({ field: { ref, onChange, ...inputProps } }) => (
                  // TODO: TranslationEditor is element from editor, shouldn't be in admin, either move component or create a new one
                  <TranslationEditor
                    translationKey="label"
                    i18n={i18n}
                    label="Label"
                    language={lang}
                    {...inputProps}
                    inputRef={ref}
                    changeTranslation={changeTranslation(onChange)}
                  />
                )}
                name="i18n"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={defaultI18n}
              />
              <Controller
                render={({
                  field: { ref, onChange, ...inputProps },
                  fieldState: { error },
                }) => (
                  <TranslationEditor
                    translationKey="description"
                    i18n={i18n}
                    label="Description"
                    language={lang}
                    {...inputProps}
                    inputRef={ref}
                    markdown
                    changeTranslation={changeTranslation(onChange)}
                    error={Boolean(error)}
                  />
                )}
                name="i18n"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={defaultI18n}
              />
            </Stack>
          </Grid>
          <Grid item={true} xs={12}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <TextField
                  select
                  label="Application Owner"
                  size="small"
                  error={Boolean(error)}
                  helperText={error?.message}
                  variant="standard"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        size="small"
                        icon="refresh"
                        tooltip="Refresh"
                        onClick={handleRefreshClick}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                >
                  {items}
                </TextField>
              )}
              name="role"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true}>
              <FormLabel component="p">Definition</FormLabel>
              <Controller
                render={(p) => (
                  <UploadZoneInput
                    {...{
                      ...p,
                      accept: {
                        "application/json": [".json"],
                      },
                    }}
                  />
                )}
                name="file"
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className={footer}>
            <Divider className={dividerFooter} />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button color="primary" label="Cancel" href={RoutePaths.Apps} />
              <Button
                color="secondary"
                disabled={isSubmitting || !isValid}
                processing={isSubmitting}
                iconRight="forward"
                type="submit"
                label="Create"
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
});
