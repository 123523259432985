import { memo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Edge } from "reactflow";
import { useSessionContext } from "core/session";
import { QueryKeys, useCreateStateTransition } from "queries/admin";
import { getApiError } from "queries/utils";
import { useLocation } from "utils/hooks";
import { useSnackbar } from "utils/hooks/useSnackbar";

import { DEFAULT_LANGUAGE_CODE } from "../../../../../../core";
import { useHandleSelectElement } from "../utils";

import { UIStateForm } from "./StateForm/types";
import { StateTransitionForm } from "./StateTransitionForm";
import { useStateTransitionTranslations } from "./StateTransitionForm/translation";
import { EdgeData } from "./StateViewer/types";
import { StateTransitionCreateAPIData } from "./types";

const CreateStateTransition = ({
  edge: selectedEdge,
}: {
  edge: Edge<EdgeData>;
}) => {
  const { success } = useStateTransitionTranslations();
  const showSnackbar = useSnackbar();
  const location = useLocation();
  const {
    language: { code: languageCode },
  } = useSessionContext();

  const setSelectedElement = useHandleSelectElement();

  const queryClient = useQueryClient();
  const { mutate } = useCreateStateTransition({
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchWorkflow] });
      showSnackbar(success, "success");
      setSelectedElement(null);
    },
  });

  const onSubmit = (formData: Record<string, unknown> | UIStateForm) => {
    const {
      queries: { schema, table },
    } = location;
    const { ...data } = formData as UIStateForm;
    const { acl = [], shortDescription, title } = data;
    const translated = {
      [languageCode]: { title, shortDescription },
      [DEFAULT_LANGUAGE_CODE]: { title, shortDescription },
    };
    const { target, source, sourceName, targetName } =
      selectedEdge as Edge<EdgeData> & {
        sourceName: string;
        targetName: string;
      };
    const stateTransitionData: StateTransitionCreateAPIData = {
      acl,
      source: source && source !== "start" && sourceName ? sourceName : null,
      target: target && target !== "end" && targetName ? targetName : null,
      i18n: translated,
    };
    mutate({ schema, table, stateTransitionData });
  };

  return <StateTransitionForm onSubmit={onSubmit} />;
};

const MemoizedCreateStateTransition = memo(CreateStateTransition);

export default MemoizedCreateStateTransition;
