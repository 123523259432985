import { IReduxModuleFactoryArgs } from "core";
import { buildInterface } from "elementTypes/common/Echarts/chartInterface";

import { CustomChart } from "../types";

import { buildActions } from "./actions";
import { buildReducer } from "./reducer";
import { buildSaga } from "./saga";
import { buildSelectors } from "./selectors";

export function reduxModuleFactory({
  path,
  element,
}: IReduxModuleFactoryArgs<CustomChart>) {
  const scope = path.join(".");

  const { actions, types } = buildActions(scope);
  const reducer = buildReducer(types);
  const selectors = buildSelectors(path, element);
  const saga = buildSaga(actions, types, element);
  const chartInterface = buildInterface(actions.load);

  return {
    actions,
    interfaces: chartInterface,
    reducer,
    saga,
    selectors,
  };
}

export * from "./types";

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
