import { memo, useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";

import { useChartEditorTranslation } from "../../translation";
import { LineChartConfig } from "../../types";
import { defaultElement } from "../defaultElement";

export const SetupComponent = memo(() => {
  const {
    elementModel: {
      config: { dataSource, hideLabel, hideXAxis, hideYAxis, isArea },
    },
    changeConfigValue,
  } = useElementEditorContext<LineChartConfig>();

  const { viewName, columns } = dataSource;

  const {
    labelLabel,
    labelShowLabel,
    xAxisLabel,
    yAxisLabel,
    valueLabel,
    viewLabel,
    valueColumnHelperText,
    isAreaLabel,
  } = useChartEditorTranslation();

  const changeDataSource = useCallback(
    (newDataSource: Partial<LineChartConfig["dataSource"]>) =>
      changeConfigValue("dataSource", { ...dataSource, ...newDataSource }),
    [changeConfigValue, dataSource],
  );

  const handleViewNameChange = (newViewName: string) =>
    changeDataSource({
      viewName: newViewName,
      columns: defaultElement.config?.dataSource.columns,
    });

  const handleFieldChange = (
    fieldName: string,
    fieldValue: string[] | string | boolean | number | number[] | null,
  ) =>
    changeDataSource({
      columns: { ...columns, [fieldName]: fieldValue },
    });

  const toggleConfigs =
    (config: "hideLabel" | "hideXAxis" | "hideYAxis" | "isArea") =>
    (_: any, checked: boolean) =>
      changeConfigValue(config, checked);

  const fields = [
    {
      label: labelLabel,
      value: columns.labelColumnName,
      name: "labelColumnName",
    },
    {
      label: valueLabel,
      value: columns.valueColumnNames,
      name: "valueColumnNames",
    },
  ];

  const { configTitle } = useEditorTranslation();

  return (
    <Section title={configTitle} wrapped={true}>
      <ViewAutocomplete
        viewValue={viewName}
        viewLabel={viewLabel}
        onViewNameChange={handleViewNameChange}
        onViewFieldChange={handleFieldChange}
        fields={fields}
      />
      <FormHelperText>{valueColumnHelperText}</FormHelperText>
      <FormControlLabel
        control={
          <Switch checked={!hideLabel} onChange={toggleConfigs("hideLabel")} />
        }
        label={labelShowLabel}
      />
      <FormControlLabel
        control={
          <Switch checked={!hideXAxis} onChange={toggleConfigs("hideXAxis")} />
        }
        label={xAxisLabel}
      />
      <FormControlLabel
        control={
          <Switch checked={!hideYAxis} onChange={toggleConfigs("hideYAxis")} />
        }
        label={yAxisLabel}
      />
      <FormControlLabel
        control={<Switch checked={isArea} onChange={toggleConfigs("isArea")} />}
        label={isAreaLabel}
      />
    </Section>
  );
});
