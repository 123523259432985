import { IRawElementType } from "core";

import Component from "./container";
import { ColorFieldEditor, defaultElement, editorMetadata } from "./editor";
import { ColorFieldConfig } from "./types";

const elementType: IRawElementType = {
  name: "default_color_field",
  component: Component,
  editable: true,
  configType: ColorFieldConfig,
  editorComponent: ColorFieldEditor,
  defaultElement,
  editorMetadata,
};

export default elementType;
