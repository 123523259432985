import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";
import { getDisabledValueFromConfig } from "elementTypes/common/utils.ts";
import { StaticSelect } from "../../types.ts";

export function buildSelectors(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
  element: StaticSelect,
) {
  const value = (state: any) =>
    dataSource.createFieldValueSelector(fieldPath, {
      defaultValue: "",
    })(state);

  const disabled = (state?: any) =>
    getDisabledValueFromConfig(state, element.config);

  return {
    value,
    disabled,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
