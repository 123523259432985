import { all, takeLatest } from "redux-saga/effects";
import { elementActionEventHandler } from "elementTypes/common/ActionConfig/saga";

import { ActionButton } from "../types";

import { Actions, Types } from "./types";

export function buildSaga(
  actions: Actions,
  types: Types,
  element: ActionButton,
) {
  function* actionSaga() {
    for (const onTrigger of element.config.onTrigger) {
      yield elementActionEventHandler(onTrigger);
    }
  }

  return function* mainSaga() {
    yield all([takeLatest(types.TRIGGER, actionSaga)]);
  };
}
