import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import ReactFlow, {
  Background,
  BackgroundProps,
  Controls,
  Edge,
  Node,
  ReactFlowInstance,
  Viewport,
  useReactFlow,
} from "reactflow";

import { useAdminContext } from "staticPages/admin/context";
import { LayoutOption } from "../component";
import { MiniMap } from "../components/MiniMap";

import CustomEdge from "./CustomEdge";
import SchemaNode from "./SchemaNode";
import { useStyles } from "./styles";
import TableNode from "./TableNode";

import { EdgeData, NodeData } from "./types";
import { getLayoutedElements } from "./utils";
import ViewNode from "./ViewNode";

const NODE_TYPES = {
  schema: SchemaNode,
  table: TableNode,
  view: ViewNode,
};

const EDGE_TYPES = {
  custom: CustomEdge,
};

type ElementTableNode = NodeData;

export type ElementSchemaNode = {
  schema: string;
  width: number;
  height: number;
};

type ElementDataNode = ElementTableNode | ElementSchemaNode;

type Props = {
  directionValue: LayoutOption;
  sourceContent: { nodes: Node<ElementDataNode>[]; edges: Edge[] };
  bgProps?: BackgroundProps;
  innerRef?: React.Ref<HTMLDivElement>;
};

const defaultViewport: Viewport = { x: 10, y: 10, zoom: 0.5 };

type SchemaNode = Node<ElementSchemaNode>;

const Component = memo<Props>(
  ({ directionValue, sourceContent, bgProps, innerRef }) => {
    const [elements, setElements] = useState<{
      nodes: Node<ElementDataNode>[];
      edges: Edge[];
    }>({ nodes: [], edges: [] });
    const reactFlowInstance = useRef<ReactFlowInstance<
      NodeData,
      EdgeData
    > | null>(null);
    const {
      classes: { layoutflow },
    } = useStyles({ selected: false });
    const { filter, selectedNode, tableViewFocus } = useAdminContext();
    const { fitView, setCenter } = useReactFlow();

    const filteredNodeContent = useMemo(() => {
      return sourceContent.nodes.filter((source) => {
        const schema = (source.data as ElementTableNode).schemaName;

        return !filter.schema.includes(schema);
      });
    }, [filter.schema, sourceContent.nodes]);

    const onLayout = useCallback(() => {
      const layoutedElements = getLayoutedElements(
        filteredNodeContent,
        sourceContent.edges,
        directionValue,
      );
      setElements(layoutedElements);
    }, [filteredNodeContent, sourceContent.edges, directionValue]);

    const onLoad = (instance: ReactFlowInstance<NodeData, EdgeData>) => {
      reactFlowInstance.current = instance;
      if (tableViewFocus && selectedNode) {
        const { width, height, position } = selectedNode;
        if (width && height) {
          const x = position.x + width / 2;
          const y = position.y + height / 2;
          setCenter(x, y, { zoom: 0.8 });
        }
      } else {
        fitView();
      }
      onLayout();
    };

    useEffect(() => {
      onLayout();
    }, [filteredNodeContent, sourceContent, directionValue]);

    return (
      <Box className={layoutflow}>
        <ReactFlow
          ref={innerRef}
          nodes={elements.nodes}
          edges={elements.edges}
          nodeTypes={NODE_TYPES}
          edgeTypes={EDGE_TYPES}
          onInit={onLoad}
          maxZoom={1}
          minZoom={0.3}
          defaultViewport={defaultViewport}
          nodesConnectable={false}
          nodesDraggable={false}
          panOnDrag
          zoomOnScroll
          fitView
        >
          <MiniMap />
          <Controls showInteractive={false} />
          <Background gap={16} {...bgProps} />
        </ReactFlow>
      </Box>
    );
  },
);

export const ErdChartComponent = (props: Props) => <Component {...props} />;
