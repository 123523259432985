import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  label: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(0.5),
  },
  group: {
    justifyContent: "space-between",
    flexWrap: "nowrap",
    paddingBottom: theme.spacing(),
  },
}));
