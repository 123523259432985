import { UseMutationOptions } from "@tanstack/react-query";
import { updateUser } from "services/api/ApiService";

import { useAuthenticatedMutation } from "../utils";

type UpdateProfileArguments = Parameters<typeof updateUser>[1];

export const useUpdateProfile = (
  options: UseMutationOptions<
    Record<string, unknown>,
    unknown,
    UpdateProfileArguments
  >,
) => useAuthenticatedMutation(updateUser, options);
