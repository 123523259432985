import { CSSProperties, memo } from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useEditorTranslation } from "core/editor";

import IconButton from "../../../common/IconButton";

import { Reference } from "./MultiReference";

type Props = {
  onClick: (params: Reference) => void;
  onDelete: (name: string) => void;
  style?: CSSProperties;
} & Reference;

export const ReferenceItem = memo<Props>(
  ({ style, onClick, onDelete, ...props }) => {
    const { editButton, deleteButton } = useEditorTranslation();
    const { targetView, referencingFieldName } = props;
    const handleEditClick = () => onClick(props as Reference);
    const handleDelete = () => onDelete(targetView);

    return (
      <ListItem divider style={style}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box overflow="auto">
            <ListItemText
              primary={
                <Typography variant="subtitle2">{targetView}</Typography>
              }
              primaryTypographyProps={{ color: "primary" }}
              secondary={referencingFieldName}
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <ListItemIcon>
              <IconButton
                icon="delete_outline"
                onClick={handleDelete}
                color="error"
                tooltip={deleteButton}
              />
              <IconButton
                icon="edit"
                onClick={handleEditClick}
                tooltip={editButton}
                edge="end"
              />
            </ListItemIcon>
          </Box>
        </Box>
      </ListItem>
    );
  },
);
