import { UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";
import {
  getUserNotifications,
  readUserNotifications,
} from "services/api/ApiService";

import { useAuthenticatedMutation, useAuthenticatedQuery } from "../utils";
import { QueryKeys } from "./queryKeys";
import { Notification } from "./types";

type ReadNotificactionsArguments = Parameters<typeof readUserNotifications>[1];

export const fetchNotifications = {
  queryKey: QueryKeys.fetchNotifications,
  queryFn: getUserNotifications,
};

export const useNotifications = (
  config?: UseQueryOptions<Notification[], Error>,
) => useAuthenticatedQuery(fetchNotifications, undefined, config);

export const useReadNotifications = (
  options?: UseMutationOptions<unknown, unknown, ReadNotificactionsArguments>,
) => useAuthenticatedMutation(readUserNotifications, options);
