import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Json Input",
      description: "",
    },
  },
  defaultSize: {
    width: 2,
    height: 2,
  },
  minSize: {
    width: 2,
    height: 2,
  },
};
