import * as t from "io-ts";
import {
  ColorConfig,
  IElement,
  UntransformedConfig,
  customExpression,
} from "core/types";

export const ExternalLinkFieldConfig = t.intersection([
  t.type({
    link: customExpression(t.union([t.string, t.null])),
  }),
  t.partial({
    disabled: customExpression(t.boolean),
    isButton: t.boolean,
    icon: t.string,
    color: ColorConfig,
    variant: t.keyof({
      outlined: null,
      contained: null,
    }),
  }),
]);

export type ExternalLinkFieldConfig = t.TypeOf<typeof ExternalLinkFieldConfig>;

export type UntransformedExternalLinkFieldConfig =
  UntransformedConfig<ExternalLinkFieldConfig>;

export const ExternalLinkFieldTranslationKeys = ["label"] as const;

export type ExternalLinkFieldTranslationKeys =
  (typeof ExternalLinkFieldTranslationKeys)[number];

export type ExternalLinkField = IElement<
  ExternalLinkFieldConfig,
  Record<string, any>,
  ExternalLinkFieldTranslationKeys
>;
