import { CSSProperties } from "react";
import { Theme } from "@mui/material";

import { makeStyles } from "tss-react/mui";

type Props = {
  selected: boolean;
};

export const useStyles = makeStyles<Props>()((theme: Theme, { selected }) => ({
  layoutflow: {
    flexGrow: 1,
    position: "relative",
    zIndex: theme.zIndex.modal,
    width: "100% !important",
    height: "100% !important",
    backgroundColor: "#e5e6ec",
    borderRadius: 5,
  },
  node: {
    minWidth: 400,
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    border: `1px solid ${
      selected ? theme.palette.info.main : theme.palette.divider
    }`,
  },
  nodeHeaderBoxBackground: {
    ...(selected && {
      color: theme.palette.info.contrastText,
      backgroundColor: theme.palette.info.main,
    }),
    borderBottom: `1px solid ${
      selected ? theme.palette.info.main : theme.palette.divider
    }`,
  },
  nodeItem: {
    padding: theme.spacing(0.5, 0.5),
    backgroundColor: theme.palette.grey[50],
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[200],
    },
    cursor: "pointer",
  },
  nodeActions: {
    marginTop: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    cursor: "pointer",
  },
  columnTypeFontVariant: {
    fontFamily: "Monospace",
    color: theme.palette.grey[600],
  },
  handleClass: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    // needed so the arrow does not overlap with the column
    left: -2,
    width: "calc(100% + 2px)",
    height: "100%",
    background: "transparent",
    transform: "none",
    border: "none",
  },
  tableRow: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  auditTableActions: {
    justifyContent: "center",
  },
  errorButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

export const edgeRawStyle: CSSProperties = { fontFamily: "Monospace" };
