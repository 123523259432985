import { ReactNode, memo } from "react";
import { Divider, PropTypes, Stack } from "@mui/material";
import MUIAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import IconButton from "elementTypes/common/IconButton";
import { TrialLabel } from "elementTypes/common/TrialLabel";

import { RoutePaths } from "staticPages/routes";
import AdminButton from "../AdminButton";
import { AppBarMenu } from "../AppBarMenu";
import AppSwitch from "../AppSwitch";
import { EditorSwitch } from "../EditorSwitch";
import { HelpButton } from "../Help";
import { LanguageSwitch } from "../LanguageSwitch";
import { Notifications } from "../Notifications";

import { mapStateToProps } from "./container";
import useStyles from "./styles";

export type Props = {
  label: ReactNode;
  open: boolean;
  hidden?: boolean;
  handleDrawerChange: () => void;
  menuWidth: string;
  headerButtonsColor: PropTypes.Color;
  toolbarVariant?: "dense" | "regular";
  handleLoginClick?: () => void;
} & ReturnType<typeof mapStateToProps>;

export const AppBar = memo<Props>((props) => {
  const {
    label,
    open,
    hidden,
    handleDrawerChange,
    toolbarVariant,
    headerButtonsColor,
    isLoggedIn,
    isAdmin,
    isStaticPage,
    handleLoginClick,
    location,
    menuWidth,
  } = props;

  const { classes } = useStyles({ menuWidth });
  const { pathname } = location;

  return (
    <MUIAppBar
      className={classNames(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      position="relative"
      elevation={0}
    >
      <Toolbar variant={toolbarVariant} disableGutters={true} sx={{ pr: 1 }}>
        {!hidden ? (
          <IconButton
            color={headerButtonsColor}
            onClick={handleDrawerChange}
            icon="menu"
            sx={{ color: "primary.contrastText" }}
          />
        ) : null}
        <Stack direction="row" flex={1} alignItems="center" spacing={1}>
          <Typography
            data-testid="application-name"
            variant="h5"
            color="inherit"
            noWrap={true}
            {...(hidden && {
              ml: 1,
            })}
          >
            {label}
          </Typography>
          <TrialLabel />
        </Stack>

        {isLoggedIn && <Notifications />}
        <HelpButton />

        <LanguageSwitch color="primaryText" />
        {isAdmin && (
          <>
            <Stack px={0.5} />
            <Divider
              orientation="vertical"
              sx={{
                borderColor: headerButtonsColor,
              }}
              className={classes.divider}
            />
            <Stack direction={"row"} gap={1} px={1}>
              <AdminButton color={headerButtonsColor} />
              {!isStaticPage && (
                <EditorSwitch
                  color={headerButtonsColor}
                  toggleDrawer={handleDrawerChange}
                  drawerOpen={open}
                />
              )}
            </Stack>
            <Divider
              orientation="vertical"
              sx={{
                borderColor: headerButtonsColor,
              }}
              className={classes.divider}
            />
            <Stack px={0.5} />
          </>
        )}
        <AppSwitch color={headerButtonsColor} />
        {isLoggedIn && <AppBarMenu />}
        {!isLoggedIn && pathname !== RoutePaths.Login ? (
          <IconButton
            color={headerButtonsColor}
            onClick={handleLoginClick}
            tooltip="Go to login page"
            icon="lock_open"
            data-testid="go-to-login-button"
          />
        ) : null}
      </Toolbar>
    </MUIAppBar>
  );
});

AppBar.displayName = "AppBar";
