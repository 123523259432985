import { PropsWithChildren, memo, useMemo } from "react";
import { CssBaseline } from "@mui/material";
import * as locales from "@mui/material/locale";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { useSelector } from "react-redux";

import { selectors as sessionSelectors } from "../../../../session/reduxModule";

import defaultTheme from "./defaultThemeDefinition";

export const EditorThemeProvider = memo<PropsWithChildren>(({ children }) => {
  const language = useSelector(sessionSelectors.currentLanguage);

  const theme = useMemo(
    () => createTheme(defaultTheme, locales[language.locale]),
    [language.locale],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
});
