export const isDevMode = () => !import.meta.env || import.meta.env.DEV;

export function isFile(variable: any): variable is File {
  return variable instanceof File;
}

export const isFullyContainedArray = <T>(
  mainArray: unknown[],
  subArray: T[],
  key?: keyof T,
) => {
  return subArray.every((item) => mainArray.includes(key ? item[key] : item));
};

export const isPartiallyContainedArray = (
  mainArray: unknown[],
  subArray: unknown[],
) => {
  return subArray.some((item) => mainArray.includes(item));
};
