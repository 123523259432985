import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import {
  DEFAULT_LANGUAGE_CODE,
  IElementModel,
  TDefaultElementChild,
  TElementModelWithPosition,
  buildCustomExpressionValue,
} from "core";

import { useElementTypesContext } from "core/context/ElementTypesContext";
import { Section } from "core/editor";
import IconButton from "elementTypes/common/IconButton";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { useElement, usePage } from "utils/hooks";

import { useTableEditorTranslation } from "../../../translation";
import { UntransformedTableConfig } from "../../../types";
import { IColumn } from "../Columns/ColumnEditor";
import { useStyles } from "../styles";
import { Actions } from "./Actions";
import { ActionsProvider } from "./ActionsContext";

type Props = {
  elementModel: IElementModel<UntransformedTableConfig>;
};

const TABLE_BODY_ACTIONS_NAME = "table_body_actions";
export const TABLE_BODY_DELETE_BUTTON = "table_body_delete_button";
export const TABLE_HEADER_NAME = "table_header_actions";

export const ActionsEditor = memo<Props>(({ elementModel }) => {
  const {
    classes: { actionsContent },
  } = useStyles();
  const {
    actionsTitle,
    addActionTooltip,
    CancelButton,
    deleteActionCancelTitle,
    deleteActionLabel,
    deleteActionSubmitTitle,
    deleteActionTitle,
    deleteActionWarning,
    DeleteButton,
    dialogContentText,
    dialogTitle,
  } = useTableEditorTranslation();

  const { updateChildren, createElement, selectElement } = useElement();

  const page = usePage();

  const { elementTypes } = useElementTypesContext();

  const [deleteItem, setDeleteItem] = useState<string | null>(null);

  // gkobluk: please fix type
  const {
    children: { body, header },
  } = elementModel as any;

  const actionsBody = useMemo(
    () =>
      body.elements.find((element: { name: string }) =>
        element.name.startsWith(TABLE_BODY_ACTIONS_NAME),
      ),
    [body],
  );

  const actionsList = (actionsBody?.children?.content?.elements ||
    []) as TElementModelWithPosition[];

  const deleteElement = {
    id: `table_body_delete_button_${uuidv4().split("-")[0]}`,
    name: TABLE_BODY_DELETE_BUTTON,
    type: { name: "default_delete_button" },
    config: {
      dataSource: {
        elementId: elementModel.id,
        fieldPath: [elementModel.config.dataSource.identifierName],
      },
      showWarning: true,
      icon: "delete",
      color: "secondary",
      placement: "top",
      disabled: buildCustomExpressionValue(`!props.metadata.canDelete`),
    },
    i18n: {
      en: {
        label: deleteActionLabel,
        title: deleteActionTitle,
        warning: deleteActionWarning,
        cancelTitle: deleteActionCancelTitle,
        submitTitle: deleteActionSubmitTitle,
      },
    },
    position: {
      row: 1,
      width: 1,
      column: 1,
      height: 1,
    },
    children: {},
  };

  const deleteAction = actionsList.find((element: { name: string }) =>
    element.name.startsWith(TABLE_BODY_DELETE_BUTTON),
  );

  const actionItems = deleteAction
    ? actionsList
    : [deleteElement, ...actionsList];

  const generateActionColumn = (
    initialChildrenElements: TDefaultElementChild[] = [],
  ) => {
    const position = {
      column: header.elements.length + 1,
      row: 1,
      width: 1,
      height: 1,
    };

    // create header column
    const defaultElement = {
      name: TABLE_HEADER_NAME,
      type: "default_table_header_cell",
      config: {
        dataSource: {
          fieldName: "actions",
          sortable: false,
        },
        align: "center",
        width: "1px",
      },
      i18n: {
        [DEFAULT_LANGUAGE_CODE]: { label: "Actions" },
      },
      position: {
        column: header.elements.length + 1,
        row: 1,
        width: 1,
        height: 1,
      },
    };

    createElement(
      elementTypes,
      elementTypes.default_table_header_cell,
      page!,
      position,
      elementModel,
      defaultElement,
      TABLE_HEADER_NAME,
      "header",
      true,
    );

    createElement(
      elementTypes,
      elementTypes.default_button_group,
      page!,
      position,
      elementModel,
      {
        name: "table_body_actions",
        config: {
          size: "small",
        },
        children: {
          content: {
            elements: initialChildrenElements,
          },
        },
      },
      "table_body_actions",
      "body",
      true,
    );
  };

  const updateActionColumnBody = (newActionsBody: any) => {
    updateChildren(
      elementModel,
      [
        ...body.elements.filter(
          (element: { name: string }) =>
            !element.name.startsWith(TABLE_BODY_ACTIONS_NAME),
        ),
        newActionsBody,
      ],
      page!,
      "body",
    );
  };

  useEffect(() => {
    // Remove the actions column & body if no action element is present
    if (actionsBody && actionsList.length === 0) {
      updateChildren(
        elementModel,
        body.elements.filter((element: TElementModelWithPosition) =>
          element.name.startsWith(TABLE_BODY_ACTIONS_NAME),
        ),
        page!,
        "body",
      );

      updateChildren(
        elementModel,
        header.elements.filter(
          (column: IColumn) => !column.name.startsWith(TABLE_HEADER_NAME),
        ),
        page!,
        "header",
      );
    }
  }, [actionsBody]);

  const onSwitchDeleteAction = () => {
    if (actionsBody) {
      const newActionsBody = {
        ...actionsBody,
        children: {
          content: {
            elements: deleteAction
              ? actionsList.filter(
                  (element: { name: string }) =>
                    element.name !== deleteElement.name,
                )
              : [deleteElement, ...actionsList],
          },
        },
      };
      updateActionColumnBody(newActionsBody);
    } else {
      generateActionColumn([deleteElement]);
    }
  };

  const onActionAdd = () => {
    const actionElement = {
      id: `action_button_${uuidv4().split("-")[0]}`,
      name: "action_button",
      type: elementTypes.default_internal_link_button,
      i18n: {
        en: {
          label: "Action Link",
        },
      },
      config: {
        icon: "link",
        color: "primary",
        linkTo: {
          pageId: null,
          params: undefined,
        },
        placement: "top",
      },
      children: {},
      position: {
        row: 1,
        width: 1,
        column: 1,
        height: 1,
      },
    };
    if (actionsBody) {
      const newActionsBody = {
        ...actionsBody,
        children: {
          content: {
            elements: [...actionItems, actionElement],
          },
        },
      };
      updateActionColumnBody(newActionsBody);
    } else {
      generateActionColumn([actionElement]);
    }
  };

  const handleDelete = (id: string) => setDeleteItem(id);
  const handleCloseDialog = () => setDeleteItem(null);

  const onActionDelete = useCallback(() => {
    if (deleteItem) {
      const newActionsBody = {
        ...actionsBody,
        children: {
          content: {
            elements: actionsList.filter(
              (element: { id: string }) => element.id !== deleteItem,
            ),
          },
        },
      };
      updateActionColumnBody(newActionsBody);
    }
    setDeleteItem(null);
  }, [deleteItem, updateActionColumnBody]);

  const onActionsRefresh = useCallback(() => {
    const newActionsBody = {
      ...actionsBody,
      children: {
        content: {
          elements: actionsList,
        },
      },
    };
    updateActionColumnBody(newActionsBody);
  }, [actionsList, updateActionColumnBody]);

  function onActionSelect(index: number) {
    const columnElement: TElementModelWithPosition | undefined =
      actionItems[index];

    if (columnElement) {
      const elementType = elementTypes[columnElement.type.name];
      if (elementType) {
        selectElement(columnElement, elementType, page!);
      }
    }
  }
  return (
    <ActionsProvider
      value={{
        deleteChecked: Boolean(deleteAction),
        actionItems,
        handleDelete,
        onActionSelect,
        onSwitchDeleteAction,
        onActionsRefresh,
      }}
    >
      <Section
        classes={{ content: actionsContent }}
        headerAction={
          <Box display="flex" alignItems="center">
            <IconButton
              icon="add"
              onClick={onActionAdd}
              color="primary"
              tooltip={addActionTooltip}
            />
          </Box>
        }
        title={actionsTitle}
        wrapped={true}
        defaultOpened={false}
      >
        <Actions />
      </Section>
      <DialogWrapper
        open={!!deleteItem}
        title={dialogTitle}
        contentText={`${dialogContentText} ${deleteItem}?`}
        submitTitle={DeleteButton}
        handleSubmit={onActionDelete}
        cancelTitle={CancelButton}
        handleClose={handleCloseDialog}
        fullWidth={true}
      />
    </ActionsProvider>
  );
});
