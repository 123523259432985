import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Subform table",
      description: "Used for 1:n data editing",
    },
  },
  defaultSize: {
    width: 4,
    height: 3,
  },
  minSize: {
    width: 3,
    height: 3,
  },
};
