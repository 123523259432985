import { memo, useMemo } from "react";

import { CallReceived, FiberManualRecord, VpnKey } from "@mui/icons-material";
import {
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";

import { useERDTranslation } from "./translation";
import { Column } from "./types";
import { sortColumns } from "./utils";

type TableMetadataProps = {
  columns: Column[];
};

export const TableColumns = memo<TableMetadataProps>(({ columns }) => {
  const translation = useERDTranslation();
  const items = useMemo(
    () =>
      sortColumns(columns)?.map((column) => (
        <ListItem alignItems="flex-start" key={column.name} divider>
          <ListItemIcon>
            {column.primaryKey ? (
              <Tooltip title={translation.primaryKeyTooltip}>
                <VpnKey color="primary" />
              </Tooltip>
            ) : column.foreignKey ? (
              <Tooltip title={translation.foreignKeyTooltip}>
                <CallReceived color="secondary" />
              </Tooltip>
            ) : (
              <FiberManualRecord color="disabled" />
            )}
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                {column.name}
                {column.unique && (
                  <>
                    {" "}
                    <Chip
                      color="default"
                      size="small"
                      label={<Typography variant="overline">UNIQUE</Typography>}
                    />
                  </>
                )}
              </>
            }
            secondary={
              column.workflowConfigured && (
                <Typography variant="caption">
                  {translation.workflowTooltip}
                </Typography>
              )
            }
          />
          <ListItemText
            primary={
              <>
                <Typography variant="overline">{column.type}</Typography>
                {(!column.nullable || column.primaryKey) && (
                  <Tooltip title={translation.notNullTooltip}>
                    <Typography variant="overline">
                      {!column.nullable || column.primaryKey ? " *" : ""}
                    </Typography>
                  </Tooltip>
                )}
              </>
            }
            style={{ textAlign: "right" }}
          />
        </ListItem>
      )),
    [columns, translation],
  );

  return <>{items}</>;
});
