import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

type Props = {
  menuWidth: string;
};

const getMenuWidth = ({ menuWidth }: { menuWidth: string }) => menuWidth;

const useStyles = makeStyles<Props>()((theme: Theme, { menuWidth }) => ({
  "content-left": {
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
    willChange: "margin",
  },
  "contentShift-left": {
    marginLeft: getMenuWidth({ menuWidth }),
  },
  footer: {
    height: theme.spacing(5),
    minHeight: theme.spacing(5),
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    "& a": {
      color: theme.palette.primary.contrastText,
      fontWeight: "bolder",
      "&:hover": {
        color: theme.palette.primary.contrastText,
      },
    },
    "& p": {
      margin: 0,
    },
  },
}));

export default useStyles;
