import { ChangeEvent, memo, useCallback } from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { EXPRESSION_TAG } from "core/constants";
import { Section, useElementEditorContext } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";
import { isCustomExpression } from "core/types/customExpression";
import { clampFractionValues } from "elementTypes/common/clampFractionValues";

import { UntransformedNumberFieldConfig } from "../../types";
import { useNumberFieldEditorTranslation } from "../translation";

export const ConfigComponent = memo(() => {
  const {
    elementModel: {
      id,
      config,
      config: { value, isPercentage, precision, prefix, suffix, highlight },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedNumberFieldConfig>();
  const translation = useNumberFieldEditorTranslation();

  const handleValueChange = useCallback(
    (newValue: string | number | boolean) =>
      changeConfigValue(
        "value",
        isCustomExpression(String(newValue))
          ? String(newValue)
          : Number(newValue),
      ),
    [changeConfigValue],
  );
  const handleHighlightChange = useCallback(
    (newValue: string) => changeConfigValue("highlight", newValue),
    [changeConfigValue],
  );

  const handleIsPercentageInputChange = useCallback(
    () => changeConfigValue("isPercentage", !isPercentage),
    [changeConfigValue, isPercentage],
  );

  const handleTextFieldChange = useCallback(
    (inputName: "precision" | "prefix" | "suffix") =>
      (e: ChangeEvent<HTMLInputElement>) =>
        changeConfigValue(inputName, e.target.value),
    [changeConfigValue],
  );

  const handlePrecisionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;

      if (newValue === "") {
        changeConfigValue("precision", null);
      } else {
        changeConfigValue(
          "precision",
          clampFractionValues(parseInt(newValue, 10)),
        );
      }
    },
    [changeConfigValue],
  );

  const handleHighlightRadioChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue("highlight", e.target.value),
    [changeConfigValue],
  );

  return (
    <>
      <Section title={"Value"} wrapped>
        <TableColumnEditor
          value={value}
          onChange={handleValueChange}
          id={id}
          allowedDataTypes={["number"]}
          allowedDataTypeIsArray={false}
        />
        <CustomExpressionEditor
          value={value}
          config={config}
          onChange={handleValueChange}
          label={translation.valueLabel}
          disableSwitcher
        />
      </Section>
      <Section title={"Formatting"} wrapped>
        <FormControlLabel
          control={
            <Switch
              checked={isPercentage}
              onChange={handleIsPercentageInputChange}
            />
          }
          label={translation.isPercentageLabel}
          name="isPercentage"
        />
        <Box display="flex" flexDirection="column" gap={1}>
          <TextField
            value={precision ?? ""}
            type="number"
            name="precision"
            label={translation.precisionLabel}
            fullWidth
            onChange={handlePrecisionChange}
          />
          <TextField
            value={prefix}
            name="prefix"
            label={translation.prefixLabel}
            fullWidth
            onChange={handleTextFieldChange("prefix")}
          />
          <TextField
            value={suffix}
            name="suffix"
            label={translation.suffixLabel}
            fullWidth
            onChange={handleTextFieldChange("suffix")}
          />
        </Box>
        <RadioGroup row value={highlight} onChange={handleHighlightRadioChange}>
          <FormControlLabel
            control={<Radio color="primary" />}
            label={translation.negativeValueLabel}
            value={`${EXPRESSION_TAG} element.value < 0`}
          />
          <FormControlLabel
            control={<Radio color="primary" />}
            label={translation.zeroValueLabel}
            value={`${EXPRESSION_TAG} element.value === 0`}
          />
        </RadioGroup>
        <CustomExpressionEditor
          value={highlight}
          config={config}
          onChange={handleHighlightChange}
          label={translation.highlightLabel}
          disableSwitcher
          switcherDisabled={!highlight}
        />
      </Section>
    </>
  );
});
