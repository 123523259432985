import { memo, useState } from "react";
import {
  Section,
  TranslationEditor,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";

import { useChartEditorTranslation } from "../../translation";
import { PieChartConfig } from "../../types";

export const TranslationComponent = memo(() => {
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);
  const {
    elementModel: { i18n },
    changeTranslation,
  } = useElementEditorContext<PieChartConfig>();
  const editorTranslation = useEditorTranslation();
  const translation = useChartEditorTranslation();

  const changeLanguage = (l: Language) => setLang(l);

  return (
    <Section
      title={editorTranslation.translationTitle}
      wrapped={true}
      cardActions={
        <LanguageSwitch
          language={lang}
          changeLanguage={changeLanguage}
          colorVariant="dark"
          fullWidth={true}
        />
      }
    >
      <TranslationEditor
        translationKey="title"
        i18n={i18n}
        changeTranslation={changeTranslation}
        label={translation.titleLabel}
        language={lang}
      />
    </Section>
  );
});
