import { memo } from "react";
import { Palette, useTheme } from "@mui/material/styles";
import { MiniMap as FlowMiniMap, Node } from "reactflow";

const nodeColor = (palette: Palette) => (node: Node) => {
  const grey100 = palette.grey[100];
  const grey400 = palette.grey[400];
  const grey500 = palette.grey[500];
  const black = palette.common.black;

  const colorByType = {
    schema: grey100,
    table: grey400,
    view: grey500,
    state: grey400,
    start: black,
    end: black,
    default: grey400,
  };
  const type = (node?.type ?? "default") as keyof typeof colorByType;
  return colorByType[type];
};

export const MiniMap = memo(() => {
  const { palette } = useTheme();

  return (
    <FlowMiniMap
      nodeStrokeColor={palette.common.black}
      nodeStrokeWidth={10}
      nodeColor={nodeColor(palette)}
      zoomable
      pannable
    />
  );
});
