import { createUseTranslation } from "core/session/translation";

export const arrayTextFieldEditorTranslation = {
  en: {
    maxLengthLabel: "Max Length",
    stylingSectionTitle: "Styling",
    colorInputLabel: "Color",
    sizeInputLabel: "Size",
    variantInputLabel: "Variant",
    primaryLabel: "Primary",
    secondaryLabel: "Secondary",
    defaultLabel: "Default",
    filledLabel: "Filled",
    outlinedLabel: "Outlined",
    smallLabel: "Small",
    mediumLabel: "Medium",
  },
};

export const useArrayTextFieldEditorTranslation = createUseTranslation(
  arrayTextFieldEditorTranslation,
);
