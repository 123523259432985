import * as t from "io-ts";
import { types } from "core/runtime-typing";
import { IElement, IElementArrayChild, IElementSingleChild } from "core/types";
import { SelectorTypes } from "core/types/element";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";

import { FormInputConfig } from "../common";

export const geoJSONInputSelectors: SelectorTypes<GeoJSONInputConfig> = {
  value: types.nullable(types.anyRecord()),
  errors: types.any("The data validation errors"),
  touched: types.boolean(
    "Indicates whether the user has typed anything in the input",
  ),
  disabled: types.optional(types.boolean()),
};

export const ownConfig = t.partial({
  allowDelete: t.boolean,
  allowLine: t.boolean,
  allowPolygon: t.boolean,
  allowRectangle: t.boolean,
  allowDrag: t.boolean,
  allowMarker: t.boolean,
  allowText: t.boolean,
  tileLayerUrl: t.union([t.null, t.string]),
  maximumFeatures: t.union([t.null, t.number]),
  geoJSONdefaultValue: t.string,
  latitude: t.number,
  longitude: t.number,
  zoom: t.number,
});

export type OwnConfig = t.TypeOf<typeof ownConfig>;

export const GeoJSONInputConfig = t.intersection([
  WithOptionalFieldDataSourceConfig(t.union([t.null, t.UnknownRecord])),
  FormInputConfig,
  ownConfig,
]);

export type GeoJSONInputConfig = t.TypeOf<typeof GeoJSONInputConfig>;

export const GeoJSONInputTranslationKeys = ["label"] as const;

export type GeoJSONInputTranslationKeys =
  (typeof GeoJSONInputTranslationKeys)[number];

export type GetModesFromConfig<T extends readonly string[]> = {
  [K in T[number]]: `allow${Capitalize<K>}`;
}[T[number]];

export type GeoJSONInput = IElement<
  GeoJSONInputConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  GeoJSONInputTranslationKeys
>;
