import { ChangeEvent, memo } from "react";
import TextField from "@mui/material/TextField";
import {
  Section,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { Language, Translation } from "core/types";
import { getTranslatedText } from "core/utils/element-utils";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";

import { UntransformedAutocompleteInputConfig } from "../../types";
import { useAutocompleteEditorTranslation } from "../translation";

type TranslationComponentProps = {
  language: Language;
  handleChangeLanguage: (lang: Language) => void;
};

export const TranslationComponent = memo<TranslationComponentProps>(
  ({ language, handleChangeLanguage }) => {
    const {
      elementModel: { i18n },
      changeTranslation,
    } = useElementEditorContext<UntransformedAutocompleteInputConfig>();
    const editorTranslation = useEditorTranslation();
    const translation = useAutocompleteEditorTranslation();

    const value =
      getTranslatedText(language, i18n as Translation<"label">, "label") ?? "";

    const handleInputChange = ({
      target: { value: newValue },
    }: ChangeEvent<HTMLInputElement>) => {
      changeTranslation({
        ...(i18n as Translation<"label">),
        [language.code]: {
          label: newValue,
        },
      });
    };

    return (
      <Section
        title={editorTranslation.translationTitle}
        wrapped={true}
        cardActions={
          <LanguageSwitch
            language={language}
            changeLanguage={handleChangeLanguage}
            colorVariant="dark"
            fullWidth={true}
          />
        }
      >
        <TextField
          value={value}
          name="label"
          label={translation.labelLabel}
          fullWidth={true}
          onChange={handleInputChange}
        />
      </Section>
    );
  },
);
