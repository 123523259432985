import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Markdown Text",
      description: "A Markdown text field with translations",
    },
    de: {
      description: "Ein Markdown Textfeld mit Übersetzungen",
    },
  },
  defaultSize: {
    width: 1,
    height: 1,
  },
};
