import { FormDataSource } from "elementInterfaces";

import { AutocompleteInput } from "../../types";

import { buildActions } from "./actions";
import { buildAutocompleteInterface } from "./autoComplete";
import { buildReducer } from "./reducer";
import { buildSaga } from "./saga";
import { buildSelectors } from "./selectors";

export function controlledReduxModuleFactory(
  path: string[],
  element: AutocompleteInput,
) {
  const scope = path.join(".");
  const { elementId, fieldPath } = element.config.dataSource!;

  const dataSource = FormDataSource.get(elementId);

  const { actions, types } = buildActions(scope, fieldPath, dataSource);
  const reducer = buildReducer(types);
  const selectors = buildSelectors(path, fieldPath, dataSource, element);
  const saga = buildSaga(actions, types, element, selectors);

  return {
    reducer,
    actions,
    selectors,
    saga,
    interfaces: buildAutocompleteInterface(actions),
  };
}
