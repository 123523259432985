import { UseMutationOptions } from "@tanstack/react-query";
import AdminService from "services/admin";
import { getLoginConfig } from "services/api";

import { SettingsConfig } from "../../staticPages/admin/pages/settings/types";
import {
  Query,
  useAuthenticatedMutation,
  useAuthenticatedQuery,
} from "../utils";

import { QueryKeys } from "./queryKeys";

const services = AdminService.getInstance();
export const fetchLoginConfig: Query<SettingsConfig> = {
  queryKey: QueryKeys.fetchLoginConfig,
  queryFn: getLoginConfig,
};

export const useLoginConfig = () => useAuthenticatedQuery(fetchLoginConfig);

export const useUpdateConfig = (
  options: UseMutationOptions<SettingsConfig, unknown, SettingsConfig>,
) => useAuthenticatedMutation(services.editLoginConfig, options);
