import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { AllServices } from "core/buildStore";
import { selectors as sessionSelectors } from "core/session/reduxModule";

import { getServerError } from "../../../core/utils/api";
import { InternalLinkField } from "../types";

import { Actions, Selectors, Types } from "./types";

export function buildSaga(
  actions: Actions,
  types: Types,
  element: InternalLinkField,
  selectors: Selectors,
) {
  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);

    const identifierValue: number | string | null = yield select(
      selectors.identifier,
    );

    const { dataSource } = element.config;
    if (!dataSource || identifierValue === null) {
      yield put(actions.loadSuccess(null));
      return;
    }

    const metadata: Record<
      "order" | "offset" | "limit",
      string | number | null
    > = {
      order: dataSource.identifierName
        ? `${dataSource.identifierName}.desc`
        : null,
      offset: 0,
      limit: 1,
    };

    const identifierName = dataSource.identifierName as keyof typeof metadata;
    if (dataSource.identifierName && identifierValue !== undefined) {
      metadata[identifierName] = `eq.${identifierValue}`;
    }

    try {
      const data: Record<string, unknown>[] = yield call(
        services.api.loadViewData,
        token,
        {
          viewName: dataSource.viewName,
          params: metadata,
        },
      );
      yield put(actions.loadSuccess(data[0]));
    } catch (error) {
      yield put(actions.loadError(getServerError(error)));
    }
  }

  return function* mainSaga() {
    yield all([takeLatest(types.LOAD, loadSaga)]);

    yield put(actions.load());
  };
}
