import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { selectorScoper } from "core/utils/redux";
import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";
import { getDisabledValueFromConfig } from "elementTypes/common/utils";
import { IState, SubFormTable } from "../../types";

export function buildSelectors(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
  element: SubFormTable,
  path: string[],
) {
  const scopeSelector = selectorScoper<IState>(path);

  const value = (state: any) =>
    dataSource.createFieldValueSelector(fieldPath, {
      defaultValue: [],
    })(state) as Record<string, unknown>[];

  const errors = (state: any) =>
    dataSource.createFieldErrorSelector(fieldPath)(state);

  const touched = (state: any) =>
    dataSource.createFieldTouchedSelector(fieldPath)(state);

  const disabled = (state?: any) =>
    dataSource.isReadOnly || getDisabledValueFromConfig(state, element.config);

  const identifierFieldName =
    dataSource.references[element.config.dataSource.fieldPath[0]]
      .identifierFieldName;

  const referencingFieldName =
    dataSource.references[element.config.dataSource.fieldPath[0]]
      .referencingFieldName;

  const columnVisibilityModel = referencingFieldName
    ? ({
        [referencingFieldName]: false,
      } as GridColumnVisibilityModel)
    : undefined;

  const selected = (state: any) => scopeSelector(state).selected;

  return {
    value,
    errors,
    touched,
    disabled,
    selected,
    columnVisibilityModel: () => columnVisibilityModel,
    identifierFieldName: () => identifierFieldName,
  };
}
