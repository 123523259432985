import { ElementGroup, IRawElementType } from "core";

import { EChartsTranslationKeys } from "../common/Echarts";

import Component from "./container";
import {
  EchartsPieChartEditor,
  defaultElement,
  editorMetadata,
} from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import { PieChartConfig, selectorTypes } from "./types";
import { getQueriesFromConfig } from "./utils";

const elementType: IRawElementType = {
  name: "echarts_pie_chart",
  component: Component,
  group: ElementGroup.Basic,
  defaultElement,
  editorComponent: EchartsPieChartEditor,
  reduxModuleFactory,
  editable: true,
  configType: PieChartConfig,
  editorMetadata,
  translationKeys: EChartsTranslationKeys,
  selectorTypes,
  getQueriesFromConfig,
};

export default elementType;
