import { createAction, createActionTypeScoper } from "core/utils/redux";
import { StorageFileMetadata } from "services/api/types/FileStorage";

export function buildUncontrolledActions(path: string[]) {
  const scope = path.join(".");
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    CLEAR: scopeActionType("CLEAR"),
    CLEAR_ALL: scopeActionType("CLEAR_ALL"),
    CLEAR_SUCCESS: scopeActionType("CLEAR_SUCCESS"),
    FETCH_METADATA_ERROR: scopeActionType("FETCH_METADATA_ERROR"),
    FETCH_METADATA_SUCCESS: scopeActionType("FETCH_METADATA_SUCCESS"),
    FETCH_METADATA: scopeActionType("FETCH_METADATA"),
    UPLOAD_ERROR: scopeActionType("UPLOAD_ERROR"),
    UPLOAD_SUCCESS: scopeActionType("UPLOAD_SUCCESS"),
    UPLOAD: scopeActionType("UPLOAD"),
    CHANGE_VALUE: scopeActionType("CHANGE_VALUE"),
  };

  const actions = {
    changeValue: createAction(types.CHANGE_VALUE, (value: string[] | null) => ({
      value,
    })),
    upload: createAction(types.UPLOAD, (files: File[] | null | null) => ({
      files,
    })),
    uploadSuccess: createAction(
      types.UPLOAD_SUCCESS,
      (metadata: StorageFileMetadata[]) => ({
        metadata,
      }),
    ),
    uploadError: createAction(types.UPLOAD_ERROR, (error: any) => ({ error })),
    clear: createAction(types.CLEAR, (fileName: string) => ({ fileName })),
    clearAll: createAction(types.CLEAR_ALL),
    clearSuccess: createAction(types.CLEAR_SUCCESS),
    fetchMetadata: createAction(types.FETCH_METADATA),
    fetchMetadataSuccess: createAction(
      types.FETCH_METADATA_SUCCESS,
      (metadata: StorageFileMetadata[] | null) => ({
        metadata,
      }),
    ),
    fetchMetadataError: createAction(
      types.FETCH_METADATA_ERROR,
      (error: any) => ({ error }),
    ),
  };

  return { types, actions };
}
