import { Implementation, createElementInterface } from "core";
import { AnyFluxStandardAction } from "core/utils/redux";

interface ModalDialogInterface {
  toggleModal: () => AnyFluxStandardAction;
}

export const ModalDialog = createElementInterface<ModalDialogInterface>();

export type ModalDialogInterfaceImplementation = Implementation<
  typeof ModalDialog
>;
