import { MouseEventHandler, createRef, memo, useEffect } from "react";
import Icon from "@mui/icons-material/Timeline";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { FixedSizeList, areEqual } from "react-window";
import { Node } from "reactflow";
import { IFixedRow } from "core";
import IconButton from "elementTypes/common/IconButton";
import { usePrevious } from "utils/hooks";
import { useDimensions } from "utils/hooks/useDimensions";

import { EmptyData } from "../../components/EmptyData";
import { useStyles } from "../../components/styles";
import { CreateNewStateBtn } from "../component";
import { useWorkflowTranslations } from "../translation";
import { getListWithIndex } from "../utils";

import { NodeData } from "./StateViewer/types";

type Props = {
  list?: NodeData[];
  onElementSelect: (el: { type: "state"; id: string }) => void;
  onFocusState: (st: string | number) => void;
};

const ITEM_SIZE = 44;

export const WorkflowLandingPage = memo<Props>(
  ({ list, onElementSelect, onFocusState }) => {
    const listRef = createRef<FixedSizeList>();
    const translation = useWorkflowTranslations();
    const { classes, cx } = useStyles();

    const Row = memo<IFixedRow<NodeData>>(({ data: items, index, style }) => {
      const item = items[index];
      const title = item?.label;

      const handleEdit: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();
        onElementSelect({ type: "state", id: String(item.id) });
      };

      const handleFocus = () => onFocusState(item.id);

      return (
        <Box
          key={item.id}
          className={cx(classes.row, {
            [classes.oddRow]: Boolean(index % 2),
          })}
          style={{ ...style, cursor: "pointer" }}
          display="grid"
          gridTemplateColumns="1fr minmax(40px, max-content)"
          alignItems="center"
          onClick={handleFocus}
          pl={1}
          gap={1}
        >
          <Typography noWrap variant="button">
            {title}
          </Typography>
          <IconButton
            icon="edit"
            onClick={handleEdit}
            size="small"
            tooltip={translation.editStateTooltip}
            edge="end"
            sx={{ marginRight: 0.5 }}
          />
        </Box>
      );
    }, areEqual);

    const { ref: boxRef, ...size } = useDimensions();
    const listHeight =
      size?.height ?? ITEM_SIZE * Math.min(list?.length ?? 0, 10);
    const prevList = usePrevious(list ?? []);

    useEffect(() => {
      if (list && (list?.length ?? 0) > prevList.length && listRef.current) {
        const scrollToQuery = getListWithIndex(prevList, list) as Node<any> & {
          index: number;
        };
        if (scrollToQuery) {
          listRef.current.scrollToItem(scrollToQuery.index);
        }
      }
    }, [list, prevList, listRef]);

    return (
      <Box
        display="grid"
        gridTemplateRows="1fr 2fr"
        gap={1}
        height="100%"
        component="aside"
      >
        <Card variant="outlined" className={cx(classes.card)}>
          <CardContent style={{ overflowY: "auto" }}>
            <Typography variant="subtitle2" gutterBottom>
              {translation.workflowLandingPageTitle}
            </Typography>

            <Typography gutterBottom variant="body2">
              {translation.landingPageParagraph1}
            </Typography>

            <Typography variant="body2" gutterBottom>
              {translation.landingPageParagraph2}
            </Typography>

            <Typography variant="body2">
              {translation.landingPageParagraph3}
            </Typography>

            <Typography variant="body2">
              {translation.landingPageParagraph4}
            </Typography>
          </CardContent>
        </Card>
        <Card variant="outlined" className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            disableTypography
            title={
              <Typography variant="h5">{translation.stateListTitle}</Typography>
            }
            avatar={
              <Box display="flex">
                <Icon />
              </Box>
            }
            action={<CreateNewStateBtn isIcon />}
          />
          <Divider />
          {list?.length ? (
            <Box
              {...{ ref: boxRef }}
              height="100%"
              // fix height overflow when scrolling
              minHeight="0"
            >
              <FixedSizeList
                ref={listRef}
                height={listHeight}
                itemCount={list?.length}
                itemSize={ITEM_SIZE}
                width="100%"
                itemData={list}
                style={{ overflowX: "hidden" }}
              >
                {Row}
              </FixedSizeList>
            </Box>
          ) : (
            <EmptyData title={translation.emptyStateList} />
          )}
        </Card>
      </Box>
    );
  },
);
