import { ChangeEvent, ComponentProps, memo, useCallback } from "react";
import { Box, ToggleButtonGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import isBoolean from "lodash/isBoolean";

import { buildCustomExpressionValue, getBooleanExpressionValue } from "core";
import {
  IconAutocomplete,
  Section,
  useElementEditorContext,
} from "core/editor";

import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import { IconNameType } from "elementTypes/common/MuiIcon";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import {
  InternalLinkButtonConfig,
  UntransformedInternalLinkButtonConfig,
} from "../../types";
import { useInternalLinkButtonEditorTranslation } from "../translation";

export const DisplayComponent = memo(() => {
  const { displaySectionTitle, disabledInputLabel } =
    useInternalLinkButtonEditorTranslation();
  const {
    elementModel: {
      config: { icon, isIcon, disabled },
      config,
    },
    changeConfigValue,
  } = useElementEditorContext<InternalLinkButtonConfig>();

  const changeIcon = useCallback(
    (newIcon: UntransformedInternalLinkButtonConfig["icon"]) =>
      changeConfigValue("icon", newIcon),
    [changeConfigValue],
  );

  const handleChange = useCallback(
    (ev: any, nextIsIcon: "icon" | "button") =>
      changeConfigValue("isIcon", nextIsIcon === "icon"),
    [changeConfigValue],
  );

  const handleDisabledChange = (value: unknown) =>
    changeConfigValue("disabled", value);

  const toggleValue =
    isIcon || (isIcon === undefined && icon) ? "icon" : "button";

  const handleToggleModeDisabled = (isExpression: boolean) => {
    const nextVal = isExpression
      ? getBooleanExpressionValue(String(disabled))
      : buildCustomExpressionValue(String(disabled));
    handleDisabledChange(nextVal);
  };

  const disabledNonExpressionEditor: ComponentProps<
    typeof CustomExpressionEditor
  >["nonExpressionEditor"] = useCallback(
    ({ value, onChange }: NonExpressionEditorProps<boolean>) => {
      const handleValueChange = (
        _event: ChangeEvent<HTMLInputElement>,
        checked: boolean,
      ) => onChange(checked);

      return (
        <FormControlLabel
          control={
            <Switch
              checked={isBoolean(value) ? value : value === "true"}
              onChange={handleValueChange}
            />
          }
          label={disabledInputLabel}
        />
      );
    },
    [disabledInputLabel],
  );

  return (
    <Section title={displaySectionTitle} wrapped={true}>
      <IconAutocomplete
        label="Icon"
        value={icon as IconNameType}
        onChange={changeIcon}
      />
      <Box mt={1}>
        <ToggleButtonGroup
          exclusive
          onChange={handleChange}
          value={toggleValue}
          fullWidth
        >
          <ToggleButton value="button">Button</ToggleButton>
          <ToggleButton value="icon">Icon</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <CustomExpressionEditor
        value={String(disabled)}
        config={config}
        nonExpressionEditor={disabledNonExpressionEditor}
        onToggleMode={handleToggleModeDisabled}
        onChange={handleDisabledChange}
        label={disabledInputLabel}
        forceFlexChange
        isBoolean
      />
    </Section>
  );
});
