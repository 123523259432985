import { ChangeEvent, memo, useCallback } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import capitalize from "lodash/capitalize";
import { Section, useElementEditorContext } from "core/editor";

import { VariantSelector } from "core/editor/common/VariantSelector";
import { Autocomplete } from "../../../common/Autocomplete";
import { IAutocompleteValue } from "../../../common/Autocomplete/types";
import { UntransformedCircularProgressConfig } from "../../types";
import { useCircularProgressEditorTranslation } from "../translation";

import { useStyles } from "./styles";

const colorOptions = ["primary", "secondary", "inherit"];

const colorAutocompleteOptions = colorOptions.map((colorOption) => ({
  value: colorOption,
  label: capitalize(colorOption),
}));

export const StylingComponent = memo(() => {
  const {
    elementModel: {
      config: { size, thickness = 0, color },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCircularProgressConfig>();
  const translation = useCircularProgressEditorTranslation();
  const { classes } = useStyles();

  const handleChange = useCallback(
    (...params: Parameters<typeof changeConfigValue>) =>
      changeConfigValue(...params),
    [changeConfigValue],
  );

  const handleSizeInputChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) => handleChange("size", Number(newValue));

  const handleThicknessInputChange = (
    _event: Event,
    value: number | number[],
  ) => {
    if (typeof value === "number") {
      handleChange("thickness", value);
    }
  };

  const handleColorInputChange = (value: IAutocompleteValue) => {
    handleChange("color", value);
  };

  return (
    <Section title={translation.stylingTitle} wrapped={true} gap={1}>
      <TextField
        value={size}
        name="size"
        type="number"
        label={translation.sizeInputLabel}
        fullWidth={true}
        onChange={handleSizeInputChange}
      />
      <FormControl fullWidth={true} className={classes.topMargin}>
        <FormLabel>{translation.thicknessInputLabel}</FormLabel>
        <Slider
          value={thickness}
          onChange={handleThicknessInputChange}
          min={0}
          max={40}
          valueLabelDisplay="auto"
        />
      </FormControl>
      <Autocomplete
        options={colorAutocompleteOptions}
        onChange={handleColorInputChange}
        value={color}
        name="color"
        label={translation.colorInputLabel}
        isLoading={false}
        virtualizedList={true}
        isClearable={true}
      />
      <VariantSelector />
    </Section>
  );
});
