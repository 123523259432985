import { memo } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Slider, { SliderProps } from "@mui/material/Slider";
import { ConnectedReduxModuleProps } from "core";

import { DEFAULTS } from "./editor/utils";
import { ReduxModule } from "./reduxModule";
import { SliderInput } from "./types";

const DefaultSliderInput = memo<
  ConnectedReduxModuleProps<ReduxModule, SliderInput>
>(
  ({
    value = DEFAULTS.min,
    element: {
      config: {
        marks = DEFAULTS.marks,
        min = DEFAULTS.min,
        max = DEFAULTS.max,
        color = DEFAULTS.color,
        orientation = DEFAULTS.orientation,
        step = DEFAULTS.step,
        track = DEFAULTS.track,
        valueLabelDisplay,
      },
      i18n: { label },
    },
    changeValue,
    disabled,
    required,
  }) => {
    const handleValueChange = (_e: Event, newValue: number | number[]) => {
      // this component will only be able to handle single number inputs
      // a separate component should be used for range input
      if (typeof newValue === "number") {
        changeValue(newValue as number);
      }
    };

    const generatedMarks: SliderProps["marks"] = Array.isArray(marks)
      ? marks.map((i) => {
          if (typeof i === "number") {
            return {
              value: i,
              label: String(i),
            };
          } else {
            return i;
          }
        })
      : marks;

    const commonProps = {
      "aria-label": label,
      valueLabelDisplay:
        valueLabelDisplay ?? (disabled ? ("on" as const) : ("auto" as const)),
      value,
      onChange: handleValueChange,
      min,
      max,
      color,
      orientation,
      step,
      track,
    };

    return (
      <FormControl fullWidth={true} style={{ height: "100%" }}>
        <FormLabel required={required}>{label}</FormLabel>
        <Slider
          marks={generatedMarks}
          {...commonProps}
          disabled={disabled}
          data-disabled={disabled}
        />
      </FormControl>
    );
  },
);

export default DefaultSliderInput;
