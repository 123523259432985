import { HTMLAttributes, memo } from "react";
import { Box } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { RefCallBack } from "react-hook-form";
import { IconNameType, MuiIcon } from "elementTypes/common/MuiIcon";

import { Autocomplete } from "../../../../elementTypes/common/Autocomplete";
import {
  AutocompleteOption,
  IAutocompleteValue,
} from "../../../../elementTypes/common/Autocomplete/types";

import { iconsOptions } from "./icons";
import { useStyles } from "./styles";

type IconAutocompleteProps = {
  value?: IconNameType | null;
  label?: string;
  name?: string;
  onChange?: (iconName: IconNameType) => void;
  innerRef?: RefCallBack;
};

export const IconAutocomplete = memo<IconAutocompleteProps>(
  ({ label = "", value = "" as IconNameType, name, onChange, ...rest }) => {
    const {
      classes: { root },
    } = useStyles();

    const customRenderOption = (
      props: HTMLAttributes<HTMLLIElement>,
      option: AutocompleteOption,
    ) => (
      <li {...props} key={String(option.value)}>
        <div className={root}>
          <ListItemIcon>
            <MuiIcon
              icon={option.value as IconNameType}
              fontSize="large"
              color="primary"
            />
          </ListItemIcon>
          <ListItemText primary={option.label} />
        </div>
      </li>
    );

    const handleChange = (iconName: IAutocompleteValue) =>
      onChange?.(iconName as IconNameType);

    return (
      <Box>
        <Autocomplete
          value={value}
          options={iconsOptions}
          name={name ?? "icons"}
          label={label}
          isLoading={false}
          defaultItemSize={48}
          virtualizedList={true}
          isClearable={true}
          customRenderOption={customRenderOption}
          onChange={handleChange}
          {...(value && {
            startAdornment: <MuiIcon icon={value} fontSize="medium" />,
          })}
          {...rest}
        />
      </Box>
    );
  },
);
