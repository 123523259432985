import { ChangeEvent, memo } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { ToggleButtonProps } from "@mui/material";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";

import { ToggleButton } from "elementTypes/common/ToggleButton";
import { ASTERISK_SYMBOL, getColumnName } from "../common/utils";

import { Switcher } from "./components";
import { BoolInputProps } from "./container";
import { useStyles } from "./style";

export const DefaultBoolInput = memo<BoolInputProps>(
  ({
    value,
    element: {
      i18n: { label, labelRight, labelLeft },
      config: { dataSource, placement, isSwitch, isButton, fullWidth },
    },
    changeValue,
    disabled,
    errors,
    required,
    color,
  }) => {
    const handleValueChange = (
      _: ChangeEvent<unknown>,
      checked: boolean | null,
    ) => {
      const isNullable = isSwitch || isButton ? false : !required;
      const shouldSetNull = isNullable && value === false && checked;
      if (shouldSetNull) {
        changeValue(null);
      } else {
        changeValue(isButton ? !value : checked);
      }
    };

    const name = getColumnName(dataSource);
    const {
      classes: { labelControl },
    } = useStyles();

    /* If the input has `config.nullable` = true and value equal to null, the component appears indeterminate.
     * This does not set the native input element to indeterminate due to inconsistent behavior across browsers.
     * However, we set a data-indeterminate attribute on the input.
     * This will work only for the Checkbox component.
     */
    const indeterminate = Boolean(
      (value === null || value === undefined) && !required,
    );
    const component = isSwitch ? (
      <Switcher
        value={value || false}
        name={name}
        labelRight={labelRight}
        labelLeft={labelLeft}
        onChange={handleValueChange}
      />
    ) : (
      <Checkbox
        checked={value || false}
        value={name}
        name={name}
        datatype={"bool"}
        onChange={handleValueChange}
        indeterminate={indeterminate}
        color={
          (!color || color === "standard"
            ? "default"
            : color) as CheckboxProps["color"]
        }
      />
    );

    return (
      <FormControl
        required={required}
        error={Boolean(errors)}
        fullWidth={fullWidth}
        sx={{
          ...(fullWidth && {
            height: "100%",
          }),
        }}
      >
        {isButton ? (
          <ToggleButton
            value={value ?? false}
            selected={value ?? false}
            onChange={handleValueChange}
            color={(color ?? "standart") as ToggleButtonProps["color"]}
            fullWidth={fullWidth}
          >
            <CheckIcon />
            <>
              {label}
              {required && ASTERISK_SYMBOL}
            </>
          </ToggleButton>
        ) : (
          <FormControlLabel
            className={isSwitch && labelLeft ? "" : labelControl}
            control={component}
            disabled={disabled}
            label={
              label ? (
                <>
                  {label}
                  {required && ASTERISK_SYMBOL}
                </>
              ) : undefined
            }
            labelPlacement={placement}
          />
        )}
        {errors && <FormHelperText>{errors}</FormHelperText>}
      </FormControl>
    );
  },
);

export default DefaultBoolInput;
