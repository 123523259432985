import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  image: {
    height: "500px",
  },
  cardContent: {
    textAlign: "center",
  },
  logo: {
    maxWidth: "150px",
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
