import { createUseTranslation } from "core/session/translation";

export const commonStaticPagesTranslation = {
  en: {
    settingsAppBarLabel: "Settings",
    noAppsAvailableAppBarLabel: "",
  },
};

export const useCommonStaticPagesTranslation = createUseTranslation(
  commonStaticPagesTranslation,
);
