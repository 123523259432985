import { useRoles as useRolesFetch } from "queries/admin";
import { getRoleOptions } from "queries/admin/utils.ts";

export const useRoles = () => {
  const {
    data = [],
    isFetching,
    isInitialLoading,
    refetch,
  } = useRolesFetch({
    select: getRoleOptions,
  });

  const isLoading = isInitialLoading || isFetching;

  const handleRefreshClick = () => refetch();

  return {
    handleRefreshClick,
    isLoading,
    rolesListItems: data,
  };
};
