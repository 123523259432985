import { selectorScoper } from "core/utils/redux";

import { IState } from "./types";

export function buildSelectors(path: string[]) {
  const scopeSelector = selectorScoper<IState>(path);

  const indexSelected = (state: any) => scopeSelector(state).indexSelected;

  return {
    indexSelected,
  };
}
