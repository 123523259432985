import { ChangeEvent, ReactNode, memo, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { ToggleButtonGroup } from "@mui/material";
import camelCase from "lodash/camelCase";
import { assocPath, dissocPath, pathOr } from "ramda";

import { Section, useElementEditorContext } from "core/editor";
import { ColorInput } from "elementTypes/common/ColorInput";
import IconButton from "elementTypes/common/IconButton";
import { ToggleButton } from "elementTypes/common/ToggleButton";

import { UntransformedEchartConfig } from "../EchartsContainer";
import { DefaultTheme, defaultThemes } from "../theme";
import { useEchartsTranslation } from "../translation";

const toolboxTypes = [
  "saveAsImage",
  "restore",
  "dataView",
  "dataZoom",
  "magicType",
  // "brush",
];

const WHITE = "#FFFFFF";
const TRANSPARENT = "transparent";
const customThemeKeys = [
  ["backgroundColor"],
  ["title", "textStyle", "color"],
  ["legend", "textStyle", "color"],
];

export const ToolboxAndColors = memo(
  <UntransformedChartConfig extends UntransformedEchartConfig>({
    children,
  }: {
    children?: ReactNode;
  }) => {
    const {
      elementModel: {
        id,
        name,
        config: { toolbox = {}, themeName: initialThemeName, theme },
      },
      changeConfigValue,
    } = useElementEditorContext<UntransformedChartConfig>();

    const translation = useEchartsTranslation();

    const [themeType, setThemeType] = useState<"default" | "custom">(
      (theme?.color as string[])?.length ? "custom" : "default",
    );

    const changeTheme = (nextTheme: UntransformedEchartConfig["theme"]) => {
      changeConfigValue("theme", nextTheme);
      if (initialThemeName) {
        changeConfigValue("themeName", undefined);
      }
    };

    const handleToggle = (
      ev: ChangeEvent<HTMLInputElement>,
      checked: boolean,
    ) => {
      let nextToolbox: Record<string, unknown>;
      if (checked) {
        nextToolbox = assocPath(
          [ev.target.name, "show"],
          checked,
          toolbox ?? {},
        );
      } else {
        nextToolbox = dissocPath([ev.target.name], toolbox ?? {});
      }

      changeConfigValue("toolbox", nextToolbox);
    };

    const toolboxItems = toolboxTypes.map((option) => {
      const isChecked = !!(toolbox[option] as Record<"show", boolean>)?.show;

      return name === "echarts_pie_chart" &&
        ["dataZoom", "magicType"].includes(option) ? null : (
        <ListItem key={`Toolbox-${option}-${id}`}>
          <FormControlLabel
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: 0,
            }}
            label={translation?.[`${option}Tooltip`]}
            control={
              <Checkbox
                edge="end"
                name={option}
                onChange={handleToggle}
                checked={isChecked}
                inputProps={{ "aria-labelledby": option }}
              />
            }
            labelPlacement="start"
          />
        </ListItem>
      );
    });

    const handleChangeColor = (index: number, newColor: string | null) => {
      changeConfigValue(
        "theme",
        assocPath(["color", Number(index)], newColor ?? WHITE, theme),
      );
    };

    const colors = ((theme?.color as string[]) ?? []).map(
      (item: string, index: number) => {
        const handleDelete = () =>
          changeTheme(dissocPath(["color", Number(index)], theme));

        const handleColorChange = (value: string | null) =>
          handleChangeColor(index, value);

        return (
          <Box key={`${id}-${index}`} px={2} py={0}>
            <Typography>{item}</Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <ColorInput value={item} changeValue={handleColorChange} />
              <IconButton
                icon="delete_outline"
                tooltip={translation.deleteTooltip}
                onClick={handleDelete}
                edge="end"
              />
            </Box>
          </Box>
        );
      },
    );

    const handleAddColor = () =>
      changeTheme({
        ...theme,
        color: [...((theme?.color as string[]) ?? []), WHITE],
      });

    const handleChangeTheme = (key: string[]) => (newColor: string | null) =>
      changeTheme(assocPath(key, newColor ?? TRANSPARENT, theme));

    const handleThemeClick =
      (nextThemeName: DefaultTheme["themeName"]) => () => {
        changeConfigValue("themeName", nextThemeName);
        if (theme) {
          changeConfigValue("theme", undefined);
        }
      };

    const handleDoubleClick =
      (nextThemeName: DefaultTheme["themeName"]) => () => {
        const nextTheme = defaultThemes.find(
          (t) => t.themeName === nextThemeName,
        )?.theme;
        if (nextTheme) {
          changeTheme(nextTheme);
          changeConfigValue("themeName", undefined);
          setThemeType("custom");
        }
      };

    const handleThemeTypeChange = (
      _: unknown,
      nextThemeType: "default" | "custom",
    ) => setThemeType(nextThemeType);

    const handleRemoveColor = (key: string[]) => () =>
      handleChangeTheme(key)(TRANSPARENT);

    const isCustom = themeType === "custom";

    const customKeys = customThemeKeys.map((customThemeKey) => (
      <Box key={String(customThemeKey)} px={2} py={1}>
        <Typography>
          {translation[`${camelCase(customThemeKey.toString())}Label`]}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ColorInput
            value={pathOr(TRANSPARENT, customThemeKey, theme)}
            label={pathOr(TRANSPARENT, customThemeKey, theme)}
            changeValue={handleChangeTheme(customThemeKey)}
          />
          <IconButton
            icon="delete_outline"
            tooltip={translation.deleteTooltip}
            onClick={handleRemoveColor(customThemeKey)}
            edge="end"
          />
        </Box>
      </Box>
    ));

    return (
      <>
        <Section wrapped={false} title={translation.themeTitle}>
          <Box px={1}>
            <ToggleButtonGroup
              size="small"
              value={themeType}
              exclusive={true}
              onChange={handleThemeTypeChange}
              fullWidth
            >
              <ToggleButton fullWidth value="default">
                {translation.defaultBtn}
              </ToggleButton>
              <ToggleButton fullWidth value="custom">
                {translation.customBtn}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {isCustom ? (
            <>
              <ListItem key="Colors">
                <ListItemText primary={translation.colorsTitle} />
                <ListItemIcon>
                  <IconButton
                    icon="add"
                    tooltip={translation.addColorTooltip}
                    onClick={handleAddColor}
                  />
                </ListItemIcon>
              </ListItem>
              <Box width="100%" maxHeight={300} style={{ overflowY: "auto" }}>
                {colors}
              </Box>
              <Divider />
              {customKeys}
            </>
          ) : (
            <DefaultThemes
              onClick={handleThemeClick}
              onDoubleClick={handleDoubleClick}
              activeTheme={initialThemeName}
            />
          )}
        </Section>
        {children}
        <Section wrapped={false} title={translation.toolboxTitle}>
          <List dense>{toolboxItems}</List>
        </Section>
      </>
    );
  },
);

const DefaultThemes = memo<{
  onClick: (themeName: DefaultTheme["themeName"]) => () => void;
  onDoubleClick: (themeName: DefaultTheme["themeName"]) => () => void;
  activeTheme?: string;
}>(({ activeTheme, onClick, onDoubleClick }) => {
  const t = useEchartsTranslation();

  const items = defaultThemes.map(({ theme, themeName }, index) => (
    <Tooltip
      key={`bg-${theme.backgroundColor}-${index}`}
      title={t.doubleClickTooltip.replace("**", themeName)}
      placement="left"
    >
      <Box
        bgcolor={theme.backgroundColor}
        p={1}
        my={0.5}
        display="flex"
        justifyContent="space-between"
        gap={1}
        alignItems="center"
        borderRadius="5px"
        style={{
          cursor: "pointer",
        }}
        onClick={onClick(themeName)}
        border="1px solid"
        borderColor={activeTheme === themeName ? "secondary.main" : "divider"}
        onDoubleClick={onDoubleClick(themeName)}
      >
        {theme.color.slice(0, 8).map((color, k) => (
          <Box
            key={`${index}-color-${color}-${k}`}
            width={25}
            height={25}
            bgcolor={color}
            borderRadius="5px"
          />
        ))}
      </Box>
    </Tooltip>
  ));

  return (
    <Box width="100%" height="auto" p={1}>
      {items}
    </Box>
  );
});
