import { Implementation, createElementInterface } from "core";
import { AnyFluxStandardAction } from "core/utils/redux";

interface AutocompleteInterface {
  reset: () => AnyFluxStandardAction;
}

export const AutocompleteInterface =
  createElementInterface<AutocompleteInterface>();

export type AutocompleteInterfaceImplementation = Implementation<
  typeof AutocompleteInterface
>;
