import { Language } from "core/types";
import { getTranslatedTexts } from "../../utils/element-utils";
import { SessionLayoutProps } from "./types";

export const getPageTitle = (
  props: SessionLayoutProps & { language: Language },
) => {
  const { isStaticPage, isAdminPage, ui, page, language } = props;
  if (isStaticPage) {
    return isAdminPage ? "Administration" : "Settings";
  } else if (ui) {
    const uiTitle = getTranslatedTexts(language, ui.i18n).label || ui.name;
    const pageTitle = page
      ? getTranslatedTexts(language, page.i18n!).label || page.url
      : "";
    return page ? `${pageTitle} - ${uiTitle}` : uiTitle;
  }
  return "Loading";
};
