import { Box } from "@mui/material";
import Chip, { ChipProps } from "@mui/material/Chip";

import { useStyles } from "./style";

type Props = {
  values: (string | number)[];
} & ChipProps;

const getChips = (
  arr: (string | number)[],
  chipProps: ChipProps,
  className: string,
) =>
  arr.map((v, i) => (
    <Chip
      key={i}
      label={v}
      {...chipProps}
      className={`${className} ${chipProps.className}`}
    />
  ));

export const ArrayField = ({ values, ...chipProps }: Props) => {
  const {
    classes: { root, child },
  } = useStyles();

  if (!values || !values?.length) {
    return <span />;
  }

  const items = getChips(values ?? [], chipProps, child);

  return (
    <Box display="flex" gap={1} className={root}>
      {items}
    </Box>
  );
};
