import * as t from "io-ts";

import { selectors } from "core/editor/reduxModule";
import { Type, buildObjectViewType, types } from "core/runtime-typing";
import { TypeFactory } from "core/types";
import { Color } from "elementTypes/common/StyledTypography/utils";

export type IColor = Partial<Color> | "transparent";

export const DataSourceConfig = t.type({
  viewName: t.string,
});

export type DataSourceConfig = t.TypeOf<typeof DataSourceConfig>;

export const WithDataSourceConfig = t.type({
  dataSource: DataSourceConfig,
});

export type WithDataSourceConfig = t.TypeOf<typeof WithDataSourceConfig>;

export const WithOptionalDataSourceConfig = t.partial(
  WithDataSourceConfig.props,
);

export type WithOptionalDataSourceConfig = t.TypeOf<
  typeof WithOptionalDataSourceConfig
>;

export const getViewRowType =
  ({
    isArray = false,
    isNullable = false,
  } = {}): TypeFactory<WithOptionalDataSourceConfig> =>
  ({ config, state }) => {
    let type: Type;
    const viewName = config?.dataSource?.viewName;
    const viewList = selectors.viewList(state);
    if (!viewList) {
      // view list still loading, do not show any typing errors
      type = types.any();
    } else if (!viewName) {
      type = types.optional(types.null(), "No source query set");
    } else {
      const view = viewList.find((v) => v.name === viewName);
      if (!view) {
        throw new Error(`Invalid query ${viewName}`);
      }
      type = buildObjectViewType(view, "The data for the row");
      if (isArray) {
        type = types.array(type, "The data rows");
      }
      if (isNullable) {
        type = types.nullable(type, type.description);
      }
    }

    return type;
  };
