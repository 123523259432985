import { FC, memo } from "react";
import { useBeforeunload } from "react-beforeunload";

import { EditorProvider } from "../EditorContext";

import { EditorLayoutProps } from "./types";

// TODO: to check: we have EditorProvider & ElementEditorProvider, do we need both?
const EditorLayoutProvider = memo<EditorLayoutProps>(
  ({
    children,
    isLayoutChanged,
    changeConfigValue,
    changePositionValue,
    changeTranslationValue,
    setDraggableElement,
    unselectElement,
  }) => {
    useBeforeunload((event: Event) => {
      if (isLayoutChanged) {
        return event.preventDefault();
      }
    });

    return (
      <EditorProvider
        value={{
          changeConfigValue,
          changePositionValue,
          changeTranslationValue,
          setDraggableElement,
          unselectElement,
        }}
      >
        {children}
      </EditorProvider>
    );
  },
);

export const EditorContainer: FC<EditorLayoutProps> = (props) => {
  const { children, ...rest } = props;
  return <EditorLayoutProvider {...rest}>{children}</EditorLayoutProvider>;
};

EditorLayoutProvider.displayName = "EditorLayoutProvider";
