import { ReactNode, memo } from "react";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { IconNameType, MuiIcon } from "elementTypes/common/MuiIcon";

type UserInfo = {
  label: string;
  icon: IconNameType;
  value: string;
  secondaryAction?: ReactNode;
};

export const UserInfo = memo<UserInfo>(
  ({ label, icon, value, secondaryAction }) => {
    return (
      <ListItem secondaryAction={secondaryAction}>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "aliceblue" }}>
            <MuiIcon icon={icon} fontSize="medium" color="info" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={label} secondary={value} />
      </ListItem>
    );
  },
);
