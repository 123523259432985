import { selectorScoper } from "core/utils/redux";

import { GeoJSONField } from "../types";

import { IState } from "./types";

export function buildSelectors(path: string[], element: GeoJSONField) {
  const scopeSelector = selectorScoper<IState>(path);

  const loading = (state: any) => scopeSelector(state).loading;
  const data = (state: any) => scopeSelector(state).data;
  const error = (state: any) => scopeSelector(state).error;

  const responseFormat = (state: any) =>
    typeof element.config.dataSource?.responseFormat === "function"
      ? element.config.dataSource?.responseFormat?.(state)
      : element.config.dataSource?.responseFormat ?? "geo";

  const value = (state: any) => element.config.value?.(state);

  return {
    loading,
    data,
    error,
    responseFormat,
    value,
  };
}
