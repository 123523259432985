import { memo, useCallback } from "react";
import { Section, useElementEditorContext } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";

import IconButton from "elementTypes/common/IconButton";
import { useCustomChartEditorTranslation } from "../../translation";
import { UntransformedCustomChartConfig } from "../../types";

export const OptionComponent = memo(() => {
  const {
    elementModel: {
      config,
      config: { option },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCustomChartConfig>();
  const t = useCustomChartEditorTranslation();

  const handleExpressionValueChange = useCallback(
    (newValue: string) => changeConfigValue("option", newValue),
    [changeConfigValue],
  );

  return (
    <Section
      title={t.optionTitle}
      wrapped={true}
      headerAction={
        <IconButton
          icon={"content_paste_search"}
          aria-label={"Show Echarts Option Documentation"}
          href="https://echarts.apache.org/en/option.html"
          external
          tooltip={t.echartsDocsTooltip}
        />
      }
    >
      <CustomExpressionEditor
        value={option as any}
        config={config}
        onChange={handleExpressionValueChange}
        disableSwitcher={true}
      />
    </Section>
  );
});
