import { Theme } from "@mui/material";

import { makeStyles } from "tss-react/mui";

export const useQueryGroupStyles = makeStyles()((theme: Theme) => ({
  draggingItem: {
    border: `1px solid ${theme.palette.divider}`,
  },
  droppableStyle: {
    border: `2px dashed ${theme.palette.divider}`,
  },
  droppablePadding: {
    padding: theme.spacing(1, 0),
  },
}));
