import { memo, useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { AutocompleteRenderGroupParams } from "@mui/material/Autocomplete";
import ListSubheader from "@mui/material/ListSubheader";
import { TruncatedTypography } from "elementTypes/common/TruncatedTypography";

import { Autocomplete } from "../../../../elementTypes/common/Autocomplete";
import { IAutocompleteValue } from "../../../../elementTypes/common/Autocomplete/types";
import { useSessionContext } from "../../../session";
import { BoundActions, Translation } from "../../../types";
import { getTranslatedTexts } from "../../../utils/element-utils";
import { actions } from "../../reduxModule";

import { FieldsAutocomplete, IField } from "./components/FieldsAutocomplete";
import { mapStateToProps } from "./container";
import { customRenderOption } from "./utils";

type OwnProps = {
  viewLabel: string;
  viewValue?: string;
  fields?: IField[];
  onViewNameChange: (viewName: string) => void;
  onViewFieldChange?: (
    fieldName: string,
    fieldValue: IAutocompleteValue,
  ) => void;
  isClearable?: boolean;
};

type Props = ReturnType<typeof mapStateToProps> &
  BoundActions<typeof actions> &
  OwnProps;

export const defaultItemSize = 52;

const ViewAutoComplete = memo<Props>(
  ({
    viewList,
    viewValue = "",
    viewLabel,
    fields,
    error,
    isClearable,
    onViewFieldChange,
    onViewNameChange,
  }) => {
    const { language } = useSessionContext();
    const getTranslatedLabel = useCallback(
      (i18n: Translation<keyof any>) =>
        getTranslatedTexts(language, i18n).title,
      [language],
    );

    const selectOptions = viewList?.length
      ? viewList
          .sort((a, b) => -b.object.name.localeCompare(a.object.name))
          .map((view) => ({
            value: view.name,
            label: getTranslatedLabel(view.i18n),
          }))
      : [];

    const sortedList = selectOptions?.sort((a, b) =>
      a.label.localeCompare(b.label),
    );

    const handleViewChange = (value: IAutocompleteValue) => {
      onViewNameChange(value as string);
    };

    const renderGroup = (params: AutocompleteRenderGroupParams) => [
      <ListSubheader key={params.key} component="div" color="primary">
        <TruncatedTypography title={params.group} />
      </ListSubheader>,
      params.children,
    ];

    const onFieldChange = useCallback(
      (fieldName: string) => (newFieldValue: IAutocompleteValue) =>
        onViewFieldChange?.(fieldName, newFieldValue),
      [onViewFieldChange],
    );

    const parentView = viewList?.find((view) => view.name === viewValue);
    const viewFields = useMemo(
      () =>
        fields?.map((field) => (
          <FieldsAutocomplete
            key={field.name}
            view={parentView}
            onChange={onFieldChange(field.name)}
            disabled={Boolean(error)}
            getTranslatedLabel={getTranslatedLabel}
            {...field}
          />
        )),
      [fields, parentView, error, onFieldChange, getTranslatedLabel],
    );

    const isLoading = !error && !viewList;

    return (
      <Box display="flex" flexDirection="column" gap={2} paddingTop={1}>
        <Autocomplete
          options={sortedList}
          value={viewValue}
          label={viewLabel}
          name="view"
          onChange={handleViewChange}
          isLoading={isLoading}
          virtualizedList={true}
          renderGroup={renderGroup}
          error={error}
          defaultItemSize={defaultItemSize}
          customRenderOption={customRenderOption}
          isClearable={isClearable}
          disablePortal
        />
        {viewFields}
      </Box>
    );
  },
);

export default ViewAutoComplete;
