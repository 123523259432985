import { memo } from "react";
import {
  CircularProgress,
  ToggleButton as MuiToggleButton,
  ToggleButtonProps,
  Tooltip,
  TooltipProps,
} from "@mui/material";

type Props = {
  tooltip?: string;
  placement?: TooltipProps["placement"];
  fullWidth?: boolean;
  isFetching?: boolean;
} & ToggleButtonProps;

export const ToggleButton = memo<Props>(
  ({ tooltip, placement, fullWidth, isFetching, children, ...rest }) => {
    const btn = (
      <MuiToggleButton
        {...rest}
        {...(fullWidth && {
          sx: {
            flex: 1,
          },
        })}
      >
        {isFetching ? <CircularProgress size={24} color="inherit" /> : children}
      </MuiToggleButton>
    );

    return tooltip ? (
      <Tooltip title={tooltip} placement={placement}>
        {btn}
      </Tooltip>
    ) : (
      btn
    );
  },
);
