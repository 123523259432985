import * as t from "io-ts";
import { types } from "core/runtime-typing";
import { IElement, IElementArrayChild, IElementSingleChild } from "core/types";
import { SelectorTypes } from "core/types/element";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";
import { storageMultipleFileMetadataType } from "services/api/types/FileStorage";

import { FormInputConfig } from "../common";

export const multipleFileInputSelectors: SelectorTypes<MultipleFileInputConfig> =
  {
    value: types.nullable(types.array(types.string())), // TODO: is this type string?
    errors: types.any(),
    touched: types.boolean(),
    loading: types.boolean(),
    metadata: types.nullable(
      types.union([types.array(storageMultipleFileMetadataType), types.any()]),
    ),
    files: types.nullable(types.array(types.file())),
    uploadError: types.nullable(types.string()),
    metadataError: types.nullable(types.string()),
  };

export const MultipleFileInputConfig = t.intersection([
  WithOptionalFieldDataSourceConfig(t.string),
  FormInputConfig,
  t.type({
    accessGroupName: t.string,
    typeGroupName: t.string,
  }),
  t.partial({
    accept: t.string,
    name: t.string,
  }),
]);

export type MultipleFileInputConfig = t.TypeOf<typeof MultipleFileInputConfig>;

export const MultipleFileInputTranslationKeys = ["label"] as const;

export type MultipleFileInputTranslationKeys =
  (typeof MultipleFileInputTranslationKeys)[number];

export type MultipleFileInput = IElement<
  MultipleFileInputConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  MultipleFileInputTranslationKeys
>;

export enum MultipleFileDialogType {
  UPLOAD = "UPLOAD",
}
