import { TypeFactory } from "core";
import { Type, types } from "core/runtime-typing";
import { mapGeneralType } from "core/runtime-typing/utils";
import { SubFormTableConfig } from "./types";

export const getFieldRowType: TypeFactory<SubFormTableConfig> = ({
  config,
}) => {
  const type: Type = types.nullable(
    types.interface(
      config.fields.reduce((acc, next) => {
        const fieldType = mapGeneralType(next.generalType);
        return {
          ...acc,
          [next.name]: !next.nullable
            ? fieldType
            : types.optional(types.nullable(fieldType)),
        };
      }, {}),
    ),
  );

  return type;
};
