import { memo, useEffect, useRef } from "react";
import { useTheme } from "@mui/material";
import * as L from "leaflet";

import "leaflet/dist/leaflet.css";

import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

import "elementTypes/common/leaflet/DefaultIcon";

import { IElementComponentProps } from "core";

import {
  OSM_ATTRIBUTION,
  OSM_TILE_LAYER_URL,
} from "elementTypes/common/leaflet/constants";

import { DEFAULT_CONFIG } from "./constants";
import { PropsFromRedux } from "./container";
import { useStyles } from "./styles";
import { GeoJSONField } from "./types";
import {
  Feature,
  adjustMapView,
  applyStyle,
  cleanupOnUnmount,
  createCustomMarker,
  handleEachFeature,
} from "./utils";

// https://stackoverflow.com/a/6234804

const DefaultGeoJSONField = memo<
  IElementComponentProps<Record<string, unknown>, GeoJSONField> & PropsFromRedux
>(
  ({
    value,
    getStyle,
    getTooltip,
    getMarkerBackgroundColor,
    getMarkerDisplayText,
    element: { id, config },
    data,
    selected,
  }) => {
    const {
      latitude = DEFAULT_CONFIG.latitude,
      longitude = DEFAULT_CONFIG.longitude,
      zoom = DEFAULT_CONFIG.zoom,
      tileLayerUrl = OSM_TILE_LAYER_URL,
      updateMapView = DEFAULT_CONFIG.updateMapView,
    } = config;

    const mapRef = useLeafletMap(id, {
      latitude,
      longitude,
      zoom,
      tileLayerUrl: tileLayerUrl ?? OSM_TILE_LAYER_URL,
    });

    const initializedDataRef = useRef(false);

    const { classes, cx } = useStyles();
    const theme = useTheme();

    const geojson = data ?? value;

    const variant = config.markerLabelVariant;

    useEffect(() => {
      const map = mapRef.current;

      if (!map) {
        // should never happen, used to satisfy TS
        return;
      }
      // Escape non geojson objects:
      try {
        const geoJsonLayer = L.geoJSON(geojson as any, {
          pointToLayer: (feature, latlng) => {
            const markerBackground = getMarkerBackgroundColor as (
              feature: Feature,
            ) => string;

            return createCustomMarker(feature, latlng, theme, {
              getMarkerDisplayText: getMarkerDisplayText as (
                feature: Feature,
              ) => string,
              getMarkerBackgroundColor: markerBackground,
              classes,
              cx,
              variant,
              selected: {
                ...(selected ?? {}),
                markerColor: config.selected?.markerColor,
                markerLabel: config.selected?.markerLabel,
              },
            });
          },
          style: (feature) =>
            applyStyle(
              feature,
              theme,
              getStyle as (feature: Feature) => L.PathOptions | L.PathOptions,
              { ...(selected ?? {}), pathColor: config.selected?.pathColor },
            ),
          onEachFeature: (feature, layer) => {
            handleEachFeature(
              feature,
              layer,
              getTooltip as (feature: Feature) => string | string,
            );
          },
        });
        geoJsonLayer.addTo(map);

        adjustMapView(map, geoJsonLayer, initializedDataRef, updateMapView);

        return cleanupOnUnmount(map);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(
          `GeoJson trying to parse non geojson. ${(e as Record<"message", string>)?.message}`,
        );
        return;
      }
    }, [
      geojson,
      id,
      updateMapView,
      getStyle,
      getTooltip,
      classes,
      getMarkerDisplayText,
      getMarkerBackgroundColor,
      theme.palette,
      mapRef,
      theme,
      cx,
      variant,
      selected,
      config.selected,
    ]);

    return <div id={id} style={{ width: "100%", height: "100%" }}></div>;
  },
);

DefaultGeoJSONField.displayName = "DefaultGeoJSONField";

// Custom hook for Leaflet map initialization and cleanup
function useLeafletMap(
  mapId: string,
  options: {
    longitude: number;
    latitude: number;
    zoom: number;
    tileLayerUrl: string;
  },
) {
  const mapRef = useRef<L.Map | null>(null);

  useEffect(() => {
    const map = (mapRef.current = L.map(mapId).setView(
      [options.latitude, options.longitude],
      DEFAULT_CONFIG.zoom,
    ));

    map.whenReady(() => {
      // It's safe to work with the map here, e.g., set zoom
      map.setZoom(options.zoom);
      L.tileLayer(options.tileLayerUrl ?? OSM_TILE_LAYER_URL, {
        attribution: options.tileLayerUrl ? undefined : OSM_ATTRIBUTION,
      }).addTo(map);
    });

    return () => {
      map.off();
      map.remove();
    };
  }, [mapId, options]);

  return mapRef;
}

export default DefaultGeoJSONField;
