import { memo, useMemo } from "react";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { Controller } from "react-hook-form";
import { IObjectView, IObjectViewField } from "core";
import { useSessionContext } from "core/session";
import { getTranslatedText } from "core/utils/element-utils";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import { IAutocompleteValue } from "elementTypes/common/Autocomplete/types";
import { customRenderOption } from "elementTypes/common/Autocomplete/utils";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";
import { MuiIcon } from "elementTypes/common/MuiIcon";

import { Reference } from "./References";

type Props = Reference & {
  viewList: IObjectView[];
  fields: IObjectViewField[];
  getView: (name: string) => IObjectView | undefined;
  onClick?: () => void;
};

export const ReferenceDialogContent = memo<Props>(
  ({
    fieldName,
    targetView,
    referencedColumnName,
    viewList,
    fields,
    getView,
  }) => {
    const { control, getValues, setValue } = useHookFormContext();
    const { language } = useSessionContext();

    const fieldOptions = useMemo(
      () =>
        fields.map((f) => ({
          value: f.name,
          label: `${getTranslatedText(language, f.i18n, "title")}`,
        })),
      [fields, language],
    );

    const views = useMemo(
      () =>
        viewList?.map((view) => ({
          value: view.name,
          label: `${
            getTranslatedText(language, view.i18n, "title") ?? view.name
          }`,
        })),
      [viewList, language],
    );

    const values = getValues();

    const currentView = values.targetView?.length
      ? values.targetView
      : targetView;

    const targetFields = useMemo(
      () =>
        getView(currentView)?.fields?.map((field) => ({
          value: field.name,
          label: `${getTranslatedText(language, field.i18n, "title")}`,
        })),
      [getView, currentView, language],
    );

    const handleTargetViewChange = (nextTargetView: IAutocompleteValue) => {
      setValue("targetView", nextTargetView);
      const view = getView(nextTargetView as string);
      const identifierField =
        view?.identifyingField?.name ?? view?.fields[0]?.name;

      setValue("referencedColumnName", identifierField);
      return nextTargetView;
    };

    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item md={12}>
          <FormControl fullWidth>
            <Controller
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  label={"Source Field"}
                  options={fieldOptions}
                  error={error?.type}
                  isClearable={false}
                  customRenderOption={customRenderOption}
                  innerRef={ref}
                  {...field}
                />
              )}
              name="fieldName"
              control={control}
              defaultValue={fieldName}
              rules={{
                required: true,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item md justifyContent="center" container>
          <MuiIcon icon="arrow_downward" fontSize="large" />
        </Grid>

        <Grid item md={12} height="max">
          <Box display="flex" flexDirection="column" gap={1}>
            <FormControl fullWidth>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={"Target Query"}
                    options={views}
                    error={error?.type}
                    isClearable={false}
                    customRenderOption={customRenderOption}
                    innerRef={ref}
                    {...field}
                    onChange={handleTargetViewChange}
                  />
                )}
                name="targetView"
                control={control}
                defaultValue={targetView}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={"Identifying Target Field"}
                    options={targetFields ?? []}
                    error={error?.type}
                    isClearable={false}
                    customRenderOption={customRenderOption}
                    disabled={!currentView.length}
                    innerRef={ref}
                    {...field}
                  />
                )}
                name="referencedColumnName"
                control={control}
                defaultValue={referencedColumnName}
                rules={{ required: true }}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    );
  },
);
