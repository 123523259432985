import { FC, memo } from "react";
import { Box, Typography } from "@mui/material";
import { NodeProps } from "reactflow";

import { ElementSchemaNode } from "./component";
import { NodeData } from "./types";

const SchemaNode: FC<NodeProps<NodeData>> = ({ data }) => {
  const { schema, width, height } = data as unknown as ElementSchemaNode;

  return (
    <Box
      width={width}
      height={height}
      border="2px dashed"
      borderColor="divider"
    >
      <Typography
        variant="subtitle1"
        style={{ transform: "translateY(-100%)" }}
      >
        {schema}
      </Typography>
    </Box>
  );
};

const MemoizedSchemaNode = memo(SchemaNode);

export default MemoizedSchemaNode;
