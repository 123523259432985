import { memo } from "react";
import Paper from "@mui/material/Paper";
import { Element, IElementComponentProps } from "core";

import { Container } from "./types";

const DefaultContainer = memo<IElementComponentProps<any, Container>>(
  ({
    element: {
      children: { content },
    },
  }) => {
    return (
      <Paper variant="outlined" sx={{ height: "100%", width: "100%" }}>
        {content.elements.map((el) => (
          <Element key={el.id} element={el} />
        ))}
      </Paper>
    );
  },
);

export default DefaultContainer;
