import { IReduxModuleFactoryArgs } from "core";
import { buildSimpleUncontrolledInputModule } from "core/utils/buildSimpleUncontrolledInputModule";

import { TextInput } from "../../types";

import { buildUncontrolledActions } from "./actions";
import { buildReducer } from "./reducer";
import { buildSaga } from "./saga";
import { buildSelectors } from "./selectors";
import { Actions, IState } from "./types";

export function uncontrolledReduxModuleFactory({
  element,
  path,
}: IReduxModuleFactoryArgs<TextInput>) {
  const INITIAL_STATE: IState = {
    value: element.config.defaultValue ?? null,
    errors: null,
    touched: false,
  };

  const uncontrolledModule = buildSimpleUncontrolledInputModule(
    element,
    path,
    INITIAL_STATE,
  );

  const { actions: uncontrolledActions, types: uncontrolledTypes } =
    buildUncontrolledActions(path);

  const actions = {
    ...uncontrolledActions,
    ...uncontrolledModule.actions,
  } as Actions;

  const types = {
    ...uncontrolledModule.types,
    ...uncontrolledTypes,
  };

  const selectors = {
    ...uncontrolledModule.selectors,
    ...buildSelectors(path),
  };

  const reducer = buildReducer(types, INITIAL_STATE);

  const saga = buildSaga(actions, types, element, selectors);

  return {
    ...uncontrolledModule,
    actions,
    reducer,
    saga,
    selectors,
  };
}
