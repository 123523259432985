import { IRawElementType } from "core";

import Component from "./container";
import { JsonFieldEditor, defaultElement, editorMetadata } from "./editor";
import { JsonFieldConfig } from "./types";

const elementType: IRawElementType = {
  name: "default_json_field",
  component: Component,
  editable: true,
  configType: JsonFieldConfig,
  defaultElement,
  editorComponent: JsonFieldEditor,
  editorMetadata,
};

export default elementType;
