import { ComponentProps, memo, useCallback } from "react";
import { Box, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Section, useElementEditorContext } from "core/editor";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { IAutocompleteValue } from "elementTypes/common/Autocomplete/types";
import { UploadZone } from "elementTypes/common/UploadZone";

import { QueryKeys } from "queries/admin";
import { useDeleteFile, useSaveFile } from "queries/admin/fileData";
import { getApiError } from "queries/utils";
import {
  FileForm,
  FileResponse,
} from "staticPages/admin/pages/files/pages/upload/types";
import { useSnackbar } from "utils/hooks/useSnackbar";

import { UntransformedStorageImageConfig } from "../../types";
import { useEditorStorageImageTranslation } from "../translation";

import FileSelect from "./FileSelect";

export const ConfigComponent = memo(() => {
  const {
    elementModel: {
      id,
      config,
      config: { path, fullSizeOnClick, isUsingFileStorage },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedStorageImageConfig>();

  const queryClient = useQueryClient();

  const translation = useEditorStorageImageTranslation();
  const showSnackbar = useSnackbar();

  const deleteFile = useDeleteFile({
    onSuccess: () => {
      changeConfigValue("path", null);
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.getFilesByGroupType],
      });
      showSnackbar(translation.deletedMessage, "success");
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const uploadFile = useSaveFile({
    onSuccess: (data: FileResponse) => {
      showSnackbar(translation.saveSuccessMessage, "success");
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.getFilesByGroupType],
      });
      changeConfigValue("isUsingFileStorage", true);
      wrappedChangePath(data.fileName);
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(`${translation.saveErrorMessage} ${msg}`, "error");
    },
  });

  const wrappedChangePath = useCallback(
    (newValue: string | number | boolean) => {
      changeConfigValue("path", String(newValue));
    },
    [changeConfigValue],
  );

  const handleFullSizeChange = useCallback(
    () => changeConfigValue("fullSizeOnClick", !fullSizeOnClick),
    [changeConfigValue, fullSizeOnClick],
  );

  const handleUsingFileStorage = useCallback(
    () => changeConfigValue("isUsingFileStorage", !isUsingFileStorage),
    [changeConfigValue, isUsingFileStorage],
  );

  const appUser = useSelector(sessionSelectors.ui);
  const uiUser = appUser?.role ?? "";

  const pathNonExpressionEditor: ComponentProps<
    typeof CustomExpressionEditor
  >["nonExpressionEditor"] = useCallback(
    ({ value, onChange }: NonExpressionEditorProps) => {
      const handlePathChange = (
        nextVal: IAutocompleteValue,
        reason?: string,
      ) => {
        if (reason === "clear") {
          deleteFile.mutate({
            fileName: value,
          });
          onChange("");
        } else {
          onChange(nextVal as string);
        }
      };
      return isUsingFileStorage ? (
        <FileSelect value={value} onChange={handlePathChange} />
      ) : (
        <TextField
          data-cypress-selector="storage-image-non-expression-path"
          fullWidth={true}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      );
    },
    [deleteFile, isUsingFileStorage],
  );

  const handleSubmit = (file: File | null) => {
    if (file) {
      const data: FileForm = {
        groupName: "private",
        typeGroupName: "image",
        file,
        acl: [uiUser],
        realName: file.name,
      };
      uploadFile.mutate(data);
    }
  };

  return (
    <Section title={translation.configSectionTitle} wrapped={true} gap={1}>
      <UploadZone
        value={null}
        maxFiles={1}
        multiple={false}
        accept={{
          "image/*": [],
        }}
        onSubmit={handleSubmit}
      />
      <Box width={"100%"} pt={1} />
      <TableColumnEditor
        id={id}
        value={path}
        onChange={wrappedChangePath}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["text"]}
      />
      <CustomExpressionEditor
        value={path}
        config={config}
        onChange={wrappedChangePath}
        label="Path"
        nonExpressionEditor={pathNonExpressionEditor}
      />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(isUsingFileStorage)}
            onChange={handleUsingFileStorage}
          />
        }
        label={translation.isUsingFileStorageLabel}
      />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(fullSizeOnClick)}
            onChange={handleFullSizeChange}
          />
        }
        label={translation.fullSizeLabel}
      />
    </Section>
  );
});
