import { Theme } from "@mui/material";

import { makeStyles } from "tss-react/mui";

import { CellAlignment } from "../default_table/types";

type DefaultTableHeaderCell = {
  width?: string;
  align?: CellAlignment;
};

export const useStyles = makeStyles<DefaultTableHeaderCell>()((
  theme: Theme,
  { width, align },
) => {
  const borderColor = theme.palette.divider;
  return {
    cell: {
      padding: theme.spacing(),
      borderBottom: `1px solid ${borderColor}`,
      borderTop: `1px solid ${borderColor}`,
      "&:not(:last-child)": {
        borderRight: `1px solid ${borderColor}`,
      },
      cursor: "pointer",
      ...(width && { width }),
    },
    title: {
      // the sort icon on the right is 24px wide, we need to compensate that
      // if the alignment type is center - else it's not important
      paddingLeft: align === "center" ? 24 : 0,
    },
  };
});
