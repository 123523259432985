import { lazy, memo, useCallback, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
  useObjectViewList,
} from "core/editor";
import Button from "elementTypes/common/Button";

import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { useTableEditorTranslation } from "../../translation";
import { TableChildren, TableConfig } from "../../types";

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

type DataSourceEditorProps = {
  generateDefaultColumns: (nextViewName: string) => void;
};

export const DataSourceEditor = memo<DataSourceEditorProps>(
  ({ generateDefaultColumns }) => {
    const { elementModel, changeConfigValue } =
      useElementEditorContext<TableConfig>();

    const {
      config: { dataSource },
      children,
    } = elementModel;
    const {
      header: { elements: headerElements },
    } = children as TableChildren;

    const {
      cancelButton,
      dataSourceTitle,
      identifierNameLabel,
      viewLabel,
      stateColumnNameLabel,
    } = useEditorTranslation();
    const {
      changeViewButton,
      changeViewConfirmation,
      changeViewButtonNoColumnRefresh,
    } = useTableEditorTranslation();

    const elementsExist = !!headerElements?.length;

    const changeDataSource = useCallback(
      (newDataSource: TableConfig["dataSource"]) =>
        changeConfigValue("dataSource", newDataSource),
      [changeConfigValue],
    );

    const {
      viewName = "",
      identifierName = "",
      stateColumnName = "",
    } = dataSource;

    const { getViewByName } = useObjectViewList();

    const sectionRef = useRef<HTMLDivElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [nextViewName, setNextViewName] = useState<string | null>(null);

    const handlePopupClose = () => {
      if (nextViewName) {
        setNextViewName(null);
      }
      setAnchorEl(null);
    };

    const handleChange = (newViewName: string) => {
      changeConfigValue("fixedFilter", undefined);
      changeConfigValue("filter", undefined);
      changeConfigValue("defaultSort", undefined);
      const nextObjectView = getViewByName(newViewName);

      // To show proper error to the user set *undefined* if newViewName is empty
      changeDataSource({
        viewName: (newViewName?.trim()?.length
          ? newViewName
          : undefined) as string,
        identifierName: nextObjectView?.identifyingField?.name,
        stateColumnName: undefined,
      });

      if (anchorEl) {
        handlePopupClose();
      }
    };

    const handleViewNameChange = (newViewName: string) => {
      if (elementsExist) {
        setNextViewName(newViewName);
        setAnchorEl(sectionRef.current);
      } else {
        generateDefaultColumns(newViewName);
      }
    };

    const handleClick = (shouldGenerateColumns: boolean) => () => {
      handleChange(nextViewName!);

      if (shouldGenerateColumns && nextViewName) {
        generateDefaultColumns(nextViewName);
      }
    };

    const handleFieldChange = (
      key: string,
      fieldValue: string[] | string | boolean | number | number[] | null,
    ) => {
      changeDataSource({
        ...dataSource,
        [key]: fieldValue || ("" as string),
      });
    };

    const fields = [
      {
        label: identifierNameLabel,
        value: identifierName,
        name: "identifierName",
        isClearable: true,
      },
      {
        label: stateColumnNameLabel,
        value: stateColumnName,
        name: "stateColumnName",
        isClearable: true,
      },
    ];

    return (
      <Section title={dataSourceTitle} wrapped={true} innerRef={sectionRef}>
        <ViewAutocomplete
          viewValue={viewName}
          viewLabel={viewLabel}
          onViewNameChange={handleViewNameChange}
          onViewFieldChange={handleFieldChange}
          fields={fields}
          isClearable={false}
        />
        <Popover
          anchorEl={anchorEl}
          onClose={handlePopupClose}
          actionsAlign="center"
          actions={
            <>
              <Button
                id="change-view-pop-up-confirm"
                label={changeViewButton}
                color="secondary"
                onClick={handleClick(true)}
              />
              <Button
                id="change-view-pop-up-confirm-no-change-column"
                label={changeViewButtonNoColumnRefresh}
                onClick={handleClick(false)}
              />
              <Button
                label={cancelButton}
                onClick={handlePopupClose}
                id="change-view-pop-up-cancel"
              />
            </>
          }
        >
          <>
            <Typography component="span">{changeViewConfirmation}</Typography>
          </>
        </Popover>
      </Section>
    );
  },
);
