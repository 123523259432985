import { IDefaultElement } from "core/types/element";

import { UntransformedActionButtonConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedActionButtonConfig> =
  {
    i18n: {
      en: {
        label: "",
      },
    },
    config: {
      onTrigger: [],
    },
  };
