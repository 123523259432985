import { useCallback, useEffect, useRef } from "react";
import useResizeObserver from "use-resize-observer";

export const useDimensions = (dependencies?: any[]) => {
  const ref = useRef<HTMLDivElement>(null);
  const { width = 0, height = 0 } = useResizeObserver<HTMLDivElement>({ ref });

  const getDimensions = useCallback(() => {
    const element = ref.current;
    if (!element) {
      return null;
    }
    return element.getBoundingClientRect().toJSON();
  }, []);

  const dimensionsRef = useRef<DOMRect | null>(null);

  useEffect(() => {
    const newDimensions = getDimensions();
    if (newDimensions) {
      const prevDimensions = dimensionsRef.current;
      const dimensionsChanged =
        !prevDimensions ||
        JSON.stringify(prevDimensions) !== JSON.stringify(newDimensions);

      if (dimensionsChanged) {
        dimensionsRef.current = newDimensions;
      }
    }
  }, [width, height, ...(dependencies ?? [])]);

  return {
    ...(dimensionsRef.current || {}),
    width,
    height,
    ref,
  };
};
