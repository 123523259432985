import { CSSProperties, memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  Prism as SyntaxHighlighter,
  SyntaxHighlighterProps,
} from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";

import IconButton from "elementTypes/common/IconButton";

type IOwnProps = {
  value: string;
  customStyle?: CSSProperties;
  isCopy?: boolean;
};

type IProps = IOwnProps & Omit<SyntaxHighlighterProps, "children">;

const defaultStyle = {
  display: "inline-flex",
  width: "100%",
  padding: "10px",
  margin: 0,
  fontSize: "100%",
  backgroundColor: "hsla(214, 100%, 97%, .9)",
  borderRadius: "5px",
};

const CopyButton = memo<{ value: string }>(({ value }) => {
  const [isCopied, setCopied] = useState(false);
  const handleCopy = () => setCopied(true);

  useEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timeout);
    }
    return;
  }, [isCopied]);

  return (
    <CopyToClipboard text={value} onCopy={handleCopy}>
      <IconButton
        icon={isCopied ? "check" : "file_copy"}
        size="small"
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
        }}
      />
    </CopyToClipboard>
  );
});

const CodeBlock = memo<IProps>(
  ({ value, language, style, customStyle, isCopy = false }) => {
    const component = (
      <SyntaxHighlighter
        language={language}
        style={style || vs}
        customStyle={customStyle ?? defaultStyle ?? {}}
      >
        {value}
      </SyntaxHighlighter>
    );

    return (
      <Box width="100%" position="relative">
        {component}
        {isCopy ? <CopyButton value={value} /> : null}
      </Box>
    );
  },
);

CodeBlock.displayName = "CodeBlock";

export default CodeBlock;
