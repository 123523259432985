import { createAction, createActionTypeScoper } from "core/utils/redux";
import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";

export function buildActions(
  scope: string,
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    CHANGE_SELECTION_MODEL: scopeActionType("CHANGE_SELECTION_MODEL"),
  };

  const changeValue = (value: any[]) =>
    dataSource.changeFieldValue(fieldPath, value);

  const changeTouched = (value: boolean) =>
    dataSource.changeFieldTouched(fieldPath, value);

  const changeSelectionModel = createAction(
    types.CHANGE_SELECTION_MODEL,
    (
      identifier: string | number | null,
      row: Record<string, unknown> | null,
    ) => ({
      identifier,
      row,
    }),
  );

  const actions = {
    changeValue,
    changeTouched,
    changeSelectionModel,
  };

  return {
    types,
    actions,
  };
}
