import { AnyElementModelWithPosition } from "core";

/**
 * sort elements based on their position in the grid
 * important for tab index in forms
 */
export function sortElements(elements: AnyElementModelWithPosition[]) {
  return [...elements].sort(
    (
      { position: { column: columnA, row: rowA } },
      { position: { column: columnB, row: rowB } },
    ) => (rowA === rowB ? columnA - columnB : rowA - rowB),
  );
}
