import { createUseTranslation } from "core/session/translation";

export const colorFieldEditorTranslation = {
  en: {
    valueLabel: "Color",
    colorPickerLabel: "Color picker",
    widthLabel: "Width",
    heightLabel: "Height",
    styleLabel: "Style",
    showLabelLabel: "Show Value",
  },
};

export const useColorFieldEditorTranslation = createUseTranslation(
  colorFieldEditorTranslation,
);
