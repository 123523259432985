import { selectorScoper } from "core/utils/redux";

import { InternalLinkField } from "../types";

import { IState } from "./types";

export function buildSelectors(path: string[], element: InternalLinkField) {
  const scopeSelector = selectorScoper<IState>(path);

  const loading = (state: any) => scopeSelector(state).loading;
  const hasStarted = (state: any) => scopeSelector(state).hasStarted;
  const data = (state: any) => scopeSelector(state).data;
  const error = (state: any) => scopeSelector(state).error;

  const { identifierValue } = element.config;

  const identifier = (state: any) => identifierValue?.(state);

  return {
    loading,
    hasStarted,
    data,
    error,
    identifier,
  };
}
