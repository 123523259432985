import { createColorSelector } from "core";
import { selectorScoper } from "core/utils/redux";
import { ActionButton } from "../types";
import { IState } from "./types";

export function buildSelectors(path: string[], element: ActionButton) {
  const scopeSelector = selectorScoper<IState>(path);
  const icon = (state: any) => element.config.icon?.(state);
  const color = createColorSelector(element.config.color);
  const clickedCount = (state: any) => scopeSelector(state).clickedCount;
  const lastClicked = (state: any) => scopeSelector(state).lastClicked;

  return {
    clickedCount,
    color,
    icon,
    lastClicked,
  };
}
