import { memo } from "react";
import { Box, Paper, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { useRouterParamByName } from "core/router/reduxModule/utils.ts";
import BackButton from "elementTypes/common/BackButton";
import Button from "elementTypes/common/Button";
import { useUpdateFile } from "queries/admin/fileData.ts";
import { useFileByName } from "queries/common/fileData.ts";
import { getApiError } from "queries/utils.ts";
import { StorageFileMetadata } from "services/api/types/FileStorage.ts";
import { RoutePaths } from "staticPages/routes";
import { useRoute, useSnackbar } from "utils/hooks";
import { UploadForm } from "../../components/UploadForm.tsx";
import useStyles from "../../styles.ts";
import { useCommonStaticPagesTranslation } from "../../translation.ts";
import { TFileUpdate } from "../upload/types.ts";

type FormData = TFileUpdate["data"];

export const EditFilePage = memo(() => {
  const { updateSuccessMessage, updateErrorMessage, editFileTitle, editLabel } =
    useCommonStaticPagesTranslation();
  const {
    formState: { isSubmitting },
    control,
    handleSubmit,
    setValue,
  } = useForm<FormData>();
  const {
    classes: { root, dividerFooter },
  } = useStyles();

  const fileName = useRouterParamByName("fileName", "");
  const showSnackbar = useSnackbar();
  const route = useRoute();

  const {
    data: file = {} as StorageFileMetadata,
    isInitialLoading,
    isLoading,
  } = useFileByName(
    { fileName },
    {
      enabled: !!fileName.length,
    },
  );

  const { mutate } = useUpdateFile({
    onSuccess: () => {
      showSnackbar(updateSuccessMessage, "success");
      route("push", RoutePaths.Files);
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(updateErrorMessage + msg, "error");
    },
  });

  const onSubmit = (data: FormData) => {
    mutate({
      fileName,
      data,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box pb={1} display="flex" alignItems="center" gap={1}>
        <BackButton isIcon href={RoutePaths.Files} />
        <Typography variant="h5">
          {editFileTitle} - {file?.realName}
        </Typography>
      </Box>
      <Paper variant="outlined" className={root}>
        <Grid container spacing={1}>
          <UploadForm
            {...{
              control,
              setValue,
              isLoading: isInitialLoading,
              fileData: file,
            }}
          />
          <Box width={"100%"} pt={1}>
            <Divider className={dividerFooter} />
          </Box>
          <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
            <Button
              color="primary"
              iconRight="forward"
              type="submit"
              label={editLabel}
              disabled={isSubmitting || isLoading}
              processing={isLoading}
            />
          </Box>
        </Grid>
      </Paper>
    </form>
  );
});
