import { IDefaultElement } from "core/types/element";

import { AutocompleteInputConfig } from "../types";

export const defaultElement: IDefaultElement<AutocompleteInputConfig> = {
  config: {
    reference: undefined,
    fullTextSearch: true,
    optionsListSorted: true,
    options: [],
  },
  i18n: {
    en: {
      label: "",
    },
  },
};
