import { ChangeEvent, memo, useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
  LanguageSection,
  Section,
  TranslationEditor,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { PlacementSelector } from "core/editor/common/PlacementSelector";
import { useSessionContext } from "core/session";

import { IAutocompleteValue } from "elementTypes/common/Autocomplete/types";
import { CodeEditor } from "../../../common/CodeEditor";
import { TextInputConfig } from "../../types";
import { useTextInputEditorTranslation } from "../translation";

export const ConfigEditor = memo(() => {
  const {
    elementModel: {
      i18n,
      config: {
        dataSource,
        multiline,
        defaultValue,
        regexPattern,
        tooltipPlacement,
      },
    },
    changeConfigValue,
    changeTranslation,
  } = useElementEditorContext<TextInputConfig>();

  useEffect(() => {
    if (dataSource && regexPattern) {
      changeConfigValue("regexPattern", undefined);
    }
  }, [dataSource]);

  const { language } = useSessionContext();

  const { enableMultiline, multilineInputLabel, helperTextLabel } =
    useTextInputEditorTranslation();

  const handleChangeMultiline = useCallback(
    (newMultilineValue: TextInputConfig["multiline"]) =>
      changeConfigValue("multiline", newMultilineValue),
    [changeConfigValue],
  );

  const handleMultilineInputChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) =>
    handleChangeMultiline(parseInt(newValue, 10));

  const handleDefaultValueInputChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) =>
    changeConfigValue("defaultValue", newValue);

  const handleRegexValueInputChange = (
    _editor: any,
    _data: any,
    nextPattern: string,
  ) => changeConfigValue("regexPattern", nextPattern);

  const multilineEnabled = multiline !== undefined;

  const toggleMultiline = () =>
    handleChangeMultiline(multiline ? undefined : 1);

  const changeTooltipPlacement = (value: IAutocompleteValue) =>
    changeConfigValue("tooltipPlacement", value as string);

  const { configTitle, defaultValueInputLabel, regexInputLabel } =
    useEditorTranslation();

  return (
    <>
      <FormInputDataSourceEditorComponent
        language={language}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["text"]}
      />
      <Section title={configTitle} wrapped={true}>
        <Box display="flex" flexDirection="column" gap={1}>
          <FormControlLabel
            control={
              <Switch checked={multilineEnabled} onChange={toggleMultiline} />
            }
            label={enableMultiline}
          />
          <TextField
            disabled={!multilineEnabled}
            value={multiline ?? 0}
            name="multiline"
            type="number"
            label={multilineInputLabel}
            fullWidth={true}
            onChange={handleMultilineInputChange}
          />
        </Box>
      </Section>
      <LanguageSection gap={2}>
        <TranslationEditor
          translationKey="label"
          i18n={i18n}
          changeTranslation={changeTranslation}
        />
        <TranslationEditor
          translationKey="helperText"
          i18n={i18n}
          changeTranslation={changeTranslation}
          label={helperTextLabel}
        />
        <PlacementSelector
          value={tooltipPlacement}
          onChange={changeTooltipPlacement}
          label="Tooltip Placement"
        />
      </LanguageSection>
      <FormInputConfigEditorComponent>
        <TextField
          disabled={Boolean(dataSource)}
          value={defaultValue ?? ""}
          name="defaultValue"
          label={defaultValueInputLabel}
          fullWidth={true}
          onChange={handleDefaultValueInputChange}
        />
        {!dataSource && (
          <CodeEditor
            value={regexPattern ?? ""}
            label={regexInputLabel}
            onChange={handleRegexValueInputChange}
            language="text/x-regex"
            theme="default"
            onAutoComplete={false}
            stylesAsMuiInput
          />
        )}
      </FormInputConfigEditorComponent>
    </>
  );
});
