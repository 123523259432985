import { UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";
import { whereEq } from "ramda";
import { IAdminUi } from "core";

import {
  completeArgNames,
  uniqueFunctionName,
} from "elementTypes/default_call_button/utils";
import AdminService from "services/admin";

import {
  Query,
  useAuthenticatedMutation,
  useAuthenticatedQuery,
} from "../utils";

import { QueryKeys } from "./queryKeys";
import { GeneratedFunction } from "./types";

type AwaitedReturnType<F extends (...args: any) => any> = Awaited<
  ReturnType<F>
>;

const getOptions = (list: GeneratedFunction[]) =>
  list.map((item) => ({
    label: item.name,
    value: uniqueFunctionName(item.name, completeArgNames(item)),
    argumentDataTypes: item.argumentDataTypes,
  }));

const getFilteredApps =
  (filter: Record<string, unknown>) => (list: IAdminUi[]) => {
    if ((Object.values(filter) ?? {})?.toString()?.trim()?.length) {
      const pred = whereEq(filter);
      return list.filter((item) => pred(item));
    } else {
      return list;
    }
  };

const services = AdminService.getInstance();

export const fetchApps: Query<IAdminUi[]> = {
  queryKey: QueryKeys.fetchApps,
  queryFn: services.getAllUi,
};

export const fetchFunctions: Query<GeneratedFunction[]> = {
  queryKey: QueryKeys.fetchFunctions,
  queryFn: services.getCypexFunctions,
};

export const fetchFunctionsByRole: Query<
  GeneratedFunction[],
  { role: string }
> = {
  queryKey: QueryKeys.fetchFunctions,
  queryFn: services.getCypexFunctionsByRole,
};

type SelectorOptions = {
  label: string;
  value: string;
  argumentDataTypes?: Record<string, string>;
}[];

export const useApps = (
  options?: UseQueryOptions<IAdminUi[], Error, IAdminUi[]>,
) => useAuthenticatedQuery(fetchApps, undefined, options);

export const useFilteredApps = (filter: Record<string, unknown>) =>
  useApps({ select: getFilteredApps(filter) });

export const useFunctions = <OT = GeneratedFunction[]>(
  options?: UseQueryOptions<GeneratedFunction[], Error, OT>,
) => useAuthenticatedQuery(fetchFunctions, undefined, options);

export const useFunctionsByRole = <OT = GeneratedFunction[]>(
  role: string,
  options?: UseQueryOptions<GeneratedFunction[], Error, OT>,
) => useAuthenticatedQuery(fetchFunctionsByRole, { role }, options);

export const useFunctionOptions = (role: string) =>
  useFunctionsByRole<SelectorOptions>(role, {
    select: getOptions,
  });

export const useCreateFunction = (
  options?: UseMutationOptions<
    unknown,
    unknown,
    { sourceCode: string; acl?: string[] }
  >,
) => useAuthenticatedMutation(services.createCypexFunction, options);

export const useDeleteFunction = (
  options?: UseMutationOptions<{ deleted: string }, unknown, { id: string }>,
) => useAuthenticatedMutation(services.deleteCypexFunction, options);

export const useUpdateFunction = (
  options?: UseMutationOptions<
    AwaitedReturnType<typeof services.updateCypexFunction>,
    unknown,
    AwaitedReturnType<typeof services.updateCypexFunction>
  >,
) => useAuthenticatedMutation(services.updateCypexFunction, options);

export const useDataApiOpenApi = () =>
  useAuthenticatedQuery({
    queryKey: "data-api-openapi",
    queryFn: services.getDataApiOpenApi,
  });

export const useAppEdition = (
  options?: UseQueryOptions<boolean, Error, boolean>,
) =>
  useAuthenticatedQuery(
    {
      queryKey: "app-editon",
      queryFn: services.getEdition,
    },
    undefined,
    options,
  );
