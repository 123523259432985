import { assocPath } from "ramda";
import { v4 as uuid4 } from "uuid";

import { IElementModel, TChildren } from "core";
import { FormConfig } from "elementTypes/default_form/types";
import { TBuiltChildren, TElement, TUpdateElementConfigByType } from "./types";

export const copyElement = (elementModel: TElement): TElement => {
  const { name, type, children } = elementModel;
  const id = uuid4().split("-")[0];
  const elName = name ?? type.name;
  const elementId = `${elName}_${id}`;
  const elementName = `${elName}_copy`;

  const deepElementCopy = {
    ...elementModel,
    config:
      updateElementConfigByType[type.name]?.(
        elementModel.config,
        elementId,
        elementModel.id,
      ) ?? elementModel.config,
    id: elementId,
    name: elementName,
    type,
    ...(Object.keys(children)?.length && {
      children: updateChildren(
        children,
        UpdateChildByType[type.name]?.(elementId),
      ),
    }),
  };

  return deepElementCopy;
};

const updateChildren = (
  children: Record<string, TChildren>,
  cb?: (el: TElement) => TElement,
) => {
  const builtChildren: TBuiltChildren = {} as TBuiltChildren;
  let child: IElementModel | IElementModel[];
  let builtChild;
  let copiedItem;

  for (const childName of Object.keys(children)) {
    child = children[childName].elements ?? children[childName].element!;
    if (Array.isArray(child)) {
      const builtChildArray = [];
      for (const item of child) {
        copiedItem = copyElement(item as TElement);
        builtChild = {
          ...copiedItem,
          ...(cb && {
            ...cb(copiedItem),
          }),
        };
        builtChildArray.push(builtChild);
      }
      builtChildren[childName] = {
        elements: builtChildArray,
      };
    } else {
      copiedItem = copyElement(child as TElement);
      builtChild = cb ? cb(copiedItem) : copiedItem;
      builtChildren[childName] = {
        element: builtChild,
      };
    }
  }
  return builtChildren;
};

const updateDataSource =
  (type: string) =>
  (parentId: string) =>
  (el: IElementModel<any, {}, never>): IElementModel<any, {}, never> => {
    return {
      ...el,
      children: updateChildren(
        el.children,
        UpdateChildByType[type]?.(parentId),
      ),
      ...(el.config?.dataSource?.elementId && {
        config: assocPath(["dataSource", "elementId"], parentId, el.config),
      }),
    };
  };

const UpdateChildByType = {
  default_form: updateDataSource("form_input"),
  form_input: updateDataSource("form_input"), // TODO: when we change structure we can avoid this fn
  default_table: updateDataSource("default_delete_button"),
  default_delete_button: updateDataSource("default_delete_button"),
  default_state_change_dropdown: updateDataSource(
    "default_state_change_dropdown",
  ),
} as { [k: string]: ReturnType<typeof updateDataSource> };

const updateElementConfigByType: TUpdateElementConfigByType = {
  default_form: (
    formConfig: FormConfig,
    nextId?: string,
    prevId?: string,
  ): FormConfig => ({
    ...formConfig,
    ...(!!formConfig?.onSuccess?.length &&
      nextId &&
      prevId && {
        onSuccess: formConfig?.onSuccess.map((action) =>
          "elementId" in action && action.elementId === prevId
            ? { ...action, elementId: nextId }
            : action,
        ),
      }),
  }),
};
