import { ElementGroup, IRawElementType } from "core";

import Component from "./container";
import { SliderInputEditor, defaultElement, editorMetadata } from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import {
  SliderInputConfig,
  SliderInputTranslationKeys,
  sliderInputSelectors,
} from "./types";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_slider_input",
  group: ElementGroup.Inputs,
  component: Component,
  editable: true,
  configType: SliderInputConfig,
  selectorTypes: sliderInputSelectors,
  translationKeys: SliderInputTranslationKeys,
  editorComponent: SliderInputEditor,
  defaultElement,
  editorMetadata,
};

export default elementType;
