import { connect } from "react-redux";
import { IElementComponentProps } from "core";
import { connectErrorHandlerUtils } from "core/utils/react-redux";

import Component from "./component";
import { MarkdownField } from "./types";

const mapStateToProps = (
  state: any,
  { element }: IElementComponentProps<{}, MarkdownField>,
) => ({
  text: element.config.text(state),
  align: element.config.align ?? "left",
});

export default connect(
  connectErrorHandlerUtils.enhanceMapStateToProps(mapStateToProps),
)(connectErrorHandlerUtils.enhanceComponent(Component));
