import { RoutePaths } from "../../../routes";
import { UsersPage } from "./component";
import {
  CreateUsersPageRoute,
  EditUsersPageRoute,
  ViewUsersPageRoute,
} from "./pages";

export const route = {
  Component: UsersPage,
  auth: true,
  isAdmin: true,
  routes: {
    [RoutePaths.UsersCreate]: CreateUsersPageRoute,
    [RoutePaths.UsersEdit]: EditUsersPageRoute,
    [RoutePaths.UsersView]: ViewUsersPageRoute,
  },
};
