import { memo, useMemo } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Element, IElementComponentProps } from "core";

import { DefaultButtonGroup } from "./types";

const DefaultButtonGroupComponent = memo<
  IElementComponentProps<{}, DefaultButtonGroup>
>(
  ({
    element: {
      children: {
        content: { elements },
      },
      props: elementProps,
      config: { size, color, fullWidth, disabled },
    },
  }) => {
    const buttons = useMemo(
      () =>
        elements?.length
          ? elements.map((child) => (
              <Element
                key={child.id}
                element={child}
                elementProps={elementProps}
              />
            ))
          : [],
      [elements, elementProps],
    );

    return (
      <ButtonGroup
        size={size}
        color={color}
        fullWidth={fullWidth}
        disabled={disabled}
      >
        {buttons}
      </ButtonGroup>
    );
  },
);

DefaultButtonGroupComponent.displayName = "DefaultButtonGroup";

export default DefaultButtonGroupComponent;
