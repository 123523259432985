import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  popper: {
    zIndex: 1002,
  },
  paperFilterDialog: { width: "600px", height: "600px" },
  deleteBtn: {
    alignSelf: "flex-end",
  },
  ruleGap: {
    paddingLeft: theme.spacing(),
  },
  groupCombinator: {
    marginTop: 0,
    marginBottom: 0,
  },
  groupWrapper: {
    alignItems: "center",
    margin: theme.spacing(),
  },
  groupButtons: {
    alignItems: "stretch",
  },
  menuIcon: {
    marginRight: theme.spacing(0.5),
  },
  inputSearch: {
    "& [class*='MuiInputBase-input']": {
      padding: `${theme.spacing()} 0 ${theme.spacing()} ${theme.spacing(2)}`,
    },
  },
}));
