import { memo, useContext, useEffect, useReducer } from "react";
import { ReactReduxContext } from "react-redux";

import { ExtendedStore } from "./buildStore";
import { IStaticRouteConfig } from "./router/types";
import { shallowEqual } from "./utils/shallowEqual";

interface IProps {
  page: IStaticRouteConfig;
}

interface IState {
  page: IStaticRouteConfig | null;
  unmount: (() => void) | null;
  error: any;
}

type UpdateElementAction = {
  type: "updatePage";
  payload: {
    page: IStaticRouteConfig;
    unmount: (() => void) | null;
  };
};

type ErrorAction = {
  type: "error";
  payload: {
    error: any;
  };
};

type Action = UpdateElementAction | ErrorAction;

const initialState: IState = {
  page: null,
  unmount: null,
  error: null,
};

function reducer(state: IState, action: Action): IState {
  switch (action.type) {
    case "updatePage":
      return {
        ...state,
        ...action.payload,
        error: null,
      };
    case "error":
      return {
        ...state,
        error: action.payload.error,
        unmount: null,
      };
    default:
      throw new Error();
  }
}

const StaticPage = memo<IProps>(({ page }) => {
  const {
    store: { reducerManager },
  } = useContext<{ store: ExtendedStore }>(ReactReduxContext as any);

  const [{ page: localPage, unmount, error }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  useEffect(() => {
    if (!shallowEqual(page, localPage)) {
      if (unmount) {
        unmount();
      }

      let mounted = null;

      try {
        mounted = reducerManager.mountStaticPage(page);
      } catch (err) {
        dispatch({
          type: "error",
          payload: {
            error: err,
          },
        });
      }

      if (mounted) {
        dispatch({
          type: "updatePage",
          payload: {
            page: mounted.page,
            unmount: mounted.unmount,
          },
        });
      }
    }

    return () => {
      if (unmount) {
        unmount();
      }
    };
  }, [localPage, page, reducerManager, unmount]);

  if (!shallowEqual(localPage, page) || !localPage || error) {
    return null;
  }

  const { Component } = localPage;

  Component.displayName = "StaticPageComponent";

  return <Component />;
});

StaticPage.displayName = "StaticPage";

export default StaticPage;
