import { memo } from "react";
import { getArrow } from "perfect-arrows";
import { ConnectionLineComponentProps } from "reactflow";

const ConnectionLine = ({
  fromX,
  fromY,
  toX,
  toY,
}: ConnectionLineComponentProps) => {
  const arrow = getArrow(fromX, fromY, toX, toY);

  const [sx, sy, cx, cy, ex, ey, ae] = arrow;
  const endAngleAsDegrees = ae * (180 / Math.PI);

  return (
    <>
      <path
        strokeWidth={2}
        className="react-flow__edge-path"
        d={`M${sx},${sy} Q${cx},${cy} ${ex},${ey}`}
        fill="none"
      />
      <polygon
        points="0,-6 12,0, 0,6"
        transform={`translate(${ex},${ey}) rotate(${endAngleAsDegrees})`}
      />
    </>
  );
};

const MemoizedConnectionLine = memo(ConnectionLine);

export default MemoizedConnectionLine;
