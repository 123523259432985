import { createUseTranslation } from "core/session/translation";

export const codeInputEditorTranslation = {
  en: {
    languageInputLabel: "Language",
    labelLabel: "Label",
  },
};

export const useCodeInputEditorTranslation = createUseTranslation(
  codeInputEditorTranslation,
);
