import { amber, red, teal } from "@mui/material/colors";

export const PALETTE = {
  // fixed ci styles: do not change
  PRIMARY: "#153558",
  SECONDARY: "#4ec1ec",
  TEXT_PRIMARY: "#40424a",
  TEXT_SECONDARY: "#283340",
  // flex ci styles
  SUCCESS: teal[500],
  WARNING: amber[600],
  ERROR: red[400],
};

// flex old ci styles
// SUCCESS: "#00c505",
// WARNING: "#f1ad42",
//  ERROR: "#b94141",
