import * as t from "io-ts";
import { getViewRowType } from "core/editor/types";
import { types } from "core/runtime-typing";
import { IElement, TypeFactory, UntransformedConfig } from "core/types";

import {
  EChartsTranslationKeys,
  EchartsContainerMultiConfig,
} from "../common/Echarts";

export const BarChartConfig = t.intersection([
  EchartsContainerMultiConfig,
  t.partial({
    hideLabel: t.boolean,
    hideLegend: t.boolean, // TODO: remove with app migration
  }),
]);

export type BarChartConfig = t.TypeOf<typeof BarChartConfig>;
export type UntransformedBarChartConfig = UntransformedConfig<BarChartConfig>;

export type BarChart = IElement<BarChartConfig, {}, EChartsTranslationKeys>;

const selectedRowType: TypeFactory<BarChartConfig> = (params) =>
  getViewRowType({ isNullable: true })(params);

export const selectorTypes = {
  loading: types.boolean(),
  data: types.any(),
  error: types.nullable(types.string()),
  selected: selectedRowType,
};
