import { assocPath, dissocPath } from "ramda";
import { Node } from "reactflow";
import { NodeData } from "staticPages/admin/pages/modelBuilder/erd/types";
import { LayoutOption } from "../pages/modelBuilder/component";
import { ActionEnum } from "./actions.enum";
import { Action } from "./actions.types";
import { WorkflowView } from "./context.types";

type State = {
  selectedNode: Node<NodeData> | null;
  filter: { schema: string[] };
  entitiesSearchValue: string;
  queriesSearchValue: string;
  tableViewFocus: boolean;
  tableViewDirection: LayoutOption;
  expandedTableList: string[];
  selectedQueryEntity: string | null;
  expandedQueryGroupList: string[];
  workflowView: WorkflowView;
};

const tableViewDirection = "LR";

export const initialState: State = {
  selectedNode: null,
  filter: { schema: [] },
  entitiesSearchValue: "",
  queriesSearchValue: "",
  tableViewFocus: true,
  tableViewDirection,
  expandedTableList: [],
  selectedQueryEntity: null,
  expandedQueryGroupList: [],
  workflowView: {},
};

export function reducer(state: State, { payload, type }: Action): State {
  switch (type) {
    case ActionEnum.SET_SELECTED_NODE:
      return {
        ...state,
        selectedNode: payload.selectedNode,
      };
    case ActionEnum.ON_FILTER_CHANGE:
      return {
        ...state,
        filter: {
          ...state.filter,
          [payload.key]: payload.value,
        },
      };
    case ActionEnum.SET_ENTITIES_SEARCH_VALUE:
      return {
        ...state,
        entitiesSearchValue: payload.entitiesSearchValue,
      };
    case ActionEnum.SET_QUERIES_SEARCH_VALUE:
      return {
        ...state,
        queriesSearchValue: payload.queriesSearchValue,
      };
    case ActionEnum.SET_TABLE_VIEW_FOCUS:
      return {
        ...state,
        tableViewFocus: payload.tableViewFocus,
      };
    case ActionEnum.SET_TABLE_VIEW_DIRECTION:
      return {
        ...state,
        tableViewDirection: payload.tableViewDirection,
      };
    case ActionEnum.SET_EXPANDED_TABLE_LIST:
      return {
        ...state,
        expandedTableList: payload.expandedTableList,
      };
    case ActionEnum.SET_EXPANDED_QUERY_GROUP_LIST:
      return {
        ...state,
        expandedQueryGroupList: payload.expandedQueryGroupList,
      };
    case ActionEnum.SET_SELECTED_QUERY_ENTITY:
      return {
        ...state,
        selectedQueryEntity: payload.selectedQueryEntity,
      };
    case ActionEnum.SET_WORKFLOW_VIEW: {
      return {
        ...state,
        workflowView: payload.value
          ? assocPath(payload.path, payload.value, state?.workflowView) ?? {}
          : dissocPath(payload.path, state?.workflowView),
      };
    }
    default:
      throw new Error(`No action provided`);
  }
}
