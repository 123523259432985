import { UseMutationOptions } from "@tanstack/react-query";
import { useAuthenticatedMutation, useAuthenticatedQuery } from "queries/utils";
import AdminService from "services/admin";
import { QueryKeys } from "./queryKeys";

type AwaitedReturnType<F extends (...args: any) => any> = Awaited<
  ReturnType<F>
>;

const services = AdminService.getInstance();

export const fetchConfig = {
  queryKey: QueryKeys.getConfig,
  queryFn: services.getConfig,
};

export const useConfig = () => useAuthenticatedQuery(fetchConfig);

type IConfig = AwaitedReturnType<typeof services.updateConfig>;

export const useUpdateConfig = (
  options: UseMutationOptions<IConfig, unknown, { [k: string]: string }>,
) => useAuthenticatedMutation(services.updateConfig, options);
