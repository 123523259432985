import { memo, useState } from "react";
import { useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { RefCallBack } from "react-hook-form";
import ReactMde, { getDefaultToolbarCommands } from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";

import { pxToNumber } from "utils/string";
import { GRID_SPACING_FACTOR } from "../../default_grid/components";
import { SPACING_MULTIPLICATOR } from "../../default_grid/components/utils";
import { useStyles } from "./style";

type Props = {
  value: string | null;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  // element position on the page, e.g., element.position.height
  disablePreview?: boolean; // default true
  positionHeight?: number | null;
  inputRef?: RefCallBack;
  onChange: (value: string | null) => void;
};

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export const MarkdownInput = memo<Props>(
  ({
    label,
    value,
    required,
    disabled,
    positionHeight = null,
    disablePreview = true,
    onChange,
    inputRef,
    ...rest
  }) => {
    const [selectedTab, setSelectedTab] = useState<"write" | "preview">(
      "write",
    );
    const theme = useTheme();
    const { classes } = useStyles();

    const space = pxToNumber(theme.spacing());

    const height =
      positionHeight === null
        ? undefined
        : positionHeight * SPACING_MULTIPLICATOR(false) * space +
          (positionHeight - 1) * GRID_SPACING_FACTOR * space +
          /* hidden grip - https://github.com/andrerpena/react-mde/blob/master/src/styles/react-mde.scss#L3 */
          10 -
          /* toolbar */
          space * 6 -
          /* form label & margin - 13.6 = htmlFontSize * 0.865rem = 16 * 0.865 */
          (label ? 13.6 * 3 : 0) -
          // unknown??
          14;

    const handleValueChange = (newValue: string) =>
      onChange(newValue ? newValue : null);

    const handleTabCange = (tab: "write" | "preview") => setSelectedTab(tab);

    const generateMarkdownPreview = (markdown: any) => {
      return Promise.resolve(converter.makeHtml(markdown));
    };

    return (
      <FormControl fullWidth={true} disabled={disabled}>
        {label && (
          <FormLabel
            required={required}
            sx={{
              mb: 1,
            }}
          >
            {label}
          </FormLabel>
        )}
        <ReactMde
          readOnly={disabled}
          value={value ?? ""}
          onChange={handleValueChange}
          initialEditorHeight={height}
          minEditorHeight={height}
          maxEditorHeight={height}
          key={height}
          toolbarCommands={getDefaultToolbarCommands()}
          classes={{
            textArea: "default-markdown-input-text-area",
            // TODO: to check class
            // grip: classes.grip,
            toolbar: classes.toolbar,
          }}
          {...(disablePreview
            ? {
                disablePreview,
              }
            : {
                selectedTab,
                onTabChange: handleTabCange,
                generateMarkdownPreview,
              })}
          {...rest}
          ref={inputRef}
        />
      </FormControl>
    );
  },
);
