import { memo } from "react";

import { StyledTypography } from "../../../../../elementTypes/common/StyledTypography";
import { useModelBuilderTranslation } from "./translation";

export const StyledStateName = memo<{
  stateName: string;
}>(({ stateName }) => {
  const translation = useModelBuilderTranslation();
  return (
    <StyledTypography
      fitContent={true}
      typographyProps={{ variant: "h5", textTransform: "uppercase" }}
      text={stateName}
      tooltip={translation.stateTooltip}
      color="primary"
    />
  );
});
