import { createAction, createActionTypeScoper } from "core/utils/redux";

export function buildActions(scope: string) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    COPY: scopeActionType("COPY"),
  };

  const actions = {
    copy: createAction(types.COPY),
  };

  return { types, actions };
}
