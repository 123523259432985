import { ChangeEvent, memo, useMemo, useState } from "react";
import { Box, Divider, FormControl, TextField } from "@mui/material";
import { GridColDef, GridRowModel } from "@mui/x-data-grid";
import { Controller, useForm } from "react-hook-form";
import ReactMarkdown from "react-markdown";
import { Section } from "core/editor";
import { useTranslator } from "core/session/translation";
import { AlertBox } from "elementTypes/common/AlertBox/index.tsx";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import Button from "elementTypes/common/Button";
import { MuiIcon } from "elementTypes/common/MuiIcon/MuiIcon.tsx";
import { LoadingComponent } from "layouts/common/Loading";
import { useDataPreview } from "queries/admin";
import { getApiError } from "queries/utils";
import { useHookFormError } from "utils/hooks/useHookFormError";
import { useSnackbar } from "utils/hooks/useSnackbar";

import { PreviewTable } from "../components/PreviewTable";
import { QueryGroup } from "../components/types.ts";
import { renderCellOverride } from "../components/utils.tsx";
import { PermissionTable } from "../erd/QueryBuilder.tsx";
import {
  handleNameValidate,
  handleTitleValidate,
  titleToName,
} from "../erd/utils.ts";
import { useDatabaseTranslation } from "../translation";

import QueryEditor from "./queryEditor";
import { useCustomQueryTranslation } from "./translation";
import { ColumnsData, UICustomQueryForm } from "./types";

type CustomQueryProps = {
  onSave: (data: UICustomQueryForm) => void;
  data?: UICustomQueryForm;
  queryGroups: QueryGroup[];
};

export const CustomQueryForm = memo<CustomQueryProps>(
  ({ data, onSave, queryGroups }) => {
    const translation = useCustomQueryTranslation();
    const { queryGroupLabel } = useDatabaseTranslation();
    const { translate } = useTranslator();

    const options = useMemo(
      () =>
        queryGroups?.map((qg) => ({
          label: translate(qg.i18n).label ?? "",
          value: qg.id,
        })),
      [queryGroups, translate],
    );

    const {
      control,
      formState: { errors },
      watch,
      setValue,
      handleSubmit,
      clearErrors,
      getValues,
      setError,
    } = useForm<UICustomQueryForm>();
    const getErrorMessage = useHookFormError();

    const isEditMode = !!data;

    const showSnackbar = useSnackbar();

    const code = watch("code") ?? data?.code;

    const dataPreviewQuery = useDataPreview({
      onError: (error) => {
        const msg = getApiError(error);
        showSnackbar(msg, "error");
      },
    });

    const tableData = dataPreviewQuery.data;

    const { isLoading } = dataPreviewQuery;

    const handleDataPreview = () => {
      const updated = {
        data: {
          // TODO remove this on the server side
          viewName: "fresh",
          code,
        },
      };
      dataPreviewQuery.mutate(updated);
    };

    const handleTitleChange =
      (cb: (ev: ChangeEvent<HTMLInputElement>) => void) =>
      (ev: ChangeEvent<HTMLInputElement>) => {
        cb(ev);
        if (!isEditMode) {
          const nextName = titleToName(ev.target.value);
          setValue("name", nextName);
        }
      };

    const handleStatementValidate = (value: string) => {
      return !!value.trim();
    };

    const columns: GridColDef[] =
      (tableData?.columns?.map((column: ColumnsData) => ({
        field: column.name,
        headerName: column.name,
        type: column.type,
        minWidth: 180,
        flex: 1,
        renderCell: renderCellOverride(column.generalType),
      })) as GridColDef[]) ?? [];

    const rows: GridRowModel[] = (tableData?.data ?? []).map(
      (row: Record<string, unknown>, index: number) => ({
        id: index,
        ...row,
      }),
    );

    return (
      <Box
        width="100%"
        height="100%"
        bgcolor="background.paper"
        border="1px solid"
        borderColor="divider"
        borderRadius={1}
        p={1}
      >
        <form
          style={{
            width: "100%",
            height: "100%",
          }}
          onSubmit={handleSubmit(onSave)}
        >
          <Box display="flex" gap={4} justifyContent="space-between">
            <FormControl fullWidth>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={translation.queryTitleLabel}
                    helperText={getErrorMessage(error)}
                    autoFocus
                    error={Boolean(error)}
                    onChange={handleTitleChange(field.onChange)}
                    autoComplete="off"
                    variant="standard"
                  />
                )}
                name="title"
                control={control}
                rules={{
                  required: true,
                  validate: handleTitleValidate,
                }}
                defaultValue={data?.title ?? ""}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label={translation.queryIdentifyingLabel}
                    helperText={getErrorMessage(error)}
                    error={Boolean(error)}
                    disabled={Boolean(data)}
                    variant="standard"
                    {...field}
                  />
                )}
                name="identifyingColumn"
                control={control}
                defaultValue={data?.identifyingColumn ?? ""}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={translation.queryNameLabel}
                    helperText={getErrorMessage(error)}
                    error={Boolean(error)}
                    variant="standard"
                    disabled
                  />
                )}
                name="name"
                control={control}
                defaultValue={data?.name ?? ""}
                rules={{
                  required: true,
                  validate: handleNameValidate,
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...field}
                    error={getErrorMessage(error)}
                    label={queryGroupLabel}
                    innerRef={ref}
                    options={options}
                    placeholder={translation.noQueryGroupSelected}
                    isClearable
                  />
                )}
                name="queryGroupId"
                control={control}
                defaultValue={data?.queryGroupId ?? null}
              />
            </FormControl>
          </Box>
          <BoxDivider />
          <Box>
            <Controller
              render={(inputProps) => (
                <QueryEditor
                  {...(inputProps as any)}
                  setError={setError}
                  clearError={clearErrors}
                  getValues={getValues}
                  onDataPreview={handleDataPreview}
                />
              )}
              name="code"
              control={control}
              defaultValue={data?.code ?? ""}
              rules={{
                required: true,
                validate: handleStatementValidate,
              }}
            />
          </Box>
          {isEditMode ? <Warning /> : <BoxDivider />}
          <Section
            title={translation.queryEditorPreviewTitle}
            wrapped={true}
            defaultOpened={rows.length > 0}
          >
            <Box>
              {isLoading ? (
                <LoadingComponent />
              ) : (
                <PreviewTable rows={rows} columns={columns} />
              )}
            </Box>
          </Section>
          <Box p={1}>
            <PermissionTable />
          </Box>
          <Box textAlign="right" p={1}>
            <Button
              type="submit"
              label={
                isEditMode ? translation.updateButton : translation.createButton
              }
              color="primary"
              disabled={!code ? true : Boolean(errors.code)}
            />
          </Box>
        </form>
      </Box>
    );
  },
);

const Warning = () => {
  const [open, setOpen] = useState(false);
  const t = useCustomQueryTranslation();
  const warningMessage = (
    <ReactMarkdown>{t.queryEditorWarningMessage}</ReactMarkdown>
  );
  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  return (
    <AlertBox
      alertTitle={t.queryEditorWarningTitle}
      message={open ? warningMessage : undefined}
      color="info"
      onClose={handleToggle}
      sx={{
        "& .MuiAlert-message": {
          width: "100%",
        },
      }}
      boxProps={{
        py: 1,
      }}
      slots={{
        closeIcon: () => (
          <MuiIcon icon={open ? "expand_less" : "expand_more"} />
        ),
      }}
    />
  );
};

const BoxDivider = () => (
  <Box py={2}>
    <Divider />
  </Box>
);
