import { createTypeSafeContext } from "../../../utils/createTypeSafeContext";

type EditorModeContextType = {
  isEditorModeEnabled: boolean;
  toggleEditorMode: (nextState?: boolean) => void;
};

export const Context = createTypeSafeContext<EditorModeContextType>(
  undefined,
  "EditorModeContextType",
);
