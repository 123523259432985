import { lazy, memo, useMemo } from "react";

import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { IEchartsContainerMultiProps } from "./EchartsContainer";
import { IOptionEchartProps } from "./types";

export const Echarts = withLazyLoading(
  lazy(() => import("./Echarts")),
  true,
);

const EchartsContainerDataset = memo<IEchartsContainerMultiProps>(
  ({
    dataSource: {
      columns: { labelColumnName, valueColumnNames },
    },
    data,
    defaultOptions,
    type,
    title,
    i18n,
    ...rest
  }) => {
    const option: IOptionEchartProps = useMemo(
      () => ({
        ...defaultOptions,
        yAxis: {
          ...defaultOptions.yAxis,
          type: "value",
        },
        legend: {
          bottom: 0,
          ...defaultOptions.legend,
        },
        xAxis: {
          ...defaultOptions.xAxis,
          type: "category",
        },
        dataset: {
          dimensions: [labelColumnName, ...valueColumnNames],
          source: data,
          sourceHeader: false,
        },
        series: valueColumnNames.map((columnName, i) => ({
          name: i18n?.[`${columnName}Label`] ?? columnName,
          ...(defaultOptions.series?.[i] ?? {}),
          type,
        })),
        title: title ? { text: title, x: "center" } : null,
      }),
      [
        data,
        defaultOptions,
        labelColumnName,
        valueColumnNames,
        type,
        title,
        i18n,
      ],
    );

    return <Echarts option={option} {...rest} chartType={type} />;
  },
);

export default EchartsContainerDataset;
