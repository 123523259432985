import { FC, memo, useCallback, useMemo } from "react";
import { EdgeProps, EdgeText, getBezierPath, useStore } from "reactflow";
import { adminTheme } from "staticPages/admin/components/adminTheme";
import { useSelectedState } from "../workflow/utils";
import { EdgeData } from "./types";

const CustomEdge: FC<EdgeProps<EdgeData>> = ({
  id,
  label,
  labelStyle,
  labelShowBg,
  labelBgStyle,
  labelBgPadding,
  labelBgBorderRadius,
  markerStart,
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
  source,
  target,
  markerEnd,
  style,
}) => {
  const { selectedEdge } = useSelectedState();

  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source]),
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target]),
  );

  const preparedLabelBgStyle = useMemo(
    () => ({
      ...labelBgStyle,
      ...(selectedEdge?.id === id && {
        stroke: adminTheme.palette.info.main,
        strokeWidth: 2,
      }),
    }),
    [id, labelBgStyle, selectedEdge?.id],
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const [path, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={path}
        markerEnd={markerEnd}
        markerStart={markerStart}
      />
      <EdgeText
        x={labelX + 15}
        y={labelY}
        label={label}
        labelBgStyle={preparedLabelBgStyle}
        labelStyle={labelStyle}
        labelShowBg={labelShowBg}
        labelBgPadding={labelBgPadding}
        labelBgBorderRadius={labelBgBorderRadius}
      />
    </>
  );
};

const MemoizedCustomEdge = memo(CustomEdge);

export default MemoizedCustomEdge;
