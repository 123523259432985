import { lazy } from "react";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";

const Component = withLazyLoading(
  lazy(() =>
    import("./component").then((module) => ({
      default: module.RepositoriesContent,
    })),
  ),
  true,
);

export const route = {
  Component,
  auth: true,
  isAdmin: true,
};
