import { memo, useCallback } from "react";
import {
  Section,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";

import { Autocomplete } from "../../../common/Autocomplete";
import { IAutocompleteValue } from "../../../common/Autocomplete/types";
import { CodeInputConfig } from "../../types";
import { useCodeInputEditorTranslation } from "../translation";

const languageOptions = [
  "JavaScript",
  "SQL",
  "Python",
  "YAML",
  "XML",
  "Markdown",
];

const languageAutocompleteOptions = languageOptions.map((languageOption) => ({
  value: languageOption.toLowerCase(),
  label: languageOption,
}));

export const ConfigComponent = memo(() => {
  const {
    elementModel: {
      config: { language },
    },
    changeConfigValue,
  } = useElementEditorContext<CodeInputConfig>();
  const editorTranslation = useEditorTranslation();
  const translation = useCodeInputEditorTranslation();

  const handleLanguageChange = useCallback(
    (newValue: CodeInputConfig["language"]) =>
      changeConfigValue("language", newValue),
    [changeConfigValue],
  );

  const handleLanguageInputChange = (value: IAutocompleteValue) => {
    handleLanguageChange(
      value as Exclude<CodeInputConfig["language"], undefined>,
    );
  };

  return (
    <Section title={editorTranslation.configTitle} wrapped={true}>
      <Autocomplete
        options={languageAutocompleteOptions}
        onChange={handleLanguageInputChange}
        value={language}
        name="language"
        label={translation.languageInputLabel}
        isLoading={false}
        virtualizedList={true}
        isClearable={false}
      />
    </Section>
  );
});
