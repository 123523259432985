import { createAction, createActionTypeScoper } from "core/utils/redux";

export function buildActions(scope: string) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    LOAD: scopeActionType("LOAD"),
    LOAD_SUCCESS: scopeActionType("LOAD_SUCCESS"),
    LOAD_ERROR: scopeActionType("LOAD_ERROR"),
  };

  const actions = {
    load: createAction(types.LOAD),
    loadSuccess: createAction(types.LOAD_SUCCESS, (data: any) => ({ data })),
    loadError: createAction(types.LOAD_ERROR, (error: any) => ({ error })),
  };

  return { types, actions };
}
