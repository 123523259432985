import { Draggable } from "react-beautiful-dnd";
import { ListChildComponentProps } from "react-window";

import { ActionItem } from "./ActionItem";
import { useActionsContext } from "./utils";

export const ActionItemRow = ({ index, style }: ListChildComponentProps) => {
  const { actionItems: items } = useActionsContext();
  const element = items[index];
  return (
    <Draggable key={element.id} draggableId={element.id} index={index}>
      {(draggableProvided, snapshot) => (
        <ActionItem
          data={{ items }}
          provided={draggableProvided}
          snapshot={snapshot}
          style={style}
          index={index}
        />
      )}
    </Draggable>
  );
};
