import { IReduxModuleFactoryArgs } from "core";
import { DefaultModalDialog } from "../types";
import { buildActions } from "./actions";
import { buildModalDialogInterface } from "./modalDialog";
import { buildReducer } from "./reducer";
import { buildSelectors } from "./selectors";

export function reduxModuleFactory({
  path,
}: IReduxModuleFactoryArgs<DefaultModalDialog>) {
  const scope = path.join(".");
  const { actions, types } = buildActions(scope);
  const reducer = buildReducer(types);
  const selectors = buildSelectors(path);

  const modalDialogInterface = buildModalDialogInterface(actions);

  return {
    actions,
    reducer,
    selectors,
    interfaces: modalDialogInterface,
  };
}

export * from "./types";

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
