import { createTypeSafeContext } from "../../utils/createTypeSafeContext";

import { IElementModel, IElementType } from "../types";

type IElementTypesContext = {
  elementTypes: Record<string, IElementType>;
  availableElementTypes: Record<string, IElementType>;
  getElementType: (element: IElementModel) => IElementType;
};

const { Provider, useTypeSafeContext } =
  createTypeSafeContext<IElementTypesContext>(
    undefined,
    "IElementTypesContext",
  );

export const useElementTypesContext = useTypeSafeContext;
export const ElementTypesProvider = Provider;
