import { Type } from "./Type";
import { TypeError } from "./TypeError";

export class BooleanType extends Type {
  public name = "boolean";

  public validate(value: any): true | TypeError {
    return typeof value === "boolean" ? true : new TypeError(value, this);
  }
}
