import { IRawElementType } from "core";

import { EChartsTranslationKeys } from "../common/Echarts";

import Component from "./container";
import {
  EchartsCustomChartEditor,
  defaultElement,
  editorMetadata,
} from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import { CustomChartConfig, customChartSelectors } from "./types";
import { getQueriesFromConfig } from "./utils";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "echarts_custom",
  component: Component,
  editable: true,
  configType: CustomChartConfig,
  translationKeys: EChartsTranslationKeys,
  defaultElement,
  editorComponent: EchartsCustomChartEditor,
  editorMetadata,
  selectorTypes: customChartSelectors,
  getQueriesFromConfig,
};

export default elementType;
