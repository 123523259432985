import { memo, useState } from "react";
import { Box } from "@mui/material";
import { Editor, EditorChange } from "codemirror";
import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";

import { CodeEditor } from "../../common/CodeEditor";
import { UntransformedCodeInputConfig } from "../types";

import { ConfigComponent } from "./components/Config";
import { TranslationComponent } from "./components/Translation";

export const CodeInputEditor = memo(() => {
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);
  const editorTranslation = useEditorTranslation();
  const {
    elementModel: {
      config: { dataSource, defaultValue, language: codeLanguage },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCodeInputConfig>();

  const changeLanguage = (l: Language) => setLang(l);
  const handleDefaultValueInputChange = (
    _editor: Editor,
    _data: EditorChange,
    newValue: string,
  ) => changeConfigValue("defaultValue", newValue || null);

  return (
    <>
      <FormInputDataSourceEditorComponent
        language={lang}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["text"]}
      />
      <TranslationComponent
        language={lang}
        handleChangeLanguage={changeLanguage}
      />
      <FormInputConfigEditorComponent>
        <Box sx={{ height: "200px", width: "100%" }}>
          <CodeEditor
            value={defaultValue ?? ""}
            label={editorTranslation.defaultValueInputLabel}
            onChange={handleDefaultValueInputChange}
            options={{ readOnly: Boolean(dataSource) }}
            language={codeLanguage}
            onAutoComplete={false}
            stylesAsMuiInput
          />
        </Box>
      </FormInputConfigEditorComponent>
      <ConfigComponent />
    </>
  );
});
