import { connect } from "react-redux";
import { IElementComponentProps } from "core";
import { connectErrorHandlerUtils } from "core/utils/react-redux";

import Component from "./component";
import { LabelField } from "./types";

const mapStateToProps = (
  state: any,
  { element }: IElementComponentProps<{}, LabelField>,
) => ({
  value: element.config.value(state),
});

export default connect(
  connectErrorHandlerUtils.enhanceMapStateToProps(mapStateToProps),
)(connectErrorHandlerUtils.enhanceComponent(Component));
