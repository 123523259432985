import { createElementInterface } from "core";
import { AnyFluxStandardAction } from "core/utils/redux";
import { Actions } from "elementTypes/default_form/reduxModule/types";
import { FormMultiReferenceConfig } from "elementTypes/default_form/types";

import { FieldPath } from "./types";

interface IFormDataSource {
  createFieldValueSelector: (
    fieldPath: FieldPath,
    opt?: { defaultValue?: any },
  ) => (state: any) => any;
  createFieldOriginalValueSelector: (
    fieldPath: FieldPath,
    opt?: { defaultValue?: any },
  ) => (state: any) => any;
  createFieldErrorSelector: (fieldPath: FieldPath) => (state: any) => any;
  createFieldTouchedSelector: (fieldPath: FieldPath) => (state: any) => any;
  createFieldRequiredSelector: (fieldPath: FieldPath) => boolean;
  changeFieldValue: (
    fieldPath: FieldPath,
    value: any,
  ) => ReturnType<Actions["changeFieldValue"]>;
  changeFieldTouched: (
    fieldPath: FieldPath,
    value: any,
  ) => ReturnType<Actions["changeFieldTouched"]>;
  addSubForm: (fieldPath: FieldPath) => ReturnType<Actions["addSubForm"]>;
  removeSubForm: (
    subFormPath: FieldPath,
  ) => ReturnType<Actions["removeSubForm"]>;
  save: () => ReturnType<Actions["save"]>;
  reset: () => ReturnType<Actions["reset"]>;
  reload: () => AnyFluxStandardAction;
  hasChanges: (s: any) => boolean;
  isValid: (s: any) => boolean;
  types: {
    DATA_READY: string;
  };
  isReadOnly: boolean;
  references: Record<string, FormMultiReferenceConfig>;
}

export const FormDataSource = createElementInterface<IFormDataSource>();
