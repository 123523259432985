import { HTMLAttributes, memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { RefCallBack } from "react-hook-form";

import { Autocomplete } from "../../../../elementTypes/common/Autocomplete";
import {
  AutocompleteOption,
  IAutocompleteValue,
} from "../../../../elementTypes/common/Autocomplete/types";

import { EmojiOption, loadEmojiOptions } from "./utils";

type Props = {
  value?: string | null;
  label?: string;
  name?: string;
  onChange?: (code: string) => void;
  innerRef?: RefCallBack;
};

export const EmojiAutocomplete = memo<Props>(
  ({ label = "", value = "", name, onChange, ...rest }) => {
    const [emojiOptions, setEmojiOptions] = useState<EmojiOption[]>([]);

    useEffect(() => {
      loadEmojiOptions().then((options) => setEmojiOptions(options));
    }, []);

    const customRenderOption = (
      props: HTMLAttributes<HTMLLIElement>,
      option: AutocompleteOption,
    ) => (
      <li {...props} key={String(option.value)}>
        <Box display="flex" gap={1} alignItems={"center"}>
          <ListItemIcon>
            <span>{option.value}</span>
          </ListItemIcon>
          <ListItemText primary={option.label} />
        </Box>
      </li>
    );

    const handleChange = (emojiName: IAutocompleteValue) =>
      onChange?.(emojiName as string);

    return (
      <Box>
        <Autocomplete
          value={value}
          options={emojiOptions}
          name={name ?? "emoji"}
          label={label}
          isLoading={false}
          defaultItemSize={48}
          virtualizedList={true}
          isClearable={true}
          customRenderOption={customRenderOption}
          onChange={handleChange}
          {...(value && {
            startAdornment: <span>{value}</span>,
          })}
          {...rest}
        />
      </Box>
    );
  },
);
