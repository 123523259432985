import { AnyType } from "./AnyType";
import { Type } from "./Type";
import { TypeError } from "./TypeError";

export class AnyRecordType extends Type {
  public name = "any";

  public getKeyType(key: string): Type | undefined {
    return super.getKeyType(key) || new AnyType();
  }

  public validate(value: any): true | TypeError {
    return typeof value === "object" ? true : new TypeError(value, this);
  }
}
