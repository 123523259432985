import { memo } from "react";
import { DataGrid, GridColDef, GridRowModel } from "@mui/x-data-grid";

const columnsDef: GridColDef[] = [
  { field: "name", headerName: "Row values", flex: 1 },
];

type ColumnProps = {
  data?: Record<string, unknown>[];
  loading?: boolean;
};

export const WorkflowSetupColumns = memo(({ data, loading }: ColumnProps) => {
  const rows = (data ?? []).map(
    (row: Record<string, unknown>, index: number) => ({
      id: index,
      ...row,
    }),
  ) as GridRowModel[];

  return (
    <DataGrid
      rows={rows}
      columns={columnsDef}
      pageSize={5}
      rowsPerPageOptions={[5]}
      autoHeight
      loading={loading}
    />
  );
});
