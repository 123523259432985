import { IReduxModuleFactoryArgs } from "core/types";

import { ProcedureButton } from "../types";

import { buildActions } from "./actions";
import { buildSaga } from "./saga";

export default function reduxModuleFactory({
  path,
  element,
}: IReduxModuleFactoryArgs<ProcedureButton>) {
  const scope = path.join(".");
  const { actions, types } = buildActions(scope);
  const saga = buildSaga(actions, types, element);

  return {
    actions,
    saga,
  };
}
