import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { AllServices } from "core/buildStore";
import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import { getTranslatedTextSaga } from "core/session/translation";
import { sessionTranslation } from "core/session/translations";
import { elementActionEventHandler } from "elementTypes/common/ActionConfig/saga";

import { getServerError } from "../../../core/utils/api";
import { CallButton } from "../types";

import { Actions, Types } from "./types";

export function buildSaga(actions: Actions, types: Types, element: CallButton) {
  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token: string | null = yield select(sessionSelectors.token);

    const {
      showNotification,
      callArgs: callArgsConfig,
      contentTypeFormat,
    } = element.config;

    try {
      const callArgsSelector =
        typeof callArgsConfig === "function"
          ? (state: any) => callArgsConfig(state)
          : () => callArgsConfig;
      const callArgs = (yield select(callArgsSelector)) as ReturnType<
        typeof callArgsSelector
      >;

      const callName = (yield select(element.config.callName)) as ReturnType<
        typeof element.config.callName
      >;

      const result: unknown = yield call(
        services.api.runProcedure,
        token,
        callName,
        "view",
        callArgs,
        contentTypeFormat,
      );

      yield put(actions.loadSuccess(result));
      if (showNotification) {
        yield put(
          sessionActions.enqueueSnackbar({
            message: yield call(
              getTranslatedTextSaga,
              sessionTranslation,
              "success",
            ),
            options: {
              variant: "success",
            },
          }),
        );
      }
    } catch (error) {
      const errorMessage = getServerError(error);
      yield put(actions.loadError(errorMessage));
      yield put(
        sessionActions.enqueueSnackbar({
          message: errorMessage,
          options: {
            variant: "error",
          },
        }),
      );
    }
  }

  function* loadSuccessSaga() {
    if (element.config.onSuccess) {
      for (const onSuccessAction of element.config.onSuccess) {
        yield elementActionEventHandler(onSuccessAction);
      }
    }
  }

  return function* mainSaga() {
    yield all([takeLatest(types.LOAD, loadSaga)]);
    yield all([takeLatest(types.LOAD_SUCCESS, loadSuccessSaga)]);
  };
}
