import { useQueryClient as useTanstackQueryClient } from "@tanstack/react-query";

export const useQueryClient = () => {
  const queryClient = useTanstackQueryClient();

  const invalidateQueries = (keys: string[]) => {
    for (const key of keys) {
      queryClient.invalidateQueries({
        queryKey: [key],
      });
    }
  };

  return {
    queryClient,
    invalidateQueries,
  };
};
