import { FallbackProps } from "react-error-boundary";

import { IElementModel } from "core";
import { ElementWithError } from "../utils/element";

export function FallbackElement({
  error,
  elementModel,
}: {
  error: FallbackProps["error"];
  elementModel?: IElementModel;
}) {
  return (
    // TODO: handle error, but still display element
    <ElementWithError
      error={error?.message ?? (error ?? "").toString()}
      elementModel={elementModel}
    />
  );
}
