import isEqual from "lodash/isEqual";
import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import { AllServices } from "core/buildStore";
import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import { createWatcherSaga } from "core/utils/saga";
import { FileInput } from "elementTypes/storage_file_input/types";

import { StorageFileMetadata } from "services/api/types/FileStorage";
import { getServerError } from "../../../../core/utils/api";

import { Actions, Selectors, Types } from "./types";

export function buildSaga(
  actions: Actions,
  types: Types,
  element: FileInput,
  selectors: Selectors,
) {
  const { accessGroupName, typeGroupName } = element.config;

  function* fetchMetadataSaga() {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);

    const currentValue: string[] | null = yield select(selectors.value);

    if (currentValue?.length) {
      try {
        const data: StorageFileMetadata[] = yield call(
          services.api.multipleFileMetadata,
          token,
          currentValue,
        );
        yield put(actions.fetchMetadataSuccess(data));
      } catch (error) {
        yield put(
          sessionActions.enqueueSnackbar({
            message: (error as Record<string, string>).message,
            options: {
              variant: "error",
            },
          }),
        );
        yield put(actions.fetchMetadataError(getServerError(error)));
      }
    } else {
      yield put(actions.fetchMetadataSuccess(null));
    }
  }

  function* reFetchMetadataSaga() {
    const currentValue: string[] | null = yield select(selectors.value);
    const currentMetadata: StorageFileMetadata[] = yield select(
      selectors.metadata,
    ) ?? [];
    const metadataNames = currentMetadata.map((metadata) => metadata?.fileName);
    const loading: boolean = yield select(selectors.loading);
    if (
      currentValue?.length &&
      !isEqual(metadataNames, currentValue) &&
      !loading
    ) {
      yield fetchMetadataSaga();
    }
  }

  function* uploadSaga(action: ReturnType<typeof actions.upload>) {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);
    const currentValue: string[] = yield select(selectors.value) ?? [];

    if (action.payload.files?.length) {
      try {
        const data: StorageFileMetadata[] = yield call(
          services.api.uploadMultipleFiles,
          token,
          action.payload.files,
          accessGroupName,
          typeGroupName,
        );
        yield put(actions.uploadSuccess(data));
        const nextVal = data?.map((mData) => mData.fileName) ?? [];
        if (currentValue) {
          yield put(actions.changeValue([...currentValue, ...nextVal]));
        } else {
          yield put(actions.changeValue([...nextVal]));
        }
      } catch (error) {
        yield put(actions.uploadError(getServerError(error)));
      }
    }
  }

  function* clearSaga(action: ReturnType<typeof actions.clear>) {
    const currentValue: string[] | null = yield select(selectors.value);

    yield put(
      actions.changeValue(
        (currentValue ?? [])?.filter((val) => val !== action.payload.fileName),
      ),
    );
    yield put(actions.clearSuccess());
  }

  function* clearAllSaga() {
    yield put(actions.changeValue(null));
    yield put(actions.clearSuccess());
  }

  return function* mainSaga() {
    yield all([
      takeLatest(types.UPLOAD, uploadSaga),
      takeLatest(types.CLEAR, clearSaga),
      takeLatest(types.FETCH_METADATA, fetchMetadataSaga),
      takeLatest(types.CLEAR_ALL, clearAllSaga),
    ]);

    createWatcherSaga(selectors.value, {
      onChange: reFetchMetadataSaga,
    }),
      createWatcherSaga(selectors.metadata, {
        onChange: reFetchMetadataSaga,
      }),
      yield put(actions.fetchMetadata());
  };
}
