import { createUseTranslation } from "core/session/translation";

export const dataDisplayTranslation = {
  en: {
    reload: "Reload",
  },
  de: {
    reload: "Neu laden",
  },
};

export const useDataDisplayTranslation = createUseTranslation(
  dataDisplayTranslation,
);
