import { createUseTranslation } from "core/session/translation";

export const callButtonEditorTranslation = {
  en: {
    callArgsLabel: "Arguments passed to function",
    callNameLabel: "Function Name",
    iconLabel: "Icon",
    displaySectionTitle: "Display",
    functionDefinitionInfo: "Stored Functions documentation",
    stylingSectionTitle: "Styling",
    colorInputLabel: "Color",
    variantInputLabel: "Variant",
    primaryLabel: "Primary",
    secondaryLabel: "Secondary",
    defaultLabel: "Default",
    inheritLabel: "Inherit",
    containedLabel: "Contained",
    outlinedLabel: "Outlined",
    showNotificationLabel: "Show Notification",
    disabledLabel: "Disabled",
    contentTypeHeader: "Content Type",
  },
};

export const useCallButtonEditorTranslation = createUseTranslation(
  callButtonEditorTranslation,
);
