import { memo, useCallback, useMemo } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import { FixedSizeList, areEqual } from "react-window";
import { Section, useObjectViewList } from "core/editor";
import { useSessionContext } from "core/session";
import { IFixedRow, IObjectViewField } from "core/types";
import { getTranslatedText } from "core/utils/element-utils";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { ToggleButton } from "elementTypes/common/ToggleButton";

import { useTableEditorTranslation } from "../../translation";
import { UntransformedTableConfig } from "../../types";

type IRow = IFixedRow<IObjectViewField>;

type Props = {
  defaultSort: UntransformedTableConfig["defaultSort"];
  viewName: UntransformedTableConfig["dataSource"]["viewName"];
  changeConfigValue: (key: keyof UntransformedTableConfig, value: any) => void;
};

export const DefaultSort = memo<Props>(
  ({ defaultSort = [], viewName, changeConfigValue }) => {
    const translation = useTableEditorTranslation();

    const { language } = useSessionContext();
    const { getViewByName } = useObjectViewList();

    const fields = useMemo(
      () => getViewByName(viewName)?.fields ?? [],
      [getViewByName, viewName],
    );

    const itemSize = 48;

    const handleChange = useCallback(
      (...params: Parameters<typeof changeConfigValue>) =>
        changeConfigValue(...params),
      [changeConfigValue],
    );

    const handleChangeSort = useCallback(
      (name: string) => (_: any, value: "asc" | "desc" | null) => {
        const existing = defaultSort.find(
          (existingColumn) => existingColumn.fieldName === name,
        );
        const nextSort = value
          ? existing
            ? defaultSort.map((item) =>
                item.fieldName === name
                  ? { ...item, asc: value === "asc" }
                  : item,
              )
            : [
                ...defaultSort,
                { fieldName: name, asc: value === "asc", hidden: true },
              ]
          : defaultSort.filter((sorted) => sorted.fieldName !== name);
        handleChange("defaultSort", nextSort);
      },
      [defaultSort, handleChange],
    );

    const Row = memo<IRow>(({ data, index, style }) => {
      const { name, i18n } = data[index];
      const sortedColumn = defaultSort?.find(
        (sorted) => sorted.fieldName === name,
      );

      return (
        <ListItem key={name} divider={true} style={style}>
          <Tooltip title={name} placement="left-start">
            <ListItemText
              primary={getTranslatedText(language, i18n, "title")}
              primaryTypographyProps={{ display: "block", variant: "body1" }}
            />
          </Tooltip>
          <ListItemIcon>
            <ToggleButtonGroup
              exclusive
              value={
                sortedColumn?.asc
                  ? "asc"
                  : sortedColumn?.asc === false
                    ? "desc"
                    : undefined
              }
              onChange={handleChangeSort(name)}
              aria-label="Default Sort"
              size="small"
            >
              <ToggleButton
                value="asc"
                aria-label="asc"
                tooltip={translation.ascTooltip}
              >
                <MuiIcon icon="arrow_downward" />
              </ToggleButton>
              <ToggleButton
                value="desc"
                aria-label="desc"
                tooltip={translation.descTooltip}
              >
                <MuiIcon icon="arrow_upward" />
              </ToggleButton>
            </ToggleButtonGroup>
          </ListItemIcon>
        </ListItem>
      );
    }, areEqual);

    if (!fields.length) {
      return null;
    }

    return (
      <Section title={translation.defaultSortTitle} defaultOpened={false}>
        <FixedSizeList
          height={itemSize * Math.min(fields.length, 5)}
          itemCount={fields!.length}
          itemSize={itemSize}
          width="100%"
          itemData={fields}
        >
          {Row}
        </FixedSizeList>
      </Section>
    );
  },
);
