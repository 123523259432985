import { IRawElementType } from "core";

import Component from "./component";
import { GridEditor, defaultElement, editorMetadata } from "./editor";
import { GridChildren, GridConfig } from "./types";

const elementType: IRawElementType = {
  defaultElement,
  name: "default_grid",
  component: Component,
  editable: true,
  configType: GridConfig,
  childrenType: GridChildren,
  editorComponent: GridEditor,
  editorMetadata,
};

export default elementType;
