import * as t from "io-ts";
import { types } from "core/runtime-typing";
import {
  IElement,
  IElementArrayChild,
  IElementSingleChild,
  UntransformedConfig,
} from "core/types";
import { SelectorTypes } from "core/types/element";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";

import { FormInputConfig } from "../common";

export const colorInputSelectors: SelectorTypes<ColorInputConfig> = {
  value: types.nullable(types.string()),
  disabled: types.optional(types.boolean()),
};

export const ColorInputConfig = t.intersection([
  WithOptionalFieldDataSourceConfig(t.union([t.null, t.string])),
  FormInputConfig,
  t.partial({
    placement: t.keyof({
      bottom: null,
      top: null,
      end: null,
      start: null,
    }),
  }),
]);

export type ColorInputConfig = t.TypeOf<typeof ColorInputConfig>;

export type UntransformedColorInputConfig =
  UntransformedConfig<ColorInputConfig>;

export const ColorInputTranslationKeys = ["label"] as const;

export type ColorInputTranslationKeys =
  (typeof ColorInputTranslationKeys)[number];

export type ColorInput = IElement<
  ColorInputConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  ColorInputTranslationKeys
>;
