import { Theme, alpha } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

import { DEFAULT_GRID_COLUMN_COUNT, GRID_SPACING_FACTOR } from "../components";
import { SPACING_MULTIPLICATOR } from "../components/utils";

const SPACING_COUNT = DEFAULT_GRID_COLUMN_COUNT - 1;

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    "&.react-grid-layout": {
      transition: "none !important",
    },
    "&.ActiveGrid": {
      "--background-color": theme.palette.background.default,
      "--pattern-color": alpha(
        theme.palette.info.light,
        theme.palette.action.disabledOpacity / 3,
      ),
      "--gap": theme.spacing(GRID_SPACING_FACTOR),
      // 100% - (SPACING_COUNT * spacing + 2 * spacing)
      // SPACING_COUNT = count of spacing between columns
      // 2 = spacing left and right of grid (which is automatically set through react-grid-library)
      "--width": `calc((100% - ${theme.spacing(
        SPACING_COUNT,
      )} - var(--gap) * 2) / 12)`,
      "--height": theme.spacing(SPACING_MULTIPLICATOR(false)),
      // the idea is to have a marker at the top left corner where the spacing
      // lines cross
      // something similar to this:
      // (# is the marker we want to render)
      //  #     #     #
      //   +---+ +---+
      //   |   | |   |
      //   |   | |   |
      //   +---+ +---+
      //  #     #     #
      //   +---+ +---+
      //   |   | |   |
      //   |   | |   |
      //   +---+ +---+
      //  #     #     #
      // the problematic part is:
      // the spacing between elements is static, 8px width / height
      // the elements themselves have dynamic width (1/12 of the total width)
      // for example, the grid could look like this:
      //  #                              #                              #
      //   +----------------------------+ +----------------------------+
      //   |                            | |                            |
      //   |                            | |                            |
      //   +----------------------------+ +----------------------------+
      //  #                              #                              #
      //   +----------------------------+ +----------------------------+
      //   |                            | |                            |
      //   |                            | |                            |
      //   +----------------------------+ +----------------------------+
      //  #                              #                              #
      // due to the static spacing width, mixed with the dynamic element width,
      // we cannot use a simple image as background
      // we have to calculate the width (done with CSS variables, see above)
      // the height stays the same
      //
      // to draw the markers, two repeating linear gradients are needed:
      // 1. linear gradient from left to right, drawing a line for each spacing
      // the grid then looks like this:
      //  #     #     #
      //  #+---+#+---+#
      //  #|   |#|   |#
      //  #|   |#|   |#
      //  #+---+#+---+#
      //  #     #     #
      //  #+---+#+---+#
      //  #|   |#|   |#
      //  #|   |#|   |#
      //  #+---+#+---+#
      //  #     #     #
      // 2. linear grid from top to bottom, painting over the first grid with
      // the background color to separate the whole line into single markers
      // the grid with only the linear grid looks like this:
      //  OOOOOOOOOOOOO
      //  XXXXXXXXXXXXX
      //  XXXXXXXXXXXXX
      //  XXXXXXXXXXXXX
      //  XXXXXXXXXXXXX
      //  OOOOOOOOOOOOO
      //  XXXXXXXXXXXXX
      //  XXXXXXXXXXXXX
      //  XXXXXXXXXXXXX
      //  XXXXXXXXXXXXX
      //  OOOOOOOOOOOOO
      // where
      // o = transparent
      // x = background color
      // using this, the lines from 1. are separated to markers (by the X's)
      // by hiding the rest of the line behind the background color
      //
      // the first applied gradient is the topmost one
      //
      // usage of the repeating-linear-gradient below:
      // repeating-linear-gradient (
      //   to direction,
      //   initial-color gap-size,
      //   other-color gap-size gap-size+block-size,
      //   initial-color gap-size+block-size gap-size*2+block-size
      // )
      // where
      // initial-color = pattern color for 1. and transparent for 2.
      // other-color = transparent for 1. and background color for 2.
      background: `repeating-linear-gradient(
          to bottom,
          transparent var(--gap),
          var(--background-color) var(--gap) calc(var(--height) + var(--gap)),
          transparent calc(var(--height) + var(--gap)) calc(var(--height) + var(--gap) * 2)
        ), repeating-linear-gradient(
          to right,
          var(--pattern-color) var(--gap),
          transparent var(--gap) calc(var(--width) + var(--gap)),
          var(--pattern-color) calc(var(--width) + var(--gap)) calc(var(--width) + var(--gap) * 2)
        )`,
      // form has other background
      "&.SpecialBackground": {
        "--background-color": theme.palette.background.paper,
      },

      "&.IncreasedCellHeight": {
        "--height": theme.spacing(SPACING_MULTIPLICATOR(true)),
      },
    },
    "& > .react-grid-item": {
      transition: "none !important",
    },
    "& > .react-grid-item.react-grid-placeholder": {
      background: theme.palette.info.main,
      transitionDuration: "30ms",
      pointerEvents: "none",
    },
    "&.ActiveGrid > .react-grid-item > .react-resizable-handle": {
      width: "26px",
      height: "26px",
      "&:after": {
        borderColor: theme.palette.info.main,
      },
    },
  },
}));
