import { IDefaultElement, buildCustomExpressionValue } from "core";

import { UntransformedCircularProgressConfig } from "../types";
import { DEFAULTS } from "../utils";

export const defaultElement: IDefaultElement<UntransformedCircularProgressConfig> =
  {
    config: {
      value: buildCustomExpressionValue("75"),
      size: DEFAULTS.size,
      variant: DEFAULTS.variant,
      color: DEFAULTS.color,
      thickness: DEFAULTS.thickness,
    },
    i18n: {
      en: {
        label: "",
      },
    },
  };
