import { createTypeSafeContext } from "utils/createTypeSafeContext";

import {
  IElementArrayChild,
  IElementModel,
  IElementSingleChild,
  IElementType,
  TElementModelWithPosition,
  Translation,
} from "../../../types";

export type IElementEditorContext<
  ConfigType extends Record<string, any> = any,
  ChildrenType extends Record<
    string,
    IElementSingleChild | IElementArrayChild
  > = any,
> = {
  elementModel:
    | IElementModel<ConfigType, ChildrenType>
    | TElementModelWithPosition<ConfigType, ChildrenType>;
  type: IElementType;
  changeConfigValue: (
    keyOrConfig: keyof ConfigType | ConfigType,
    value?: any,
  ) => void;
  changeTranslation: (value: Translation<string>) => void;
};

const { Provider, Consumer, useTypeSafeContext } =
  createTypeSafeContext<IElementEditorContext<any, any>>();

export function useElementEditorContext<
  ConfigType extends Record<string, any> = any,
  ChildrenType extends Record<
    string,
    IElementSingleChild | IElementArrayChild
  > = any,
>(): IElementEditorContext<ConfigType, ChildrenType> {
  return useTypeSafeContext();
}

export const ElementEditorProvider = Provider;
export const ElementEditorConsumer = Consumer;
