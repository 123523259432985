import { IRawElementType } from "core";

import Component from "./container";
import { ArrayTextFieldEditor, defaultElement, editorMetadata } from "./editor";
import { ArrayTextFieldConfig } from "./types";

const elementType: IRawElementType = {
  name: "default_array_text_field",
  component: Component,
  editable: true,
  configType: ArrayTextFieldConfig,
  defaultElement,
  editorComponent: ArrayTextFieldEditor,
  editorMetadata,
};

export default elementType;
