import { selectorScoper } from "core/utils/redux";
import { IState } from "./types";

export function buildSelectors(path: string[]) {
  const scopeSelector = selectorScoper<IState>(path);

  const modalOpen = (state: any) => scopeSelector(state).modalOpen;

  return {
    modalOpen,
  };
}
