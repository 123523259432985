import { IDefaultElement } from "core";

import { UntransformedStorageImageConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedStorageImageConfig> =
  {
    config: {
      path: "",
      variant: "contain",
    },
  };
