import request from "superagent";
import { Definition, IAdminUi, IUiSavePoint, Translation } from "core/types";
import { AuditList } from "queries/admin/auditData";
import {
  Extension,
  GeneratedFunction,
  RepositoryBranch,
  RepositoryConfiguration,
  RepositoryData,
  Role,
} from "queries/admin/types";
import { FILE_STORAGE_PREFIX } from "services/api/constants";
import { StorageFileMetadata } from "services/api/types/FileStorage";
import { UICreateAPIData } from "staticPages/admin/pages/apps/pages/create/types";
import { UiEditParams } from "staticPages/admin/pages/apps/pages/edit/types";
import { UiGenerate } from "staticPages/admin/pages/apps/pages/generate/types";
import { AuditTable } from "staticPages/admin/pages/audits/pages/tables/types";
import {
  FileForm,
  FileGroup,
  FileType,
  TFileUpdate,
} from "staticPages/admin/pages/files/pages/upload/types";
import { LDAPData } from "staticPages/admin/pages/ldap/types";
import {
  DeleteQueryGroup,
  DeleteQueryParams,
  QueryGroup,
  QueryObject,
} from "staticPages/admin/pages/modelBuilder/components/types";
import {
  ColumnsData,
  CustomQueryResponse,
  TestCustomQueryResponse,
} from "staticPages/admin/pages/modelBuilder/customQuery/types";
import {
  DataModel,
  HistoryTrackingSwitchParams,
  SetColumnAliasResponse,
  WorkflowSetupParams,
} from "staticPages/admin/pages/modelBuilder/erd/types";
import { Workflow } from "staticPages/admin/pages/modelBuilder/workflow/components/StateViewer/types";
import { StateResponsePayload } from "staticPages/admin/pages/modelBuilder/workflow/components/types";
import {
  CreateStateParams,
  CreateStateTransitionParams,
  DeleteStateParams,
  DeleteStateTransitionParams,
  UpdateStateParams,
  UpdateStateTransitionParams,
  WorkflowActivationParams,
} from "staticPages/admin/pages/modelBuilder/workflow/types";
import { SettingsConfig } from "staticPages/admin/pages/settings/types";
import { UserForm } from "staticPages/admin/pages/users/pages/create/types";
import { EditUserForm } from "staticPages/admin/pages/users/pages/edit/types";
import { User } from "staticPages/admin/pages/users/types";
import { apiClient, getResponseError, withAuthHeader } from "utils/api";

const LDAP_CONFIG_URL = "/app/auth/config/ldap";
export default class AdminService {
  private static _instance: AdminService;

  public static getInstance(): AdminService {
    if (!AdminService._instance) {
      AdminService._instance = new AdminService();
    }

    return AdminService._instance;
  }

  // UI Methods
  public async getEdition(token: string) {
    return await apiClient<boolean>("GET", "/app/admin/edition", token);
  }

  public async getAllUi(token: string) {
    return await apiClient<IAdminUi[]>("GET", "/app/ui/admin/list", token);
  }

  public async generateUi(token: string, uiData: UiGenerate) {
    const data = {
      ...uiData,
      objectViews: uiData.objectViews.map((v) => ({ id: v })),
    };
    return await apiClient("POST", "/app/ui/generate", token, data);
  }

  public async createUi(token: string, uiData: UICreateAPIData) {
    return await apiClient("POST", "/app/ui", token, uiData);
  }

  public async restoreUi(
    uiName: string,
    savePointId: string,
    { token }: { token?: string } = {},
  ) {
    return await apiClient(
      "POST",
      `/app/ui/${uiName}/save/${savePointId}/restore`,
      token,
      { uiName, savePointId },
    );
  }

  public async saveUi(
    name: string,
    definition: Definition,
    description: string,
    { token }: { token?: string } = {},
  ) {
    return await apiClient("POST", `/app/ui/${name}/save`, token, {
      definition,
      description,
    });
  }

  public async editUi(
    token: string,
    { name, editData }: UiEditParams,
  ): Promise<IUiSavePoint & { newPagesNumber: number }> {
    return await apiClient("PUT", `/app/ui/${name}`, token, editData);
  }

  public async releaseUi(
    uiName: string,
    data: { name: string; description: string },
    { token }: { token?: string } = {},
  ) {
    return await apiClient("POST", `/app/ui/${uiName}/release`, token, data);
  }

  public async deleteUI(token: string, { uiName }: { uiName: string }) {
    return await apiClient("DELETE", `/app/ui/${uiName}`, token);
  }

  public async publishReleaseUi(
    uiName: string,
    releaseName: string,
    { token }: { token?: string } = {},
  ) {
    return await apiClient(
      "POST",
      `/app/ui/${uiName}/release/${releaseName}/publish`,
      token,
    );
  }

  // File Methods
  public async getAllFiles(token: string) {
    return await apiClient<StorageFileMetadata[]>(
      "GET",
      "/app/storage/file",
      token,
    );
  }

  public async uploadFile(token: string, fileData: FileForm) {
    const req = withAuthHeader(request.post("/app/storage/file"), token);
    return req
      .field("groupName", fileData.groupName)
      .field("typeGroupName", fileData.typeGroupName)
      .field("acl[]", fileData.acl ?? [])
      .field("realName", fileData.realName ?? "")
      .attach("file", fileData.file as never)
      .then(({ body }) => body)
      .catch((err) => {
        throw getResponseError(err);
      });
  }

  public async deleteFile(token: string, { fileName }: { fileName: string }) {
    return await apiClient(
      "DELETE",
      `${FILE_STORAGE_PREFIX}${fileName}`,
      token,
    );
  }

  public async getFileGroups(token: string) {
    return await apiClient<FileGroup[]>("GET", "/app/storage/groups", token);
  }

  public async getFileTypes(token: string) {
    return await apiClient<FileType[]>("GET", "/app/storage/types", token);
  }

  public async getFilesByGroupType(
    token: string,
    { groupType }: { groupType: string },
  ) {
    return await apiClient<StorageFileMetadata[]>(
      "GET",
      `/app/storage/groups/${groupType}/files`,
      token,
    );
  }

  public async updateFile(token: string, { fileName, data }: TFileUpdate) {
    return await apiClient(
      "PATCH",
      `${FILE_STORAGE_PREFIX}${fileName}`,
      token,
      data,
    );
  }

  // Functions Methods
  public async getCypexFunctions(token: string) {
    return await apiClient<GeneratedFunction[]>("GET", "/app/functions", token);
  }

  public async createCypexFunction(
    token: string,
    params: { sourceCode: string; acl?: string[] },
  ) {
    return await apiClient("POST", "/app/functions", token, params);
  }

  public async deleteCypexFunction(token: string, params: { id: string }) {
    return await apiClient<{ deleted: string }>(
      "DELETE",
      `/app/functions/${params.id}`,
      token,
    );
  }

  public async updateCypexFunction(
    token: string,
    params: { id: string; acl?: string[] },
  ) {
    return await apiClient<{ id: string; acl?: string[] }>(
      "PUT",
      `/app/functions/${params.id}`,
      token,
      params,
    );
  }

  public async getCypexFunction(token: string, params: { id: string }) {
    return await apiClient<GeneratedFunction[]>(
      "GET",
      `/app/functions/${params.id}`,
      token,
    );
  }

  public async getCypexFunctionsByRole(
    token: string,
    params: { role: string },
  ) {
    return await apiClient<GeneratedFunction[]>(
      "GET",
      `/app/functions`,
      token,
      undefined,
      { role: params.role },
    );
  }

  public async getAllRoles(token: string) {
    return await apiClient<Role[]>("GET", "/app/roles", token);
  }

  public async deleteRole(token: string, { roleName }: { roleName: string }) {
    return await apiClient("DELETE", `/app/auth/role/${roleName}`, token);
  }

  public async createUserRole(token: string, params: Role) {
    return await apiClient<Role>(
      "POST",
      `/app/auth/role/${params.name}`,
      token,
      { isAdmin: params.isAdmin },
    );
  }

  // Audit Methods
  public async getTableAudit(
    token: string,
    query: { schemaName: string; tableName: string },
  ) {
    return await apiClient<AuditTable[]>(
      "GET",
      "/app/audit/table",
      token,
      undefined,
      query,
    );
  }

  public async getAuditTableList(token: string) {
    return await apiClient<AuditList[]>("GET", "/app/audit/table/list", token);
  }

  public async getUserAudit(token: string) {
    return await apiClient("GET", "/app/audit/user-events", token);
  }

  // User Methods
  public async getUser(token: string, { userId }: { userId: string }) {
    return await apiClient<User>(
      "GET",
      `/app/users/integrated/${userId}`,
      token,
    );
  }

  public async getAllUsers(token: string) {
    return await apiClient<User[]>("GET", "/app/users/integrated", token);
  }

  public async saveUser(token: string, user: UserForm) {
    return await apiClient("POST", "/app/users/integrated", token, user);
  }

  public async editUser(
    token: string,
    params: { userId: string; user: EditUserForm },
  ) {
    return await apiClient(
      "PUT",
      `/app/users/integrated/${params.userId}`,
      token,
      params.user,
    );
  }

  public async deleteUser(token: string, { userId }: { userId: string }) {
    return await apiClient("DELETE", `/app/users/integrated/${userId}`, token);
  }

  public async getDataApiOpenApi(token: string) {
    try {
      const body = await apiClient("GET", "/view/", token);
      const data = body as unknown as Record<string, unknown>;

      if (!data) {
        return null;
      }

      data.host = window.location.host;
      data.paths = Object.fromEntries(
        Object.entries(data.paths as Record<string, Object>).map(
          ([key, value]) => [`/view${key}`, value],
        ),
      );
      // remove PostgREST information
      delete data.schemes;
      delete data.info;
      delete data.externalDocs;

      return data;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async editLoginConfig(token: string, config: SettingsConfig) {
    return await apiClient<SettingsConfig>(
      "PATCH",
      "/app/auth/config/login",
      token,
      config,
    );
  }

  public async getLDAPConfig(token: string) {
    return await apiClient<LDAPData>("GET", LDAP_CONFIG_URL, token);
  }

  public async deleteLDAPConfig(token: string) {
    return await apiClient("DELETE", LDAP_CONFIG_URL, token);
  }

  public async saveLDAPConfig(token: string, ldapData: LDAPData) {
    return await apiClient<LDAPData>(
      "PUT",
      LDAP_CONFIG_URL,

      token,
      ldapData,
    );
  }

  public async getQueries(
    token: string,
    params?: { role: string },
  ): Promise<QueryObject[]> {
    return await apiClient(
      "GET",
      "/app/admin/model/queries",
      token,
      undefined,
      params ?? {},
    );
  }

  public async getModel(token: string) {
    return await apiClient<DataModel>("GET", "/app/admin/model", token);
  }

  public async getColumnValues(
    token: string,
    params: {
      schema: string;
      table: string;
      column: string;
    },
  ) {
    const { schema, table, column } = params;
    return await apiClient<Record<string, unknown>[]>(
      "GET",
      `/app/admin/model/${schema}/${table}/workflow/column/${column}`,
      token,
    );
  }

  public async getStateWorkflow(
    token: string,
    params: {
      schema: string;
      table: string;
    },
  ) {
    const { schema, table } = params;
    return await apiClient<Workflow>(
      "GET",
      `/app/admin/model/${schema}/${table}/workflow/graph`,
      token,
    );
  }

  public async getStateNode(
    token: string,
    params: {
      id: number;
    },
  ) {
    const { id } = params;
    return await apiClient<StateResponsePayload>(
      "GET",
      `/app/admin/model/workflow/state/${id}`,
      token,
    );
  }

  public async createDefaultQuery(
    token: string,
    params: {
      schema: string;
      table: string;
      data: {
        viewName: string;
        permissions: { grantee: string; privileges: string[] }[];
        i18n: Translation<keyof any>;
      };
    },
  ) {
    const { schema, table, data } = params;
    return await apiClient(
      "POST",
      `/app/admin/model/query/${schema}/${table}`,
      token,
      data,
    );
  }

  public async createDefaultQueries(
    token: string,
    params: {
      tables: Record<string, string[]>;
      permissions: { grantee: string; privileges: string[] }[];
    },
  ) {
    return await apiClient("POST", `/app/admin/model/query/all`, token, params);
  }

  public async getStateTransition(
    token: string,
    params: {
      transitionId: string | undefined;
    },
  ) {
    const { transitionId } = params;
    if (!transitionId) {
      return;
    }
    return await apiClient(
      "GET",
      `/app/admin/model/workflow/transition/${transitionId}`,
      token,
    );
  }

  public async createCustomQuery(
    token: string,
    data: {
      viewName: string;
      code: string;
      permissions: { grantee: string; privileges: string[] }[];
      i18n: Translation<keyof any>;
      identifyingColumn: string | null;
      queryGroupId: number | null;
    },
  ) {
    return await apiClient(
      "POST",
      "/app/admin/model/query/custom",
      token,
      data,
    );
  }

  public async editCustomQuery(
    token: string,
    data: {
      viewName: string;
      code: string;
      permissions: { grantee: string; privileges: string[] }[];
      i18n: Translation<"title">;
      identifyingColumn: string | null;
      queryGroupId: number | null;
    },
  ) {
    return await apiClient("PUT", "/app/admin/model/query/custom", token, data);
  }

  public async editDefaultQuery(
    token: string,
    data: {
      viewName: string;
      permissions: { grantee: string; privileges: string[] }[];
      i18n: Translation<"title">;
      queryGroupId: number | null;
    },
  ) {
    return await apiClient(
      "PUT",
      "/app/admin/model/query/default",
      token,
      data,
    );
  }

  public async createStateTransition(
    token: string,
    stateTransitionParams: CreateStateTransitionParams,
  ) {
    const { schema, table, stateTransitionData } = stateTransitionParams;
    return await apiClient(
      "POST",
      `/app/admin/model/${schema}/${table}/workflow/transition`,
      token,
      stateTransitionData,
    );
  }

  public async createState(token: string, params: CreateStateParams) {
    const { schema, table, stateData } = params;
    return await apiClient(
      "POST",
      `/app/admin/model/${schema}/${table}/workflow/state`,
      token,
      stateData,
    );
  }

  public async updateState(token: string, params: UpdateStateParams) {
    const { id, stateData } = params;
    return await apiClient(
      "PUT",
      `/app/admin/model/workflow/state/${id}`,
      token,
      stateData,
    );
  }

  public async deleteState(token: string, params: DeleteStateParams) {
    const { id } = params;
    return await apiClient(
      "DELETE",
      `/app/admin/model/workflow/state/${id}`,
      token,
    );
  }

  public async updateStateTransition(
    token: string,
    params: UpdateStateTransitionParams,
  ) {
    const { transitionId, schema, table, stateTransitionData } = params;
    return await apiClient(
      "PUT",
      `/app/admin/model/${schema}/${table}/workflow/transition/${transitionId}`,
      token,
      stateTransitionData,
    );
  }

  public async workflowSetup(token: string, params: WorkflowSetupParams) {
    const { schema, table, workflowSetupAPIData } = params;

    return await apiClient(
      "POST",
      `/app/admin/model/${schema}/${table}/workflow`,
      token,
      workflowSetupAPIData,
    );
  }

  public async workflowActivationSwitch(
    token: string,
    params: WorkflowActivationParams,
  ) {
    const { schema, table, active } = params;

    return await apiClient(
      "PUT",
      `/app/admin/model/${schema}/${table}/workflow/toggle`,
      token,
      { active },
    );
  }

  public async setColumAlias(
    token: string,
    params: {
      schema: string;
      table: string;
      column: string;
    },
  ) {
    const { schema, table, column } = params;
    return await apiClient<SetColumnAliasResponse>(
      "PUT",
      `/app/admin/model/${schema}/${table}/lookup`,
      token,
      { column },
    );
  }

  public async deleteStateTransition(
    token: string,
    params: DeleteStateTransitionParams,
  ) {
    const { id } = params;
    return await apiClient(
      "DELETE",
      `/app/admin/model/workflow/transition/${id}`,
      token,
    );
  }

  public async getGeneratedQuery(token: string, params: { viewName: string }) {
    const { viewName } = params;
    return await apiClient<CustomQueryResponse>(
      "GET",
      `/app/admin/model/query/${viewName}`,
      token,
    );
  }

  public async testCustomQuery(
    token: string,
    params: {
      data: {
        viewName: string;
        code: string;
      };
    },
  ) {
    const { data } = params;
    return await apiClient<TestCustomQueryResponse>(
      "POST",
      "/app/admin/model/query/temp",
      token,
      data,
    );
  }

  public async historyTrackingSwitch(
    token: string,
    params: HistoryTrackingSwitchParams,
  ) {
    const { schema, table, active } = params;
    return await apiClient(
      "PUT",
      `/app/admin/model/${schema}/${table}/historyTracker/toggle`,
      token,
      { active },
    );
  }

  public async getDataPreview(
    token: string,
    params: {
      data: {
        viewName: string;
        code: string;
      };
    },
  ) {
    const { data } = params;
    return await apiClient<{
      columns: ColumnsData[];
      data: Record<string, unknown>[];
    }>("POST", "/app/admin/model/query/preview", token, data);
  }

  public async deleteQuery(token: string, params: DeleteQueryParams) {
    const { name } = params;
    return await apiClient("DELETE", `/app/admin/model/query/${name}`, token);
  }

  public async deleteQueries(token: string, params: { viewNames: string[] }) {
    return await apiClient<
      { viewName: string; result: Record<string, unknown> | Error }[]
    >("DELETE", "/app/admin/model/queries", token, params);
  }

  public async deleteWorkflow(
    token: string,
    params: {
      schema: string;
      table: string;
    },
  ) {
    const { schema, table } = params;
    return await apiClient(
      "DELETE",
      `/app/admin/model/${schema}/${table}/workflow`,
      token,
    );
  }

  public async getExtensions(token: string) {
    return await apiClient<Extension[]>("GET", `/app/admin/extensions`, token);
  }

  public async installExtension(
    token: string,
    params: {
      name: string;
      version?: string;
    },
  ) {
    return await apiClient<{ success: boolean }>(
      "POST",
      `/app/admin/install_extension`,
      token,
      params,
    );
  }

  public async uninstallExtension(
    token: string,
    params: {
      name: string;
    },
  ) {
    return await apiClient<{ success: boolean }>(
      "POST",
      `/app/admin/uninstall_extension`,
      token,
      params,
    );
  }

  public async installApplication(
    token: string,
    params: {
      repositoryId: number;
      name: string;
      version: string;
    },
  ) {
    return await apiClient<{ success: boolean }>(
      "POST",
      `/app/admin/install_application`,
      token,
      params,
    );
  }

  public async uninstallApplication(
    token: string,
    params: {
      repositoryId: number;
      name: string;
      version?: string;
    },
  ) {
    return await apiClient<{ success: boolean }>(
      "POST",
      `/app/admin/uninstall_application`,
      token,
      params,
    );
  }

  public async getRepositoryConfigurations(token: string) {
    return await apiClient<RepositoryConfiguration[]>(
      "GET",
      `/app/admin/repositories`,
      token,
    );
  }

  public async getRepositoryConfiguration(
    token: string,
    params: { id: string },
  ) {
    return await apiClient<RepositoryConfiguration>(
      "GET",
      `/app/admin/repository/${params.id}`,
      token,
    );
  }

  public async updateRepositoryConfiguration(
    token: string,
    params: RepositoryConfiguration,
  ) {
    return await apiClient<RepositoryConfiguration>(
      "POST",
      `/app/admin/repositories`,
      token,
      params,
    );
  }

  public async testRepositoryConfiguration(
    token: string,
    params: RepositoryConfiguration,
  ) {
    return await apiClient<boolean>(
      "POST",
      `/app/admin/repository/test`,
      token,
      params,
    );
  }

  public async getRepositoryBranches(
    token: string,
    params: Pick<
      RepositoryConfiguration,
      "repositoryPath" | "repositoryBaseUrl" | "personalAccessToken"
    >,
  ) {
    return await apiClient<RepositoryBranch[] | { message: string }>(
      "POST",
      `/app/admin/repository/branches`,
      token,
      params,
    );
  }

  public async deleteRepositoryConfiguration(
    token: string,
    params: { id: number },
  ) {
    return await apiClient("DELETE", `/app/admin/repositories`, token, params);
  }

  public async getRepositoriesContent(token: string) {
    return await apiClient<RepositoryData[]>(
      "GET",
      `/app/admin/repositories/content`,
      token,
    );
  }

  public async getRepositoryResourceContent(
    token: string,
    params: {
      repositoryId: number;
      resourcePath: string;
    },
  ) {
    return await apiClient<string>(
      "GET",
      `/app/admin/repositories_resource_content`,
      token,
      undefined,
      params,
    );
  }

  public async getQueryGroups(token: string) {
    return await apiClient<QueryGroup[]>(
      "GET",
      `/app/admin/model/query-groups`,
      token,
    );
  }

  public async createQueryGroup(
    token: string,
    params: { i18n: Translation<"label"> },
  ) {
    return await apiClient<QueryGroup>(
      "POST",
      `/app/admin/model/group/`,
      token,
      params,
    );
  }

  public async updateQueryGroup(
    token: string,
    { id, i18n }: { id: number; i18n: Translation<"label"> },
  ) {
    return await apiClient<QueryGroup>(
      "PATCH",
      `/app/admin/model/group/${id}`,
      token,
      { i18n },
    );
  }

  public async deleteQueryGroup(token: string, params: { id: number }) {
    return await apiClient<DeleteQueryGroup>(
      "DELETE",
      `/app/admin/model/group/${params.id}`,
      token,
    );
  }

  public async getInternalModel(token: string) {
    return await apiClient<DataModel>(
      "GET",
      `/app/admin/model/internal`,
      token,
    );
  }

  public async updateQueryWithQueryGroup(
    token: string,
    { id, queryGroupId }: { id: string; queryGroupId: number | null },
  ) {
    return await apiClient(
      "PATCH",
      `/app/admin/model/query/${id}/assign-group`,
      token,
      { queryGroupId },
    );
  }

  public async getConfig(token: string) {
    return await apiClient<Record<string, string>[]>(
      "GET",
      "/app/config",
      token,
    );
  }

  public async updateConfig(
    token: string,
    config: Record<string, string | number>,
  ) {
    return await apiClient<Record<string, string>>(
      "PUT",
      "/app/config",
      token,
      config,
    );
  }
}
