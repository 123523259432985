import * as t from "io-ts";
import { types } from "core/runtime-typing";
import {
  IElement,
  IElementArrayChild,
  IElementSingleChild,
  UntransformedConfig,
} from "core/types";
import { SelectorTypes } from "core/types/element";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";

import { FormInputConfig } from "../common";

export const sliderInputSelectors: SelectorTypes<SliderInputConfig> = {
  value: types.nullable(types.number()),
  disabled: types.optional(types.boolean()),
};

export const Mark = t.type({
  value: t.number,
});

export const SliderInputConfig = t.intersection([
  WithOptionalFieldDataSourceConfig(
    t.union([t.number, t.array(t.number), t.null]),
  ),
  FormInputConfig,
  t.type({
    min: t.number,
    max: t.number,
  }),
  t.partial({
    step: t.union([t.null, t.number]),
    color: t.keyof({ primary: null, secondary: null }),
    marks: t.union([t.boolean, t.array(Mark)]),
    orientation: t.keyof({ horizontal: null, vertical: null }),
    track: t.union([
      t.literal("normal"),
      t.literal(false),
      t.literal("inverted"),
    ]),
    value: t.union([t.number, t.array(t.number)]),
    valueLabelDisplay: t.keyof({
      on: null,
      off: null,
      auto: null,
    }),
  }),
]);

export type SliderInputConfig = t.TypeOf<typeof SliderInputConfig>;

export type UntransformedSliderInputConfig =
  UntransformedConfig<SliderInputConfig>;

export const SliderInputTranslationKeys = ["label"] as const;

export type SliderInputTranslationKeys =
  (typeof SliderInputTranslationKeys)[number];

export type SliderInput = IElement<
  SliderInputConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  SliderInputTranslationKeys
>;
