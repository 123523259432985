import {
  IElementComponentProps,
  PropsFromConnector,
  createColorSelector,
} from "core";
import {
  actions as routerActions,
  selectors as routerSelectors,
} from "core/router/reduxModule";
import { actions } from "core/session/reduxModule";
import { connectElement } from "core/utils/react-redux";

import Component from "./component";
import { ReduxModule } from "./reduxModule";
import { Table } from "./types";

const mapCreateButtonState = (
  state: any,
  config: IElementComponentProps<ReduxModule, Table>["element"]["config"],
) => {
  let linkTo: {
    pageId: string;
    params: Record<string, any>;
  } = {
    pageId: "",
    params: {},
  };

  if (!config.createButton) {
    return {
      enabled: false,
      disabled: false,
      color: undefined,
      linkTo,
    };
  }
  const allPages = routerSelectors.allPages(state);

  const { createButton } = config;

  const params = Object.keys(createButton?.linkTo.params).reduce(
    (p, k) => ({
      ...p,
      [k]: createButton.linkTo.params[k](state),
    }),
    {},
  );

  linkTo = {
    pageId: createButton.linkTo.pageId,
    params,
  };

  return {
    enabled:
      typeof createButton.enabled === "boolean"
        ? createButton.enabled
        : createButton.enabled?.(state),
    disabled: createButton.disabled?.(state),
    color: createColorSelector(createButton.color)(state),
    linkTo,
    targetPage: allPages?.[linkTo?.pageId],
  };
};

const mapStateToProps = (
  state: any,
  { module, element }: IElementComponentProps<ReduxModule, Table>,
) => ({
  location: routerSelectors.location(state),
  getColOrder: module.utils.getColOrder,
  createButtonState: mapCreateButtonState(state, element.config),
});

const mapDispatchToProps = {
  updateQueryStringValues: routerActions.updateQueryStringValues,
  enqueueSnackbar: actions.enqueueSnackbar,
  navigate: routerActions.push,
};

const connector = connectElement<
  ReduxModule,
  Table,
  typeof mapStateToProps,
  typeof mapDispatchToProps
>(mapStateToProps, mapDispatchToProps);

export type Props = PropsFromConnector<typeof connector>;
export type MapStateToProps = ReturnType<typeof mapStateToProps>;
export type MapDispatchToProps = typeof mapDispatchToProps;
export default connector(Component);
