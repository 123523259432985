import { memo } from "react";
import FormControl from "@mui/material/FormControl";
import { Editor, EditorChange } from "codemirror";
import { ConnectedReduxModuleProps } from "core";

import { CodeEditor } from "../common/CodeEditor";

import { ReduxModule } from "./reduxModule";
import { CodeInput } from "./types";

const DefaultCodeInput = memo<
  ConnectedReduxModuleProps<ReduxModule, CodeInput>
>(
  ({
    value,
    element: {
      i18n: { label },
      config: { language, disabled },
    },
    required,
    changeValue,
  }) => {
    const handleInputChange = (
      _editor: Editor,
      _data: EditorChange,
      newValue: string,
    ) => changeValue(newValue || null);

    return (
      <FormControl fullWidth={true} sx={{ height: "100%", width: "100%" }}>
        <CodeEditor
          value={value}
          label={label}
          onChange={handleInputChange}
          options={{ readOnly: disabled }}
          language={language}
          onAutoComplete={false}
          stylesAsMuiInput
          required={required}
        />
      </FormControl>
    );
  },
);

export default DefaultCodeInput;
