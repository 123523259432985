import {
  ChangeEvent,
  MouseEvent,
  SyntheticEvent,
  memo,
  useCallback,
  useMemo,
} from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

import { Node } from "dagre";
import { useNodes, useReactFlow } from "reactflow";

import IconButton from "elementTypes/common/IconButton";
import { useAdminContext } from "staticPages/admin/context";
import { useDebouncedState } from "utils/hooks";
import { DataModel, NodeData, Table, View } from "../erd/types";

import { useDatabaseTranslation } from "../translation";
import { EmptyData } from "./EmptyData";
import { useStyles } from "./styles";
import TableDetails from "./TableDetails";
import { fuseFn, getFilteredTables } from "./utils";

type IProps = {
  entities: DataModel;
  showActions?: boolean;
};

export const EntitiesPanel = memo<IProps>(
  ({ entities, showActions = true }) => {
    const translation = useDatabaseTranslation();
    const { classes } = useStyles();
    const nodes = useNodes<NodeData>();
    const { setCenter, fitView } = useReactFlow();
    const {
      filter: { schema: schemaFilter },
      onFilterChange,
      selectedNode,
      setSelectedNode,
      entitiesSearchValue,
      setEntitiesSearchValue,
      tableViewFocus,
      setTableViewFocus,
      expandedTableList,
      setExpandedTableList,
      setSelectedQueryEntity,
    } = useAdminContext();

    const [cachedSearchValue, cachedHandleSearch] = useDebouncedState(
      entitiesSearchValue,
      setEntitiesSearchValue,
    );

    const [cachedTableViewFocus, cachedHandViewFocus] = useDebouncedState(
      tableViewFocus,
      setTableViewFocus,
    );

    const [cachedExpanded, cachedHandleExpanded] = useDebouncedState(
      expandedTableList,
      setExpandedTableList,
    );

    const handleSetCenter = useCallback(
      (node: Node<any>, zoom = 0.5) => {
        const x = node.position.x + node.width / 2;
        const y = node.position.y + node.height / 2;

        setCenter(x, y, { zoom });
      },
      [setCenter],
    );

    const findNode = useCallback(
      (schema: string, entity: string, type: "table" | "view") => {
        return nodes.find(
          (n) =>
            n.data.schemaName === schema && n.data[`${type}Name`] === entity,
        );
      },
      [nodes],
    );

    const handleFocusing = useCallback(() => {
      if (!cachedTableViewFocus && selectedNode) {
        const { tables, views } = entities;

        Object.entries(tables).map(([schema, tableObject]) => {
          const foundNode = Object.values(tableObject).find(
            (table) => `${schema}.${table.table}` === selectedNode.id,
          );

          if (foundNode) {
            const node = findNode(schema, foundNode.table, "table");
            node && handleSetCenter(node, 0.8);
          }

          if (!foundNode) {
            Object.entries(views).map(([schemaName, viewObject]) => {
              const foundView = Object.values(viewObject).find(
                (view) => `${schemaName}.${view.view}` === selectedNode.id,
              );

              if (foundView) {
                const node = findNode(schemaName, foundView.view, "view");
                node && handleSetCenter(node, 0.8);
              }
            });
          }
        });
      }

      if (cachedTableViewFocus) {
        fitView();
      }

      cachedHandViewFocus(!cachedTableViewFocus);
    }, [
      cachedHandViewFocus,
      cachedTableViewFocus,
      entities,
      findNode,
      fitView,
      handleSetCenter,
      selectedNode,
    ]);

    const focusEntity = useCallback(
      (schema: string, entity: string, type: "table" | "view") => () => {
        const node = findNode(schema, entity, type);

        if (node) {
          cachedTableViewFocus && handleSetCenter(node, 0.8);
          setSelectedNode(node);
        }
      },

      [findNode, cachedTableViewFocus, handleSetCenter, setSelectedNode],
    );

    const selectNode = useCallback(
      (node: string) => (event: MouseEvent<HTMLLIElement>) => {
        if (event.detail === 2) {
          setSelectedQueryEntity(node);
        }
      },
      [setSelectedQueryEntity],
    );

    const handleSearchChange = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      cachedHandleSearch(e.target.value);

      if (e.target.value?.trim()?.length && !cachedExpanded?.length) {
        handleExpandClick();
      }
    };

    const filteredTables = getFilteredTables(
      entities?.tables ?? {},
      cachedSearchValue,
    );

    const tables = (
      <TablesTreeView
        {...{
          subKey: "entities",
          tables: filteredTables,
          onClickCb: (cbSchema, cbTable) => () =>
            showActions
              ? focusEntity(cbSchema, cbTable, "table")()
              : selectNode(`${cbSchema}.${cbTable}`),
        }}
      />
    );

    const views = useMemo(
      () =>
        Object.entries(entities?.views ?? {}).map(([schema, viewObject]) => {
          if (!viewObject) {
            return null;
          }
          const viewObjects: View[] = Object.values(viewObject);
          const isChecked = !schemaFilter.includes(schema);

          const filteredViewObjects = fuseFn(viewObjects, [
            { name: "view", weight: 2 },
            { name: "schema", weight: 1 },
          ])
            .search(`'${cachedSearchValue}`)
            .map((viewObj) => ({
              ...viewObj.item,
            })) as View[];

          const preparedViewObjects = cachedSearchValue.trim()
            ? filteredViewObjects
            : viewObjects;

          const handleClick = (ev: MouseEvent<HTMLButtonElement>) => {
            ev.preventDefault();
            ev.stopPropagation();

            const nextFilter = isChecked
              ? [...schemaFilter, schema]
              : schemaFilter.filter((sch) => sch !== schema);

            onFilterChange("schema", nextFilter);
          };

          return (
            <TreeItem
              key={schema}
              itemId={`${schema}_view`}
              disabled={!isChecked}
              label={
                <Box
                  display="flex"
                  gap={1}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {schema}
                  <IconButton
                    icon={isChecked ? "visibility" : "visibility_off"}
                    color={isChecked ? "primary" : "inherit"}
                    size="small"
                    onClick={handleClick}
                    edge="end"
                  />
                </Box>
              }
            >
              {preparedViewObjects?.map((view) =>
                view ? (
                  <TreeItem
                    key={view.view}
                    itemId={view.view}
                    disabled={!isChecked}
                    label={
                      <Typography className={classes.textOverflow}>
                        {view.view}
                      </Typography>
                    }
                    onClick={
                      showActions
                        ? focusEntity(schema, view.view, "view")
                        : selectNode(`${schema}.${view.view}`)
                    }
                  />
                ) : null,
              )}
            </TreeItem>
          );
        }),
      [
        entities?.views,
        schemaFilter,
        cachedSearchValue,
        onFilterChange,
        classes.textOverflow,
        showActions,
        focusEntity,
        selectNode,
      ],
    );

    const handleToggle = (_: SyntheticEvent, nodeIds: string[]) => {
      cachedHandleExpanded(nodeIds);
    };

    const handleExpandClick = () => {
      const nextVal = cachedExpanded.length
        ? []
        : [
            "tables",
            "views",
            ...Object.keys(entities?.tables ?? {}),
            ...Object.keys(entities?.views ?? {}).map(
              (schema) => `${schema}_view`,
            ),
          ];
      cachedHandleExpanded(nextVal);
    };

    return (
      <Card
        className={`${classes.cardHeight} ${classes.card}`}
        variant="outlined"
      >
        <CardHeader
          disableTypography
          avatar={
            <Box display="flex">
              <IconButton
                icon={"table_chart"}
                edge="start"
                tooltip="An entity, practically a tracked table in CYPEX, plays an important role in the GUI prediction process operated by the underlying data structure."
              />
            </Box>
          }
          classes={{
            root: classes.cardHeader,
            avatar: classes.queryHeaderAvatar,
          }}
          title={<Typography variant="h6">{translation.entities}</Typography>}
          action={
            <Box display="flex" alignItems="center">
              <IconButton
                icon={cachedExpanded.length ? "unfold_less" : "unfold_more"}
                tooltip={
                  cachedExpanded.length
                    ? translation.collapseAllTooltip
                    : translation.expandAllTooltip
                }
                onClick={handleExpandClick}
                disabled={!(views.length + Object.keys(filteredTables).length)}
              />
              {showActions && (
                <IconButton
                  icon={cachedTableViewFocus ? "centerFocusStrong" : "cropFree"}
                  tooltip={
                    cachedTableViewFocus
                      ? translation.disableFocusing
                      : translation.enableFocusing
                  }
                  onClick={handleFocusing}
                />
              )}
            </Box>
          }
        />
        <Box p={1} borderTop="1px solid" borderColor="divider">
          <TextField
            InputProps={{ endAdornment: <SearchIcon /> }}
            fullWidth
            size="small"
            variant="standard"
            placeholder={translation.searchLabel}
            value={cachedSearchValue}
            onChange={handleSearchChange}
          />
        </Box>
        <Divider />
        {Object.keys(filteredTables).length || views.length ? (
          <Box className={classes.treeStyle}>
            <SimpleTreeView
              expandedItems={cachedExpanded}
              onExpandedItemsChange={handleToggle}
            >
              <TreeItem itemId="tables" label={translation.tables}>
                {tables}
              </TreeItem>
              <TreeItem itemId="views" label={translation.views}>
                {views}
              </TreeItem>
            </SimpleTreeView>
          </Box>
        ) : (
          <EmptyData title="No entities found" />
        )}
      </Card>
    );
  },
);

export const TablesTreeView = ({
  subKey,
  tables,
  hideSize,
  hideAction,
  onClickCb,
}: {
  subKey: string;
  tables: Record<string, Record<string, Table>>;
  hideSize?: boolean;
  hideAction?: boolean;
  onClickCb?: (schema: string, table: string) => () => void;
}) => {
  const {
    filter: { schema: shemaFilter },
    onFilterChange,
  } = useAdminContext();

  const items = Object.entries(tables).map(([schema, tableObject]) => {
    if (!tableObject) {
      return null;
    }

    const schemaTables = Object.values(tableObject) ?? [];
    const isChecked = !shemaFilter.includes(schema);

    const handleClick = (ev: MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      ev.stopPropagation();

      const nextFilter = isChecked
        ? [...shemaFilter, schema]
        : shemaFilter.filter((sch) => sch !== schema);

      onFilterChange("schema", nextFilter);
    };

    return (
      <TreeItem
        key={`${subKey}-${schema}`}
        itemId={schema}
        disabled={!isChecked}
        label={
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
          >
            {schema}
            <IconButton
              icon={
                hideAction
                  ? "delete_outline"
                  : isChecked
                    ? "visibility"
                    : "visibility_off"
              }
              color={isChecked ? "primary" : "inherit"}
              size="small"
              onClick={handleClick}
              edge="end"
            />
          </Box>
        }
      >
        {schemaTables.map((schemaTable) => (
          <TableDetails
            key={`${subKey}-${schemaTable.schema}-${schemaTable.table}`}
            schema={schema}
            table={schemaTable.table}
            tableSize={hideSize ? undefined : schemaTable.tableSize}
            tableSchema={schemaTable.schema}
            {...(!hideAction && {
              onClick: onClickCb?.(schema, schemaTable.table),
            })}
          />
        ))}
      </TreeItem>
    );
  });

  return <>{items}</>;
};
