import { TypedUseSelectorHook, useSelector } from "react-redux";
import { selectors } from "core/session/reduxModule";
import { reducer as rootReducer } from "core/session/reduxModule/reducer";

export type RootState = ReturnType<typeof rootReducer>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type SelectorKey = keyof typeof selectors & keyof RootState;

export const useSession = <T extends SelectorKey>(key: T) =>
  useAppSelector((state: RootState) => selectors[key](state));
