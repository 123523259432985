import { handleActions } from "core/utils/redux";

import { ActionTypes, IReducerState, Types } from "./types";

export const INITIAL_STATE: IReducerState = {
  loading: false,
  error: null,
  files: null,
  metadata: null,
  metadataError: null,
};

export function buildReducer<State extends IReducerState>(
  types: Types,
  initialState: State,
) {
  return handleActions<State, ActionTypes>(initialState, {
    [types.UPLOAD]: (state, action: ActionTypes["upload"]) => ({
      ...state,
      loading: true,
      error: null,
      metadataError: null,
      files: action.payload.files,
    }),
    [types.FETCH_METADATA]: (state) => ({
      ...state,
      loading: true,
      error: null,
      metadataError: null,
    }),
    [types.UPLOAD_SUCCESS]: (state, action: ActionTypes["uploadSuccess"]) => ({
      ...state,
      loading: false,
      metadataError: null,
      metadata: [...(state.metadata ?? []), ...(action.payload.metadata ?? [])],
    }),
    [types.FETCH_METADATA_SUCCESS]: (
      state,
      action: ActionTypes["fetchMetadataSuccess"],
    ) => ({
      ...state,
      loading: false,
      metadataError: null,
      metadata: action.payload.metadata,
    }),
    [types.UPLOAD_ERROR]: (state, action: ActionTypes["uploadError"]) => ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
    [types.FETCH_METADATA_ERROR]: (
      state,
      action: ActionTypes["fetchMetadataError"],
    ) => ({
      ...state,
      loading: false,
      metadataError: action.payload.error,
    }),
    [types.CLEAR]: (state, action: ActionTypes["clear"]) => {
      return {
        ...state,
        loading: true,
        metadataError: null,
        metadata: (state.metadata ?? [])?.filter(
          (mData) => mData.fileName !== action.payload.fileName,
        ),
        files: null,
      };
    },
    [types.CLEAR_ALL]: (state, _action: ActionTypes["clearAll"]) => ({
      ...state,
      loading: true,
      metadataError: null,
      metadata: null,
      files: null,
    }),
    [types.CLEAR_SUCCESS]: (state) => ({
      ...state,
      loading: false,
    }),
  });
}
