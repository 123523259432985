import { Type } from "./Type";
import { TypeError } from "./TypeError";
import { IAutocompleteKey } from "./types";

export class NullType extends Type {
  public name = "null";

  public validate(value: any): true | TypeError {
    return value === null ? true : new TypeError(value, this);
  }

  public getAutocompleteRecord(): Record<string, IAutocompleteKey> {
    return {};
  }
}
