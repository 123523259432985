import { useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  useCreateQueryGroup,
  useDeleteQueries,
  useDeleteQuery,
  useDeleteQueryGroup,
  useEditDefaultQuery,
  useEditQueryGroup,
  useGeneratedQuery,
  useUpdateQueryWithQueryGroup,
} from "queries/admin";
import { getApiError } from "queries/utils.ts";
import { CustomQueryResponse } from "staticPages/admin/pages/modelBuilder/customQuery/types.ts";
import { useSnackbar } from "utils/hooks/useSnackbar.ts";
import { usePermissionContext } from "../../../context/databasePanelPermission/PermissionContext.utils.ts";
import { ModalContentType } from "../../types.ts";
import { TQueryFetchUtils } from "../types.ts";

export const useQueryFetch = ({
  createQueryGroupParams,
  deleteQueryGroupParams,
  saveDefaultQueryParams,
  deleteQueryParams,
  deleteQueriesParams,
  customQuery: { modal },
}: TQueryFetchUtils) => {
  const { permissions, setPermissions } = usePermissionContext();

  const queryClient = useQueryClient();
  const showSnackbar = useSnackbar();

  const invalidateQueries = (keys?: string[]) =>
    (
      keys ?? [
        QueryKeys.fetchQueryGroups,
        QueryKeys.fetchQueries,
        QueryKeys.fetchModel,
      ]
    ).map((queryKey) =>
      queryClient.invalidateQueries({
        queryKey: [queryKey],
      }),
    );

  const onError = (error: unknown) => {
    const msg = getApiError(error);
    showSnackbar(msg, "error");
  };

  // refetch to get permissions
  useGeneratedQuery(
    { viewName: modal?.selected?.name ?? "" },
    {
      enabled:
        !!modal?.selected?.name &&
        modal.type === ModalContentType.queryEdit &&
        !Object.keys(permissions ?? {}).length,
      onSuccess: (data: CustomQueryResponse) => {
        const nextPermissions = data.permissions.reduce(
          (res, permission) => ({
            ...res,
            [permission.grantee]: permission.privileges,
          }),
          {} as Record<string, string[]>,
        );
        setPermissions(nextPermissions);
      },
    },
  );

  const { mutate: createQueryGroup } = useCreateQueryGroup({
    mutationKey: [QueryKeys.createQueryGroup],
    onSuccess: () => {
      invalidateQueries();
      createQueryGroupParams.onSuccess();
    },
    onError,
  });

  const { mutate: editQueryGroup } = useEditQueryGroup({
    mutationKey: [QueryKeys.updateQueryGroup],
    onSuccess: () => invalidateQueries(),
    onError,
  });

  const { mutate: saveDefaultQuery } = useEditDefaultQuery({
    mutationKey: [QueryKeys.deleteQuery],
    onSuccess: () => {
      invalidateQueries();

      saveDefaultQueryParams.onSuccess();
    },
    onError,
  });

  const { mutate: deleteQueryGroup } = useDeleteQueryGroup({
    mutationKey: [QueryKeys.deleteQueryGroup],
    onSuccess: () => {
      invalidateQueries();

      deleteQueryGroupParams.onSuccess();
    },
    onError,
  });

  const { mutate: deleteQuery } = useDeleteQuery({
    mutationKey: [QueryKeys.deleteQuery],
    onSuccess: () => {
      invalidateQueries();

      deleteQueryParams.onSuccess();
    },
    onError,
  });

  const { mutate: deleteQueries } = useDeleteQueries({
    mutationKey: [QueryKeys.deleteQueries],
    onSuccess: () => {
      invalidateQueries();

      deleteQueriesParams.onSuccess();
    },
    onError,
  });

  const { mutate: updateQueryWithQueryGroup } = useUpdateQueryWithQueryGroup({
    mutationKey: [QueryKeys.updateQueryWithQueryGroup],
    onError,
    onSuccess: () => {
      invalidateQueries([QueryKeys.fetchQueries, QueryKeys.fetchQueryGroups]);
    },
  });

  return {
    createQueryGroup,
    editQueryGroup,
    deleteQueryGroup,
    updateQueryWithQueryGroup,
    saveDefaultQuery,
    deleteQuery,
    deleteQueries,
  };
};
