import { memo, useMemo } from "react";
import TBody from "@mui/material/TableBody";
import { IElement } from "core";

import { Props as ContainerProps } from "../container";
import { ISelectedRow } from "../reduxModule/types";
import { CellAlignment, TableConfig } from "../types";
import { getReferencesFromArray } from "../utils";
import { TableRow } from ".";

type Props = {
  data: any[];
  elements: IElement[];
  alignments: CellAlignment[];
  selectRow: ContainerProps["selectRow"];
  selected: ISelectedRow;
  referencesData: Record<string, any[] | null> | null;
  referencesSource?: TableConfig["dataSource"]["references"];
  identifierName?: string;
  error?: string | null;
  loading?: boolean;
  canSelectRow?: boolean;
};

export const TableBody = memo<Props>(
  ({
    data,
    identifierName,
    elements,
    alignments,
    error,
    loading,
    canSelectRow,
    selectRow,
    selected,
    referencesSource,
    referencesData,
  }) => {
    const hasReference =
      Object.keys(referencesSource ?? {}).length &&
      Object.keys(referencesData ?? {}).length;

    // create the reference objects for each row
    // useMemo is important here, because when this changes, the column
    // elements are remounted
    const dataReferenceMapping = useMemo(
      () =>
        data.map((row: Record<string, unknown>) =>
          !hasReference
            ? null
            : Object.entries(referencesSource!).reduce(
                (result, [key, source]) => {
                  const rowValue = row[key];
                  return {
                    ...result,
                    [key]: Array.isArray(rowValue)
                      ? getReferencesFromArray(
                          key,
                          rowValue,
                          referencesData,
                          source.identifierName,
                        )
                      : referencesData?.[key]?.find(
                          (refData) =>
                            refData[source.identifierName] === row[key],
                        ) ?? null,
                  };
                },
                {},
              ),
        ),
      [data, hasReference, referencesData, referencesSource],
    );

    const bodyItems = useMemo(
      () =>
        data.map((row: Record<string, string | number>, idx: number) => {
          const identifier = identifierName ? row[identifierName] : idx;
          const references = dataReferenceMapping[idx];

          return (
            <TableRow
              key={`${identifier}-row-${idx}`}
              elements={elements}
              alignments={alignments}
              identifier={identifier}
              row={row}
              references={references}
              canSelectRow={canSelectRow}
              selectRow={selectRow}
              selected={identifier === (selected && selected.identifier)}
            />
          );
        }),
      [
        data,
        identifierName,
        dataReferenceMapping,
        elements,
        alignments,
        canSelectRow,
        selectRow,
        selected,
      ],
    );

    return (
      <TBody>
        {!loading && (!data.length || Boolean(error)) ? <></> : bodyItems}
      </TBody>
    );
  },
);

TableBody.displayName = "TableBody";
