import { createUseTranslation } from "core/session/translation";

export const geoJSONInputEditorTranslation = {
  en: {
    modeTitle: "Modes",
    defaultGeoJsonValueTitle: "Default Value",
    defaultGeoJsonValue: "Default GeoJSON Value",
    coordinatesTitle: "Coordinates",
    latitude: "Latitude",
    longitude: "Longitude",
    zoom: "Zoom",
    deleteLabel: "Allow deletion",
    dragLabel: "Allow dragging",
    lineLabel: "Allow drawing lines",
    polygonLabel: "Allow drawing polygons",
    rectangleLabel: "Allow drawing rectangles",
    markerLabel: "Allow drawing markers",
    textLabel: "Allow drawing text",
    translationLabel: "Translation",
    tileLayerUrlLabel: "Tile Layer URL",
    tileLayerUrlDescription: "Leave empty to use OpenStreetMaps",
    maximumFeaturesLabel: "Maximum amount of features",
    maximumFeaturesDescription: "Creating new features will be disabled",
  },
};

export const useGeoJSONInputEditorTranslation = createUseTranslation(
  geoJSONInputEditorTranslation,
);
