import { createAction, createActionTypeScoper } from "core/utils/redux";
import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";

import { IAutocompleteValue } from "../../../common/Autocomplete/types";
import { ValueObject } from "../../../common/Autocomplete/utils.tsx";

export function buildActions(
  scope: string,
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    LOAD_OPTIONS: scopeActionType("LOAD_OPTIONS"),
    LOAD_OPTIONS_SUCCESS: scopeActionType("LOAD_OPTIONS_SUCCESS"),
    LOAD_OPTIONS_ERROR: scopeActionType("LOAD_OPTIONS_ERROR"),
    SEARCH_INPUT_VALUE_CHANGE: scopeActionType("SEARCH_INPUT_VALUE_CHANGE"),
    SET_VALUE_OBJECT: scopeActionType("SET_VALUE_OBJECT"),
  };

  const changeValue = (value: IAutocompleteValue) =>
    dataSource.changeFieldValue(fieldPath, value);

  const changeTouched = (value: boolean) =>
    dataSource.changeFieldTouched(fieldPath, value);

  const actions = {
    changeValue,
    changeTouched,
    changeSearchInputValue: createAction(
      types.SEARCH_INPUT_VALUE_CHANGE,
      (searchInputValue: string | null) => ({ searchInputValue }),
    ),
    loadOptions: createAction(
      types.LOAD_OPTIONS,
      (setInputValue?: boolean) => ({ setInputValue }),
    ),
    loadOptionsSuccess: createAction(
      types.LOAD_OPTIONS_SUCCESS,
      (
        options: any[] | null,
        rawOptions: any[] | null,
        moreDataAvailable = false,
      ) => ({
        options,
        rawOptions,
        moreDataAvailable,
      }),
    ),
    loadOptionsError: createAction(types.LOAD_OPTIONS_ERROR, (error: any) => ({
      error,
    })),
    setValueObject: createAction(
      types.SET_VALUE_OBJECT,
      (valueObject: ValueObject) => ({
        valueObject,
      }),
    ),
  };

  return {
    actions,
    types,
  };
}
