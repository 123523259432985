import { IReduxModuleFactoryArgs } from "core";
import { buildSimpleUncontrolledInputModule } from "core/utils/buildSimpleUncontrolledInputModule";

import { DateTimeInput } from "../../types";

import { uncontrolledSelectors } from "./selectors";

const INITIAL_STATE = {
  value: null,
  errors: null,
};

export function uncontrolledReduxModuleFactory({
  element,
  path,
}: IReduxModuleFactoryArgs<DateTimeInput>) {
  const uncontrolledModule = buildSimpleUncontrolledInputModule(
    element,
    path,
    INITIAL_STATE,
  );

  const selectors = uncontrolledSelectors(element.config);

  return {
    ...uncontrolledModule,
    selectors: {
      ...uncontrolledModule.selectors,
      ...selectors,
      value: selectors.value(uncontrolledModule.selectors.value),
      disabled: () => Boolean(element.config.disabled),
    },
  };
}
