import { IElement, IElementComponentProps } from "core";
import { WithFieldDataSourceConfig } from "elementInterfaces/FormDataSource/types";
import { FormInputConfig } from "./FormInputConfig";

type DataSource = WithFieldDataSourceConfig["dataSource"];

export function getColumnName(dataSource?: DataSource): string {
  return dataSource && dataSource.fieldPath
    ? dataSource.fieldPath[0].toString() // since `fieldPath[0]` can be a number
    : "";
}

// used to make element selectable by the editor
export const NON_BREAKING_SPACE = "\u00A0";

// the marker used to show required field
export const ASTERISK_SYMBOL = "\u00a0*";

export const getDisabledValueFromConfig = <
  T extends IElement<FormInputConfig, {}, string>,
>(
  state: any,
  config: IElementComponentProps<{}, T>["element"]["config"],
) =>
  typeof config.disabled === "boolean"
    ? config.disabled
    : config.disabled?.(state);
