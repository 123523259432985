import { useMemo } from "react";
import { useRouterParamByName } from "core/router/reduxModule/utils";
import { Language, Translation } from "../../../../../core";
import { useSessionContext } from "../../../../../core/session";
import { getTranslatedText } from "../../../../../core/utils/element-utils";
import { useModel, useQueries } from "../../../../../queries/admin";
import { QueryObject } from "../components/types";
import { DataModel } from "../erd/types";

const getTitle = (i18n: Translation<"title">, language: Language) =>
  getTranslatedText(language, i18n, "title") ?? "";
export const useDatabaseData = (role?: string) => {
  const { language } = useSessionContext();
  const queries = useQueries(role ? { role } : undefined);

  const model = useModel();
  const queryName = useRouterParamByName("queryName", "");

  const sortedlist = useMemo(
    () =>
      ((queries.data ?? []) as QueryObject[]).sort((a, b) =>
        getTitle(a.i18n as Translation<"title">, language)?.localeCompare(
          getTitle(b.i18n as Translation<"title">, language),
        ),
      ),
    [queries.data, language],
  );

  const dataModel = useMemo(
    () =>
      (model.data ?? {
        tables: {},
        views: {},
        constraints: [],
      }) as DataModel,
    [model.data],
  );

  return {
    queryName,
    queries: {
      ...queries,
      data: sortedlist,
    },
    model: {
      ...model,
      data: dataModel,
    },
  };
};
