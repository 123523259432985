import { MouseEvent, MutableRefObject, memo, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { toUpperCase } from "fp-ts/lib/string";
import { toJpeg, toPng, toSvg } from "html-to-image";
import { useReactFlow } from "reactflow";
import Button from "elementTypes/common/Button";
import { useSnackbar } from "utils/hooks";

type Props = {
  fileName: string;
  elementToImageRef: MutableRefObject<null | HTMLDivElement>;
};

const imageTypes = ["jpeg", "png", "svg"] as const;

type ImgType = (typeof imageTypes)[number];

const saveBlobAsFile = (fileName: string) => (dataUrl: string) => {
  // Create a new anchor element
  const anchor = document.createElement("a");
  // Set the URL
  anchor.href = dataUrl;
  // Set the download attribute with the desired file name
  anchor.download = fileName;

  // Hide the anchor element from the display
  anchor.style.display = "none";

  // Append the anchor to the document body
  (document.body || document.documentElement).appendChild(anchor);

  // Check if the click() method is available
  if (typeof anchor.click === "function") {
    anchor.click();
  } else {
    // If not, create and dispatch a click event
    anchor.target = "_blank";
    anchor.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      }),
    );
  }

  // Revoke the object URL to release resources
  URL.revokeObjectURL(anchor.href);

  // Remove the anchor element from the document
  anchor.remove();
};

const filter = (node: HTMLElement) => {
  const exclusionClasses = [
    "react-flow__minimap",
    "react-flow__controls",
    "react-flow__attribution",
  ];
  return !exclusionClasses.some((classname) =>
    node.classList?.contains(classname),
  );
};

const imageWidth = 1024;
const imageHeight = 768;

export const SaveAsImageButton = memo<Props>(
  ({ fileName, elementToImageRef }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const showSnackbar = useSnackbar();

    const imageElement = elementToImageRef?.current;
    const rect = imageElement?.getBoundingClientRect();
    const width = rect?.width ?? imageWidth;
    const height = rect?.height ?? imageHeight;

    const { fitView } = useReactFlow();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => setAnchorEl(null);
    const handleShowError = (err: any) => showSnackbar(err.message, "error");

    const handleSaveByType = (type: ImgType) => {
      fitView();
      const downloadImageWithName = saveBlobAsFile(`${fileName}.${type}`);

      const options = {
        filter,
        cacheBust: true,
        backgroundColor: "#fff",
        width,
        height,
      };

      if (imageElement) {
        return {
          png: () =>
            toPng(imageElement, options)
              .then(downloadImageWithName)
              .catch(handleShowError)
              .finally(handleCloseMenu),
          jpeg: () =>
            toJpeg(imageElement, options)
              .then(downloadImageWithName)
              .catch(handleShowError)
              .finally(handleCloseMenu),
          svg: () =>
            toSvg(imageElement, options)
              .then(downloadImageWithName)
              .catch(handleShowError)
              .finally(handleCloseMenu),
        };
      } else {
        return null;
      }
    };

    const handleClickMenu = (ev: MouseEvent<HTMLLIElement>) => {
      const imageType = ev.currentTarget.dataset.actionName as ImgType;
      handleSaveByType(imageType)?.[imageType]();
    };

    return (
      <>
        <Button
          id="basic-button"
          label="Save As Image"
          onClick={handleClick}
          {...(Boolean(anchorEl) && {
            "aria-controls": "basic-menu",
            "aria-haspopup": "true",
            "aria-expanded": "true",
          })}
        />
        <List
          {...{ anchorEl, onClose: handleCloseMenu, onClick: handleClickMenu }}
        />
      </>
    );
  },
);

const List = memo<{
  anchorEl: null | HTMLElement;
  onClose: () => void;
  onClick: (ev: MouseEvent<HTMLLIElement>) => void;
}>(({ anchorEl, onClose, onClick }) => {
  const open = Boolean(anchorEl);

  const items = imageTypes.map((type) => (
    <MenuItem key={type} data-action-name={`${type}`} onClick={onClick}>
      {toUpperCase(type)}
    </MenuItem>
  ));

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {items}
    </Menu>
  );
});
