import { memo } from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";

import ReactMde, { getDefaultToolbarCommands } from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

import { CommandMap, ToolbarCommands } from "react-mde/lib/definitions/types";

import { MuiIcon } from "../MuiIcon";

import { useStyles } from "./styles";

export type Alignment = TypographyProps["align"];

type Props = {
  value: string;
  onChange: (value: any) => void;
  onAlignmentChange: (alignment: Alignment) => void;
};

const alignments = ["left", "center", "right"] as const;
const prLanguages = ["javascript", "sql"];
const codeCommand = "`";

const isCodeBlock = (value: string) =>
  value.startsWith("``") && !value.includes("```");

export const LanguageEditor = memo<Props>(
  ({ value, onChange, onAlignmentChange }) => {
    const {
      classes: { suggestionsDropdown },
    } = useStyles();

    const commands = alignments.reduce(
      (acc, alignment) => ({
        ...acc,
        [alignment]: {
          icon: () => (
            <MuiIcon icon={`format_align_${alignment}`} fontSize="medium" />
          ),
          buttonProps: { "aria-label": `Align ${alignment}` },
          execute: () => onAlignmentChange(alignment as Alignment),
          keyCommand: "code",
        },
      }),
      {} as CommandMap,
    );

    const activeCommands = [
      ...getDefaultToolbarCommands(),
      alignments,
    ] as ToolbarCommands;

    const suggestionTriggerCharacters = [codeCommand];
    const loadSuggestions = () => {
      const suggestions = isCodeBlock(value)
        ? prLanguages.map((lang: string) => ({
            preview: <Typography variant="button">{lang}</Typography>,
            value: `${codeCommand}${lang}`,
          }))
        : [];

      return Promise.resolve(suggestions);
    };

    return (
      <ReactMde
        value={value}
        onChange={onChange}
        disablePreview={true}
        initialEditorHeight={100}
        minEditorHeight={100}
        maxEditorHeight={500}
        commands={commands}
        toolbarCommands={activeCommands}
        suggestionTriggerCharacters={suggestionTriggerCharacters}
        loadSuggestions={loadSuggestions}
        classes={{
          suggestionsDropdown,
          textArea: "language-editor-text-area",
        }}
      />
    );
  },
);
