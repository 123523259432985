import * as t from "io-ts";
import {
  ColorConfig,
  IElement,
  UntransformedConfig,
  customExpression,
} from "core/types";

export const InternalLinkButtonConfig = t.intersection([
  t.type({
    linkTo: t.type({
      pageId: t.string,
      params: t.record(t.string, customExpression(t.unknown)),
    }),
  }),
  t.partial({
    color: ColorConfig,
    size: t.keyof({
      small: null,
      medium: null,
      large: null,
    }),
    icon: t.string,
    fullWidth: t.boolean,
    disabled: customExpression(t.boolean),
    variant: t.keyof({
      outlined: null,
      contained: null,
    }),
    isIcon: t.boolean,
  }),
]);

export type InternalLinkButtonConfig = t.TypeOf<
  typeof InternalLinkButtonConfig
>;

export type UntransformedInternalLinkButtonConfig =
  UntransformedConfig<InternalLinkButtonConfig>;

export const InternalLinkButtonTranslationKeys = ["label"] as const;

export type InternalLinkButtonTranslationKeys =
  (typeof InternalLinkButtonTranslationKeys)[number];

export type InternalLinkButton = IElement<
  InternalLinkButtonConfig,
  {},
  InternalLinkButtonTranslationKeys
>;
