import { ElementGroup, IRawElementType } from "core";

import Component from "./container";
import {
  AutocompleteInputEditor,
  defaultElement,
  editorMetadata,
} from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import {
  AutocompleteInputConfig,
  AutocompleteInputTranslationKeys,
  autocompleteInputSelectors,
} from "./types";
import { getQueriesFromConfig } from "./utils";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_autocomplete_input",
  group: ElementGroup.Inputs,
  component: Component,
  editable: true,
  configType: AutocompleteInputConfig,
  selectorTypes: autocompleteInputSelectors,
  translationKeys: AutocompleteInputTranslationKeys,
  editorComponent: AutocompleteInputEditor,
  defaultElement,
  editorMetadata,
  getQueriesFromConfig,
};

export default elementType;
