import { createUseTranslation } from "core/session/translation";

export const settingsTranslation = {
  en: {
    changePasswordTitle: "Change Password",
    newPasswordLabel: "New Password",
    repeatPasswordLabel: "Repeat new password",
    repeatPasswordNotEqual: "Password and repeated password must be equal",
  },
};

export const useSettingsTranslation = createUseTranslation(settingsTranslation);
