import { memo } from "react";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { formatDistanceToNow, formatRelative } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import Link from "elementTypes/common/Link";
import { TableRow } from "staticPages/admin/common";
import { Table } from "staticPages/admin/common/components/Table";
import { default as commonStyles } from "staticPages/admin/styles";

import { actions, selectors } from "./reduxModule";
import { IUserAudit } from "./reduxModule/types";

const Row = memo<IUserAudit>(({ id, event, message, userID, timestamp }) => {
  return (
    <TableRow rowId={id}>
      <Link
        variant="body2"
        title="View user info"
        href={`/admin/users/view/${userID}`}
      >
        {userID}
      </Link>
      <Typography>{event}</Typography>
      <Typography>{message}</Typography>
      <Tooltip title={formatRelative(new Date(timestamp), new Date())}>
        <Typography>
          {formatDistanceToNow(new Date(timestamp), {
            addSuffix: true,
          })}
        </Typography>
      </Tooltip>
    </TableRow>
  );
});

export const UsersAuditPage = memo(() => {
  const {
    classes: { horizontallyCenter },
  } = commonStyles();
  const auditsList = useSelector(selectors.auditsList);
  const loading = useSelector(selectors.loading);
  const error = useSelector(selectors.error);
  const dispatch = useDispatch();

  const titles = ["User ID", "Event", "Message", "Date"];

  const rows = auditsList.map((audit) => <Row key={audit.id} {...audit} />);

  const handleDataReload = () => dispatch(actions.load());

  return (
    <Grid container spacing={2} className={horizontallyCenter}>
      <Grid item xs={12}>
        <Typography variant="h5">Audit Users</Typography>
      </Grid>
      <Grid item xs={12}>
        <Table
          rows={rows}
          headers={titles.map((title) => ({
            name: title.toLowerCase(),
            title,
          }))}
          onDataReload={handleDataReload}
          loading={loading}
          error={error}
        />
      </Grid>
    </Grid>
  );
});
