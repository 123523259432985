import { Theme, alpha, darken, lighten } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

import { CellAlignment } from "./types";

export const isLightTheme = (type: string) => type === "light";

export const DEFAULT_CELL_ALIGN: CellAlignment = "left";

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "grid",
    overflow: "auto",
    gridTemplateRows: "min-content auto min-content",
  },
  tableWrapper: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  footerWrapper: {
    borderTop: `1px solid ${
      theme.palette.grey[isLightTheme(theme.palette.mode) ? 200 : 700]
    }`,
  },
  footerToolbar: {
    minHeight: "unset",
  },
  paginationClass: {
    display: "grid",
    gridTemplateColumns: "auto 50px",
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  infoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flexDirection: "column",
  },
  icon: {
    fontSize: "5rem",
    marginRight: theme.spacing(),
  },
}));

export const useHeaderStyles = makeStyles()((theme: Theme) => ({
  head: {
    "& tr": {
      backgroundColor: isLightTheme(theme.palette.mode)
        ? darken(theme.palette.grey[200], 0.05)
        : lighten(theme.palette.grey[900], 0.35),
      "& th": {
        backgroundColor: "inherit",
      },
    },
  },
  cell: {
    padding: theme.spacing(),
    borderBottom: `1px solid
      ${
        isLightTheme(theme.palette.mode)
          ? lighten(alpha(theme.palette.divider, 1), 0.93)
          : darken(alpha(theme.palette.divider, 1), 0.7)
      }`,
    borderTop: `1px solid
        ${
          isLightTheme(theme.palette.mode)
            ? lighten(alpha(theme.palette.divider, 1), 0.93)
            : darken(alpha(theme.palette.divider, 1), 0.7)
        }`,
    "&:not(:last-child)": {
      borderRight: `1px solid
          ${
            isLightTheme(theme.palette.mode)
              ? lighten(alpha(theme.palette.divider, 1), 0.93)
              : darken(alpha(theme.palette.divider, 1), 0.7)
          }`,
    },
  },
  title: {
    // the sort icon on the right is 24px wide, we need to compensate that
    paddingLeft: 24,
  },
}));

export const useBodyStyles = makeStyles()((theme: Theme) => ({
  cellClass: {
    padding: theme.spacing(),
    borderBottom: `1px solid
      ${
        isLightTheme(theme.palette.mode)
          ? lighten(alpha(theme.palette.divider, 1), 0.93)
          : darken(alpha(theme.palette.divider, 1), 0.7)
      }`,
    "&:not(:last-child)": {
      borderRight: `1px solid
          ${
            isLightTheme(theme.palette.mode)
              ? lighten(alpha(theme.palette.divider, 1), 0.93)
              : darken(alpha(theme.palette.divider, 1), 0.7)
          }`,
    },
    "&:first-of-type": {
      paddingLeft: theme.spacing(2),
    },
    "&:last-of-type": {
      paddingLeft: theme.spacing(2),
    },
    "& p": {
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      width: "100%",
    },
  },
  rowClass: {
    "&.selected": {
      backgroundColor: alpha(theme.palette.info.main, 0.1),
      "&:hover": {
        backgroundColor: alpha(theme.palette.info.main, 0.15),
      },
    },
    "&:nth-of-type(even):not(.selected)": {
      backgroundColor:
        theme.palette.grey[isLightTheme(theme.palette.mode) ? 100 : 700],
    },
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.grey[isLightTheme(theme.palette.mode) ? 100 : 700],
        0.7,
      ),
    },
    "&.canSelectRow": {
      cursor: "pointer",
    },
  },
}));
