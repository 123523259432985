import { IElement } from "core/types/element";
import { handleActions } from "core/utils/redux";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces";

import { Types } from "./actions";
import { ActionTypes, IState } from "./types";

export function buildReducer<
  T extends IElement<WithOptionalFieldDataSourceConfig>,
>({ config: { defaultValue } }: T, types: Types, initialState: any) {
  return handleActions<IState<T["config"]["defaultValue"]>>(
    { ...initialState, value: defaultValue ?? initialState.value },
    {
      [types.VALUE_CHANGE]: (state, action: ActionTypes["changeValue"]) => ({
        ...state,
        value: action.payload.value,
      }),
      [types.TOUCHED_CHANGE]: (
        state,
        action: ActionTypes["changeTouched"],
      ) => ({
        ...state,
        touched: action.payload.value,
      }),
    },
  );
}
