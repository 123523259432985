import { MouseEvent, memo, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardHeader,
  Chip,
  Popover,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEditorMode } from "core/context/EditorMode/useEditorMode";
import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import Button from "elementTypes/common/Button";

import { CurrentUserIntegrated } from "services/api";
import { RoutePaths } from "staticPages/routes";
import { useAppBarMenuTranslation } from "./translations";
import { generateComplementaryColors } from "./utils";

export const AppBarMenu = memo(() => {
  const { settings: settingsTitle, logout: logoutTitle } =
    useAppBarMenuTranslation();

  const { isEditorModeEnabled, toggleEditorMode } = useEditorMode();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const additionalInformation = useSelector(
    sessionSelectors.additionalInformation,
  );
  const theme = useTheme();
  const isAdmin = useSelector(sessionSelectors.isAdmin);
  const dispatch = useDispatch();
  const settingsHref = RoutePaths.Settings;

  const handleMenu = (e: MouseEvent<HTMLDivElement>) =>
    setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleLogoutClick = () => {
    if (isEditorModeEnabled) {
      toggleEditorMode(false);
    }
    dispatch(sessionActions.logout());
  };

  if (!additionalInformation) {
    return null;
  }

  let name = "";

  if (additionalInformation.type === "integrated") {
    name = additionalInformation.userName ?? "";
  } else {
    name = additionalInformation.name;
  }

  const email =
    "email" in additionalInformation ? additionalInformation?.email : "";

  const firstLetter = (name ?? email)?.[0] ?? "U";

  const ava = firstLetter.toUpperCase();
  const { bgColor, contrastTextColor } = generateComplementaryColors(
    theme.palette.primary.main,
    name ?? (additionalInformation as CurrentUserIntegrated)?.email ?? "",
  );

  return (
    <>
      <Avatar
        sx={{
          backgroundColor: bgColor,
          color: contrastTextColor,
          borderColor: contrastTextColor,
          border: "1px solid",
          cursor: "pointer",
          width: 34,
          height: 34,
        }}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        data-testid="user-menu"
        onClick={handleMenu}
      >
        {ava}
      </Avatar>
      <Popover
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted={true}
      >
        <Card variant="outlined">
          <CardHeader
            avatar={
              <Avatar
                sx={{ backgroundColor: bgColor, color: contrastTextColor }}
              >
                {ava}
              </Avatar>
            }
            title={
              <Box
                gap={1}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                {name}
                {isAdmin ? (
                  <Chip
                    label="ADMIN"
                    color="info"
                    variant="outlined"
                    size="small"
                  />
                ) : null}
              </Box>
            }
            subheader={email}
          />
          <CardActions sx={{ gap: 1 }}>
            <Button
              iconLeft="settings"
              onClick={handleClose}
              label={settingsTitle}
              href={settingsHref}
            />
            <Button
              iconRight="exit_to_app"
              onClick={handleLogoutClick}
              label={logoutTitle}
              data-testid="logout-button"
            />
          </CardActions>
        </Card>
      </Popover>
    </>
  );
});
