import { memo, useMemo, useState } from "react";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import Link from "elementTypes/common/Link";
import { IconNameType, MuiIcon } from "elementTypes/common/MuiIcon";
import { useAppEdition } from "queries/admin";

import useStyles from "../../styles";
import { itemTitle } from "./utils.tsx";

type MenuItem = {
  name: string;
  title: string;
  path: string;
  icon?: IconNameType;
  menu?: MenuItem[];
  isEnterprise?: boolean;
};

type Props = {
  menuOpen: boolean;
  menuItemSelected: string;
  nested?: boolean;
} & MenuItem;

export const MenuItem = memo<Props>(
  ({ menu, title, nested, path, icon, menuOpen, menuItemSelected }) => {
    const {
      classes: { nested: nestedClass, menuIcon, itemTextClass },
    } = useStyles();
    const [collapsed, toggleCollapse] = useState<boolean>(false);
    const hasMenu = !!menu?.length;
    const { data: isEnterprise } = useAppEdition();
    const nestedMenu = useMemo(
      () =>
        menu?.map((item) =>
          !isEnterprise && item?.isEnterprise ? null : (
            <MenuItem
              key={item.name}
              {...item}
              title={item.title}
              nested={true}
              menuOpen={menuOpen}
              menuItemSelected={menuItemSelected}
            />
          ),
        ),
      [menu, menuOpen, menuItemSelected, isEnterprise],
    );

    const handleListItemClick = () => {
      if (hasMenu) {
        toggleCollapse((prevCollapsed) => !prevCollapsed);
      }
    };

    const listIcon = useMemo(
      () =>
        icon ? (
          <ListItemIcon className={!menuOpen ? menuIcon : ""}>
            <MuiIcon icon={icon} fontSize="medium" />
          </ListItemIcon>
        ) : null,
      [menuOpen, menuIcon, icon],
    );
    const isSelected = menuItemSelected === path;
    const itemBtn = (
      <Link href={!hasMenu ? path : undefined} underline="none" color="inherit">
        <ListItem
          button={true}
          className={nested && menuOpen ? nestedClass : ""}
          onClick={handleListItemClick}
          selected={isSelected}
        >
          {listIcon}
          {menuOpen && itemTitle(title, itemTextClass)}
          {hasMenu && (
            <MuiIcon icon={collapsed ? "expand_less" : "expand_more"} />
          )}
        </ListItem>
      </Link>
    );

    return (
      <>
        {menuOpen ? (
          itemBtn
        ) : (
          <Tooltip title={title} placement="right">
            {itemBtn}
          </Tooltip>
        )}
        {hasMenu && (
          <Collapse in={collapsed} timeout="auto" unmountOnExit={true}>
            <List component="div" disablePadding={true}>
              {nestedMenu}
            </List>
          </Collapse>
        )}
      </>
    );
  },
);
