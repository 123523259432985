import { IReduxModuleFactoryArgs } from "core";
import { FormDataSource } from "elementInterfaces";

import { FileInput } from "../../types";

import { buildActions } from "./actions";
import { INITIAL_STATE, buildReducer } from "./reducer";
import { buildSaga } from "./saga";
import { buildSelectors } from "./selectors";

export function controlledReduxModuleFactory({
  path,
  element,
}: IReduxModuleFactoryArgs<FileInput>) {
  const { elementId, fieldPath } = element.config.dataSource!;

  const dataSource = FormDataSource.get(elementId);

  const { actions, types } = buildActions(
    path.join("."),
    fieldPath,
    dataSource,
  );
  const reducer = buildReducer(types, INITIAL_STATE);
  const selectors = buildSelectors(path, fieldPath, dataSource, element);
  const saga = buildSaga(actions, types, element, selectors);

  return {
    actions,
    selectors,
    saga,
    reducer,
  };
}

export type ReduxModule = ReturnType<typeof controlledReduxModuleFactory>;
