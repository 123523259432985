import { memo, useState } from "react";
import { FixedSizeList } from "react-window";
import { Section, useElementEditorContext } from "core/editor";
import { useTranslator } from "core/session/translation";
import { UntransformedBarChartConfig } from "elementTypes/echarts_bar_chart/types.ts";
import { UntransformedLineChartConfig } from "elementTypes/echarts_line_chart/types.ts";
import { useEchartsTranslation } from "../../../translation.ts";
import { LegendLabelsDialog } from "./LegendLabelsDialog.tsx";
import { LegendLabelsRow } from "./LegendLabelsRow.tsx";

const ITEM_SIZE = 48;

export const LegendLabels = memo(<
  UntransformedChartConfig extends UntransformedLineChartConfig &
    UntransformedBarChartConfig,
>() => {
  const [columnName, setColumnName] = useState<string | null>(null);

  const {
    elementModel: {
      i18n,
      config: { dataSource },
    },
    changeTranslation,
  } = useElementEditorContext<UntransformedChartConfig>();
  const { translate } = useTranslator();
  const { legendLabelsLabel } = useEchartsTranslation();

  const {
    columns: { valueColumnNames },
  } = dataSource;

  const translatedObject = translate(i18n);

  const handleCloseDialog = () => setColumnName(null);

  const handleRowClick = (nextColumnName: string) => () =>
    setColumnName(nextColumnName);

  return (
    <>
      <Section title={legendLabelsLabel}>
        <FixedSizeList
          itemSize={ITEM_SIZE}
          height={ITEM_SIZE * Math.min(valueColumnNames.length, 5)}
          itemCount={valueColumnNames.length}
          itemData={valueColumnNames}
          width="100%"
        >
          {(props) => (
            <LegendLabelsRow
              {...props}
              translatedObject={translatedObject}
              handleRowClick={handleRowClick}
            />
          )}
        </FixedSizeList>
      </Section>
      <LegendLabelsDialog
        i18n={i18n}
        columnName={columnName}
        changeTranslation={changeTranslation}
        handleCloseDialog={handleCloseDialog}
      />
    </>
  );
});
