import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Query",
      description: "Retrieve data",
    },
  },
  defaultSize: {
    width: 0,
    height: 0,
  },
};
