import { PropsFromConnector } from "core/types";
import { connectElement } from "core/utils/react-redux";

import Component from "./component";
import { ReduxModule } from "./reduxModule";
import { NumberField } from "./types";

const connector = connectElement<ReduxModule, NumberField>();

export type PropsFromRedux = PropsFromConnector<typeof connector>;

export default connector(Component);
