import { IRawElementType } from "core";

import Component from "./container";
import { TableEditor, defaultElement, editorMetadata } from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import {
  TableChildren,
  TableConfig,
  TableTranslationKeys,
  tableSelectors,
} from "./types";
import { getQueriesFromConfig } from "./utils";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_table",
  component: Component,
  configType: TableConfig,
  childrenType: TableChildren,
  selectorTypes: tableSelectors,
  editorComponent: TableEditor,
  editorMetadata,
  defaultElement,
  getQueriesFromConfig,
  translationKeys: TableTranslationKeys,
};

export default elementType;

export * from "./reduxModule/types";
export { useTableContext } from "./TableContext";
