import {
  JSONSchema6,
  JSONSchema6Definition,
  JSONSchema6TypeName,
} from "json-schema";
import { assocPath, pathOr } from "ramda";
import { GeneralTypes, IObjectViewField } from "core/types/app";

import { IObjectView } from "../../../../../core";

import { Field } from "./FieldRow";

type JSON6Extended = JSONSchema6Definition & {
  name: string;
};

export const convertValuesToData = (values: Record<string, unknown>) => {
  let data: Record<string, Field> = {};
  for (const key in values) {
    const path = key.split(".").map((k) => k.toString());
    const value = key.endsWith("name")
      ? (values[key] as string | number).toString()
      : values[key];
    data = assocPath(path, value, data);
  }
  return data;
};

const getType = (type: GeneralTypes): JSONSchema6TypeName => {
  switch (type) {
    case "text":
    case "dateTime":
    case "date":
    case "time":
      return "string";
    case "json":
      return "object";
    default:
      return type as JSONSchema6TypeName;
  }
};

function buildNullable(
  type: JSONSchema6TypeName,
  required: boolean,
): JSONSchema6TypeName | JSONSchema6TypeName[] {
  return required
    ? (type as JSONSchema6TypeName)
    : ([type, "null"] as JSONSchema6TypeName[]);
}

const getDateFormat = (
  type: string,
): Record<"format", string> | Record<string, unknown> => {
  switch (type) {
    case "dateTime":
    case "date":
    case "time":
      return { format: "date-time" };
    // case "time":
    //   return { format: "time"}; // use when time input will be fixed
    default:
      return {};
  }
};

const typeExists = (type?: JSONSchema6TypeName | JSONSchema6TypeName[]) =>
  type && !!(type as string | string[]).length;

const getSchemaProperties = (
  currentProperties: JSONSchema6,
  defaultProperties?: JSONSchema6,
): JSONSchema6["properties"] =>
  Object.entries(currentProperties as Record<string, JSONSchema6>).reduce(
    (res, [key, value]) => {
      const defaultPropsKey = (defaultProperties?.[key as keyof JSONSchema6] ??
        {}) as JSONSchema6;
      return {
        ...res,
        [key]: {
          ...(value as JSONSchema6),
          ...(!typeExists(value.type) && {
            ...defaultPropsKey,
          }),
        },
        ...(value.items &&
          typeof value.items === "object" && {
            items: {
              ...value.items,
              ...(!typeExists((value.items as JSONSchema6).type) && {
                ...(defaultPropsKey?.items as JSONSchema6),
              }),
            },
          }),
        ...(value.properties &&
          !!Object.keys(value.properties).length && {
            properties: getSchemaProperties(
              value.properties,
              defaultPropsKey.properties,
            ),
          }),
      };
    },
    {},
  );

const getMissingTypes = (
  viewSchema: JSONSchema6,
  defaultSchema: JSONSchema6,
) => {
  let nextSchema = {
    ...viewSchema,
  };

  if (viewSchema.properties) {
    nextSchema = {
      ...nextSchema,
      properties: getSchemaProperties(
        viewSchema.properties,
        defaultSchema.properties,
      ),
    };
  }
  return nextSchema;
};

function getDefaultSchema(
  currentViewFields?: IObjectViewField[],
  viewIdentifyingFieldName?: string,
): JSONSchema6 {
  let required: string[] = [];
  const properties = currentViewFields
    ? currentViewFields.reduce((result, field) => {
        const isRequired = field.nullable === false;

        if (isRequired && field.name !== viewIdentifyingFieldName) {
          required = [...required, field.name];
        }

        return {
          ...result,
          [field.name]: {
            type: field.generalType.isArray
              ? buildNullable("array", isRequired)
              : buildNullable(getType(field.generalType.type), isRequired),
            ...(field.generalType.isArray && {
              items: {
                type: getType(field.generalType.type) as JSONSchema6TypeName,
              },
            }),
            ...getDateFormat(field.generalType.type),
          },
        };
      }, {})
    : {};

  return {
    type: "object",
    required,
    properties,
  } as JSONSchema6;
}

export const getFormSchemas = (view?: IObjectView | null) => {
  if (!view) {
    return {
      defaultSchema: undefined,
      viewSchema: undefined,
    };
  }
  const { fields: currentViewFields, identifyingField: viewIdentifyingField } =
    view;

  const defaultSchema = getDefaultSchema(
    currentViewFields,
    viewIdentifyingField?.name,
  );

  const viewSchema = !view?.jsonSchema
    ? null
    : getMissingTypes(view?.jsonSchema, defaultSchema);

  return {
    defaultSchema,
    viewSchema,
  };
};

const emptyToUndefined = (value: unknown) =>
  typeof value === "string" && !(value as string).trim().length
    ? undefined
    : value;

export const omitProps = [
  "name",
  "isRequired",
  "canBeChanged",
  "isIdentifyingField",
];

const shouldBeRemoved = (name: string) => omitProps.includes(name);

export const transformProps = ({
  isRequired,
  ...properties
}: {
  [k: string]: JSONSchema6Definition;
}): { [k: string]: JSONSchema6Definition } => {
  const typeValue = properties["type"];

  const val =
    typeof typeValue === "object"
      ? (typeValue as string[])[0]
      : String(typeValue);
  const type =
    isRequired || isRequired === undefined
      ? val
      : buildNullable(val as JSONSchema6TypeName, false);

  return Object.entries(properties).reduce(
    (res, [key, value]) =>
      shouldBeRemoved(key)
        ? res
        : {
            ...res,
            ...(key === "items" && typeof value === "object"
              ? {
                  [key]: transformProps(
                    value as { [k: string]: JSONSchema6Definition },
                  ),
                }
              : { [key]: emptyToUndefined(value) }),
            ...(key === "type" && {
              type,
            }),
          },
    {},
  );
};

export const fieldSchemaToFormData = (
  field: Field,
  { properties, required }: JSONSchema6,
): Field => {
  const schemaField = properties?.[field.name];
  const fieldProps = field?.properties as Record<string, Field>;

  return {
    ...field,
    isRequired: required?.includes(field.name) ?? false,
    ...(fieldProps &&
      schemaField && {
        properties: Object.entries(fieldProps).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: fieldSchemaToFormData(
              { ...(value ?? {}), name: key },
              schemaField as JSONSchema6,
            ),
          }),
          {},
        ),
      }),
  };
};

export const validateName = (
  values: Record<string, unknown>,
  name: string,
  path: string[],
  value: string,
) => {
  if (!value.trim()) {
    return false;
  }
  const data = convertValuesToData(values);
  const properties = pathOr({}, path, data) as Record<string, JSON6Extended>;

  const nonUnique = Object.keys(properties).filter(
    (key) => key !== name && properties[key]?.["name"] === value,
  );

  return !nonUnique.length;
};
