import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()({
  root: {
    "& > .MuiInput-root": {
      display: "flex",
      flexWrap: "wrap",
      padding: "0 !important",
    },
  },
});
