import { memo, useState } from "react";
import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
} from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";

import { AdvancedComponent, TranslationComponent } from "./components";

export const FileInputEditor = memo<{ isArray?: boolean }>(({ isArray }) => {
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);

  const changeLanguage = (l: Language) => setLang(l);

  return (
    <>
      <FormInputDataSourceEditorComponent
        language={lang}
        allowedDataTypeIsArray={isArray}
        allowedDataTypes={["text"]}
      />
      <AdvancedComponent />
      <TranslationComponent
        language={lang}
        handleChangeLanguage={changeLanguage}
      />
      <FormInputConfigEditorComponent />
    </>
  );
});
