import { memo } from "react";
import { useDatabasePanelContext } from "../context/databasePanelPermission/DatabasePanelContext.utils.ts";

import { QueriesPanelProvider } from "../context/queriesPanel/index.tsx";
import { DataModel } from "../erd/types";
import { EntitiesPanel } from "./EntitiesPanel";
import { PanelSkeleton } from "./PanelSkeleton";
import { ProceduresPanel } from "./ProceduresPanel/index.tsx";
import { QueriesPanel } from "./QueriesPanel/component.tsx";
import { DatabasePanelType, QueryObject } from "./types";

type Props = {
  loading: {
    model: boolean;
    queries?: boolean;
  };
  entities: DataModel;
  showActions?: boolean;
  isInternal?: boolean;
  queries?: QueryObject[];
};

export const DatabaseListPanel = memo<Props>(
  ({ loading, entities, showActions, isInternal, queries }) => {
    const { type } = useDatabasePanelContext();

    const entitiesPanel = loading.model ? (
      <PanelSkeleton />
    ) : (
      <EntitiesPanel entities={entities} showActions={showActions} />
    );

    // !!!: when the internal DB is shown, we can't create queries, use this section to create a new one to interact with CYPEX DB
    const queriesPanel = isInternal ? (
      <span />
    ) : (
      <QueriesPanelProvider
        queries={queries ?? []}
        isLoading={loading.queries}
        isInternal={isInternal}
      >
        <QueriesPanel />
      </QueriesPanelProvider>
    );

    const proceduresPanel = isInternal ? <span /> : <ProceduresPanel />;

    const content = {
      [DatabasePanelType.entities]: entitiesPanel,
      [DatabasePanelType.queries]: queriesPanel,
      [DatabasePanelType.procedures]: proceduresPanel,
    };

    return content[type];
  },
);
