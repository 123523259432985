import { memo } from "react";
import { ToggleButtonGroup } from "@mui/material";
import { ToggleButton } from "elementTypes/common/ToggleButton";

import { MuiIcon } from "../../../../../elementTypes/common/MuiIcon";
import { LayoutOption, LayoutOptionKey, LayoutOptions } from "../component";
import { useDatabaseTranslation } from "../translation";

type IProps = {
  value: LayoutOption;
  onChange: (event: any, value: LayoutOption) => void;
};

export const ToggleLayoutPanel = memo<IProps>(({ value, onChange }) => {
  const translation = useDatabaseTranslation();

  const layoutOptions = Object.entries(LayoutOptions).map(([key, option]) => (
    <ToggleButton
      key={option}
      value={option}
      tooltip={translation[`${key}Tooltip`]}
      size="small"
    >
      <MuiIcon icon={`border_${key as LayoutOptionKey}`} fontSize="large" />
    </ToggleButton>
  ));

  return (
    <ToggleButtonGroup value={value} onChange={onChange} exclusive size="small">
      {layoutOptions}
    </ToggleButtonGroup>
  );
});
