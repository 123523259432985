import { memo, useState } from "react";
import { Box, Slider, Typography } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { FILE_STORAGE_PREFIX } from "services/api/constants.ts";
import {
  defaultValues,
  maxValues,
  minValues,
  properties,
  propertyMarks,
  stepValues,
} from "../../consts.ts";
import { useTranslation } from "../../translation.ts";
import { TLogoEditor, TProperties } from "../../types.ts";

export const LogoEditor = memo<TLogoEditor>(({ fileName, innerRef }) => {
  const translation = useTranslation();

  const [adjustments, setAdjustments] =
    useState<Record<TProperties, number>>(defaultValues);

  const handleChangeProperty =
    (key: TProperties) => (_: unknown, value: number | number[]) =>
      setAdjustments((prevState) => ({
        ...prevState,
        [key]: Number(value),
      }));

  const editor = (
    <Box display="flex" flexDirection="column" gap={1} width="100%">
      {properties.map((property) => (
        <Box display="flex" flexDirection="column" gap={0.5} key={property}>
          <Typography gutterBottom>
            {translation[`${property}Label`]}
          </Typography>
          <Slider
            min={minValues[property]}
            max={maxValues[property]}
            step={stepValues[property]}
            marks={propertyMarks[property]}
            valueLabelDisplay="auto"
            value={adjustments[property]}
            defaultValue={defaultValues[property]}
            onChange={handleChangeProperty(property)}
          />
        </Box>
      ))}
    </Box>
  );

  const image =
    typeof fileName === "string" && !!fileName?.length
      ? `${FILE_STORAGE_PREFIX}${fileName}`
      : fileName;

  return (
    <Box
      display="flex"
      gap={3}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={2}
        width="100%"
      >
        <AvatarEditor
          image={image ?? ""}
          ref={innerRef}
          scale={adjustments["scale"]}
          height={144}
          width={717.26}
          rotate={adjustments["rotate"]}
          borderRadius={adjustments["borderRadius"]}
          crossOrigin="anonymous"
        />
        <Typography variant="subtitle2">
          {translation.logoEditorHelperLabel}
        </Typography>
      </Box>
      {editor}
    </Box>
  );
});
