import { PropsFromConnector } from "core";
import { actions as routerActions } from "core/router/reduxModule";
import { connectElement } from "core/utils/react-redux";

import Component from "./component";
import { ReduxModule } from "./reduxModule/types";
import { Form } from "./types";

/**
 * TODO:
 * We cannot use the automatic `connectElement` because we're re-mapping the state shape to avoid collisions.
 * Two ways to solve this: add an arbitrary mapping function to `connectElement` (that must be properly typed)
 * or deeply merge the selector and action objects (which might make typing selectors and action values from
 * reduxModules very tricky)
 */

const mapDispatchToProps = {
  goBack: routerActions.goBack,
};

const connector = connectElement<
  ReduxModule,
  Form,
  undefined,
  typeof mapDispatchToProps
>(undefined, mapDispatchToProps);

export type Props = PropsFromConnector<typeof connector>;

export default connector(Component);
