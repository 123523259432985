const ELEMENT_ID = "loader";

export default {
  show: () => {
    const loader = document.getElementById(ELEMENT_ID);
    if (loader) {
      loader.style.visibility = "visible";
    }
  },
  hide: () => {
    const loader = document.getElementById(ELEMENT_ID);
    if (loader) {
      loader.style.visibility = "hidden";
    }
  },
};
