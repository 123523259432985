import { memo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { EChartsOption } from "echarts";
import DefaultDataDisplay from "elementTypes/default_data_display/DataDisplay";

import { Logo } from "layouts/common/Logo/Logo";
import { RoutePaths } from "staticPages/routes";
import Echarts from "../../../../elementTypes/common/Echarts/Echarts";
import { QueryKeys, useApps, useUsers } from "../../../../queries/admin";
import { IApp, IUser } from "../../../types";
import WorkflowDashboard from "./SetupWizard";

export const AdminPageComponent = memo(() => {
  const queryClient = useQueryClient();
  const users = useUsers();
  const apps = useApps();
  const usersList = (users.data ?? []) as IUser[];
  const appList = (apps.data ?? []) as IApp[];

  const totalUsers = usersList.length;
  const totalApps = appList.length;
  const activeUsers = usersList.filter((user) => user.isActive).length;
  const load = (key: string) => () =>
    queryClient.invalidateQueries({ queryKey: [key] });

  const option: EChartsOption = {
    tooltip: { trigger: "item", formatter: "{b}: {c} ({d}%)" },
    legend: { show: true },
    series: [
      {
        avoidLabelOverlap: true,
        label: { show: true },
        type: "pie",
        radius: ["55%", "75%"],
        data: [
          {
            name: "Active Users",
            value: activeUsers,
          },
          {
            name: "Deactivated Users",
            value: totalUsers - activeUsers,
          },
        ],
      },
    ],
  };

  return (
    <>
      <Grid container spacing={2} paddingX={2}>
        <Grid
          item
          xs={12}
          sm={6}
          justifyContent={"center"}
          alignContent={"center"}
        >
          <Typography
            variant="h3"
            component="h1"
            sx={{ fontWeight: "bold", mb: 1 }}
          >
            Build Applications
          </Typography>

          <Box display={"flex"} alignItems={"center"} gap={2}>
            <Typography variant="h3" component="h1" sx={{ fontWeight: "bold" }}>
              with
            </Typography>
            <Logo textOnly maxWidth={"230px"} />
          </Box>

          <Typography
            variant="subtitle1"
            sx={{ mt: 3, color: "text.primary", fontWeight: "bold" }}
          >
            Unlock the Power of Low-Code Development for PostgreSQL
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{ mt: 2, lineHeight: 1.6, color: "text.secondary" }}
          >
            Here’s the deal: follow a few clear, straightforward steps —
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ lineHeight: 1.6, color: "text.secondary" }}
          >
            define roles, craft your queries, and boom,
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ lineHeight: 1.6, color: "text.secondary" }}
          >
            you’re launching your first app
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <WorkflowDashboard />
        </Grid>
      </Grid>
      <Grid container spacing={2} paddingX={2} paddingY={4}>
        <Grid item xs={4}>
          <Box display="grid" gap={2}>
            <DefaultDataDisplay
              label="Total Apps"
              icon="app_shortcut"
              barPosition="bottom"
              formattedData={totalApps}
              error={null}
              loading={apps.isFetching || apps.isInitialLoading}
              load={load(QueryKeys.fetchApps)}
              color="primary"
              href={RoutePaths.Apps}
            />
            <DefaultDataDisplay
              label="Total Users"
              icon="people_alt"
              barPosition="bottom"
              formattedData={totalUsers}
              error={null}
              loading={users.isInitialLoading || users.isFetching}
              load={load(QueryKeys.fetchUsers)}
              color="info"
              href={RoutePaths.Users}
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Echarts
            option={option}
            elementId="app-usage"
            chartType="pie"
            legend={{ orient: "vertical", placement: "end" }}
          />
        </Grid>
      </Grid>
    </>
  );
});
