import { memo } from "react";
import { Checkbox } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { ListChildComponentProps } from "react-window";

import { Translation } from "core";
import { useSessionContext } from "core/session";
import { getTranslatedTexts } from "core/utils/element-utils";
import IconButton from "elementTypes/common/IconButton";
import { useTableEditorTranslation } from "elementTypes/default_table/translation";
import { useStyles as useTableStyles } from "../styles";
import { TABLE_BODY_DELETE_BUTTON } from "./ActionsEditor";
import { useActionsContext } from "./utils";

type Props = {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
} & ListChildComponentProps;

export const ActionItem = memo<Props>(
  ({ data: { items }, index, provided, snapshot, style }) => {
    const { language } = useSessionContext();
    const { selectActionTooltip, removeselectActionTooltip } =
      useTableEditorTranslation();
    const {
      deleteChecked,
      handleDelete,
      onActionSelect,
      onSwitchDeleteAction,
    } = useActionsContext();

    const element = items[index];
    const {
      classes: { draggingItem, actionItemsText },
      cx,
    } = useTableStyles();
    const label = getTranslatedTexts(
      language,
      element.i18n as Translation<"label">,
    ).label;

    const { isDragging } = snapshot;

    return (
      <ListItem
        key={element.id}
        divider
        className={cx(
          { [draggingItem]: isDragging },
          "editor-table-section-columns-list-item",
        )}
        selected={isDragging}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          ...style,
          ...provided.draggableProps.style,
        }}
      >
        <ListItemText
          classes={{ primary: actionItemsText }}
          primary={label || element.name}
        />
        <ListItemIcon>
          {element.name === TABLE_BODY_DELETE_BUTTON ? (
            <Checkbox
              edge="end"
              onChange={onSwitchDeleteAction}
              checked={deleteChecked}
            />
          ) : (
            <>
              <IconButton
                icon="touch_app"
                className="editor-table-column-select"
                onClick={() => onActionSelect(index)}
                tooltip={selectActionTooltip}
                edge="end"
              />{" "}
              <IconButton
                icon="delete_outline"
                className="editor-table-column-delete"
                onClick={() => handleDelete(element.id)}
                disabled={false}
                tooltip={removeselectActionTooltip}
                edge="end"
              />
            </>
          )}
        </ListItemIcon>
      </ListItem>
    );
  },
);
