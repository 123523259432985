const iconMap = {
  "3d_rotation": "ThreeDRotation",
  "4k": "FourK",
  "360": "ThreeSixty",
  lock_open: "LockOpen",
  cached: "Cached",
  preview: "Preview",
  app_shortcut: "AppShortcut",
  more_vert: "MoreVert",
  help_outline: "HelpOutline",
} as const;

export default iconMap;
