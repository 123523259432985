import { memo, useCallback } from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { ColorSelector, Section, useElementEditorContext } from "core/editor";
import { PlacementSelector } from "core/editor/common/PlacementSelector";
import { IColor } from "core/editor/types";

import { BoolInputConfig } from "../../types";
import { useBoolInputEditorTranslation } from "../translation";

const colors = ["primary", "secondary", "success", "error", "warning", "info"];
// TODO: check types

export const StylingComponent = memo(() => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<BoolInputConfig>();
  const { placement, isSwitch, isButton, fullWidth } = config;
  const translation = useBoolInputEditorTranslation();

  const handlePlacementChange = useCallback(
    (newValue: BoolInputConfig["placement"]) =>
      changeConfigValue("placement", newValue),
    [changeConfigValue],
  );
  const handleIsSwitchChange = useCallback(
    (prop: "isSwitch" | "isButton" | "fullWidth") =>
      (_: any, newValue: BoolInputConfig["isSwitch" | "isButton"]) => {
        changeConfigValue(prop, newValue);
        if (prop === "isButton") {
          changeConfigValue("color", "standard");
        }
      },
    [changeConfigValue],
  );

  const handleColorChange = (nextValue: string) => {
    changeConfigValue("color", nextValue);
  };

  return (
    <Section title={translation.stylingTitle}>
      {!isButton && (
        <PlacementSelector value={placement} onChange={handlePlacementChange} />
      )}
      <Box px={2} pb={1} gap={1}>
        <FormControlLabel
          disabled={isButton}
          control={
            <Switch
              checked={Boolean(isSwitch)}
              onChange={handleIsSwitchChange("isSwitch")}
            />
          }
          label={translation.isSwitchLabel}
        />
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(isButton)}
              onChange={handleIsSwitchChange("isButton")}
            />
          }
          label={translation.isButtonLabel}
        />
        {isButton && (
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(fullWidth)}
                onChange={handleIsSwitchChange("fullWidth")}
              />
            }
            // label={translation.isButtonLabel}
            label={"Full Width"}
          />
        )}
      </Box>
      <Box px={2} pb={1}>
        <ColorSelector
          config={config}
          onChange={handleColorChange}
          colors={[isButton ? "standard" : "default", ...colors] as IColor[]}
          label="Color"
          defaultValue={(isButton ? "standard" : "default") as IColor}
        />
      </Box>
    </Section>
  );
});
