import { Palette, PaletteColor, Theme } from "@mui/material";
import colorString from "color-string";
import * as L from "leaflet";

import {
  Color,
  Colors,
  ContrastColor,
  ContrastColors,
} from "elementTypes/common/StyledTypography/utils";
import { DEFAULT_CONFIG } from "./constants";

export type Feature = GeoJSON.Feature;

type Selected = {
  property?: string;
  propertyValue?: string | number;
  pathColor?: L.PathOptions;
  markerColor?: string;
  markerLabel?: string;
};

type MarkerOptions = {
  getMarkerDisplayText?: (feature: Feature) => string;
  getMarkerBackgroundColor?: (
    feature: Feature,
  ) => string | ((feature: Feature) => string);
  classes: Record<string, string>;
  cx: Function;
  variant?: "icon" | "text";
  selected?: Selected;
};

type ColorConfig = {
  backgroundColor: string;
  contrastColor: string;
};

function escapeHtml(unsafe: string | null | undefined) {
  return unsafe
    ? unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;")
    : "";
}

export function createCustomMarker(
  feature: Feature,
  latlng: L.LatLng,
  theme: Theme,
  options: MarkerOptions,
): L.Marker {
  const markerLabel: string = getMarkerLabelSafe(
    feature,
    options.getMarkerDisplayText,
  );
  const backgroundColorConfig: string = getMarkerBackgroundColorSafe(
    feature,
    options.getMarkerBackgroundColor,
  );

  const { backgroundColor, contrastColor }: ColorConfig = determineMarkerColors(
    backgroundColorConfig,
    theme,
  );

  let bgColor = backgroundColor;
  let fillColor = contrastColor;

  let label =
    options.variant && options.variant === "text"
      ? feature?.properties?.[markerLabel] ?? markerLabel
      : markerLabel;

  const isSelected =
    options.selected?.property &&
    feature?.properties?.[options.selected?.property] ===
      options.selected?.propertyValue;

  if (isSelected) {
    const selectedElement: ColorConfig = determineMarkerColors(
      options?.selected?.markerColor ?? "",
      theme,
    );
    bgColor = selectedElement.backgroundColor;
    fillColor = selectedElement.contrastColor;

    label =
      options.variant &&
      options.variant === "text" &&
      options?.selected?.property
        ? feature?.properties?.[options.selected?.property] ??
          options.selected?.markerLabel
        : options.selected?.markerLabel;
  }

  return L.marker(latlng, {
    icon: L.divIcon({
      className: "",
      iconSize: [40, 56],
      iconAnchor: [20, 56],
      tooltipAnchor: [20, -36],
      popupAnchor: [0, -56],
      html: `<div class='${options.cx(options.classes.icon, { [options.classes.empty]: !markerLabel })}' style='--bg-color:${bgColor};--color:${fillColor}'><div>${options?.variant === "icon" ? label : escapeHtml(label) ?? ""}</div></div>`,
    }),
  });
}

function getMarkerLabelSafe(
  feature: Feature,
  getMarkerDisplayText?: (feature: Feature) => string,
): string {
  try {
    return typeof getMarkerDisplayText === "function"
      ? getMarkerDisplayText?.(feature)
      : getMarkerDisplayText ?? "";
  } catch {
    return "";
  }
}

function getMarkerBackgroundColorSafe(
  feature: Feature,
  getMarkerBackgroundColor?: (
    feature: Feature,
  ) => string | ((feature: Feature) => string),
): string {
  try {
    return typeof getMarkerBackgroundColor === "function"
      ? (getMarkerBackgroundColor(feature) as string)
      : getMarkerBackgroundColor || "";
  } catch {
    return "";
  }
}

export function determineMarkerColors(
  backgroundColorConfig: string,
  theme: Theme,
) {
  // default colors
  let backgroundColor = "white";
  let contrastColor = "black";

  // if the color is supported by us (material-ui) directly, use it
  if (
    Colors[backgroundColorConfig as Color] &&
    ContrastColors[backgroundColorConfig as ContrastColor]
  ) {
    const backgroundColorPath = Colors[backgroundColorConfig as Color].split(
      ".",
    ) as [keyof Palette, keyof PaletteColor];

    backgroundColor = (theme.palette[backgroundColorPath[0]] as PaletteColor)
      .main;

    const contrastColorPath = ContrastColors[
      backgroundColorConfig as ContrastColor
    ].split(".") as [keyof Palette, keyof PaletteColor];

    const contrastColorPalette = theme.palette[
      contrastColorPath[0]
    ] as PaletteColor;

    contrastColor = contrastColorPalette[contrastColorPath[1]];
  } else if (backgroundColorConfig) {
    // if the color is not supported, see if it's a valid CSS color
    const parsedColor = colorString.get.rgb(backgroundColorConfig);
    if (parsedColor !== null) {
      backgroundColor = backgroundColorConfig;
      // material-uis getContrastText function does not support color names (e.g. "red")
      // thus we have to convert the color first
      contrastColor = theme.palette.getContrastText(
        colorString.to.hex(parsedColor),
      );
    }
  }

  return { backgroundColor, contrastColor };
}

export function applyStyle(
  feature: Feature | undefined,
  thema: Theme,
  getStyle?: (feature: Feature) => L.PathOptions,
  selected?: Selected,
) {
  try {
    if (feature) {
      if (typeof getStyle === "function") {
        return getStyle?.(feature);
      } else {
        const nextColor =
          getStyle && "color" in getStyle
            ? (getStyle as Record<"color", string>)?.color
            : DEFAULT_CONFIG.styleFunction.color;
        const colorObj = determineMarkerColors(nextColor, thema);
        const selectedColor = determineMarkerColors(
          selected?.pathColor?.color ?? DEFAULT_CONFIG.styleFunction.color,
          thema,
        );
        return {
          color:
            selected?.property &&
            feature.properties?.[selected?.property] === selected?.propertyValue
              ? selectedColor.backgroundColor
              : colorObj.backgroundColor,
        };
      }
    } else {
      return {};
    }
  } catch {
    return {};
  }
}

export function handleEachFeature(
  feature: Feature,
  layer: L.Layer,
  getTooltip?: (feature: Feature) => string | string,
): void {
  try {
    let tooltip: string | undefined;
    if (getTooltip && typeof getTooltip === "function") {
      tooltip = getTooltip(feature);
    } else {
      tooltip = getTooltip
        ? feature?.properties?.[getTooltip]
          ? String(feature?.properties?.[getTooltip])
          : String(getTooltip)
        : undefined;
    }
    if (tooltip !== undefined) {
      layer.bindTooltip(tooltip);
    }
  } catch {}
}

export function adjustMapView(
  map: L.Map,
  geoJsonLayer: L.GeoJSON,
  initializedDataRef: React.MutableRefObject<boolean>,
  updateMapView: boolean,
): void {
  if (!initializedDataRef.current || updateMapView) {
    const bounds: L.LatLngBounds = geoJsonLayer.getBounds();
    if (bounds.isValid()) {
      map.fitBounds(bounds);
      initializedDataRef.current = true;
    }
  }
}

export function cleanupOnUnmount(map: L.Map): () => void {
  return (): void => {
    map.eachLayer((layer: L.Layer) => {
      if (!(layer as any)._container) {
        map.removeLayer(layer);
      }
    });
  };
}
