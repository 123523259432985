import { memo } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { FixedSizeList } from "react-window";

import { ActionItem } from "./ActionItem";
import { ActionItemRow } from "./ActionItemRow";
import { useActionsContext } from "./utils";

const columnItemSize = 48;

export const Actions = memo(() => {
  const { actionItems, onActionsRefresh } = useActionsContext();

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination || source.index === destination.index) {
      return;
    }
    const element = actionItems[source.index];
    actionItems.splice(source.index, 1);
    actionItems.splice(destination.index, 0, element);
    onActionsRefresh();
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        mode="virtual"
        renderClone={(provided, snapshot, { source: { index } }) => {
          const element = actionItems[index];

          return (
            <ActionItem
              {...element}
              data={{ items: actionItems }}
              provided={provided}
              snapshot={snapshot}
              index={index}
              style={{}}
            />
          );
        }}
      >
        {(provided) => (
          <FixedSizeList
            outerRef={provided.innerRef}
            height={columnItemSize * Math.min(actionItems.length, 5)}
            itemCount={actionItems.length}
            itemSize={columnItemSize}
            width="100%"
          >
            {ActionItemRow}
          </FixedSizeList>
        )}
      </Droppable>
    </DragDropContext>
  );
});
