import { memo } from "react";
import { CircularProgress as MUICircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import { IElementComponentProps } from "core";

import { useStyles } from "./style";
import { CircularProgress } from "./types";
import { DEFAULTS } from "./utils";

type Props = IElementComponentProps<{}, CircularProgress> & {
  value: number | string;
};

const CircularProgressComponent = memo<Props>(
  ({
    value,
    element: {
      config: {
        size = DEFAULTS.size,
        color = DEFAULTS.color,
        thickness = DEFAULTS.thickness,
        variant = DEFAULTS.variant,
      },
      i18n: { label },
    },
  }) => {
    const {
      classes: { root, progressCircle, backgroundCircle, labelClass },
    } = useStyles({
      size,
    });
    const progressValue =
      typeof value === "string" ? parseInt(value, 10) : value;
    const commonProps = {
      size,
      color,
      thickness,
      variant: "determinate" as const,
    };

    return (
      <div className={root}>
        <MUICircularProgress
          value={progressValue}
          className={progressCircle}
          {...commonProps}
        />
        <MUICircularProgress
          className={backgroundCircle}
          value={100}
          {...commonProps}
        />
        <Typography className={labelClass} variant={variant}>
          {label || value}
        </Typography>
      </div>
    );
  },
);

export default CircularProgressComponent;
