import { HTMLAttributes } from "react";
import { ListItemText, Tooltip } from "@mui/material";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import {
  AutocompleteOption,
  IAutocompleteValue,
} from "elementTypes/common/Autocomplete/types";
import { useGetFilesByGroupType } from "queries/admin/fileData";
import { getFileOptions } from "queries/admin/utils";

import { useEditorStorageImageTranslation } from "../translation";

const customRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: AutocompleteOption,
) => (
  <li {...props} key={String(option.value)}>
    <Tooltip title={option["title"]}>
      <ListItemText primary={option.label} />
    </Tooltip>
  </li>
);

export default function FileSelect({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: IAutocompleteValue, reason?: string) => void;
}) {
  const files = useGetFilesByGroupType(
    { groupType: "image" },
    { select: getFileOptions },
  );

  const translation = useEditorStorageImageTranslation();

  return (
    <Autocomplete
      options={files.data}
      onChange={onChange}
      value={value}
      name="file-select"
      label={translation.chooseFile}
      isLoading={files.isFetching}
      virtualizedList={true}
      defaultItemSize={48}
      customRenderOption={customRenderOption}
    />
  );
}
