import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  header: {
    margin: 0,
    padding: theme.spacing(0.5, 1),
  },
  action: {
    margin: 0,
  },
  content: {
    paddingTop: 0,
  },
  specificGap: {
    "&:last-child": {
      margin: 0,
    },
    position: "relative",
  },
  card: {
    borderLeft: "unset",
    borderRight: "unset",
  },
}));
