import { createUseTranslation } from "core/session/translation";

export const commonProceduresTranslation = {
  en: {
    panelTitle: "Functions",
    createSuccessSnack: "New Procedure is created:",
    deleteSuccessSnack: "Procedure @procedure has been deleted.",
    updateSuccessSnack: "Procedure @procedure has been updated.",
    deleteModalTitle: "Delete Procedure",
    viewModalTitle: "Function Overview",
    createModalTitle: "Create New",
    refetchTooltip: "Refetch User-defined Functions",
    createTooltip: "Create New",
    searchInputPlaceholder: "Search Procedure",
    closeLabel: "Close",
    deleteModalContent: "Are You sure you want to delete:",
    nameLabel: "Name",
    createdByLabel: "Created By",
    argumentsLabel: "Arguments",
    returnTypeLabel: "Return Type",
    startErrorHint: "Function must start with:",
    endErrorHint: "Missed semicolon at the end; ",
    manualErrorMsg: "Invalid SQL Code",
    alertTitle:
      "Functions can perform any operations allowed by PostgreSQL (read data, modify data, raise errors, and even DDL operations).",
    alertContent:
      "Every procedure stored in the <b>cypex_generated</b> schema and accessible by the active database role.",
    alertBtn: "Stored Functions Documentation",
  },
};

export const useProceduresTranslation = createUseTranslation(
  commonProceduresTranslation,
);
