import { useCallback, useEffect, useRef } from "react";

export function useIsMounted(): () => boolean {
  const ref = useRef(false);

  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);

  const isMounted = useCallback(() => ref.current, []);

  return isMounted;
}
