import * as R from "ramda";
import { v4 as uuid } from "uuid";

import {
  DEFAULT_LANGUAGE_CODE,
  Language,
  Translation,
} from "../../../../types";

import { ItemDetails } from "./components";

export type IData = Record<string, any>;

type TranslationType = "label" | "icon";

export const reorderList = (
  list: ItemDetails[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result.map((item: ItemDetails, index: number) => ({
    ...item,
    order: index + 1,
  }));
};

export const orderMenu = (menu: ItemDetails[]): ItemDetails[] =>
  menu
    .sort((a, b) => a.order - b.order)
    .map((item, index) => ({
      ...item,
      order: index,
      ...(!!item.menu.length && {
        menu: orderMenu(item.menu),
      }),
    }));

export const createI18n = (
  language: Language,
  label?: string,
): Translation<"label" | "icon"> => ({
  [DEFAULT_LANGUAGE_CODE]: {
    label: label ?? "",
    icon: "",
  },
  [language.code]: {
    label: label ?? "",
    icon: "",
  },
});

export const createNewEntry = (
  order: number,
  language: Language,
  label?: string,
): ItemDetails => ({
  id: uuid().split("-")[0],
  i18n: createI18n(language, label),
  order,
  pageId: "",
  menu: [],
  externalLink: null,
});

export const exposeItemValue = (
  menuItem: ItemDetails,
  data: IData,
  language: Language,
  orderIndex?: number,
): ItemDetails => {
  if (!(data && !!Object.keys(data).length)) {
    return menuItem;
  }
  const nextItem: ItemDetails = R.clone(menuItem ?? {});

  const getTranslation = (param: TranslationType): string =>
    data?.[param] ?? nextItem?.i18n?.[language.code]?.[param] ?? "";

  const result = {
    ...nextItem,
    ...(orderIndex && {
      order: orderIndex + 1,
    }),
    i18n: {
      ...(nextItem?.i18n ?? {}),
      [language.code]: {
        ...nextItem?.i18n?.[language.code],
        ...(!data.pageId && {
          label: getTranslation("label"),
        }),
        icon: getTranslation("icon"),
      },
    } as Translation<TranslationType>,
    ...(nextItem.menu?.length
      ? {
          pageId: "",
          menu: nextItem.menu.reduce((res, subMenuItem, index) => {
            const subDataItem = data.menu.find(
              (m: IData) => m.id === subMenuItem.id,
            );
            return subDataItem
              ? [
                  ...res,
                  exposeItemValue(subMenuItem, subDataItem, language, index),
                ]
              : res;
          }, [] as ItemDetails[]),
          submenuType: data.submenuType ?? nextItem.submenuType,
        }
      : {
          menu: [],
          pageId: data.pageId,
          submenuType: undefined,
          externalLink: data.externalLink,
        }),
  };

  return result;
};

export const getPathFromNestedName = (nestedName: string) =>
  nestedName.split(".").reduce(
    (res, p) => {
      const val = p && Number.isInteger(Number(p)) ? Number(p) : p;
      return val === "" ? res : [...res, val];
    },
    [] as (string | number)[],
  );
