import * as t from "io-ts";
import { customExpression } from "core";

// docs: https://postgrest.org/en/v12/references/api/resource_representation.html#response-format
export const ContentType = t.keyof({
  json: null,
  geo: null,
  text: null,
  xml: null,
  bytea: null,
});

export type BuiltinContentType = t.TypeOf<typeof ContentType>;

export const ContentTypeConfig = t.union([
  ContentType,
  customExpression(t.union([ContentType, t.null])),
]);
