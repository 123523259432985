import { ReactNode, memo } from "react";
import { Tooltip } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import omit from "ramda/es/omit";

import { NON_BREAKING_SPACE } from "../utils";
import {
  Color,
  Colors,
  ContrastColor,
  ContrastColors,
  adaptColor,
} from "./utils";

export type IStyledTypography = {
  text: string | number | null | ReactNode;
  color?: Color | null;
  typographyProps?: TypographyProps;
  boxProps?: BoxProps;
  fitContent?: boolean;
  tooltip?: string;
  id?: string;
};

export const StyledTypography = memo<IStyledTypography>(
  ({ color, text, fitContent, boxProps, typographyProps, tooltip, id }) => {
    const background = boxProps?.bgcolor ?? ("" as Color);
    const borderColor = boxProps?.borderColor as Color;

    const blockProps = {
      ...(!fitContent && {
        width: "100%",
        height: "100%",
      }),
      ...(borderColor && {
        borderColor: adaptColor(Colors[borderColor as Color]),
      }),
    };

    const typographyText = (
      <Typography
        component={background || borderColor ? "span" : "p"}
        {...typographyProps}
        id={id}
        color="inherit"
      >
        {text ?? NON_BREAKING_SPACE}
      </Typography>
    );

    const boxColor =
      background && (!color || color === "default")
        ? adaptColor(
            ContrastColors[background as ContrastColor as ContrastColor],
          )
        : borderColor
          ? adaptColor(
              Colors[(color ?? (borderColor as Color) ?? "default") as Color],
            )
          : color
            ? adaptColor(Colors[color as Color])
            : "inherit";

    const nextBoxProps =
      omit(["bgcolor", "borderColor"], (boxProps ?? {}) as BoxProps) ?? {};

    const component = (
      <Box
        {...nextBoxProps}
        sx={{
          display: fitContent ? "inline-flex" : "flex",
          ...blockProps,
          color: boxColor,
          bgcolor:
            background !== "" && background
              ? adaptColor(Colors[background.toString() as Color])
              : "transparent",
        }}
      >
        {tooltip ? (
          <Tooltip title={tooltip}>{typographyText}</Tooltip>
        ) : (
          typographyText
        )}
      </Box>
    );
    return component;
  },
);
