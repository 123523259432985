import { ChangeEvent, ComponentProps, memo, useCallback, useMemo } from "react";
import { FormControlLabel, Switch, TextField } from "@mui/material";
import { Section, useElementEditorContext } from "core/editor";
import {
  DEFAULT_CONFIG,
  MODES,
} from "elementTypes/default_geojson_input/constants";
import { capitalizeFirstLetter } from "utils/string.ts";

import { GeoJSONInputConfig, GetModesFromConfig } from "../../types";
import { useGeoJSONInputEditorTranslation } from "../translation";

export const Modes = memo(() => {
  const {
    modeTitle,
    defaultGeoJsonValueTitle,
    defaultGeoJsonValue,
    coordinatesTitle,
    zoom,
    latitude,
    longitude,
    tileLayerUrlLabel,
    tileLayerUrlDescription,
    maximumFeaturesLabel,
    maximumFeaturesDescription,
    ...translation
  } = useGeoJSONInputEditorTranslation();

  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<GeoJSONInputConfig>();

  const handleSwitchValueChange = useCallback(
    (
      key: keyof GeoJSONInputConfig,
    ): ComponentProps<typeof Switch>["onChange"] =>
      (_event, checked) => {
        changeConfigValue(key, checked);
      },
    [changeConfigValue],
  );

  const handleTileLayerUrlChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue("tileLayerUrl", e.target.value || null),
    [changeConfigValue],
  );

  const handleDefaultGeoJSONChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue(
        "geoJSONdefaultValue",
        e.target.value?.trim() ? e.target.value : undefined,
      ),
    [changeConfigValue],
  );

  const handleLatitudeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue("latitude", parseFloat(e.target.value) || 0),
    [changeConfigValue],
  );

  const handleLongitudeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue("longitude", parseFloat(e.target.value) || 0),
    [changeConfigValue],
  );

  const handleZoomChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue("zoom", parseInt(e.target.value, 10) || 0),
    [changeConfigValue],
  );

  const handleMaximumFeatureChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue(
        "maximumFeatures",
        e.target.value ? parseInt(e.target.value, 10) : null,
      ),
    [changeConfigValue],
  );

  const modesConfig = useMemo(
    () =>
      MODES.map((mode) => {
        const configKey = `allow${capitalizeFirstLetter(
          mode,
        )}` as GetModesFromConfig<typeof MODES>;
        const checked = config[configKey] ?? DEFAULT_CONFIG[configKey];

        return (
          <FormControlLabel
            key={`${mode}-config-editor`}
            control={
              <Switch
                checked={checked}
                onChange={handleSwitchValueChange(configKey)}
              />
            }
            label={translation[`${mode}Label`]}
          />
        );
      }),
    [config, handleSwitchValueChange, translation],
  );

  return (
    <div>
      <Section title={defaultGeoJsonValueTitle} wrapped={true}>
        <TextField
          fullWidth
          value={
            config.geoJSONdefaultValue ?? DEFAULT_CONFIG.geoJSONdefaultValue
          }
          onChange={handleDefaultGeoJSONChange}
          label={defaultGeoJsonValue}
          multiline
          maxRows={5}
        />
      </Section>
      <Section title={coordinatesTitle} wrapped={true} gap={1}>
        <TextField
          fullWidth
          value={config.latitude ?? DEFAULT_CONFIG.latitude}
          onChange={handleLatitudeChange}
          label={latitude}
          type="number"
        />
        <TextField
          fullWidth
          value={config.longitude ?? DEFAULT_CONFIG.longitude}
          onChange={handleLongitudeChange}
          label={longitude}
          type="number"
        />
        <TextField
          fullWidth
          value={config.zoom ?? DEFAULT_CONFIG.zoom}
          onChange={handleZoomChange}
          label={zoom}
          type="number"
        />
      </Section>
      <Section title={modeTitle} wrapped={true} gap={1}>
        <TextField
          fullWidth
          value={config.tileLayerUrl ?? ""}
          onChange={handleTileLayerUrlChange}
          label={tileLayerUrlLabel}
          helperText={tileLayerUrlDescription}
        />
        <TextField
          fullWidth
          value={config.maximumFeatures}
          type="number"
          onChange={handleMaximumFeatureChange}
          label={maximumFeaturesLabel}
          helperText={maximumFeaturesDescription}
        />
        {modesConfig}
      </Section>
    </div>
  );
});
