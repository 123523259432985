import { createColorSelector } from "core";
import { selectorScoper } from "core/utils/redux";

import { CallButton } from "../types";

import { IState } from "./types";

export function buildSelectors(path: string[], element: CallButton) {
  const scopeSelector = selectorScoper<IState>(path);

  const loading = (state: any) => scopeSelector(state).loading;
  const result = (state: any) => scopeSelector(state).result;
  const error = (state: any) => scopeSelector(state).error;
  const icon = (state: any) => element.config.icon?.(state);
  const color = createColorSelector(element.config.color);

  return {
    loading,
    result,
    error,
    icon,
    color,
  };
}
