import { memo, useCallback, useMemo } from "react";
import {
  Box,
  FormControl,
  Button as MuiButton,
  Typography,
} from "@mui/material";
import debounce from "lodash/debounce";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
} from "react-hook-form";
import { FUNCTIONS_URL } from "core/postgREST";
import { AlertBox } from "elementTypes/common/AlertBox";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";
import { ControlledCodeMirror } from "../../ControlledCodeMirror";
import { PermissionAutocomplete } from "../../PermissionAutocomplete";
import { useProceduresTranslation } from "../translation";
import { placeholder, placeholderStart, regexPattern } from "../utils";

export type TFormController = {
  field: ControllerRenderProps<FieldValues, "sourceCode">;
  fieldState: ControllerFieldState;
};

export const Form = memo(() => {
  const t = useProceduresTranslation();
  const { control, clearErrors, setError } = useHookFormContext();

  const validationRules = {
    required: true,
    pattern: regexPattern,
  };

  const handleValidateQuery = useCallback(
    (newCode: string) => {
      if (!newCode) {
        return;
      }
      const code = newCode.trim();

      const arr = [
        "CREATE OR REPLACE FUNCTION cypex_generated.",
        "CREATE FUNCTION cypex_generated.",
      ];
      const isValidStart = arr.some((pattern) =>
        code.toLocaleLowerCase().startsWith(pattern.toLocaleLowerCase()),
      );
      const isValidEnd = code.toLocaleLowerCase().endsWith(";");

      if (!isValidStart || !isValidEnd) {
        const errorLine: string = isValidStart
          ? String(code.split("\n").length)
          : "1";

        const manualError = {
          type: "pattern",
          types: {
            errorLine,
            errorHint: `${!isValidEnd ? t.endErrorHint : ""}${
              !isValidStart ? `${t.startErrorHint} ${placeholderStart}` : ""
            }`,
          },
          message: t.manualErrorMsg,
        };
        setError("sourceCode", manualError);
      } else {
        clearErrors("sourceCode");
      }
    },
    [clearErrors, setError, t.endErrorHint, t.manualErrorMsg, t.startErrorHint],
  );

  const debouncedValidation = useMemo(
    () => debounce(handleValidateQuery, 512),
    [],
  );

  return (
    <Box gap={1} display={"flex"} flexDirection={"column"}>
      <AlertBox
        alertTitle={t.alertTitle}
        message={
          <Box gap={1} display={"flex"} flexDirection={"column"}>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: t.alertContent }}
            />
            <MuiButton
              href={FUNCTIONS_URL}
              target="_blank"
              color="inherit"
              size="small"
            >
              {t.alertBtn}
            </MuiButton>
          </Box>
        }
        color="info"
      />
      <FormControl fullWidth>
        <Controller
          render={(inputProps) => {
            return (
              <ControlledCodeMirror
                {...(inputProps as any)}
                isLoading={false}
                debouncedValidation={debouncedValidation}
                clearError={clearErrors}
              />
            );
          }}
          name="sourceCode"
          control={control}
          defaultValue={placeholder}
          rules={validationRules}
        />
      </FormControl>
      <PermissionAutocomplete acl={[]} />
    </Box>
  );
});
