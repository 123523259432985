import { memo } from "react";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SecurityIcon from "@mui/icons-material/Security";
import {
  CircularProgress,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useERDTranslation } from "../../../erd/translation.ts";
import { NodeData } from "../../../erd/types.ts";
import { ContextMenuProps } from "../../types.ts";
import { DialogType } from "../consts.ts";

const ContextMenu = memo<ContextMenuProps>(
  ({
    anchorEl,
    nodeData,
    workflowConfigured,
    isFetchingModel,
    onClose,
    onOpenDialog,
    onOpenWorkflow,
  }) => {
    const t = useERDTranslation();

    const stateColumn = (nodeData?.columns ?? []).find(
      (column) =>
        nodeData &&
        "stateColumn" in nodeData &&
        column.name === nodeData?.stateColumn,
    );

    const isBrokenState =
      nodeData &&
      "stateColumn" in nodeData &&
      nodeData?.stateColumn &&
      !stateColumn;

    return (
      <Menu
        id="table-node-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <MenuItem
          onClick={onOpenDialog(DialogType.generateQuery)}
          disabled={
            (nodeData as NodeData)?.autogenerated || Boolean(isFetchingModel)
          }
        >
          <ListItemText primary={t.menuGenerateQuery} />
          {isFetchingModel ? (
            <CircularProgress sx={{ ml: 1 }} size={20} color="inherit" />
          ) : null}
        </MenuItem>
        {!(nodeData as NodeData)?.fdw &&
          (workflowConfigured ? (
            <MenuItem onClick={onOpenWorkflow}>
              <ListItemText primary={t.menuWorkflowViewer} />
              <Tooltip title={t.workflowTooltip}>
                <GroupWorkIcon
                  fontSize="small"
                  color={
                    (nodeData as NodeData).workflowActivated
                      ? "primary"
                      : "disabled"
                  }
                />
              </Tooltip>
            </MenuItem>
          ) : (
            <MenuItem onClick={onOpenDialog(DialogType.workflow)}>
              <ListItemText primary={t.menuWorkflowSetup} />
              {isBrokenState && (
                <Tooltip
                  title={t.workflowBrokenTooltip.replace(
                    "@columnName",
                    (nodeData as NodeData).stateColumn,
                  )}
                >
                  <GroupWorkIcon fontSize="small" color={"error"} />
                </Tooltip>
              )}
            </MenuItem>
          ))}
        <MenuItem onClick={onOpenDialog(DialogType.defineLookups)}>
          <ListItemText primary={t.menuKeyLookup} />
          {(nodeData as NodeData)?.lookupLabelColumn && (
            <Tooltip title={t.defaultLookupConfiguredTooltip}>
              <RemoveRedEyeIcon fontSize="small" />
            </Tooltip>
          )}
        </MenuItem>
        {!(nodeData as NodeData)?.fdw && (
          <MenuItem onClick={onOpenDialog(DialogType.auditTable)}>
            <ListItemText primary={t.auditTableTitle} />

            {(nodeData as NodeData)?.historyTrackingConfigured && (
              <Tooltip title={t.historyTrackingConfiguredTitle}>
                <SecurityIcon fontSize="small" />
              </Tooltip>
            )}
          </MenuItem>
        )}
        <MenuItem onClick={onOpenDialog(DialogType.tableDetails)}>
          {t.menuTableDetails}
        </MenuItem>
      </Menu>
    );
  },
);

export default ContextMenu;
