import { selectorScoper } from "core/utils/redux";
import { getDisabledValueFromConfig } from "elementTypes/common/utils.ts";
import { StaticSelect } from "../../types.ts";

import { IState } from "./types";

export function buildSelectors(path: string[], element: StaticSelect) {
  const scopeSelector = selectorScoper<IState>(path);

  const value = (state: any) => scopeSelector(state).value;

  const disabled = (state?: any) =>
    getDisabledValueFromConfig(state, element.config);

  return {
    value,
    disabled,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
