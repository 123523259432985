import { createUseTranslation } from "core/session/translation";

const workflowTranslations = {
  en: {
    workflowLandingPageTitle: "Configure how your data flows",
    workflowTitle: "Workflow",
    workflowDescTitle: "Information about Workflow",
    exit: "Exit",
    newState: "New State",
    editStateTooltip: "Edit State",
    emptyStateList: "No State found",
    stateListTitle: "States",
    landingPageParagraph1:
      "Workflow is a series of activities - state changes (also called transitions) - that are necessary to complete a process in its entirety.",
    landingPageParagraph2:
      "Each step (state change) in a workflow has a specific step before it and a specific step after it, with the exception of the first and last steps.",
    landingPageParagraph3:
      "Drag from one state to another to create a transition.",
    landingPageParagraph4:
      "In a linear workflow, an outside event usually initiates the first step. A workflow describes valid actions as defined by a business process.",
    deleteWorkflowTitle: "Delete workflow",
    deleteWorkflowConfirmation:
      "This action will remove all the configuration related to the workflow, and it can not be undone. Are you sure?",
    deleteWorklow: "Delete current workflow",
    deleteButton: "Delete",
    workflowDeleted: "The workflow was removed successfully",
    cancelButton: "Cancel",
  },
  es: {
    workflowTitle: "Workflow",
    exit: "Salir",
    newState: "Nuevo estado",
    deleteWorkflowTitle: "Borrar workflow",
    deleteWorkflowConfirmation:
      "Esta acción removerá toda la configuración relacionada con el workflow actual, y no puede deshacerse. Está seguro?",
    deleteWorklow: "Borrar workflow actual",
    deleteButton: "Borrar",
    workflowDeleted: "The workflow se ha borrado satisfactoriamente",
  },
};

export const useWorkflowTranslations =
  createUseTranslation(workflowTranslations);
