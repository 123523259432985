import { memo } from "react";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ConnectedReduxModuleProps } from "core";

import { ReduxModule } from "./reduxModule";
import { useStyles } from "./styles";
import { ArrayTextInput } from "./types";

export const DefaultArrayTextInput = memo<
  ConnectedReduxModuleProps<ReduxModule, ArrayTextInput>
>(
  ({
    value,
    element: {
      i18n: { label },
      config: { nullable },
    },
    changeValue,
    changeTouched,
    disabled,
    errors,
    required,
  }) => {
    const { classes, cx } = useStyles();
    const handleInputChange = (_: any, newData: string[] | null) =>
      changeValue(newData);

    const handleChangeTouched = () => changeTouched(true);

    // TODO smart detection of pasted / current value (detect / select separator)
    return (
      <Autocomplete
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label={label}
            error={Boolean(errors)}
            helperText={errors ? errors : undefined}
            required={required}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              className: cx(params.InputProps?.className, classes.input),
            }}
          />
        )}
        disableClearable={nullable === false}
        onBlur={handleChangeTouched}
        onChange={handleInputChange}
        value={value}
        disabled={disabled}
        multiple
        freeSolo
        options={[]}
      />
    );
  },
);

export default DefaultArrayTextInput;
