import * as t from "io-ts";
import { ColorConfig, IElement, UntransformedConfig } from "core/types";
import { customExpression } from "core/types/customExpression";
import { ActionConfigsType } from "elementTypes/common/ActionConfig/types";

export const ActionButtonConfig = t.intersection([
  t.type({
    onTrigger: ActionConfigsType,
  }),
  t.partial({
    icon: customExpression(t.string),
    color: ColorConfig,
    variant: t.keyof({
      outlined: null,
      contained: null,
    }),
    disabled: customExpression(t.boolean),
  }),
]);

export type ActionButtonConfig = t.TypeOf<typeof ActionButtonConfig>;

export const ActionButtonTranslationKeys = ["label"] as const;

export type ActionButtonTranslationKeys =
  (typeof ActionButtonTranslationKeys)[number];

export type UntransformedActionButtonConfig =
  UntransformedConfig<ActionButtonConfig>;

export type ActionButton = IElement<
  ActionButtonConfig,
  {},
  ActionButtonTranslationKeys
>;
