import { memo } from "react";
import { TableFooter as MuiTableFooter } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "elementTypes/common/IconButton";

type Props = {
  colSpan: number;
  onClick: () => void;
  loading?: boolean;
};

export const TableFooter = memo<Props>(({ colSpan, onClick, loading }) => (
  <MuiTableFooter>
    <TableRow>
      <TableCell align="right" scope="row" colSpan={colSpan}>
        <IconButton
          onClick={onClick}
          color="secondary"
          icon="cached"
          size="small"
          disabled={loading}
          processing={loading}
        />
      </TableCell>
    </TableRow>
  </MuiTableFooter>
));
