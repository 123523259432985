import { IReduxModuleFactoryArgs } from "core";

import { NumberInput } from "../types";

import { controlledReduxModuleFactory } from "./controlledReduxModule";
import { uncontrolledReduxModuleFactory } from "./uncontrolledReduxModule";

export function reduxModuleFactory(args: IReduxModuleFactoryArgs<NumberInput>) {
  const { element } = args;

  return element.config.dataSource
    ? controlledReduxModuleFactory(element)
    : uncontrolledReduxModuleFactory(args);
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
