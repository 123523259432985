import { FC, memo } from "react";
import Icon from "@mui/icons-material/ZoomOutMap";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { Handle, NodeProps, Position } from "reactflow";

import { useStyles } from "./style";
import { useStateViewerTranslations } from "./translation";

const EndNode: FC<NodeProps<any>> = ({ targetPosition = Position.Bottom }) => {
  const { classes } = useStyles();
  const { endStateHint } = useStateViewerTranslations();
  return (
    <Tooltip title={endStateHint}>
      <Box
        p={0.5}
        display="flex"
        alignItems="center"
        border="2px solid"
        borderColor="grey.600"
        justifyContent="center"
        fontWeight={600}
        color="white"
        borderRadius="8px"
        bgcolor="background.paper"
      >
        <Chip
          className={`${classes.node} ${classes.staticNode}`}
          variant="filled"
          icon={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className="custom-drag-handle"
              width="40px"
              height="40px"
            >
              <Icon fontSize="small" sx={{ color: "white" }} />
            </Box>
          }
          label={
            <>
              <Handle
                type="source"
                position={targetPosition}
                className={classes.handleClass}
                isValidConnection={(connection) =>
                  connection.target !== connection.source &&
                  !(
                    connection.target === "start" || connection.source === "end"
                  )
                }
              />
              <Typography>END</Typography>
            </>
          }
        />
      </Box>
    </Tooltip>
  );
};

const MemoizedEndNode = memo(EndNode);

export default MemoizedEndNode;
