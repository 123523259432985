import { ChangeEvent, FormEvent, memo, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  TextField,
  Typography,
} from "@mui/material";

import { DialogWrapper } from "elementTypes/helpers/HOC/DialogWrapper/component.tsx";
import { LoadingComponent } from "layouts/common/Loading.tsx";
import {
  useCreateFunction,
  useDeleteFunction,
  useFunctions,
  useUpdateFunction,
} from "queries/admin/commonData.ts";
import { GeneratedFunction } from "queries/admin/types.ts";
import { getApiError } from "queries/utils.ts";
import { useDebouncedState } from "utils/hooks/useDebouncedState.ts";
import { useSnackbar } from "utils/hooks/useSnackbar.ts";
import IconButton from "../../../../../../elementTypes/common/IconButton";
import { useStyles } from "../styles.ts";
import { DialogContent } from "./components/DialogContent.tsx";
import { List } from "./components/List.tsx";
import { useProceduresTranslation } from "./translation.ts";
import { searchData } from "./utils.ts";

type Action = "delete" | "create" | "view";

export type Modal = {
  type: Action;
  item?: GeneratedFunction;
};

export const ProceduresPanel = memo(() => {
  const t = useProceduresTranslation();
  const { classes, cx } = useStyles();
  const { data, isFetching, refetch } = useFunctions();

  const [modal, setModal] = useState<Modal | null>(null);
  const handleClose = () => setModal(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [cachedSearchValue, cachedHandleSearch] = useDebouncedState(
    searchQuery,
    setSearchQuery,
    { delay: 200 },
  );
  const handleSearchChange = (ev: ChangeEvent<HTMLInputElement>) =>
    cachedHandleSearch(ev.target.value);

  const showSnackbar = useSnackbar();

  const handleSuccess = (msg: string, color?: "warning" | "info") => {
    showSnackbar(msg, color ?? "success");
    refetch();
    handleClose();
  };

  const createFn = useCreateFunction({
    onSuccess: (res) =>
      handleSuccess(
        `${t.createSuccessSnack} ${(res as Record<"name", string>)?.name}`,
      ),
    onError: (err) => showSnackbar(getApiError(err), "error"),
  });
  const deleteFn = useDeleteFunction({
    onSuccess: (res) =>
      handleSuccess(
        `${t.deleteSuccessSnack.replace("@procedure", res.deleted)}`,
        "info",
      ),
    onError: (err) => showSnackbar(getApiError(err), "error"),
  });
  const updateFn = useUpdateFunction({
    onSuccess: (res) =>
      handleSuccess(`${t.updateSuccessSnack.replace("@procedure", res.id)}`),
    onError: (err) => showSnackbar(getApiError(err), "error"),
  });

  const filteredData = useMemo(
    () =>
      cachedSearchValue.trim()
        ? searchData(data, cachedSearchValue.trim())
        : data,
    [cachedSearchValue, data],
  );

  const handleItemClick =
    (type: Action, item: GeneratedFunction) => (ev: any) => {
      ev.preventDefault();
      ev.stopPropagation();
      setModal({
        type,
        item,
      });
    };

  const handleNewClick = () => {
    setModal({
      type: "create",
    });
  };

  const handleSubmit = (
    formData?: Record<string, unknown> | FormEvent<HTMLDivElement>,
  ) => {
    if (modal?.type === "delete" && modal?.item?.id) {
      deleteFn.mutate({
        id: modal?.item?.id,
      });
    } else {
      const nexData = { ...formData } as {
        id: string;
        sourceCode: string;
        acl?: string[];
      };

      modal?.type === "create"
        ? createFn.mutate(nexData)
        : updateFn.mutate({
            id: modal?.item?.id ?? "",
            acl: nexData.acl,
          });
    }
  };

  const title = {
    delete: t.deleteModalTitle,
    view: t.viewModalTitle,
    create: t.createModalTitle,
  };

  return (
    <>
      <Card
        variant="outlined"
        className={cx(classes.cardHeight, classes.card, classes.scrollableCard)}
      >
        <CardHeader
          disableTypography
          avatar={
            <Box display="flex">
              <IconButton
                icon={"manage_history"}
                edge="start"
                tooltip={t.refetchTooltip}
                onClick={() => refetch()}
              />
            </Box>
          }
          title={<Typography variant="h6">{t.panelTitle}</Typography>}
          classes={{
            root: classes.cardHeader,
            action: classes.queryHeaderAction,
            avatar: classes.queryHeaderAvatar,
          }}
          action={
            <IconButton
              icon={"add"}
              tooltip={t.createTooltip}
              onClick={handleNewClick}
            />
          }
        />
        <Box p={1} borderTop="1px solid" borderColor="divider">
          <TextField
            InputProps={{ endAdornment: <SearchIcon /> }}
            fullWidth
            size="small"
            variant="standard"
            placeholder={t.searchInputPlaceholder}
            value={cachedSearchValue}
            onChange={handleSearchChange}
          />
        </Box>
        <Divider />
        {isFetching ? (
          <LoadingComponent />
        ) : (
          <List data={filteredData} onClick={handleItemClick} />
        )}
      </Card>
      <DialogWrapper
        open={!!modal?.type}
        handleClose={handleClose}
        title={title[modal?.type ?? "create"]}
        keepMounted={false}
        fullWidth={true}
        maxWidth={modal?.type === "delete" ? "sm" : "md"}
        isForm={true}
        disableBackdropClick={modal?.type === "create"}
        handleSubmit={handleSubmit}
        submitTitle={modal?.type !== "delete" ? "Save" : "Delete"}
        submitButtonProps={{
          color: modal?.type !== "delete" ? "primary" : "error",
        }}
        cancelTitle={t.closeLabel}
      >
        {modal ? <DialogContent data={modal} /> : <span />}
      </DialogWrapper>
    </>
  );
});
