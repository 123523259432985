import { memo, useEffect } from "react";
import { Stack } from "@mui/material";

import {
  Section,
  TranslationEditor,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { Language, Translation } from "core/types";
import { getTranslatedText } from "core/utils/element-utils";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";

import { BoolInputConfig, BoolInputTranslationKeys } from "../../types";
import { useBoolInputEditorTranslation } from "../translation";

type TranslationComponentProps = {
  language: Language;
  handleChangeLanguage: (l: Language) => void;
};

export const TranslationComponent = memo<TranslationComponentProps>(
  ({ language, handleChangeLanguage }) => {
    const { elementModel, changeTranslation } =
      useElementEditorContext<BoolInputConfig>();
    const editorTranslation = useEditorTranslation();
    const translation = useBoolInputEditorTranslation();
    const i18n = elementModel.i18n as Translation<BoolInputTranslationKeys>;
    const { code } = language;

    const {
      config: { isSwitch, isButton },
    } = elementModel;

    const getLabelValue = (labelKey: BoolInputTranslationKeys) =>
      getTranslatedText<BoolInputTranslationKeys>(language, i18n, labelKey) ??
      "";

    useEffect(() => {
      const labelLeft = getLabelValue("labelLeft");
      const labelRight = getLabelValue("labelRight");
      if (!isSwitch && (!!labelLeft.length || !!labelRight.length)) {
        changeTranslation({
          ...i18n,
          [code]: {
            ...i18n[code],
            labelLeft: "",
            labelRight: "",
          },
        });
      }
    }, [isSwitch]);

    return (
      <Section
        title={editorTranslation.translationTitle}
        wrapped={true}
        cardActions={
          <LanguageSwitch
            language={language}
            changeLanguage={handleChangeLanguage}
            colorVariant="dark"
            fullWidth={true}
          />
        }
      >
        <Stack spacing={2}>
          <TranslationEditor
            translationKey="label"
            i18n={i18n}
            changeTranslation={changeTranslation}
            label={translation.labelLabel}
            language={language}
          />
          {isSwitch && !isButton && (
            <>
              <TranslationEditor
                translationKey="labelLeft"
                i18n={i18n}
                changeTranslation={changeTranslation}
                label={translation.labelLeftLabel}
                language={language}
              />
              <TranslationEditor
                translationKey="labelRight"
                i18n={i18n}
                changeTranslation={changeTranslation}
                label={translation.labelRightLabel}
                language={language}
              />
            </>
          )}
        </Stack>
      </Section>
    );
  },
);
