import {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import SearchIcon from "@mui/icons-material/Search";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import { TextField, Typography } from "@mui/material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import classNames from "classnames";
import IconButton from "elementTypes/common/IconButton";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import { useSearchQuery } from "utils/hooks";
import { capitalizeFirstLetter } from "utils/string";

import { useElementTypesContext } from "../../../context/ElementTypesContext";
import { useTranslation } from "../../../session";
import { useTranslator } from "../../../session/translation";
import { IElementType } from "../../../types";
import { useEditorContext } from "../../EditorContext";
import { useEditorTranslation } from "../../translation";
import { useStyles } from "../styles";

type ItemProps = {
  element: IElementType<any>;
  handleDragStart: (text: string) => void;
  handleDrop: () => void;
};

function instanceOfElementType(object: any): object is IElementType {
  return "component" in object && typeof object === "object";
}

const ElementItem = memo<ItemProps>(
  ({ element: { name, editorMetadata }, handleDragStart, handleDrop }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const {
      classes: { expand, expandOpen, itemClass },
    } = useStyles();
    const { showMoreTooltip } = useEditorTranslation();
    const { description, title } = useTranslation(editorMetadata?.i18n);
    const handleExpandClick = () =>
      setExpanded((prevExpanded: boolean) => !prevExpanded);

    const onDragStart = (e: DragEvent<HTMLElement>) => {
      e.dataTransfer.setData("text/plain", name);
      handleDragStart(name);
    };

    const onDrop = (e: DragEvent<HTMLElement>) => {
      handleDrop();
      e.preventDefault();
    };

    return (
      <Box
        marginY={0.5}
        draggable={true}
        unselectable="on"
        onDragStart={onDragStart}
        onDragEnd={onDrop}
        minHeight={38}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        className="add-element"
        data-element-type-name={name}
      >
        <Box height="100%">
          <CardHeader
            className={itemClass}
            subheader={title ?? name}
            action={
              <>
                {description && (
                  <IconButton
                    icon="expand_more"
                    onClick={handleExpandClick}
                    tooltip={showMoreTooltip}
                    className={classNames(expand, {
                      [expandOpen]: expanded,
                    })}
                  />
                )}
              </>
            }
          />
        </Box>
        {description && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <>
              <CardContent>
                <Typography>{description}</Typography>
              </CardContent>
              <Divider />
            </>
          </Collapse>
        )}
      </Box>
    );
  },
);

export const ElementCreator = memo(() => {
  const [filterValue, setFilterValue] = useState<"group" | "alphabetically">(
    "group",
  );

  const { availableElementTypes, elementTypes: allElementTypes } =
    useElementTypesContext();

  const {
    searchInputLabel,
    titleTooltipGroup,
    titleTooltipAlphabetically,
    noItemsLabel,
  } = useEditorTranslation();
  const { setDraggableElement } = useEditorContext();

  const { translate } = useTranslator();

  const {
    classes: { flexCenter, inputClass },
  } = useStyles();

  const handleDragStart = useCallback(
    (newValue: string) => {
      const elementType = allElementTypes[newValue];
      const defaultSize = elementType?.editorMetadata?.defaultSize;
      const minSize = elementType?.editorMetadata?.minSize;
      const { width, height } = defaultSize ??
        minSize ?? { width: 1, height: 1 };
      setDraggableElement({
        i: newValue as string,
        w: width,
        h: height,
      });
    },
    [setDraggableElement, allElementTypes],
  );

  const handleDrop = useCallback(() => {
    setDraggableElement(null);
  }, [setDraggableElement]);

  const searchData = useMemo(
    () =>
      Object.entries(availableElementTypes).map(([name, type]) => {
        const i18n = type.editorMetadata?.i18n;
        const translated = i18n
          ? translate(i18n)
          : { title: "", description: "" };
        return {
          name,
          ...translated,
        };
      }),
    [availableElementTypes, translate],
  );

  const { filteredResult, searchValue, setSearchValue } = useSearchQuery(
    searchData,
    [
      { name: "title", weight: 0.7 },
      { name: "description", weight: 0.2 },
    ],
  );

  const noResults = useMemo(
    () => (
      <Card square variant="outlined">
        <CardContent>{`${noItemsLabel} ${searchValue}`}</CardContent>
      </Card>
    ),
    [noItemsLabel, searchValue],
  );

  const filteredItems = searchValue?.trim()
    ? filteredResult.map((result) => [
        result.item.name,
        availableElementTypes[result.item.name],
        result.score,
      ])
    : Object.entries(availableElementTypes).map(([name, type]) => [
        name,
        type,
        0,
      ]);

  const getItemsAlphabetically = useCallback(
    () => (
      <Box mt={0.5} className="elements-alphabetically">
        {filteredItems.length ? (
          <Card square variant="outlined">
            {filteredItems.map(([elemName, elem], index) => (
              <ElementItem
                key={(elemName as string) ?? index}
                element={elem as IElementType<any>}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
              />
            ))}
          </Card>
        ) : (
          noResults
        )}
      </Box>
    ),
    [filteredItems, noResults, handleDragStart, handleDrop],
  );

  const getItemsByGroup = useCallback(() => {
    // Group by group name
    const groupedItems = filteredItems.reduce(
      (acc: Record<string, IElementType[]>, curr) => {
        const currentElement = curr[1] as IElementType;

        const group =
          "group" in currentElement
            ? String(currentElement?.["group"])
            : "various";

        acc[group] = acc[group] || [];
        if (instanceOfElementType(currentElement)) {
          acc[group].push(currentElement);
        }

        return acc;
      },
      {},
    );

    // Sort groups alphabetically
    const sortedGroups = Object.keys(groupedItems).sort((a, b) =>
      a.localeCompare(b),
    );

    // Render grouped items
    return sortedGroups.map((groupName) => (
      <Box mt={0.5} key={groupName} className="element-group">
        <Card square variant="outlined">
          <CardHeader
            title={capitalizeFirstLetter(groupName)}
            titleTypographyProps={{ align: "center" }}
          />
          {groupedItems[groupName].map((elem) => (
            <ElementItem
              key={elem.name}
              element={elem}
              handleDragStart={handleDragStart}
              handleDrop={handleDrop}
            />
          ))}
        </Card>
      </Box>
    ));
  }, [filteredItems, handleDragStart, handleDrop]);

  const renderedItems = useMemo(() => {
    const itemsByGroup = getItemsByGroup();
    return filterValue === "alphabetically" ? (
      getItemsAlphabetically()
    ) : itemsByGroup.length ? (
      itemsByGroup
    ) : (
      <Box mt={0.5}>{noResults}</Box>
    );
  }, [filterValue, getItemsAlphabetically, getItemsByGroup, noResults]);

  const handleSearchTextfieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => setSearchValue(e.target.value);

  const handleSearchClear = () => setSearchValue("");

  const handleFilterChange = (
    _event: MouseEvent<HTMLElement>,
    filter: "group" | "alphabetically" | null,
  ) => {
    if (filter) {
      setFilterValue(filter);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        id="editor-elements"
      >
        <Card square variant="outlined">
          <CardContent className={flexCenter}>
            <TextField
              placeholder={searchInputLabel}
              value={searchValue}
              onChange={handleSearchTextfieldChange}
              inputProps={{ "aria-label": "search" }}
              InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment: searchValue && (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      size="small"
                      icon="clear"
                      onClick={handleSearchClear}
                    />
                  </InputAdornment>
                ),
              }}
              className={inputClass}
              id="editor-elements-search"
            />
            <ToggleButtonGroup
              size="small"
              value={filterValue}
              exclusive={true}
              onChange={handleFilterChange}
            >
              <ToggleButton value="group">
                <Tooltip title={titleTooltipGroup}>
                  <GroupWorkIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="alphabetically">
                <Tooltip title={titleTooltipAlphabetically}>
                  <SortByAlphaIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </CardContent>
        </Card>
      </Box>
      <>{renderedItems}</>
    </>
  );
});
