import { ChangeEvent, memo, useEffect, useState } from "react";
import { Box, Divider, TextField, Typography } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";

import {
  buildCustomExpressionValue,
  getPureExpression,
  isCustomExpression,
} from "core";
import { Section, useElementEditorContext } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { CodeBlock } from "elementTypes/common/CodeBlock";
import IconButton from "elementTypes/common/IconButton";

import { UntransformedExternalLinkFieldConfig } from "../../types";
import { useExternalLinkFieldEditorTranslation } from "../translation";

export const LinkConfig = memo(() => {
  const {
    elementModel: {
      config,
      config: { link = "" },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedExternalLinkFieldConfig>();
  const i18n = useExternalLinkFieldEditorTranslation();

  const changeLinkInput = (newLink?: string) =>
    changeConfigValue("link", newLink);

  const customLinkInput = ({
    value: identifierValue,
    onChange: onIdentifierChange,
  }: {
    value: string;
    onChange: (value: string) => void;
  }) => {
    const onCustomChange = (e: ChangeEvent<HTMLInputElement>) =>
      onIdentifierChange(e.target.value);

    return (
      <TextField
        value={identifierValue}
        onChange={onCustomChange}
        fullWidth={true}
        placeholder={i18n.linkPlaceholder}
        helperText={i18n.linkHelperText}
      />
    );
  };

  const toggleMode = (isExpression: boolean) => {
    const nextLink = isExpression
      ? getPureExpression(link)
      : buildCustomExpressionValue(`"${link}"`);
    changeLinkInput(nextLink);
  };

  return (
    <Section title={i18n.linkConfigTitle}>
      <Box px={2}>
        <CustomExpressionEditor
          value={link}
          config={config}
          onChange={changeLinkInput}
          label={i18n.linkLabel}
          nonExpressionEditor={customLinkInput}
          onToggleMode={toggleMode}
        />
      </Box>
      {isCustomExpression(link) ? <CodeExamples /> : null}
    </Section>
  );
});

const googleExample = "`https://www.google.com/search?q=${VARIABLE}`";

const CodeExamples = () => {
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timeout);
    }
    return;
  }, [isCopied]);

  const handleCopy = () => setCopied(true);

  const translation = useExternalLinkFieldEditorTranslation();

  return (
    <div>
      <Divider />
      <Box py={1} px={2}>
        <Typography>{translation.examplesTitle}</Typography>
      </Box>
      <Divider />
      <Box py={1} px={2}>
        <CopyToClipboard text={googleExample} onCopy={handleCopy}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="caption">{translation.googleLabel}</Typography>
            <IconButton
              icon="file_copy"
              tooltip={
                isCopied ? translation.copiedTooltip : translation.copyTooltip
              }
              size="small"
            />
          </Box>
        </CopyToClipboard>
        <CodeBlock language="js" value={googleExample}>
          {googleExample}
        </CodeBlock>
      </Box>
    </div>
  );
};
