import { Box } from "@mui/material";

interface SpacerProps {
  vertical?: number;
  horizontal?: number;
}

export const Spacer = ({ vertical, horizontal }: SpacerProps) => (
  <Box marginTop={vertical} marginLeft={horizontal} />
);
