import List from "@mui/material/List";
import ascend from "ramda/es/ascend";
import prop from "ramda/es/prop";
import sortWith from "ramda/es/sortWith";
import { IMenuItem, ITranslatedMenuItem } from "core";
import { Language } from "core/types";
import { getTranslatedTexts } from "core/utils/element-utils";

import { MenuItem } from "./MenuItem";

export interface IGenerateMenuItemsProps {
  uiName: string;
  menuItemSelected: string;
  depth?: number;
}

export const GenerateMenuItems = (
  menu: IMenuItem[],
  props: IGenerateMenuItemsProps,
  language: Language,
) => {
  const translatedMenu = menu.map((m) => ({
    ...m,
    i18n: getTranslatedTexts(language, m.i18n),
  })) as ITranslatedMenuItem[];

  return sortWith([ascend<ITranslatedMenuItem>(prop("order"))])(
    translatedMenu,
  ).map((v) => <MenuItem {...v} {...props} key={v.id} item={v} />);
};

export const GenerateMenuList = (
  menu: IMenuItem[],
  uiName: string,
  menuItemSelected: string,
  language: Language,
  depth?: number,
) => (
  <List dense disablePadding>
    {GenerateMenuItems(
      menu,
      {
        uiName,
        depth,
        menuItemSelected,
      },
      language,
    )}
  </List>
);
