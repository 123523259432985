import { createUseTranslation } from "core/session/translation";

export const jsonFieldEditorTranslation = {
  en: {
    valueLabel: "Json",
    collapsedLabel: "Collapsed",
    enableClipboardLabel: "Enable Clipboard",
  },
};

export const useJsonFieldEditorTranslation = createUseTranslation(
  jsonFieldEditorTranslation,
);
