import { memo } from "react";
import Markdown from "react-markdown";
import { IElementComponentProps } from "core";

import { Code, Heading, InlineCode, Paragraph } from "./components";
import { useStyles } from "./styles";
import { MarkdownField } from "./types";

type IProps = IElementComponentProps<{}, MarkdownField> & {
  text: string | null;
};

const DefaultMarkdownField = memo<IProps>(({ text, element: { config } }) => {
  const {
    classes: { root },
  } = useStyles({ visibility: config.overflow });
  return (
    <span className={root}>
      <Markdown
        skipHtml={!config.htmlAllowed}
        components={{
          code: ({ node, inline, className, children, style, ...props }) => {
            const match = /language-(\w+)/.exec(className || "");
            const value = String(children);

            return !inline && match ? (
              <Code
                language={match[1]}
                value={value.replace(/\n$/, "")}
                customStyle={style}
                {...props}
              >
                {value.replace(/\n$/, "")}
              </Code>
            ) : (
              <InlineCode value={value} {...props} />
            );
          },
          h1: Heading(config.align),
          h2: Heading(config.align),
          h3: Heading(config.align),
          h4: Heading(config.align),
          h5: Heading(config.align),
          h6: Heading(config.align),
          p: Paragraph(config.align),
        }}
      >
        {text || ""}
      </Markdown>
    </span>
  );
});

export default DefaultMarkdownField;
