export type EmojiOption = {
  value: string;
  label: string;
};

export const loadEmojiOptions = (): Promise<
  { value: string; label: string }[]
> => {
  return Promise.all([
    import("../../../../assets/emoji1").then((module) => module.emoji1),
    import("../../../../assets/emoji2").then((module) => module.emoji2),
  ])
    .then(([emojiData1, emojiData2]) => {
      const part1 = emojiData1.map((icon) => ({
        value: icon.character,
        label: icon.slug,
      }));
      const part2 = emojiData2.map((icon) => ({
        value: icon.character,
        label: icon.slug,
      }));
      return [...part1, ...part2];
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Failed to load emoji options", error);
      return [];
    });
};

export default loadEmojiOptions;
