import { memo, useCallback, useMemo } from "react";
import {
  Box,
  Card,
  CardMedia,
  Button as MuiButton,
  Stack,
  Typography,
} from "@mui/material";
import { DropzoneProps, FileRejection, useDropzone } from "react-dropzone";
import { FieldPath, FieldValues } from "react-hook-form";
import { RenderProps } from "elementTypes/common/HookForm";
import IconButton from "elementTypes/common/IconButton";
import { MuiIcon } from "elementTypes/common/MuiIcon";

import { bytesToSize } from "utils/bytesToSize";
import { useSnackbar } from "utils/hooks";

export type UploadZoneType<
  Values extends FieldValues,
  T extends FieldPath<Values>,
> = RenderProps<Values, T> &
  Partial<DropzoneProps> & {
    width?: string;
    dense?: boolean;
    onUpload?(file: File): void;
  };
const PreviewCard = memo<{
  preview: File;
  onDelete?: (file: File) => () => void;
}>(({ onDelete, preview: fileProps }) => {
  const preview = fileProps as File;
  const isImg = preview.type.startsWith("image/");
  return (
    <Card
      variant="outlined"
      sx={{ display: "flex", width: "100%", alignItems: "center" }}
    >
      <CardMedia
        component={isImg ? "img" : "div"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 45,
          width: isImg ? "auto" : 50,
          ...(isImg && {
            maxWidth: "20%",
          }),
        }}
        {...(isImg && {
          image: URL.createObjectURL(preview),
          alt: preview.name,
          onLoad: () => URL.revokeObjectURL(URL.createObjectURL(preview)),
        })}
      >
        {!isImg ? <MuiIcon icon="file_copy" color="inherit" /> : null}
      </CardMedia>
      <Box
        sx={{
          p: 0.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          gap: 1,
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Typography
            sx={{
              flex: 1,
            }}
            noWrap
          >
            {preview.name}
          </Typography>
          <Typography variant="caption">{bytesToSize(preview.size)}</Typography>
        </Box>
        {onDelete && (
          <IconButton icon="delete_outlined" onClick={onDelete(preview)} />
        )}
      </Box>
    </Card>
  );
});

export const UploadZoneInput = <
  Values extends FieldValues,
  T extends FieldPath<Values>,
>({
  field,
  fieldState: { error },
  accept,
  width,
  ...rest
}: UploadZoneType<Values, T>) => {
  const maxFiles = rest?.maxFiles ?? 1;
  const showSnackbar = useSnackbar();
  const { acceptedFiles, isDragAccept, getRootProps, getInputProps } =
    useDropzone({
      onDropAccepted: (nextFiles: File[]) => {
        field.onChange(
          rest.multiple ? [...field.value, ...nextFiles] : nextFiles[0],
        );
        !rest.multiple && rest.onUpload?.(nextFiles[0]);
      },
      onDropRejected: (rejectedFiles: FileRejection[]) => {
        rejectedFiles.map((rf) =>
          showSnackbar(
            `${rf.file.name} - ${rf.errors?.map((e) => e.message).join(", ")}`,
            "error",
            { key: `${rf.file.name}` },
          ),
        );
      },
      ...rest,
      accept,
      maxFiles,
      multiple: rest?.multiple ?? false,
    });

  const handleRemove = useCallback(
    (removeFile: File) => () => {
      rest.multiple
        ? field.onChange(
            (field.value ?? []).filter(
              (of: File) => of.name !== removeFile.name,
            ),
          )
        : field.onChange(null);
    },
    [field, rest.multiple],
  );

  const isFileSelected = field.value && acceptedFiles?.length >= maxFiles;
  const uploadedFiles = useMemo(
    () =>
      !field.value ? (
        <span />
      ) : Array.isArray(field.value) ? (
        (field.value ?? [])?.map((preview: File, i: number) => {
          if (!preview) {
            return <span key={i} />;
          }
          return (
            <PreviewCard
              key={`Preview-${preview.name}`}
              onDelete={handleRemove}
              preview={preview}
            />
          );
        })
      ) : (
        <PreviewCard
          preview={field.value}
          {...(handleRemove && { onDelete: handleRemove })}
        />
      ),
    [field.value, handleRemove],
  );

  return (
    <Stack justifyContent="center" gap={1} width={width}>
      {uploadedFiles}
      <MuiButton
        {...getRootProps({
          className: "dropzone",
        })}
        component="div"
        variant="outlined"
        color={error ? "error" : "primary"}
        startIcon={
          <MuiIcon
            icon={error ? "error" : isFileSelected ? "check" : "cloud_upload"}
          />
        }
        disabled={rest?.disabled}
        sx={{
          overflow: "unset",
          minHeight: rest?.dense ? "60px" : "90px",
          ...(isDragAccept && {
            borderStyle: "dashed",
          }),
        }}
      >
        {isFileSelected ? "File selected" : "Select or Drop file"}
        <input {...getInputProps()} ref={field.ref} />
      </MuiButton>
    </Stack>
  );
};
