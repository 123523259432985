import { memo, useMemo, useState } from "react";
import { RefCallBack } from "react-hook-form";

import { BaseAutocomplete, BaseAutocompleteProps } from "./components";
import { AutocompleteOption, IAutocompleteValue } from "./types";
import { getValueObject } from "./utils.tsx";

type Props = Partial<BaseAutocompleteProps> & {
  value?: IAutocompleteValue;
  label?: string;
  name?: string;
  options?: AutocompleteOption[];
  error?: string;
  isMulti?: boolean;
  isClearable?: boolean;
  withCheckbox?: boolean;
  disableCloseOnSelect?: boolean;
  onChange?: (value: string | string[], reason?: string) => void;
  innerRef?: RefCallBack;
};

export const Autocomplete = memo<Props>(
  ({
    isMulti,
    value = isMulti ? [] : "",
    label = "",
    name = "",
    options = [],
    error,
    searchInputValue: initialSearchInputValue,
    onChange,
    onInputChange,
    isClearable = true,
    ...rest
  }) => {
    const valueObject = useMemo(
      () => getValueObject(options, value, isMulti) ?? null,
      [value, options, isMulti],
    );

    const [searchInputValue, setInputValue] = useState<string | undefined>(
      initialSearchInputValue,
    );

    const handleChange = (newValue: IAutocompleteValue, reason?: string) => {
      onChange?.(newValue, reason);
      setInputValue(undefined);
    };

    const handleInputChange = (newValue: string | undefined) => {
      onInputChange?.(newValue);
      setInputValue(newValue);
    };

    return (
      <BaseAutocomplete
        options={options}
        onChange={handleChange}
        valueObject={valueObject ?? null}
        name={name}
        label={label}
        isLoading={false}
        defaultItemSize={48}
        virtualizedList={true}
        isClearable={isClearable}
        {...rest}
        errors={error}
        isMulti={isMulti}
        searchInputValue={searchInputValue}
        onInputChange={handleInputChange}
      />
    );
  },
);
