import { memo } from "react";
import { IElementComponentProps } from "core";

import { HtmlDisplay } from "./types";

const DefaultHtmlDisplay = memo<IElementComponentProps<{}, HtmlDisplay>>(
  ({
    element: {
      i18n: { text },
    },
  }) => <div dangerouslySetInnerHTML={{ __html: text }} />,
);

export default DefaultHtmlDisplay;
