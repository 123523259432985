import { IElementComponentProps, PropsFromConnector } from "core";
import { connectElement } from "core/utils/react-redux";

import Component from "./component";
import { ReduxModule } from "./reduxModule";
import { CallButton } from "./types";

const mapStateToProps = (
  state: any,
  { element }: IElementComponentProps<{}, CallButton>,
) => ({
  disabled:
    typeof element.config.disabled === "function"
      ? element.config.disabled?.(state)
      : element.config.disabled,
});

const mapDispatchToProps = {};

const connector = connectElement<
  ReduxModule,
  CallButton,
  typeof mapStateToProps,
  typeof mapDispatchToProps
>(mapStateToProps, mapDispatchToProps);

export type CallButtonProps = PropsFromConnector<typeof connector>;

export default connector(Component);
