import { IReduxModuleFactoryArgs } from "core/types";

import { NumberField } from "../types";

import { buildSelectors } from "./selectors";

export function reduxModuleFactory({
  element,
}: IReduxModuleFactoryArgs<NumberField>) {
  const selectors = buildSelectors(element);

  return {
    selectors,
  };
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
