import { createContext, useContext } from "react";

import { IReduxModuleContext } from "../types";

// DO NOT REFACTOR THIS TO `| undefined`
// this context is deeply integrated into core element logic
const ReduxModuleContext = createContext<IReduxModuleContext>({
  context: {},
  scope: "",
  idMaps: [{}],
  getModule: () => null as any,
});

export function useReduxModuleContext() {
  const context = useContext(ReduxModuleContext);
  return context;
}

export default ReduxModuleContext;
