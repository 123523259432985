import { memo, useMemo } from "react";

import { Edge, Node } from "reactflow";
import { useFlowState } from "../utils";

import { EdgeData, NodeData } from "./StateViewer/types";
import { WorkflowLandingPage } from "./WorkflowLandingPage";

import {
  CreateState,
  CreateStateTransition,
  EditState,
  EditStateTransition,
} from ".";

type LandingPageProps = {
  list?: NodeData[];
};

export const RightPanel = memo<LandingPageProps>(({ list }) => {
  const { node, edge, focusState, setSelectedElement } = useFlowState();
  // TODO: During elements drag and drop this component re-renders too often

  const showList = useMemo(
    () => (!edge && !node) || ["end", "start"].includes(node?.type ?? ""),
    [edge, node],
  );

  if (showList) {
    return (
      <WorkflowLandingPage
        list={list}
        onElementSelect={setSelectedElement}
        onFocusState={focusState}
      />
    );
  }

  if (node && !["end", "start"].includes(node.type ?? "")) {
    return node?.id !== "newElement" ? (
      <EditState node={node as Node<NodeData>} />
    ) : (
      <CreateState />
    );
  }

  if (edge && edge.source !== "end") {
    return edge?.id !== "newElement" ? (
      <EditStateTransition edge={edge as Edge<EdgeData>} />
    ) : (
      <CreateStateTransition edge={edge as Edge<EdgeData>} />
    );
  }

  return <span />;
});
