import { FormDataSourceImplementation } from "elementInterfaces";

export function buildSelectors(dataSource: FormDataSourceImplementation) {
  const disabled = (state: any) =>
    !(dataSource.isValid(state) && dataSource.hasChanges(state));

  return {
    disabled,
  };
}
