import { GridCellEditCommitParams, GridRowId } from "@mui/x-data-grid";
import { RoleOption } from "queries/admin/types";
import { createTypeSafeContext } from "utils/createTypeSafeContext";

import { LDAPData, ValidationModel, Value } from "./types";

type LDAPContext = {
  roleMappings: LDAPData["roleMappings"];
  ldapConfiguration: LDAPData["ldapConfiguration"];
  errors?: ValidationModel;
  roleOptions?: RoleOption[];
  onChange: (path: string[], value: Value) => void;
  onRowAdd: () => void;
  onRowDelete: (id: GridRowId) => () => void;
  onCommitChange: (params: GridCellEditCommitParams, event: any) => void;
};

const { Provider, Consumer, useTypeSafeContext } =
  createTypeSafeContext<LDAPContext>();

export const useLDAPContext = useTypeSafeContext;
export const LDAPProvider = Provider;
export const LDAPConsumer = Consumer;
