import { ChangeEvent, MouseEvent, lazy, memo, useState } from "react";
import { FormControl, Tooltip } from "@mui/material";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import { ColorResult, SketchPicker } from "react-color";
import { RefCallBack } from "react-hook-form";

import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";

import { useStyles } from "./style";

export type Placement = FormControlLabelProps["labelPlacement"];

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

type Props = {
  value: string;
  changeValue: (value: string | null) => void;
  label?: string | JSX.Element;
  disabled?: boolean;
  placement?: Placement;
  required?: boolean;
  fullHeight?: boolean;
  innerRef?: RefCallBack;
};

export const ColorInput = memo<Props>(
  ({
    value,
    label,
    disabled,
    placement = "end",
    required,
    fullHeight,
    changeValue,
    innerRef,
  }) => {
    const color = value || "transparent";
    const [anchorEl, setAnchorEl] = useState<HTMLLabelElement | null>(null);

    // always save the currently selected color in localColor, even while still selecting
    // when finished selecting, actually call changeValue and reset the localColor
    const [localColor, setLocalColor] = useState<string | null>(null);
    const { classes, cx } = useStyles({
      isLabel: Boolean(label),
      bgColor: color,
      placement,
    });

    const handleClick = (event: MouseEvent<HTMLLabelElement>) =>
      !disabled && setAnchorEl(event.currentTarget);

    const handleChange = ({ hex }: ColorResult) => {
      const nextVal = hex === "transparent" && !required ? null : hex;
      if (nextVal !== value) {
        changeValue(nextVal);
        setLocalColor(null);
      }
    };

    const handleLocalChange = (
      { hex }: ColorResult,
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      event.stopPropagation();
      if (hex !== value) {
        setLocalColor(hex);
      }
    };

    const component = (
      <span
        className={cx(classes.colorControl, {
          [classes.fullHeight]: fullHeight,
        })}
      />
    );

    const handleClose = (_: any, reason: string) => {
      if (reason === "backdropClick") {
        setAnchorEl(null);
      }
    };

    return (
      <>
        <FormControl
          required={required}
          fullWidth={true}
          data-disabled={disabled}
          className={cx({ [classes.fullHeight]: fullHeight })}
        >
          <Tooltip title={color}>
            <FormControlLabel
              className={cx(classes.labelControl, {
                [classes.fullHeight]: fullHeight,
              })}
              control={component}
              disabled={disabled}
              label={label}
              labelPlacement={placement}
              onClick={handleClick}
              data-value={label}
            />
          </Tooltip>
        </FormControl>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          wrapped={false}
        >
          <SketchPicker
            color={localColor ?? color}
            onChangeComplete={handleChange}
            onChange={handleLocalChange}
            className="color-input-popover"
            ref={innerRef}
          />
        </Popover>
      </>
    );
  },
);
