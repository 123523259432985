import { IDefaultElement, buildCustomExpressionValue } from "core";

import { UntransformedNumberFieldConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedNumberFieldConfig> = {
  config: {
    value: buildCustomExpressionValue("0"),
    precision: null,
    isPercentage: false,
    prefix: "",
    suffix: "",
    highlight: buildCustomExpressionValue("false"),
  },
};
