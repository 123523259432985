import { PropsWithChildren, memo, useMemo } from "react";
import * as locales from "@mui/material/locale";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import defaultsDeep from "lodash/defaultsDeep";
import { IAppMetadata, Language } from "core";

import defaultTheme from "./defaultThemeDefinition";

export type IThemeContainerProps = {
  appMetadata: IAppMetadata | null | undefined;
  language: Language;
} & PropsWithChildren;

const ThemeContainer = memo<IThemeContainerProps>(
  ({ appMetadata, children, language }) => {
    const {
      definition: {
        layout: {
          definition: { theme: themeOptions = {} },
        },
      },
    } = appMetadata?.release ?? { definition: { layout: { definition: {} } } };

    const themeDefinition = useMemo(
      () => defaultsDeep({}, themeOptions, defaultTheme(themeOptions)),
      [themeOptions],
    );

    const theme = useMemo(
      () => createTheme(themeDefinition, locales[language.locale]),
      [themeDefinition, language.locale],
    );

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    );
  },
);

ThemeContainer.displayName = "ThemeContainer";

export default ThemeContainer;
