import { Type } from "./Type";
import { TypeError } from "./TypeError";
import { IAutocompleteKey } from "./types";

/**
 * TODO:
 * The description should default to the union of all descriptions
 */

export class UnionType extends Type {
  public name: string;

  constructor(
    public readonly types: Type[],
    description?: string,
  ) {
    super(description);
    this.name = this.generateTypeName();
  }

  private generateTypeName(): string {
    return this.types.map((t) => t.name).join(" | ");
  }

  public validate(value: any): true | TypeError {
    for (const type of this.types) {
      const result = type.validate(value);
      if (result === true) {
        return true;
      }
    }
    return new TypeError(value, this);
  }

  /**
   * TODO:
   * Make autocomplete smarter to provide the correct alternative
   */
  public getAutocompleteRecord(): Record<string, IAutocompleteKey> {
    return Object.assign(
      {},
      ...this.types.map((t) => t.getAutocompleteRecord()),
    );
  }
}
