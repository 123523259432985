import { HTMLAttributes, memo, useCallback, useMemo } from "react";
import { Box, ListItem, ListItemText } from "@mui/material";
import { JSONSchema6 } from "json-schema";
import { useSelector } from "react-redux";
import { selectors as editorSelectors } from "core/editor/reduxModule";
import {
  GeneralTypes,
  IObjectViewField,
  Language,
  Translation,
} from "core/types";
import { getTranslatedText } from "core/utils/element-utils";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import {
  AutocompleteOption,
  IAutocompleteValue,
} from "elementTypes/common/Autocomplete/types";
import IconButton from "elementTypes/common/IconButton";
import { ASTERISK_SYMBOL } from "elementTypes/common/utils";
import { Form } from "elementTypes/default_form/types";

import { usePage } from "utils/hooks";
import {
  Section,
  getNearestParentElement,
  useElementEditorContext,
  useParentForm,
} from "../..";
import { useEditorTranslation } from "../../translation";
import { getPageElementsByType } from "../../utils";

type DataSourceEditorComponentProps = {
  language: Language;
  showMultiReferenceFields?: boolean;
  allowedDataTypes?: GeneralTypes[];
  allowedDataTypeIsArray?: boolean;
  helperText?: {
    elementId?: JSX.Element | string | null;
    fieldPath?: JSX.Element | string | null;
  };
};

const customRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: AutocompleteOption,
) => (
  <ListItem
    {...props}
    secondaryAction={
      <IconButton
        icon="star"
        tooltip={
          option?.isCorrect
            ? "recomended"
            : "Not recommended: Database column type mismatch"
        }
        color={option?.isCorrect ? "info" : "error"}
      />
    }
    key={(option?.value as string) ?? "secondaryAction"}
  >
    <ListItemText id={`${option.value}`} primary={option.label} />
  </ListItem>
);

export const FormInputDataSourceEditorComponent =
  memo<DataSourceEditorComponentProps>(
    ({
      language,
      showMultiReferenceFields,
      allowedDataTypes,
      allowedDataTypeIsArray,
      helperText,
    }) => {
      const {
        elementModel: {
          id,
          i18n,
          config: { dataSource },
        },
        changeConfigValue,
        changeTranslation,
      } = useElementEditorContext<WithOptionalFieldDataSourceConfig>();
      const editorTranslation = useEditorTranslation();

      const updatedElements = useSelector(editorSelectors.updatedElements);
      const page = usePage();

      const rootElement = page!.element;
      const { parentView, parentDataSource, parentJSONSchema } =
        useParentForm();

      const requiredFields = useMemo(
        () => (parentJSONSchema as JSONSchema6)?.required ?? [],
        [parentJSONSchema],
      );

      const parentMultiReferenceConfig =
        showMultiReferenceFields && parentDataSource?.multiReference;

      const isCorrectFieldType = useCallback(
        (field: IObjectViewField) =>
          !allowedDataTypes ||
          (allowedDataTypes.includes(field.generalType.type) &&
            (allowedDataTypeIsArray === undefined ||
              allowedDataTypeIsArray === field.generalType.isArray)),
        [allowedDataTypes, allowedDataTypeIsArray],
      );

      const options = useMemo(() => {
        let items;
        if (showMultiReferenceFields) {
          if (parentMultiReferenceConfig) {
            items = Object.keys(parentMultiReferenceConfig).map(
              (referenceKey) => ({
                value: referenceKey,
                label: referenceKey,
                disabled: false,
                defaultLabel: referenceKey,
                isCorrect: true,
              }),
            );
          } else {
            items = undefined;
          }
        } else {
          items = parentView?.fields
            .map((viewField) => ({
              value: viewField.name,
              label: `${getTranslatedText(language, viewField.i18n, "title")} (${
                viewField.name
              })${requiredFields.includes(viewField.name) ? ASTERISK_SYMBOL : ""}`,
              // disabled: !isCorrectFieldType(viewField),
              isCorrect: isCorrectFieldType(viewField),
              defaultLabel:
                getTranslatedText(language, viewField.i18n, "title") ??
                viewField.name,
            }))
            .sort((a, b) => Number(b.isCorrect) - Number(a.isCorrect));
        }
        return items;
      }, [
        parentView,
        language,
        parentMultiReferenceConfig,
        showMultiReferenceFields,
        requiredFields,
        isCorrectFieldType,
      ]);

      const dataSourceIdOptions = useMemo(() => {
        // use the nearest form as default value (first item in the returned array)
        const nearestForm = getNearestParentElement(
          rootElement,
          updatedElements,
          id,
          "default_form",
        );

        let allForms = getPageElementsByType(
          rootElement,
          updatedElements,
          "default_form",
        );
        if (parentMultiReferenceConfig) {
          allForms = allForms.filter(
            (form) => (form as Form)?.config?.dataSource?.multiReference,
          );
        }

        const allFormOptions = allForms.map((element) => ({
          value: element.id,
          label: element.id,
        }));

        if (nearestForm) {
          return [
            { value: nearestForm.id, label: nearestForm.id },
            ...allFormOptions.filter((e) => e.value !== nearestForm.id),
          ];
        } else {
          return allFormOptions;
        }
      }, [rootElement, updatedElements, id, parentMultiReferenceConfig]);

      const changeDataSource = useCallback(
        (newDataSource: WithOptionalFieldDataSourceConfig["dataSource"]) =>
          changeConfigValue("dataSource", newDataSource),
        [changeConfigValue],
      );

      const { code } = language;

      const setTranslation = (newLabel: string) => ({
        ...i18n,
        [code]: {
          ...i18n[code],
          label: newLabel,
        },
      });

      const handleFieldPathInputChange = (value: IAutocompleteValue) => {
        const currentLabel = getTranslatedText(
          language,
          i18n as Translation<"label">,
          "label",
        );
        const currentDefaultLabel = options?.find(
          (f) => f.value === dataSource?.fieldPath?.[0],
        )?.defaultLabel;

        const defaultLabel = options?.find(
          (f) => f.value === value,
        )?.defaultLabel;

        if (!value) {
          changeTranslation(setTranslation(""));
        }

        if (
          defaultLabel &&
          (currentLabel === currentDefaultLabel ||
            !currentLabel ||
            !currentDefaultLabel)
        ) {
          changeTranslation(setTranslation(defaultLabel));
        }

        changeDataSource({
          elementId: dataSource!.elementId,
          fieldPath: [value] as string[],
        });
      };

      const handleElementIdInputChange = (value: IAutocompleteValue) => {
        changeDataSource({
          elementId: value as string,
          fieldPath: dataSource!.fieldPath,
        });
      };

      const createDataSource = () => {
        let defaultDataSource: WithOptionalFieldDataSourceConfig["dataSource"] =
          {
            elementId: dataSourceIdOptions?.[0]?.value,
            fieldPath: [],
          };

        if (parentView) {
          const defaultOption = parentView.fields.find(isCorrectFieldType);
          if (defaultOption) {
            defaultDataSource = {
              ...defaultDataSource,
              fieldPath: [defaultOption.name],
            };

            const defaultLabel = options?.find(
              (f) => f.value === defaultOption.name,
            )?.defaultLabel;

            if (defaultLabel) {
              changeTranslation(setTranslation(defaultLabel));
            }
          }
        }

        changeDataSource(defaultDataSource);
      };

      const deleteDataSource = () => {
        changeDataSource(undefined);
      };

      // for now use view metadata
      // TODO: use data object

      return (
        <Section
          title={editorTranslation.dataSourceTitle}
          wrapped={Boolean(dataSource)}
          defaultOpened={Boolean(dataSource)}
          headerAction={
            <IconButton
              icon={dataSource ? "delete_outline" : "add"}
              onClick={dataSource ? deleteDataSource : createDataSource}
              disabled={!dataSourceIdOptions?.length}
              tooltip={
                dataSource
                  ? editorTranslation.removeDataSourceTooltip
                  : editorTranslation.addDataSourceTooltip
              }
            />
          }
        >
          {dataSource && (
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" flexDirection="column" gap={1}>
                <Autocomplete
                  options={dataSourceIdOptions}
                  onChange={handleElementIdInputChange}
                  value={dataSource.elementId}
                  name="elementId"
                  label={editorTranslation.elementIdLabel}
                  isLoading={false}
                  virtualizedList={true}
                  disablePortal
                />
                {helperText?.elementId}
              </Box>
              <Box display="flex" flexDirection="column" gap={1}>
                <Autocomplete
                  options={options ?? []}
                  onChange={handleFieldPathInputChange}
                  value={dataSource.fieldPath?.[0]}
                  name="fieldPath"
                  label={editorTranslation.fieldPathLabel}
                  isLoading={false}
                  virtualizedList={true}
                  getOptionDisabled={(o) => o.disabled}
                  disablePortal
                  customRenderOption={customRenderOption || false}
                />
                {helperText?.fieldPath}
              </Box>
            </Box>
          )}
        </Section>
      );
    },
  );
