import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import { DeleteButtonConfig, DeleteButtonTranslationKeys } from "./types";

export default {
  reduxModuleFactory,
  name: "default_delete_button",
  component: Component,
  editable: true,
  configType: DeleteButtonConfig,
  translationKeys: DeleteButtonTranslationKeys,
};
