import { createAction, createActionTypeScoper } from "core/utils/redux";
import { LoginConfig } from "services/api";

import { constants } from "./constants";

const scopeActionType = createActionTypeScoper(constants.MODULE_NAME);

export const types = {
  LOAD_LOGIN_CONFIG_SUCCESS: scopeActionType("LOAD_LOGIN_CONFIG_SUCCESS"),
  LOAD_LOGIN_CONFIG_ERROR: scopeActionType("LOAD_LOGIN_CONFIG_ERROR"),
};

export const actions = {
  loadLoginConfigSuccess: createAction(
    types.LOAD_LOGIN_CONFIG_SUCCESS,
    (value: LoginConfig) => ({
      value,
    }),
  ),
  loadLoginConfigError: createAction(
    types.LOAD_LOGIN_CONFIG_ERROR,
    (error: string) => ({
      error,
    }),
  ),
};
