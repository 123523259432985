import { FC, HTMLAttributes, useMemo } from "react";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import { FormControl, Grid, ListItemText, Typography } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import { AutocompleteOption } from "elementTypes/common/Autocomplete/types";

import { useERDTranslation, useKeyLookupTranslation } from "./translation";
import { NodeData } from "./types";
import { sortColumns } from "./utils";

type Props = {
  nodeData: NodeData;
};

export const KeyLookup: FC<Props> = ({
  nodeData: { columns, lookupLabelColumn },
}) => {
  const translation = useERDTranslation();
  const {
    defaultLookupColumnText,
    descriptionText,
    columnLabel,
    humanReadableText,
    recommendationLabel,
    selectText,
    uniqueLabel,
    useText,
    textLabel,
  } = useKeyLookupTranslation();
  const { control } = useFormContext();

  const items = useMemo(
    () =>
      sortColumns(columns).map((column) => ({
        value: column.name,
        label: column.name,
        column,
      })),
    [columns],
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>
            {selectText}{" "}
            <Typography style={{ fontWeight: 700 }} component="span">
              {defaultLookupColumnText}
            </Typography>{" "}
            {descriptionText}
          </Typography>
          <Alert style={{ marginTop: 16 }} variant="outlined" severity="info">
            <AlertTitle>{recommendationLabel}</AlertTitle>
            <Typography>
              {useText}{" "}
              <Typography style={{ fontWeight: 500 }} component="span">
                {uniqueLabel}
              </Typography>{" "}
              {humanReadableText}{" "}
              <Typography style={{ fontWeight: 500 }} component="span">
                {textLabel}
              </Typography>{" "}
              {columnLabel}
            </Typography>
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Controller
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  innerRef={ref}
                  error={error?.message}
                  label={translation.keyLookupSelectColumn}
                  options={items}
                  customRenderOption={(
                    props: HTMLAttributes<HTMLLIElement>,
                    option: AutocompleteOption,
                  ) => (
                    <li key={String(option.value)} {...props}>
                      <ListItemText
                        primary={
                          <>
                            {option.label}
                            {option.column.unique && (
                              <FingerprintIcon fontSize="small" />
                            )}
                          </>
                        }
                        primaryTypographyProps={{
                          style: {
                            display: "flex",
                            alignItems: "center",
                          },
                        }}
                        secondary={option.column.type}
                        secondaryTypographyProps={{
                          style: {
                            color: "#999",
                          },
                        }}
                        style={{
                          margin: 0,
                          // display non-text columns lighter, so it's easier to select the correct column
                          ...(option.column.type !== "text" && {
                            color: "#777",
                          }),
                        }}
                      />
                    </li>
                  )}
                  variant="outlined"
                />
              )}
              name="defaultLookup"
              control={control}
              defaultValue={
                !lookupLabelColumn ? items[0]?.value : lookupLabelColumn
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
