import * as t from "io-ts";
import { customExpression } from "core/types";

export const Color = t.union([
  t.literal("primary"),
  t.literal("secondary"),
  t.literal("success"),
  t.literal("error"),
  t.literal("warning"),
  t.literal("info"),
  t.literal("default"),
  t.literal("lighter"),
  t.literal("disabled"),
  t.literal("white"),
  t.literal("black"),
  t.null,
]);

export const BtnColor = t.union([
  t.literal("primary"),
  t.literal("secondary"),
  t.literal("success"),
  t.literal("error"),
  t.literal("warning"),
  t.literal("info"),
  t.literal("standard"),
  t.literal("default"),
  t.null,
]);

export const ColorConfig = t.union([Color, customExpression(Color)]);
export const BtnColorConfig = t.union([BtnColor, customExpression(BtnColor)]);

export type ColorConfig = t.TypeOf<typeof ColorConfig>;
export type BtnColorConfig = t.TypeOf<typeof BtnColorConfig>;

export const createColorSelector = (color?: ColorConfig) => (state: any) =>
  color
    ? typeof color === "string"
      ? color
      : color(state) ?? undefined
    : undefined;
