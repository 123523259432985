import {
  ChangeEvent,
  MouseEvent,
  ReactNode,
  lazy,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import deepEqual from "fast-deep-equal";
import { useSelector } from "react-redux";

import {
  IElementModel,
  buildCustomExpressionValue,
  isCustomExpression,
} from "core";
import {
  Section,
  getPageElementsByType,
  useEditorTranslation,
} from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { useLanguageSectionContext } from "core/editor/common/LanguageSection/LanguageSectionContext";
import { selectors as editorSelectors } from "core/editor/reduxModule";
import { getTranslatedTexts } from "core/utils/element-utils";
import { AlertBox } from "elementTypes/common/AlertBox";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import { IAutocompleteValue } from "elementTypes/common/Autocomplete/types";
import { getRenderOption } from "elementTypes/common/Autocomplete/utils";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import { TableHeaderCell } from "elementTypes/default_table_header_cell/types";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { usePage } from "utils/hooks";

import {
  buildConfigFromFixedFilter,
  buildFixedFilterFromConfig,
} from "../../reduxModule/utils";
import {
  FilterGroupCombinator,
  FixedFilterGroup,
  FixedFilterRule,
  IFilterGroup,
} from "../../toolsPanel";
import { useTableEditorTranslation } from "../../translation";
import { UntransformedTableConfig } from "../../types";

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

const TableFilter = withLazyLoading(
  lazy(() =>
    import("../../toolsPanel/DefaultTableFilter").then((module) => ({
      default: module.TableFilter,
    })),
  ),
  true,
);

const defaultFilterValue = {
  combinator: FilterGroupCombinator.AND,
  filters: [],
};

const defaultExpressionValue = buildCustomExpressionValue(
  JSON.stringify(defaultFilterValue, null, 2),
);

type Props = {
  elementModel: IElementModel<UntransformedTableConfig>;
  config: UntransformedTableConfig;
  changeConfigValue: (key: keyof UntransformedTableConfig, value: any) => void;
};

enum FilterType {
  elements = "elements",
  fixed = "fixed",
}

const buildExpressionFromElementsFilter = (
  filters: FixedFilterRule[],
): string => {
  const nextFilter = {
    combinator: "AND",
    filters: filters.map((filter) => ({
      field: filter.field,
      operator: filter.operator,
      value: `@@@elements.${filter.value}.value@@@`,
    })),
  };

  const buildCustomExpression = buildCustomExpressionValue(
    JSON.stringify(nextFilter, null, 2),
  );
  // TODO: refactor code to build the expression via a JS AST

  return buildCustomExpression.replace(/"@@@/g, "").replace(/@@@"/g, "");
};

export const FixedFilter = memo<Props>(
  ({
    config,
    config: { filter, elementsFilter = [] },
    changeConfigValue,
    elementModel: { children },
  }) => {
    const fixedFilter = config.fixedFilter ?? defaultFilterValue;
    const {
      header: { elements: columns },
    } = children as any;

    const updatedElements = useSelector(editorSelectors.updatedElements);
    const page = usePage();

    const rootElement = page!.element;

    const { lang } = useLanguageSectionContext();

    const elementOptions = useMemo(() => {
      const elements = getPageElementsByType(rootElement, updatedElements, [
        "default_autocomplete_input",
        "default_bool_input",
      ]);
      return (
        elements.map((element) => {
          const elLabel: string = (
            getTranslatedTexts(lang, element.i18n) as any
          )?.label;

          return {
            value: element.id,
            label: elLabel.length ? elLabel : element.id,
            ...(!elLabel && {
              name: element.id,
            }),
          };
        }) ?? []
      );
    }, [lang, rootElement, updatedElements]);

    const columnsOptions = useMemo(
      () =>
        columns.map((column: TableHeaderCell) => ({
          value: column?.config?.dataSource?.fieldName,
          label: getTranslatedTexts(lang, column.i18n).label,
        })) ?? [],
      [columns, lang],
    );

    const translation = useTableEditorTranslation();
    const { applyButton, cancelButton } = useEditorTranslation();

    const [filterIsExpression, setIsExpression] = useState(
      isCustomExpression(fixedFilter),
    );
    const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);

    const [filterType, setFilterType] = useState<FilterType>(
      (elementsFilter?.length || !config.fixedFilter) && elementOptions.length
        ? FilterType.elements
        : FilterType.fixed,
    );

    const fixedFilterExpression = (fixedFilter ??
      defaultExpressionValue) as string;

    const handleClose = useCallback(() => setAnchorEl(null), []);

    const handleChange = useCallback(
      (...params: Parameters<typeof changeConfigValue>) =>
        changeConfigValue(...params),
      [changeConfigValue],
    );

    const changeFixedFilter = useCallback(
      (newFixedFilter?: string | IFilterGroup) => {
        handleChange(
          "fixedFilter",
          typeof newFixedFilter === "string"
            ? newFixedFilter
            : buildConfigFromFixedFilter(newFixedFilter ?? null),
        );
      },
      [handleChange],
    );

    const onChangeFilter = useCallback(
      (nextFilter: IFilterGroup) => changeFixedFilter(nextFilter),
      [changeFixedFilter],
    );

    const [openDialogFilter, setOpenDialogFilter] = useState<boolean>(false);
    const handleToggleDialogFilter = useCallback(
      () => setOpenDialogFilter((prevOpen) => !prevOpen),
      [],
    );

    const deleteFilter = useCallback(() => {
      filterIsExpression && setIsExpression(false);
      changeFixedFilter(undefined);
    }, [changeFixedFilter, filterIsExpression]);

    const updateFixedFilter = useCallback(
      (isExpression: boolean) => {
        changeFixedFilter(
          isExpression
            ? (defaultExpressionValue as string)
            : (defaultFilterValue as IFilterGroup),
        );
        setIsExpression(isExpression);
      },
      [changeFixedFilter],
    );

    const handleChangeMode = useCallback(
      (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (
          (checked && !deepEqual(fixedFilter, defaultFilterValue)) ||
          (!checked && String(fixedFilter) !== defaultExpressionValue)
        ) {
          setAnchorEl(event.currentTarget);
        } else {
          updateFixedFilter(checked);
        }
      },
      [fixedFilter, updateFixedFilter],
    );

    const handleUpdateFilter = useCallback(() => {
      updateFixedFilter(!filterIsExpression);
      handleClose();
    }, [filterIsExpression, handleClose, updateFixedFilter]);

    const handleFilterTypeChange = (
      _event: MouseEvent<HTMLElement>,
      nextFilter: FilterType | null,
    ) => {
      if (nextFilter) {
        setFilterType(nextFilter);
      }
    };

    const buttons = Object.values(FilterType).map((filterValue) => (
      <ToggleButton
        key={filterValue}
        fullWidth
        value={filterValue}
        disabled={
          (!!elementsFilter?.length && filterValue === FilterType.fixed) ||
          (!elementOptions.length && filterValue === FilterType.elements)
        }
      >
        {translation[`${filterValue}FilterButton`]}
      </ToggleButton>
    ));

    const handleAddElemensFilter = () => {
      const nextFilters = [
        ...(elementsFilter ?? []),
        {
          field: "",
          operator: "eq",
          value: elementOptions[0].value,
        },
      ];

      handleChange("elementsFilter", nextFilters);
      changeFixedFilter(buildExpressionFromElementsFilter(nextFilters));
    };

    const handleElementsFilterChange =
      (type: "elementId" | "field", elementId: number) =>
      (neVal: IAutocompleteValue) => {
        const nextFilters = (elementsFilter ?? []).map((elFilter, index) =>
          index === elementId
            ? {
                ...elFilter,
                ...(type === "elementId"
                  ? { value: neVal as string }
                  : { field: neVal as string }),
              }
            : elFilter,
        );

        handleChange("elementsFilter", nextFilters);

        changeFixedFilter(buildExpressionFromElementsFilter(nextFilters));
      };

    const handleDeleteElFilter = (elIndex: number) => () => {
      const nextFilters = (elementsFilter ?? []).filter(
        (_, index) => index !== elIndex,
      );

      handleChange(
        "elementsFilter",
        nextFilters.length ? nextFilters : undefined,
      );

      changeFixedFilter(
        nextFilters.length
          ? buildExpressionFromElementsFilter(nextFilters)
          : undefined,
      );

      if (!nextFilters.length) {
        setIsExpression(false);
      }
    };

    const elFilterItems = (elementsFilter ?? []).reduce(
      (res: ReactNode[], elFilter, elIndex: number) =>
        elFilter
          ? [
              ...res,
              <Box
                key={`element-filter-${elIndex}`}
                width="100%"
                p={1.5}
                {...(!!res?.length && {
                  borderTop: "1px solid",
                  borderColor: "divider",
                })}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={4}
                  pb={1}
                >
                  <Autocomplete
                    value={(elFilter.value ?? "") as string}
                    onChange={handleElementsFilterChange("elementId", elIndex)}
                    options={elementOptions}
                    label={translation.elementFilterLabel}
                    isClearable={false}
                    defaultItemSize={60}
                    customRenderOption={getRenderOption("name")}
                  />
                  {/* <IconButton
                    icon="touch_app"
                    size="small"
                    onClick={handleSelectElFilter(elIndex)}
                  /> */}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={4}
                >
                  <Autocomplete
                    options={columnsOptions}
                    value={elFilter.field ?? ""}
                    label={translation.columnFilterLabel}
                    onChange={handleElementsFilterChange("field", elIndex)}
                    isClearable={false}
                  />
                  <IconButton
                    icon="delete_outline"
                    size="small"
                    onClick={handleDeleteElFilter(elIndex)}
                  />
                </Box>
              </Box>,
            ]
          : res,
      [] as ReactNode[],
    );

    return (
      <>
        <Section title={translation.fixedFilterTitle} wrapped={true}>
          <Box pb={1}>
            <ToggleButtonGroup
              size="small"
              value={filterType}
              exclusive={true}
              onChange={handleFilterTypeChange}
              fullWidth
            >
              {buttons}
            </ToggleButtonGroup>
          </Box>
          {elementOptions.length ? (
            <AlertBox
              color="info"
              message={translation.filterAlertMsg}
              boxProps={{ mb: 1 }}
            />
          ) : null}
          {filterType === FilterType.fixed ? (
            <>
              <Box
                display="flex"
                gap={4}
                justifyContent="space-between"
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={filterIsExpression}
                      onChange={handleChangeMode}
                    />
                  }
                  label={translation.advancedLabel}
                />
                <IconButton
                  icon="delete_outline"
                  onClick={deleteFilter}
                  tooltip={translation.removeFilterTooltip}
                />
              </Box>
              {!filterIsExpression && (
                <Button
                  label={translation.defaultFilterButton}
                  onClick={handleToggleDialogFilter}
                  color="primary"
                  iconLeft="filter_list"
                  fullWidth
                />
              )}
              <Popover
                onClose={handleClose}
                actionsAlign="center"
                actions={
                  <>
                    <Button
                      label={applyButton}
                      color="secondary"
                      onClick={handleUpdateFilter}
                    />
                    <Button label={cancelButton} onClick={handleClose} />
                  </>
                }
                anchorEl={anchorEl}
              >
                <Typography align="center">
                  {translation.applyFilterConfirmation}
                </Typography>
              </Popover>
              {filterIsExpression && (
                <CustomExpressionEditor
                  onChange={changeFixedFilter}
                  value={fixedFilterExpression}
                  config={config}
                  disableSwitcher={true}
                />
              )}
            </>
          ) : (
            <Box>
              {elementOptions.length && (
                <>
                  <Button
                    label={translation.addElementFilterButton}
                    onClick={handleAddElemensFilter}
                    fullWidth
                  />

                  {elFilterItems}
                </>
              )}
            </Box>
          )}
        </Section>
        {!filterIsExpression && filter && !elementsFilter.length && (
          <TableFilter
            fields={filter.fields}
            filter={
              (buildFixedFilterFromConfig(
                fixedFilter as FixedFilterGroup,
              ) as IFilterGroup | null) ?? defaultFilterValue
            }
            changeFilter={onChangeFilter}
            openDialogFilter={openDialogFilter}
            handleToggleDialogFilter={handleToggleDialogFilter}
          />
        )}
      </>
    );
  },
);
