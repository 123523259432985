import { memo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Element } from "core";
import IconButton from "elementTypes/common/IconButton";
import { NON_BREAKING_SPACE } from "elementTypes/common/utils";

import Button from "../common/Button";

import { DefaultModalDialogProps } from "./container";
import { useStyles } from "./styles";

const DefaultModalDialogComponent = memo<DefaultModalDialogProps>(
  ({
    element: {
      id,
      children: { content },
      i18n: { openButtonLabel, modalTitle },
    },
    openButtonDisabled,
    modalOpen,
    toggleModal,
  }) => {
    const { classes } = useStyles();

    const handleButtonClick = () => toggleModal();
    const handleClose = () => toggleModal();
    const handleDialogClose = (
      _ev: Record<string, unknown>,
      reason?: "backdropClick" | "escapeKeyDown",
    ) => {
      // do not automatically close when clicking outside of the dialog
      if (reason !== "backdropClick") {
        handleClose();
      }
    };

    return (
      <>
        <Button
          className={classes.root}
          onClick={handleButtonClick}
          aria-label="open"
          label={openButtonLabel}
          disabled={openButtonDisabled}
        />
        <Dialog
          open={modalOpen}
          onClose={handleDialogClose}
          aria-labelledby={`dialog-title-${id}`}
          fullWidth
          maxWidth="md"
          // allows the element panel inputs to be focused
          disableEnforceFocus
          // allows the element panel to be used in edit mode
          disablePortal
          classes={{ paper: classes.dialogContentPaper }}
        >
          <DialogTitle
            id={`dialog-title-${id}`}
            className={classes.dialogTitle}
          >
            {modalTitle || NON_BREAKING_SPACE}
            <IconButton
              aria-label="close"
              className={classes.dialogCloseIcon}
              onClick={handleClose}
              icon="close"
            />
          </DialogTitle>
          <DialogContent className={classes.dialogRoot}>
            {content.elements.map((el) => (
              <Element key={el.id} element={el} />
            ))}
          </DialogContent>
        </Dialog>
      </>
    );
  },
);

export default DefaultModalDialogComponent;
