import { Chip, Stack, Tooltip } from "@mui/material";
import { useAppEdition } from "queries/admin";
import { QUERIES_LIMIT } from "services/api/constants";
import { useSession } from "utils/hooks";
import { MuiIcon } from "../MuiIcon";

export const TrialLabel = () => {
  const isAdmin = useSession<"isAdmin">("isAdmin");
  const { data: isEnterprise, isLoading } = useAppEdition({
    enabled: !!isAdmin,
  });

  if (isEnterprise || isLoading) {
    return null;
  }

  return (
    <Tooltip title="You are using the Trial Version. App Features are limited.">
      <Chip label="Trial" size="small" color="warning" variant="filled" />
    </Tooltip>
  );
};

export const IsTrialTooltipTitle = ({
  title,
}: {
  title: string | JSX.Element;
}) => {
  const isAdmin = useSession<"isAdmin">("isAdmin");
  const { data: isEnterprise, isLoading } = useAppEdition({
    enabled: !!isAdmin,
  });

  if (isEnterprise || isLoading) {
    return <>{title}</>;
  }

  return (
    <Tooltip
      placement="top"
      title={`In the Trial Version, Queries are limited to ${QUERIES_LIMIT}.`}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        {title}
        <MuiIcon icon="info" color="warning" />
      </Stack>
    </Tooltip>
  );
};
