import { createAction, createActionTypeScoper } from "core/utils/redux";

export function buildActions(scope: string) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    VALUE_CHANGE: scopeActionType("VALUE_CHANGE"),
  };

  const actions = {
    changeValue: createAction(types.VALUE_CHANGE, (value: string | null) => ({
      value,
    })),
  };

  return { types, actions };
}

type R = ReturnType<typeof buildActions>;
export type Actions = R["actions"];
export type Types = R["types"];
