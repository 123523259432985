import { memo, useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Section, useElementEditorContext } from "core/editor";

import { UntransformedJsonInputConfigConfig } from "../../types";
import { useJsonInputEditorTranslation } from "../translation";

export const AdvancedComponent = memo(() => {
  const {
    elementModel: {
      config: { collapsed, enableClipboard },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedJsonInputConfigConfig>();
  const translation = useJsonInputEditorTranslation();

  const handleCollapsedInputChange = useCallback(
    () => changeConfigValue("collapsed", !collapsed),
    [changeConfigValue, collapsed],
  );
  const handleEnabledClipboardInputChange = useCallback(
    () => changeConfigValue("enableClipboard", !enableClipboard),
    [changeConfigValue, enableClipboard],
  );

  return (
    <Section title={translation.advancedTitle} wrapped={true}>
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(collapsed)}
            onChange={handleCollapsedInputChange}
          />
        }
        label={translation.collapsedLabel}
      />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(enableClipboard)}
            onChange={handleEnabledClipboardInputChange}
          />
        }
        label={translation.enabledClipboardLabel}
      />
    </Section>
  );
});
