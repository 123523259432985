import { IReduxModuleFactoryArgs } from "core";
import { buildSimpleUncontrolledInputModule } from "core/utils/buildSimpleUncontrolledInputModule";
import { AnyFluxStandardAction } from "core/utils/redux";

import { MultipleFileInput } from "../../types";
import { buildReducer } from "../controlled/reducer";
import { INITIAL_STATE as CONTROLLED_INITIAL_STATE } from "../controlled/reducer";
import { buildSaga } from "../controlled/saga";
import { Actions } from "../controlled/types";
import { IState } from "../controlled/types";

import { buildUncontrolledActions } from "./actions";
import { buildSelectors } from "./selectors";

export function uncontrolledReduxModuleFactory({
  path,
  element,
}: IReduxModuleFactoryArgs<MultipleFileInput>) {
  const INITIAL_STATE: IState = {
    ...CONTROLLED_INITIAL_STATE,
    value: element.config.defaultValue ?? null,
    errors: null,
    touched: false,
  };
  const uncontrolledModule = buildSimpleUncontrolledInputModule(
    element,
    path,
    INITIAL_STATE,
  );

  const { actions: uncontrolledActions, types: uncontrolledTypes } =
    buildUncontrolledActions(path);

  const actions = {
    ...uncontrolledActions,
    ...uncontrolledModule.actions,
  } as Actions;

  const types = {
    ...uncontrolledModule.types,
    ...uncontrolledTypes,
  };

  const selectors = {
    ...uncontrolledModule.selectors,
    ...buildSelectors(path, element),
  };

  const ownReducer = buildReducer(types, INITIAL_STATE);
  const reducer = <Action extends AnyFluxStandardAction>(
    state: IState,
    action: Action,
  ) => uncontrolledModule.reducer(ownReducer(state, action), action);

  const saga = buildSaga(actions, types, element, selectors);

  return {
    actions,
    selectors,
    saga,
    reducer,
  };
}

export type ReduxModule = ReturnType<typeof uncontrolledReduxModuleFactory>;
