import { HTMLAttributes, MutableRefObject, SyntheticEvent } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { AutocompleteOption } from "elementTypes/common/Autocomplete/types";
import { TableModel } from "elementTypes/default_table/types";

import { usePage } from "utils/hooks";
import { getNearestParentElement, useObjectViewList } from "../..";
import { buildCustomExpressionValue } from "../../..";
import { GeneralTypes, IObjectViewField } from "../../../types";
import { selectors as editorSelectors } from "../../reduxModule";

type Props = {
  id: string;
  value: string;
  onChange: (value: string | number | boolean) => void;
  allowedDataTypes?: GeneralTypes[];
  allowedDataTypeIsArray?: boolean;
  isFieldArray?: boolean;
  isTableElement?: MutableRefObject<boolean>;
};

function filterNonNullable<T>(t: T): t is NonNullable<T> {
  return t !== null && t !== undefined;
}

export function TableColumnEditor(props: Props) {
  const page = usePage();
  const updatedElements = useSelector(editorSelectors.updatedElements);
  const { getViewByName } = useObjectViewList();

  const nearestTable = getNearestParentElement(
    page!.element,
    updatedElements,
    props.id,
    "default_table",
  ) as TableModel | null;

  if (!nearestTable) {
    return null;
  }
  const { viewName, references } = nearestTable.config.dataSource;

  const sourceView = getViewByName(viewName);

  if (!sourceView) {
    return null;
  }

  if (props.isTableElement && sourceView && nearestTable) {
    props.isTableElement.current = true;
  }

  const referenceViewMapping = references
    ? Object.keys(references)
        .map((sourceFieldName) => {
          const referencedViewName = references[sourceFieldName].viewName;
          const referencedView = getViewByName(referencedViewName);

          const sourceField = sourceView.fields.find(
            (f) => f.name === sourceFieldName,
          );

          if (!referencedView || !sourceField) {
            return null;
          }

          return {
            sourceField,
            view: referencedView,
          };
        })
        .filter(filterNonNullable)
    : [];

  const isCorrectFieldType = (field: IObjectViewField) =>
    !!props.isFieldArray ||
    !props.allowedDataTypes ||
    (props.allowedDataTypes.includes(field.generalType.type) &&
      (props.allowedDataTypeIsArray === undefined ||
        props.allowedDataTypeIsArray === field.generalType.isArray));

  const options = sourceView.fields.map((f) => ({
    value: buildCustomExpressionValue(`props.data[${JSON.stringify(f.name)}]`),
    label: f.name,
    name: f.name,
    referenceField: null as string | null,
    disabled: !isCorrectFieldType(f),
  }));

  referenceViewMapping.forEach((v) => {
    options.push(
      ...v.view.fields.map((f) => {
        const referencesExpressionTextWithoutAccessor = `props.references[${JSON.stringify(
          v.sourceField.name,
        )}]`;

        const referencesExpressionText = `${referencesExpressionTextWithoutAccessor}[${JSON.stringify(
          f.name,
        )}]`;

        const referencesExpressionIsArray = `${referencesExpressionTextWithoutAccessor}.map((value) => value.${f.name})`;

        // used when the source field is nullable
        const nullableReferencesExpressionText = `${referencesExpressionTextWithoutAccessor} ? ${
          props.isFieldArray
            ? referencesExpressionIsArray
            : referencesExpressionText
        } : null`;

        const rawValue = v.sourceField.nullable
          ? nullableReferencesExpressionText
          : props.isFieldArray
            ? referencesExpressionIsArray
            : referencesExpressionText;

        const value = buildCustomExpressionValue(rawValue);

        return {
          value,
          name: f.name,
          label: f.name,
          referenceField: v.sourceField.name,
          disabled: !isCorrectFieldType(f),
        };
      }),
    );
  });

  const handleChange = (
    _e: SyntheticEvent<Element, Event>,
    valueOptions: AutocompleteOption,
  ) => {
    if (valueOptions !== null) {
      props.onChange(valueOptions.value);
    }
  };

  const defaultCurrentValue = {
    label: "CUSTOM EXPRESSION",
    name: "",
    referenceField: null,
    value: "",
    disabled: false,
  };

  const currentValue =
    options.find((o) => o?.value === props?.value?.trim()) ??
    defaultCurrentValue;

  return (
    <Autocomplete
      options={options}
      groupBy={(o) => o.referenceField ?? ""}
      getOptionLabel={(o) =>
        (o.referenceField ? o.referenceField + " → " : "") + o.label
      }
      getOptionDisabled={(o) => o.disabled}
      value={currentValue}
      disableClearable
      onChange={handleChange}
      renderOption={(
        liProps: HTMLAttributes<HTMLLIElement>,
        o: AutocompleteOption,
      ) => <li {...liProps}>{o.label}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          className="editor-table-column"
          label="Table Field"
          variant="outlined"
          helperText="Use this to autogenerate the expression"
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}
