import { memo } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { OptionsObject, SnackbarKey, useSnackbar } from "notistack";
import { connect } from "react-redux";
import { actions } from "core/session/reduxModule/actions";

const mapDispatchToProps = {
  removeSnackbar: actions.removeSnackbar,
};

type Props = typeof mapDispatchToProps & { snackbarKey?: SnackbarKey };

const Component = memo<Props>(({ snackbarKey, removeSnackbar }) => {
  const { closeSnackbar } = useSnackbar();
  const onClick = () => {
    closeSnackbar(snackbarKey);
    removeSnackbar(snackbarKey);
  };

  return (
    <Tooltip title="Dismiss">
      <IconButton
        color="inherit"
        size="small"
        sx={{ color: "primary.contrastText" }}
        centerRipple={true}
        onClick={onClick}
      >
        <ClearIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
});

const connector = connect(null, mapDispatchToProps);
const SnackbarComponent = connector(Component);

export const SnackbarDefaultAction = (key: OptionsObject["key"]) => (
  <SnackbarComponent snackbarKey={key} />
);
