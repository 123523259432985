import { Theme } from "@mui/material/styles";
import { keyframes } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

const smoothRotate3DY = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  20% {
    transform: rotateY(0deg);
  }
  80% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(360deg);
  }
`;

const getTransition = {
  transition: "transform 3s",
  animation: `${smoothRotate3DY} 3s ease-in-out 1`,
  transformOrigin: "center center", // Set the origin to the center
  backfaceVisibility: "hidden", // Hide the backface during rotation
};
// .st0{fill:#41404A;}
// .st1{fill:#4EC1EC;}

export const useStyles = makeStyles()((theme: Theme) => ({
  st0: {
    fill: theme.palette.primary.main,
    "&.animate": getTransition as any,
  },
  st1: {
    fill: theme.palette.secondary.main,
    "&.animate": getTransition as any,
  },
}));
