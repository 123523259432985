import { IDefaultElement, buildCustomExpressionValue } from "core";

import { UntransformedAdvancedConditionalConfig } from "../types";

const advancedGrid = (name: string) => ({
  i18n: {},
  name: `advanced_grid_${name}`,
  type: { name: "default_grid" },
  config: {},
  children: {
    content: {
      elements: [],
    },
  },
  position: {
    width: 1,
    height: 1,
    row: 1,
    column: 1,
  },
});

export const defaultElement: IDefaultElement<UntransformedAdvancedConditionalConfig> =
  {
    config: {
      value: buildCustomExpressionValue(true),
    },
    children: {
      content: {
        elements: [advancedGrid("true_value"), advancedGrid("false_value")],
      },
    },
  };
