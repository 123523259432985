import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";

import { ErrorsReport, errorsReport, reportWithPath } from "./errorReporter";

export type ValidationErrorObject = {
  name: string;
  message: string;
  errors: Record<string, ErrorsReport>;
};

export const createValidationError = <A>(
  validation: t.Validation<A>,
): ValidationErrorObject => ({
  name: "Validation Error",
  message: reportWithPath(validation),
  errors: errorsReport(validation),
});

// Modify the validate function to return either the correct type or an error object
export const validate = <T extends t.Mixed>(
  validator: T,
  value: unknown,
): T["_A"] | ValidationErrorObject => {
  const decodedValue = validator.decode(value);

  if (isLeft(decodedValue)) {
    return createValidationError(decodedValue);
  }

  return decodedValue.right;
};
