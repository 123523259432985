import isNumber from "lodash/isNumber";
import omit from "ramda/es/omit";
import { GeneralType, IObjectViewField } from "core";
import { FieldConfig } from "elementTypes/default_sub_form_table/types";
import { ROW_ID } from "./const";

export const validateValueByType = (type: string, value: any) => {
  switch (type) {
    case "boolean":
      return value !== undefined && value !== null;
    case "number":
      return isNumber(value);
    case "json":
      return value && Object.keys(value).length;
    default:
      return !!value?.trim().length;
  }
};

export const validateField = (field: IObjectViewField) => (nextValue: any) => {
  const {
    generalType: { type, isArray: valueIsArray },
    nullable,
  } = field;
  const required = nullable === false;

  if (!required) {
    return true;
  }

  if (valueIsArray) {
    return nextValue && Array.isArray(nextValue) && !!nextValue.length;
  } else {
    return validateValueByType(type, nextValue);
  }
};

// DATA

function replaceNaN(data: Record<string, unknown>) {
  const newData = { ...data };
  for (const key in newData) {
    const value = newData[key];
    if (typeof value === "number" && isNaN(value)) {
      newData[key] = null;
    }
  }
  return newData;
}

const defaultValues = {
  number: 0,
  text: "",
  dateTime: "",
  date: "",
  time: "",
  json: {},
  boolean: false,
  fallback: null,
  geo: null,
} as const;

const getDefaultValue = (generalType: GeneralType) =>
  generalType.isArray ? [] : defaultValues[generalType.type] ?? "";

export function fixRowData(
  data: Record<string, unknown>,
  fieldConfig: FieldConfig[],
) {
  // set null for all columns
  const defaultData = fieldConfig.reduce(
    (acc, next) => ({
      ...acc,
      [next.name]: getDefaultValue(next.generalType),
    }),
    {},
  );
  return { ...defaultData, ...replaceNaN(data) };
}

export const toDataGridRows = (
  data: Record<string, unknown>[],
): (Record<typeof ROW_ID, number> & Record<string, unknown>)[] =>
  data.map((row, idx) => ({ ...row, [ROW_ID]: idx }));

export const toDbDataRows = (data: Record<string, unknown>[]) =>
  data.map((row: Record<string, unknown>) =>
    omit(
      [ROW_ID],
      Object.entries(row).reduce(
        (res, [key, value]) => ({
          ...res,
          [key]: value === "" ? null : value,
        }),
        {} as Record<string, unknown>,
      ),
    ),
  );
