import { IDefaultElement } from "core/types/element";

import { BoolInputConfig } from "../types";

export const defaultElement: IDefaultElement<BoolInputConfig> = {
  i18n: {
    en: {
      label: "",
      labelLeft: "",
      labelRight: "",
    },
  },
};
