import { assocPath, dissocPath, path } from "ramda";
import { ASYNC_ACTION_INITIAL_STATE, handleActions } from "core/utils/redux";

import { IState, Types } from "./types";

const INITIAL_STATE: IState = {
  load: ASYNC_ACTION_INITIAL_STATE,
  save: ASYNC_ACTION_INITIAL_STATE,
  data: null,
  errors: {},
  touched: {},
  originalData: null,
  failedData: null,
  allowedStateChanges: [],
  stateFieldValue: null,
  saveAttemptsSinceReset: 0,
};

// Variable for mocking purposes.
let mockSubFormId = 0;

export function buildReducer(types: Types) {
  return handleActions<IState>(INITIAL_STATE, {
    [types.LOAD]: (state) => ({
      ...state,
      load: {
        inProgress: true,
        error: null,
      },
    }),
    [types.LOAD_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
      originalData: action.payload.data,
      load: {
        inProgress: false,
        error: null,
      },
    }),
    [types.LOAD_ERROR]: (state, action) => ({
      ...state,
      load: {
        inProgress: false,
        error: action.payload.error,
      },
    }),
    [types.SAVE]: (state) => ({
      ...state,
      save: {
        inProgress: true,
        error: null,
      },
      saveAttemptsSinceReset: state.saveAttemptsSinceReset + 1,
    }),
    [types.SAVE_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
      originalData: action.payload.data,
      failedData: null,
      save: {
        inProgress: false,
        error: null,
      },
    }),
    [types.SAVE_ERROR]: (state, action) => ({
      ...state,
      save: {
        inProgress: false,
        error: action.payload.error,
      },
    }),
    [types.RESET]: (state) => ({
      ...state,
      data: state.originalData,
      errors: {},
      touched: {},
      save: ASYNC_ACTION_INITIAL_STATE,
      failedData: null,
      saveAttemptsSinceReset: 0,
    }),
    [types.FIELD_VALUE_CHANGE]: (state, action) =>
      assocPath(
        ["data", ...action.payload.fieldPath],
        action.payload.value,
      )(state) as IState,
    [types.FIELD_TOUCHED_CHANGE]: (state, action) =>
      assocPath(
        ["touched", ...action.payload.fieldPath],
        action.payload.value,
      )(state) as IState,
    [types.SAVE_ERRORS]: (state, action) => ({
      ...state,
      errors: action.payload.errors,
      failedData: action.payload.failedData ?? state.failedData,
      save: {
        ...state.save,
        inProgress: false,
      },
    }),
    [types.SUB_FORM_ADD]: (state, action) => ({
      ...state,
      data: assocPath(
        action.payload.fieldPath,
        [
          ...(path(action.payload.fieldPath, state.data) as any[]),
          { foo: "new", id: (++mockSubFormId).toString() },
        ],
        state.data,
      ),
    }),
    [types.SUB_FORM_REMOVE]: (state, action) => ({
      ...state,
      data: dissocPath(action.payload.subFormPath, state.data),
    }),
    [types.SET_ALLOWED_STATE_CHANGES]: (state, action) => ({
      ...state,
      allowedStateChanges: action.payload.allowedStateChanges,
    }),
    [types.SET_STATE_FIELD_VALUE]: (state, action) => ({
      ...state,
      stateFieldValue: action.payload.stateFieldValue,
    }),
  });
}
