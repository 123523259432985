import { CSSProperties, memo } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { Translation } from "core";
import { useEditorTranslation } from "core/editor";
import IconButton from "elementTypes/common/IconButton";

import { FieldConfig } from "../../types";

import { useStyles } from "./styles";

type Props = {
  onClick: (params: FieldConfig) => void;
  onDelete: (name: string) => void;
  translateTitle: (i18n: Translation<"title">) => string | undefined;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  style?: CSSProperties;
} & FieldConfig;

export const FieldItem = memo<Props>(
  ({
    style,
    onClick,
    onDelete,
    translateTitle,
    provided,
    snapshot,
    ...props
  }) => {
    const { name, i18n } = props;
    const title = translateTitle(i18n) ?? name;
    const { editButton, deleteButton } = useEditorTranslation();
    const {
      classes: { draggingItem },
      cx,
    } = useStyles();
    const { isDragging } = snapshot;

    const handleEditClick = () => onClick(props as FieldConfig);
    const handleDelete = () => onDelete(name);

    return (
      <ListItem
        divider
        className={cx({ [draggingItem]: isDragging })}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          ...style,
          ...provided.draggableProps.style,
        }}
        ref={provided.innerRef}
        selected={isDragging}
      >
        <ListItemText primary={title} />
        <ListItemIcon>
          <IconButton
            icon="delete_outline"
            onClick={handleDelete}
            tooltip={deleteButton}
            color="error"
          />
          <IconButton
            icon="edit"
            onClick={handleEditClick}
            tooltip={editButton}
            edge="end"
          />
        </ListItemIcon>
      </ListItem>
    );
  },
);
