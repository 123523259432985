import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  xAxis: { width: "100%" },
  yAxis: { height: "100%" },
  stretch: {
    width: "100%",
    height: "100%",
  },
  contain: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  fullScreenImage: {
    height: `calc(100% - ${theme.spacing(6)})`,
    width: "100%",
    objectFit: "contain",
  },
  pointerFullScreen: {
    cursor: "pointer",
  },
  root: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  skeletonWrapper: {
    padding: theme.spacing(),
  },
  dropButton: {
    overflow: "unset",
    "&.dzu-dropzone": {
      minHeight: "40px",
    },
    marginBottom: theme.spacing(1),
  },
  activeBorder: {
    "&.dzu-dropzoneActive": {
      border: "2px dashed currentColor",
    },
  },
  none: {},
}));
