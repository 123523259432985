import { BaseSyntheticEvent, memo, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import Button from "elementTypes/common/Button";

import { useLoginStyles } from "./styles";
import { useLoginFormTranslation } from "./translation";
import { IUserTypeForm, IUserTypeFormData } from "./types";

const LoginForm = memo<IUserTypeForm>(
  ({ loggingIn, onSubmit, identifierTitle }) => {
    const {
      classes: { root, item, buttonContainer },
    } = useLoginStyles();
    const { control, handleSubmit } = useForm<IUserTypeFormData>();
    const t = useLoginFormTranslation();
    const [showPassword, setShowPassword] = useState(false);

    const onClick = () => setShowPassword(!showPassword);

    const handleFormSubmit = (data: any, e?: BaseSyntheticEvent<any>) => {
      e?.preventDefault();

      onSubmit(data);
    };

    return (
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container={true} spacing={2} className={root}>
          <Grid item={true} xs={12} className={item}>
            <Controller
              name="identifier"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={identifierTitle ?? t.identifierLabel}
                  fullWidth={true}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
              defaultValue=""
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item={true} xs={12} className={item}>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label={t.passwordLabel}
                  type={showPassword ? "text" : "password"}
                  fullWidth={true}
                  error={Boolean(error)}
                  helperText={error?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={onClick}
                          edge="end"
                          size="small"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
              defaultValue=""
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid
            container={true}
            item={true}
            xs={12}
            justifyContent="flex-end"
            className={buttonContainer}
          >
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              processing={loggingIn}
              disabled={loggingIn}
              iconRight="person"
            >
              {t.buttonLogin}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  },
);

export default LoginForm;
