import { IRawElementType } from "core";

import { EChartsTranslationKeys } from "../common/Echarts";

import Component from "./container";
import {
  EchartsBarChartEditor,
  defaultElement,
  editorMetadata,
} from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import { BarChartConfig, selectorTypes } from "./types";
import { getQueriesFromConfig } from "./utils";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "echarts_bar_chart",
  component: Component,
  editable: true,
  configType: BarChartConfig,
  translationKeys: EChartsTranslationKeys,
  defaultElement,
  editorComponent: EchartsBarChartEditor,
  editorMetadata,
  selectorTypes,
  getQueriesFromConfig,
};

export default elementType;
