import { types } from "../runtime-typing";
import { IApiError } from "../types";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type Optional<T> = { [P in keyof T]?: T[P] };

export type VariadicFn<A extends any[]> = (...args: A) => any;
export type ArgumentTypes<T> = T extends VariadicFn<infer A> ? A : never;

export const IAsyncActionState = types.interface({
  inProgress: types.boolean(),
  error: types.nullable(types.union([types.string(), IApiError])),
});

export type NonUndefined<T> = T extends undefined ? never : T;

export type Coalesce<T, Default> = T extends undefined | null ? Default : T;
