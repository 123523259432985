import { memo, useCallback, useMemo, useState } from "react";
import { useQueryGroups } from "queries/admin";
import { RoutePaths } from "staticPages/routes";
import { useQueryGroupsList, useRoute, useSnackbar } from "utils/hooks";
import {
  ModalProps,
  TQueriesPanelProvider,
  TSelectedQuery,
} from "../../components/QueriesPanel/types.ts";
import {
  ModalContentType,
  QueryGroup,
  QueryTypeFilterOptions,
  QueryViewOptions,
} from "../../components/types.ts";
import { useQueryGroupUtils } from "../../components/utils.tsx";
import { useDatabaseTranslation } from "../../translation.ts";
import { usePermissionContext } from "../databasePanelPermission/PermissionContext.utils.ts";
import { QueriesPanelProviderProps } from "../types.ts";
import { Provider } from "./QueriesPanelContext.utils.ts";
import { generateFetchQueryProps } from "./utils.ts";

export const QueriesPanelProvider = memo<QueriesPanelProviderProps>(
  ({ children, queries, isLoading, isInternal }) => {
    const [queriesModal, setQueriesModal] = useState<ModalProps | null>(null);
    const [queryType, setQueryType] = useState<QueryTypeFilterOptions | null>(
      null,
    );
    const [queryView, setQueryView] = useState<QueryViewOptions | null>(
      QueryViewOptions.group,
    );

    const { setPermissions } = usePermissionContext();

    const translation = useDatabaseTranslation();
    const snackbar = useSnackbar();
    const route = useRoute();

    const showSnackbar = useCallback(
      (message: string) => snackbar(message, "success"),
      [snackbar],
    );

    const { data: queryGroups = [] } = useQueryGroups({ enabled: !isInternal });
    const { searchQueries, searchQueryGroups } = useQueryGroupUtils();
    const { findQueryGroupByKey, queryGroupsByType, translatedQueryGroups } =
      useQueryGroupsList({
        queryGroups,
        queryType,
        queries,
      });

    const handleOpenModal = useCallback(
      (
        contentType: ModalContentType,
        nextSelected?: TSelectedQuery,
        nextSelectedQueryGroup?: QueryGroup,
      ) =>
        setQueriesModal({
          type: contentType,
          selected: nextSelected,
          selectedQueryGroup: nextSelectedQueryGroup,
        }),
      [setQueriesModal],
    );

    const handleCloseModal = useCallback(() => {
      setPermissions({});
      setQueriesModal(null);
    }, [setPermissions]);

    const handleOpenRowModal = useCallback(
      (type: ModalContentType) => (item: TSelectedQuery) =>
        handleOpenModal(type, item),
      [handleOpenModal],
    );

    const handleEditRow = useCallback(
      (isDefault: boolean, item: TSelectedQuery) => {
        isDefault
          ? handleOpenRowModal(ModalContentType.queryEdit)(item)
          : route("push", `${RoutePaths.DatabaseCustomQuery}/${item.name}`);
      },
      [handleOpenRowModal, route],
    );

    const fetchQueryProps = useMemo(
      () =>
        generateFetchQueryProps(
          translation,
          handleCloseModal,
          showSnackbar,
          queriesModal,
        ),
      [handleCloseModal, queriesModal, showSnackbar, translation],
    );

    const providerValue: TQueriesPanelProvider = {
      queries,
      showActions: !isInternal,
      isLoading,
      queryGroups,
      queryGroupsUtils: {
        queryGroupsByType,
        findQueryGroupByKey,
        searchQueries,
        searchQueryGroups,
        translatedQueryGroups,
      },
      queryProps: {
        queryType,
        setQueryType,
        queryView,
        setQueryView,
        fetchQueryProps,
      },
      queryRowProps: {
        handleEditRow,
        handleOpenRowModal,
      },
      modalProps: {
        modal: queriesModal,
        setModalOpen: setQueriesModal,
        handleCloseModal,
        handleOpenModal,
      },
    };

    return <Provider value={providerValue}>{children}</Provider>;
  },
);
