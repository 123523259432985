import { memo } from "react";
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  Chip,
  ChipProps,
  TextField,
} from "@mui/material";
import { omit } from "ramda";
import { SelectOption } from "elementTypes/default_autocomplete_input/types";

import { PropsFromRedux } from "./container";
import { useStyles } from "./style";

type Props = PropsFromRedux;

const DefaultArrayTextField = memo<Props>(
  ({
    values,
    element: {
      config: { maxLength, color, size, variant },
    },
  }) => {
    const {
      classes: { root },
    } = useStyles();

    const renderTags = (
      tags: SelectOption[],
      getTagProps: AutocompleteGetTagProps,
    ) =>
      (tags ?? []).map(
        ({ label: tagLabel, value: tagValue }: SelectOption, index: number) => {
          const chipProp = omit(
            ["onDelete"],
            getTagProps({ index }),
          ) as Partial<ChipProps>;

          return (
            <Chip
              key={`${index}-${tagValue}`}
              label={tagLabel}
              {...chipProp}
              color={color}
              variant={variant}
              size={size}
            />
          );
        },
      );

    const inputValue = (values ?? []).map((value) => ({
      label: String(value),
      value,
    }));

    const renderInput = (params: AutocompleteRenderInputParams) => (
      <TextField
        variant="standard"
        {...params}
        InputProps={{
          ...params.InputProps,
          disableUnderline: true,
          readOnly: true,
        }}
        className={root}
      />
    );

    return (
      <Autocomplete
        multiple
        open={false}
        limitTags={maxLength ?? 100}
        renderTags={renderTags}
        renderInput={renderInput}
        options={[]}
        value={inputValue}
        popupIcon=""
        clearIcon=""
        readOnly
      />
    );
  },
);

export default DefaultArrayTextField;
