import { ChangeEvent, memo, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import { getBooleanExpressionValue } from "core";
import { Section, useElementEditorContext } from "core/editor";

import { UntransformedAutocompleteInputConfig } from "../../types";
import { useAutocompleteEditorTranslation } from "../translation";

export const AdvancedComponent = memo(() => {
  const {
    elementModel: {
      config: {
        isClearable = true,
        fullTextSearch,
        autosuggestHighlight,
        virtualizedList,
        firstOptionSelected,
        nullable,
        disabled,
        optionsListSorted = true,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedAutocompleteInputConfig>();
  const translation = useAutocompleteEditorTranslation();
  const inputDisabled = Boolean(
    typeof disabled === "boolean"
      ? disabled
      : getBooleanExpressionValue(disabled),
  );

  useEffect(() => {
    if (nullable && !isClearable) {
      changeConfigValue("isClearable", true);
    }
  }, [nullable, isClearable, changeConfigValue]);

  const onChange = (
    { target: { name } }: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    switch (name) {
      case "withCheckbox":
        if (checked) {
          changeConfigValue("allowSameValue", undefined);
        }
        break;

      case "allowSameValue":
        if (checked) {
          changeConfigValue("withCheckbox", undefined);
        }
        break;
      case "firstOptionSelected":
        if (checked) {
          changeConfigValue("isClearable", false);
        }
        break;
    }

    changeConfigValue(
      name as keyof UntransformedAutocompleteInputConfig,
      checked,
    );
  };

  return (
    <Section title={translation.advancedTitle} wrapped={true}>
      <FormControl>
        <FormControlLabel
          control={
            <Switch
              data-testid="isClearableTest"
              checked={Boolean(isClearable)}
              onChange={onChange}
              disabled={nullable || inputDisabled || firstOptionSelected}
            />
          }
          label={translation.isClearableLabel}
          name="isClearable"
        />
        <FormHelperText disabled={!nullable} variant="outlined">
          {translation.nullableFlagHelperText}
        </FormHelperText>
      </FormControl>
      <FormControlLabel
        control={
          <Switch
            data-testid="fullTextSearchTest"
            checked={Boolean(fullTextSearch)}
            onChange={onChange}
            disabled={Boolean(disabled)}
          />
        }
        label={translation.fullTextSearchLabel}
        name="fullTextSearch"
      />
      <FormControlLabel
        control={
          <Switch
            data-testid="firstOptionSelectedTest"
            checked={Boolean(firstOptionSelected)}
            onChange={onChange}
            disabled={inputDisabled}
          />
        }
        label={translation.firstOptionSelectedLabel}
        name="firstOptionSelected"
      />
      <FormControlLabel
        control={
          <Switch
            data-testid="autosuggestHighlightTest"
            checked={Boolean(autosuggestHighlight)}
            onChange={onChange}
            disabled={inputDisabled}
          />
        }
        label={translation.autoSuggestHighlightLabel}
        name="autosuggestHighlight"
      />
      <FormControlLabel
        control={
          <Switch
            data-testid="virtualizedListTest"
            checked={Boolean(virtualizedList)}
            onChange={onChange}
            disabled={inputDisabled}
          />
        }
        label={translation.virtualizedListLabel}
        name="virtualizedList"
      />
      <FormControlLabel
        control={
          <Switch
            data-testid="optionsListSorted"
            checked={Boolean(optionsListSorted)}
            onChange={onChange}
            disabled={inputDisabled}
          />
        }
        label={translation.optionsListSorted}
        name="optionsListSorted"
      />
    </Section>
  );
});
