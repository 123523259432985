import { UseQueryOptions } from "@tanstack/react-query";
import { fileMetadata } from "services/api";
import { StorageFileMetadata } from "services/api/types/FileStorage.ts";
import { Query, useAuthenticatedQuery } from "../utils.ts";

import { QueryKeys } from "./queryKeys.ts";

const fetchFileByName: Query<
  StorageFileMetadata | undefined,
  Parameters<typeof fileMetadata>[1]
> = {
  queryKey: QueryKeys.fetchFileByName,
  queryFn: fileMetadata,
};

export const useFileByName = (
  params: Parameters<typeof fileMetadata>[1],
  options: UseQueryOptions<
    StorageFileMetadata | undefined,
    Error,
    StorageFileMetadata | undefined
  >,
) => useAuthenticatedQuery(fetchFileByName, params, options);
