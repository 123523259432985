import { makeStyles } from "tss-react/mui";

export const useErrorMessageStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(),
  },
}));

export const useRouterStyles = makeStyles()(() => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));
