import { HTMLAttributes } from "react";
import { ListItem, ListItemText, ListItemTextProps } from "@mui/material";
import { AutocompleteOption } from "./types";

export const defaultValueObject = (v: string | number): AutocompleteOption => ({
  value: v ?? "",
  label: v.toString() ?? "",
});

export type ValueObject = AutocompleteOption | AutocompleteOption[] | null;

export const getValueObject = (
  selectOptions: AutocompleteOption[],
  value?: string | string[] | boolean | number | number[] | null,
  isMulti?: boolean,
): ValueObject => {
  let valueObject: ValueObject = null;

  if (isMulti) {
    valueObject = [];
    if (value && !!(value as string[] | number[]).length) {
      // if value array contains option value existing option object will be pushed into select options array
      // otherwise it will be a default value object
      valueObject = (value as any[]).map((v: string | number) => {
        const existingOption = selectOptions.find((o) => o.value === v);
        return existingOption ?? defaultValueObject(v);
      });
    }
  } else {
    if (
      value !== "" &&
      value !== null &&
      value !== undefined &&
      !!selectOptions.length
    ) {
      //if select options array contains value will return existing option object
      // otherwise will convert the value to a default value object
      valueObject =
        selectOptions.find((o) => o.value === value) ??
        defaultValueObject(value as string);
    }
  }
  return valueObject;
};

export const getRenderOption =
  (
    key: string,
    secondaryTypographyProps?: ListItemTextProps["secondaryTypographyProps"],
  ) =>
  (props: HTMLAttributes<HTMLLIElement>, option: AutocompleteOption) => (
    <ListItem key={key} {...props}>
      <ListItemText
        primary={option.label}
        secondary={option[key]}
        secondaryTypographyProps={
          secondaryTypographyProps ?? {
            variant: "overline",
            display: "block",
          }
        }
      />
    </ListItem>
  );

export const customRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: AutocompleteOption,
) => (
  <ListItem {...props} key={String(option.value)}>
    <ListItemText
      primary={option.label}
      secondary={option.value}
      secondaryTypographyProps={{ variant: "overline", display: "block" }}
    />
  </ListItem>
);

export const defaultRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: AutocompleteOption,
) => (
  <ListItem {...props} key={String(option.value)}>
    <ListItemText primary={option.label} />
  </ListItem>
);
