import { createUseTranslation } from "core/session/translation";

export const colorInputEditorTranslation = {
  en: {
    stylingTitle: "Styling",
    labelLabel: "Label",
  },
};

export const useColorInputEditorTranslation = createUseTranslation(
  colorInputEditorTranslation,
);
