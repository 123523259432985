import { memo } from "react";
import { useSelector } from "react-redux";
import { useEditorMode } from "core/context/EditorMode/useEditorMode";
import { selectors as editorSelectors } from "core/editor/reduxModule";
import IconButton, { ButtonProps } from "elementTypes/common/IconButton";

import { RoutePaths } from "staticPages/routes";
import { useAdminButtonTranslation } from "./translations";

export const AdminButton = memo<ButtonProps>(({ color }) => {
  const { label } = useAdminButtonTranslation();
  const { isEditorModeEnabled } = useEditorMode();
  const isLayoutChanged = useSelector(editorSelectors.isLayoutChanged);

  const buttonProps = {
    icon: "admin_panel_settings",
    color,
    placement: "bottom" as const,
    fontSize: "large" as const,
    href: RoutePaths.Admin,
    target: isEditorModeEnabled ? "_blank" : undefined,
    ...(!isEditorModeEnabled && isLayoutChanged
      ? {
          disabled: true,
        }
      : {
          tooltip: label,
        }),
  } as const;

  return (
    <IconButton
      {...buttonProps}
      sx={{ color: buttonProps.color }}
      size="small"
    />
  );
});
