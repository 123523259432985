import { fold } from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import { validate } from "utils/io-ts";

import { DECODE_UTILS, INSTANTIATED } from "./constants";
import { ILocation } from "./router/reduxModule";
import {
  GetCurrentLanguage,
  GetModule,
  IElement,
  IElementModel,
  IElementProps,
  IElementType,
  IPage,
} from "./types";
import { DEFAULT_LANGUAGE_CODE } from "./types/i18n";
import { getDidYouMean } from "./utils/didYouMean";
import { getElementDisplay } from "./utils/element-utils";
import { hackStringType } from "./utils/io-ts";

hackStringType();

function fillDefaultI18nValues(
  element: IElementModel,
  translationType: NonNullable<IElementType["translationType"]>,
): IElementModel["i18n"] {
  return pipe(
    translationType.decode(element.i18n),
    fold(
      (errors) => {
        const intermediateI18n = {
          ...element.i18n,
          [DEFAULT_LANGUAGE_CODE]: {
            ...element.i18n[DEFAULT_LANGUAGE_CODE],
          },
        };
        for (const error of errors) {
          for (const { key, actual } of error.context) {
            if (actual === undefined) {
              intermediateI18n[DEFAULT_LANGUAGE_CODE][key] = "";
            }
          }
        }

        return intermediateI18n;
      },
      (decodedI18n) => decodedI18n,
    ),
  );
}

export function getElementInstanceId(
  element: IElementModel,
  props: IElementProps,
  scope: string,
  dynamic: boolean,
) {
  const idPrefix = scope ? `${scope}.` : "";
  if (dynamic) {
    if (props.key === undefined || props.key === null) {
      throw new Error(
        `Dynamic elements must be provided a 'key' prop. In ${getElementDisplay(
          element,
        )}`,
      );
    }
    return `${idPrefix}${element.id}.${props.key}`;
  }
  return `${idPrefix}${element.id}`;
}

export function instantiateElement(
  elementModel: IElementModel,
  elementType: IElementType,
  id: string,
  getModule: GetModule,
  getCurrentLanguage: GetCurrentLanguage,
  props: IElementProps,
  scope: string,
  location: ILocation,
  page: IPage | null,
): IElement {
  let config: any = {};
  let i18n: IElementModel["i18n"] = elementModel.i18n;

  if (elementType.translationType) {
    // set the i18n value to empty string when unset
    i18n = fillDefaultI18nValues(elementModel, elementType.translationType);
  }

  /**
   * TODO:
   * The whole element could be checked with an io-ts type, that checks config and children. See potential
   * performance issue in the TODO of types/elementChildren.
   */

  if (elementType.configType) {
    const decodeUtils = {
      getModule,
      getCurrentLanguage,
      page,
      element: { ...elementModel, i18n },
      location,
      id,
      props: createPropsProxy(props, elementModel),
    };
    config = validate(elementType.configType, {
      ...elementModel.config,
      [DECODE_UTILS]: decodeUtils,
    });
  } else if (Object.keys(elementModel.config).length) {
    throw new Error(
      `Invalid config values for ${getElementDisplay(
        elementModel,
      )}: Element type has no config type declared, but the following keys were provided: ${Object.keys(
        elementModel.config,
      ).join(", ")}\n\nFull config: ${JSON.stringify(elementModel.config)}`,
    );
  }

  if (elementType.childrenType) {
    validate((elementType as any).childrenType, elementModel.children);
  } else if (Object.keys(elementModel.children).length) {
    throw new Error(
      `Invalid children for ${getElementDisplay(
        elementModel,
      )}: Element type has no children type declared, but the following keys were provided: ${Object.keys(
        elementModel.children,
      ).join(", ")}`,
    );
  }

  // Issue: Can't debug with react devtools
  // https://github.com/facebook/react/issues/14709
  // const children = createChildrenProxy(element);
  const children = elementModel.children;

  return {
    ...elementModel,
    children,
    props,
    id,
    scope,
    config,
    i18n,
    originalId: elementModel.id.toString(),
    originalConfig: elementModel.config,
    [INSTANTIATED]: true,
  };
}

function createPropsProxy(props: IElementProps, element: IElementModel) {
  return new Proxy(
    {},
    {
      get: (_, prop) => {
        if (typeof prop === "symbol") {
          throw new Error("Cannot use a Symbol as a props accessor.");
        }
        const val = props[prop];
        if (val === undefined) {
          const didYouMean = getDidYouMean(Object.keys(props), prop.toString());
          throw new Error(
            `${getElementDisplay(
              element,
            )} has no prop named "${prop.toString()}".${didYouMean}`,
          );
        }
        return val;
      },
    },
  );
}

/*function createChildrenProxy(element: IElementModel) {
  const children = element.children;
  return new Proxy(
    {},
    {
      get: (_, prop) => {
        if (typeof prop === "symbol") {
          throw new Error("Cannot use a Symbol as a children accessor.");
        }
        const child = children[prop];
        if (child === undefined) {
          const didYouMean = getDidYouMean(
            Object.keys(children),
            prop.toString(),
          );
          throw new Error(
            `${getElementDisplay(
              element,
            )} has no prop named "${prop.toString()}".${didYouMean}`,
          );
        }
        return child;
      },
    },
  );
}*/
