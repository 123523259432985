import { createContext, useContext } from "react";

import { DEFAULT_LANGUAGE, Language } from "../../../types";

interface ILanguageSectionContext {
  lang: Language;
  changeLanguage: (lang: Language) => void;
}

/* DO NOT REFACTOR
 * We have to use a default value when the context is not provided
 * because of that we can't use createTypeSafeContext */
export const LanguageSectionContext = createContext<ILanguageSectionContext>({
  lang: DEFAULT_LANGUAGE,
  changeLanguage: (_lang: Language) => {
    throw new Error("Not implemented");
  },
});

export function useLanguageSectionContext() {
  const context = useContext(LanguageSectionContext);

  return context;
}

export const LanguageSectionProvider = LanguageSectionContext.Provider;
