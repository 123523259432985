import { memo } from "react";
import { Box, Stack, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import { useHookFormContext } from "elementTypes/common/HookForm/utils.ts";
import IconButton from "elementTypes/common/IconButton";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { PAGES_LIMIT } from "services/api/constants.ts";
import { useCommonStaticPagesTranslation } from "staticPages/admin/pages/apps/translation.ts";
import { ViewsTable } from "../../generate/components/ViewsTable.tsx";
import { TFormController } from "../../generate/types.ts";
import { IUIEditForm, TGeneratePages } from "../types.ts";

export const GeneratePages = memo<TGeneratePages>(
  ({ uiMetadata, isEnterprise }) => {
    const {
      trialPagesLimitLabel,
      generateQueriesLabel,
      generateHelperTooltip,
    } = useCommonStaticPagesTranslation();
    const {
      control,
      formState: { errors },
    } = useHookFormContext<Pick<IUIEditForm, "queries">>();

    const viewsListTable = ({ field, fieldState }: TFormController) => (
      <ViewsTable
        field={field}
        fieldState={fieldState}
        role={uiMetadata?.ui?.role}
        queryToPagesMapping={
          uiMetadata?.release?.definition.queryToPagesMapping
        }
        label={
          <Box display="flex" alignItems="center" gap={0.5}>
            <Typography variant="subtitle1" fontWeight={500}>
              {generateQueriesLabel}
            </Typography>
            <IconButton
              icon="help_outline"
              tooltip={generateHelperTooltip}
              placement="right"
            />
          </Box>
        }
      />
    );

    return (
      <Box display="flex" flexDirection="column" gap={1}>
        <FormControl fullWidth error={Boolean(errors?.queries)}>
          <Controller
            render={viewsListTable}
            name="queries"
            control={control}
            defaultValue={[]}
          />
          {!isEnterprise && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <MuiIcon icon="info" color="warning" />
              <Typography>{`${trialPagesLimitLabel} ${PAGES_LIMIT}.`}</Typography>
            </Stack>
          )}
        </FormControl>
      </Box>
    );
  },
);
