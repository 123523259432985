import { IObjectView, Translation } from "core/types";

export type Table = {
  name: string;
  schema: string;
  tableSize: number;
};

export type Schema = {
  name: string;
};

export type I18n = {
  title: string;
};

export type QueryObject = {
  id: string;
  name: string;
  i18n: Translation<"title">;
  autogenerated: boolean;
  saved_definition: string;
  current_definition: string;
};

export type DeleteQueryParams = {
  name: string;
};

export type QueryGroup = {
  id: number;
  i18n: Translation<"label">;
  queries: number[];
};

export type DeleteQueryGroup = {
  raw: Array<string>;
  affected_rows: number;
};

export type UIQueryGroups<T extends QueryObject | IObjectView = QueryObject> = {
  noGroup: T[];
  emptyGroups?: QueryGroup[];
} & {
  [key: string]: T[];
};

export type TQueryGroupUtils = {
  Common: {
    searchValue: string;
    queryType: QueryTypeFilterOptions | null;
  };
  GetQueryGroupName: {
    filter: QueryTypeFilterOptions | null;
  } & (
    | {
        type: "query";
        queryGroups: QueryGroup[];
        query: QueryObject;
      }
    | {
        type: "queryGroup";
        groupName?: string;
        uiQueryGroups: UIQueryGroups;
      }
  );
  SearchQueryGroups: {
    queryGroupData: UIQueryGroups<QueryObject>;
  } & TQueryGroupUtils["Common"];
  SearchQueries: {
    queriesByType: QueryObject[];
    queryGroups: QueryGroup[];
  } & TQueryGroupUtils["Common"];
};

export enum ModalContentType {
  queryPreview = "query_preview",
  definitionDiff = "definition_diff",
  queryDelete = "query_delete",
  queriesDelete = "queries_delete",
  createQueryGroup = "create_query_group",
  editQueryGroup = "edit_query_group",
  deleteQueryGroup = "delete_query_group",
  queryEdit = "query_edit",
}

export enum QueryTypeFilterOptions {
  default = "default",
  custom = "custom",
}

export enum QueryViewOptions {
  list = "list",
  group = "group",
}

export type TGroupForm = {
  i18n?: Translation<"label">;
  onClose: () => void;
};

export enum DatabasePanelType {
  entities = "entities",
  queries = "queries",
  procedures = "procedures",
}

export type TDatabasePanelContext = {
  type: DatabasePanelType;
  setType: (_ev: unknown, nextType: DatabasePanelType | null) => void;
};
