import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Boolean Field",
      description: "Display a boolean value",
    },
    de: {
      description: "Zeige einen boolschen Wert an",
    },
  },
};
