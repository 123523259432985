import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  dividerFooter: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(),
  },
}));

export default useStyles;
