import { IReduxModuleFactoryArgs } from "core";
import { buildSimpleUncontrolledInputModule } from "core/utils/buildSimpleUncontrolledInputModule";

import { JsonInput } from "../types";

import { controlledReduxModuleFactory } from "./controlledReduxModule";

const INITIAL_STATE = {
  value: null,
};

export function reduxModuleFactory({
  path,
  element,
}: IReduxModuleFactoryArgs<JsonInput>) {
  return element.config.dataSource
    ? controlledReduxModuleFactory(element)
    : buildSimpleUncontrolledInputModule(element, path, INITIAL_STATE);
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
