export const DEFAULTS = {
  min: 0,
  max: 100,
  step: 1,
  marks: false,
  valueLabelDisplay: "on",
  color: "primary",
  orientation: "horizontal",
  track: "normal",
} as const;
