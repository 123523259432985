import { ChangeEvent, memo, useMemo, useState } from "react";
import { Badge, Box, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useQueryGroups } from "queries/admin";
import { useQueries } from "queries/admin/modelBuilderData.ts";
import { Table } from "staticPages/admin/common/components/Table";
import { useDebouncedState } from "utils/hooks";
import { useHookFormError } from "utils/hooks/useHookFormError";
import { useViewsTableTranslation } from "../translation.ts";
import { TViewsTableProps } from "../types.ts";
import { Dialog } from "./Dialog.tsx";
import { RenderViews } from "./RenderViews.tsx";
import { generateTableConfig, useViewsTableContent } from "./utils.ts";
import { ViewsTableToolbar } from "./ViewsTableToolbar.tsx";

const ROWS_PER_PAGE_OPTIONS = [5, 10, 15];

export const ViewsTable = memo<TViewsTableProps>(
  ({
    fieldState: { error },
    field: { ref, value, onChange },
    label,
    role,
    queryToPagesMapping,
  }) => {
    const selectedQueries: string[] = value;

    const { data: queryGroupsData } = useQueryGroups();
    const queryGroups = useMemo(() => queryGroupsData ?? [], [queryGroupsData]);
    const [modal, setModal] = useState<{ name: string } | null>(null);
    const [searchValue, setSearchValue] = useState("");
    const translation = useViewsTableTranslation();
    const getErrorMessage = useHookFormError();
    const [currentPage, setCurrentPage] = useState(0);
    const [currentRows, setCurrentRows] = useState(ROWS_PER_PAGE_OPTIONS[0]);

    const {
      data: queries = [],
      isFetching,
      isInitialLoading,
      refetch,
    } = useQueries({ role: role ?? "" }, queryToPagesMapping);

    const isLoading = isFetching || isInitialLoading;

    const handleRefreshViewList = () => refetch();

    const [cachedSearchValue, cachedHandleSearch] = useDebouncedState(
      searchValue,
      setSearchValue,
      { delay: 200 },
    );

    const handleSearch = (newValue: string) => {
      setCurrentPage(0);
      cachedHandleSearch(newValue);
    };

    const handlePageChange = (_event: unknown, newPage: number) =>
      setCurrentPage(newPage);

    const handleRowsOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
      setCurrentRows(+event.target.value);
      setCurrentPage(0);
    };

    const handleShowDialog = (queryName: string) =>
      setModal({ name: queryName });

    const {
      tableConfig: subtableConfig,
      translatedQueryGroups,
      mappedQueries,
      filteredQueries,
      checkboxChecked,
      handleSelectQuery,
      handleSelectionChange,
    } = useViewsTableContent({
      queries,
      selectedQueries,
      queryGroups,
      searchValue,
      selectCallback: onChange,
    });

    const handleCloseDialog = () => setModal(null);

    const tableConfig = useMemo(
      () => generateTableConfig(translation),
      [translation],
    );

    const selectionProps = {
      field: "expand",
      checked: checkboxChecked,
      disabled: !filteredQueries.length,
      onChange: handleSelectionChange,
    };

    const renderViewsProps = {
      mappedQueries,
      translatedQueryGroups,
      selectedQueries,
      tableConfig: subtableConfig,
      handleSelectQuery,
      handleShowDialog,
    };

    const renderedViews = RenderViews(renderViewsProps);

    const paginationProps = {
      count: renderedViews.length,
      currentPage,
      rowsPerPage: currentRows,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      onPageChange: handlePageChange,
      onRowsPerPageChange: handleRowsOptionChange,
    };

    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={0.5}
          pb={1}
        >
          <Box display="flex" alignItems="center" gap={2}>
            {label ?? (
              <Typography variant="subtitle1">
                {translation.queriesLabel}
              </Typography>
            )}
            {!!selectedQueries.length && (
              <Tooltip
                title={translation.selectedQueriesLabel}
                placement="right"
              >
                <Badge badgeContent={selectedQueries.length} color="info" />
              </Tooltip>
            )}
          </Box>
          <ViewsTableToolbar
            isLoading={isLoading}
            setSearchValue={handleSearch}
            searchValue={cachedSearchValue}
            handleRefresh={handleRefreshViewList}
          />
        </Box>
        <Table
          headers={tableConfig}
          alignment={tableConfig}
          rows={renderedViews}
          error={getErrorMessage(error)}
          loading={isLoading}
          innerRef={ref}
          width="100%"
          stickyHeader
          pagination={paginationProps}
          selection={selectionProps}
          sx={{
            minHeight: "100px",
          }}
        />
        <Dialog modal={modal} handleCloseDialog={handleCloseDialog} />
      </>
    );
  },
);
