import { memo } from "react";
import { Box } from "@mui/material";
import { IElementComponentProps } from "core";

import { JsonView } from "../common/JsonView";

import { JsonField } from "./types";

interface IProps extends IElementComponentProps {
  value: Record<string, unknown>;
}

const DefaultJsonField = memo<
  IElementComponentProps<Record<string, unknown>, JsonField> & IProps
>(
  ({
    value,
    element: {
      config: { collapsed = true, enableClipboard = false },
    },
  }) => (
    <Box overflow="auto" maxHeight="100%">
      <JsonView
        value={value}
        collapsed={collapsed}
        enableClipboard={enableClipboard}
        embedded
      />
    </Box>
  ),
);

export default DefaultJsonField;
