import { IDefaultElement } from "core";

import { UntransformedDateTimeFieldConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedDateTimeFieldConfig> =
  {
    config: {
      value: "01/01/2020 00:00:00",
      showDatePart: true,
      showTimePart: true,
    },
  };
