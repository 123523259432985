import { memo, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  DEFAULT_LANGUAGE_CODE,
  Language,
  LayoutDefinition,
  Translation,
} from "core";
import { TranslationEditor } from "core/editor";

import { actions as routerActions } from "core/router/reduxModule";
import { useSessionContext } from "core/session";
import { AlertBox } from "elementTypes/common/AlertBox/index.tsx";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import BackButton from "elementTypes/common/BackButton";
import Button from "elementTypes/common/Button";
import { HookForm } from "elementTypes/common/HookForm";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";
import IconButton from "elementTypes/common/IconButton";
import { LayoutForm } from "elementTypes/common/LayoutDefinition";
import { generateLogoFileName } from "elementTypes/common/LayoutDefinition/utils.ts";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";

import { useAppEdition, useRoles } from "queries/admin";
import { useGenerateApp } from "queries/admin/appData";
import { useSaveFile } from "queries/admin/fileData.ts";
import { getApiError } from "queries/utils";
import { PAGES_LIMIT } from "services/api/constants";
import { FileForm } from "staticPages/admin/pages/files/pages/upload/types.ts";
import { RoutePaths } from "staticPages/routes";
import { PALETTE } from "utils/ci-colors.ts";
import { useHookFormError } from "utils/hooks/useHookFormError";
import { useSnackbar } from "utils/hooks/useSnackbar";

import { isFile } from "utils/other.ts";
import { useCommonStaticPagesTranslation } from "../../translation";
import { ViewsTable } from "./components/ViewsTable.tsx";

import useStyles from "./styles";
import { IUIGenerateForm, TFormController } from "./types";

const layoutDefinition = {
  logo: {
    url: "https://www.cybertec-postgresql.com",
  },
  menu: {
    hidden: false,
    defaultClosed: false,
  },
  theme: {
    shape: {
      borderRadius: 5,
    },
    palette: {
      primary: {
        main: PALETTE.PRIMARY,
      },
      secondary: {
        main: PALETTE.SECONDARY,
      },
    },
  },
  cookieBanner: {
    active: false,
  },
  customStyles: "",
  headerButtons: {
    color: "inherit",
  },
} as LayoutDefinition;

export const GenerateAppsPage = memo(() => {
  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();

  const translation = useCommonStaticPagesTranslation();

  const {
    classes: { dividerFooter },
  } = useStyles();

  const handleFetchError = (error: unknown) => {
    const msg = getApiError(error);
    showSnackbar(msg, "error");
  };

  const generateApp = useGenerateApp({
    onSuccess: () => {
      showSnackbar(translation.createAppMessage, "success");
      dispatch(routerActions.push(RoutePaths.Apps));
    },
    onError: handleFetchError,
  });

  const { mutateAsync: uploadFile } = useSaveFile({
    onError: handleFetchError,
  });

  const handleSubmit = (data: Record<string, unknown>) => {
    const { name, i18n, role, objectViews, ...rest } = data as IUIGenerateForm;

    const logoFile = (rest as IUIGenerateForm).logo?.fileName;
    let newLayoutDefinition = { ...rest };

    const createApp = (lDef: LayoutDefinition) =>
      generateApp.mutate({
        name: name.trim(),
        i18n,
        role,
        objectViews,
        layoutDefinition: lDef,
      });

    if (isFile(logoFile)) {
      const fileToUpload = generateLogoFileName(logoFile, name);
      const logoFileUpload: FileForm = {
        file: fileToUpload,
        groupName: "public",
        typeGroupName: "image",
      };
      uploadFile(logoFileUpload).then((res) => {
        newLayoutDefinition = {
          ...newLayoutDefinition,
          logo: {
            ...newLayoutDefinition.logo,
            fileName: res.fileName,
          },
        };

        createApp(newLayoutDefinition);
      });
    } else {
      createApp(newLayoutDefinition);
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" gap={1}>
        <BackButton isIcon href={RoutePaths.Apps} />
        <Typography variant="h5">{translation.generateAppTitle}</Typography>
      </Box>
      <Paper variant="outlined">
        <HookForm onSubmit={handleSubmit}>
          <Form />
          <Divider className={dividerFooter} />
          <FormButtons
            submitDisabled={false}
            isLoading={generateApp.isLoading}
          />
        </HookForm>
      </Paper>
    </>
  );
});

export const Form = memo(() => {
  const t = useCommonStaticPagesTranslation();
  const { control, watch, setValue } = useHookFormContext();
  const { data: isEnterprise } = useAppEdition();

  const defaultI18n = {
    [DEFAULT_LANGUAGE_CODE]: { description: "", label: "" },
  } as Translation<"label" | "description">;

  const rolesList = useRoles({
    onSuccess: (res) => setValue("role", res?.[0].name),
  });

  const role = watch("role");
  const i18n = watch("i18n") ?? defaultI18n;

  const getErrorMessage = useHookFormError();

  const rolesListItems = useMemo(
    () => rolesList?.data?.map(({ name }) => ({ value: name, label: name })),
    [rolesList.data],
  );

  const handleRefreshClick = () => rolesList.refetch();

  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);

  const queryListTable = ({ field, fieldState }: TFormController) => (
    <ViewsTable field={field} fieldState={fieldState} role={role} />
  );

  const changeTranslation =
    (cb: (value: Translation<string>) => void) => (value: Translation) => {
      setValue("i18n", value);
      cb(value);
    };

  return (
    <Box display="grid" gridTemplateColumns="2fr 1px 1fr">
      <Box>
        <Box
          p={0.5}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderBottom="1px solid"
          borderColor="divider"
        >
          <Typography variant="h6">{t.generalTitle}</Typography>
        </Box>
        <Box display="flex" gap={1} flexDirection="column" px={1}>
          <Controller
            control={control}
            name="name"
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t.applicationName}
                error={Boolean(error)}
                helperText={error?.message}
                fullWidth
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        icon="help_outline"
                        size="small"
                        tooltip="Once the app name is created, it's read-only. The unique identifier for the app  will be visible in the URL"
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            defaultValue=""
          />
          <Box mx={-1}>
            <Divider />
          </Box>
          <Stack direction="column" width="100%" spacing={1}>
            <LanguageSwitch
              language={lang}
              changeLanguage={setLang}
              colorVariant="dark"
              fullWidth={true}
            />
            <Controller
              render={({ field: { ref, onChange, ...inputProps } }) => (
                <TranslationEditor
                  translationKey="label"
                  i18n={i18n}
                  label="Label"
                  language={lang}
                  {...inputProps}
                  inputRef={ref}
                  changeTranslation={changeTranslation(onChange)}
                />
              )}
              name="i18n"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue={defaultI18n}
            />
            <Controller
              render={({
                field: { ref, onChange, ...inputProps },
                fieldState: { error },
              }) => (
                <TranslationEditor
                  translationKey="description"
                  i18n={i18n}
                  label="Description"
                  language={language}
                  {...inputProps}
                  inputRef={ref}
                  markdown
                  changeTranslation={changeTranslation(onChange)}
                  error={Boolean(error)}
                />
              )}
              name="i18n"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue={defaultI18n}
            />
          </Stack>
          <Box mx={-1} mt={0.65}>
            <Divider />
          </Box>
          <AlertBox color="info" message={t.appRoleWarning} />
          <Controller
            render={({
              field: { ref, ...fieldInput },
              fieldState: { error },
            }) => (
              <Autocomplete
                {...fieldInput}
                innerRef={ref}
                options={rolesListItems}
                label={t.applicationOwner}
                error={getErrorMessage(error)}
                disabled={!rolesList.data}
                startAdornment={
                  <>
                    {rolesList.isFetching || rolesList.isInitialLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <IconButton
                        size="small"
                        icon="refresh"
                        tooltip={t.generateRefreshTooltip}
                        onClick={handleRefreshClick}
                      />
                    )}
                  </>
                }
              />
            )}
            name="role"
            control={control}
            rules={{
              required: true,
            }}
            defaultValue={rolesList?.data?.[0].name ?? ""}
          />
          <FormControl fullWidth>
            <Controller
              render={queryListTable}
              name="objectViews"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue={[]}
            />
            {isEnterprise ? null : (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                color="info"
              >
                <MuiIcon icon="info" color="warning" />
                <Typography
                  color="warning"
                  variant="caption"
                >{`In the Trial Version, Pages are limited to ${PAGES_LIMIT}. Recommended Queries number is 3.`}</Typography>
              </Stack>
            )}
          </FormControl>
        </Box>
      </Box>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Box>
        <Box
          p={0.5}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderBottom="1px solid"
          borderColor="divider"
        >
          <Typography variant="h6">{t.layoutTitle}</Typography>
        </Box>
        <LayoutForm {...{ layoutDefinition, errors: {} }} />
      </Box>
    </Box>
  );
});

const FormButtons = memo<{ submitDisabled: boolean; isLoading?: boolean }>(
  ({ submitDisabled, isLoading }) => {
    const {
      formState: { isSubmitting },
    } = useHookFormContext();
    const t = useCommonStaticPagesTranslation();

    return (
      <Box display="flex" justifyContent="space-between" p={1}>
        <Button
          color={"secondary"}
          label={t.cancelButton}
          href={RoutePaths.Apps}
        />
        <Button
          color="primary"
          disabled={isSubmitting || submitDisabled || isLoading}
          processing={isSubmitting || isLoading}
          iconRight="forward"
          type="submit"
          label={t.generateLabel}
        />
      </Box>
    );
  },
);
