import { IElement } from "core/types/element";
import { selectorScoper } from "core/utils/redux";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces";
import { FormInputConfig } from "elementTypes/common";

import { IState } from "./types";

export function buildSelectors<
  T,
  Element extends IElement<
    WithOptionalFieldDataSourceConfig & FormInputConfig
  > = IElement<WithOptionalFieldDataSourceConfig & FormInputConfig>,
>(path: string[], element: Element) {
  const scopeSelector = selectorScoper<IState<T>>(path);

  const value = (state: any) => scopeSelector(state).value;
  const errors = (state: any) => scopeSelector(state).errors;
  const touched = (state: any) => scopeSelector(state).touched;
  const disabled = (state?: any) =>
    typeof element.config.disabled === "boolean"
      ? element.config.disabled
      : element.config.disabled?.(state);

  const required = () => element.config.nullable === false;

  return {
    value,
    errors,
    touched,
    disabled,
    required,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
