import { SyntheticEvent, memo, useCallback, useMemo, useState } from "react";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

import {
  DataGrid,
  GridActionsCellItem,
  GridEnrichedColDef,
  GridEventListener,
  GridPreProcessEditCellProps,
  GridRowHeightReturnValue,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid";
import { GridRowModesModelProps } from "@mui/x-data-grid/models/api/gridEditingApi";

import { GeneralTypes } from "core";
import { useSessionContext } from "core/session";
import { getTranslatedText } from "core/utils/element-utils";

import { ROW_ID } from "./const";
import { CustomEditCell } from "./CustomEditCell";
import { CustomRenderCell } from "./CustomRenderCell";
import { useTableStyles } from "./styles";
import { TableHeader } from "./TableHeader";
import { ITableFormProps } from "./types";
import {
  fixRowData,
  toDataGridRows,
  toDbDataRows,
  validateField,
} from "./utils";

const gridColumnType = {
  text: "string",
  time: "dateTime",
  fallback: "text",
};

type GridColumnType = keyof typeof gridColumnType;

function instanceOfgridColumnType(type: string): type is GridColumnType {
  return type in gridColumnType;
}

const customCell = (type: GeneralTypes) => ["json"].includes(type);

export const TableForm = memo<ITableFormProps>(
  ({
    data,
    fields,
    title,
    disabled,
    errors,
    columnVisibilityModel,
    selected,
    changeSelectionModel,
    onDataUpdate,
  }) => {
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const { language } = useSessionContext();

    const rows = toDataGridRows(data);

    const {
      classes: { root },
    } = useTableStyles();

    const handleUpdateData = useCallback(
      (nextData: Record<string, unknown>[]) =>
        onDataUpdate(toDbDataRows(nextData ?? [])),
      [onDataUpdate],
    );

    const handleRowEditStart = (
      _params: GridRowParams,
      event: MuiEvent<SyntheticEvent>,
    ) => {
      event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<"rowEditStop"> = (
      _params,
      event,
    ) => {
      event.defaultMuiPrevented = true;
    };

    const handleEditClick = (rowId: GridRowId) => () => {
      setRowModesModel((prevModel) => ({
        ...prevModel,
        [rowId]: { mode: GridRowModes.Edit },
      }));
    };

    const handleSaveClick = useCallback(
      (rowId: GridRowId) => () => {
        setRowModesModel((prevModel) => ({
          ...prevModel,
          [rowId]: { mode: GridRowModes.View, isNew: false },
        }));
      },
      [],
    );

    const handleDeleteClick = useCallback(
      (rowId: GridRowId) => () => {
        const nextData = rows.filter((row) => {
          if (selected?.field && row[selected?.field] === selected.value) {
            changeSelectionModel(null, null);
          }
          return row[ROW_ID] !== rowId;
        });
        handleUpdateData(nextData);
      },
      [rows, selected, handleUpdateData, changeSelectionModel],
    );

    const handleCancelClick = useCallback(
      (rowId: GridRowId) => () => {
        setRowModesModel((prevModel) => ({
          ...prevModel,
          [rowId]: { mode: GridRowModes.View, ignoreModifications: true },
        }));

        const editedRow = rowModesModel[rowId] as GridRowModesModelProps & {
          isNew?: boolean;
        };
        if (editedRow?.isNew) {
          handleDeleteClick(rowId)();
        }
      },
      [rowModesModel, handleDeleteClick],
    );

    const processRowUpdate = (newRow: GridRowModel) => {
      handleUpdateData(
        rows.map((r) => (r[ROW_ID] === newRow[ROW_ID] ? newRow : r)),
      );
      return newRow;
    };

    const handleSelectRow = ({ row }: GridRowParams) =>
      changeSelectionModel(selected?.field ? row[selected?.field] : null, row);

    const handleAddRow = () => {
      const newRowID = rows.length;
      const nextData = [...rows, fixRowData({}, fields)];

      onDataUpdate(nextData);

      setTimeout(
        () =>
          setRowModesModel((oldModel) => ({
            ...oldModel,
            [newRowID]: { mode: GridRowModes.Edit, isNew: true },
          })),
        300,
      );
    };

    const onRowModesModelChange = (newModel: GridRowModesModel) =>
      setRowModesModel(newModel);

    const getRowId = (r: Record<string, unknown>): number =>
      r[ROW_ID] as number;

    const getRowHeight = () => "auto" as GridRowHeightReturnValue;

    const selectedRow = rows.find(
      (r) => selected?.field && r[selected.field] === selected.value,
    );

    const columns = useMemo(
      () => [
        ...(fields.map((field) => {
          const columnType = field.generalType.type;
          const adaptedType = instanceOfgridColumnType(columnType)
            ? gridColumnType[columnType]
            : columnType;
          return {
            field: field.name,
            headerName:
              getTranslatedText(language, field.i18n, "title") ?? field.name,
            type: field.generalType.isArray
              ? `array-${adaptedType ?? field.generalType.type}`
              : adaptedType ?? field.generalType.type,
            isArray: field.generalType.isArray,
            required: field.nullable === false,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => ({
              ...params.props,
              error: !validateField(field)(params.props.value),
            }),
            flex: 1,
            editable: !disabled,
            align: "left",
            headerAlign: "left",
            ...(field?.generalType?.type === "json" && {
              minWidth: 250,
              minHeight: 100,
            }),
            ...((customCell(field?.generalType.type) ||
              field?.generalType.isArray) && {
              renderCell: (rc) => <CustomRenderCell {...rc} />,
              renderEditCell: (re) => <CustomEditCell {...re} />,
            }),
          };
        }) as GridEnrichedColDef[]),
        {
          field: "actions",
          type: "actions",
          headerName: "Actions",
          width: 100,
          getActions: ({ id: rowId }: GridRowParams) => {
            const isInEditMode =
              rowModesModel[rowId]?.mode === GridRowModes.Edit;

            const nonEditBtns = [
              <GridActionsCellItem
                key="GridActionsCellItemEditIcon"
                icon={<EditIcon />}
                onClick={handleEditClick(rowId)}
                label="Edit"
                {...({
                  "data-testid": "edit-subform-table-row",
                } as any)}
              />,
              <GridActionsCellItem
                key="GridActionsCellItemDeleteIcon"
                icon={<DeleteIcon />}
                onClick={handleDeleteClick(rowId)}
                label="Delete"
                {...({
                  "data-testid": "delete-subform-table-row",
                } as any)}
              />,
            ];

            const editBtns = [
              <GridActionsCellItem
                key="GridActionsCellItemCancelIcon"
                icon={<CancelIcon />}
                onClick={handleCancelClick(rowId)}
                label="Cancel"
                {...({
                  "data-testid": "cancel-subform-table-row",
                } as any)}
              />,
              <GridActionsCellItem
                key="GridActionsCellItemSaveIcon"
                icon={<SaveIcon />}
                onClick={handleSaveClick(rowId)}
                label="Save"
                {...({
                  "data-testid": "save-subform-table-row",
                } as any)} // TODO: how to set properly data-testid
              />,
            ];

            return isInEditMode ? editBtns : nonEditBtns;
          },
        },
      ],
      [
        fields,
        language,
        disabled,
        rowModesModel,
        handleCancelClick,
        handleSaveClick,
        handleDeleteClick,
      ],
    );

    return (
      <DataGrid
        {...{
          columns,
          rows,
          columnVisibilityModel,
          rowModesModel,
          onRowModesModelChange,
          processRowUpdate,
          getRowId,
          editMode: "row",
          onRowEditStart: handleRowEditStart,
          onRowEditStop: handleRowEditStop,
          experimentalFeatures: { newEditingApi: true },
          onRowClick: handleSelectRow,
          getRowHeight,
        }}
        {...(selectedRow && {
          selectionModel: [selectedRow[ROW_ID] as GridRowId],
        })}
        components={{
          Toolbar: TableHeader,
        }}
        componentsProps={{
          toolbar: {
            title,
            disabled,
            errors,
            onRowAdd: handleAddRow,
          },
        }}
        className={root}
        // added custom column types
        columnTypes={{
          "array-number": {},
          "array-string": {},
          json: {},
        }}
      />
    );
  },
);
