import { createUseTranslation } from "core/session/translation";

export const settingsTranslation = {
  en: {
    logoPathLabel: "Logo Path",
    identifierInputTitleLabel: "Identifier Input Title",
  },
};

export const useSettingsTranslation = createUseTranslation(settingsTranslation);
