import { createUseTranslation } from "core/session/translation";

export const jsonInputEditorTranslation = {
  en: {
    stylingTitle: "Styling",
    labelLabel: "Label",
    advancedTitle: "Advanced",
    collapsedLabel: "Collapsed",
    enabledClipboardLabel: "Enabled Clipboard",
  },
};

export const useJsonInputEditorTranslation = createUseTranslation(
  jsonInputEditorTranslation,
);
