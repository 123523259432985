import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { AllServices } from "core/buildStore";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { getServerError } from "core/utils/api";
import { createWatcherSaga } from "core/utils/saga";
import {
  buildFixedFilterFromConfig,
  mapParamsToApiServiceParams,
} from "elementTypes/default_table/reduxModule/utils";
import { IFilterGroup } from "elementTypes/default_table/toolsPanel/types";

import { CustomChart } from "../types";

import { Actions, Types } from "./types";

export function buildSaga(
  actions: Actions,
  types: Types,
  element: CustomChart,
) {
  const { dataSource, filter: configFilter, sort, limit } = element.config;

  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);
    let filter: IFilterGroup | null = null;
    let configFilterValue = null;

    try {
      if (configFilter) {
        configFilterValue = (yield select(configFilter)) as ReturnType<
          typeof configFilter
        >;

        filter = buildFixedFilterFromConfig(
          configFilterValue,
        ) as IFilterGroup | null;
      }

      if (dataSource.viewName !== "") {
        const data: any[] = yield call(services.api.loadViewData, token, {
          viewName: dataSource.viewName,
          params: mapParamsToApiServiceParams({
            limit: limit ?? 1000,
            filter,
            offset: 0,
            order: sort ?? null,
          }),
        });
        yield put(actions.loadSuccess(data));
      } else {
        yield put(actions.loadSuccess(null));
      }
    } catch (error) {
      yield put(actions.loadError(getServerError(error)));
    }
  }

  function* callLoad() {
    yield put(actions.load());
  }

  return function* mainSaga() {
    yield all([
      takeLatest(types.LOAD, loadSaga),
      createWatcherSaga(configFilter, {
        onChange: callLoad,
      }),
    ]);

    yield put(actions.load());
  };
}
