import Component from "./container";
import reduxModuleFactory from "./reduxModule";
import { ProcedureButtonConfig, ProcedureButtonTranslationKeys } from "./types";

export default {
  reduxModuleFactory,
  name: "default_procedure_button",
  component: Component,
  editable: true,
  configType: ProcedureButtonConfig,
  translationKeys: ProcedureButtonTranslationKeys,
};
