import { Type } from "./Type";
import { TypeError } from "./TypeError";

export class NumberType extends Type {
  public name = "number";

  public validate(value: any): true | TypeError {
    return typeof value === "number" ? true : new TypeError(value, this);
  }
}
