import { createUseTranslation } from "core/session/translation";

export const useColorsTranslation = createUseTranslation({
  en: {
    primary: "Primary",
    secondary: "Secondary",
    success: "Success",
    error: "Error",
    warning: "Warning",
    info: "Info",
    default: "Default",
    lighter: "Lighter",
    disabled: "Disabled",
    white: "White",
    black: "Black",
    transparent: "No Color",
  },
});
