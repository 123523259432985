import { memo } from "react";
import {
  Box,
  Card,
  Divider,
  FormControl,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import { Controller } from "react-hook-form";
import Button from "elementTypes/common/Button";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";
import IconButton from "elementTypes/common/IconButton";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { PermissionAutocomplete } from "../../../components/PermissionAutocomplete";
import { StyledStateName } from "../../../components/StyledStateName";
import { useFocusWorkflowNode, useHandleSelectElement } from "../../utils";
import { useStateFormTranslations } from "./translation";
import { UIStateForm } from "./types";

export const FormContent = memo<{
  node?: UIStateForm;
  inProgress?: boolean;
  onDelete?: () => void;
}>(({ node, inProgress, onDelete }) => {
  const { control, watch } = useHookFormContext();
  const setSelectedElement = useHandleSelectElement();

  const handleClose = () => setSelectedElement(null);

  const t = useStateFormTranslations();

  const isEditing = Boolean(node);

  const isEditable = watch("isEditable") ?? node?.isEditable ?? true;

  const title = (
    <Typography variant="h6" noWrap>
      {node ? t.editState : t.createState}
    </Typography>
  );

  const onFocus = useFocusWorkflowNode();
  const handleFocus = () => onFocus(node?.id ?? "newElement");

  return (
    <Card
      variant="outlined"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      component="aside"
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        gap={1}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <IconButton
            onClick={handleClose}
            tooltip={t.cancelButton}
            icon="arrow_back"
          />
          {title}
        </Box>
        {node && (
          <Box flex={1}>
            <StyledStateName stateName={node.state} />
          </Box>
        )}
        <IconButton
          onClick={handleFocus}
          tooltip={t.focusButtonTooltip}
          icon="center_focus_strong"
        />
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        width="100%"
      >
        <Box
          py={1}
          px={1.5}
          display="flex"
          flexDirection="column"
          flex={1}
          gap={1}
        >
          <FormControl fullWidth>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label={t.titleLabelField}
                  autoComplete={"off"}
                  error={Boolean(error)}
                  size="small"
                  {...field}
                />
              )}
              control={control}
              name="title"
              defaultValue={node?.title ?? ""}
              rules={{
                required: true,
              }}
            />
          </FormControl>
          {!node && (
            <FormControl fullWidth>
              <Controller
                render={({ field }) => (
                  <TextField
                    label={t.stateLabelField}
                    fullWidth={true}
                    size="small"
                    InputProps={{
                      readOnly: isEditing,
                    }}
                    {...field}
                  />
                )}
                control={control}
                name="state"
                defaultValue=""
              />
            </FormControl>
          )}
          <FormControl fullWidth>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label={t.descriptionLabelField}
                  autoComplete={"off"}
                  error={Boolean(error)}
                  size="small"
                  {...field}
                />
              )}
              control={control}
              name="shortDescription"
              defaultValue={node?.shortDescription ?? ""}
            />
          </FormControl>

          <Controller
            render={({ field: { ref, ...field } }) => {
              const handleChange = (_: any, value: boolean) =>
                field.onChange(!value);

              return (
                <Tooltip title={t.editableTitle} placement="left">
                  <ToggleButton
                    {...field}
                    ref={ref}
                    onChange={handleChange}
                    selected={!!field.value}
                    size="small"
                    color="primary"
                    sx={{
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <MuiIcon icon={field.value ? "check" : "block"} />
                    {t.editableLabel}
                  </ToggleButton>
                </Tooltip>
              );
            }}
            name="isEditable"
            control={control}
            defaultValue={node?.isEditable ?? true}
          />

          {isEditable && <PermissionAutocomplete acl={node?.acl} />}
        </Box>
        <Divider />
        <Box p={1} display="flex" justifyContent="space-between" gap={1}>
          {node ? (
            <Button
              onClick={onDelete}
              label={t.deleteState}
              color="error"
              disabled={inProgress}
            />
          ) : (
            <span />
          )}
          <Button
            label={t.submitButton}
            type="submit"
            color="primary"
            processing={inProgress}
          />
        </Box>
      </Box>
    </Card>
  );
});
