import { IDefaultElement } from "core";

import { UntransformedTableConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedTableConfig> = {
  config: {
    dataSource: {
      viewName: "",
    },
    filter: { fields: [] },
    createButton: {
      enabled: false,
      linkTo: {
        pageId: "",
        params: {},
      },
    },
  },
  children: {
    header: {
      elements: [],
    },
    body: {
      elements: [],
    },
  },
  i18n: {
    en: {
      createButtonLabel: "Create",
    },
    de: {
      createButtonLabel: "Neu",
    },
  },
};
