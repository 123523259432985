import { useEffect, useRef } from "react";
import { SnackbarKey, useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import { actions, selectors } from "../reduxModule";

export const Notifier = () => {
  const displayed = useRef<SnackbarKey[]>([]);
  const dispatch = useDispatch();
  const notifications = useSelector(selectors.notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed.current = [...displayed.current, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed.current = [...displayed.current.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }) => {
        if (!key) {
          return;
        }
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.current.includes(key)) {
          return;
        }

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (_event, myKey) => {
            // remove this snackbar from redux store
            dispatch(actions.removeSnackbar(myKey));
            removeDisplayed(myKey);
          },
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      },
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};
