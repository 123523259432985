import { memo, useMemo } from "react";
import { Element } from "core";

import { ChildElementModel } from "../component";
import { sortElements } from "../utils";

import Cell from "./Cell";
import { useStaticGridStyles } from "./styles";

type Props = {
  elements: ChildElementModel[];
  elementProps: Record<string, any>;
  isFormGrid: boolean;
};

export const DEFAULT_GRID_COLUMN_COUNT = 12;

export const GRID_SPACING_FACTOR = 1;

export const StaticGrid = memo<Props>(
  ({ elements, elementProps, isFormGrid }) => {
    const {
      classes: { root },
    } = useStaticGridStyles({ isFormGrid });
    const items = useMemo(
      () =>
        sortElements(elements).map((child: any) => (
          <Cell {...child.position} key={child.id}>
            <Element element={child} elementProps={elementProps} />
          </Cell>
        )),
      [elements, elementProps],
    );

    return <div className={root}>{items}</div>;
  },
);
