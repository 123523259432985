import { memo, useCallback, useMemo, useState } from "react";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { formatDistanceToNow, formatRelative } from "date-fns";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { StyledTypography } from "elementTypes/common/StyledTypography";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { useDeleteFile, useFiles } from "queries/admin/fileData";
import { getApiError } from "queries/utils";
import { FILE_STORAGE_PREFIX } from "services/api/constants";
import { StorageFileMetadata } from "services/api/types/FileStorage.ts";
import { RoutePaths } from "staticPages/routes";
import { useSnackbar } from "utils/hooks/useSnackbar";

import { TableRow } from "../../common";
import { Table } from "../../common/components/Table";
import useStyles from "../../styles";

import { useCommonStaticPagesTranslation } from "./translation";

type Row = StorageFileMetadata & {
  handleDelete: (id: string | number) => void;
};

const Row = memo<Row>(
  ({
    id,
    realName,
    fileType,
    createdAt,
    typeGroup: { typeName },
    fileGroup: { name: groupName },
    fileName,
    handleDelete,
  }) => {
    const {
      classes: { inlineAndEndFlex },
    } = useStyles();
    const { deleteButton, editFileTitle } = useCommonStaticPagesTranslation();
    const onDelete = () => handleDelete(id);

    return (
      <>
        <TableRow rowId={id}>
          <Typography>{realName}</Typography>
          <Typography>{fileType}</Typography>
          <Tooltip title={formatRelative(new Date(createdAt), new Date())}>
            <Typography>
              {formatDistanceToNow(new Date(createdAt), {
                addSuffix: true,
              })}
            </Typography>
          </Tooltip>
          <Typography>{typeName}</Typography>
          <Chip
            icon={
              <MuiIcon
                icon={groupName === "private" ? "security" : "visibility"}
              />
            }
            label={groupName}
            color={groupName === "private" ? "default" : "success"}
            variant="outlined"
          />
          <Grid item={true} className={inlineAndEndFlex}>
            <Link href={`${FILE_STORAGE_PREFIX}${fileName}`} target="_blank">
              <IconButton
                icon="open_in_new"
                color="secondary"
                tooltip="Open"
                placement="top"
              />
            </Link>
            <IconButton
              icon="edit"
              color="secondary"
              href={RoutePaths.FileEditByName(fileName)}
              tooltip={editFileTitle}
              placement="top"
            />
            <IconButton
              color="secondary"
              icon="delete_outline"
              onClick={onDelete}
              tooltip={deleteButton}
              placement="top"
            />
          </Grid>
        </TableRow>
      </>
    );
  },
);

const getValueByKey = (
  data: StorageFileMetadata[],
  value: string | number | null,
  key: keyof StorageFileMetadata,
) => String(data.find((file) => file.id === value)?.[key]);

export const FilesPageComponent = memo(() => {
  const {
    classes: { horizontallyCenter, inlineAndEndFlex },
  } = useStyles();

  const translation = useCommonStaticPagesTranslation();

  const showSnackbar = useSnackbar();

  const files = useFiles();

  const [deleteInfo, setDeleteInfo] = useState<string | number | null>(null);

  const deleteFile = useDeleteFile({
    onSuccess: () => {
      files.refetch();
      showSnackbar(translation.deletedMessage, "success");
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const getRealName = getValueByKey(files.data ?? [], deleteInfo, "realName");

  const handleDeleteFile = useCallback(() => {
    if (files.data) {
      deleteFile.mutate({
        fileName: getValueByKey(files.data ?? [], deleteInfo, "fileName"),
      });
    }
    setDeleteInfo(null);
  }, [files.data, deleteFile, deleteInfo]);

  const handleCloseDialog = () => {
    setDeleteInfo(null);
  };

  const titles = [
    translation.tableNameLabel,
    translation.tableFileTypeLabel,
    translation.tableUploadLabel,
    translation.tableTypeGroupLabel,
    translation.tableAccessGroupLabel,
    translation.tableActionsLabel,
  ];

  const rows = useMemo(
    () =>
      files.data?.map((file) => (
        <Row key={file.id} handleDelete={setDeleteInfo} {...file} />
      )),
    [files.data],
  );

  const dataRefetch = () => files.refetch();

  const headers = titles.map((title) => ({
    name: title.toLowerCase(),
    title,
  }));

  return (
    <Grid container spacing={2} className={horizontallyCenter}>
      <Grid item xs={12} sm={8}>
        <Typography variant="h5">{translation.pageTitle}</Typography>
      </Grid>
      <Grid item xs={12} sm={4} className={inlineAndEndFlex}>
        <Button
          color="primary"
          label={translation.uploadLabel}
          iconLeft="cloud_upload"
          href={RoutePaths.FilesUpload}
        />
      </Grid>
      <Grid item xs={12}>
        <Table
          rows={rows}
          headers={headers}
          onDataReload={dataRefetch}
          loading={files?.isInitialLoading}
          error={files.error?.toString()}
        />
      </Grid>
      <DialogWrapper
        open={!!deleteInfo}
        title={translation.dialogTitle}
        contentText={
          <>
            {translation.dialogContentText}{" "}
            <StyledTypography text={getRealName} color="primary" fitContent />?
          </>
        }
        submitTitle={translation.DeleteButton}
        handleSubmit={handleDeleteFile}
        cancelTitle={translation.CancelButton}
        handleClose={handleCloseDialog}
        fullWidth={true}
      />
    </Grid>
  );
});
