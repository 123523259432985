import { IElementComponentProps, PropsFromConnector } from "core";
import { connectElement } from "core/utils/react-redux";

import Component from "./component";
import { ReduxModule } from "./reduxModule";
import { BoolInput } from "./types";

const mapStateToProps = (
  state: any,
  { element }: IElementComponentProps<{}, BoolInput>,
) => ({
  color:
    typeof element.config?.color === "function"
      ? element.config.color(state)
      : element.config.color,
});

const connector = connectElement<
  ReduxModule,
  BoolInput,
  typeof mapStateToProps
>(mapStateToProps);
export type BoolInputProps = PropsFromConnector<typeof connector>;

export default connector(Component);
