import { memo } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import { Section } from "core/editor";

import { useTableEditorTranslation } from "../../translation";
import { UntransformedTableConfig } from "../../types";

import { intervalSliderMarks } from "./utils";

type Props = {
  interval: UntransformedTableConfig["interval"];
  changeConfigValue: (key: keyof UntransformedTableConfig, value: any) => void;
};

export const Interval = memo<Props>(({ interval = 0, changeConfigValue }) => {
  const translation = useTableEditorTranslation();

  const handleIntervalChange = (_event: Event, value: number | number[]) => {
    if (typeof value === "number") {
      changeConfigValue("interval", value);
    }
  };

  return (
    <Section
      title={translation.advancedTitle}
      wrapped={true}
      defaultOpened={false}
    >
      <FormControl fullWidth>
        <Tooltip title={translation.intervalTooltipLabel}>
          <FormLabel>{translation.intervalLabel}</FormLabel>
        </Tooltip>
        <Slider
          value={interval}
          onChange={handleIntervalChange}
          step={null}
          min={intervalSliderMarks[0].value}
          max={intervalSliderMarks[intervalSliderMarks.length - 1].value}
          valueLabelDisplay="auto"
          marks={intervalSliderMarks}
        />
      </FormControl>
    </Section>
  );
});
