import { memo, useMemo, useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  ConnectedStaticReduxModuleActionsProps,
  IMenuItem,
  ITranslatedMenuItem,
  RouterReduxModule,
} from "core";
import { prefixPageUrl } from "core/router/reduxModule/utils";
import { useSessionContext } from "core/session";
import Link from "elementTypes/common/Link";
import { IconNameType, MuiIcon } from "elementTypes/common/MuiIcon";

import { GenerateMenuList, IGenerateMenuItemsProps } from "../MenuList";

import { mapStateToProps } from "./container";
import useStyles from "./styles";

type MenuItemProps = {
  item: ITranslatedMenuItem;
  menu: IMenuItem[];
} & ConnectedStaticReduxModuleActionsProps<RouterReduxModule> &
  IGenerateMenuItemsProps &
  ReturnType<typeof mapStateToProps>;

export const MenuItem = memo<MenuItemProps>(
  ({ depth = 0, item, menu, uiName, menuItemSelected, getPageById }) => {
    const { language } = useSessionContext();
    const { classes } = useStyles({ depth });
    const isNested = !!menu.length;
    const isCollapsible = item?.submenuType !== "divider";
    const isError = !isNested && !item.externalLink && !item.pageId;
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const handleClickMenu = () => setMenuOpen((prevOpen) => !prevOpen);

    const page = useMemo(
      () => (item.pageId ? getPageById(item.pageId) : null),
      [item.pageId, getPageById],
    );

    const prefixedUrl = page ? prefixPageUrl(page.url) : undefined;

    // Determine if the link is external or internal
    const isExternalLink = Boolean(item?.externalLink ?? false);
    const href = item?.externalLink ?? prefixedUrl;

    const itemIcon = item.i18n?.icon ? (
      <ListItemIcon
        className={`${classes.icon} ${isError ? classes.errorIcon : classes.baseIcon}`}
      >
        <MuiIcon
          icon={isError ? "error" : (item.i18n.icon! as IconNameType)}
          fontSize="large"
        />
      </ListItemIcon>
    ) : null;

    const subDepth = isCollapsible ? 1 : 0;

    const SubMenu = useMemo(
      () =>
        GenerateMenuList(
          menu,
          uiName,
          menuItemSelected,
          language,
          depth + subDepth,
        ),
      [depth, menu, uiName, menuItemSelected, language, subDepth],
    );

    const btnContent = (
      <>
        {itemIcon}
        <ListItemText primary={item.i18n.label} />
        {isNested &&
          isCollapsible &&
          (menuOpen ? <ExpandLess /> : <ExpandMore />)}
      </>
    );

    return (
      <Link
        href={isNested ? undefined : href}
        underline="none"
        color="inherit"
        component={isNested ? "li" : "a"}
        target={isExternalLink ? "_blank" : undefined}
        rel={isExternalLink ? "noopener noreferrer" : undefined}
        aria-label={item.i18n.label}
        external={isExternalLink}
      >
        <ListItemButton
          className={`${classes.button} ${classes.nested} menu-item`}
          selected={prefixedUrl === menuItemSelected}
          {...(isNested
            ? {
                onClick: handleClickMenu,
                "aria-expanded": menuOpen,
              }
            : {})}
        >
          {btnContent}
        </ListItemButton>
        {isNested &&
          (isCollapsible ? (
            <Collapse
              in={menuOpen}
              unmountOnExit={false}
              timeout={0}
              mountOnEnter={true}
            >
              {SubMenu}
            </Collapse>
          ) : (
            SubMenu
          ))}
      </Link>
    );
  },
);
