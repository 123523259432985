import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  header: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  container: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
  },
  tooltipNoMaxWidth: {
    maxWidth: "none",
  },
}));

export default useStyles;
