import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Array Text Field",
      description: "Display a list of text",
    },
  },
  defaultSize: {
    width: 2,
    height: 1,
  },
};
