import { ChangeEvent, memo, useCallback } from "react";
import { Box, FormControl, TextField } from "@mui/material";
import { Section, useElementEditorContext } from "core/editor";
import { FileInputConfig } from "elementTypes/storage_file_input/types";
import { MultipleFileInputConfig } from "elementTypes/storage_multiple_files_input/types";
import {
  useFileGroupOptions,
  useFileTypeOptions,
} from "queries/admin/fileData";

import { Autocomplete } from "../../../Autocomplete";
import { IAutocompleteValue } from "../../../Autocomplete/types";
import { useMultipleFileInputEditorTranslation } from "../translation";

enum Fields {
  accessGroupName = "accessGroupName",
  typeGroupName = "typeGroupName",
  accept = "accept",
}

export const AdvancedComponent = memo(() => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<MultipleFileInputConfig | FileInputConfig>();
  const translation = useMultipleFileInputEditorTranslation();

  const fileOptions = useFileTypeOptions();
  const groupOptions = useFileGroupOptions();

  const handleChange = useCallback(
    (...params: Parameters<typeof changeConfigValue>) =>
      changeConfigValue(...params),
    [changeConfigValue],
  );

  const handleInputAcceptChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => handleChange(Fields.accept, value);

  const handleAutocompleteChange =
    (inputName: Fields) => (value: IAutocompleteValue) =>
      handleChange(inputName, value);

  return (
    <Section title={translation.advancedTitle} wrapped={true}>
      <Box display="flex" flexDirection="column" gap={2}>
        <FormControl fullWidth>
          <Autocomplete
            options={groupOptions.data ?? []}
            onChange={handleAutocompleteChange(Fields.accessGroupName)}
            value={config.accessGroupName}
            name={Fields.accessGroupName}
            label={translation[`${Fields.accessGroupName}InputLabel`]}
            isLoading={groupOptions.isInitialLoading}
          />
        </FormControl>
        <FormControl fullWidth>
          <Autocomplete
            options={fileOptions.data ?? []}
            onChange={handleAutocompleteChange(Fields.typeGroupName)}
            value={config.typeGroupName}
            name={Fields.typeGroupName}
            label={translation[`${Fields.typeGroupName}InputLabel`]}
            isLoading={fileOptions.isInitialLoading}
          />
        </FormControl>
        <TextField
          value={config[Fields.accept] ?? ""}
          name={Fields.accept}
          label={translation[`${Fields.accept}InputLabel`]}
          fullWidth={true}
          onChange={handleInputAcceptChange}
        />
      </Box>
    </Section>
  );
});
