import { ChangeEvent, memo, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import { getBooleanExpressionValue } from "core";
import { Section, useElementEditorContext } from "core/editor";

import { UntransformedAutocompleteInputConfig } from "../../types";
import { useAutocompleteEditorTranslation } from "../translation";

import { useStyles } from "./styles";

enum Sizes {
  medium = "medium",
  small = "small",
}

enum Variants {
  default = "default",
  outlined = "outlined",
}

export const MultiSelectionComponent = memo(() => {
  const {
    elementModel: {
      config: {
        isClearable = true,
        isMulti,
        withCheckbox,
        allowSameValue,
        nullable,
        disabled,
        size,
        chipVariant,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedAutocompleteInputConfig>();
  const translation = useAutocompleteEditorTranslation();
  const { classes } = useStyles();
  const inputDisabled = Boolean(
    typeof disabled === "boolean"
      ? disabled
      : getBooleanExpressionValue(disabled),
  );

  useEffect(() => {
    if (nullable && !isClearable) {
      changeConfigValue("isClearable", true);
    }
  }, [nullable, isClearable, changeConfigValue]);

  const handleChange = (
    { target: { name } }: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    switch (name) {
      case "isMulti":
        if (!checked) {
          changeConfigValue("withCheckbox", undefined);
          changeConfigValue("allowSameValue", undefined);
        }
        break;
    }

    changeConfigValue(
      name as keyof UntransformedAutocompleteInputConfig,
      checked,
    );
  };

  const handleStyleChange =
    (type: "size" | "chipVariant") => (_e: ChangeEvent, value: string) =>
      changeConfigValue(type, value);

  const sizes = Object.keys(Sizes).map((currentSize) => (
    <FormControlLabel
      key={currentSize}
      control={<Radio color="primary" />}
      label={translation[`${currentSize}Label`]}
      value={currentSize}
    />
  ));

  const variants = Object.keys(Variants).map((variant) => (
    <FormControlLabel
      key={variant}
      control={<Radio color="primary" />}
      label={translation[`${variant}Label`]}
      value={variant}
    />
  ));

  return (
    <Section title={translation.multiSelectionTitle} wrapped={true}>
      <FormControl>
        <FormControlLabel
          control={
            <Switch
              data-testid="isMultiTest"
              checked={Boolean(isMulti)}
              onChange={handleChange}
            />
          }
          label={translation.isMultiLabel}
          name="isMulti"
        />
        <FormHelperText disabled={!isMulti} variant="outlined">
          {translation.isMultiFlagHelperText}
        </FormHelperText>
      </FormControl>
      <FormControlLabel
        control={
          <Switch
            checked={isMulti ? Boolean(withCheckbox) : false}
            onChange={handleChange}
            disabled={!isMulti || allowSameValue || inputDisabled}
          />
        }
        label={translation.withCheckboxLabel}
        name="withCheckbox"
      />
      <FormControlLabel
        control={
          <Switch
            checked={isMulti ? Boolean(allowSameValue) : false}
            onChange={handleChange}
            disabled={!isMulti || withCheckbox || inputDisabled}
          />
        }
        label={translation.allowSameValueLabel}
        name="allowSameValue"
      />
      <FormControl
        fullWidth={true}
        disabled={!isMulti}
        className={classes.marginTop}
      >
        <FormLabel>{translation.sizeInputLabel}</FormLabel>
        <RadioGroup
          row={true}
          value={size ?? Sizes.medium}
          onChange={handleStyleChange("size")}
        >
          {sizes}
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth={true} disabled={!isMulti}>
        <FormLabel>{translation.chipVariantInputLabel}</FormLabel>
        <RadioGroup
          row={true}
          value={chipVariant ?? Variants.default}
          onChange={handleStyleChange("chipVariant")}
        >
          {variants}
        </RadioGroup>
      </FormControl>
    </Section>
  );
});
