import { memo, useLayoutEffect, useRef, useState } from "react";
import { Chip, ChipProps, Tooltip } from "@mui/material";
import { getBoxToBoxArrow } from "perfect-arrows";
import { EdgeProps, useNodes } from "reactflow";
import { useTranslation } from "core/session";
import { useTransitionStyles } from "./style";

// calculate bezier curve center point
// based on https://math.stackexchange.com/a/1361717
function getBezierCenterCoordinate(co1: number, co2: number, co3: number) {
  return (co1 - 2 * co2 + co3) * 0.25 + 2 * (co2 - co1) * 0.5 + co1;
}

const useGetNodes = ({ source, target }: Record<string, string>) => {
  const nodes = useNodes();
  return {
    sourceNode: nodes.find((n) => n.id === source)!,
    targetNode: nodes.find((n) => n.id === target)!,
  };
};

const Transition = ({
  id,
  data,
  source,
  target,
  style = {},
  selected = false,
}: EdgeProps) => {
  const { sourceNode, targetNode } = useGetNodes({ source, target });

  const { classes } = useTransitionStyles({ selected });
  const translation = useTranslation(data?.i18n);

  // used to correctly center the transition label
  const targetRef = useRef<HTMLDivElement | null>();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, []);

  let arrow1 = <path />;
  let arrow2 = <path />;
  let foreignObject = <foreignObject />;

  try {
    const arrow = getBoxToBoxArrow(
      sourceNode.position.x,
      sourceNode.position.y,
      sourceNode.width!,
      sourceNode.height!,
      targetNode.position.x,
      targetNode.position.y,
      targetNode.width!,
      targetNode.height!,
    );

    const [sx, sy, cx, cy, ex, ey, ae] = arrow;
    const endAngleAsDegrees = ae * (180 / Math.PI);

    const ccx = getBezierCenterCoordinate(sx, cx, ex);
    const ccy = getBezierCenterCoordinate(sy, cy, ey);

    arrow1 = (
      <path
        id={id}
        style={style}
        className={`react-flow__edge-path ${classes.path}`}
        d={`M${sx},${sy} Q${cx},${cy} ${ex},${ey}`}
        fill="none"
      />
    );

    arrow2 = (
      <polygon
        points="0,-6 12,0, 0,6"
        transform={`translate(${ex},${ey}) rotate(${endAngleAsDegrees})`}
      />
    );

    foreignObject = (
      <foreignObject
        width={"100%"}
        height={dimensions.height}
        x={ccx - dimensions.width / 2}
        y={ccy - dimensions.height / 2}
        className={classes.transitionContainer}
      >
        {translation.title && (
          <Tooltip title={translation.shortDescription ?? ""}>
            <Chip
              className={`${classes.edgeButtonClass} react-flow__edge-btn`}
              size="small"
              label={translation.title}
              // material-ui typing bug; does not accept "undefined"
              ref={targetRef as unknown as ChipProps["ref"]}
            />
          </Tooltip>
        )}
      </foreignObject>
    );
  } catch (e: unknown) {}

  return (
    <g className={classes.group}>
      {arrow1}
      {arrow2}
      {foreignObject}
    </g>
  );
};

const MemoizedTransition = memo(Transition);

export default MemoizedTransition;
