import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export type ColorVariant = "light" | "dark" | "primaryText" | "secondaryText";

type Props = { color?: ColorVariant };

const colorMapping = (theme: Theme): Record<ColorVariant, string> => ({
  light:
    theme.palette.mode === "light"
      ? theme.palette.common.white
      : theme.palette.text.primary,
  dark:
    theme.palette.mode === "light"
      ? theme.palette.text.primary
      : theme.palette.common.white,
  primaryText: theme.palette.primary.contrastText,
  secondaryText: theme.palette.secondary.contrastText,
});

export const useStyles = makeStyles<Props>()((theme: Theme, { color }) => ({
  button: {
    color: colorMapping(theme)[color ?? "light"],
    borderColor: "currentColor",
  },
}));
