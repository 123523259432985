import { ChangeEvent, MouseEvent, memo, useCallback, useMemo } from "react";
import { Box, FormLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";

import { buildCustomExpressionValue } from "core";
import { Section, useElementEditorContext } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";
import { ToggleButton } from "elementTypes/common/ToggleButton";

import {
  getDefaultFormat,
  toMoment,
} from "elementTypes/default_date_time_input/utils";
import { handleToggleExpressionMode } from "utils/string";
import { UntransformedDateTimeFieldConfig } from "../../types";
import { useDateTimeFieldEditorTranslation } from "../translation";

enum Format {
  date = "showDatePart",
  time = "showTimePart",
}

export const SetupComponent = memo(() => {
  const {
    elementModel: {
      id,
      config,
      config: { value, showDatePart = true, showTimePart = true, formatString },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDateTimeFieldConfig>();
  const translation = useDateTimeFieldEditorTranslation();

  const format =
    showDatePart && showTimePart
      ? [Format.date, Format.time]
      : showDatePart
        ? [Format.date]
        : [Format.time];

  const defaultFormat = useMemo(() => {
    return getDefaultFormat("dateTime");
  }, []);

  const handleExpressionValueChange = useCallback(
    (newValue: string | number | boolean) =>
      changeConfigValue("value", String(newValue)),
    [changeConfigValue],
  );
  const customInputValue = ({
    value: inputValue,
    onChange: onIdentifierChange,
  }: {
    value: string;
    onChange: (value: string) => void;
  }) => {
    const onCustomChange = (date: Date | null, keyboardInput?: string) => {
      if (keyboardInput) {
        if (keyboardInput.length === defaultFormat.length) {
          onIdentifierChange(
            moment(keyboardInput, toMoment(defaultFormat)).toISOString(),
          );
        }
      } else {
        if (date) {
          onIdentifierChange(date.toISOString());
        }
      }
    };

    return (
      <DateTimePicker
        disabled={!value}
        label={translation.valueLabel}
        value={inputValue}
        onChange={onCustomChange}
        inputFormat={defaultFormat}
        renderInput={(props) => <TextField fullWidth {...props} />}
      />
    );
  };

  const handleFormatChange = (
    _event: MouseEvent<HTMLElement>,
    nexValue: [Format.time | Format.date],
  ) => {
    if (nexValue?.length) {
      for (const f of Object.values(Format)) {
        changeConfigValue(f as Format.time | Format.date, nexValue.includes(f));
      }
    }
  };

  const formats = Object.values(Format).map((f) => (
    <ToggleButton key={f} value={f} aria-label={f}>
      {translation[`${f}Label`]}
    </ToggleButton>
  ));

  const handleChangeFormatString = (event: ChangeEvent<HTMLInputElement>) => {
    const nextFormat = event.target.value.trim()
      ? event.target.value
      : undefined;

    changeConfigValue("formatString", nextFormat);
  };

  const handleChangeTimezone = useCallback(
    (newValue: string) => changeConfigValue("timezone", newValue),
    [changeConfigValue],
  );

  const handleToggleMode = (isExpression: boolean) =>
    handleToggleExpressionMode(
      isExpression,
      value,
      handleExpressionValueChange,
    );

  return (
    <Section title={translation.valueLabel} wrapped={true}>
      <TableColumnEditor
        id={id}
        value={value}
        onChange={handleExpressionValueChange}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["date", "dateTime", "time"]}
      />
      <CustomExpressionEditor
        value={value as any}
        config={config}
        onChange={handleExpressionValueChange}
        label={translation.valueLabel}
        nonExpressionEditor={customInputValue}
        disableSwitcher={false}
        switcherDisabled={!value}
        onToggleMode={handleToggleMode}
      />
      <Box pb={1.5} gap={5} display="flex" alignItems="center">
        <FormLabel>{translation.formatLabel}</FormLabel>
        <ToggleButtonGroup
          size="small"
          value={format}
          onChange={handleFormatChange}
          fullWidth
        >
          {formats}
        </ToggleButtonGroup>
      </Box>
      <TextField
        fullWidth
        value={formatString ?? ""}
        label={translation.formatStringLabel}
        onChange={handleChangeFormatString}
        placeholder="MM/dd/yyyy hh:mm a"
        helperText={translation.formatHelperText}
      />

      <CustomExpressionEditor
        label={"Timezone"}
        value={config.timezone ?? buildCustomExpressionValue("null")}
        config={config}
        onChange={handleChangeTimezone}
      />
    </Section>
  );
});
