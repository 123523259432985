import { FC, PropsWithChildren } from "react";
import { StackProps } from "@mui/material/Stack";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";
import { useLanguage } from "utils/hooks";

import { useEditorTranslation } from "../../translation";
import { Section } from "../Section";

import { LanguageSectionProvider } from "./LanguageSectionContext";

type Props = PropsWithChildren &
  StackProps & {
    wrapped?: boolean;
    showSection?: boolean;
    showTitle?: boolean;
  };

const LanguageSection: FC<Props> = ({
  children,
  wrapped = true,
  showSection = true,
  showTitle = true,
  ...rest
}) => {
  const { translationTitle } = useEditorTranslation();
  const { lang, changeLanguage } = useLanguage();

  const languageSwitch = (
    <LanguageSwitch
      language={lang}
      changeLanguage={changeLanguage}
      colorVariant="dark"
      fullWidth={true}
    />
  );

  const content = (
    <LanguageSectionProvider
      value={{
        lang,
        changeLanguage,
      }}
    >
      {children}
    </LanguageSectionProvider>
  );

  return (
    <Section
      title={showTitle ? translationTitle : undefined}
      wrapped={wrapped}
      cardActions={languageSwitch}
      collapsible={showSection}
      {...rest}
    >
      {content}
    </Section>
  );
};

export { LanguageSection };
