import { IReduxModuleFactoryArgs } from "core/types";
import { TableDataSource } from "elementInterfaces";

import { DeleteButton } from "../types";

import { buildActions } from "./actions";

export function reduxModuleFactory({
  element,
}: IReduxModuleFactoryArgs<DeleteButton>) {
  const { elementId } = element.config.dataSource;

  const dataSource = TableDataSource.get(elementId);

  const { actions } = buildActions(element, dataSource);

  return {
    actions,
  };
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
