import { IElementComponentProps, PropsFromConnector } from "core";
import { connectElement } from "core/utils/react-redux";

import Component from "./component";
import { ReduxModule } from "./reduxModule";
import { ActionButton } from "./types";

const mapStateToProps = (
  state: any,
  { element }: IElementComponentProps<{}, ActionButton>,
) => ({
  disabled: element.config.disabled?.(state),
});

const mapDispatchToProps = {};

const connector = connectElement<
  ReduxModule,
  ActionButton,
  typeof mapStateToProps,
  typeof mapDispatchToProps
>(mapStateToProps, mapDispatchToProps);

export type ActionButtonProps = PropsFromConnector<typeof connector>;

export default connector(Component);
