import { memo, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import Button from "elementTypes/common/Button";
import maintenancePath from "layouts/common/maintenance.svg";

import verticalLogoPath from "../common/verticalLogo.svg";

import { actions } from "./reduxModule";
import useStyles from "./styles";
import { useMaintenancePageTranslation } from "./translation";

export const MaintenancePage = memo(() => {
  const {
    classes: { root, image, logo, cardContent },
  } = useStyles();
  const translation = useMaintenancePageTranslation();
  const { error } = useSelector(sessionSelectors.loadAppMetadata);
  const dispatch = useDispatch();

  const handleRetryClick = () => dispatch(sessionActions.bootstrap());

  useEffect(() => {
    if (!error) {
      dispatch(actions.redirect());
    }
  }, [error, dispatch]);

  return (
    <>
      <Helmet>
        <title>{translation.apiNotAvailableText}</title>
      </Helmet>

      <Card className={root}>
        <CardContent className={cardContent}>
          <img className={logo} src={verticalLogoPath} alt="logo" />
          <Typography variant="h2" align="center">
            {translation.apiNotAvailableText}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            iconRight="refresh"
            label={translation.retryText}
            size="large"
            variant="contained"
            sx={{ color: "primary.contrastText" }}
            onClick={handleRetryClick}
          />
        </CardActions>
        <CardMedia>
          <img src={maintenancePath} alt="Maintenance" className={image} />
        </CardMedia>
      </Card>
    </>
  );
});
