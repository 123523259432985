import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Internal Link Button",
      description: "Display a Link Button",
    },
  },
  minSize: {
    width: 1,
    height: 1,
  },
  defaultSize: {
    width: 1,
    height: 1,
  },
};
