import * as t from "io-ts";
import { IElement, customExpression } from "core/types";

export const LabelFieldConfig = t.type({
  value: customExpression(t.any),
  color: t.keyof({
    primary: null,
    secondary: null,
    default: null,
  }),
  variant: t.keyof({
    filled: null,
    outlined: null,
  }),
  size: t.keyof({
    small: null,
    medium: null,
  }),
});

export type LabelFieldConfig = t.TypeOf<typeof LabelFieldConfig>;

export type LabelField = IElement<LabelFieldConfig>;
