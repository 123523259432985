import { memo } from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import IconButton from "elementTypes/common/IconButton";
import { useEchartsTranslation } from "../../../translation.ts";
import { TRow } from "./types.ts";

export const LegendLabelsRow = memo<TRow>(
  ({ data, style, index, translatedObject, handleRowClick }) => {
    const { editLabelTooltip } = useEchartsTranslation();

    const columnName = data[index];
    const label = translatedObject[`${columnName}Label`] || columnName;

    return (
      <ListItemButton divider style={style} key={`${columnName}-${index}`}>
        <ListItemText primary={label} />
        <ListItemIcon>
          <IconButton
            icon="edit"
            data-testid="editor-charts-label-edit"
            onClick={handleRowClick(columnName)}
            tooltip={editLabelTooltip}
            edge="end"
          />
        </ListItemIcon>
      </ListItemButton>
    );
  },
);
