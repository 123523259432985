import { QueryToPagesMappingValue } from "core";
import { QueryObject } from "staticPages/admin/pages/modelBuilder/components/types";

export const getAvailableQueries = (
  queryToPagesMapping: Record<string, QueryToPagesMappingValue> = {},
  queryList: QueryObject[] = [],
) =>
  queryList.filter(
    (query) => !Object.keys(queryToPagesMapping).includes(query.name),
  );
