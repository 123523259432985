import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  dividerClass: {
    margin: `${theme.spacing()} 0`,
  },
  root: {
    overflow: "auto",
    maxHeight: "100%",
    height: "100%",
    scrollbarWidth: "none",
    MsOverflowStyle: "-ms-autohiding-scrollbar",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  dataDisplay: {
    wordBreak: "break-word",
    overflow: "auto",
  },
}));
