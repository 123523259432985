import { AnyRecordType } from "./AnyRecordType";
import { FunctionType } from "./FunctionType";
import { NumberType } from "./NumberType";
import { StringType } from "./StringType";
import { Type } from "./Type";
import { TypeError } from "./TypeError";
import { IAutocompleteKey } from "./types";

export class DateType extends Type {
  public name = "date";

  public validate(value: any): true | TypeError {
    return Object.prototype.toString.call(value) === "[object Date]"
      ? true
      : new TypeError(value, this);
  }

  public getAutocompleteRecord(): Record<string, IAutocompleteKey> {
    return {
      ...super.getAutocompleteRecord(),
      ...autocompleteRecord,
    };
  }
}

/**
 * TODO:
 * Make Date Objects be Moment.js instances and simplify interface (expose only used methods, and possibly make some
 * friendlier wrappers)
 */

const autocompleteRecord = {
  getDate: {
    type: new FunctionType(
      new NumberType(
        "An integer number, between 1 and 31, representing the day of the month for the given date according to local time.",
      ),
    ),
    isBuiltin: false,
  },
  getDay: {
    type: new FunctionType(
      new NumberType(
        "An integer number, between 0 and 6, corresponding to the day of the week for the given date, according to local time: 0 for Sunday, 1 for Monday, 2 for Tuesday, and so on.",
      ),
    ),
    isBuiltin: false,
  },
  getFullYear: {
    type: new FunctionType(
      new NumberType(
        "A number corresponding to the year of the given date, according to local time.",
      ),
    ),
    isBuiltin: false,
  },
  getHours: {
    type: new FunctionType(
      new NumberType(
        "An integer number, between 0 and 23, representing the hour for the given date according to local time.",
      ),
    ),
    isBuiltin: false,
  },
  getMilliseconds: {
    type: new FunctionType(
      new NumberType(
        "A number, between 0 and 999, representing the milliseconds for the given date according to local time.",
      ),
    ),
    isBuiltin: false,
  },
  getMinutes: {
    type: new FunctionType(
      new NumberType(
        "An integer number, between 0 and 59, representing the minutes in the given date according to local time.",
      ),
    ),
    isBuiltin: false,
  },
  getMonth: {
    type: new FunctionType(
      new NumberType(
        "An integer number, between 0 and 11, representing the month in the given date according to local time. 0 corresponds to January, 1 to February, and so on.",
      ),
    ),
    isBuiltin: false,
  },
  getSeconds: {
    type: new FunctionType(
      new NumberType(
        "An integer number, between 0 and 59, representing the seconds in the given date according to local time.",
      ),
    ),
    isBuiltin: false,
  },
  getTime: {
    type: new FunctionType(
      new NumberType(
        "A number representing the milliseconds elapsed between 1 January 1970 00:00:00 UTC and the given date.",
      ),
    ),
    isBuiltin: false,
  },
  getTimezoneOffset: {
    type: new FunctionType(
      new NumberType(
        "A number representing the time-zone offset, in minutes, from the date based on current host system settings to UTC.",
      ),
    ),
    isBuiltin: false,
  },
  getUTCDate: {
    type: new FunctionType(
      new NumberType(
        "An integer number, between 1 and 31, representing the day of the month in the given date according to universal time.",
      ),
    ),
    isBuiltin: false,
  },
  getUTCDay: {
    type: new FunctionType(
      new NumberType(
        "An integer number corresponding to the day of the week for the given date, according to universal time: 0 for Sunday, 1 for Monday, 2 for Tuesday, and so on.",
      ),
    ),
    isBuiltin: false,
  },
  getUTCFullYear: {
    type: new FunctionType(
      new NumberType(
        "A number representing the year in the given date according to universal time.",
      ),
    ),
    isBuiltin: false,
  },
  getUTCHours: {
    type: new FunctionType(
      new NumberType(
        "An integer number, between 0 and 23, representing the hours in the given date according to universal time.",
      ),
    ),
    isBuiltin: false,
  },
  getUTCMilliseconds: {
    type: new FunctionType(
      new NumberType(`An integer number, between 0 and 999, representing the milliseconds portion of the given date object.  This method is a companion to the other UTC based methods that give hour portion, minute portion, etc.; this method gives milliseconds portion.  
Not to be confused with Unix epoch time.  To get total milliseconds since 1970/01/01, use the method ".getTime()".`),
    ),
    isBuiltin: false,
  },
  getUTCMinutes: {
    type: new FunctionType(
      new NumberType(
        "An integer number, between 0 and 59, representing the minutes in the given date according to universal time.",
      ),
    ),
    isBuiltin: false,
  },
  getUTCMonth: {
    type: new FunctionType(
      new NumberType(
        "An integer number, between 0 and 11, corresponding to the month of the given date according to universal time. 0 for January, 1 for February, 2 for March, and so on.",
      ),
    ),
    isBuiltin: false,
  },
  getUTCSeconds: {
    type: new FunctionType(
      new NumberType(
        "An integer number, between 0 and 59, representing the seconds in the given date according to universal time.",
      ),
    ),
    isBuiltin: false,
  },
  getYear: {
    type: new FunctionType(
      new NumberType(`For years greater than or equal to 2000, the value returned by getYear() is 100 or greater. For example, if the year is 2026, getYear() returns 126.
For years between and including 1900 and 1999, the value returned by getYear() is between 0 and 99. For example, if the year is 1976, getYear() returns 76.
For years less than 1900, the value returned by getYear() is less than 0. For example, if the year is 1800, getYear() returns -100.
To take into account years before and after 2000, you should use getFullYear() instead of getYear() so that the year is specified in full.`),
    ),
    isBuiltin: false,
  },
  toDateString: {
    type: new FunctionType(
      new StringType(
        "A string representing the date portion of the given Date object in human readable form in English.",
      ),
    ),
    isBuiltin: false,
  },
  toISOString: {
    type: new FunctionType(
      new StringType(
        "A string representing the given date in the ISO 8601 format according to universal time.",
      ),
    ),
    isBuiltin: false,
  },
  toJSON: {
    type: new FunctionType(
      new AnyRecordType("A string representation of the given date."),
    ),
    isBuiltin: false,
  },
  toLocaleDateString: {
    type: new FunctionType(
      new StringType(`The locales and options arguments customize the behavior of the function and let applications specify the language whose formatting conventions should be used. In implementations, which ignore the locales and options arguments, the locale used and the form of the string returned are entirely implementation dependent.

See the Intl.DateTimeFormat() constructor for details on these parameters and how to use them.

The default value for each date-time component property is undefined, but if the weekday, year, month, day properties are all undefined, then year, month, and day are assumed to be "numeric".`),
    ),
    isBuiltin: false,
  },
  toLocaleString: {
    type: new FunctionType(
      new StringType(`The locales and options arguments customize the behavior of the function and let applications specify the language whose formatting conventions should be used. In implementations, which ignore the locales and options arguments, the locale used and the form of the string returned are entirely implementation dependent.

See the Intl.DateTimeFormat() constructor for details on these parameters and how to use them.

The default value for each date-time component property is undefined. But, if the weekday, year, month, and day properties are all undefined, then year, month, and day are assumed to be "numeric".`),
    ),
    isBuiltin: false,
  },
  toLocaleTimeString: {
    type: new FunctionType(
      new StringType(`The locales and options arguments customize the behavior of the function and let applications specify the language whose formatting conventions should be used. In implementations, which ignore the locales and options arguments, the locale used and the form of the string returned are entirely implementation dependent.

See the Intl.DateTimeFormat() constructor for details on these parameters and how to use them.

The default value for each date-time component property is undefined, but if the hour, minute, second properties are all undefined, then hour, minute, and second are assumed to be "numeric".`),
    ),
    isBuiltin: false,
  },
  toTimeString: {
    type: new FunctionType(
      new StringType(
        "A string representing the time portion of the given date in human readable form in American English.",
      ),
    ),
    isBuiltin: false,
  },
  toUTCString: {
    type: new FunctionType(
      new StringType(
        "A string representing the given date using the UTC time zone.",
      ),
    ),
    isBuiltin: false,
  },
};
