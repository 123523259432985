import { memo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Node } from "reactflow";
import { useSessionContext, useTranslation } from "core/session";
import { LoadingComponent } from "layouts/common/Loading";
import { QueryKeys } from "queries/admin";
import {
  useDeleteState,
  useStateNode,
  useUpdateState,
} from "queries/admin/modelBuilderData";
import { getApiError } from "queries/utils";
import { useAdminContext } from "staticPages/admin/context";
import { useLocation } from "utils/hooks";
import { useSnackbar } from "utils/hooks/useSnackbar";

import { DEFAULT_LANGUAGE_CODE } from "../../../../../../core";
import { useHandleSelectElement } from "../utils";

import { StateForm } from "./StateForm";
import { useStateFormTranslations } from "./StateForm/translation";
import { UIStateForm } from "./StateForm/types";
import { NodeData } from "./StateViewer/types";
import { StateUpdateAPIData } from "./types";

const EditState = ({ node: element }: { node: Node<NodeData> }) => {
  const setSelectedElement = useHandleSelectElement();
  const { queries } = useLocation();
  const { setWorkflowView } = useAdminContext();

  const { id } = element ?? {};

  const { success, successDelete } = useStateFormTranslations();
  const showSnackbar = useSnackbar();
  const {
    language: { code: languageCode },
  } = useSessionContext();
  const stateNodeResponse = useStateNode(
    {
      id: Number(id),
    },
    { enabled: !!id?.length },
  );

  const stateNode = stateNodeResponse.data;

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useUpdateState({
    onSettled: (_, error) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.fetchWorkflow],
      });

      if (!error) {
        setSelectedElement(null);
      }
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
    onSuccess: () => {
      showSnackbar(success, "success");
    },
  });
  const deleteState = useDeleteState({
    onSuccess: () => {
      if (stateNode) {
        setWorkflowView({
          path: [queries.schema, queries.table, "nodes", String(stateNode.id)],
          value: null,
        });
      }

      showSnackbar(successDelete, "success");
      setSelectedElement(null);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchWorkflow] });
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const translation = useTranslation(stateNode?.i18n);

  if (!stateNode) {
    return null;
  }

  const { id: stateNodeId, state, stateChangesFound, acl } = stateNode;

  const { title, shortDescription } = translation;

  if (stateNodeResponse.isInitialLoading) {
    return <LoadingComponent />;
  }

  const isEditable = Boolean(acl);

  const node: UIStateForm = {
    id: stateNodeId,
    state,
    title,
    shortDescription,
    isEditable,
    acl,
  } as UIStateForm;

  const onSubmit = (formData: Record<string, unknown> | UIStateForm) => {
    const { ...data } = formData as UIStateForm;

    const text = {
      title: data.title.trim(),
      shortDescription: data.shortDescription?.trim(),
    };

    const payload: StateUpdateAPIData = {
      i18n: {
        [languageCode]: text,
        [DEFAULT_LANGUAGE_CODE]: text,
      },
      acl: data.isEditable ? data.acl ?? [] : null,
    };

    if (id) {
      mutate({
        id: Number(id),
        stateData: payload,
      });
    }
  };

  const onDelete = () => {
    const stateId = node.id;
    deleteState.mutate({ id: String(stateId) });
  };

  return (
    <StateForm
      key={stateNodeResponse.dataUpdatedAt}
      node={node}
      onSubmit={onSubmit}
      onDelete={onDelete}
      stateChangesFound={stateChangesFound}
      inProgress={isLoading}
    />
  );
};

const MemoizedEditState = memo(EditState);

export default MemoizedEditState;
