import { MouseEvent, memo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRowModel,
} from "@mui/x-data-grid";

import { AlertBox } from "elementTypes/common/AlertBox";
import { CodeBlock } from "elementTypes/common/CodeBlock";
import IconButton from "elementTypes/common/IconButton";
import { LoadingComponent } from "layouts/common/Loading";
import { useDataPreviewQuery } from "queries/admin";
import { PreviewTable } from "../components/PreviewTable";
import { renderCellOverride } from "../components/utils";
import { ColumnsData } from "../customQuery/types";
import { useDatabaseTranslation } from "../translation";

type DataPreviewProps = {
  tableName: string;
  schemaName: string;
  code?: string;
};

export const DataPreview = memo<DataPreviewProps>(
  ({ tableName, schemaName, code }) => {
    const t = useDatabaseTranslation();
    const query = `select * from "${schemaName}"."${tableName}"`;

    const { data, error, isLoading } = useDataPreviewQuery(
      {
        data: {
          viewName: "fresh",
          code: query,
        },
      },
      { enabled: !!tableName },
    );

    const columns: GridColDef[] =
      data?.columns.map((column: ColumnsData) => ({
        field: column.name,
        headerName: column.name,
        type: ["integer", "text", "bigint", "double precision"].includes(
          column.type,
        )
          ? "string"
          : column.type,
        minWidth: 180,
        flex: 1,
        renderCellOverride: renderCellOverride(column.generalType),
        renderHeader: (headerProps: GridColumnHeaderParams) => (
          <RenderHeader
            {...headerProps}
            t={{ copiedTooltip: t.copiedTooltip, copyTooltip: t.copyTooltip }}
          />
        ),
      })) ?? [];

    const rows: GridRowModel[] =
      data?.data.map((row: Record<string, unknown>, index: number) => ({
        id: index,
        ...row,
      })) ?? [];

    if (isLoading) {
      return <LoadingComponent />;
    }

    return (
      <>
        {error ? (
          <AlertBox
            boxProps={{
              mb: 1,
            }}
            alertTitle={error?.message ?? "An error occurred."}
          />
        ) : null}
        <PreviewTable rows={rows} columns={columns} error={error?.message} />
        {code ? (
          <Box mt={1}>
            <Typography marginBottom={1}>{t.queryDefinitionTitle}</Typography>
            <CodeBlock value={code} language="sql" isCopy>
              {code}
            </CodeBlock>
          </Box>
        ) : null}
      </>
    );
  },
);

const RenderHeader = memo<
  GridColumnHeaderParams & { t: Record<string, string> }
>(({ field, colDef: { headerName }, t }) => {
  const [fieldCopied, setFieldCopied] = useState<string | null>(null);
  const handleCopy = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    await navigator.clipboard.writeText(field);
    setFieldCopied(field);

    setTimeout(() => setFieldCopied(null), 2000);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
      width="100%"
    >
      <IconButton
        onClick={handleCopy}
        icon={fieldCopied === field ? "check" : "file_copy"}
        tooltip={fieldCopied === field ? t.copiedTooltip : t.copyTooltip}
        size="small"
        edge="start"
        color="inherit"
        fontSize="small"
        placement="top"
      />
      <Typography variant="subtitle2">{headerName}</Typography>
    </Box>
  );
});
