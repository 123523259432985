import { CSSProperties, memo } from "react";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import { FixedSizeList, areEqual } from "react-window";
import { ArrayField } from "elementTypes/common/ArrayField";
import { GeneratedFunction } from "queries/admin/types";
import { useDimensions } from "utils/hooks/useDimensions";
import IconButton from "../../../../../../../elementTypes/common/IconButton";

type Props = {
  data?: GeneratedFunction[];
  onClick: (
    type: "view" | "delete",
    item: GeneratedFunction,
  ) => (ev: any) => void;
};

const ITEM_SIZE = 40;

export const List = memo<Props>(({ data = [], onClick }) => {
  const { ref: boxRef, ...size } = useDimensions();
  const listHeight =
    size?.height ?? ITEM_SIZE * Math.min(data?.length ?? 0, 10);

  type TRow = {
    data: GeneratedFunction[];
    style: CSSProperties;
    index: number;
  };

  const Row = memo<TRow>(({ index, style }) => {
    const item = data[index];

    return (
      <ListItem
        key={item.id}
        divider={true}
        style={style}
        onClick={onClick("view", item)}
        secondaryAction={
          <IconButton
            value={item.name}
            icon="delete_outline"
            edge="end"
            onClick={onClick("delete", item)}
          />
        }
        disablePadding
      >
        <ListItemButton role={undefined} dense sx={{ height: "100%" }}>
          <ListItemText
            id={item.name}
            primary={
              <Stack direction={"row"} gap={1}>
                {item.name}
                {item?.argumentDataTypes && (
                  <ArrayField
                    values={Object.keys(item?.argumentDataTypes)}
                    color="info"
                    size="small"
                    variant="outlined"
                  />
                )}
              </Stack>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  }, areEqual);

  return (
    <Box
      {...{ ref: boxRef }}
      height="100%"
      // fix height overflow when scrolling
      minHeight="0"
    >
      <FixedSizeList
        height={listHeight}
        itemCount={data?.length ?? 0}
        itemSize={ITEM_SIZE}
        width="100%"
        data-id="functions-list"
      >
        {Row}
      </FixedSizeList>
    </Box>
  );
});
