import { memo } from "react";
import { Box } from "@mui/material";
import { Controller } from "react-hook-form";
import { useHookFormContext } from "elementTypes/common/HookForm/utils.ts";
import { UploadZone } from "elementTypes/common/UploadZone/component.tsx";
import { useFileByName } from "queries/common/fileData.ts";
import { TLogoUpload } from "../../types.ts";

export const LogoUpload = memo<TLogoUpload>(
  ({ defaultValue, openModal, dense }) => {
    const { control, setValue } = useHookFormContext();
    const { data: file } = useFileByName(
      { fileName: (defaultValue as string) ?? "" },
      {
        enabled: typeof defaultValue === "string" && !!defaultValue?.length,
        onSuccess: (data) => {
          setValue("logo.fileName", data?.fileName);
        },
      },
    );

    const acceptedFiles: Record<string, string[]> = {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/svg+xml": [".svg"],
    };

    const value = typeof defaultValue === "string" ? file : defaultValue;

    const uploadLogo = (
      <Controller
        render={(p) => {
          const handleSubmit = (newFile: File | null) => {
            p.field.onChange(newFile);
            openModal();
          };
          return (
            <UploadZone
              value={!p.field.value ? p.field.value : value ?? p.field.value}
              multiple={false}
              accept={acceptedFiles}
              onSubmit={handleSubmit}
              onEdit={openModal}
              innerRef={p.field.ref}
              dense={dense}
            />
          );
        }}
        name="logo.fileName"
        control={control}
        defaultValue={value}
      />
    );

    return (
      <Box display="flex" flexDirection="column" gap={2} alignItems="center">
        {uploadLogo}
      </Box>
    );
  },
);
