import { memo } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Section } from "core/editor";

import { MuiIcon } from "../../../common/MuiIcon";
import { ToggleButton } from "../../../common/ToggleButton";
import { Alignment } from "../../component";
import { UntransformedTextFieldConfig } from "../../types";
import { useDefaultTextEditorTranslation } from "../translation";

type Props = {
  verticalAlignment: Alignment;
  horizontalAlignment: Alignment;
  onChange: (
    type: keyof UntransformedTextFieldConfig,
  ) => (_: any, value: string) => void;
};

export const FieldAlignment = memo<Props>(
  ({ horizontalAlignment, verticalAlignment, onChange }) => {
    const { alignmentTitle, horizontalLabel, verticalLabel } =
      useDefaultTextEditorTranslation();
    return (
      <Section title={alignmentTitle} wrapped={true}>
        <FormControl fullWidth>
          <FormLabel component="p">{horizontalLabel}</FormLabel>
          <ToggleButtonGroup
            value={horizontalAlignment}
            exclusive
            onChange={onChange("horizontalAlignment")}
            fullWidth
          >
            <ToggleButton value="start" aria-label="start">
              <MuiIcon icon="format_align_left" fontSize="large" />
            </ToggleButton>
            <ToggleButton value="center" aria-label="center">
              <MuiIcon icon="format_align_center" fontSize="large" />
            </ToggleButton>
            <ToggleButton value="end" aria-label="end">
              <MuiIcon icon="format_align_right" fontSize="large" />
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel component="p">{verticalLabel}</FormLabel>
          <ToggleButtonGroup
            value={verticalAlignment}
            exclusive
            onChange={onChange("verticalAlignment")}
            fullWidth
          >
            <ToggleButton value="start" aria-label="start">
              <MuiIcon icon="vertical_align_top" fontSize="large" />
            </ToggleButton>
            <ToggleButton value="center" aria-label="center">
              <MuiIcon icon="vertical_align_center" fontSize="large" />
            </ToggleButton>
            <ToggleButton value="end" aria-label="end">
              <MuiIcon icon="vertical_align_bottom" fontSize="large" />
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      </Section>
    );
  },
);
