import { IDefaultElement, buildCustomExpressionValue } from "core";

import { UntransformedJsonFieldConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedJsonFieldConfig> = {
  config: {
    value: buildCustomExpressionValue("{}"),
    collapsed: true,
    enableClipboard: false,
  },
};
