import { SelectorTypes } from "core";
import { types } from "core/runtime-typing";
import { buildActions } from "./actions";
import { buildSelectors } from "./selectors";

export type IState = {
  clickedCount: number;
  lastClicked: Date | null;
};

type R = ReturnType<typeof buildActions>;
export type Actions = R["actions"];
export type Types = R["types"];

export type Selectors = ReturnType<typeof buildSelectors>;

export const actionButtonSelectors: SelectorTypes<Selectors> = {
  clickedCount: types.number("Number of times the button was clicked."),
  lastClicked: types.nullable(types.date(), "Last time the button was clicked"),
};
