import { ElementGroup, IRawElementType } from "core";

import Component from "./container";
import { ArrayTextInputEditor, defaultElement, editorMetadata } from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import {
  ArrayTextInputConfig,
  ArrayTextInputTranslationKeys,
  defaultArrayTextInputSelectorTypes,
} from "./types";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_array_text_input",
  group: ElementGroup.Inputs,
  component: Component,
  editable: true,
  configType: ArrayTextInputConfig,
  selectorTypes: defaultArrayTextInputSelectorTypes,
  translationKeys: ArrayTextInputTranslationKeys,
  editorComponent: ArrayTextInputEditor,
  defaultElement,
  editorMetadata,
};

export default elementType;
