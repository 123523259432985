import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import AdminService from "services/admin";

import { getServerError } from "../../../../../../../core/utils/api";

import { actions, types } from "./actions";
import { IUserAudit } from "./types";

const services = AdminService.getInstance();

function* loadSaga() {
  const token: string = yield select(sessionSelectors.token);

  try {
    const data: IUserAudit[] = yield call(services.getUserAudit, token);
    yield put(actions.loadSuccess(data));
  } catch (error) {
    yield put(actions.loadError(getServerError(error)));
  }
}

export function* saga() {
  yield all([takeLatest(types.LOAD, loadSaga)]);

  yield put(actions.load());
}
