import {
  allCompiledRoutes,
  allPages,
  isAdminPage,
  isStaticPage,
  keysByKeyAliases,
  loadingPage,
  locationWithParams,
  notFound,
  page,
  pageElement,
  params,
  preventLocationChange,
  routerError,
  staticPageId,
} from "../../selectors";

export const selectors = {
  loadingPage,
  page,
  pageElement,
  staticPageId,
  isAdminPage,
  isStaticPage,
  error: routerError,
  notFound,
  preventLocationChange,
  keysByKeyAliases,
  compiledRoutes: allCompiledRoutes,
  allPages,
  params,
  location: locationWithParams,
};
