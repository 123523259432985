import { ElementGroup, IRawElementType } from "core";

import Component from "./container";
import { DataDisplayEditor, defaultElement, editorMetadata } from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import {
  DataDisplayConfig,
  DataDisplayTranslationKeys,
  dataDisplaySelectors,
} from "./types";
import { getQueriesFromConfig } from "./utils";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_data_display",
  component: Component,
  group: ElementGroup.Basic,
  editable: true,
  configType: DataDisplayConfig,
  selectorTypes: dataDisplaySelectors,
  translationKeys: DataDisplayTranslationKeys,
  defaultElement,
  editorComponent: DataDisplayEditor,
  editorMetadata,
  getQueriesFromConfig,
};

export default elementType;
