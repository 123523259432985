import { actions as sessionActions } from "core/session/reduxModule/actions";
import { createAction, createActionTypeScoper } from "core/utils/redux";

import { IFilterGroup } from "../toolsPanel";
import { Table, TableMetadata } from "../types";

import { IState, ITableParams } from "./types";

export function buildActions(scope: string, _: Table) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    LOAD: scopeActionType("LOAD"),
    LOAD_SUCCESS: scopeActionType("LOAD_SUCCESS"),
    LOAD_ERROR: scopeActionType("LOAD_ERROR"),
    ORDER_CHANGE: scopeActionType("ORDER_CHANGE"),
    LIMIT_CHANGE: scopeActionType("LIMIT_CHANGE"),
    OFFSET_CHANGE: scopeActionType("OFFSET_CHANGE"),
    FILTER_CHANGE: scopeActionType("FILTER_CHANGE"),
    FILTER_APPLY: scopeActionType("FILTER_APPLY"),
    SEARCH_VALUE_CHANGE: scopeActionType("SEARCH_VALUE_CHANGE"),
    ROW_UPDATE: scopeActionType("ROW_UPDATE"),
    ROW_UPDATE_SUCCESS: scopeActionType("ROW_UPDATE_SUCCESS"),
    ROW_UPDATE_ERROR: scopeActionType("ROW_UPDATE_ERROR"),
    ROW_DELETE: scopeActionType("ROW_DELETE"),
    ROW_DELETE_SUCCESS: scopeActionType("ROW_DELETE_SUCCESS"),
    ROW_DELETE_ERROR: scopeActionType("ROW_DELETE_ERROR"),
    POLL_START: scopeActionType("POLL_START"),
    POLL_STOP: scopeActionType("POLL_STOP"),
    SELECT_ROW: scopeActionType("SELECT_ROW"),
  };

  const actions = {
    load: createAction(types.LOAD, (params: Partial<ITableParams> = {}) => ({
      params,
    })),
    loadSuccess: createAction(
      types.LOAD_SUCCESS,
      (
        data: any,
        params: ITableParams,
        nextPageAvailable: boolean,
        metadata: TableMetadata,
        references: IState["references"],
      ) => ({
        data,
        params,
        nextPageAvailable,
        metadata,
        references,
      }),
    ),
    loadError: createAction(
      types.LOAD_ERROR,
      (error: string, params: ITableParams) => ({ error, params }),
    ),
    changeOrder: createAction(
      types.ORDER_CHANGE,
      (fieldName: string, { multi = false }: { multi?: boolean } = {}) => ({
        fieldName,
        multi,
      }),
    ),
    changeLimit: createAction(types.LIMIT_CHANGE, (limit: number) => ({
      limit,
    })),
    changeOffset: createAction(types.OFFSET_CHANGE, (offset: number) => ({
      offset,
    })),
    changeFilter: createAction(
      types.FILTER_CHANGE,
      (nextFilter: IFilterGroup) => ({
        nextFilter,
      }),
    ),
    applyFilter: createAction(types.FILTER_APPLY),
    changeSearchValue: createAction(
      types.SEARCH_VALUE_CHANGE,
      (searchInputValue: string) => ({ searchInputValue }),
    ),
    updateRow: createAction(types.ROW_UPDATE, (oldData: any, data: any) => ({
      oldData,
      data,
    })),
    updateRowSuccess: createAction(types.ROW_UPDATE_SUCCESS),
    updateRowError: createAction(types.ROW_UPDATE_ERROR, (error: string) => ({
      error,
    })),
    deleteRow: createAction(types.ROW_DELETE, (data: any) => ({ data })),
    deleteRowSuccess: createAction(types.ROW_DELETE_SUCCESS),
    deleteRowError: createAction(types.ROW_DELETE_ERROR, (error: string) => ({
      error,
    })),
    enqueueSnackbar: sessionActions.enqueueSnackbar,
    startPolling: createAction(types.POLL_START),
    stopPolling: createAction(types.POLL_STOP),
    selectRow: createAction(
      types.SELECT_ROW,
      (identifier: string | number, row: Record<string, unknown>) => ({
        identifier,
        row,
      }),
    ),
  };

  return { types, actions };
}
