import {
  DEFAULT_LANGUAGE_CODE,
  Language,
  Translated,
  Translation,
} from "../../types";

export const getNewTranslation = (
  i18n: Translation,
  language: Language,
  newTranslation: Translated,
  expand?: boolean,
) => {
  const translation = {
    ...i18n,
    [language.code]: expand
      ? { ...i18n[language.code], ...newTranslation }
      : { ...newTranslation },
    [DEFAULT_LANGUAGE_CODE]: expand
      ? { ...i18n[DEFAULT_LANGUAGE_CODE], ...newTranslation }
      : { ...newTranslation },
  } as Translation;

  return translation;
};
