import { memo, useCallback } from "react";
import {
  Box,
  Card,
  CardMedia,
  Link,
  Button as MuiButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DropzoneProps, useDropzone } from "react-dropzone";
import { FieldPath, FieldValues } from "react-hook-form";
import IconButton from "elementTypes/common/IconButton";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { FILE_STORAGE_PREFIX } from "services/api/constants";
import { StorageFileMetadata } from "services/api/types/FileStorage";
import { bytesToSize } from "utils/bytesToSize";
import { isFile } from "utils/other";
import { RenderProps } from "../HookForm";

export type UploadZoneType<
  Values extends FieldValues,
  T extends FieldPath<Values>,
> = RenderProps<Values, T> & Partial<DropzoneProps>;

export const UploadPreviewCard = memo<{
  preview: StorageFileMetadata | File;
  onDelete?: () => void;
  onEdit?: () => void;
  fileProps?: File;
  dense: boolean;
}>(({ onDelete, onEdit, preview, fileProps, dense }) => {
  const MAX_WIDTH = 224.1;

  const isImg =
    (preview as StorageFileMetadata)?.fileType?.startsWith("image/") ||
    (preview as File)?.type?.startsWith("image/");

  const imageProps =
    fileProps || isFile(preview)
      ? {
          image: URL.createObjectURL(fileProps ?? (preview as File)),
          alt:
            (preview as StorageFileMetadata)?.realName ??
            (preview as File)?.name,
          onLoad: () =>
            URL.revokeObjectURL(
              URL.createObjectURL(fileProps ?? (preview as File)),
            ),
        }
      : {
          image: `${FILE_STORAGE_PREFIX}/${preview.fileName}`,
          alt: preview.realName,
        };

  const handleEdit = (e: any) => {
    e.stopPropagation();
    onEdit?.();
  };

  const media = (
    <CardMedia
      component={isImg ? "img" : "div"}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 45,
        width: isImg ? "auto" : 50,
        maxWidth: dense ? MAX_WIDTH / 2 : MAX_WIDTH,
        ...(isImg && {
          marginRight: 1,
        }),
      }}
      {...(isImg && {
        ...imageProps,
      })}
    >
      {!isImg ? <MuiIcon icon="file_copy" color="inherit" /> : null}
    </CardMedia>
  );

  const card = (
    <Card
      variant="outlined"
      sx={{ display: "flex", cursor: onEdit ? "pointer" : "auto" }}
      {...(onEdit && {
        onClick: handleEdit,
      })}
    >
      {media}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          gap: 1,
          position: onEdit ? "relative" : undefined,
        }}
      >
        {onEdit ? (
          <IconButton
            icon="edit"
            color="primary"
            sx={{ position: "absolute", right: 0, zIndex: 2 }}
          />
        ) : (
          <>
            {/* Image Editor used on the side panel that has min space, that is why other properties are hidden  */}
            <Typography sx={{ flex: 1 }} noWrap>
              {fileProps?.name ??
                (preview as StorageFileMetadata)?.realName ??
                (preview as File)?.name ??
                ""}
            </Typography>
            <Typography variant="caption">
              {(preview as StorageFileMetadata).fileType ??
                (preview as File)?.type}
            </Typography>
            {fileProps?.size && (
              <Typography variant="caption">
                {bytesToSize(fileProps.size)}
              </Typography>
            )}
            <Link
              href={`${FILE_STORAGE_PREFIX}${
                (preview as StorageFileMetadata).fileName
              }`}
              target="_blank"
            >
              <IconButton
                {...(onDelete && {
                  edge: "end",
                })}
                icon="open_in_new"
                color="secondary"
                tooltip={"Open"}
              />
            </Link>
          </>
        )}
        {onDelete && <IconButton icon="delete_outlined" onClick={onDelete} />}
      </Box>
    </Card>
  );
  return onEdit ? (
    <Tooltip title="Edit" placement="top" sx={{ cursor: "pointer" }}>
      {card}
    </Tooltip>
  ) : (
    card
  );
});

type CommonProps = Partial<DropzoneProps> & {
  error?: string;
  innerRef?: any;
  onDelete?: (fileName: string) => void;
  onEdit?: (storageFile: StorageFileMetadata) => void;
  dense?: boolean;
};

type SingleUploadZone = {
  value: StorageFileMetadata | null;
  onSubmit: (file: File | null) => void;
} & CommonProps;

type MultipleUploadZone = CommonProps & {
  multiple: true;
  value: StorageFileMetadata[] | null;
  onSubmit: (files: File[] | null) => void;
  onUpdate: () => void;
};

export const UploadZone = memo<SingleUploadZone | MultipleUploadZone>(
  ({ value, multiple, onDelete, onEdit, dense = false, ...props }) => {
    const initialValue: StorageFileMetadata[] = multiple
      ? (value as StorageFileMetadata[]) ?? []
      : value
        ? [value]
        : [];

    const { acceptedFiles, getRootProps, getInputProps, isDragAccept } =
      useDropzone({
        onDropAccepted: (nextFiles: File[]) => {
          if (multiple) {
            (props as MultipleUploadZone).onSubmit(nextFiles);
          } else {
            (props as SingleUploadZone).onSubmit(nextFiles[0]);
          }
        },
        ...props,
        multiple,
      });

    const handleRemove = useCallback(
      (removeFile: StorageFileMetadata) => () =>
        onDelete?.(removeFile.fileName),
      [onDelete],
    );

    const handleEdit = useCallback(
      (storageFile: StorageFileMetadata) => () => onEdit?.(storageFile),
      [onEdit],
    );

    const allFiles = initialValue.map(
      (preview: StorageFileMetadata, i: number) => {
        const fileProps = acceptedFiles.find(
          (af) => af.name === preview.realName,
        );

        return (
          <UploadPreviewCard
            key={`Preview-${preview.realName}-{${i}}`}
            onDelete={onDelete && handleRemove(preview)}
            onEdit={onEdit && handleEdit(preview)}
            preview={preview}
            fileProps={fileProps}
            dense={dense}
          />
        );
      },
    );

    return (
      <Stack justifyContent="center" gap={1} width={"100%"}>
        {allFiles}
        <MuiButton
          {...getRootProps({
            className: "dropzone",
          })}
          component="div"
          variant="outlined"
          color={props?.error ? "error" : "primary"}
          startIcon={<MuiIcon icon={"cloud_upload"} />}
          disabled={props?.disabled}
          sx={{
            overflow: "unset",
            minHeight: "90px",
            ...(isDragAccept && {
              borderStyle: "dashed",
            }),
          }}
        >
          {"Select or Drop file"}
          <input {...getInputProps()} ref={props.innerRef} />
        </MuiButton>
      </Stack>
    );
  },
);
