import { useContext } from "react";
import { EnhancedStore, Tuple, configureStore } from "@reduxjs/toolkit";
import { Middlewares } from "@reduxjs/toolkit/dist/configureStore";
import { ReactReduxContext } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { Services } from "services";

import {
  ReduxModule,
  TReducerManager,
  createReducerManager,
} from "./reducerManager";
import { RouterService } from "./router/RouterService";
import { StorageService, storageService } from "./StorageService";
import { GetElementType } from "./types";

type IOptions = {
  services?: any;
  reduxModules?: ReduxModule[];
};

export type AllServices = {
  router: RouterService;
  storage: StorageService;
} & Services;

type TStore = {
  State: TReducerManager["reduce"];
  Middleware: Tuple<Middlewares<any>>;
  ReturnType: EnhancedStore<TStore["State"], never> & {
    reducerManager: TReducerManager;
  };
};

export default function buildStore(
  getElementType: GetElementType,
  routerService: RouterService,
  { services }: IOptions = {},
) {
  const reducerManager = createReducerManager(getElementType);

  const allServices: AllServices = {
    router: routerService,
    storage: storageService,
    ...services,
  };

  const sagaMiddleware = createSagaMiddleware({
    context: {
      reducerManager,
      services: allServices,
    },
  });

  const store = configureStore<TStore["State"], never, TStore["Middleware"]>({
    reducer: reducerManager.reduce,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).prepend(sagaMiddleware),
    devTools: process.env.NODE_ENV !== "production",
  }) as TStore["ReturnType"];

  store.reducerManager = reducerManager;
  reducerManager.initialize(store, sagaMiddleware);

  sagaMiddleware.run(reducerManager.saga);

  return store;
}

export type ExtendedStore = ReturnType<typeof buildStore>;

export const useReducerManager = () => {
  const {
    store: { reducerManager },
  } = useContext<{ store: ExtendedStore }>(ReactReduxContext as any);

  return reducerManager;
};
