import {
  activeGrid,
  activeSavePointId,
  copiedElements,
  creatingPage,
  draggableElementParams,
  editModeOn,
  editorErrors,
  highlighted,
  isDragging,
  isLayoutChanged,
  isResizing,
  newPages,
  newRoutes,
  nextElementId,
  releasing,
  saveError,
  saving,
  selected,
  selectedByPageId,
  uiReleases,
  uiSavePoints,
  updatedElements,
  updatedLayoutDefinition,
  updatedMenu,
  updatedQueryToPagesMapping,
  usedUrls,
  viewList,
} from "../../selectors";

export const selectors = {
  newPages,
  updatedElements,
  updatedLayoutDefinition,
  updatedMenu,
  newRoutes,
  editModeOn,
  selectedByPageId,
  draggableElementParams,
  isResizing,
  isDragging,
  selected,
  activeGrid,
  nextElementId,
  uiReleases,
  uiSavePoints,
  viewList,
  errors: editorErrors,
  saving,
  saveError,
  releasing,
  creatingPage,
  usedUrls,
  activeSavePointId,
  highlighted,
  isLayoutChanged,
  updatedQueryToPagesMapping,
  copiedElements,
};

export type Selectors = typeof selectors;
