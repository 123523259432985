import kebabCase from "lodash/kebabCase";
import { LayoutDefinition } from "core";
import { LogoType } from "./types.ts";

const generateNewFile = (file: File | Blob, name: string) =>
  new File([file], name, { type: file.type ?? "image/png" });

export const generateLogoFileName = (
  logo: File | Blob,
  appName: string,
  defaultExtension = "png",
) => {
  if (!(logo instanceof File) && !(logo instanceof Blob)) {
    throw new Error("Invalid logo type. Expected File or Blob.");
  }

  const extension = logo.type?.split("/").pop() || defaultExtension;

  return generateNewFile(logo, `Logo-${kebabCase(appName)}.${extension}`);
};

export const getFileFromURL = async (dataUrl: string) => {
  try {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], `Logo`, { type: blob.type });
  } catch {
    return null;
  }
};

export const getInitialUploadState = (
  logo: LayoutDefinition["logo"],
): LogoType => (logo?.path?.length ? LogoType.PATH : LogoType.UPLOAD);
