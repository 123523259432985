import { createAction, createActionTypeScoper } from "core/utils/redux";

export function buildActions(scope: string) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    RUN_PROCEDURE: scopeActionType("RUN_PROCEDURE"),
    RUN_PROCEDURE_SUCCESS: scopeActionType("RUN_PROCEDURE_SUCCESS"),
    RUN_PROCEDURE_ERROR: scopeActionType("RUN_PROCEDURE_ERROR"),
  };

  const actions = {
    runProcedure: createAction(types.RUN_PROCEDURE),
    runProcedureSuccess: createAction(types.RUN_PROCEDURE_SUCCESS),
    runProcedureError: createAction(
      types.RUN_PROCEDURE_ERROR,
      (error: any) => ({ error }),
    ),
  };

  return {
    actions,
    types,
  };
}
