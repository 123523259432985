import { omit } from "ramda";
import { useSelector } from "react-redux";
import { selectors as routerSelectors } from "core/router/reduxModule";
import { parseQueries } from "core/router/reduxModule/utils";
import { useRoute } from "utils/hooks";
import { TLocationSearch } from "./types.ts";

export const useLocationSearch = <
  T extends Record<string, string | undefined | null>,
>({
  defaultState,
}: TLocationSearch<T>) => {
  const location = useSelector(routerSelectors.location);
  const route = useRoute();

  const getQueriesFromFilter = (filter: Partial<T>) =>
    Object.keys(filter).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: filter[curr] ?? "",
      }),
      {} as Record<string, string>,
    );

  const changeLocation = (filter: Partial<T>) => {
    const newQuery = getQueriesFromFilter(filter);

    route("replace", location.pathname, newQuery);
  };

  const getInitialState = (namesToOmit: string[]) =>
    (omit(namesToOmit, parseQueries(location.search)) ?? defaultState) as T;

  const getQueryParam = (name: string) => location.queries[name] ?? "";

  return {
    changeLocation,
    getInitialState,
    getQueryParam,
  };
};

const paginateRows = <T>(
  views: T[],
  currentPage: number,
  rowsPerPage: number,
): T[] =>
  views.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage,
  );

export const renderRows = <T>(
  rows: T[] | undefined,
  pagination:
    | (Record<string, unknown> & {
        currentPage: number;
        rowsPerPage: number;
      })
    | undefined,
  fallback: JSX.Element,
) =>
  rows?.length
    ? pagination
      ? paginateRows(rows, pagination.currentPage, pagination.rowsPerPage)
      : rows
    : fallback;
