import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { selectors as sessionSelectors } from "core/session/reduxModule";

export type ErrorWithMessage = Record<"message", string>;
type Params = Record<string, unknown>;

type QueryFnWithoutParams<QueryResult> = (
  token: string,
) => QueryResult | Promise<QueryResult>;

type QueryFnWithParams<QueryResult, QueryFunctionParameters> = (
  token: string,
  params: QueryFunctionParameters,
) => QueryResult | Promise<QueryResult>;

export type Query<QueryResult, QueryFunctionParameters = Params> = {
  queryKey: string;
  queryFn:
    | QueryFnWithParams<QueryResult, QueryFunctionParameters>
    | QueryFnWithoutParams<QueryResult>;
};

export const useToken = () => useSelector(sessionSelectors.token);

export const useAuthenticatedQuery = <
  QueryResult,
  QueryFunctionParameters extends Params = Params,
  QuerySelectResult = QueryResult,
>(
  { queryKey, queryFn }: Query<QueryResult, QueryFunctionParameters>,
  params?: QueryFunctionParameters,
  config?: UseQueryOptions<QueryResult, Error, QuerySelectResult>,
) => {
  const token = useToken() ?? "";

  // if (!token) {
  //  this should never happen
  // }

  const result = useQuery({
    queryKey: [queryKey, token, params],
    queryFn: () => queryFn(token, params as QueryFunctionParameters),
    ...config,
  });

  return result;
};

export const useAuthenticatedMutation = <
  QueryResult,
  QueryFunctionParameters extends Params = Params,
>(
  queryFn:
    | QueryFnWithParams<QueryResult, QueryFunctionParameters>
    | QueryFnWithoutParams<QueryResult>,
  options?: UseMutationOptions<QueryResult, unknown, QueryFunctionParameters>,
) => {
  const token = useToken() ?? "";

  // if (!token) {
  //  this should never happen
  // }

  const mutationFn = async (args: QueryFunctionParameters) =>
    await queryFn(token, args);

  const mutation = useMutation({ mutationFn, ...options });

  return mutation;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) {
    return maybeError;
  }

  try {
    return { message: JSON.stringify(maybeError) };
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return { message: String(maybeError) };
  }
}

export function getApiError(error: unknown) {
  return toErrorWithMessage(error).message;
}
