import { memo, useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";

import { useChartEditorTranslation } from "../../translation";
import { PieChartConfig } from "../../types";
import { defaultElement } from "../defaultElement";

export const SetupComponent = memo(() => {
  const {
    elementModel: {
      config: {
        dataSource,
        dataSource: { viewName, columns },
        hideLabel,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<PieChartConfig>();

  const { labelLabel, labelShowLabel, valueLabel, viewLabel } =
    useChartEditorTranslation();

  const changeDataSource = useCallback(
    (newDataSource: Partial<PieChartConfig["dataSource"]>) =>
      changeConfigValue("dataSource", { ...dataSource, ...newDataSource }),
    [changeConfigValue, dataSource],
  );

  const handleViewNameChange = (newViewName: string) =>
    changeDataSource({
      viewName: newViewName,
      columns: defaultElement.config?.dataSource.columns,
    });

  const handleFieldChange = (
    fieldName: string,
    fieldValue: string[] | string | boolean | number | number[] | null,
  ) =>
    changeDataSource({
      columns: { ...columns, [fieldName]: fieldValue },
    });

  const toggleLabel = useCallback(
    () => changeConfigValue("hideLabel", !hideLabel),
    [changeConfigValue, hideLabel],
  );

  const fields = [
    {
      label: labelLabel,
      value: columns.labelColumnName,
      name: "labelColumnName",
    },
    {
      label: valueLabel,
      value: columns.valueColumnName,
      name: "valueColumnName",
    },
  ];

  const { configTitle } = useEditorTranslation();

  return (
    <Section title={configTitle} wrapped={true}>
      <ViewAutocomplete
        viewValue={viewName}
        viewLabel={viewLabel}
        onViewNameChange={handleViewNameChange}
        onViewFieldChange={handleFieldChange}
        fields={fields}
      />
      <FormControlLabel
        control={<Switch checked={!hideLabel} onChange={toggleLabel} />}
        label={labelShowLabel}
      />
    </Section>
  );
});
