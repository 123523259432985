import { memo } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { useStyles } from "./styles";

type Props = {
  title?: string;
  colSpan?: number;
};

export const EmptyRow = memo<Props>(({ title, colSpan = 10 }) => {
  const {
    classes: { root },
  } = useStyles();
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Typography variant="button" className={root} component="h5">
          {title}
        </Typography>
      </TableCell>
    </TableRow>
  );
});
