import { createUseTranslation } from "core/session/translation";

export const markdownInputEditorTranslation = {
  en: {
    stylingTitle: "Styling",
    labelLabel: "Label",
  },
};

export const useMarkdownInputEditorTranslation = createUseTranslation(
  markdownInputEditorTranslation,
);
