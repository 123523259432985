import * as t from "io-ts";
import { types } from "core/runtime-typing";
import {
  ConnectedReduxModuleProps,
  IElement,
  IElementArrayChild,
  IElementSingleChild,
  UntransformedConfig,
} from "core/types";
import { SelectorTypes } from "core/types/element";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";

import { FormInputConfig } from "../common";

import { ReduxModule } from "./reduxModule";

export const markdownInputSelectors: SelectorTypes<MarkdownInputConfig> = {
  value: types.nullable(types.string()),
  disabled: types.optional(types.boolean()),
};

export const MarkdownInputConfig = t.intersection([
  WithOptionalFieldDataSourceConfig(t.union([t.null, t.string])),
  FormInputConfig,
]);

export type MarkdownInputConfig = t.TypeOf<typeof MarkdownInputConfig>;

export const MarkdownInputTranslationKeys = ["label"] as const;

export type MarkdownInputTranslationKeys =
  (typeof MarkdownInputTranslationKeys)[number];

export type UntransformedMarkdownConfig =
  UntransformedConfig<MarkdownInputConfig>;

export type MarkdownInput = IElement<
  MarkdownInputConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  MarkdownInputTranslationKeys
>;

export type DefaultMarkdownInputProps = ConnectedReduxModuleProps<
  ReduxModule,
  MarkdownInput
>;
