import { createUseTranslation } from "core/session/translation";

export const stylePanelTranslation = {
  en: {
    colorTitle: "App Colors",
    logoTitle: "Logo",
    primaryLabel: "Primary",
    pathLabel: "Image Path",
    secondaryLabel: "Secondary",
    urlLabel: "Link URL",
    urlError: "Invalid URL",
    pathError: "Invalid Image Format",
    pathHint: "Formats: JPG(JPEG), PNG, SVG",
    advancedTitle: "Advanced",
    borderRadiusLabel: "Border Radius",
    customStyles: "Custom CSS",
    customStylesTooltip:
      "Elements can be selected via special HTML attributes, namely `data-cypex-element-id` and `data-cypex-element-type`.",
    customStylesTooltipExample: `[data-cypex-element-type=default_table] tr td:first-of-type {
  background: lightgreen;
}`,
    tryOutLabel: "Try out",
    logoTypeLabel: "Logo Source Type",
    logoTypePathLabel: "Path",
    logoTypeUploadLabel: "Upload",
    logoTypeUploadTooltip:
      "Choose this option if you want to upload your own logo",
    logoTypePathTooltip:
      "Choose this option if you want to specify a file from the CYPEX public folder",
    editLogoLabel: "Edit Logo",
    scaleLabel: "Scale",
    rotateLabel: "Rotate",
    logoEditorHelperLabel: "Manipulate the logo by moving it around.",
    pathHelperText:
      "Type in a path from the CYPEX public folder (e.g. /public/logo.png)",
    errorText: "An error has occurred.",
    deleteLabel: "Delete",
  },
  de: {},
  es: {},
};

export const useTranslation = createUseTranslation(stylePanelTranslation);
