import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as editorActions, selectors } from "core/editor/reduxModule";
import { Context } from "./context";

export const EditorModeProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();

  const isEditorModeEnabled = useSelector(selectors.editModeOn);

  const toggleEditorMode = (nextState?: boolean) => {
    dispatch(editorActions.toggleEditMode(nextState));
  };

  return (
    <Context.Provider value={{ isEditorModeEnabled, toggleEditorMode }}>
      {children}
    </Context.Provider>
  );
};
