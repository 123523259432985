import { memo } from "react";
import { useDispatch } from "react-redux";
import { IElementComponentProps, RouterReduxModuleComponentProps } from "core";
import { getPushArguments } from "core/router";
import { actions as routerActions } from "core/router/reduxModule";
import { AlertBox } from "elementTypes/common/AlertBox";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { Colors } from "elementTypes/common/StyledTypography/utils";
import { NON_BREAKING_SPACE } from "elementTypes/common/utils";

import { MapStateToProps } from "./container";
import useStyles from "./styles";
import { useInternalLinkButtonTranslation } from "./translation";
import { InternalLinkButton } from "./types";

type Props = IElementComponentProps<
  Record<string, unknown>,
  InternalLinkButton
> &
  Pick<RouterReduxModuleComponentProps, "push"> & {
    className?: string;
  } & MapStateToProps;

export const DefaultInternalLinkButton = memo<Props>(
  ({
    element: {
      i18n: { label },
      config: {
        linkTo: { pageId },
        icon,
        fullWidth,
        size,
        variant,
        isIcon,
      },
    },
    color = "default",
    params,
    pages,
    disabled,
    ...rest
  }) => {
    const {
      classes: { root },
      cx,
    } = useStyles();
    const translation = useInternalLinkButtonTranslation();
    const dispatch = useDispatch();
    const page = pages[pageId];
    if (!page) {
      return <>{NON_BREAKING_SPACE}</>;
    }

    const emptyParams = Object.keys(page.params).filter(
      (paramKey) =>
        params[paramKey as keyof typeof params] === undefined ||
        !`${params[paramKey as keyof typeof params]}`.trim(),
    );
    if (emptyParams.length) {
      return (
        <AlertBox
          title={translation.configErrorTitle}
          message={(emptyParams.length === 1
            ? translation.configErrorMessage
            : translation.configErrorPlurarInflectedMessage
          ).replace("{{emptyParams}}", emptyParams.join(", "))}
        />
      );
    }

    const pageUrl = getPushArguments(page, params)[0];

    const navigate = () => dispatch(routerActions.push(pageUrl));

    const commonProps = {
      color: Colors[color],
      size,
      disabled,
      onClick: navigate,
    };

    return isIcon || (isIcon === undefined && icon) ? (
      <IconButton icon={icon} tooltip={label} {...(commonProps as any)} />
    ) : (
      <Button
        label={label}
        fullWidth={fullWidth}
        {...commonProps}
        className={cx(rest.className, {
          [root]: fullWidth,
        })}
        variant={variant}
        {...(icon && {
          iconRight: icon,
        })}
      />
    );
  },
);

DefaultInternalLinkButton.displayName = "DefaultInternalLinkButton";
