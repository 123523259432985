import { memo } from "react";

import { Autocomplete } from "../../../../../elementTypes/common/Autocomplete";
import { IAutocompleteValue } from "../../../../../elementTypes/common/Autocomplete/types";
import { IObjectView, IObjectViewField, Translation } from "../../../../types";
import { defaultItemSize } from "../component";
import { customRenderOption } from "../utils";

export type IField = {
  label: string;
  value: IAutocompleteValue;
  name: string;
  isClearable?: boolean;
};

type Props = {
  onChange: (fieldValue: IAutocompleteValue) => void;
  getTranslatedLabel: (i18n: Translation<keyof any>) => string;
  view?: IObjectView;
  disabled?: boolean;
} & IField;

export const FieldsAutocomplete = memo<Props>(
  ({
    view,
    value,
    name,
    label,
    disabled,
    isClearable,
    onChange,
    getTranslatedLabel,
  }) => {
    const isMulti = Array.isArray(value);

    const fieldOptions = view?.fields.map((field: IObjectViewField) => ({
      value: field.name,
      label: getTranslatedLabel(field.i18n),
    }));

    return (
      <Autocomplete
        options={fieldOptions || []}
        {...{
          value,
          label,
          name,
          onChange,
          isMulti,
          disabled,
          customRenderOption,
          defaultItemSize,
          isClearable,
          isLoading: false,
          virtualizedList: true,
          disablePortal: true,
        }}
      />
    );
  },
);
