import { memo, useEffect } from "react";

import { useElementEditorContext } from "core/editor";
import { useSessionContext } from "core/session";
import { getNewTranslation } from "../../../core/session/translation/utils.ts";
import { UntransformedTabsConfig } from "../types";
import { migrateLabelsToTranslation } from "../utils";
import { StylingComponent, TabsComponent } from "./components";

export const DefaultTabsEditor = memo(() => {
  const {
    elementModel: {
      config: { labels },
      i18n,
    },
    changeConfigValue,
    changeTranslation,
  } = useElementEditorContext<UntransformedTabsConfig>();

  const { language } = useSessionContext();

  useEffect(() => {
    if (labels?.length) {
      // labels prop is deprecated, use translation
      // *migrateLabelsToTranslation* can be deleted in the future
      const newI18n = getNewTranslation(
        i18n,
        language,
        migrateLabelsToTranslation(labels),
      );
      changeTranslation(newI18n);
      changeConfigValue("labels", undefined);
    }
  }, [labels, i18n]);

  return (
    <>
      <TabsComponent />
      <StylingComponent />
    </>
  );
});
