import MuiButton, { ButtonProps } from "@mui/material/Button";
import { Palette, PaletteColor, alpha } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { path } from "ramda";

import {
  Color,
  Colors,
  ContrastColor,
  ContrastColors,
} from "../StyledTypography/utils";

export const ColorButton = styled(MuiButton)<ButtonProps>(({
  theme,
  variant: variantProp,
  color: colorProp,
}) => {
  let styles = {
    root: {},
  };

  if (!colorProp) {
    return styles;
  }
  const color: string | unknown = path(colorProp.split("."), theme.palette);

  if (color === undefined) {
    return styles;
  }

  const colorNamespace = colorProp.split(".") as [keyof Palette, string];

  const namespace = theme.palette[colorNamespace[0]] as PaletteColor;
  // we don't have the "from"

  const colorMapping = Object.entries(Colors).find(
    ([_from, to]) => colorProp === to,
  ) as [ContrastColor, Color] | undefined;

  if (!colorMapping) {
    return styles;
  }

  const contrastColor: string | undefined = path(
    ContrastColors[colorMapping![0]].split("."),
    theme.palette,
  );

  if (!contrastColor) {
    return styles;
  }
  // careful, the namespaces "text" and common do not have the properties accessed below
  // e.g. contrastText, main, dark
  // that's why we need to fallback via "??"

  // contained styles
  // https://github.com/mui-org/material-ui/blob/f285808fbf8728b0cc0435e65a76dbc691be3643/packages/material-ui/src/Button/Button.js#L138
  const containedRoot = {
    color: contrastColor,
    backgroundColor: namespace.main ?? color,
    "&:hover": {
      backgroundColor: namespace.dark ?? color,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: namespace.main ?? color,
      },
    },
  };

  // outlined styles
  // https://github.com/mui-org/material-ui/blob/f285808fbf8728b0cc0435e65a76dbc691be3643/packages/material-ui/src/Button/Button.js#L80

  const outlinedRoot = {
    color: namespace.main ?? color,
    border: `1px solid ${alpha(namespace.main ?? color, 0.5)}`,
    "&:hover": {
      border: `1px solid ${namespace.main ?? color}`,
      backgroundColor: alpha(
        namespace.main ?? color,
        theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  };

  styles = {
    ...styles,
    root: variantProp === "contained" ? containedRoot : outlinedRoot,
  };

  return styles;
});
