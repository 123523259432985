import { buildCustomExpressionValue } from "core";
import {
  FilterGroupCombinator,
  IFilterGroup,
} from "elementTypes/default_table/toolsPanel";

export const defaultFilterValue: IFilterGroup = {
  combinator: FilterGroupCombinator.AND,
  filters: [],
};

export const defaultExpressionValue = buildCustomExpressionValue(
  JSON.stringify(defaultFilterValue, null, 2),
);
