import superagent, { Response, SuperAgentRequest } from "superagent";
import { BuiltinContentType } from "services/api/types/ContentType";
import { getHeaders } from "./headersUtil";

// Listener ID generator
let nextListenerId = 0;

// Token expired listeners
const tokenExpiredListeners: Record<number, () => void> = {};

/**
 * Registers a callback to be invoked when the token expires.
 * @param callback - The callback to invoke.
 * @returns A function to unregister the callback.
 */
export const onTokenExpired = (callback: () => void) => {
  const id = ++nextListenerId;
  tokenExpiredListeners[id] = callback;
  return () => delete tokenExpiredListeners[id];
};

/**
 * Adds common headers and authorization token to the request.
 * @param req - The request object.
 * @param token - The authorization token.
 * @param responseFormat - The response format.
 * @returns The modified request object.
 */
export function withCommonHeaders(
  req: SuperAgentRequest,
  token?: string | null,
  responseFormat: BuiltinContentType = "json",
): SuperAgentRequest {
  const headers = getHeaders(responseFormat);
  Object.entries(headers).forEach(([key, value]) => {
    req = req.set(key, value);
  });

  if (token) {
    req = withAuthHeader(req, token);
  }
  return req;
}

/**
 * Adds an authorization header to the request.
 * @param req - The request object.
 * @param token - The authorization token.
 * @returns The modified request object.
 */
export function withAuthHeader(
  req: SuperAgentRequest,
  token: string,
): SuperAgentRequest {
  return req.set("Authorization", `Bearer ${token}`);
}

/**
 * Handles response errors and notifies token expired listeners if needed.
 * @param err - The error object.
 * @returns The error response body or a new error object.
 */
export function getResponseError(err: any): any {
  if (
    err.response?.body &&
    ((err.status === 401 &&
      (err.response.body.name === "AuthorizationRequiredError" ||
        err.response.body.message === "JWT expired")) ||
      (err.response.body.message &&
        err.response.body.message.includes(
          "'NoneType' object has no attribute 'language_id'",
        )))
  ) {
    notifyTokenExpiredListeners();
  }

  return err.response?.body ?? err.response?.error ?? new Error(err.toString());
}

/**
 * Notifies all registered token expired listeners.
 */
function notifyTokenExpiredListeners(): void {
  for (const id of Object.keys(tokenExpiredListeners)) {
    const nextId = +id;
    const listener = tokenExpiredListeners[nextId];
    if (listener) {
      listener();
    }
  }
}

/**
 * API client for making requests.
 * @param method - The HTTP method.
 * @param url - The URL to request.
 * @param body - The request body.
 * @param query - The query parameters.
 * @param headers - The custom headers.
 * @param token - The authorization token.
 * @param responseFormat - The response format.
 * @returns The response data.
 */
export const apiClient = async <T>(
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
  url: string,
  token?: string | null,
  body?: any,
  query?: any,
  headers: Record<string, string> = {},
  responseFormat: BuiltinContentType = "json",
): Promise<T> => {
  try {
    let req: SuperAgentRequest = superagent(method, url);

    if (query) {
      req = req.query(query);
    }

    if (body) {
      req = req.send(body);
    }

    // Add common headers and authorization token
    req = withCommonHeaders(req, token, responseFormat);
    req.set(headers);

    const res: Response = await req;
    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};
