import { memo } from "react";
import { pathOr } from "ramda";
import { Controller } from "react-hook-form";

import { LayoutDefinition } from "core/types";
import { ColorInput } from "elementTypes/common/ColorInput";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";

import { PALETTE } from "utils/ci-colors";
import { useTranslation } from "../translation";

type Props = {
  name: string;
  layoutDefinition: LayoutDefinition | null;
};

export const InputColor = memo<Props>(({ name, layoutDefinition }) => {
  const translation = useTranslation();
  const { control } = useHookFormContext();
  const getDefault = (inputColor: string) =>
    inputColor === "primary" ? PALETTE.PRIMARY : PALETTE.SECONDARY;

  const value = pathOr(
    getDefault(name),
    ["theme", "palette", name, "main"],
    layoutDefinition,
  ) as string;

  return (
    <Controller
      render={({ field: { ref, ...field } }) => (
        <ColorInput
          label={translation[`${name}Label`]}
          placement="top"
          {...field}
          changeValue={field.onChange}
          innerRef={ref}
        />
      )}
      name={`theme.palette.${name}.main`}
      control={control}
      defaultValue={value ?? ""}
    />
  );
});
