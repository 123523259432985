import { createUseTranslation } from "core/session/translation";

const workflowActivateTranslations = {
  en: {
    activateLabel: "Enforcement Enabled",
    deactivateLabel: "Enforcement Disabled",
    activateTooltip:
      "When 'State Enforcement' is enabled, users can only update the state column values by selecting from a predefined set of allowed values. This ensures that data integrity is maintained and that the workflow follows a structured path. Users won't be able to add, delete, or modify state values independently. To make changes, select from the available options in the user interface.",
    deactivateTooltip:
      "When 'State Enforcement' is disabled, users have more flexibility in managing state column values. They can freely add, delete, or modify state values, providing a more dynamic workflow. However, it's essential to ensure that data integrity is maintained by following best practices when making changes. To update state values, simply edit the field in the user interface.",
    helperText: `In case of 
      enforced state changes CYPEX will create simple triggers on the underlying 
      tables to make sure that only valid changes can be made. Usually enforced state 
      changes should be chosen because they make sure that a data model cannot 
      contain faulty data. However, in some cases it might also make sense to work 
      with states that are not brutally enforced by CYPEX.`,
    activationSuccess: "The workflow is now active",
    deactivationSuccess: "The workflow is now inactive",
  },
  es: {
    activateLabel: "Aplicación habilitada",
    deactivateLabel: "Aplicación deshabilitada",
    activateTooltip: "Workflow is available for use in UI",
    deactivateTooltip:
      "Workflow is not available for use in UI. For admin use only",
    helperText: `In case of 
    enforced state changes CYPEX will create simple triggers on the underlying 
    tables to make sure that only valid changes can be made. Usually enforced state 
    changes should be chosen because they make sure that a data model cannot 
    contain faulty data. However, in some cases it might also make sense to work 
    with states that are not brutally enforced by CYPEX.`,
    activationSuccess: "The workflow is now active",
    deactivationSuccess: "The workflow is now inactive",
  },
};

export const useWorkflowActivateTranslations = createUseTranslation(
  workflowActivateTranslations,
);
