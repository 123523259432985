import { ConnectedProps, connect } from "react-redux";

import { actions, selectors } from "../reduxModule";
import { EditorContainer } from "./component";

export const mapStateToProps = (state: any) => ({
  draggableElementParams: selectors.draggableElementParams(state),
  isLayoutChanged: selectors.isLayoutChanged(state),
});

export const mapDispatchToProps = {
  changeConfigValue: actions.changeConfigValue,
  changePositionValue: actions.changePositionValue,
  changeTranslationValue: actions.changeTranslationValue,
  setDraggableElement: actions.setDraggableElement,
  unselectElement: actions.unselectElement,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;

export const EditorLayout = connector(EditorContainer);
