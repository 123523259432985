// Response formats and their corresponding headers
export const contentTypeFormat = {
  json: "application/json",
  geo: "application/geo+json",
  text: "text/plain",
  xml: "text/xml",
  bytea: "application/octet-stream",
} as const;

// Headers to set for each response format
export const contentTypeField = {
  json: "Content-Type",
  geo: "Accept",
  text: "Content-Type",
  xml: "Content-Type",
  bytea: "Content-Type",
};

// Function to get headers for a given response format
export function getHeaders(responseFormat: keyof typeof contentTypeFormat) {
  const headers: Record<string, string> = {};

  const reqField = contentTypeField[responseFormat];
  const reqFormat = contentTypeFormat[responseFormat];

  headers[reqField] = reqFormat;

  return headers;
}
