import { ElementGroup, IRawElementType } from "core";

import Component from "./container";
import { MetaQueryEditor, defaultElement, editorMetadata } from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import {
  MetaQueryConfig,
  MetaQueryTranslationKeys,
  metaQuerySelectors,
} from "./types";
import { getQueriesFromConfig } from "./utils";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "meta_query",
  component: Component,
  group: ElementGroup.Meta,
  editable: false,
  configType: MetaQueryConfig,
  selectorTypes: metaQuerySelectors,
  translationKeys: MetaQueryTranslationKeys,
  defaultElement,
  editorComponent: MetaQueryEditor,
  editorMetadata,
  getQueriesFromConfig,
};

export default elementType;
