import capitalize from "lodash/capitalize";
import { QueryToPagesMappingValue } from "core";
import { StorageFileMetadata } from "services/api/types/FileStorage.ts";
import { getAvailableQueries } from "staticPages/admin/pages/apps/pages/edit/utils.ts";

import { QueryObject } from "staticPages/admin/pages/modelBuilder/components/types";
import { Role, RoleOption } from "./types";

export const getRoleOptions = (roles: Role[] = []) =>
  roles.map(
    (role) =>
      ({
        label: role.name,
        value: role.name,
      }) as RoleOption,
  );

export const getOptions =
  <ItemType extends Record<string, unknown>, K extends keyof ItemType>(
    name: K,
  ) =>
  (list: ItemType[] = []) =>
    list.map((item) => {
      const value = item[name];
      return {
        label: capitalize(value as string),
        value,
      };
    });

export const getFileOptions = (files: StorageFileMetadata[] = []) =>
  files.map(
    (file) =>
      ({
        label: file.realName,
        value: file.fileName,
        title: `${file.realName}${
          file.createdAt
            ? ` - ${new Date(file.createdAt).toLocaleString()}`
            : ""
        }`,
      }) as { label: string; value: string; title: string },
  );

export const getQueriesOptions =
  (queryToPagesMapping?: Record<string, QueryToPagesMappingValue>) =>
  (queryList: QueryObject[]) =>
    queryToPagesMapping
      ? getAvailableQueries(queryToPagesMapping, queryList)
      : queryList;
