import isEmpty from "lodash/isEmpty";

export const getServerError = (error: unknown) =>
  !error || (typeof error === "object" && isEmpty(error))
    ? "An error has occurred."
    : typeof error === "string"
      ? error
      : "message" in ((error ?? {}) as Error)
        ? (error as Error).message
        : typeof error === "object" && "message" in error
          ? (error as Record<string, string>).message
          : (error as any).message;
