import { createUseTranslation } from "core/session/translation";

export const editorDataDisplayTranslation = {
  en: {
    modeTitle: "Mode",
    firstRowLabel: "First Row",
    allDataLabel: "All Data",
  },
};

export const useEditorDataDisplayTranslation = createUseTranslation(
  editorDataDisplayTranslation,
);
