import { IDefaultElement } from "core";
import { defaultExpressionValue } from "elementTypes/common/Echarts/utils";

import { UntransformedPieChartConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedPieChartConfig> = {
  config: {
    dataSource: {
      viewName: "",
      columns: {
        valueColumnName: "",
        labelColumnName: "",
      },
    },
    hideLabel: false,
    filter: defaultExpressionValue,
  },
  i18n: {
    en: {
      title: "",
    },
  },
};
