import { MutableRefObject } from "react";
import AvatarEditor from "react-avatar-editor";
import { LayoutDefinition } from "core";
import { StorageFileMetadata } from "services/api/types/FileStorage.ts";
import { properties } from "./consts.ts";

export enum LogoType {
  UPLOAD = "Upload",
  PATH = "Path",
}

type TCommonProps = {
  dense?: boolean;
};

export type Props = TCommonProps & {
  layoutDefinition: LayoutDefinition;
  errors: Record<string, string> | null;
};

export type TLogoUpload = TCommonProps & {
  defaultValue?: File | string;
  openModal: (storageFile?: StorageFileMetadata) => void;
};

export type TLogoEditor = {
  innerRef: MutableRefObject<AvatarEditor | null>;
  fileName: string | File;
};

export type TProperties = (typeof properties)[number];
