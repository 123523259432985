import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

type Props = {
  bordered: boolean;
};

export const useStyles = makeStyles<Props>()((theme: Theme, { bordered }) => ({
  root: {
    position: "relative",
    height: "100%",
    width: "100%",
    // min width is mandatory for working in the expression editor with flex
    minWidth: 0,
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  popperBox: {
    "&.CodeEditor-info-box": {
      zIndex: 1550,
    },
  },
  hintsContainer: {
    "&.CodeEditor-hints-container": {
      "& .autocomplete-hint-custom.CodeMirror-hint.CodeMirror-hint-active": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.getContrastText(theme.palette.primary.light),
      },
      "& .autocomplete-hint-builtin.CodeMirror-hint.CodeMirror-hint-active": {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.contrastText,
      },
      "& .autocomplete-hint-custom.CodeMirror-hint": {
        backgroundColor: "transparent",
        color: theme.palette.text.primary,
      },
    },
  },
  adaptToMuiStyles: {
    "& .CodeMirror pre.CodeMirror-line": {
      paddingLeft: theme.spacing(1.5),
      paddingTop: theme.spacing(1),
      fontSize: "0.8rem",
    },
  },
  codeMirror: {
    height: "100%",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    overflow: "auto",
    "& .CodeMirror": {
      background: "unset",
      height: "100%",
      width: "100%",
    },
    "& .CodeMirror.CodeMirror-fullscreen": {
      position: "fixed",
      zIndex: 1300,
      background: "white",
      top: 96,
      bottom: 40,
    },
  },
  fieldset: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.background.paper,
    pointerEvents: "none",
    padding: 0,
    // border style is "groove" by default, making the border hardly visible
    borderRadius: theme.shape.borderRadius,
    border: bordered ? `1px solid ${theme.palette.divider}` : "none",
    "& legend": {
      marginLeft: theme.spacing(),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      transform: "translateY(-4px)",
    },
    "&:hover, &$focused": {
      borderColor: theme.palette.text.primary,
    },
  },
  focused: {},
  hasError: {
    borderColor: theme.palette.error.main,
  },
}));
