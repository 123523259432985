import { format, startOfToday } from "date-fns";
import { buildCustomExpressionValue, getExpression } from "core";

export function toUpperCamelCase(s: string): string {
  const words = s.split(/\s|_|-/);
  let result = "";
  let word;
  for (word of words) {
    result += word.charAt(0).toUpperCase() + word.slice(1);
  }
  return result;
}

export function stringToDateObject(str: string | null): Date | null {
  if (str === null) {
    return null;
  }

  let value: Date | null;

  value = new Date(str);
  if (isNaN(value.getTime())) {
    // handle time value
    // time string is in format "HH:MM:SS"
    value = new Date(format(startOfToday(), "yyyy-MM-dd ") + str);
  }
  if (isNaN(value.getTime())) {
    value = null;
  }
  return value;
}

export function isStringExists(string?: string | null): boolean {
  return Boolean(string && !!string.trim().length);
}

export const capitalizeFirstLetter = (str: string) =>
  str.replace(/^\w/, (c: string) => c.toUpperCase());

export const allowedCharactersPattern = new RegExp("^([a-zA-Z0-9 _-]+)$");

export const isAlphaNumeric = (value: string): boolean =>
  allowedCharactersPattern.test(value);

export const isURL = (str: string) =>
  /^(?:\w+:)?\/\/([^\s]+\S{2}|localhost[?\d]*)\S*$/.test(str);

export const isImagePath = (str: string) => /\.(jpeg|jpg|svg|png)$/.test(str);

export const kebabToCamelCase = (str: string) =>
  str.replace(/-.|_./g, (x) => (x[1] ? x[1].toUpperCase() : x[1] ?? ""));

export const urlPathPattern = new RegExp(
  "^/(([a-zA-Z0-9]+[a-zA-Z0-9-_]+/)*[a-zA-Z0-9-_]*[a-zA-Z0-9])?$",
);

export const humanize = (str: string) => {
  const humanizedStr = str
    .replace(/-/g, " ")
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\s{2,}/g, " ")
    .trim();

  return capitalizeFirstLetter(humanizedStr);
};

export const humanizeCamelCase = (str: string) => {
  if (!str) {
    return undefined;
  }
  const arr = str?.match(/^[a-z]+|[A-Z][a-z]*/g) ?? [];
  return arr
    .map((x) => (x[0] ? x[0].toUpperCase() + x.substring(1).toLowerCase() : ""))
    .join(" ");
};

// This method is used for migration purposes
// from Material UI v4 to Material UI v5
export const pxToNumber = (input: string | number | undefined) =>
  input
    ? typeof input === "number"
      ? input
      : parseInt(input.slice(0, -2), 10)
    : 0;

export const isOverflowing = (textContainer: HTMLSpanElement | null) =>
  textContainer ? textContainer.offsetWidth < textContainer.scrollWidth : false;

export const handleToggleExpressionMode = (
  isExpression: boolean,
  value: string,
  changeValue: (newValue: string) => void,
) => {
  const nextVal = isExpression
    ? getExpression(value).replace(/"/g, "")
    : buildCustomExpressionValue(`"${value}"`);

  changeValue(nextVal);
};

export const replaceSubstringAny = (
  str: any,
  search: RegExp,
  replace: string,
) => {
  if (typeof str !== "string") {
    return str;
  }
  return search.test(str) ? str.replace(search, replace) : str;
};
