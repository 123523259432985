export const RoutePaths = {
  Admin: "/admin",

  Roles: "/admin/roles",
  RoleCreate: "/admin/roles/create",

  Database: "/admin/database",
  DatabaseWorkflow: "/admin/database/workflow",
  DatabaseCustomQuery: "/admin/database/customQuery",
  DatabaseCustomQueryGet: "/admin/database/customQuery/:queryName",

  Apps: "/admin/apps",
  AppsCreate: "/admin/apps/create",
  AppsGenerate: "/admin/apps/generate",
  AppsEdit: "/admin/apps/edit/:uiName",
  AppsEditByName: (uiName: string) => `/admin/apps/edit/${uiName}`,

  Users: "/admin/users",
  UsersCreate: "/admin/users/create",
  UsersEdit: "/admin/users/edit/:userId",
  UsersView: "/admin/users/view/:userId",

  Ldap: "/admin/ldap",

  AdminSettings: "/admin/settings",
  AdminSettingsEdit: "/admin/settings/edit",

  Audits: "/admin/audits",
  AuditsTables: "/admin/audits/tables",
  AuditUsers: "/admin/audits/users",

  Files: "/admin/files",
  FilesUpload: "/admin/files/upload",
  FileEdit: "/admin/files/edit/:fileName",
  FileEditByName: (fileName: string) => `/admin/files/edit/${fileName}`,

  DataApi: "/admin/data-api",

  AppRepos: "/admin/repositories/applications",
  RepositoryConfig: "/admin/repositories/configuration",
  RepositoryConfigGet: "/admin/repositories/configuration/:id",
  RepositoryConfigNew: "/admin/repositories/configuration/new",

  Extensions: "/admin/extensions",
  Internal: "/admin/internal",
  Config: "/admin/config",

  Login: "/login",
  Maintenance: "/maintenance",
  NoAppsAvailable: "/no-apps-available",
  Settings: "/settings",
} as const;
