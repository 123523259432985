import { useCallback, useMemo } from "react";
import * as Either from "fp-ts/lib/Either";
import * as t from "io-ts";
import { memoize } from "proxy-memoize";
import { useSelector } from "react-redux";

import { containsModule, createElementsProxy } from "core";

import { useReducerManager } from "core/buildStore";
import { useReduxModuleContext } from "core/context/ReduxModuleContext";
import * as selectors from "core/selectors";
import { lodash } from "utils/libs/lodash";

export function usePageIdentifierLabelSelector(
  pageIdentifierLabelExpression?: string,
) {
  const reduxModuleContext = useReduxModuleContext();

  const reducerManager = useReducerManager();

  const location = useSelector(selectors.location)!;

  const getModule = reducerManager.getModuleGetter(reduxModuleContext.idMaps);
  // const lodash = useLodash();
  const buildPageLabelSelector = useCallback(
    (
      value: string,
    ): Either.Either<string, (state: any) => Either.Either<string, any>> => {
      const funcBody = `'use strict'; return ${value}`;

      try {
        const expressionFunc = new Function(
          "elements",
          "location",
          "_",
          funcBody,
        );
        return Either.right((state: any) => {
          try {
            const val = expressionFunc(
              createElementsProxy(state, getModule, location),
              location,
              lodash,
            );

            // check if val contains element module
            // this most likely happens while editing a custom expression
            // if not caught early, this can potentially lead to infinite loops
            if (containsModule(val)) {
              throw new Error("Value must not contain module.");
            }

            const result = t
              .union([t.string, t.number, t.boolean, t.null, t.undefined])
              .decode(val);

            if (Either.isRight(result)) {
              return result;
            } else {
              return Either.left("Invalid value");
            }
          } catch (e) {
            // runtime error
            if (e instanceof Error) {
              return Either.left(e.toString());
            }
            return Either.left("Runtime error");
          }
        });
      } catch (e) {
        // invalid syntax
        if (e instanceof Error) {
          return Either.left(e.toString());
        }
        return Either.left("Invalid syntax");
      }
    },
    [getModule, location],
  );

  const pageIdentifierLabelSelector = useMemo(
    () =>
      pageIdentifierLabelExpression
        ? buildPageLabelSelector(pageIdentifierLabelExpression)
        : Either.left("Identifier label is empty"),
    [buildPageLabelSelector, pageIdentifierLabelExpression],
  );

  return pageIdentifierLabelSelector;
}

export function usePageIdentifierLabel(pageIdentifierLabelExpression?: string) {
  const pageIdentifierLabelSelector = usePageIdentifierLabelSelector(
    pageIdentifierLabelExpression,
  );

  const pageLabel = useSelector(
    useMemo(
      () =>
        memoize((state) =>
          Either.isRight(pageIdentifierLabelSelector)
            ? pageIdentifierLabelSelector.right(state)
            : Either.right(null),
        ),
      [pageIdentifierLabelSelector],
    ),
  );

  if (Either.isRight(pageLabel)) {
    return pageLabel.right;
  }
  return null;
}
