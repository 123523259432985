import AdminService from "services/admin";
import { useAuthenticatedQuery } from "../utils";

import { QueryKeys } from "./queryKeys";

const services = AdminService.getInstance();

type Params = { schemaName: string; tableName: string };

export type AuditList = { schema: string; table: string };

export const fetchTableAudit = {
  queryKey: QueryKeys.fetchTableAudit,
  queryFn: services.getTableAudit,
};

export const fetchAuditTableList = {
  queryKey: QueryKeys.fetchAuditTableList,
  queryFn: services.getAuditTableList,
};

export const useTableAudit = (params: Params) =>
  useAuthenticatedQuery(fetchTableAudit, params);

export const useAuditTableList = () =>
  useAuthenticatedQuery(fetchAuditTableList);
