import { connect } from "react-redux";

import { selectors as editorSelectors } from "../../editor/reduxModule";
import { selectors as routerSelectors } from "../../router/reduxModule";
import { selectors as sessionSelectors } from "../reduxModule";

import { SessionLayout as LComponent } from "./component";

const mapStateToProps = (state: any) => ({
  loadAppMetadata: sessionSelectors.loadAppMetadata(state),
  appMetadata: sessionSelectors.appMetadata(state),
  ui: sessionSelectors.ui(state),
  page: routerSelectors.page(state),
  isStaticPage: routerSelectors.isStaticPage(state),
  isAdminPage: routerSelectors.isAdminPage(state),
  isLoggedIn: sessionSelectors.isLoggedIn(state),
  isAdmin: sessionSelectors.isAdmin(state),
  isEditModeOn: editorSelectors.editModeOn(state),
});

export type MapStateToProps = typeof mapStateToProps;

export const Layout = connect(mapStateToProps)(LComponent);
