import { TableDataSource } from "elementInterfaces";

import { Actions, Types } from "./types";

export function buildDataSourceInterface(actions: Actions, types: Types) {
  return TableDataSource.implement({
    updateRow: actions.updateRow,
    deleteRow: actions.deleteRow,
    reload: actions.load,
    enqueueSnackbar: actions.enqueueSnackbar,
    types: {
      DATA_READY: types.LOAD_SUCCESS,
    },
  });
}
