import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Text Field",
      description: "Show unformatted text",
    },
    de: {
      description: "Zeige unformatierten Text an",
    },
  },
};
