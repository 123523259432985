import { IReduxModuleFactoryArgs } from "core";

import { MultipleFileInput } from "../types";

import { controlledReduxModuleFactory } from "./controlled";
import { uncontrolledReduxModuleFactory } from "./uncontrolled";

export function reduxModuleFactory({
  path,
  element,
}: IReduxModuleFactoryArgs<MultipleFileInput>) {
  return element.config.dataSource
    ? controlledReduxModuleFactory({
        path,
        element,
      } as IReduxModuleFactoryArgs<MultipleFileInput>)
    : uncontrolledReduxModuleFactory({
        path,
        element,
      } as IReduxModuleFactoryArgs<MultipleFileInput>);
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
