import { IDefaultElement } from "core/types/element";

import { UntransformedJsonInputConfigConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedJsonInputConfigConfig> =
  {
    i18n: {
      en: {
        label: "",
      },
    },
  };
