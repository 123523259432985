import { memo, useCallback, useEffect, useState } from "react";
import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
  useElementEditorContext,
  useObjectViewList,
  useParentForm,
} from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";
import { Translation } from "core/types";
import { AlertBox } from "elementTypes/common/AlertBox";
import { usePrevious } from "utils/hooks";

import { SubFormTableConfig } from "../types";

import { FieldsComponent, TranslationComponent } from "./components";
import { useSubformTableEditorTranslation } from "./translation.ts";

export const SubFormTableEditor = memo(() => {
  const {
    elementModel: {
      config: { dataSource, fields },
      i18n,
    },
    changeConfigValue,
    changeTranslation,
  } = useElementEditorContext<SubFormTableConfig>();
  const { language } = useSessionContext();
  const { getViewByName } = useObjectViewList();
  const [lang, setLang] = useState<Language>(language);
  const { parentFormNoReferenceLabel } = useSubformTableEditorTranslation();

  const { fieldPath, elementId } = dataSource ?? {};
  const prevFieldPath = usePrevious(fieldPath);
  const prevElementId = usePrevious(elementId);
  const { parentDataSource } = useParentForm();

  const [viewName] = fieldPath ?? [];
  const { identifierFieldName, referencingFieldName } =
    parentDataSource?.multiReference?.[viewName] ?? {};

  const getViewFields = useCallback(() => {
    if (!viewName) {
      return undefined;
    }

    const view = getViewByName(String(viewName));
    return view?.fields;
  }, [viewName, getViewByName]);

  const viewFields = getViewFields();

  const fieldPathHelperText =
    parentDataSource &&
    !Object.keys(parentDataSource.multiReference ?? {}).length ? (
      <AlertBox severity="warning" alertTitle={parentFormNoReferenceLabel} />
    ) : null;

  useEffect(() => {
    if (
      fieldPath?.length &&
      (prevFieldPath !== fieldPath || prevElementId !== elementId)
    ) {
      viewFields &&
        changeConfigValue(
          "fields",
          viewFields.filter(
            (field) =>
              field.name !== identifierFieldName &&
              field.name !== referencingFieldName,
          ),
        );
    }
  }, [fieldPath, elementId]);

  return (
    <>
      <FormInputDataSourceEditorComponent
        language={language}
        showMultiReferenceFields
        helperText={{
          fieldPath: fieldPathHelperText,
        }}
      />
      <TranslationComponent
        language={lang}
        i18n={i18n as Translation<"label">}
        changeTranslation={changeTranslation}
        handleChangeLanguage={setLang}
      />
      <FieldsComponent
        language={language}
        fields={fields ?? []}
        viewFields={viewFields?.filter(
          (field) => field?.name !== referencingFieldName,
        )}
        changeConfigValue={changeConfigValue}
      />
      <FormInputConfigEditorComponent />
    </>
  );
});
