import { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { isCustomExpression } from "core";
import { getParentElement, useElementEditorContext } from "core/editor";
import { selectors as editorSelectors } from "core/editor/reduxModule";
import { usePage } from "utils/hooks";

import { UntransformedTextFieldConfig } from "../types";

import { FieldAlignment, FieldColors, Styles, Text } from "./components";

export const TextFieldEditor = memo(() => {
  const {
    elementModel: { config, id },
    changeConfigValue,
  } = useElementEditorContext<UntransformedTextFieldConfig>();
  const {
    background,
    rounded,
    fitContent,
    verticalAlignment,
    horizontalAlignment,
    size,
    variant,
    fontVariant,
    wrap,
  } = config;

  const wrappedChangeValue = useCallback(
    (type: keyof UntransformedTextFieldConfig, newValue: any) =>
      changeConfigValue(type, newValue),
    [changeConfigValue],
  );

  const handleExpressionValueChange = useCallback(
    (type: keyof UntransformedTextFieldConfig) =>
      (newValue: string | number | boolean) => {
        /*
         * background color (that is a border color if variant === "outlined")
         * will be set to `null` if color === "transparent"
         */
        wrappedChangeValue(
          type,
          !isCustomExpression(String(newValue)) &&
            String(newValue) === "transparent"
            ? null
            : newValue,
        );

        /*
         * If next background value is `null` ("transparent")
         * vertical and horizontal alignment should be reset
         * since when `default_text_field` has background it's a block component
         * otherwise it's an inline component,
         * the same with `fitContent` and `size` props
         */
        if (newValue === "transparent") {
          variant === "outlined" && wrappedChangeValue("variant", undefined);
          verticalAlignment &&
            verticalAlignment !== "start" &&
            wrappedChangeValue("verticalAlignment", undefined);
          horizontalAlignment &&
            horizontalAlignment !== "start" &&
            wrappedChangeValue("horizontalAlignment", undefined);
          fitContent && wrappedChangeValue("fitContent", undefined);
          size && size === "small" && wrappedChangeValue("size", undefined);
        }

        /*
         * If switching background (border) color and the `variant` is "outlined"
         * and font and background colors aren't expressions, set `color` the same as
         * background (border) color, to display component like a `<Chip />`
         */
        if (
          type === "background" &&
          variant === "outlined" &&
          !isCustomExpression(String(newValue)) &&
          !isCustomExpression(config?.color) &&
          String(newValue) !== "transparent"
        ) {
          wrappedChangeValue("color", newValue);
        }
      },
    [
      config,
      fitContent,
      horizontalAlignment,
      size,
      variant,
      verticalAlignment,
      wrappedChangeValue,
    ],
  );

  const onStyleChange = useCallback(
    (type: keyof UntransformedTextFieldConfig) =>
      (_: any, nextValue: boolean | string) => {
        nextValue !== null && wrappedChangeValue(type, nextValue);

        /*
         * Since prop `size` can be applied to the component only
         * if `fitContent === true`, so size should be reset
         * when `nextValue` (that is `fitContent`)  is `false`
         */
        if (type === "fitContent" && !nextValue && size === "small") {
          wrappedChangeValue("size", undefined);
        }

        /*
         * Variant switching likewise a background color changing.
         * When `variant` changes to "outlined" set color
         * the same as background(border) color
         * or set to default (`undefined`) if `variant === "filled"`
         */
        if (
          type === "variant" &&
          !isCustomExpression(config?.color) &&
          !isCustomExpression(config?.background)
        ) {
          wrappedChangeValue(
            "color",
            nextValue === "outlined" ? background : undefined,
          );
        }
      },
    [background, config, size, wrappedChangeValue],
  );

  const page = usePage();
  const updatedElements = useSelector(editorSelectors.updatedElements);

  const isTableColumn = useMemo(
    () =>
      getParentElement(page!.element, updatedElements, id)?.type.name ===
      "default_table",
    [id, page, updatedElements],
  );

  return (
    <>
      <Text id={id} config={config} onChange={handleExpressionValueChange} />
      <FieldColors config={config} onChange={handleExpressionValueChange} />
      {!(isTableColumn && !background) && (
        <FieldAlignment
          verticalAlignment={verticalAlignment ?? "start"}
          horizontalAlignment={horizontalAlignment ?? "start"}
          onChange={onStyleChange}
        />
      )}
      <Styles
        fontVariant={fontVariant ?? "body1"}
        rounded={rounded ?? false}
        fitContent={fitContent ?? false}
        onChange={onStyleChange}
        size={size}
        variant={variant}
        backgroundExists={Boolean(background)}
        wrap={wrap}
      />
    </>
  );
});
