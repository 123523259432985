import { memo } from "react";
import {
  Section,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import ActionConfigEditor from "elementTypes/common/ActionConfig";
import {
  ActionConfigType,
  ActionConfigsType,
} from "elementTypes/common/ActionConfig/types";
import { Spacer } from "elementTypes/common/Spacer";

import { FormConfig, FormTypes, UntransformedFormConfig } from "../../types";

import { DisableSpacing } from "./DisableSpacing";
import { HideBackButton } from "./HideBackButton";

export const Config = memo<{
  type: FormConfig["type"];
}>(({ type }) => {
  const { configTitle, stylesTitle } = useEditorTranslation();
  const {
    elementModel: {
      config,
      config: { onSuccess },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedFormConfig>();

  const handleOnSuccessChange = (newValue: ActionConfigType[]) =>
    changeConfigValue("onSuccess", newValue);

  return (
    <>
      <Section title={configTitle} wrapped={true}>
        {type !== FormTypes.detail && <HideBackButton />}
        <Spacer vertical={1} />
        <ActionConfigEditor
          actionConfigs={onSuccess as ActionConfigsType}
          onActionConfigsChange={handleOnSuccessChange}
          elementConfig={config}
        />
      </Section>
      <Section title={stylesTitle} wrapped={true}>
        <DisableSpacing />
      </Section>
    </>
  );
});
