import { createUseTranslation } from "core/session/translation";

const CQTranslations = {
  en: {
    queryTitleLabel: "Title",
    queryNameLabel: "Name",
    queryIdentifyingLabel: "Identifier Column",
    queryEditorInfo: "Start crafting your SQL code here!",
    queryEditorErrorTitle: "Error details",
    queryEditorViewExists: "Query {{viewName}} already exist",
    queryEditorLoading: "Validating",
    createButton: "Create",
    dataGridContent: "No results were retrieved",
    queryEditorErrorDetails: "Details",
    queryEditorLine: "Line",
    queryEditorLineErrorPrefix: "Error",
    queryEditorLineDetailsPrefix: "Details",
    queryEditorRun: "Run",
    permissionTableTitle: "Permission Table",
    updateButton: "Update",
    queryEditorPreviewTitle: "Data preview",
    noQueryGroupSelected: "No query group selected",
    queryEditorWarningTitle: "Custom views should be edited with caution!",
    queryEditorWarningMessage:
      "**When modifying a custom view:**\n - You can add more columns to the view.\n - Deleting or changing existing columns is not recommended, as it may break dependent queries.\n - Consider creating a new query if you need to make significant changes.",
  },
  es: {
    queryEditorInfo: "Escriba la consulta SQL",
    queryEditorErrorTitle: "Detalle de errores",
    queryEditorViewExists: "La consulta {{viewName}} ya existe",
    queryEditorLoading: "Validando",
    queryEditorErrorDetails: "Detalles",
    queryEditorLine: "Linea",
    queryEditorLineErrorPrefix: "Error",
    queryEditorLineDetailsPrefix: "Detales",
    queryEditorRun: "Ejecutar",
    queryEditorPreviewTitle: "Vista previa",
  },
};

export const useCustomQueryTranslation = createUseTranslation(CQTranslations);
