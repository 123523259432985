import { memo } from "react";
import { Box, Card, CardContent, Divider } from "@mui/material";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import { AppBar } from "layouts/common/AppBar";
import { LoadingComponent } from "layouts/common/Loading";

import { VertikalLogo } from "layouts/common/Logo/VerticalLogo";
import verticalLogoPath from "../common/verticalLogo.svg";

import LoginForm from "./LoginForm";
import { selectors as loginSelectors } from "./reduxModule";
import useStyles from "./styles";

export const LoginPage = memo(() => {
  const config = useSelector(loginSelectors.config);
  const loading = useSelector(loginSelectors.loading);

  const loggingIn = useSelector(sessionSelectors.loggingIn);

  const dispatch = useDispatch();

  const {
    classes: { root, logo },
  } = useStyles();

  const handleSubmit = (values: any) => dispatch(sessionActions.login(values));

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <AppBar
        label=""
        menuWidth=""
        handleDrawerChange={() => null}
        open={false}
        toolbarVariant="dense"
        headerButtonsColor={"inherit"}
        hidden
      />
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className={root}>
          <Card variant="outlined">
            <Box
              py={3}
              display="flex"
              alignContent="center"
              justifyContent="center"
            >
              {config?.logoPath ? (
                <img
                  alt="logo"
                  src={config?.logoPath ?? verticalLogoPath}
                  className={logo}
                />
              ) : (
                <Box className={logo} width={"100%"}>
                  <VertikalLogo />
                </Box>
              )}
            </Box>
            <Divider />
            <CardContent>
              <LoginForm
                onSubmit={handleSubmit}
                loggingIn={loggingIn}
                identifierTitle={config?.identifierInputTitle}
              />
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
});
