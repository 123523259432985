import { ReactNode, memo, useMemo } from "react";
import { Box, Theme, Typography, useTheme } from "@mui/material";

import { IElementComponentProps } from "core";
import { NON_BREAKING_SPACE } from "../common/utils";
import { ColorField } from "./types";

interface IProps extends IElementComponentProps<{}, ColorField> {
  value: string | null;
  width?: number;
  height?: number;
  showLabel?: boolean;
}

const DefaultColorField = memo<IProps>(
  ({ value, width, height, showLabel }) => {
    const theme: Theme = useTheme();
    let colorComponent: ReactNode | null = null;
    const label = (
      <Typography
        variant="caption"
        style={{
          overflowX: "hidden",
          color: theme.palette.getContrastText(value ?? "#fff"),
        }}
      >
        {value}
      </Typography>
    );

    const shouldHideLabel = useMemo(
      () => (width && width >= 70) || !showLabel,
      [width, showLabel],
    );

    if (value) {
      if (CSS.supports("color", value)) {
        colorComponent = (
          <Box
            display="flex"
            bgcolor={value ?? "#fff"}
            width={width ? `${width}%` : "30px"}
            height={height ? `${height}%` : "30px"}
            border="1px solid"
            borderColor="divider"
            borderRadius="3px"
            alignItems="center"
            justifyContent="center"
          >
            {showLabel && width && width >= 70 ? label : null}
          </Box>
        );
      }

      return (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          {...(!shouldHideLabel && {
            gridGap: 8,
            pr: 0.5,
          })}
          width="100%"
          height="100%"
        >
          {colorComponent}
          {!shouldHideLabel && label}
        </Box>
      );
    }

    return <span>{NON_BREAKING_SPACE}</span>;
  },
);

export default DefaultColorField;
