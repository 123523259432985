import * as t from "io-ts";
import { selectors } from "core/editor/reduxModule";
import { Type, types } from "core/runtime-typing";
import { buildNullableObjectViewType } from "core/runtime-typing/utils";
import {
  IElement,
  SelectorTypes,
  TypeFactory,
  UntransformedConfig,
  arrayChild,
  customExpression,
} from "core/types";
import { IAsyncActionState } from "core/utils/types";
import { ActionConfigType } from "elementTypes/common/ActionConfig/types";

export const FormMultiReferenceConfig = t.type({
  viewName: t.string,
  identifierFieldName: t.string,
  referencingFieldName: t.string,
});

export type FormMultiReferenceConfig = t.TypeOf<
  typeof FormMultiReferenceConfig
>;

export enum FormTypes {
  create = "create",
  edit = "edit",
  detail = "detail",
}

export const FormConfig = t.intersection([
  t.type({
    dataSource: t.intersection([
      t.type({
        viewName: t.string,
      }),
      t.partial({
        identifierName: t.string,
        identifierValue: customExpression(t.union([t.number, t.string])),
        multiReference: t.record(t.string, FormMultiReferenceConfig),
        stateFieldName: t.string,
      }),
    ]),
    type: t.union([
      t.literal(FormTypes.edit),
      t.literal(FormTypes.create),
      t.literal(FormTypes.detail),
    ]),
  }),
  t.partial({
    jsonSchema: t.object,
    validation: customExpression(t.union([t.boolean, t.string])),
    defaultData: t.record(
      t.string,
      t.union([customExpression(t.unknown), t.undefined]),
    ),
    hideBackButton: t.boolean,
    disableSpacing: t.boolean,
    onSuccess: t.array(ActionConfigType),
  }),
]);

export const FormChildren = t.type({
  content: arrayChild("*", {
    positioned: true,
  }),
});

export type FormChildrenType = t.TypeOf<typeof FormChildren>;

const dataSelector: TypeFactory<FormConfig> = ({ config, state }) => {
  let type: Type;
  const viewName = config.dataSource?.viewName;
  const multiReference = config.dataSource?.multiReference;

  const viewList = selectors.viewList(state);
  if (!viewList) {
    // view list still loading, do not show any typing errors
    type = types.any();
  } else if (!viewName) {
    type = types.optional(types.null(), "no source view set");
  } else {
    const view = viewList.find((v) => v.name === viewName);
    if (!view) {
      throw new Error(`Invalid view ${viewName}`);
    }
    const referenceTypes: Record<PropertyKey, Type> = {};
    if (multiReference) {
      for (const referenceField in multiReference) {
        const referenceViewName = multiReference[referenceField].viewName;
        const referenceView = viewList.find(
          (v) => v.name === referenceViewName,
        );
        if (!referenceView) {
          throw new Error(`Invalid view ${referenceViewName}`);
        }
        referenceTypes[referenceField] = types.array(
          buildNullableObjectViewType(referenceView, true),
        );
      }
    }
    type = buildNullableObjectViewType(view, true, undefined, referenceTypes);
    type = types.nullable(type);
  }
  return type;
};

export const formSelectors: SelectorTypes<FormConfig> = {
  loadState: IAsyncActionState,
  saveState: IAsyncActionState,
  data: dataSelector,
  errors: types.anyRecord(),
  originalData: dataSelector,
  touched: types.record(
    types.union([types.string(), types.number()]),
    types.boolean(),
  ),
  hasChanges: types.optional(types.boolean()),
  isValid: types.optional(types.boolean()),
  identifier: types.optional(types.union([types.string(), types.number()])),
};

export type FormConfig = t.TypeOf<typeof FormConfig>;

export type UntransformedFormConfig = UntransformedConfig<FormConfig>;

export type FormChildren = t.TypeOf<typeof FormChildren>;

export type Form = IElement<FormConfig, FormChildren>;
