import { ChangeEvent, memo, useCallback } from "react";
import TextField from "@mui/material/TextField";
import { RefCallBack } from "react-hook-form";

import { MarkdownInput } from "elementTypes/common/MarkdownInput";
import { DEFAULT_LANGUAGE_CODE, Language, Translation } from "../../../types";
import { useLanguageSectionContext } from "../LanguageSection/LanguageSectionContext";

import { useLabelEditorTranslation } from "./translation";

type TranslationEditorProps = {
  translationKey: string;
  i18n: Translation<TranslationEditorProps["translationKey"]>;
  changeTranslation: (value: Translation<string>) => void;
  language?: Language;
  multiline?: boolean;
  label?: string;
  inputRef?: RefCallBack;
  markdown?: boolean;
  error?: boolean;
  autoFocus?: boolean;
};

// TODO use LanguageSection as wrapper wherever this is used
export const TranslationEditor = memo<TranslationEditorProps>(
  ({
    i18n,
    translationKey,
    language,
    label,
    multiline,
    markdown,
    error,
    autoFocus,
    changeTranslation,
    inputRef,
  }) => {
    const { label: defaultLabel } = useLabelEditorTranslation();
    const { lang: defaultLanguage } = useLanguageSectionContext();
    const lang = language ?? defaultLanguage;

    const value = i18n?.[lang.code]?.[translationKey] ?? "";

    const { code } = lang;

    const handleChange = useCallback(
      (newValue: string) =>
        changeTranslation({
          ...i18n,
          ...(!i18n?.[DEFAULT_LANGUAGE_CODE]?.[translationKey]?.trim()
            .length && {
            [DEFAULT_LANGUAGE_CODE]: {
              ...i18n[DEFAULT_LANGUAGE_CODE],
              [translationKey]: newValue,
            },
          }),
          [code]: {
            ...i18n[code],
            [translationKey]: newValue,
          },
        }),
      [translationKey, code, i18n, changeTranslation],
    );

    const handleInputChange = useCallback(
      ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) =>
        handleChange(newValue),
      [handleChange],
    );

    const handleMDChange = (nextMDValue: string | null) =>
      handleChange(nextMDValue ?? "");

    return markdown ? (
      <MarkdownInput
        {...{
          value,
          inputRef,
          label: label ?? defaultLabel,
          onChange: handleMDChange,
          disablePreview: false,
          error,
          autoFocus,
        }}
      />
    ) : (
      <TextField
        value={value}
        name={translationKey}
        label={label ?? defaultLabel}
        fullWidth={true}
        size="small"
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
        multiline={multiline}
        ref={inputRef}
        error={error}
        autoFocus={autoFocus}
      />
    );
  },
);
