import { Theme } from "@mui/material";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  dialogRoot: {
    padding: theme.spacing(2),
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0, 0, 0, 2),
  },

  cypexInfo: {
    display: "grid",
    gridTemplateColumns: "minmax(10ch, max-content) auto",
    gridAutoRows: theme.spacing(6),
    margin: theme.spacing(1),
    gridColumnGap: theme.spacing(2),
    alignItems: "baseline",
  },
}));

export default useStyles;
