import { ReactNode, memo } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import {
  ControllerProps,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  FormProvider,
  UseFormProps,
  useForm,
} from "react-hook-form";

export type FormOptions = UseFormProps<FieldValues>;

export type InputType = Parameters<ControllerProps["render"]>[0];

export type RenderProps<
  Values extends FieldValues = {},
  T extends FieldPath<Values> = FieldPath<Values>,
> = {
  field: ControllerRenderProps<Values, T>;
} & Pick<InputType, "fieldState" | "formState">;

type FormProps = {
  children: ReactNode | ReactNode[];
  onSubmit: (data: Record<string, any>) => void;
  defaultValues?: FieldValues;
  formOptions?: FormOptions;
  className?: string;
  boxProps?: BoxProps;
};

export const HookForm = memo<FormProps>(
  ({ defaultValues, children, className, formOptions, boxProps, onSubmit }) => {
    const methods = useForm({ defaultValues, ...formOptions });

    return (
      <FormProvider {...methods}>
        <Box
          {...boxProps}
          className={className}
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {children}
        </Box>
      </FormProvider>
    );
  },
);
