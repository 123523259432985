import { memo } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { Element } from "core";
import { LoadingComponent } from "layouts/common/Loading";

import { FormActions } from "./components";
import { Props } from "./container";
import useStyles from "./styles";
import { FormTypes } from "./types";

const DefaultForm = memo<Props>(
  ({
    element: {
      children: { content },
      config: { type, disableSpacing, hideBackButton },
    },
    loadState,
    saveState,
    data,
    isValid,
    hasChanges,
    save: handleSave,
    reset: handleReset,
    setStateFieldValue,
    goBack,
    allowedStateChanges,
    stateFieldValue,
  }) => {
    const { classes } = useStyles({ disableSpacing });
    const loading =
      loadState.inProgress || saveState.inProgress || data === null;

    const elements = content.elements.map((el) => (
      <Element key={el.id} element={el} />
    ));

    return (
      <Paper className={classes.root} variant={"outlined"}>
        {!loadState.inProgress ? (
          <Box flexGrow={1} p={1} sx={{ overflowY: "auto" }}>
            {elements}
          </Box>
        ) : (
          <LoadingComponent />
        )}

        {type !== FormTypes.detail && (
          <>
            <Divider className={classes.divider} />
            <FormActions
              isValid={isValid}
              hasChanges={hasChanges}
              handleSave={handleSave}
              handleReset={handleReset}
              onStateChange={setStateFieldValue}
              goBack={goBack}
              loading={loading}
              type={type}
              allowedStateChanges={allowedStateChanges}
              stateFieldValue={stateFieldValue}
              hideBackButton={hideBackButton}
              errors={{
                load: loadState.error,
                save: saveState.error,
              }}
            />
          </>
        )}
      </Paper>
    );
  },
);

DefaultForm.displayName = "DefaultForm";

export default DefaultForm;
