import { Type } from "./Type";
import { TypeError } from "./TypeError";
import { IAutocompleteKey } from "./types";

export class InterfaceType extends Type {
  // TODO: display with all fields
  public name = "object";

  constructor(
    public readonly fields: Record<PropertyKey, Type>,
    description?: string,
  ) {
    super(description);
  }

  public validate(value: any): true | TypeError {
    if (typeof value !== "object" || value === null || value === undefined) {
      return new TypeError(value, this, "TODO: detailed error message");
    }
    let result: true | TypeError;
    for (const key of Object.keys(this.fields)) {
      const v = value[key];
      result = this.fields[key].validate(v);
      if (result !== true) {
        return new TypeError(
          value,
          this,
          `Invalid value "${v}" for field "${key}": ${result.message}`,
        );
      }
    }
    return true;
  }

  public getAutocompleteRecord(): Record<string, IAutocompleteKey> {
    return Object.keys(this.fields).reduce(
      (acc, k) => ({ ...acc, [k]: { type: this.fields[k], isBuiltin: false } }),
      {},
    );
  }
}
