import { IDefaultElement } from "core";

import { CodeInputConfig } from "../types";

export const defaultElement: IDefaultElement<CodeInputConfig> = {
  config: {
    language: "sql",
  },
  i18n: {
    en: {
      label: "",
    },
  },
};
