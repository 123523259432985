import { memo } from "react";
import { Box, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useForm } from "react-hook-form";
import BackButton from "elementTypes/common/BackButton";
import Button from "elementTypes/common/Button";
import { useSaveFile } from "queries/admin/fileData";
import { getApiError } from "queries/utils";
import { fileTypeGroupName } from "services/api/constants";
import { RoutePaths } from "staticPages/routes";
import { useRoute } from "utils/hooks";
import { useSnackbar } from "utils/hooks/useSnackbar";

import { UploadForm } from "../../components/UploadForm.tsx";
import useStyles from "../../styles.ts";
import { useCommonStaticPagesTranslation } from "../../translation";

import { FileForm } from "./types";

export const CreateFilePage = memo(() => {
  const {
    classes: { root, dividerFooter },
  } = useStyles();
  const {
    formState: { isSubmitting },
    control,
    watch,
    handleSubmit,
    setValue,
  } = useForm<FileForm>();

  const translation = useCommonStaticPagesTranslation();
  const route = useRoute();

  const showSnackbar = useSnackbar();

  const uploadFile = useSaveFile({
    onSuccess: () => {
      showSnackbar(translation.saveSuccessMessage, "success");
      route("push", RoutePaths.Files);
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(translation.saveErrorMessage + msg, "error");
    },
  });

  const submit = (data: FileForm) => uploadFile.mutate(data);

  const typeGroupName: keyof typeof fileTypeGroupName =
    (watch("typeGroupName") as keyof typeof fileTypeGroupName) ?? "other";

  const acceptedFiles =
    typeGroupName === "other"
      ? undefined
      : typeGroupName === "document"
        ? fileTypeGroupName[typeGroupName].reduce(
            (res, type) => ({ ...res, [type]: [] }),
            {},
          )
        : { [fileTypeGroupName[typeGroupName]]: [] };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box pb={1} display="flex" alignItems="center" gap={1}>
        <BackButton isIcon href={RoutePaths.Files} />
        <Typography variant="h5">{translation.uploadFilesTitle}</Typography>
      </Box>
      <Paper variant="outlined" className={root}>
        <Grid container spacing={1}>
          <UploadForm
            {...{
              control,
              setValue,
              acceptedFiles,
            }}
          />
          <Box width={"100%"} pt={1}>
            <Divider className={dividerFooter} />
          </Box>
          <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
            <Button
              color="primary"
              iconRight="forward"
              type="submit"
              label={translation.uploadLabel}
              disabled={isSubmitting || uploadFile.isLoading}
              processing={uploadFile.isLoading}
            />
          </Box>
        </Grid>
      </Paper>
    </form>
  );
});
