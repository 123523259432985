import { handleActions } from "core/utils/redux";

import { FilterGroupCombinator } from "../toolsPanel";

import {
  ActionTypes,
  IFetchParams,
  ISelectedRow,
  IState,
  Types,
} from "./types";

const INITIAL_PARAMS: IFetchParams = {
  offset: 0,
  limit: null,
  order: null,
  filter: {
    combinator: FilterGroupCombinator.AND,
    filters: [],
  },
};

export const emptySelectedRow: ISelectedRow = {
  row: null,
  identifier: null,
};

const INITIAL_STATE: IState = {
  loading: false,
  data: null,
  references: null,
  error: null,
  params: INITIAL_PARAMS,
  nextFilter: INITIAL_PARAMS.filter!,
  loadingParams: null,
  nextPageAvailable: false,
  searchInputValue: "",
  metadata: {
    canUpdate: false,
    canDelete: false,
    rows: {},
  },
  selected: emptySelectedRow,
};

/**
 * TODO:
 * Handle row update error case, and row updating state.
 */

export function buildReducer(types: Types) {
  return handleActions<IState, ActionTypes>(INITIAL_STATE, {
    [types.LOAD]: (state, action: ActionTypes["load"]) => ({
      ...state,
      loading: true,
      loadingParams: action.payload.params,
      error: null,
    }),
    [types.LOAD_SUCCESS]: (state, action: ActionTypes["loadSuccess"]) => ({
      ...state,
      loading: false,
      loadingParams: null,
      data: action.payload.data,
      params: action.payload.params,
      nextFilter: action.payload.params.filter!,
      nextPageAvailable: action.payload.nextPageAvailable,
      metadata: action.payload.metadata,
      references: action.payload.references,
    }),
    [types.LOAD_ERROR]: (state, action: ActionTypes["loadError"]) => ({
      ...state,
      params: action.payload.params,
      nextFilter: action.payload.params.filter!,
      loading: false,
      loadingParams: null,
      error: action.payload.error,
    }),
    [types.SEARCH_VALUE_CHANGE]: (
      state,
      action: ActionTypes["changeSearchValue"],
    ) => ({
      ...state,
      searchInputValue: action.payload.searchInputValue || "",
    }),
    [types.SELECT_ROW]: (state, action: ActionTypes["selectRow"]) => ({
      ...state,
      selected: {
        ...action.payload,
      },
    }),
  });
}
