import { IRawElementType } from "core";

import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import {
  StateChangeDropDownConfig,
  stateChangeDropDownSelectors,
} from "./types";
// import {
// StateChangeDropdownEditor,
// defaultElement,
// editorMetadata,
// } from "./editor";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_state_change_dropdown",
  component: Component,
  editable: false,
  configType: StateChangeDropDownConfig,
  selectorTypes: stateChangeDropDownSelectors,
  // editorComponent: StateChangeDropdownEditor,
  // defaultElement,
  // editorMetadata,
};

export default elementType;
