import { useCallback } from "react";
import { Box, Checkbox } from "@mui/material";
import { GridCellParams } from "@mui/x-data-grid";
import Fuse from "fuse.js";
import { whereEq } from "ramda";
import { DEFAULT_LANGUAGE_CODE, GeneralType, Translation } from "core";
import { ArrayField } from "elementTypes/common/ArrayField";
import { JsonView } from "elementTypes/common/JsonView";
import { Table } from "staticPages/admin/pages/modelBuilder/erd/types";
import { QueryObject, TQueryGroupUtils, UIQueryGroups } from "./types";

export const fuseFn = <T,>(
  searchArray: ReadonlyArray<T>,
  keys: Array<{ name: string; weight: number }>,
) =>
  new Fuse(searchArray, {
    keys,
    includeScore: true,
    useExtendedSearch: true,
  });

type TranslationKey = "label" | "title";

export const useQueryGroupUtils = () => {
  const getAllTranslations = <T extends TranslationKey>(
    i18n: Translation<T>,
    key: T,
  ) =>
    Object.values(i18n).reduce(
      (res, v) => (v?.[key] ? ([...res, v[key]] as string[]) : res),
      [] as string[],
    );

  const getQueryGroupName = useCallback(
    (props: TQueryGroupUtils["GetQueryGroupName"]) => {
      // returns name of query group or empty groups or no group
      const { type, filter } = props;

      if (filter) {
        return null;
      }

      if (type === "query") {
        const { queryGroups, query } = props;

        const emptyQueryGroups = queryGroups.filter(
          ({ queries }) => !queries.length,
        );

        const queryGroup = queryGroups.find(({ queries }) =>
          queries.includes(Number(query.id)),
        );

        if (queryGroup) {
          const allTranslations = getAllTranslations(queryGroup.i18n, "label");

          return allTranslations.length
            ? allTranslations
            : emptyQueryGroups
              ? emptyQueryGroups
                  .map(({ i18n }) => getAllTranslations(i18n, "label"))
                  .flat()
              : null;
        }
      } else {
        const {
          uiQueryGroups: { emptyGroups },
          groupName,
        } = props;

        if (!groupName) {
          return null;
        }

        if (groupName === "emptyGroups") {
          return emptyGroups
            ? emptyGroups
                .map(({ i18n }) => getAllTranslations(i18n, "label"))
                .flat()
            : null;
        }

        if (groupName !== "noGroup") {
          return [groupName];
        }
      }

      return null;
    },
    [],
  );

  const searchQueryGroups = useCallback(
    ({
      queryGroupData,
      queryType,
      searchValue,
    }: TQueryGroupUtils["SearchQueryGroups"]) =>
      Object.keys(queryGroupData).reduce((acc, curr) => {
        const queryGroup = queryGroupData[curr];
        const searchData = Object.entries(queryGroup).map(([key, query]) => {
          const { i18n } = query;
          return {
            ...query,
            key,
            allLang: {
              title: getAllTranslations(i18n, "title"),
              queryGroupName: getQueryGroupName({
                type: "queryGroup",
                filter: queryType,
                uiQueryGroups: queryGroupData,
                groupName: curr,
              }),
            },
          };
        });

        const filteredQueryGroup = fuseFn(searchData, [
          { name: "allLang.title", weight: 1 },
          { name: "allLang.queryGroupName", weight: 2 },
        ])
          .search(`'${searchValue}`)
          .map((queryObj) => ({
            ...queryObj.item,
          })) as QueryObject[];

        return {
          ...acc,
          ...(!!filteredQueryGroup.length && { [curr]: filteredQueryGroup }),
        };
      }, {} as UIQueryGroups),
    [getQueryGroupName],
  );

  const searchQueries = useCallback(
    ({
      queriesByType,
      queryGroups,
      queryType,
      searchValue,
    }: TQueryGroupUtils["SearchQueries"]) => {
      const searchData = Object.entries(queriesByType).map(([key, query]) => {
        const { i18n } = query;
        return {
          ...query,
          key,
          allLang: {
            title: getAllTranslations(i18n, "title"),
            queryGroupName: getQueryGroupName({
              type: "query",
              query,
              queryGroups,
              filter: queryType,
            }),
          },
        };
      });

      return fuseFn(searchData, [
        { name: `allLang.title`, weight: 1 },
        { name: `allLang.queryGroupName`, weight: 2 },
      ])
        .search(`'${searchValue}`)
        .map((queryObj) => queryObj.item) as QueryObject[];
    },
    [getQueryGroupName],
  );

  return {
    searchQueries,
    searchQueryGroups,
  };
};

const transformTablesObjectToArray = (
  tables: Record<string, Record<string, Table>>,
  predicate?: Record<string, unknown>,
) => {
  let arr: Table[] = [];
  for (const sch of Object.values(tables ?? {})) {
    for (const tab of Object.values(sch ?? {})) {
      if (
        (tab && !predicate) ||
        (tab && predicate && whereEq(predicate)(tab))
      ) {
        arr = [...arr, tab];
      }
    }
  }
  return arr;
};

const transformFuseArrayToObject = (
  res: Record<string, Record<string, Table>>,
  tableObj: Fuse.FuseResult<Table>,
) => {
  return tableObj?.item
    ? {
        ...res,
        [tableObj.item.schema]: {
          ...(res?.[tableObj.item.schema] ?? {}),
          [tableObj.item?.table]: tableObj.item,
        },
      }
    : res;
};

const transformTableArrayToObject = (arr: Table[]) =>
  arr.reduce(
    (res: Record<string, Record<string, Table>>, tableObj: Table) => {
      return tableObj
        ? {
            ...res,
            [tableObj.schema]: {
              ...(res?.[tableObj.schema] ?? {}),
              [tableObj?.table]: tableObj,
            },
          }
        : res;
    },
    {} as Record<string, Record<string, Table>>,
  );

export const getFilteredTables = (
  tables: Record<string, Record<string, Table>>,
  searchValue?: string,
  predicate?: Record<string, unknown>,
) => {
  const arr: Table[] = transformTablesObjectToArray(tables, predicate);

  return searchValue?.trim()
    ? fuseFn(arr, [
        { name: "schema", weight: 1 },
        { name: "table", weight: 2 },
      ])
        .search(`'${searchValue ?? ""}`)
        .reduce(transformFuseArrayToObject, {})
    : predicate
      ? transformTableArrayToObject(arr)
      : tables;
};

export const jsonCell = (params: GridCellParams) => (
  <JsonView
    value={params.value as Record<string, unknown> | null}
    popup={true}
    embedded={true}
  />
);

export const textArrayCell = (params: GridCellParams) => (
  <ArrayField
    values={params.value as GeneralType["type"][]}
    variant="outlined"
  />
);

export const boolCell = (params: GridCellParams) => (
  <Box display="flex" justifyContent="center" alignContent="center">
    <Checkbox checked={Boolean(params.value)} disabled />
  </Box>
);

export const renderCellOverride = (generalType: GeneralType) => {
  if (generalType.isArray) {
    return generalType.type === "json" ? jsonCell : textArrayCell;
  } else {
    switch (generalType.type) {
      case "json":
        return jsonCell;
      case "boolean":
        return boolCell;
      default:
        return null;
    }
  }
};

export const buildEmptyI18n = (key: string) => ({
  [DEFAULT_LANGUAGE_CODE]: {
    [key]: "",
  },
});
