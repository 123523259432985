import { memo } from "react";
import { ConnectedReduxModuleProps } from "core";
import { AlertBox } from "elementTypes/common/AlertBox";
import { LoadingComponent } from "layouts/common/Loading";

import {
  EchartsContainerDataset,
  IEchartsContainerMultiProps,
  IOptionEchartProps,
} from "../common/Echarts";
import { useEchartsTranslation } from "../common/Echarts/translation.ts";

import { ReduxModule } from "./reduxModule";
import { BarChart } from "./types";

type Props = ConnectedReduxModuleProps<ReduxModule, BarChart>;

const EchartsBarChart = memo<Props>(
  ({
    element: {
      id,
      config: { dataSource, hideLabel, isStacked, legend, ...rest },
      i18n,
    },
    error,
    data,
    selected,
    selectValue,
  }) => {
    const { title } = i18n;
    const propsEchartsContainer = {
      title,
      type: "bar",
      defaultOptions: {
        xAxis: {
          axisTick: {
            alignWithLabel: true,
          },
        },
        tooltip: {
          axisPointer: {
            type: "shadow",
          },
        },
        series: [
          {
            avoidLabelOverlap: true,
            label: {
              show: !hideLabel,
            },
            ...(isStacked && { stack: "stack1" }),
          },
        ],
      } as IOptionEchartProps,
      ...rest,
    } as IEchartsContainerMultiProps;

    const { errorTitle, configErrorTitle, queryNameError } =
      useEchartsTranslation();

    if (!dataSource.viewName) {
      return <AlertBox title={configErrorTitle} message={queryNameError} />;
    }

    if (error) {
      return <AlertBox title={errorTitle} message={error} />;
    }

    if (!data) {
      return <LoadingComponent />;
    }

    const chartProps = {
      data,
      dataSource,
      legend,
      selected,
      elementId: id,
      selectValue,
      i18n,
    };

    return (
      <EchartsContainerDataset {...propsEchartsContainer} {...chartProps} />
    );
  },
);

export default EchartsBarChart;
