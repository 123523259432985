import { ChangeEvent, ComponentProps, memo, useCallback } from "react";
import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import capitalize from "lodash/capitalize";
import isBoolean from "lodash/isBoolean";
import {
  IPage,
  Translation,
  buildCustomExpressionValue,
  getBooleanExpressionValue,
} from "core";
import {
  ColorSelector,
  IconAutocomplete,
  LanguageSection,
  PageSelector,
  Section,
  TranslationEditor,
} from "core/editor";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import { IconNameType } from "elementTypes/common/MuiIcon";
import {
  Sizes,
  Variants,
} from "elementTypes/default_internal_link_button/editor/consts";
import { useTableEditorTranslation } from "elementTypes/default_table/translation";
import {
  CreateButtonProps,
  UntransformedTableConfig,
} from "elementTypes/default_table/types";
import { useStyles } from "./styles";

type Props = {
  createButton: UntransformedTableConfig["createButton"];
  changeConfigValue: (key: keyof UntransformedTableConfig, value: any) => void;
  changeTranslation: (value: Translation<string>) => void;
  i18n: Translation<string>;
};

type ConfigKeys = keyof CreateButtonProps;

export const CreateButtonEditor = memo<Props>(
  ({ changeConfigValue, changeTranslation, createButton, i18n }) => {
    const translation = useTableEditorTranslation();
    const {
      classes: { titleSection },
    } = useStyles();

    const {
      enabled,
      disabled: customExpressionDisabled,
      icon,
      isIcon = false,
      size,
      variant,
      linkTo,
    } = createButton ?? { linkTo: { pageId: "", params: {} } };

    const handleConfigChange = (key: ConfigKeys) => (value: any) =>
      changeConfigValue("createButton", { ...createButton, [key]: value });

    const handleConfigChangeWithEvent =
      (key: ConfigKeys) => (_e: any, value: any) =>
        handleConfigChange(key)(value);

    const handlePageChange = (newPage: IPage, newParams: any) =>
      handleConfigChange("linkTo")({ pageId: newPage.id, params: newParams });

    const handleToggleMode = (isExpression: boolean) => {
      const nextVal = isExpression
        ? getBooleanExpressionValue(String(enabled))
        : buildCustomExpressionValue(String(enabled));
      handleConfigChange("enabled")(nextVal);
    };

    const sizes = Object.keys(Sizes).map((itemSize) => (
      <FormControlLabel
        key={itemSize}
        control={<Radio color="primary" />}
        label={translation[`${itemSize}Label`]}
        value={itemSize}
      />
    ));

    const variants = Object.keys(Variants).map((itemVariant) => (
      <FormControlLabel
        key={itemVariant}
        control={<Radio color="primary" />}
        label={capitalize(itemVariant)}
        value={itemVariant}
      />
    ));

    const disabledNonExpressionEditor: ComponentProps<
      typeof CustomExpressionEditor
    >["nonExpressionEditor"] = useCallback(
      ({ value, onChange }: NonExpressionEditorProps<boolean>) => {
        const handleChange = (
          _event: ChangeEvent<HTMLInputElement>,
          checked: boolean,
        ) => onChange(checked);

        return (
          <FormControlLabel
            control={
              <Switch
                checked={isBoolean(value) ? value : value === "true"}
                onChange={handleChange}
              />
            }
            label={translation.enabledLabel}
          />
        );
      },
      [translation.enabledLabel],
    );

    const languageSection = (
      <>
        <CustomExpressionEditor
          value={String(enabled)}
          config={createButton ?? {}}
          label={translation.enabledLabel}
          onChange={handleConfigChange("enabled")}
          onToggleMode={handleToggleMode}
          nonExpressionEditor={disabledNonExpressionEditor}
        />
        <LanguageSection showSection={false} wrapped={false}>
          <TranslationEditor
            changeTranslation={changeTranslation}
            i18n={i18n}
            translationKey="createButtonLabel"
          />
        </LanguageSection>
      </>
    );

    const pageConfigSection = (
      <Section
        wrapped={false}
        collapsible={false}
        title={translation.pageSectionLabel}
        classes={{ title: titleSection }}
      >
        <PageSelector
          pageId={linkTo?.pageId}
          config={createButton ?? {}}
          params={linkTo?.params}
          onChange={handlePageChange}
          label={translation.pageSelectorLabel}
        />
      </Section>
    );

    const stylingConfigSection = (
      <Section
        title={translation.stylingSectionLabel}
        wrapped={false}
        collapsible={false}
        classes={{ title: titleSection }}
      >
        <IconAutocomplete
          label={translation.iconLabel}
          value={icon as IconNameType}
          onChange={handleConfigChange("icon")}
        />
        <Box mt={1}>
          <ToggleButtonGroup
            exclusive
            onChange={handleConfigChangeWithEvent("isIcon")}
            value={isIcon}
            fullWidth
          >
            <ToggleButton value={false}>{translation.buttonLabel}</ToggleButton>
            <ToggleButton value={true}>{translation.iconLabel}</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <ColorSelector
          config={createButton ?? {}}
          configKey="color"
          label={translation.colorLabel}
          onChange={handleConfigChange("color")}
        />
        <FormLabel>{translation.sizeInputLabel}</FormLabel>
        <RadioGroup
          row={true}
          value={size}
          onChange={handleConfigChangeWithEvent("size")}
        >
          {sizes}
        </RadioGroup>

        <RadioGroup
          row={true}
          value={variant}
          onChange={handleConfigChangeWithEvent("variant")}
        >
          {variants}
        </RadioGroup>
        <CustomExpressionEditor
          value={customExpressionDisabled ?? buildCustomExpressionValue(false)}
          config={createButton ?? {}}
          label={translation.disabledLabel}
          onChange={handleConfigChange("disabled")}
          disableSwitcher
        />
      </Section>
    );

    return (
      <Section
        title={translation.createLabel}
        wrapped={true}
        defaultOpened={false}
        gap={1}
      >
        {languageSection}
        {pageConfigSection}
        {stylingConfigSection}
      </Section>
    );
  },
);
