import { UseMutationOptions } from "@tanstack/react-query";
import { IElementModel } from "core";

import { generateUIElement as generateUIElementAPI } from "services/api";
import { useAuthenticatedMutation } from "../utils";

export const useGenerateUiElement = (
  options: UseMutationOptions<
    IElementModel,
    unknown,
    { uiName: string; elementName: string; element?: IElementModel }
  >,
) => useAuthenticatedMutation(generateUIElementAPI, options);
