import { memo } from "react";
import InputLabel from "@mui/material/InputLabel";
import { ColorSelect, useElementEditorContext } from "core/editor";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";

import {
  DEFAULT_CONFIG,
  colorItems,
  featureFunctionDefault,
} from "elementTypes/default_geojson_field/constants";
import {
  GeoJSONSelected,
  UntransformedGeoJSONFieldConfig,
  markerBackgroundColorFunctionTyping,
} from "../../../types";
import { useDefaultGeoJSONFieldEditorTranslation } from "../../translation";
import { hasSelection } from "../../utils";

export const MarkerBackground = memo(() => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedGeoJSONFieldConfig>();

  const t = useDefaultGeoJSONFieldEditorTranslation();

  const withSelection = hasSelection(
    config?.selected as unknown as GeoJSONSelected,
  );

  const value =
    config.markerBackgroundColorFunction ??
    DEFAULT_CONFIG.markerBackgroundColorFunction;

  const handleChange = (nextvalue: string) => {
    changeConfigValue("markerBackgroundColorFunction", nextvalue);
  };

  const handleSelectedChange = (nextColor?: string) => {
    changeConfigValue("selected", {
      ...(config.selected ?? {}),
      markerColor: nextColor,
    });
  };

  const nonExpressionEditor = ({
    value: color,
    onChange,
  }: NonExpressionEditorProps) => (
    <>
      <ColorSelect
        colorItems={colorItems}
        defaultValue={DEFAULT_CONFIG.markerBackgroundColorFunction}
        value={color}
        onChange={onChange}
        background
      />
      {withSelection ? (
        <>
          <InputLabel sx={{ py: 1 }}>{t.selectedPropertyLabel}</InputLabel>
          <ColorSelect
            colorItems={colorItems}
            defaultValue={DEFAULT_CONFIG.markerBackgroundColorFunction}
            value={config.selected?.markerColor ?? ""}
            onChange={handleSelectedChange}
            background
          />
        </>
      ) : null}
    </>
  );

  return (
    <CustomExpressionEditor
      value={value}
      label={t.markerBackgroundColorFunctionLabel}
      labelTooltip={t.markerBackgroundColorFunctionDescription}
      config={config}
      onChange={handleChange}
      additionalScope={markerBackgroundColorFunctionTyping}
      nonExpressionEditor={nonExpressionEditor}
      onToggleMode={(isExpression) => {
        if (isExpression) {
          handleChange(DEFAULT_CONFIG.markerBackgroundColorFunction);
        } else {
          handleChange(featureFunctionDefault(value));
          if (config.selected) {
            handleSelectedChange(undefined);
          }
        }
      }}
    />
  );
});
