import * as t from "io-ts";
import { IElement, arrayChild } from "core/types";

export const ContainerConfig = t.type({});

export type ContainerConfig = t.TypeOf<typeof ContainerConfig>;

export const ContainerChildren = t.type({
  content: arrayChild("*", { positioned: true }),
});

export type ContainerChildren = t.TypeOf<typeof ContainerChildren>;

export type Container = IElement<ContainerConfig, ContainerChildren>;
