import { memo } from "react";
import SyncIcon from "@mui/icons-material/Sync";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Controller } from "react-hook-form";

import { getServerError } from "core/utils/api";
import { HookForm } from "elementTypes/common/HookForm";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";
import { useUpdateProfile } from "queries/app/user";
import { useSnackbar } from "utils/hooks";
import BackButton from "../../../elementTypes/common/BackButton";
import { useSettingsTranslation } from "../translation";

export interface IUpdateProfileForm {
  password: string;
  passwordRepeated: string;
}

const UserProfile = memo(() => {
  const showSnackbar = useSnackbar();
  const updateProfile = useUpdateProfile({
    onSuccess: (res) => {
      const msg = `User ${res.email} updated successfully`;
      showSnackbar(msg, "success");
    },
    onError: (error) => {
      showSnackbar(getServerError(error), "error");
    },
  });
  const { changePasswordTitle } = useSettingsTranslation();

  const handleSubmit = (value: Record<string, any>) => {
    const nextData = value as IUpdateProfileForm;
    updateProfile.mutate({ password: nextData.password });
  };

  return (
    <>
      <Box display="flex" alignItems="center" gap={1}>
        <BackButton isIcon />
        <Typography variant="h5">{changePasswordTitle}</Typography>
      </Box>
      <Paper variant="outlined">
        <HookForm
          onSubmit={handleSubmit}
          boxProps={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}
        >
          <Form />
        </HookForm>
      </Paper>
    </>
  );
});

const Form = memo(() => {
  const {
    formState: { isSubmitting, isValid },
    control,
    getValues,
  } = useHookFormContext<IUpdateProfileForm>();

  const { newPasswordLabel, repeatPasswordLabel, repeatPasswordNotEqual } =
    useSettingsTranslation();
  return (
    <>
      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        defaultValue=""
        render={({ field, fieldState: { error } }) => (
          <TextField
            label={newPasswordLabel}
            type="password"
            autoComplete="current-password"
            fullWidth
            {...field}
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />

      <Controller
        name="passwordRepeated"
        control={control}
        rules={{
          required: true,
          validate: (repeatedPassword: string) =>
            getValues().password === repeatedPassword,
        }}
        defaultValue=""
        render={({ field, fieldState: { error } }) => (
          <TextField
            label={repeatPasswordLabel}
            type="password"
            autoComplete="current-password"
            fullWidth
            error={!!error}
            helperText={error && repeatPasswordNotEqual}
            {...field}
          />
        )}
      />

      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button
          color="secondary"
          disabled={isSubmitting || !isValid}
          endIcon={<SyncIcon />}
          type="submit"
        >
          Update
        </Button>
      </Box>
    </>
  );
});

export default UserProfile;
