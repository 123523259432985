import { ChangeEvent, memo } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Section, useElementEditorContext } from "core/editor";

import { useChartEditorTranslation } from "../../translation";
import { PieChartConfig } from "../../types";
import { useStyles } from "../styles";

export const ModeComponent = memo(() => {
  const {
    elementModel: {
      config: { isDonut },
    },
    changeConfigValue,
  } = useElementEditorContext<PieChartConfig>();

  const { modeTitle, donutLabel, pieLabel } = useChartEditorTranslation();

  const toggleMode = (_: ChangeEvent<HTMLInputElement>, value: string) =>
    changeConfigValue("isDonut", value === "donut");

  const {
    classes: { root, labelClass },
  } = useStyles();

  return (
    <Section title={modeTitle} wrapped={true}>
      <RadioGroup
        row={true}
        value={isDonut ? "donut" : "pie"}
        onChange={toggleMode}
        className={root}
      >
        <FormControlLabel
          control={<Radio color="primary" />}
          label={pieLabel}
          value="pie"
          className={labelClass}
        />
        <FormControlLabel
          control={<Radio color="primary" />}
          label={donutLabel}
          value="donut"
          className={labelClass}
        />
      </RadioGroup>
    </Section>
  );
});
