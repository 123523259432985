import { memo } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { actions as routerActions } from "core/router/reduxModule";
import { LANGUAGES } from "core/types/i18n";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import BackButton from "elementTypes/common/BackButton";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";

import { RoutePaths } from "staticPages/routes";
import { useSessionContext } from "../../../../../../core/session";
import { useRoles, useSaveUser } from "../../../../../../queries/admin";
import { getRoleOptions } from "../../../../../../queries/admin/utils";
import { getApiError } from "../../../../../../queries/utils";
import { useSnackbar } from "../../../../../../utils/hooks/useSnackbar";

import useStyles from "./styles";
import { UserForm } from "./types";

export const CreateUsersPage = memo(() => {
  const showSnackbar = useSnackbar();
  const dispatch = useDispatch();
  const {
    classes: { root, footer, dividerFooter },
  } = useStyles();
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
  } = useForm<UserForm>({ mode: "onChange" });

  const { language } = useSessionContext();

  const { data: roleOptions, refetch } = useRoles({
    select: getRoleOptions,
  });

  const saveUser = useSaveUser({
    onSuccess: () => {
      showSnackbar("User created successfully", "success");
      dispatch(routerActions.push(RoutePaths.Users));
    },
    onError: (er) => {
      const apiError = getApiError(er);
      showSnackbar(`User was not created: ${apiError}`, "error");
    },
  });

  const submit = (data: UserForm) => {
    saveUser.mutate(data);
  };

  const handleLoad = () => refetch();

  const startAdornment = (
    <IconButton
      size="small"
      icon="refresh"
      tooltip="Refresh"
      onClick={handleLoad}
    />
  );

  const langs = LANGUAGES.map((lang) => (
    <MenuItem key={lang.code} value={lang.code}>
      {lang.label}
    </MenuItem>
  ));

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box display="flex" alignItems="center" gap={1}>
        <BackButton isIcon href={RoutePaths.Users} />
        <Typography variant="h5">Create User</Typography>
      </Box>
      <Paper variant="outlined" className={root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Email"
                  type="email"
                  fullWidth={true}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
              name="email"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Username"
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
              name="userName"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Password"
                  type="password"
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message}
                  autoComplete="current-password"
                />
              )}
              name="password"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  label="Role"
                  options={roleOptions}
                  startAdornment={startAdornment}
                  innerRef={ref}
                  error={error?.message}
                />
              )}
              name="role"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel shrink={true} id="language">
              Language
            </InputLabel>
            <Controller
              render={({ field }) => (
                <Select fullWidth={true} {...field}>
                  {langs}
                </Select>
              )}
              name="language"
              rules={{ required: true }}
              control={control}
              defaultValue={language.code}
            />
          </Grid>
          <Grid item xs={12} className={footer}>
            <Divider className={dividerFooter} />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button color="primary" label="Cancel" href={RoutePaths.Users} />
              <Button
                color="secondary"
                disabled={isSubmitting || !isValid}
                processing={isSubmitting}
                iconRight="forward"
                type="submit"
                label="Create"
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
});
