import { memo } from "react";
import Divider from "@mui/material/Divider";

import { Section } from "core/editor";
import { Coordinates } from "./components/Coordinates";
import { DataSourceEditor } from "./components/Datasource";
import { LayerUrl } from "./components/LayerUrl";
import { MarkerBackground } from "./components/Marker/Background";
import { MarkerDisplayText } from "./components/Marker/DisplayText";
import { PathOptions } from "./components/Path/Options";
import { SelectedGeoElement } from "./components/Selected";
import { MarkerTooltip } from "./components/Tooltip/Tooltip";
import { useDefaultGeoJSONFieldEditorTranslation } from "./translation";

export const GeoJSONFieldEditor = memo(() => {
  const t = useDefaultGeoJSONFieldEditorTranslation();

  return (
    <>
      <LayerUrl />
      <DataSourceEditor />
      <Section title={t.tooltipFunctionLabel} wrapped={true}>
        <MarkerTooltip />
      </Section>
      <SelectedGeoElement />
      <Section title={t.markerStylesLabel} wrapped={true}>
        <MarkerDisplayText />
        <Divider />
        <MarkerBackground />
      </Section>
      <Section title={t.pathStylesLabel} wrapped={true} gap={1}>
        <PathOptions />
      </Section>
      <Coordinates />
    </>
  );
});
