import { memo, useMemo } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { BackgroundVariant, ReactFlowProvider } from "reactflow";
import "reactflow/dist/style.css";
import { Tip } from "common/elements/Tip.tsx";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { ToggleButton } from "elementTypes/common/ToggleButton";

import { QueryKeys } from "queries/admin";
import { useAdminContext } from "staticPages/admin/context";
import { useDebouncedState, useQueryClient } from "utils/hooks";
import { DatabaseListPanel } from "./components/DatabaseListPanel";
import { ToggleDatabasePanel } from "./components/ToggleDatabasePanel.tsx";
import { ToggleLayoutPanel } from "./components/ToggleLayoutPanel";
import { DatabaseProvider } from "./context/database";
import { DialogType } from "./context/database/consts.ts";
import { useERDContext } from "./context/database/ERDContext.utils.ts";
import { QueryFilterProvider } from "./context/queryFilter";
import { useQueryFilterContext } from "./context/queryFilter/QueryFilterContext.utils.ts";
import { ErdChart } from "./erd";
import {
  modelToReactFlowElements,
  useNotAutogeneratedTables,
} from "./erd/utils";
import { useDatabaseData } from "./hooks/databaseData";
import { useDatabaseTranslation } from "./translation";

export const LayoutOptions = {
  horizontal: "LR",
  vertical: "TB",
} as const;

export type LayoutOptionKey = keyof typeof LayoutOptions;

export type LayoutOption = (typeof LayoutOptions)[keyof typeof LayoutOptions];

export const DatabasePageComponent = memo(() => {
  const translation = useDatabaseTranslation();

  return (
    <>
      <Helmet>
        <title>{translation.databasePageTitle}</title>
      </Helmet>
      <ReactFlowProvider>
        <DatabaseProvider>
          <QueryFilterProvider>
            <Component />
          </QueryFilterProvider>
        </DatabaseProvider>
      </ReactFlowProvider>
    </>
  );
});

const Component = () => {
  const { onModalOpen } = useERDContext();
  const { roleFilter } = useQueryFilterContext();
  const { queryClient, invalidateQueries } = useQueryClient();
  const { model, queries } = useDatabaseData(roleFilter);
  const { tableViewDirection, setTableViewDirection } = useAdminContext();
  const isModelFetching = queryClient.isFetching([QueryKeys.fetchModel]);
  const translation = useDatabaseTranslation();
  const elements = useMemo(
    () => model?.data && modelToReactFlowElements(model?.data),
    [model?.data],
  ) ?? { nodes: [], edges: [] };

  const [cachedDirection, handleCachedDirection] = useDebouncedState(
    tableViewDirection,
    setTableViewDirection,
  );

  const handleLayoutChange = (_event: any, nextValue: LayoutOption) => {
    if (nextValue) {
      handleCachedDirection(nextValue);
    }
  };

  const handleModelRefresh = () =>
    invalidateQueries([
      QueryKeys.fetchModel,
      QueryKeys.fetchQueries,
      QueryKeys.fetchFunctions,
    ]);

  const filteredTables = useNotAutogeneratedTables();

  return (
    <Box
      width="100%"
      minHeight="100%"
      height="100%"
      display="grid"
      gap={1}
      gridTemplateColumns="3fr 1fr"
      gridTemplateRows="min-content 1fr"
      p={0.5}
    >
      <Box gridColumn="1 / span 1" display="grid">
        <Card variant="outlined" sx={{ p: 1 }}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Tip
              title={translation.dbTooltipTitle}
              text={translation.dabTooltipText}
            />
            <Typography variant="h5">
              {translation.databaseOverviewTitle}
            </Typography>
            <Stack
              spacing={1}
              direction="row"
              justifyContent="space-between"
              flex={1}
            >
              <Stack>
                <ToggleLayoutPanel
                  onChange={handleLayoutChange}
                  value={cachedDirection}
                />
              </Stack>
              <Stack spacing={1} direction="row">
                <ToggleButton
                  size="small"
                  value="createDefaultViews"
                  isFetching={Boolean(isModelFetching)}
                  {...(!Object.values(filteredTables ?? {})?.length
                    ? {
                        disabled: true,
                      }
                    : {
                        tooltip: translation.refreshModelTooltip,
                        onChange: onModalOpen(
                          DialogType.generateDefaultQueries,
                        ),
                      })}
                >
                  {translation.generateAllQueriesTitle}
                </ToggleButton>
                <ToggleButton
                  tooltip={translation.refreshModelTooltip}
                  size="small"
                  onChange={handleModelRefresh}
                  value="refresh"
                  isFetching={Boolean(isModelFetching)}
                >
                  <MuiIcon icon="autorenew" fontSize="large" />
                </ToggleButton>
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Box>
      <ToggleDatabasePanel />
      <Box gridColumn="1 / 1">
        <ErdChart
          directionValue={cachedDirection}
          sourceContent={elements}
          bgProps={{
            variant: BackgroundVariant.Lines,
            color: "#f1f1f1",
          }}
        />
      </Box>
      <DatabaseListPanel
        loading={{
          model: model.isInitialLoading,
          queries: queries.isInitialLoading,
        }}
        entities={model.data}
        queries={queries.data}
      />
    </Box>
  );
};
