import { MouseEvent, lazy, useState } from "react";

import Typography from "@mui/material/Typography";
import { CodeBlock } from "elementTypes/common/CodeBlock";
import IconButton, {
  Props as ButtonProps,
} from "elementTypes/common/IconButton";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

type Props = {
  buttonProps: Omit<ButtonProps, "icon">;
};

export const DefaultQueryInfo = ({ buttonProps }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (ev: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(ev.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        icon="help_outline"
        onClick={handleClick}
        tooltip="Default Query Info"
        {...buttonProps}
      />
      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <>
          <Typography>
            In PostgreSQL, a &quot;default view&quot; is a type of database
            object that provides a virtual representation of the data in a
            database table. A view (query) can be thought of as a virtual table
            that is derived from the underlying table, and can be used like any
            other table in a database.
          </Typography>
          <br></br>
          <Typography>
            The default view is named after the table it represents, and
            contains all of the columns of the table. It is used to provide a
            convenient way to access the data in the table without having to
            specify the column names every time.
          </Typography>
          <br></br>
          <Typography>
            For example, if you have a table named &quot;users&quot; with
            columns &quot;id&quot;, &quot;name&quot;, and &quot;email&quot;, a
            default view named &quot;users&quot; will also be created
            automatically. You can query this view just like you would query the
            &quot;users&quot; table, like this:
          </Typography>
          <br></br>
          <CodeBlock value={"SELECT * FROM users;"} language="sql">
            SELECT * FROM users;
          </CodeBlock>
          <br></br>
          <br></br>
          <Typography>
            This query will return all of the data in the &quot;users&quot;
            table, just as if you had written:
          </Typography>
          <br></br>
          <CodeBlock
            value={"SELECT id, name, email FROM users;"}
            language="sql"
          >
            SELECT id, name, email FROM users;
          </CodeBlock>
        </>
      </Popover>
    </>
  );
};
