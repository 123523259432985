import { memo, useCallback } from "react";
import {
  Box,
  FormControlLabel,
  Slider,
  Switch,
  Typography,
} from "@mui/material";
import { Section, useElementEditorContext } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";

import { ColorInput } from "elementTypes/common/ColorInput";

import { handleToggleExpressionMode } from "utils/string";
import { UntransformedColorFieldConfig } from "../types";
import { useColorFieldEditorTranslation } from "./translation";

export const ColorFieldEditor = memo(() => {
  const {
    elementModel: { id, config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedColorFieldConfig>();
  const translation = useColorFieldEditorTranslation();
  const { value, width, height, showLabel } = config;

  const handleValueChange = useCallback(
    (newValue: string | number | boolean) => {
      changeConfigValue("value", String(newValue));
    },
    [changeConfigValue],
  );

  const customExpressionInput = ({
    value: colorValue,
    onChange: onColorChange,
  }: {
    value: string;
    onChange: (value: string) => void;
  }) => {
    const onCustomChange = (nextVal: string | null) => {
      onColorChange(nextVal ?? "");
    };

    return (
      <ColorInput
        label={colorValue}
        value={colorValue}
        changeValue={onCustomChange}
        placement="top"
      />
    );
  };

  const handleToggleMode = (isExpression: boolean) =>
    handleToggleExpressionMode(isExpression, value, handleValueChange);

  const formatSliderValue = (valueToFormat: number) => `${valueToFormat}%`;

  const onSliderChange =
    (type: "width" | "height") => (_ev: Event, newWidth: number | number[]) =>
      changeConfigValue(type, newWidth);

  const handleVisibilityChange = useCallback(
    (_: any, isShown: boolean) => {
      changeConfigValue("showLabel", isShown);
    },
    [changeConfigValue],
  );

  return (
    <>
      <Section title={translation.valueLabel} wrapped={true}>
        <TableColumnEditor
          id={id}
          value={value}
          onChange={handleValueChange}
          allowedDataTypeIsArray={false}
          allowedDataTypes={["text"]}
        />
        <CustomExpressionEditor
          value={value}
          config={config}
          onChange={handleValueChange}
          nonExpressionEditor={customExpressionInput}
          label={translation.colorPickerLabel}
          switcherDisabled={false}
          onToggleMode={handleToggleMode}
        />
      </Section>
      <Section title={translation.styleLabel} wrapped={true}>
        <Box>
          <Typography>{translation.widthLabel}</Typography>
          <Slider
            name="width"
            value={width ?? 0}
            valueLabelDisplay="auto"
            valueLabelFormat={formatSliderValue}
            step={10}
            min={20}
            max={100}
            onChange={onSliderChange("width")}
          />
        </Box>
        <Box>
          <Typography>{translation.heightLabel}</Typography>
          <Slider
            name="height"
            value={height ?? 0}
            valueLabelDisplay="auto"
            valueLabelFormat={formatSliderValue}
            step={10}
            min={20}
            max={100}
            onChange={onSliderChange("height")}
          />
        </Box>
        <Box>
          <FormControlLabel
            label={translation.showLabelLabel}
            control={
              <Switch
                value={showLabel ?? false}
                onChange={handleVisibilityChange}
              />
            }
          />
        </Box>
      </Section>
    </>
  );
});
