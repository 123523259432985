import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Action Button",
      description: "",
    },
  },
  minSize: {
    height: 1,
    width: 1,
  },
};
