import { Theme, alpha, darken } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "200px",
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.grey[200],
    },
    "& .MuiDataGrid-row": {
      "&.Mui-odd": {
        backgroundColor: theme.palette.grey[100],
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      },
      "&.Mui-selected": {
        backgroundColor: theme.palette.action.selected,
        "&:hover": {
          backgroundColor: darken(theme.palette.action.selected, 0.5),
        },
      },
    },
  },
  errorTextField: {
    backgroundColor: alpha(theme.palette.error.light, 0.4),
    "&:hover": {
      backgroundColor: alpha(theme.palette.error.light, 0.3),
    },
  },
}));
