import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Leaflet Map GeoJSON Input",
      description: "Map to edit GeoJSON",
    },
  },
  defaultSize: {
    width: 4,
    height: 4,
  },
  minSize: {
    width: 2,
    height: 2,
  },
};
