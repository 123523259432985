import { createAction, createActionTypeScoper } from "core/utils/redux";
import { StorageFileMetadata } from "services/api/types/FileStorage";

export function buildUncontrolledActions(path: string[]) {
  const scope = path.join(".");
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    CLEAR: scopeActionType("CLEAR"),
    FETCH_METADATA_ERROR: scopeActionType("FETCH_METADATA_ERROR"),
    FETCH_METADATA_SUCCESS: scopeActionType("FETCH_METADATA_SUCCESS"),
    FETCH_METADATA: scopeActionType("FETCH_METADATA"),
    UPLOAD_ERROR: scopeActionType("UPLOAD_ERROR"),
    UPLOAD_SUCCESS: scopeActionType("UPLOAD_SUCCESS"),
    UPLOAD: scopeActionType("UPLOAD"),
  };

  const actions = {
    upload: createAction(types.UPLOAD, (file: File | null) => ({
      file,
    })),
    uploadSuccess: createAction(
      types.UPLOAD_SUCCESS,
      (metadata: StorageFileMetadata) => ({
        metadata,
      }),
    ),
    uploadError: createAction(types.UPLOAD_ERROR, (error: any) => ({ error })),
    clear: createAction(types.CLEAR),
    fetchMetadata: createAction(types.FETCH_METADATA),
    fetchMetadataSuccess: createAction(
      types.FETCH_METADATA_SUCCESS,
      (metadata: StorageFileMetadata | null) => ({
        metadata,
      }),
    ),
    fetchMetadataError: createAction(
      types.FETCH_METADATA_ERROR,
      (error: any) => ({ error }),
    ),
  };

  return { types, actions };
}
