import { Theme } from "@mui/material";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  node: {
    textTransform: "uppercase",
    height: "auto",
    borderRadius: theme.spacing(0.5),

    "& .custom-drag-handle": {
      cursor: "grab",
      transform: "rotate(45deg)",
      "& > svg": {
        height: "0.7em",
      },
    },
    padding: 0,
    cursor: "pointer",
    "& .MuiChip-label": {
      padding: theme.spacing(1.5),
      position: "relative",
      display: "flex",
      alignItems: "center",
      minWidth: "70px",
    },
  },
  nodeBgColor: {
    backgroundColor: theme.palette.background.paper,
  },
  staticNode: {
    backgroundColor: theme.palette.grey[600],
    color: "white",
    fontWeight: 600,
  },

  handleClass: {
    "&.react-flow__handle": {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "transparent",
      transform: "none",
      border: "none",
      borderRadius: 0,
    },
  },
}));

export const useTransitionStyles = makeStyles<{ selected: boolean }>()(
  (theme: Theme, { selected }) => ({
    group: {
      "& .react-flow__edge-path": {
        stroke: theme.palette.grey[400],
      },
      "&:hover .react-flow__edge-path": {
        stroke: theme.palette.text.primary,
      },
      "&:hover .react-flow__edge-btn": {
        borderColor: theme.palette.text.primary,
      },
    },
    path: {
      cursor: "pointer",
      transition: "stroke 0.5s ease-in-out",
    },
    transitionContainer: {
      textAlign: "center",
    },
    edgeButtonClass: {
      display: "flex",
      width: "100px",
      borderRadius: theme.spacing(),
      color: selected ? theme.palette.common.white : theme.palette.text.primary,
      backgroundColor: selected
        ? theme.palette.text.primary
        : theme.palette.common.white,
      borderColor: selected
        ? theme.palette.text.primary
        : theme.palette.grey[400],
      border: "1px solid",
      transition: "border-color 0.5s ease-in-out",

      "&:hover, &:focus": {
        cursor: "pointer",
      },
    },
  }),
);
