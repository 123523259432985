import { buildCustomExpressionValue } from "core";
import { IDefaultElement } from "core/types/element";

import { UntransformedClipboardCopyButtonConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedClipboardCopyButtonConfig> =
  {
    i18n: {
      en: {
        label: "",
      },
    },
    config: {
      value: buildCustomExpressionValue(null),
      disabled: false,
    },
  };
