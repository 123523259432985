import { AutocompleteOption } from "elementTypes/common/Autocomplete/types";
import { UntransformedAutocompleteInputConfig } from "./types";

export const getQueriesFromConfig = (
  config: UntransformedAutocompleteInputConfig,
) => [config.reference?.viewName].filter(Boolean) as string[];

export const customSort = (a: AutocompleteOption, b: AutocompleteOption) => {
  const labelNumberA = parseFloat(a.label);
  const labelNumberB = parseFloat(b.label);
  if (!isNaN(labelNumberA) && !isNaN(labelNumberB)) {
    return labelNumberA - labelNumberB;
  }
  return a.label.localeCompare(b.label);
};
