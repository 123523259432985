import type { IColor } from "core/editor/types";

export enum Colors {
  primary = "primary",
  secondary = "secondary",
  success = "success",
  error = "error",
  warning = "warning",
  info = "info",
  default = "text.primary",
  lighter = "text.secondary",
  disabled = "text.disabled",
  white = "common.white",
  black = "common.black",
}

export enum ContrastColors {
  primary = "primary.contrastText",
  secondary = "secondary.contrastText",
  success = "success.contrastText",
  error = "error.contrastText",
  warning = "warning.contrastText",
  info = "info.contrastText",
  default = "background.default",
  lighter = "background.paper",
  disabled = "divider",
  white = "common.black",
  black = "common.white",
}

export type Color = keyof typeof Colors;
export type ContrastColor = keyof typeof ContrastColors;

type AllColors = Color | IColor | "transparent";

export const adaptColor = (color: AllColors | string) => {
  let adaptedColor: Colors | string = color;

  switch (color) {
    case "primary":
    case "secondary":
    case "success":
    case "warning":
    case "info":
    case "error":
      adaptedColor = `${adaptedColor}.main`;
      break;
    case "default":
      adaptedColor = `text.primary`;
      break;
    case "lighter":
      adaptedColor = `text.secondary`;
      break;
    case "disabled":
      adaptedColor = `text.${adaptedColor}`;
      break;
    case "white":
    case "black":
      adaptedColor = `common.${adaptedColor}`;
      break;
    default:
      break;
  }

  return adaptedColor;
};
