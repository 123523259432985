import { UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";
import { QueryToPagesMappingValue } from "core";
import AdminService from "services/admin";
import {
  DeleteQueryGroup,
  DeleteQueryParams,
  QueryGroup,
  QueryObject,
} from "staticPages/admin/pages/modelBuilder/components/types";
import {
  CustomQueryResponse,
  DataPreviewResponse,
  TestCustomQueryResponse,
} from "staticPages/admin/pages/modelBuilder/customQuery/types";
import {
  DataModel,
  HistoryTrackingSwitchParams,
  SetColumnAliasResponse,
  WorkflowSetupParams,
} from "staticPages/admin/pages/modelBuilder/erd/types";
import { Workflow } from "staticPages/admin/pages/modelBuilder/workflow/components/StateViewer/types";
import {
  CreateStateParams,
  CreateStateTransitionParams,
  DeleteStateParams,
  DeleteStateTransitionParams,
  UpdateStateParams,
  UpdateStateTransitionParams,
  WorkflowActivationParams,
} from "staticPages/admin/pages/modelBuilder/workflow/types";

import { StateResponsePayload } from "../../staticPages/admin/pages/modelBuilder/workflow/components/types";
import {
  Query,
  useAuthenticatedMutation,
  useAuthenticatedQuery,
} from "../utils";

import { QueryKeys } from "./queryKeys";
import { getQueriesOptions } from "./utils";

const services = AdminService.getInstance();

export type ColumnValuesArguments = Parameters<
  typeof services.getColumnValues
>[1];

type WorkflowArguments = Parameters<typeof services.getStateWorkflow>[1];
type StateNodeArguments = Parameters<typeof services.getStateNode>[1];
type DefaultQueryArguments = Parameters<typeof services.createDefaultQuery>[1];
type CustomQueryArguments = Parameters<typeof services.createCustomQuery>[1];
type DefaultQueriesArguments = Parameters<
  typeof services.createDefaultQueries
>[1];
type EditCustomQueryArguments = Parameters<typeof services.editCustomQuery>[1];
export type EditDefaultQueryArguments = Parameters<
  typeof services.editDefaultQuery
>[1];
type GetCustomQueryArguments = Parameters<typeof services.getGeneratedQuery>[1];
export type stateTransitionArguments = Parameters<
  typeof services.getStateTransition
>[1];
type DataPreviewArguments = Parameters<typeof services.getDataPreview>[1];
type ColumnAliasArguments = Parameters<typeof services.setColumAlias>[1];
type TestCustomQueryArguments = Parameters<typeof services.testCustomQuery>[1];
type CreateQueryGroupArguments = Parameters<
  typeof services.createQueryGroup
>[1];
type DeleteQueryGroupArguments = Parameters<
  typeof services.deleteQueryGroup
>[1];
type UpdateQueryGroupArguments = Parameters<
  typeof services.updateQueryGroup
>[1];
type UpdateQueryWithQueryGroupArguments = Parameters<
  typeof services.updateQueryWithQueryGroup
>[1];

export const fetchQueries: Query<QueryObject[]> = {
  queryKey: QueryKeys.fetchQueries,
  queryFn: services.getQueries,
};

export const fetchModel: Query<DataModel> = {
  queryKey: QueryKeys.fetchModel,
  queryFn: services.getModel,
};

export const fetchColumnValues: Query<
  Record<string, unknown>[],
  ColumnValuesArguments
> = {
  queryKey: QueryKeys.fetchColumnValues,
  queryFn: services.getColumnValues,
};

export const fetchStateWorkflow: Query<Workflow, WorkflowArguments> = {
  queryKey: QueryKeys.fetchWorkflow,
  queryFn: services.getStateWorkflow,
};

export const fetchStateNode = {
  queryKey: QueryKeys.fetchStateNode,
  queryFn: services.getStateNode,
};

export const fetchDataPreview: Query<
  DataPreviewResponse,
  DataPreviewArguments
> = {
  queryKey: QueryKeys.fetchDataPreview,
  queryFn: services.getDataPreview,
};

export const fetchStateTransition: Query<
  ReturnType<typeof services.getStateTransition>,
  stateTransitionArguments
> = {
  queryKey: QueryKeys.fetchStateTransition,
  queryFn: services.getStateTransition,
};

export const fetchGeneratedQuery = {
  queryKey: QueryKeys.fetchGeneratedQuery,
  queryFn: services.getGeneratedQuery,
};

export const useModel = () => useAuthenticatedQuery(fetchModel);

export const useQueries = (
  params?: { role: string },
  queryToPagesMapping?: Record<string, QueryToPagesMappingValue>,
) =>
  useAuthenticatedQuery(
    fetchQueries,
    params,
    queryToPagesMapping
      ? {
          select: getQueriesOptions(queryToPagesMapping),
        }
      : undefined,
  );

export const useColumnValues = (
  params: ColumnValuesArguments,
  config?: UseQueryOptions<
    Record<string, unknown>[],
    Error,
    Record<string, unknown>[]
  >,
) => useAuthenticatedQuery(fetchColumnValues, params, config);

export const useDataPreview = (
  options?: UseMutationOptions<
    DataPreviewResponse,
    unknown,
    DataPreviewArguments
  >,
) => useAuthenticatedMutation(services.getDataPreview, options);

export const getTableDataPreview: Query<
  DataPreviewResponse,
  DataPreviewArguments
> = {
  queryKey: QueryKeys.fetchTableDataPreview,
  queryFn: services.getDataPreview,
};

export const fetchQueryGroups = {
  queryKey: QueryKeys.fetchQueryGroups,
  queryFn: services.getQueryGroups,
};

export const fetchInternalModel = {
  queryKey: QueryKeys.fetchInternalModel,
  queryFn: services.getInternalModel,
};

export const useDataPreviewQuery = (
  params: DataPreviewArguments,
  config?: UseQueryOptions<DataPreviewResponse, Error>,
) => useAuthenticatedQuery(getTableDataPreview, params, config);

export const useWorkFlow = (
  params: WorkflowArguments,
  config?: UseQueryOptions<Workflow, Error>,
) => useAuthenticatedQuery(fetchStateWorkflow, params, config);

export const useStateNode = (
  params: StateNodeArguments,
  config?: UseQueryOptions<StateResponsePayload, Error>,
) => useAuthenticatedQuery(fetchStateNode, params, config);

export const useGeneratedQuery = (
  params: GetCustomQueryArguments,
  config?: UseQueryOptions<CustomQueryResponse, Error>,
) => useAuthenticatedQuery(fetchGeneratedQuery, params, config);

export const useCreateDefaultQuery = (
  options?: UseMutationOptions<unknown, unknown, DefaultQueryArguments>,
) => useAuthenticatedMutation(services.createDefaultQuery, options);

export const useCreateDefaultQueries = (
  options?: UseMutationOptions<unknown, unknown, DefaultQueriesArguments>,
) => useAuthenticatedMutation(services.createDefaultQueries, options);

export const useCreateCustomQuery = (
  options?: UseMutationOptions<unknown, unknown, CustomQueryArguments>,
) => useAuthenticatedMutation(services.createCustomQuery, options);

export const useEditCustomQuery = (
  options?: UseMutationOptions<unknown, unknown, EditCustomQueryArguments>,
) => useAuthenticatedMutation(services.editCustomQuery, options);

export const useEditDefaultQuery = (
  options?: UseMutationOptions<unknown, unknown, EditDefaultQueryArguments>,
) => useAuthenticatedMutation(services.editDefaultQuery, options);

export const useStateTransition = (
  params: stateTransitionArguments,
  config?: UseQueryOptions<any, Error>,
) => useAuthenticatedQuery(fetchStateTransition, params, config);

export const useCreateState = (
  options?: UseMutationOptions<unknown, unknown, CreateStateParams>,
) => useAuthenticatedMutation(services.createState, options);

export const useUpdateState = (
  options?: UseMutationOptions<unknown, unknown, UpdateStateParams>,
) => useAuthenticatedMutation(services.updateState, options);

export const useDeleteState = (
  options?: UseMutationOptions<unknown, unknown, DeleteStateParams>,
) => useAuthenticatedMutation(services.deleteState, options);

export const useCreateStateTransition = (
  options?: UseMutationOptions<unknown, unknown, CreateStateTransitionParams>,
) => useAuthenticatedMutation(services.createStateTransition, options);

export const useUpdateStateTransition = (
  options?: UseMutationOptions<unknown, unknown, UpdateStateTransitionParams>,
) => useAuthenticatedMutation(services.updateStateTransition, options);

export const useWorkflowSetup = (
  options?: UseMutationOptions<unknown, unknown, WorkflowSetupParams>,
) => useAuthenticatedMutation(services.workflowSetup, options);

export const useWorkflowActivationSwitch = (
  options?: UseMutationOptions<unknown, unknown, WorkflowActivationParams>,
) => useAuthenticatedMutation(services.workflowActivationSwitch, options);

export const useColumnAliasQuery = (
  options?: UseMutationOptions<
    SetColumnAliasResponse,
    unknown,
    ColumnAliasArguments
  >,
) => useAuthenticatedMutation(services.setColumAlias, options);

export const useDeleteStateTransition = (
  options?: UseMutationOptions<unknown, unknown, DeleteStateTransitionParams>,
) => useAuthenticatedMutation(services.deleteStateTransition, options);

export const useTestCustomQuery = (
  options?: UseMutationOptions<
    TestCustomQueryResponse,
    unknown,
    TestCustomQueryArguments
  >,
) => useAuthenticatedMutation(services.testCustomQuery, options);

export const useHistoryTrackingSwitch = (
  options?: UseMutationOptions<unknown, unknown, HistoryTrackingSwitchParams>,
) => useAuthenticatedMutation(services.historyTrackingSwitch, options);

export const useDeleteQuery = (
  options?: UseMutationOptions<unknown, unknown, DeleteQueryParams>,
) => useAuthenticatedMutation(services.deleteQuery, options);

export const useDeleteWorkflow = (
  options?: UseMutationOptions<
    unknown,
    unknown,
    {
      schema: string;
      table: string;
    }
  >,
) => useAuthenticatedMutation(services.deleteWorkflow, options);

export const useDeleteQueries = (
  options?: UseMutationOptions<
    { viewName: string; result: Record<string, unknown> | Error }[],
    unknown,
    { viewNames: string[] }
  >,
) => useAuthenticatedMutation(services.deleteQueries, options);

const getQueryGroups = (data: QueryGroup[]) =>
  data.map((qg) => ({
    ...qg,
    queries: qg.queries.reduce(
      (res, id) => (!id ? res : [...res, Number(id)]),
      [] as number[],
    ),
  }));

export const useQueryGroups = (
  options?: UseQueryOptions<QueryGroup[], Error, QueryGroup[]>,
) =>
  useAuthenticatedQuery(fetchQueryGroups, undefined, {
    ...options,
    select: getQueryGroups,
  });

export const useCreateQueryGroup = (
  options?: UseMutationOptions<QueryGroup, unknown, CreateQueryGroupArguments>,
) => useAuthenticatedMutation(services.createQueryGroup, options);

export const useEditQueryGroup = (
  options?: UseMutationOptions<QueryGroup, unknown, UpdateQueryGroupArguments>,
) => useAuthenticatedMutation(services.updateQueryGroup, options);

export const useDeleteQueryGroup = (
  options?: UseMutationOptions<
    DeleteQueryGroup,
    unknown,
    DeleteQueryGroupArguments
  >,
) => useAuthenticatedMutation(services.deleteQueryGroup, options);

export const useUpdateQueryWithQueryGroup = (
  options?: UseMutationOptions<
    unknown,
    unknown,
    UpdateQueryWithQueryGroupArguments
  >,
) => useAuthenticatedMutation(services.updateQueryWithQueryGroup, options);

export const useInternalModel = () => useAuthenticatedQuery(fetchInternalModel);
