import * as t from "io-ts";
import { types } from "core/runtime-typing";
import { IElement, IElementArrayChild, IElementSingleChild } from "core/types";
import { SelectorTypes } from "core/types/element";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";

import { FormInputConfig } from "../common";

export const textInputSelectors: SelectorTypes<TextInputConfig> = {
  value: types.nullable(types.union([types.string(), types.number()])),
  errors: types.any("The data validation errors"),
  touched: types.boolean(
    "Indicates whether the user has typed anything in the input",
  ),
  disabled: types.optional(types.boolean()),
};

export const TextInputConfig = t.intersection([
  WithOptionalFieldDataSourceConfig(t.union([t.null, t.string, t.number])),
  FormInputConfig,
  t.partial({
    startAdornment: t.string,
    endAdornment: t.string,
    startAdornmentIcon: t.string,
    endAdornmentIcon: t.string,
    multiline: t.number,
    regexPattern: t.string,
    tooltipPlacement: t.keyof({
      top: null,
      end: null,
      start: null,
      bottom: null,
    }),
  }),
]);

export type TextInputConfig = t.TypeOf<typeof TextInputConfig>;

export const TextInputTranslationKeys = ["label", "helperText"] as const;

export type TextInputTranslationKeys =
  (typeof TextInputTranslationKeys)[number];

export type TextInput = IElement<
  TextInputConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  TextInputTranslationKeys
>;
