import { memo } from "react";

import { CodeBlock } from "../../common/CodeBlock";

interface IOwnProps {
  value: string;
}

type IProps = IOwnProps;

export const InlineCode = memo<IProps>(({ value }) => (
  <CodeBlock value={value}>{value}</CodeBlock>
));
