import { createUseTranslation } from "core/session/translation";

export const useLoginFormTranslation = createUseTranslation({
  en: {
    buttonLogin: "Login",
    identifierLabel: "Username / Email",
    passwordLabel: "Password",
  },
  de: {
    buttonLogin: "Anmelden",
    identifierLabel: "Benutzername / E-Mail",
    passwordLabel: "Passwort",
  },
});

export const useRootTranslation = createUseTranslation({
  en: {
    method: "Method",
  },
  de: {
    method: "Anmeldemethode",
  },
});
