import * as t from "io-ts";
import { types } from "core/runtime-typing";
import {
  IElement,
  IElementArrayChild,
  IElementSingleChild,
  UntransformedConfig,
} from "core/types";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";

import { FormInputConfig } from "../common";

export const defaultArrayTextInputSelectorTypes = {
  value: types.nullable(types.array(types.string())),
  disabled: types.optional(types.boolean()),
};

export const ArrayTextInputConfig = t.intersection([
  WithOptionalFieldDataSourceConfig(t.union([t.null, t.array(t.string)])),
  FormInputConfig,
]);

export type ArrayTextInputConfig = t.TypeOf<typeof ArrayTextInputConfig>;

export type UntransformedArrayTextInputConfig =
  UntransformedConfig<ArrayTextInputConfig>;

export const ArrayTextInputTranslationKeys = ["label"] as const;

export type ArrayTextInputTranslationKeys =
  (typeof ArrayTextInputTranslationKeys)[number];

export type ArrayTextInput = IElement<
  ArrayTextInputConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  ArrayTextInputTranslationKeys
>;
