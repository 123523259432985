import { ChangeEvent, memo, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

import { PageAutocomplete } from "..";
import { useSessionContext } from "../../../session";
import { IPage } from "../../../types";
import { getTranslatedText } from "../../../utils/element-utils";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "../CustomExpressionEditor";

import { mapStateToProps } from "./container";
import { usePageSelectorTranslation } from "./translation";

type Props = ReturnType<typeof mapStateToProps> & {
  pageId: string | null;
  params?: any;
  config: Record<string, any>;
  onChange: (page: IPage, params: any) => void;
  label?: string;
  onDelete?: () => void;
  firstPageSelected?: boolean;
};

type ParamInputProps = {
  label: string;
  value: string;
  config: Record<string, any>;
  onChange: (label: string, value: string) => void;
};

const ParamInput = memo<ParamInputProps>(
  ({ config, label, value, onChange }) => {
    const handleChange = (nextValue: string) => onChange(label, nextValue);

    const customInput = ({
      value: paramValue,
      onChange: onParamChange,
    }: NonExpressionEditorProps) => {
      const onCustomChange = (e: ChangeEvent<HTMLInputElement>) =>
        onParamChange(e.target.value);

      return (
        <TextField
          label={label}
          value={paramValue}
          onChange={onCustomChange}
          fullWidth={true}
        />
      );
    };

    return (
      <CustomExpressionEditor
        label={label}
        config={config}
        value={value}
        onChange={handleChange}
        nonExpressionEditor={customInput}
      />
    );
  },
);

export const PageSelector = memo<Props>(
  ({
    config,
    onChange,
    onDelete,
    pages,
    pageId,
    params,
    label = "",
    firstPageSelected,
  }) => {
    const { language } = useSessionContext();
    const { paramsLabel } = usePageSelectorTranslation();

    const selectedPage = pageId && pages[pageId];

    const options = useMemo(
      () =>
        Object.keys(pages).map((innerPageId) => {
          const { id, url, i18n } = pages[innerPageId];
          return {
            value: id,
            label: getTranslatedText(language, i18n, "label") ?? url,
            url,
          };
        }),
      [pages, language],
    );

    const handlePageIdChange = (newPageId: string) => {
      const page = pages[newPageId];
      if (page) {
        onChange(page, {});
      } else if (newPageId === "") {
        onDelete?.();
      }
    };

    useMemo(() => {
      if (firstPageSelected && options?.[0]?.value && !pageId) {
        handlePageIdChange(options[0].value);
      }
    }, []);

    let pageParams;

    if (selectedPage && !!Object.keys(selectedPage.params).length) {
      const handleParamChange = (k: string, value: string) =>
        onChange(selectedPage, {
          ...(params ?? {}),
          [k]: value,
        });

      pageParams = Object.keys(selectedPage.params).map((k) => (
        <ParamInput
          key={k}
          label={k}
          config={config}
          value={params[k] || ""}
          onChange={handleParamChange}
        />
      ));
    } else {
      pageParams = null;
    }

    return (
      <>
        <PageAutocomplete
          value={pageId}
          options={options}
          onChange={handlePageIdChange}
          label={label}
          clearable={!!onDelete}
        />
        {pageParams ? (
          <Box display="flex" flexDirection="column" gap={1} mt={2}>
            <Typography variant="subtitle2">{paramsLabel}</Typography>
            <Box gap={1}>{pageParams}</Box>
          </Box>
        ) : null}
      </>
    );
  },
);
