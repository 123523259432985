import * as t from "io-ts";
import { getViewRowType } from "core/editor/types";
import { types } from "core/runtime-typing";
import { IElement, TypeFactory } from "core/types";
import { UntransformedConfig } from "core/types/react";

import {
  EChartsTranslationKeys,
  EchartsContainerSingleConfig,
} from "../common/Echarts";

export const PieChartConfig = t.intersection([
  EchartsContainerSingleConfig,
  t.partial({
    hideLabel: t.boolean,
    hideLegend: t.boolean, // TODO: remove with app migration
    isDonut: t.boolean,
    noDataStyles: t.type({
      color: t.string,
      borderColor: t.string,
    }),
  }),
]);

export type PieChartConfig = t.TypeOf<typeof PieChartConfig>;
export type UntransformedPieChartConfig = UntransformedConfig<PieChartConfig>;

export type PieChart = IElement<PieChartConfig, {}, EChartsTranslationKeys>;

const selectedRowType: TypeFactory<PieChartConfig> = (params) =>
  getViewRowType({ isNullable: true })(params);

export const selectorTypes = {
  loading: types.boolean(),
  data: types.any(),
  error: types.nullable(types.string()),
  selected: selectedRowType,
};
