import { RoutePaths } from "../../../routes";
import { SettingsPage } from "./component";
import { EditUsersPageRoute } from "./pages";

export const route = {
  Component: SettingsPage,
  auth: true,
  isAdmin: true,
  routes: {
    [RoutePaths.AdminSettingsEdit]: EditUsersPageRoute,
  },
};
