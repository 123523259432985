import { GeneralType, IObjectView } from "../types";

import { AnyRecordType } from "./AnyRecordType";
import { AnyType } from "./AnyType";
import { ArrayType } from "./ArrayType";
import { BooleanType } from "./BooleanType";
import { FunctionType } from "./FunctionType";
import { InterfaceType } from "./InterfaceType";
import { LiteralType } from "./LiteralType";
import { NullableType } from "./NullableType";
import { NullType } from "./NullType";
import { NumberType } from "./NumberType";
import { OptionalType } from "./OptionalType";
import { StringType } from "./StringType";
import { Type } from "./Type";
import { types } from ".";

export function buildNullableObjectViewType(
  objectView: IObjectView,
  nullable: boolean | null,
  description?: string,
  additionalFields?: Record<PropertyKey, Type>,
): Type {
  return new InterfaceType(
    objectView.fields.reduce((acc, field) => {
      let type = mapGeneralType(field.generalType);
      if (nullable) {
        type = new OptionalType(new NullableType(type));
      } else if (nullable === null && field.nullable) {
        type = new NullableType(type);
      }

      return {
        ...acc,
        [field.name]: type,
        ...additionalFields,
      };
    }, {}),
    description,
  );
}

export function buildObjectViewType(
  objectView: IObjectView,
  description?: string,
): Type {
  return buildNullableObjectViewType(objectView, null, description);
}

export function mapGeneralType(generalType: GeneralType) {
  let type: Type;
  if (
    ["text", "dateTime", "date", "time", "fallback", "geo"].includes(
      generalType.type,
    )
  ) {
    type = new StringType();
  } else if (generalType.type === "number") {
    type = new NumberType();
  } else if (generalType.type === "boolean") {
    type = new BooleanType();
  } else {
    // we don't know the exact object type
    type = new AnyRecordType();
  }
  if (generalType.isArray) {
    type = new ArrayType(type);
  }
  return type;
}

export function objectToType(obj: any, description?: string): Type {
  let type: Type;
  const tof = typeof obj;
  if (obj === null) {
    type = new NullType(description);
  } else if (obj === undefined) {
    type = new LiteralType(undefined, description);
  } else if (tof === "boolean") {
    type = new BooleanType(description);
  } else if (tof === "number") {
    type = new NumberType(description);
  } else if (tof === "string") {
    type = new StringType(description);
  } else if (tof === "function") {
    type = new FunctionType(new AnyType(), description);
  } else if (Array.isArray(obj)) {
    // Since JS allows mixed type Arrays we can't really infer the type
    type = new ArrayType(new AnyType(), description);
  } else if (tof === "object") {
    const keys = Object.getOwnPropertyNames(obj);
    type = new InterfaceType(
      keys.reduce((acc, k) => ({ ...acc, [k]: objectToType(obj[k]) }), {}),
      description,
    );
  } else {
    type = new AnyType(description);
  }
  return type;
}

export function buildReferencesType(
  references: {
    [k: string]: {
      viewName: string;
      identifierName: string;
    };
  },
  viewList: IObjectView[],
  nullable: boolean | null,
  isArray: boolean | null,
  description?: string,
): Type {
  return new InterfaceType(
    Object.entries(references).reduce((acc, [key, { viewName }]) => {
      let type: Type;
      const view = viewList.find((v) => v.name === viewName);
      if (!view) {
        throw new Error(`Invalid query ${viewName}`);
      }
      type = buildObjectViewType(view, "The referenced data for the row");
      if (isArray) {
        type = types.array(type, "The referenced data rows");
      }
      if (nullable) {
        type = types.nullable(type, type.description);
      }

      return {
        ...acc,
        [key]: type,
      };
    }, {}),
    description,
  );
}
