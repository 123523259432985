import { ChangeEvent, ReactNode, memo, useState } from "react";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { ToggleButtonGroup } from "@mui/material";
import camelCase from "lodash/camelCase";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import { IAutocompleteValue } from "elementTypes/common/Autocomplete/types";
import IconButton from "elementTypes/common/IconButton";
import { ToggleButton } from "elementTypes/common/ToggleButton";

import { useLDAPContext } from "../context";
import { useCommonStaticPagesTranslation } from "../translation";
import { objectHasValue } from "../utils";

type ContentProps = {
  formActions: ReactNode;
  children: ReactNode;
  errors?: Record<string, unknown>;
};

enum ConfigType {
  static_role = "static_role",
  postgres_role_in_ldap = "postgres_role_in_ldap",
  ldap_role_to_postgres = "ldap_role_to_postgres",
}

export const FormLDAP = memo<ContentProps>(({ children, formActions }) => {
  const translation = useCommonStaticPagesTranslation();
  const {
    ldapConfiguration,
    roleOptions,
    errors: validationErrors,
    onChange,
  } = useLDAPContext();

  const errors = objectHasValue(validationErrors?.ldapConfiguration ?? {})
    ? validationErrors?.ldapConfiguration
    : null;

  const {
    searchAttribute,
    defaultRole,
    url,
    bindDn,
    bindPassword,
    baseDn,
    roleAttribute,
    roleMapping,
  } = ldapConfiguration;

  const handleChangeConfig = (ev: ChangeEvent<HTMLInputElement>) => {
    ev.persist();
    onChange(["ldapConfiguration", ev.target.name], ev.target.value);
  };

  const handleAutocompleteChange = (newValue: IAutocompleteValue) => {
    if (newValue) {
      const nextVal = {
        ...ldapConfiguration,
        defaultRole: newValue as string,
        roleAttribute: null,
        roleMapping: false,
      };

      onChange(["ldapConfiguration"], nextVal);
    }
  };

  const handleChangeConfigType = (_ev: any, value: ConfigType) => {
    if (!value) {
      return;
    }
    let nextVal = {};

    if (value === ConfigType.static_role) {
      nextVal = {
        defaultRole: roleOptions?.[0].value as string,
        roleAttribute: null,
        roleMapping: false,
      };
    } else if (value === ConfigType.ldap_role_to_postgres) {
      nextVal = {
        defaultRole: null,
        roleAttribute: ldapConfiguration.roleAttribute ?? "",
        roleMapping: true,
      };
      onChange(["roleMappings"], []);
    } else if (value === ConfigType.postgres_role_in_ldap) {
      nextVal = {
        defaultRole: null,
        roleAttribute: ldapConfiguration.roleAttribute ?? "",
        roleMapping: false,
      };
    }

    onChange(["ldapConfiguration"], {
      ...ldapConfiguration,
      ...nextVal,
    });
  };

  const roleMappingCheck = defaultRole
    ? ConfigType.static_role
    : roleMapping
      ? ConfigType.ldap_role_to_postgres
      : ConfigType.postgres_role_in_ldap;

  const buttonItems = Object.values(ConfigType).map((btnConfig) => (
    <ToggleButton key={btnConfig} value={btnConfig} aria-label={btnConfig}>
      <Typography>{translation[`${camelCase(btnConfig)}Label`]}</Typography>
    </ToggleButton>
  ));

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const togglePassword = () => setShowPassword((prevShown) => !prevShown);

  return (
    <Box display="flex" gap={1} flexDirection="column">
      <TextField
        data-test-id={(errors?.url ?? "") as string}
        required
        label={translation.urlInputLabel}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        name="url"
        value={url}
        onChange={handleChangeConfig}
        error={Boolean(errors?.url)}
        helperText={(errors?.url ?? "") as string}
        variant="standard"
      />
      <Box display="grid" gap={1} gridTemplateColumns="2fr 1fr">
        <TextField
          required
          label={translation.bindDnInputLabel}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          name="bindDn"
          value={bindDn}
          onChange={handleChangeConfig}
          error={Boolean(errors?.bindDn)}
          helperText={(errors?.bindDn ?? "") as string}
          variant="standard"
        />
        <TextField
          data-test-id={(errors?.bindPassword ?? "") as string}
          required
          label={translation.bindpwdInputLabel}
          type={showPassword ? "text" : "password"}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          name="bindPassword"
          value={bindPassword}
          onChange={handleChangeConfig}
          error={Boolean(errors?.bindPassword)}
          helperText={(errors?.bindPassword ?? "") as string}
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={togglePassword}
                  icon={showPassword ? "visibility" : "visibility-off"}
                />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <TextField
        required
        label={translation.baseDnInputLabel}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        name="baseDn"
        value={baseDn}
        onChange={handleChangeConfig}
        error={Boolean(errors?.baseDn)}
        helperText={(errors?.baseDn ?? "") as string}
        variant="standard"
      />
      <TextField
        data-test-id={(errors?.searchAttribute ?? "") as string}
        required
        label={translation.searchAttributeInputLabel}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        name="searchAttribute"
        value={searchAttribute}
        onChange={handleChangeConfig}
        error={Boolean(errors?.searchAttribute)}
        helperText={(errors?.searchAttribute ?? "") as string}
        variant="standard"
      />
      <ToggleButtonGroup
        value={roleMappingCheck}
        exclusive
        onChange={handleChangeConfigType}
        aria-label="handleRoleMapping"
        size="small"
        fullWidth
      >
        {buttonItems}
      </ToggleButtonGroup>
      {[
        ConfigType.postgres_role_in_ldap,
        ConfigType.ldap_role_to_postgres,
      ].includes(roleMappingCheck) && (
        <TextField
          required
          label={translation.roleAttributeInputLabel}
          fullWidth
          name="roleAttribute"
          value={roleAttribute}
          onChange={handleChangeConfig}
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(errors?.roleAttribute)}
          helperText={(errors?.roleAttribute ?? "") as string}
          variant="standard"
        />
      )}
      {roleMappingCheck === ConfigType.static_role && (
        <Autocomplete
          options={roleOptions}
          value={defaultRole ?? ""}
          onChange={handleAutocompleteChange}
          name="defaultRole"
          label={translation.defaultRoleInputLabel}
          required
        />
      )}
      {roleMappingCheck === ConfigType.ldap_role_to_postgres && <>{children}</>}
      {formActions}
    </Box>
  );
});
