import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: `calc(100% - ${theme.spacing(6)})`,
    width: "100%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    gridArea: "top",
  },
  title: {
    flexGrow: 1,
  },
  formFooter: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
  logo: {
    display: "block",
    height: 250,
    objectFit: "contain",

    "&:hover": {
      "& [class*='-st0']": {
        filter: "brightness(1.25) contrast(103%)",
        transition: "filter 0.3s ease-in-out",
      },
      "& [class*='-st1']": {
        filter: "brightness(1.25) contrast(103%)",
        transition: "filter 0.5s ease-in-out",
      },
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

export const useLoginStyles = makeStyles()(() => ({
  root: {
    marginTop: "10px",
  },
  item: {
    marginBottom: "10px",
  },
  buttonContainer: {
    display: "flex",
  },
}));

export default useStyles;
