import { createUseTranslation } from "core/session/translation";

export const dateTimeInputEditorTranslation = {
  en: {
    stylingTitle: "Styling",
    labelLabel: "Label",
    formatLabel: "Format",
    showDatePartLabel: "Date",
    showTimePartLabel: "Time",
    configTitle: "Configuration",
    minDateLabel: "Minimum Date",
    maxDateLabel: "Maximum Date",
    formatStringLabel: "Display Format",
    formatHelperText: "Leave empty for automatic format",
  },
};

export const useDateTimeInputEditorTranslation = createUseTranslation(
  dateTimeInputEditorTranslation,
);
