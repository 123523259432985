import { Location } from "history";
import { IPage, TCompiledRoute } from "../../types";
import { createAction, createActionTypeScoper } from "../../utils/redux";

import { MODULE_NAME } from "./constants";
import { parseQueries } from "./utils";

const scopeActionType = createActionTypeScoper(MODULE_NAME);

export const types = {
  PUSH: scopeActionType("PUSH"),
  REPLACE: scopeActionType("REPLACE"),
  GO: scopeActionType("GO"),
  GO_BACK: scopeActionType("GO_BACK"),
  GO_FORWARD: scopeActionType("GO_FORWARD"),
  LOCATION_CHANGE: scopeActionType("LOCATION_CHANGE"),
  NOT_FOUND: scopeActionType("NOT_FOUND"),
  PAGE_LOAD: scopeActionType("PAGE_LOAD"),
  PAGE_LOAD_SUCCESS: scopeActionType("PAGE_LOAD_SUCCESS"),
  PAGE_LOAD_ERROR: scopeActionType("PAGE_LOAD_ERROR"),
  STATIC_PAGE_LOAD_SUCCESS: scopeActionType("STATIC_PAGE_LOAD_SUCCESS"),
  QUERY_STRING_UPDATE_VALUES: scopeActionType("QUERY_STRING_UPDATE_VALUES"),
  PREVENT_LOCATION_CHANGE_SET: scopeActionType("PREVENT_LOCATION_CHANGE_SET"),
  COMPILED_ROUTES_SET: scopeActionType("COMPILED_ROUTES_SET"),
  PAGES_REPLACE: scopeActionType("PAGES_REPLACE"),
};

export const actions = {
  push: createAction(
    types.PUSH,
    (
      url: string,
      queries?: Record<string, string>,
      state?: Location["state"],
    ) => ({
      url,
      queries,
      state,
    }),
  ),
  replace: createAction(
    types.REPLACE,
    (
      url: string,
      queries?: Record<string, string>,
      state?: Location["state"],
    ) => ({
      url,
      queries,
      state,
    }),
  ),
  go: createAction(types.PUSH, (index: number) => ({ index })),
  goBack: createAction(types.GO_BACK),
  goForward: createAction(types.GO_FORWARD),
  locationChange: createAction(
    types.LOCATION_CHANGE,
    (pathname: string, search: string, state: Location["state"], key = "") => ({
      pathname,
      search,
      state,
      key,
      queries: parseQueries(search),
    }),
  ),
  notFound: createAction(types.NOT_FOUND),
  loadPage: createAction(
    types.PAGE_LOAD,
    (page: IPage, params: Record<string, string>, locationKey?: string) => ({
      page,
      params,
      locationKey,
    }),
  ),
  loadPageSuccess: createAction(
    types.PAGE_LOAD_SUCCESS,
    (page: IPage, params: Record<string, string>, locationKey?: string) => ({
      page,
      params,
      locationKey,
    }),
  ),
  loadPageError: createAction(types.PAGE_LOAD_ERROR, (error: string) => ({
    error,
  })),
  staticPageLoadSuccess: createAction(
    types.STATIC_PAGE_LOAD_SUCCESS,
    (id: string, params: Record<string, string>, isAdmin: boolean) => ({
      id,
      params,
      isAdmin,
    }),
  ),
  updateQueryStringValues: createAction(
    types.QUERY_STRING_UPDATE_VALUES,
    (values: Record<string, string>) => values,
  ),
  setPreventLocationChange: createAction(
    types.PREVENT_LOCATION_CHANGE_SET,
    (value: boolean, { addKeyAlias }: { addKeyAlias?: string } = {}) => ({
      value,
      addKeyAlias,
    }),
  ),
  setCompiledRoutes: createAction(
    types.COMPILED_ROUTES_SET,
    (compiledRoutes: TCompiledRoute[]) => compiledRoutes,
  ),
  replacePages: createAction(
    types.PAGES_REPLACE,
    (allPages: Record<string, IPage>) => allPages,
  ),
};
