import { ChangeEvent, memo, useCallback } from "react";
import TextField from "@mui/material/TextField";
import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";

import { UntransformedInternalLinkFieldConfig } from "../types";

export const DataSourceEditor = memo(() => {
  const {
    elementModel: {
      config,
      config: { dataSource, identifierValue: identifier },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedInternalLinkFieldConfig>();

  const changeDataSource = useCallback(
    (newDataSource: UntransformedInternalLinkFieldConfig["dataSource"]) =>
      changeConfigValue("dataSource", newDataSource),
    [changeConfigValue],
  );

  const { viewName = "", identifierName = "" } = dataSource ?? {};

  const handleViewNameChange = (newViewName: string) => {
    identifier?.length && changeIdentifier("");
    const nextDataSource = newViewName.length
      ? {
          viewName: newViewName,
          identifierName: "",
        }
      : undefined;
    changeDataSource(nextDataSource);
  };

  const handleFieldChange = (
    _: string,
    fieldValue: string[] | string | boolean | number | number[] | null,
  ) => {
    identifier?.length && changeIdentifier("");
    changeDataSource({
      viewName,
      identifierName: fieldValue as string,
    });
  };

  const changeIdentifier = useCallback(
    (newIdentifier: string | undefined) =>
      changeConfigValue("identifierValue", newIdentifier),
    [changeConfigValue],
  );

  const fields = [
    {
      label: "Identifier Name",
      value: identifierName,
      name: "identifierValue",
      isClearable: true,
    },
  ];

  const customIdentifierInput = ({
    value: identifierValue,
    onChange: onIdentifierChange,
  }: {
    value: string;
    onChange: (value: string) => void;
  }) => {
    const onCustomChange = (e: ChangeEvent<HTMLInputElement>) =>
      onIdentifierChange(e.target.value);

    return (
      <TextField
        disabled={!identifierName}
        label="Identifier Value"
        value={identifierValue}
        onChange={onCustomChange}
        fullWidth={true}
      />
    );
  };

  const { dataSourceTitle } = useEditorTranslation();

  return (
    <Section title={dataSourceTitle} wrapped={true} defaultOpened={false}>
      <ViewAutocomplete
        viewValue={viewName}
        viewLabel="Query Name"
        onViewNameChange={handleViewNameChange}
        onViewFieldChange={handleFieldChange}
        fields={fields}
        isClearable={true}
      />
      <CustomExpressionEditor
        value={identifier ?? ""}
        config={config}
        onChange={changeIdentifier}
        label="Identifier Value"
        nonExpressionEditor={customIdentifierInput}
        switcherDisabled={!identifierName}
      />
    </Section>
  );
});
