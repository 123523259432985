import { FieldError } from "react-hook-form";

import { useHookFormErrorTranslation } from "./translation";

export enum HookFormErrorTypes {
  Validate = "validate",
  Required = "required",
  Pattern = "pattern",
}

export const useHookFormError = () => {
  const translation = useHookFormErrorTranslation();

  return (error?: FieldError) => {
    switch (error?.type) {
      case HookFormErrorTypes.Validate:
        return translation.invalidError;
      case HookFormErrorTypes.Required:
        return translation.requiredError;
      case HookFormErrorTypes.Pattern:
        return translation.notMatchError;
      default:
        return undefined;
    }
  };
};
