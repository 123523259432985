import { NodeMenuState } from "../types.ts";

export enum DialogType {
  workflow = "workflow",
  generateQuery = "generateQuery",
  defineLookups = "defineLookups",
  auditTable = "auditTable",
  tableDetails = "tableDetails",
  generateDefaultQueries = "generateDefaultQueries",
}

export enum ERDActions {
  OPEN_MENU = "OPEN_MENU",
  OPEN_DIALOG = "OPEN_DIALOG",
  SET_INITIAL_STATE = "SET_INITIAL_STATE",
}

export const ERDInitialState: NodeMenuState = {
  anchorEl: null,
  open: null,
  nodeData: null,
};

export const WORKFLOW_URL = `/admin/database/workflow`;
