import { memo } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useErrorMessageStyles } from "./styles";

type ErrorMessage = {
  error: string;
};

export const ErrorMessage = memo<ErrorMessage>(({ error }) => {
  const {
    classes: { paper },
  } = useErrorMessageStyles();

  return (
    <Paper variant="outlined" className={paper}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h5">Error</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="error">{error}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
});
