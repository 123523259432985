import { memo, useState } from "react";
import Box from "@mui/material/Box";
import { Section, useElementEditorContext } from "core/editor";
import { useSessionContext, useTranslation } from "core/session";
import { getNewTranslation } from "core/session/translation/utils.ts";
import IconButton from "elementTypes/common/IconButton";
import { useElement, usePage } from "utils/hooks";

import { TabsChildren, UntransformedTabsConfig } from "../../types";
import { deleteTabTranslation, getNewTabParams } from "../../utils";
import { useDefaultTabsEditorTranslation } from "../translation";

import { TabConfigProps } from "./TabConfig";
import { TabDialog } from "./TabDialog";
import { TabsContent } from "./TabsContent";
import { TabsContentProvider } from "./TabsContext";

export const TabsComponent = memo(() => {
  const {
    elementModel,
    elementModel: {
      config: { default: defaultTab },
      i18n,
    },
    changeConfigValue,
    changeTranslation,
  } = useElementEditorContext<UntransformedTabsConfig>();
  const { addTabTooltip, tabsTitle } = useDefaultTabsEditorTranslation();
  const [tabDetails, setTabDetails] = useState<TabConfigProps | null>(null);
  const page = usePage();

  const { language } = useSessionContext();

  const tabLabelsTranslation = useTranslation<string>(i18n);

  const {
    content: { elements: tabs },
  } = elementModel.children as TabsChildren;

  const { updateChildren, createElement } = useElement();

  const handleAddTab = () => setTabDetails(getNewTabParams(tabs.length + 1));
  const handleDialogClose = () => setTabDetails(null);

  const deleteTab = (index: number) => {
    const newTabs = tabs.filter(
      (_: any, tabIndex: number) => tabIndex !== index,
    );

    if (defaultTab === index) {
      changeConfigValue("default", 0);
    }

    changeTranslation(
      getNewTranslation(
        i18n,
        language,
        deleteTabTranslation(tabLabelsTranslation, index),
      ),
    );

    updateChildren(elementModel, newTabs, page!, "content");
  };

  const handleTabButtonClick = (
    type: "edit" | "delete",
    params: TabConfigProps | number,
  ) => {
    switch (type) {
      case "edit":
        setTabDetails(params as TabConfigProps);
        break;
      case "delete":
        deleteTab(params as number);
        break;
      default:
        break;
    }
  };

  return (
    <TabsContentProvider
      value={{
        tabDetails,
        page,
        setTabDetails,
        updateChildren,
        handleDialogClose,
        handleTabButtonClick,
        deleteTab,
        createElement,
      }}
    >
      <Section
        title={tabsTitle}
        classes={{ root: "editor-tabs-section-tabs" }}
        headerAction={
          <Box display="flex" alignItems="center">
            <IconButton
              icon="add"
              onClick={handleAddTab}
              color="primary"
              tooltip={addTabTooltip}
              data-testid={"add-tab-button"}
            />
          </Box>
        }
      >
        <TabsContent />
      </Section>
      <TabDialog />
    </TabsContentProvider>
  );
});
