import * as t from "io-ts";
import {
  IElement,
  UntransformedConfig,
  arrayChild,
  customExpression,
} from "core/types";

export const DefaultModalDialogConfig = t.partial({
  openButtonDisabled: customExpression(t.boolean),
});

export type DefaultModalDialogConfig = t.TypeOf<
  typeof DefaultModalDialogConfig
>;

export const DefaultModalDialogChildren = t.type({
  content: arrayChild("*", { positioned: true }),
});

export type DefaultModalDialogChildren = t.TypeOf<
  typeof DefaultModalDialogChildren
>;

export const DefaultModalDialogTranslationKeys = [
  "modalTitle",
  "openButtonLabel",
] as const;

export type DefaultModalDialogTranslationKeys =
  (typeof DefaultModalDialogTranslationKeys)[number];

export type UntransformedDefaultModalDialogConfig =
  UntransformedConfig<DefaultModalDialogConfig>;
export type DefaultModalDialog = IElement<
  DefaultModalDialogConfig,
  DefaultModalDialogChildren,
  DefaultModalDialogTranslationKeys
>;
