import { createUseTranslation } from "core/session/translation";

export const editorTranslation = {
  en: {
    modeTitle: "Mode",
    donutLabel: "Donut",
    labelLabel: "Label",
    labelShowLabel: "Show Label",
    legendShowLabel: "Show Legend",
    pieLabel: "Pie",
    valueLabel: "Value",
    viewLabel: "Source Query",
    titleLabel: "Title",
    sortTitle: "Sort",
    ascendantTooltip: "Sort Ascending",
    descendantTooltip: "Sort Descending",
  },
};

export const useChartEditorTranslation =
  createUseTranslation(editorTranslation);
