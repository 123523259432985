import { memo, useMemo, useRef } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { BackgroundVariant, ReactFlowProvider } from "reactflow";
import "reactflow/dist/style.css";

import { MuiIcon } from "elementTypes/common/MuiIcon";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import { useInternalModel } from "queries/admin";
import { useAdminContext } from "staticPages/admin/context";
import { useDebouncedState } from "utils/hooks";
import { LayoutOption } from "../modelBuilder/component";
import { DatabaseListPanel } from "../modelBuilder/components/DatabaseListPanel";
import { ToggleLayoutPanel } from "../modelBuilder/components/ToggleLayoutPanel";
import { DatabaseProvider } from "../modelBuilder/context/database";
import { ErdChart } from "../modelBuilder/erd";
import { DataModel } from "../modelBuilder/erd/types";
import { modelToReactFlowElements } from "../modelBuilder/erd/utils";
import { useDatabaseTranslation } from "../modelBuilder/translation";
import { SaveAsImageButton } from "../modelBuilder/workflow/components/SaveAsImageButton.tsx";

export const CypexInternal = memo(() => {
  const { internalPageTitle } = useDatabaseTranslation();

  return (
    <>
      <Helmet>
        <title>{internalPageTitle}</title>
      </Helmet>
      <ReactFlowProvider>
        <DatabaseProvider isInternal>
          <Component />
        </DatabaseProvider>
      </ReactFlowProvider>
    </>
  );
});

const Component = memo(() => {
  const { data, isFetching, isLoading, refetch } = useInternalModel();

  const elementToImageRef = useRef<null | HTMLDivElement>(null);

  const internalDBModelData = useMemo(
    () =>
      (data ?? {
        tables: [],
        relations: [],
        constraints: [],
      }) as DataModel,
    [data],
  );

  const { internalTitle, refreshModelTooltip } = useDatabaseTranslation();
  const { tableViewDirection, setTableViewDirection } = useAdminContext();

  const elements = useMemo(
    () => internalDBModelData && modelToReactFlowElements(internalDBModelData),
    [internalDBModelData],
  ) ?? { nodes: [], edges: [] };

  const [cachedDirection, handleCachedDirection] = useDebouncedState(
    tableViewDirection,
    setTableViewDirection,
  );

  const handleLayoutChange = (_event: any, nextValue: LayoutOption) => {
    if (nextValue) {
      handleCachedDirection(nextValue);
    }
  };

  const handleModelRefresh = () => refetch();

  return (
    <Box
      width="100%"
      minHeight="100%"
      height="100%"
      display="grid"
      gap={1}
      gridTemplateColumns="3fr 1fr"
      gridTemplateRows="min-content 1fr"
      p={0.5}
    >
      <Box gridColumn="1 / span 2">
        <Card variant="outlined">
          <Box display="flex" alignItems="center" p={1} gap={1}>
            <Typography variant="h5">{internalTitle}</Typography>
            <Stack
              spacing={1}
              flex={1}
              direction="row"
              justifyContent="space-between"
              width={"100%"}
            >
              <Stack direction={"row"} gap={1}>
                <ToggleLayoutPanel
                  onChange={handleLayoutChange}
                  value={cachedDirection}
                />
                <SaveAsImageButton
                  fileName={`cypex_internal_db`}
                  elementToImageRef={elementToImageRef}
                />
              </Stack>
              <Stack spacing={1} direction="row">
                <ToggleButton
                  tooltip={refreshModelTooltip}
                  size="small"
                  onChange={handleModelRefresh}
                  value="refresh"
                  isFetching={isFetching}
                >
                  <MuiIcon icon="autorenew" fontSize="large" />
                </ToggleButton>
              </Stack>
            </Stack>
          </Box>
        </Card>
      </Box>
      <Box gridColumn="1 / 1">
        <ErdChart
          directionValue={cachedDirection}
          sourceContent={elements}
          innerRef={elementToImageRef}
          bgProps={{
            variant: BackgroundVariant.Cross,
          }}
        />
      </Box>
      <DatabaseListPanel
        loading={{
          model: isLoading,
        }}
        entities={internalDBModelData}
        isInternal
      />
    </Box>
  );
});
