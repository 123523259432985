import * as t from "io-ts";
import { customExpression } from "core";
import { UntransformedActionButtonConfig } from "elementTypes/default_action_button/types";
import { UntransformedCallButtonConfig } from "elementTypes/default_call_button/types";
import { UntransformedFormConfig } from "elementTypes/default_form/types";

export enum ActionType {
  reload_table = "reload_table",
  save_form = "save_form",
  refresh_chart = "refresh_chart",
  toggle_modal = "toggle_modal",
  reset_input = "reset_input",
  show_notification = "show_notification",
  navigate = "navigate",
  go_back = "go_back",
  reload_form = "reload_form",
  refresh_display_data = "refresh_display_data",
  refresh_geojson_field = "refresh_geojson_field",
}

export enum NotificationVariant {
  success = "success",
  error = "error",
  default = "default",
  warning = "warning",
  info = "info",
}

const notification = t.intersection([
  t.type({
    message: t.string,
  }),
  t.partial({
    variant: t.string,
    persist: t.boolean,
  }),
]);

export const reloadTable = t.intersection([
  t.type({
    type: t.literal(ActionType.reload_table),
    elementId: t.string,
  }),
  t.partial({
    notification,
  }),
]);

export const reloadForm = t.intersection([
  t.type({
    type: t.literal(ActionType.reload_form),
    elementId: t.string,
  }),
  t.partial({
    notification,
  }),
]);

const saveForm = t.intersection([
  t.type({
    type: t.literal(ActionType.save_form),
    elementId: t.string,
  }),
  t.partial({
    notification,
  }),
]);

const refreshChart = t.intersection([
  t.type({
    type: t.literal(ActionType.refresh_chart),
    elementId: t.string,
  }),
  t.partial({
    notification,
  }),
]);

const refreshDisplayData = t.intersection([
  t.type({
    type: t.literal(ActionType.refresh_display_data),
    elementId: t.string,
  }),
  t.partial({
    notification,
  }),
]);

const refreshGeoJSON = t.intersection([
  t.type({
    type: t.literal(ActionType.refresh_geojson_field),
    elementId: t.string,
  }),
  t.partial({
    notification,
  }),
]);

const toggleModal = t.intersection([
  t.type({
    type: t.literal(ActionType.toggle_modal),
    elementId: t.string,
    notification,
  }),
  t.partial({
    notification,
  }),
]);

const resetInput = t.intersection([
  t.type({
    type: t.literal(ActionType.reset_input),
    elementId: t.string,
  }),
  t.partial({
    notification,
  }),
]);

const showNotification = t.type({
  type: t.literal(ActionType.show_notification),
  notification,
});

const navigate = t.intersection([
  t.type({
    type: t.literal(ActionType.navigate),
    linkTo: t.type({
      pageId: t.string,
      params: t.record(t.string, customExpression(t.unknown)),
    }),
  }),
  t.partial({
    notification,
  }),
]);

const goBack = t.intersection([
  t.type({
    type: t.literal(ActionType.go_back),
  }),
  t.partial({
    notification,
  }),
]);

export const ActionConfigType = t.union([
  reloadTable,
  saveForm,
  reloadForm,
  refreshChart,
  refreshDisplayData,
  refreshGeoJSON,
  toggleModal,
  resetInput,
  showNotification,
  navigate,
  goBack,
]);

export const ActionConfigsType = t.array(ActionConfigType);

export type ActionConfigType = t.TypeOf<typeof ActionConfigType>;
export type ActionConfigsType = t.TypeOf<typeof ActionConfigsType>;

export type ElementsWithActionConfigType =
  | UntransformedActionButtonConfig
  | UntransformedCallButtonConfig
  | UntransformedFormConfig;
