import { Implementation, createElementInterface } from "core";
import { AnyFluxStandardAction } from "core/utils/redux";

type DisplayDataInterface = {
  reload: () => AnyFluxStandardAction;
};

export const DisplayDataInterface =
  createElementInterface<DisplayDataInterface>();

export type DisplayDataInterfaceImplementation = Implementation<
  typeof DisplayDataInterface
>;
