import { ElementGroup, IRawElementType } from "core";

import Component from "./container";
import { JsonInputEditor, defaultElement, editorMetadata } from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import {
  JsonInputConfig,
  JsonInputTranslationKeys,
  jsonInputSelectors,
} from "./types";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_json_input",
  group: ElementGroup.Inputs,
  component: Component,
  editable: true,
  configType: JsonInputConfig,
  selectorTypes: jsonInputSelectors,
  translationKeys: JsonInputTranslationKeys,
  editorComponent: JsonInputEditor,
  defaultElement,
  editorMetadata,
};

export default elementType;
