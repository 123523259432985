// Component.tsx
import { PropsWithChildren, memo } from "react";
import { buildLayoutGetter } from "core/getLayout";
import { layouts } from "layouts";
import { IAppMetadata } from "../../types";

type ComponentProps = {
  appMetadata: IAppMetadata | null | undefined;
} & PropsWithChildren;

const ChildrenComponent = memo<ComponentProps>(({ appMetadata, children }) => {
  const getLayout = buildLayoutGetter(layouts);
  const layoutName = appMetadata?.release.definition.layout.name;
  const RealLayout = getLayout(layoutName);
  if (!RealLayout) {
    return <span>{`Layout "${layoutName}" not supported`}</span>;
  }
  ChildrenComponent.displayName = `${layoutName}ChildrenComponent`;

  return <RealLayout appMetadata={appMetadata!}>{children}</RealLayout>;
});

export default ChildrenComponent;
