import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Number Field",
      description: "Display a number value",
    },
  },
};
