import { createAction, createActionTypeScoper } from "core/utils/redux";

export function buildUncontrolledActions(path: string[]) {
  const scope = path.join(".");
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    VALUE_CHANGE: scopeActionType("VALUE_CHANGE"),
    SET_ERROR: scopeActionType("SET_ERROR"),
    TOUCH_CHANGE: scopeActionType("TOUCH_CHANGE"),
  };

  const actions = {
    changeValue: createAction(types.VALUE_CHANGE, (value: string | null) => ({
      value,
    })),
    setError: createAction(types.SET_ERROR, (error: string | null) => ({
      error,
    })),
    changeTouched: createAction(types.TOUCH_CHANGE, (value: boolean) => ({
      value,
    })),
  };

  return { types, actions };
}
