import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  codeClass: {
    marginTop: theme.spacing(),

    "& .CodeMirror": {
      height: "50px",
    },
  },
  suggestionsDropdown: {
    "& > li[aria-selected=true], & > li:hover": {
      color: `${theme.palette.text.primary} !important`,
      backgroundColor: `${theme.palette.action.selected} !important`,
    },
  },
}));
