import * as t from "io-ts";
import {
  Color,
  IElement,
  UntransformedConfig,
  customExpression,
} from "core/types";

const Alignment = t.keyof({
  start: null,
  center: null,
  end: null,
});

export const TextFieldConfig = t.intersection([
  t.type({
    text: customExpression(t.union([t.string, t.number, t.null])),
  }),
  t.partial({
    verticalAlignment: Alignment,
    horizontalAlignment: Alignment,
    color: t.union([Color, customExpression(Color)]),
    background: t.union([Color, customExpression(Color)]),
    rounded: t.boolean,
    fitContent: t.boolean,
    size: t.keyof({
      small: null,
      medium: null,
    }),
    variant: t.keyof({
      outlined: null,
      filled: null,
    }),
    fontVariant: t.keyof({
      h1: null,
      h2: null,
      h3: null,
      h4: null,
      h5: null,
      h6: null,
      subtitle1: null,
      subtitle2: null,
      body1: null,
      body2: null,
      caption: null,
      button: null,
      overline: null,
    }),
    wrap: t.boolean,
  }),
]);

export type TextFieldConfig = t.TypeOf<typeof TextFieldConfig>;

export type UntransformedTextFieldConfig = UntransformedConfig<TextFieldConfig>;

export type TextField = IElement<TextFieldConfig>;
