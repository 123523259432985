import { memo, useState } from "react";
import {
  Section,
  TranslationEditor,
  useElementEditorContext,
} from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";

import { UntransformedCircularProgressConfig } from "../../types";
import { useCircularProgressEditorTranslation } from "../translation";

export const TranslationComponent = memo(() => {
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);
  const {
    elementModel: { i18n },
    changeTranslation,
  } = useElementEditorContext<UntransformedCircularProgressConfig>();
  const translation = useCircularProgressEditorTranslation();
  const changeLanguage = (l: Language) => setLang(l);

  return (
    <Section
      title={translation.translationLabel}
      wrapped={true}
      cardActions={
        <LanguageSwitch
          language={lang}
          changeLanguage={changeLanguage}
          colorVariant="dark"
          fullWidth={true}
        />
      }
    >
      <TranslationEditor
        translationKey="label"
        i18n={i18n}
        changeTranslation={changeTranslation}
        label={translation.labelLabel}
        language={lang}
      />
    </Section>
  );
});
