import { useContext } from "react";
import { ActionsContext } from "./ActionsContext";

export function useActionsContext() {
  const context = useContext(ActionsContext);

  if (!context) {
    throw new Error(
      "useActionsContext must be used within a ActionsContext.Provider",
    );
  }
  return context;
}
