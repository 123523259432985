import { memo } from "react";

import {
  DisplayComponent,
  LanguageComponent,
  LinkConfigComponent,
  StylingComponent,
} from "./components";

export const InternalLinkButtonEditor = memo(() => (
  <>
    <LinkConfigComponent />
    <LanguageComponent />
    <DisplayComponent />
    <StylingComponent />
  </>
));
