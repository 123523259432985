import { memo } from "react";

import { DataSourceEditor } from "./DataSource";
import { DisplayEditor } from "./Display";
import { LabelEditor } from "./Label";

export const DataDisplayEditor = memo(() => (
  <>
    <LabelEditor />
    <DataSourceEditor />
    <DisplayEditor />
  </>
));
