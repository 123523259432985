import { makeStyles } from "tss-react/mui";

import { MarkdownFieldConfig } from "./types";

type StyleProps = {
  visibility: MarkdownFieldConfig["overflow"];
};

export const useStyles = makeStyles<StyleProps>()((_theme, { visibility }) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "inline-block",
    overflowY: visibility ?? "visible",
  },
}));
