import { createUseTranslation } from "core/session/translation";

export const editorTranslation = {
  en: {
    labelLabel: "Label",
    labelShowLabel: "Show Label",
    legendShowLabel: "Show Legend",
    valueLabel: "Value",
    viewLabel: "Source Query",
    valueColumnHelperText: "Type and press enter to add value",
    titleLabel: "Title",
    isStackedLabel: "Stacked",
    sortTitle: "Sort",
    ascendantTooltip: "Sort Ascending",
    descendantTooltip: "Sort Descending",
    colorLabel: "Background Color",
    borderColorLabel: "Border Color",
    deleteTooltip: "Delete",
    noDataTitle: "No Data Styles",
  },
};

export const useChartEditorTranslation =
  createUseTranslation(editorTranslation);
