import { createAction, createActionTypeScoper } from "core/utils/redux";

export function buildActions(scope: string) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    TRIGGER: scopeActionType("TRIGGER"),
  };

  const actions = {
    trigger: createAction(types.TRIGGER),
  };

  return { types, actions };
}
