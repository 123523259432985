import { memo, useMemo } from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";

import { MuiIcon } from "elementTypes/common/MuiIcon";
import { useAppEdition } from "queries/admin";
import useStyles from "../../styles";
import { menu } from "./menu";
import { MenuItem } from "./MenuItem";
import { itemTitle } from "./utils.tsx";
import { AdminMenuConnectedProps } from ".";

type Props = AdminMenuConnectedProps & {
  open: boolean;
  toggleMenu: () => void;
};

export const AdminMenuComponent = memo<Props>(
  ({ ui, open, toggleMenu, menuItemSelected }) => {
    const {
      classes: { listNavContainer, menu: menuClass, toggleButtonTitle },
    } = useStyles();

    const { data: isEnterprise } = useAppEdition();

    const appMenu = menu.map((item) =>
      !isEnterprise && item?.isEnterprise ? null : (
        <MenuItem
          key={item.name}
          {...item}
          title={item.title}
          menuOpen={open}
          menuItemSelected={menuItemSelected}
        />
      ),
    );

    const listIcon = useMemo(
      () => (
        <ListItemIcon>
          <MuiIcon
            icon={open ? "arrow_back_ios" : "arrow_forward_ios"}
            fontSize="medium"
          />
        </ListItemIcon>
      ),
      [open],
    );

    const toggleBtn = (
      <ListItem button={true} onClick={toggleMenu}>
        {listIcon}
        {itemTitle(open ? "Collapse Menu" : "", toggleButtonTitle)}
      </ListItem>
    );

    return (
      <Paper className={menuClass} elevation={0} square>
        <div className={listNavContainer}>
          <List component="nav">{appMenu}</List>
          {ui && (
            <div>
              <Divider />
              {open ? (
                toggleBtn
              ) : (
                <Tooltip title="Open Menu" placement="right">
                  {toggleBtn}
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </Paper>
    );
  },
);
