import { ChangeEvent, memo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import isEmpty from "lodash/isEmpty";
import { ConnectedReduxModuleProps } from "core";

import IconButton, { Placement } from "elementTypes/common/IconButton";
import { IconNameType } from "elementTypes/common/MuiIcon";
import {
  EndInputAdornment,
  StartInputAdornment,
} from "../common/InputAdornment";

import { ReduxModule } from "./reduxModule";
import { TextInput } from "./types";

const placement: Record<string, Placement> = {
  end: "right",
  start: "left",
  top: "top",
  bottom: "bottom",
};

const DefaultTextInput = memo<
  ConnectedReduxModuleProps<ReduxModule, TextInput>
>(
  ({
    value,
    element,
    errors,
    disabled,
    required,
    changeValue,
    changeTouched,
  }) => {
    const {
      config: {
        startAdornment,
        startAdornmentIcon,
        endAdornment,
        endAdornmentIcon,
        multiline,
        regexPattern,
        tooltipPlacement,
      },
    } = element;

    const hasStartAdornment = startAdornment || startAdornmentIcon;
    const getStartAdornment = () => (
      <StartInputAdornment
        icon={startAdornmentIcon as IconNameType}
        text={startAdornment}
      />
    );

    const hasEndAdornment = endAdornment || endAdornmentIcon;
    const getEndAdornment = () => (
      <EndInputAdornment
        icon={endAdornmentIcon as IconNameType}
        text={endAdornment}
      />
    );

    const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
      changeValue(e.target.value ? e.target.value : null);
    };

    const handleTouchedChange = () => {
      changeTouched(true);
    };

    const helperTextTooltip = (
      <IconButton
        icon="help_outline"
        size="small"
        tooltip={element.i18n.helperText}
        placement={placement[tooltipPlacement ?? "end"]}
      />
    );

    const inputComponent = (
      <TextField
        data-testid="default_text_input"
        label={element.i18n.label}
        fullWidth={true}
        value={value || ""}
        onChange={handleValueChange}
        disabled={disabled}
        onBlur={handleTouchedChange}
        error={Boolean(errors)}
        helperText={!isEmpty(errors) && errors}
        multiline={Boolean(multiline)}
        rows={multiline}
        InputProps={{
          startAdornment: hasStartAdornment ? getStartAdornment() : undefined,
          endAdornment: hasEndAdornment ? getEndAdornment() : undefined,
        }}
        inputProps={{
          ...(regexPattern && {
            pattern: regexPattern,
          }),
        }}
        required={required}
      />
    );

    return element.i18n.helperText?.trim() ? (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        {inputComponent}
        {helperTextTooltip}
      </Box>
    ) : (
      inputComponent
    );
  },
);

DefaultTextInput.displayName = "DefaultTextInput";

export default DefaultTextInput;
