import {
  ChangeEvent,
  ComponentType,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  cloneElement,
  forwardRef,
  lazy,
  memo,
} from "react";

import { Popper, Typography } from "@mui/material";
import {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  autocompleteClasses,
} from "@mui/material/Autocomplete";

import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { RefCallBack } from "react-hook-form";

import { withLazyLoading } from "../../../helpers/HOC/LazyLoading";
import { AutocompleteOption, IAutocompleteValue } from "../types";

import { useStyles } from "./styles";

import { MultiAutocomplete, SingleAutocomplete } from "./index";

export const DEFAULT_ITEM_HEIGHT = 36;
const MAX_ITEM_HEIGHT = 48;

type ErrorProps = {
  optionsError?: string;
  errors?: any;
};

type BaseMultiProps = {
  allowSameValue?: boolean;
  isMulti?: boolean;
  withCheckbox?: boolean;
};

type ValueProps = {
  valueObject: AutocompleteOption | AutocompleteOption[] | string | null;
};

type GenericAutocompleteProps = AutocompleteProps<
  AutocompleteOption,
  boolean,
  boolean,
  boolean
>;

type Variant = TextFieldProps["variant"];
type Size = GenericAutocompleteProps["size"];

export type BaseAutocompleteProps = {
  options: AutocompleteOption[];
  name: string;
  label: string;
  onChange: (value: IAutocompleteValue, reason?: string) => void;
  isLoading?: boolean;
  searchInputValue?: string;
  disabled?: boolean;
  isClearable?: boolean;
  autosuggestHighlight?: boolean;
  virtualizedList?: boolean;
  size?: Size;
  variant?: Variant;
  placeholder?: string;
  className?: string;
  defaultItemSize?: number;
  required?: boolean;
  disablePortal?: boolean;
  innerRef?: RefCallBack;
  customRenderOption?: GenericAutocompleteProps["renderOption"];
  changeTouched?: (value: boolean) => void;
  onInputChange?: (value: string | undefined) => void;
  groupBy?: GenericAutocompleteProps["groupBy"];
  renderGroup?: GenericAutocompleteProps["renderGroup"];
  getOptionDisabled?: (opt: AutocompleteOption) => boolean;
  /**
   * can only be used if isMulti is not `true`
   */
  startAdornment?: ReactNode;
};

export type CommonProps = {
  Listbox: ComponentType<HTMLAttributes<HTMLElement>>;
  debugMode: boolean;
  renderInput: (param: AutocompleteRenderInputParams) => ReactNode;
  getOptionLabel: (opt: AutocompleteOption) => string;
  highlightedOption: (opt: AutocompleteOption, input: string) => ReactNode;
} & Pick<
  BaseAutocompleteProps,
  | "options"
  | "onInputChange"
  | "isLoading"
  | "searchInputValue"
  | "disabled"
  | "getOptionDisabled"
  | "isClearable"
  | "autosuggestHighlight"
  | "virtualizedList"
  | "size"
  | "customRenderOption"
  | "defaultItemSize"
  | "groupBy"
  | "renderGroup"
  | "className"
  | "disablePortal"
>;

export type BaseProps = BaseAutocompleteProps &
  ValueProps &
  ErrorProps &
  BaseMultiProps;

const ListboxComponent = withLazyLoading(
  lazy(() => import("./ListboxComponent")),
);

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export const BaseAutocomplete = memo<BaseProps>(
  ({
    label,
    variant,
    name,
    placeholder,
    isMulti,
    isLoading,
    valueObject,
    optionsError,
    errors,
    allowSameValue,
    searchInputValue,
    defaultItemSize = DEFAULT_ITEM_HEIGHT,
    startAdornment,
    required,
    isClearable,
    innerRef,
    onInputChange,
    changeTouched,
    customRenderOption,
    groupBy,
    renderGroup,
    getOptionDisabled,
    ...rest
  }) => {
    const {
      classes: { inputEndAdornment },
    } = useStyles();
    const itemSize = rest.withCheckbox ? MAX_ITEM_HEIGHT : defaultItemSize;

    const Listbox = forwardRef<HTMLDivElement>((props, ref) => (
      <div ref={ref}>
        <ListboxComponent itemSize={itemSize} {...props} />
      </div>
    )) as ComponentType<HTMLAttributes<HTMLElement>>;

    const renderInput = (params: AutocompleteRenderInputParams) => {
      const handleInputChange = ({
        target: { value: searchValue },
      }: ChangeEvent<HTMLInputElement>) => onInputChange?.(searchValue ?? "");

      const handleTouchedChange = () => changeTouched?.(true);

      const helperText = (
        <>
          {optionsError}
          {errors && (
            <>
              {optionsError && <br />}
              {errors}
            </>
          )}
        </>
      );

      return (
        <TextField
          {...params}
          label={label}
          fullWidth={true}
          name={name}
          placeholder={placeholder}
          variant={variant}
          size="small"
          error={Boolean(optionsError || errors)}
          helperText={optionsError || errors ? helperText : null}
          required={required}
          ref={innerRef}
          onChange={handleInputChange}
          onBlur={handleTouchedChange}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {startAdornment}
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: (
              <>
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {params?.InputProps?.endAdornment &&
                  !isLoading &&
                  cloneElement(params.InputProps.endAdornment as ReactElement, {
                    className: `${inputEndAdornment} ${
                      (params?.InputProps?.endAdornment as ReactElement)?.props
                        ?.className
                    }`,
                  })}
              </>
            ),
          }}
        />
      );
    };

    const debugMode = import.meta.env.DEV;

    const getOptionLabel = (option: AutocompleteOption) => String(option.label);

    const highlightedOption = (
      option: AutocompleteOption,
      inputValue: string,
    ) => {
      const matches = match(option.label, inputValue);
      const parts = parse(option.label, matches);

      return (
        <>
          {parts.map(({ highlight, text }, index) => (
            <Typography
              key={`${index}-${text}`}
              component="span"
              variant={highlight ? "subtitle2" : "body2"}
              {...(highlight && {
                color: "primary",
                fontWeight: 800,
              })}
            >
              {text}
            </Typography>
          ))}
        </>
      );
    };

    const selectComponent: JSX.Element = isMulti ? (
      <MultiAutocomplete
        {...rest}
        isLoading={isLoading}
        Listbox={Listbox}
        allowSameValue={allowSameValue}
        value={valueObject as AutocompleteOption[]}
        renderInput={renderInput}
        isClearable={isClearable}
        debugMode={debugMode}
        getOptionLabel={getOptionLabel}
        highlightedOption={highlightedOption}
        customRenderOption={customRenderOption}
        getOptionDisabled={getOptionDisabled}
        {...(onInputChange && {
          searchInputValue,
          onInputChange,
        })}
        {...(rest.virtualizedList && {
          disableListWrap: true,
          PopperComponent: StyledPopper,
        })}
      />
    ) : (
      <SingleAutocomplete
        {...rest}
        isLoading={isLoading}
        Listbox={Listbox}
        value={valueObject as AutocompleteOption}
        debugMode={debugMode}
        isClearable={isClearable}
        renderInput={renderInput}
        getOptionLabel={getOptionLabel}
        highlightedOption={highlightedOption}
        customRenderOption={customRenderOption}
        groupBy={groupBy}
        renderGroup={renderGroup}
        getOptionDisabled={getOptionDisabled}
        {...(onInputChange && {
          searchInputValue,
          onInputChange,
        })}
      />
    );
    return selectComponent;
  },
);

BaseAutocomplete.displayName = "BaseAutocomplete";
