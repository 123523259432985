import { selectorScoper } from "core/utils/redux";

import { DataDisplay } from "../types";

import { IState } from "./types";

export function buildSelectors(path: string[], element: DataDisplay) {
  const scopeSelector = selectorScoper<IState>(path);

  const loading = (state: any) => scopeSelector(state).loading;
  const data = (state: any) => scopeSelector(state).data;
  const error = (state: any) => scopeSelector(state).error;

  const {
    format,
    color: colorConfig,
    identifier: identifierConfig,
  } = element.config;
  const formattedData = (state: any) =>
    data(state) && format ? format(state) : undefined;
  const color = (state: any) =>
    !loading(state) ? colorConfig?.(state) : undefined;

  const identifier = (state: any) => identifierConfig?.(state);

  const responseFormat = (state: any) =>
    typeof element.config.dataSource?.responseFormat === "function"
      ? element.config.dataSource?.responseFormat?.(state)
      : element.config.dataSource?.responseFormat ?? "json";

  return {
    loading,
    data,
    error,
    formattedData,
    color,
    identifier,
    responseFormat,
  };
}
