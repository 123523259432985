import { createTypeSafeContext } from "utils/createTypeSafeContext";

import { ITableToolsContext } from "./types";

const { Provider, Consumer, useTypeSafeContext } =
  createTypeSafeContext<ITableToolsContext>();

// eslint-disable-next-line react-refresh/only-export-components
export const useTableToolsContext = useTypeSafeContext;
export const TableToolsProvider = Provider;
export const TableToolsConsumer = Consumer;
