import { Theme } from "@mui/material";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((_theme: Theme) => ({
  root: {
    justifyContent: "space-around",
  },
  labelClass: {
    margin: 0,
  },
}));
