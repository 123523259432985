export type Colors =
  | "inherit"
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";

const supportedColors = [
  "inherit",
  "default",
  "primary",
  "secondary",
  "error",
  "info",
  "success",
  "warning",
];

export const isDefaultSupportedColor = (color?: string) =>
  supportedColors.includes(color ?? "");

export const iconColorFromProps = (color?: string) =>
  isDefaultSupportedColor(color) ?? "primary";
