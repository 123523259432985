import { TypographyProps } from "@mui/material/Typography";
import * as t from "io-ts";
import { IElement } from "core/types";
import { customExpression } from "core/types/customExpression";

export const CustomTextFieldConfig = t.intersection([
  t.type({
    text: customExpression(t.union([t.string, t.null])),
  }),
  t.partial({
    style: t.UnknownRecord,
  }),
]);

export type CustomTextFieldConfig = t.TypeOf<typeof CustomTextFieldConfig> &
  t.PartialType<TypographyProps>;

export type CustomTextField = IElement<CustomTextFieldConfig>;
