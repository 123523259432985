import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  IElementModel,
  IObjectViewField,
  TCssGridConfig,
  TElementModelWithPosition,
} from "core";

import { ViewFieldToElement, viewFieldToElementType } from "core/editor";

import { actions as editorActions } from "core/editor/reduxModule";
import { UpdateChildrenParams } from "elementTypes/default_table/editor/component";

export const useElement = () => {
  const dispatch = useDispatch();

  const updateChildren = useCallback(
    (...params: UpdateChildrenParams) => {
      dispatch(editorActions.updateElementChildren(...params));
    },
    [dispatch],
  );

  const createElement = useCallback(
    (...params: Parameters<typeof editorActions.createElement>) => {
      dispatch(editorActions.createElement(...params));
    },
    [dispatch],
  );

  const selectElement = useCallback(
    (...params: Parameters<typeof editorActions.selectElement>) => {
      dispatch(editorActions.selectElement(...params));
    },
    [dispatch],
  );

  const updateCopiedElements = useCallback(
    (...params: Parameters<typeof editorActions.updateCopiedElements>) =>
      dispatch(editorActions.updateCopiedElements(...params)),
    [dispatch],
  );

  const deleteElement = useCallback(
    (...params: Parameters<typeof editorActions.deleteElement>) => {
      dispatch(editorActions.deleteElement(...params));
    },
    [dispatch],
  );

  const buildElementPosition = useCallback(
    (
      index = 0,
      type: "row" | "column",
      customWidth?: number,
      customHeight?: number,
    ): TCssGridConfig => {
      return type === "row"
        ? {
            column: 1,
            row: index + 1,
            width: customWidth ?? 1,
            height: customHeight ?? 1,
          }
        : {
            column: index + 1,
            row: 1,
            width: customWidth ?? 1,
            height: customHeight ?? 1,
          };
    },
    [],
  );

  const getElementTypeFromField = useCallback(
    (
      field: IObjectViewField,
      elementModel: IElementModel | TElementModelWithPosition,
      parentType: "default_form" | "default_table",
      type: "input" | "field",
      position: TCssGridConfig,
    ): ViewFieldToElement =>
      viewFieldToElementType[field.generalType.type][type]({
        field,
        parentElement: elementModel,
        parentType,
        position,
      }),
    [],
  );

  const updateElement = useCallback(
    (...params: Parameters<typeof editorActions.updateElements>) => {
      dispatch(editorActions.updateElements(...params));
    },
    [dispatch],
  );

  return {
    createElement,
    updateElement,
    updateChildren,
    updateCopiedElements,
    deleteElement,
    selectElement,
    buildElementPosition,
    getElementTypeFromField,
  };
};
