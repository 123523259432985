import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Image",
      description: "Display an image",
    },
    de: {
      title: "Bild",
      description: "Bild anzeigen",
    },
  },
  defaultSize: {
    width: 2,
    height: 2,
  },
};
