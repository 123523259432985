import { Type } from "./Type";
import { TypeError } from "./TypeError";
import { IAutocompleteKey } from "./types";

export class StringType extends Type {
  public name = "string";

  public validate(value: any): true | TypeError {
    return typeof value === "string" ? true : new TypeError(value, this);
  }

  public getAutocompleteRecord(): Record<string, IAutocompleteKey> {
    const { toString, ...keys } = super.getAutocompleteRecord();
    return keys;
  }
}
