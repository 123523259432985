import { types } from "core/runtime-typing";
import { ExtractActions } from "core/utils/redux";

import { IFilterGroup, filterGroupType } from "../toolsPanel";
import { TableMetadata } from "../types";

import { buildActions } from "./actions";
import { buildSelectors } from "./selectors";

export interface IOrder {
  fieldName: string;
  asc: boolean;
  hidden?: boolean;
}

/**
 * TODO:
 * We can make IOrder be an io-ts type and have a io-ts-to-selector-types helper function, to avoid duplication
 */
export const orderType = types.interface({
  fieldName: types.string(),
  asc: types.boolean(),
  hidden: types.nullable(types.boolean()),
});

export interface IFetchParams {
  offset: number;
  limit: number | null;
  order: IOrder[] | null;
  filter: IFilterGroup | null;
}

export interface ITableParams extends IFetchParams {
  limit: number;
}

/**
 * TODO:
 * We can make TableParams be an io-ts type and have a io-ts-to-selector-types helper function, to avoid duplication
 */
export const tableParamsType = types.interface({
  offset: types.number(),
  limit: types.nullable(types.number()),
  // TODO check why this must be optional AND nullable
  order: types.optional(types.nullable(types.array(orderType))),
  filter: types.nullable(filterGroupType),
});

export interface ISelectedRow {
  identifier: string | number | null;
  row: Record<string, unknown> | null;
}

export interface IState {
  loading: boolean;
  data: any[] | null;
  references: Record<"string", any[] | null> | null;
  error: string | null;
  params: ITableParams | IFetchParams;
  nextFilter: IFilterGroup;
  loadingParams: Partial<ITableParams> | null;
  nextPageAvailable: boolean;
  searchInputValue: string;
  metadata: TableMetadata;
  selected: ISelectedRow;
}

export type IOrderIndex = {
  asc: boolean;
  pos: number;
  hidden: boolean;
};

export type OrderIndexed = Record<string, IOrderIndex>;

type R = ReturnType<typeof buildActions>;
export type Actions = R["actions"];
export type Types = R["types"];

export type ActionTypes = ExtractActions<Actions>;

export type Selectors = ReturnType<typeof buildSelectors>;
