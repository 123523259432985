import { stringify } from "query-string";
import { all, put, takeLatest } from "redux-saga/effects";
import { actions as routerActions } from "core/router/reduxModule";
import history from "core/router/reduxModule/history";
import { parseQueries } from "core/router/reduxModule/utils";

import { RoutePaths } from "staticPages/routes";
import { types } from "./actions";

function* redirectSaga() {
  const { next, ui } = parseQueries(history.location.search);

  const queries = { next, ui };

  yield put(routerActions.push(`${RoutePaths.Login}?${stringify(queries)}`));
}

export function* saga() {
  yield all([takeLatest(types.REDIRECT, redirectSaga)]);
}
