import { memo } from "react";
import { ConnectedReduxModuleProps } from "core";

import Button from "../common/Button";

import { ReduxModule } from "./reduxModule";
import useStyles from "./styles";
import { FormButton } from "./types";

const DefaultFormButton = memo<
  ConnectedReduxModuleProps<ReduxModule, FormButton>
>(({ click, disabled, element }) => {
  const {
    classes: { root },
  } = useStyles();

  return (
    <Button
      onClick={click}
      disabled={disabled}
      label={element.i18n.label}
      className={root}
      {...element.config}
    />
  );
});

DefaultFormButton.displayName = "DefaultFormButton";

export default DefaultFormButton;
