import { ChangeEvent, memo, useCallback, useMemo } from "react";
import { Box, FormControl, FormControlLabel, Switch } from "@mui/material";

import { GeneralTypes } from "core";
import { Section, useObjectViewList } from "core/editor";
import { useSessionContext } from "core/session";
import { getTranslatedText } from "core/utils/element-utils";

import { Autocomplete } from "../../../common/Autocomplete";
import { IAutocompleteValue } from "../../../common/Autocomplete/types";
import { useTableEditorTranslation } from "../../translation";
import { UntransformedTableConfig } from "../../types";
import { filterableObjectViewFields } from "./utils";

type Props = {
  viewName: UntransformedTableConfig["dataSource"]["viewName"];
  simpleFilter: UntransformedTableConfig["simpleFilter"];
  hideSimpleFilter: UntransformedTableConfig["hideSimpleFilter"];
  changeConfigValue: (key: keyof UntransformedTableConfig, value: any) => void;
};

export const SimpleFilter = memo<Props>(
  ({ viewName, simpleFilter = [], hideSimpleFilter, changeConfigValue }) => {
    const translation = useTableEditorTranslation();
    const { language } = useSessionContext();
    const { getViewByName } = useObjectViewList();

    const currentView = useMemo(
      () => getViewByName(viewName),
      [getViewByName, viewName],
    );
    const viewFields = currentView?.fields;

    const usableFields = useMemo(
      () => viewFields?.filter(filterableObjectViewFields),
      [viewFields],
    );

    const handleChange = useCallback(
      (prop: keyof UntransformedTableConfig, newValue: string[]) =>
        changeConfigValue(
          prop,
          usableFields?.reduce(
            (res, field) =>
              newValue.includes(field.name)
                ? [
                    ...res,
                    {
                      name: field.name,
                      type: field.generalType.type,
                      isArray: field.generalType.isArray,
                    },
                  ]
                : res,
            [] as { name: string; type: GeneralTypes; isArray: boolean }[],
          ),
        ),
      [changeConfigValue, usableFields],
    );

    const handleSimpleFilterInputChange = useCallback(
      (value: IAutocompleteValue) => {
        handleChange("simpleFilter", value as string[]);
      },
      [handleChange],
    );

    const options = useMemo(
      () =>
        usableFields?.map((viewField) => ({
          value: viewField.name,
          label: `${getTranslatedText(language, viewField.i18n, "title")} (${
            viewField.name
          })`,
        })) ?? [],
      [usableFields, language],
    );

    const simpleFilterValues = useMemo(
      () => simpleFilter.map((element) => element.name) || [],
      [simpleFilter],
    );

    const handleChangeHidden = (_: ChangeEvent<{}>, checked: boolean) => {
      changeConfigValue("hideSimpleFilter", checked);

      if (checked) {
        changeConfigValue("simpleFilter", undefined);
      }
    };

    return (
      <Section
        title={translation.simpleFilterTitle}
        wrapped={true}
        defaultOpened={false}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(hideSimpleFilter)}
                  onChange={handleChangeHidden}
                />
              }
              label={translation.hideSimpleFilter}
              name="isClearable"
            />
          </FormControl>
          {!hideSimpleFilter && (
            <Autocomplete
              options={options}
              onChange={handleSimpleFilterInputChange}
              value={simpleFilterValues}
              name="simpleFilter"
              label={translation.simpleFilterLabel}
              isLoading={false}
              virtualizedList={true}
              isMulti={true}
              size="small"
              variant="outlined"
            />
          )}
        </Box>
      </Section>
    );
  },
);
