import { memo } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { actions as routerActions } from "core/router/reduxModule";
import Button from "elementTypes/common/Button";
import { RoutePaths } from "staticPages/routes";
import { useSnackbar } from "utils/hooks/useSnackbar";

import { useUpdateConfig } from "../../../../../../../queries/admin";
import { getApiError } from "../../../../../../../queries/utils";
import { SettingsConfig } from "../../../types";
import useStyles from "../styles";

export const Form = memo<{ config: SettingsConfig }>(({ config }) => {
  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();
  const {
    classes: { formPaper, footer, dividerFooter },
  } = useStyles();

  const updateConfig = useUpdateConfig({
    onSuccess: () => {
      showSnackbar("Settings Configuration edited successfully", "success");
      dispatch(routerActions.push(RoutePaths.AdminSettings));
    },
    onError: (error) => {
      const msg = getApiError(error) ?? "Settings Configuration was not edited";
      showSnackbar(msg, "error");
    },
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid },
  } = useForm<SettingsConfig>({
    mode: "onBlur",
    defaultValues: {
      logoPath: config?.logoPath ?? "",
      identifierInputTitle: config?.identifierInputTitle ?? "",
    },
  });

  const submit = (data: SettingsConfig) => {
    const result: SettingsConfig = { ...data };
    if (data.logoPath === "") {
      // delete if empty
      result.logoPath = null;
    }
    if (data.identifierInputTitle === "") {
      // delete if empty
      result.identifierInputTitle = null;
    }
    updateConfig.mutate(result);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(submit)}>
      <Paper variant="outlined" className={formPaper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Logo path"
                  fullWidth={true}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="logoPath"
              control={control}
              defaultValue=""
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Identifier Input Title"
                  fullWidth={true}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="identifierInputTitle"
              control={control}
              defaultValue=""
            />
          </Grid>

          <Grid item xs={12} className={footer}>
            <Divider className={dividerFooter} />
            <Box
              px={1}
              pb={1}
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                color="primary"
                label="Cancel"
                href={RoutePaths.AdminSettings}
              />
              <Button
                color="secondary"
                disabled={isSubmitting || !isValid}
                processing={isSubmitting}
                iconRight="forward"
                type="submit"
                label="Edit"
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
});
