import { Type } from "./Type";
import { TypeError } from "./TypeError";

export class FunctionType extends Type {
  public name: string;

  constructor(
    public readonly returnType: Type,
    description?: string,
  ) {
    super(description);
    this.name = this.generateTypeName();
  }

  private generateTypeName(): string {
    return `function () => ${this.returnType.name}`;
  }

  public validate(value: any): true | TypeError {
    if (typeof value !== "function") {
      return new TypeError(value, this, "TODO: detailed error message");
    }
    return true;
  }
}
