import { ChangeEvent, memo } from "react";
import { Search } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";
import IconButton from "elementTypes/common/IconButton";
import { useViewsTableTranslation } from "../translation.ts";
import { TViewsTableToolbar } from "../types.ts";

export const ViewsTableToolbar = memo<TViewsTableToolbar>(
  ({ searchValue, setSearchValue, isLoading, handleRefresh }) => {
    const { searchLabel, refreshLabel } = useViewsTableTranslation();

    const handleSearch = ({
      target: { value },
    }: ChangeEvent<HTMLInputElement>) => setSearchValue(value);

    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <TextField
          placeholder={searchLabel}
          disabled={isLoading}
          value={searchValue}
          onChange={handleSearch}
          InputProps={{
            startAdornment: <Search />,
          }}
        />
        <IconButton
          icon="refresh"
          tooltip={refreshLabel}
          disabled={isLoading}
          onClick={handleRefresh}
          placement="top"
        />
      </Box>
    );
  },
);
