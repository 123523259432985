import { forwardRef, memo } from "react";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";

import { CellAlignment } from "../default_table/types";

import { useStyles } from "./style";

type ISortLabelProps = {
  active: boolean;
  label: string;
  ableToSort: boolean;
  tooltip: string;
  direction?: "desc" | "asc";
  className?: string;
  align?: CellAlignment;
};

export const SortLabel = memo(
  forwardRef<HTMLButtonElement, ISortLabelProps>(
    (
      { active, className, label, ableToSort, direction, tooltip, align },
      ref,
    ) => {
      const {
        classes: { title },
      } = useStyles({ align });

      return (
        <Tooltip title={tooltip} placement={"bottom-start"} enterDelay={300}>
          <TableSortLabel
            ref={ref}
            disabled={!ableToSort}
            active={active}
            direction={direction || "asc"}
            className={classNames(title, className)}
          >
            {label}
          </TableSortLabel>
        </Tooltip>
      );
    },
  ),
);

SortLabel.displayName = "SortLabel";
