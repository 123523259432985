import { Fragment, useCallback, useMemo, useState } from "react";
import { Box, Collapse, TableCell, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import { IObjectView } from "core";
import { useTranslator } from "core/session/translation";
import IconButton from "elementTypes/common/IconButton";
import { TableRow as CustomTableRow } from "staticPages/admin/common";
import { Table } from "staticPages/admin/common/components/Table";
import {
  isFullyContainedArray,
  isPartiallyContainedArray,
} from "utils/other.ts";
import { TRenderViews } from "../types.ts";
import { ViewsTableRow } from "./ViewsTableRow.tsx";

export const RenderViews = ({
  mappedQueries,
  translatedQueryGroups,
  selectedQueries,
  tableConfig,
  handleSelectQuery,
  handleShowDialog,
}: TRenderViews) => {
  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

  const { translate } = useTranslator();

  const isExpanded = useCallback(
    (id: string) => expandedGroups.includes(id),
    [expandedGroups],
  );

  const handleExpand = useCallback(
    (id: string) => () =>
      setExpandedGroups((prevState) =>
        isExpanded(id) ? prevState.filter((a) => a !== id) : [...prevState, id],
      ),
    [isExpanded],
  );

  const groupQueries = useMemo(
    () =>
      Object.keys(mappedQueries).reduce((acc, id) => {
        if (["noGroup", "emptyGroups"].includes(id)) {
          return acc;
        }

        const queriesInGroup = mappedQueries[id] as IObjectView[];
        const queriesOfGroup = queriesInGroup.map((query) => query.id);

        const isGroupSelected = isFullyContainedArray(
          selectedQueries,
          queriesOfGroup,
        );

        const isGroupPartiallySelected =
          !isGroupSelected &&
          isPartiallyContainedArray(selectedQueries, queriesOfGroup);

        const queriesList = queriesInGroup.map((query) => {
          const isSelected = selectedQueries.includes(query.id);
          const title = translate(query.i18n).title ?? "";

          const showDialog = () => handleShowDialog(query.name);
          return (
            <ViewsTableRow
              key={`group-row-${query.id}`}
              id={query.id}
              title={title}
              isSelected={isSelected}
              handleSelect={handleSelectQuery}
              handleShowDialog={showDialog}
              isSubtable={true}
            />
          );
        });

        const queriesTable = (
          <Fragment key={`wrapper-${id}`}>
            <CustomTableRow rowId={id} key={`custom-table-row-subtable-${id}`}>
              <IconButton
                icon={isExpanded(id) ? "expand_less" : "expand_more"}
                size="small"
                onClick={handleExpand(id)}
              />
              <Checkbox
                checked={isGroupSelected}
                onClick={handleSelectQuery(queriesOfGroup)}
                indeterminate={isGroupPartiallySelected}
              />
              <Typography component="span">
                {translatedQueryGroups[id] ?? ""}
              </Typography>
              <Typography component="span" width="20px" display="flex" />
            </CustomTableRow>
            <TableRow key={id}>
              <TableCell padding="none" colSpan={6}>
                <Collapse in={isExpanded(id)} timeout="auto" unmountOnExit>
                  <Box
                    p={1}
                    pb={2}
                    bgcolor="action.selected"
                    border="1px solid"
                    borderColor="grey.300"
                  >
                    <Table
                      alignment={tableConfig}
                      rows={queriesList}
                      headers={tableConfig}
                      key={`subtable-${id}`}
                    />
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        );

        return [...acc, queriesTable];
      }, [] as JSX.Element[]),
    [
      handleExpand,
      handleSelectQuery,
      handleShowDialog,
      isExpanded,
      mappedQueries,
      selectedQueries,
      tableConfig,
      translate,
      translatedQueryGroups,
    ],
  );

  const noGroupQueries = useMemo(
    () =>
      mappedQueries?.noGroup.map((query) => {
        const isSelected = selectedQueries.includes(query.id);
        const title = translate(query.i18n).title ?? "";

        const showDialog = () => handleShowDialog(query.name);
        return (
          <ViewsTableRow
            key={`noGroup-${query.id}`}
            id={query.id}
            title={title}
            isSelected={isSelected}
            handleSelect={handleSelectQuery}
            handleShowDialog={showDialog}
          />
        );
      }),
    [
      mappedQueries?.noGroup,
      selectedQueries,
      translate,
      handleSelectQuery,
      handleShowDialog,
    ],
  );

  return useMemo(
    () => [...groupQueries, ...noGroupQueries],
    [groupQueries, noGroupQueries],
  );
};
