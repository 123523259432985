import { EChartsReactProps } from "echarts-for-react";

export type IOptionEchartProps = Record<string, any>;

export type EchartsProps<T = string> = EChartsReactProps & {
  chartType: T;
  elementId: string;
  showBackground?: boolean;
  toolbox?: Record<string, Record<string, unknown>>;
  themeName?: string;
  legend?: {
    placement?: "top" | "bottom" | "end" | "start" | "hidden";
    orient?: "vertical" | "horizontal";
  };
  selected?: Record<string, unknown> | null;
  selectValue?: (data: Record<string, unknown> | null) => void;
};

export const EChartsTranslationKeys = ["title"] as const;

export type EChartsTranslationKeys =
  | (typeof EChartsTranslationKeys)[number]
  | `${string}Label`;
