import * as t from "io-ts";
import { IElement, UntransformedConfig, customExpression } from "core/types";

export const JsonFieldConfig = t.intersection([
  t.type({
    value: customExpression(t.any),
  }),
  t.partial({
    collapsed: t.boolean,
    enableClipboard: t.boolean,
  }),
]);

export type JsonFieldConfig = t.TypeOf<typeof JsonFieldConfig>;

export type UntransformedJsonFieldConfig = UntransformedConfig<JsonFieldConfig>;

export type JsonField = IElement<JsonFieldConfig>;
