export const FILE_STORAGE_PREFIX = "/app/storage/file/";

// Limit features in the trial version
export const QUERIES_LIMIT = 10;
export const PAGES_LIMIT = 15;

// These types are fetched via API, "/app/storage/types"
export const fileTypeGroupName = {
  image: "image/*",
  video: "video/*",
  audio: "audio/*",
  text: "text/*",
  document: [
    "application/x-abiword",
    "application/x-freearc",
    "application/vnd.amazon.ebook",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/xhtml+xml",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/pdf",
    "application/json",
    "application/ld+json",
    "application/epub+zip",
  ],
  other: undefined,
} as const;
