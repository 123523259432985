import { IDefaultElement } from "core";

import { UntransformedDateTimeInputConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedDateTimeInputConfig> =
  {
    i18n: {
      en: {
        label: "Label",
      },
    },
    config: {
      showDatePart: true,
      showTimePart: true,
    },
  };
