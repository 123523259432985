import { MouseEvent, lazy, memo, useMemo, useState } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { IApiError } from "core/types";

import { AlertBox } from "elementTypes/common/AlertBox";
import Button from "elementTypes/common/Button";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";

import { XOR } from "utils/boolean";
import { useErrorsTranslation } from "../translation";

export type ErrorType = IApiError | string | null;

type Props = {
  errors: {
    load?: ErrorType;
    save?: ErrorType;
  };
};

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

const getErrorMsg = (error?: ErrorType) =>
  (error as IApiError)?.message ?? String(error);

export const FormErrors = memo<Props>(({ errors }) => {
  const { alertTitle, formErrorsTitle } = useErrorsTranslation();

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const errorsExist = !!Object.values(errors ?? {}).filter(Boolean).length;

  const handleOpen = (ev: MouseEvent<HTMLButtonElement>) =>
    setAnchor(ev.currentTarget);

  const handleClose = () => setAnchor(null);

  const oneError = useMemo(() => XOR(errors.save, errors.load), [errors]);

  const renderErrorsModal = useMemo(
    () =>
      Object.entries(errors).map(
        ([key, error]) =>
          error && (
            <ListItem key={key}>
              <ListItemIcon>
                <MuiIcon icon="error" />
              </ListItemIcon>
              <ListItemText primary={getErrorMsg(error)} />
            </ListItem>
          ),
      ),
    [errors],
  );

  if (!errorsExist) {
    return null;
  }

  return (
    <>
      <AlertBox
        color="error"
        variant="standard"
        boxProps={{
          flexGrow: 1,
          marginX: 1,
        }}
        {...(oneError
          ? {
              message: getErrorMsg(errors.save || errors.load),
              alertTitle,
            }
          : {
              alertTitle: formErrorsTitle,
              action: (
                <Button
                  label="more"
                  onClick={handleOpen}
                  color="inherit"
                  size="small"
                />
              ),
            })}
      />
      <Popover anchorEl={anchor} onClose={handleClose} wrapped={false}>
        {renderErrorsModal}
      </Popover>
    </>
  );
});
