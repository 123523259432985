import { memo } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Slider from "@mui/material/Slider";
import { pathOr } from "ramda";
import { Controller } from "react-hook-form";

import { LayoutDefinition } from "core";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";
import { pxToNumber } from "utils/string";
import { useTranslation } from "../translation";

export const Advanced = memo<{
  layoutDefinition: LayoutDefinition;
  name: string;
}>(({ name, layoutDefinition }) => {
  const value = pathOr(5, ["theme", "shape", name], layoutDefinition);
  const translation = useTranslation();
  const { control } = useHookFormContext();

  return (
    <FormControl fullWidth={true}>
      <FormLabel>{translation.borderRadiusLabel}</FormLabel>
      <Controller
        render={({ field }) => (
          <Slider
            step={1}
            min={0}
            max={32}
            valueLabelDisplay="auto"
            {...field}
          />
        )}
        name={`theme.shape.${name}`}
        control={control}
        defaultValue={pxToNumber(value) ?? ""}
      />
    </FormControl>
  );
});
