import { memo } from "react";
import { Section, useElementEditorContext } from "core/editor";
import { PlacementSelector } from "core/editor/common/PlacementSelector";

import { ColorInputConfig } from "../../types";
import { useColorInputEditorTranslation } from "../translation";

export const StylingComponent = memo(() => {
  const {
    elementModel: {
      config: { placement = "start" },
    },
    changeConfigValue,
  } = useElementEditorContext<ColorInputConfig>();
  const translation = useColorInputEditorTranslation();

  const handleChange = (value: string) => changeConfigValue("placement", value);

  return (
    <Section title={translation.stylingTitle}>
      <PlacementSelector value={placement} onChange={handleChange} />
    </Section>
  );
});
