import {
  CSSProperties,
  ChangeEvent,
  MouseEvent,
  lazy,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import isEqual from "lodash/isEqual";
import { FixedSizeList, areEqual } from "react-window";
import { GeneralTypes, IFixedRow } from "core";
import { Section, useObjectViewList } from "core/editor";
import { useSessionContext } from "core/session";
import IconButton from "elementTypes/common/IconButton";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";

import {
  useOperatorsTranslation,
  useTableEditorTranslation,
} from "../../translation";
import { UntransformedTableConfig } from "../../types";

import { generateFilterFieldConfig } from "./utils";

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

type IFilterField = {
  name: string;
  label: string;
  operators: string[];
  input: {
    type: GeneralTypes;
  };
};

type IRow = IFixedRow<IFilterField>;

type Props = {
  filter: UntransformedTableConfig["filter"];
  viewName: UntransformedTableConfig["dataSource"]["viewName"];
  changeConfigValue: (key: keyof UntransformedTableConfig, value: any) => void;
};

export const AllowedFilter = memo<Props>(
  ({ filter, viewName, changeConfigValue }) => {
    const translation = useTableEditorTranslation();
    const { language } = useSessionContext();
    const { getViewByName } = useObjectViewList();
    const viewFields = useMemo(
      () => getViewByName(viewName)?.fields ?? [],
      [getViewByName, viewName],
    );
    const { fields } = filter ?? { fields: [] };

    const generatedFields = useMemo(
      () =>
        viewFields?.length
          ? generateFilterFieldConfig(viewFields, language)
          : null,
      [viewFields, language],
    );

    const changeFilter = useCallback(
      (prop: "fields", newVal: any) =>
        changeConfigValue("filter", { ...filter, [prop]: newVal }),
      [changeConfigValue, filter],
    );

    useEffect(() => {
      if (!fields?.length) {
        changeFilter("fields", generateFilterFieldConfig(viewFields, language));
      }
    }, [fields, viewFields, language, changeFilter]);

    const allowedField = useCallback(
      (name: string) => fields.find((f) => f.name === name),
      [fields],
    );

    const getDefaultField = useCallback(
      (name: string) => generatedFields?.find((f) => f.name === name),
      [generatedFields],
    );

    const toggleField = useCallback(
      (
        { target: { name } }: ChangeEvent<HTMLInputElement>,
        checked: boolean,
      ) => {
        if (checked) {
          const newField = getDefaultField(name);
          newField && changeFilter("fields", [...fields, newField]);
        } else {
          changeFilter(
            "fields",
            fields.filter((f) => f.name !== name),
          );
        }
      },
      [changeFilter, fields, getDefaultField],
    );

    const changeOperator = useCallback(
      (fieldName: string, operators: string[]) => {
        const nextFields = fields.map((field) =>
          field.name === fieldName
            ? {
                ...field,
                operators,
              }
            : field,
        );
        changeFilter("fields", nextFields);
      },
      [changeFilter, fields],
    );

    const Row = memo<IRow>(({ data, index, style }) => {
      const item = data[index];
      const field = allowedField(item.name);
      const isChecked = Boolean(field);
      return (
        <FilterField
          key={item.name}
          style={style}
          isChecked={isChecked}
          {...item}
          toggleField={toggleField}
          onOperatorChange={changeOperator}
          activeOperators={field?.operators ?? []}
          tooltip={translation.operatorsTooltip}
          disabled={fields.length === 1 && isChecked}
        />
      );
    }, areEqual);

    const itemSize = 48;

    return (
      <Section title={translation.allowedFilterTitle} defaultOpened={false}>
        <FixedSizeList
          height={itemSize * Math.min(generatedFields?.length ?? 1, 5)}
          itemCount={generatedFields?.length ?? 1}
          itemSize={itemSize}
          width="100%"
          itemData={generatedFields ?? []}
        >
          {Row}
        </FixedSizeList>
      </Section>
    );
  },
);

const FilterField = memo<
  IFilterField & {
    isChecked: boolean;
    style: CSSProperties;
    activeOperators: string[];
    tooltip: string;
    disabled: boolean;
    toggleField: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    onOperatorChange: (name: string, operators: string[]) => void;
  }
>(
  ({
    style,
    isChecked,
    name,
    toggleField,
    label,
    input,
    operators,
    onOperatorChange,
    activeOperators,
    tooltip,
    disabled,
  }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [updatedOperators, setUpdatedOperators] =
      useState<string[]>(activeOperators);

    const handleClose = () => {
      if (!isEqual(activeOperators, updatedOperators)) {
        onOperatorChange(name, updatedOperators);
      }

      setAnchorEl(null);
    };

    const openMenu = (event: MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(event.currentTarget);

    const toggleOperator = (operator: string, checked: boolean) => {
      setUpdatedOperators(
        checked
          ? [...updatedOperators, operator]
          : updatedOperators.filter((uOperator) => uOperator !== operator),
      );
    };

    const isActiveOperator = (operatorName: string) =>
      updatedOperators.some((o) => o === operatorName);

    return (
      <ListItem dense style={style} divider selected={Boolean(anchorEl)}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={isChecked}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": name }}
            onChange={toggleField}
            name={name}
            disabled={disabled}
          />
        </ListItemIcon>
        <Tooltip title={name} placement="right-end">
          <ListItemText
            id={name}
            primary={label}
            secondary={input.type}
            secondaryTypographyProps={{
              variant: "caption",
              color: "primary",
            }}
          />
        </Tooltip>
        <ListItemIcon>
          <IconButton
            icon="menu_open"
            edge="end"
            aria-label="operators"
            onClick={openMenu}
            disabled={!isChecked}
            tooltip={tooltip}
          />
          <Popover
            onClose={handleClose}
            actionsAlign="center"
            anchorEl={anchorEl}
            cardProps={{ p: "0 !important" }}
          >
            <Operators
              operators={operators}
              toggleOperator={toggleOperator}
              isActiveOperator={isActiveOperator}
            />
          </Popover>
        </ListItemIcon>
      </ListItem>
    );
  },
);

const Operators = memo<{
  operators: IFilterField["operators"];
  toggleOperator: (operator: string, checked: boolean) => void;
  isActiveOperator: (name: string) => boolean;
}>(({ operators, toggleOperator, isActiveOperator }) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) =>
    toggleOperator(e.target.name, checked);

  const items = operators.map((operator) => (
    <Operator
      key={operator}
      name={operator}
      isChecked={isActiveOperator(operator)}
      onChange={onChange}
    />
  ));
  return <>{items}</>;
});

const Operator = memo<{
  name: string;
  isChecked: boolean;
  onChange: CheckboxProps["onChange"];
}>(({ name, isChecked, onChange }) => {
  const translation = useOperatorsTranslation();

  return (
    <ListItem dense divider>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={isChecked}
          tabIndex={-1}
          disableRipple
          inputProps={{ "aria-labelledby": name }}
          onChange={onChange}
          name={name}
        />
      </ListItemIcon>
      <Tooltip
        title={translation[`${name}Description`] ?? name}
        placement="right-end"
      >
        <ListItemText id={name} primary={translation[`${name}Label`] ?? name} />
      </Tooltip>
    </ListItem>
  );
});
