import { IStaticRouteConfig } from "core";

import { WorkflowPageComponent } from "./component";

export const route: IStaticRouteConfig = {
  Component: WorkflowPageComponent,
  auth: true,
  isAdmin: true,
  routes: {},
};
