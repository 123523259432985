import { memo } from "react";
import { isCustomExpression } from "core";
import { Section } from "core/editor";
import { BackgroundSelector, ColorSelector } from "core/editor/common";

import { BG_COLORS } from "core/editor/common/BackgroundSelector/consts";
import { UntransformedTextFieldConfig } from "../../types";
import { useDefaultTextEditorTranslation } from "../translation";

type Props = {
  config: UntransformedTextFieldConfig;
  onChange: (
    type: keyof UntransformedTextFieldConfig,
  ) => (value: string) => void;
};

export const FieldColors = memo<Props>(({ config, onChange }) => {
  const { colorsTitle } = useDefaultTextEditorTranslation();

  const handleColorChange =
    (type: "color" | "background") => (nextValue: string) => {
      let newValue = "";

      if (isCustomExpression(nextValue) || BG_COLORS.includes(nextValue)) {
        newValue = nextValue;
      } else if (type === "color") {
        newValue = "default";
      } else {
        newValue = "transparent";
      }

      onChange(type)(newValue);
    };

  return (
    <Section title={colorsTitle} wrapped={true}>
      <ColorSelector config={config} onChange={handleColorChange("color")} />
      <BackgroundSelector
        config={config}
        onChange={handleColorChange("background")}
      />
    </Section>
  );
});
