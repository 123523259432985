import { ActionCreator, AnyAction } from "redux";
import { ExtendableError } from "ts-error";

import { ArgumentTypes } from "./types";

export function createActionTypeScoper(PREFIX: string) {
  // Set to help detect duplicated action types.
  const usedTypes: Set<string> = new Set();

  return function createActionType(TYPE: string) {
    if (usedTypes.has(TYPE)) {
      throw new Error(`Duplicated action type "${TYPE}" for scope "${PREFIX}"`);
    }
    usedTypes.add(TYPE);
    return `${PREFIX}/${TYPE}`;
  };
}

export type ExtractActions<T> = {
  [P in keyof T]: T[P] extends ActionCreator<infer A>
    ? A
    : AnyFluxStandardAction;
};

// ActionFunction extends ActionCreator<infer A> ? A : AnyFluxStandardAction;

/*
 * TODO: use this instead of custom type per reducer (which uses the ExtractAction type)
 * BLOCKED BY regression of typescript 3.8 (https://github.com/microsoft/TypeScript/issues/37364)
 * USAGE Example:
 * type ActionTypes = ExtractActionTypes<Actions>
 * ...
 * (state, action: ActionTypes["load"])
 */

// export type ExtractActionTypes<
//   Actions extends Record<string, (...args: any[]) => AnyFluxStandardAction>
// > = {
//   [K in keyof Actions]: ReturnType<Actions[K]>;
// };

export type ICreator<Args extends any[]> = (...args: Args) => any;

export interface IFluxStandardAction<P, M> extends AnyAction {
  payload: P;
  meta: M;
}

/* TODO:
 * Improve typing. The actionCreator returned does not re-enforce the arguments declared in the payload
 * and meta creators.
 * Also, when the metaCreator arguments are not provided the IFluxStandardAction type declares the
 * `meta` prop as any (the same goes for payloadCreator). It should use a conditional type to handle the
 * cases where metaCreator is undefined.
 * Something like:
 * `IFluxStandardAction<PC == undefined ? object : ReturnType<PC>, MC == undefined ? object : ReturnType<MC>>`
 */
export function createAction<
  Args extends any[],
  PC extends ICreator<Args>,
  MC extends ICreator<Args>,
>(type: string, payloadCreator?: PC, metaCreator?: MC) {
  return function actionCreator(
    ...args: ArgumentTypes<PC>
  ): IFluxStandardAction<ReturnType<PC>, ReturnType<MC>> {
    const action = { type } as any;
    action.payload = payloadCreator ? payloadCreator(...args) : {};
    action.meta = metaCreator ? metaCreator(...args) : {};
    return action;
  };
}

// Like redux's Reducer type but without undefined as an option for state
export type NonInitialReducer<
  S,
  A extends AnyFluxStandardAction = AnyFluxStandardAction,
> = (state: S, action: A) => S;

export interface IHandlers<S> {
  [type: string]: NonInitialReducer<S>;
}

export type AnyFluxStandardAction = IFluxStandardAction<any, any>;

type ActionMapping = Record<string, AnyFluxStandardAction>;

export function handleActions<S, Actions extends ActionMapping = ActionMapping>(
  initialState: S,
  handlers: IHandlers<S>,
  {
    preReducer,
    postReducer,
  }: {
    preReducer?: NonInitialReducer<S>;
    postReducer?: NonInitialReducer<S>;
  } = {},
) {
  return function reducer<Action extends Actions[string]>(
    state = initialState,
    action: Action,
  ) {
    let nextState: S = preReducer ? preReducer(state, action) : state;
    if (action.type in handlers) {
      /* TODO:
      Change signature to: handleActions<S, A extends IActions = IActions>
       * Improve typing. The type of action in the following line should be inferred from the generic type A,
       * which is the map of actions creators. With that information, the action handler's `action` argument
       * should be strong typed to detect errors with action payloads and meta.
       */
      nextState = handlers[action.type](nextState!, action);
    }
    return postReducer ? postReducer(nextState, action) : nextState;
  };
}

export class UndefinedScopeStateError extends ExtendableError {
  constructor(path: string[]) {
    super(`State in ${path.join(".")} is undefined`);
  }
}

export function selectorScoper<State>(path: string[]) {
  return function scopedSelector(state: any): State {
    const scoped = path.reduce((s, k) => s[k], state);
    if (scoped === undefined) {
      throw new UndefinedScopeStateError(path);
    }
    return scoped as State;
  };
}

export interface IAsyncActionState {
  inProgress: boolean;
  error: string | null;
}

export const ASYNC_ACTION_INITIAL_STATE: IAsyncActionState = {
  inProgress: false,
  error: null,
};
