import { ChangeEvent, memo, useCallback } from "react";
import { TextField } from "@mui/material";
import { Section, useElementEditorContext } from "core/editor";
import { DEFAULT_CONFIG } from "elementTypes/default_geojson_field/constants";

import { UntransformedGeoJSONFieldConfig } from "../../types";
import { useDefaultGeoJSONFieldEditorTranslation } from "../translation";

export const Coordinates = memo(() => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedGeoJSONFieldConfig>();

  const translations = useDefaultGeoJSONFieldEditorTranslation();

  const handleChange = useCallback(
    (configName: "latitude" | "longitude" | "zoom") =>
      (e: ChangeEvent<HTMLInputElement>) =>
        changeConfigValue(
          configName,
          configName === "zoom"
            ? parseInt(e.target.value, 10) ?? 0
            : parseFloat(e.target.value) ?? 0,
        ),
    [changeConfigValue],
  );

  return (
    <Section title={translations.coordinatesTitle} wrapped={true} gap={1}>
      <TextField
        fullWidth
        value={config.latitude ?? DEFAULT_CONFIG.latitude}
        onChange={handleChange("latitude")}
        label={translations.latitude}
        type="number"
      />
      <TextField
        fullWidth
        value={config.longitude ?? DEFAULT_CONFIG.longitude}
        onChange={handleChange("longitude")}
        label={translations.longitude}
        type="number"
      />
      <TextField
        fullWidth
        value={config.zoom ?? DEFAULT_CONFIG.zoom}
        onChange={handleChange("zoom")}
        label={translations.zoom}
        type="number"
      />
    </Section>
  );
});
