import { createUseTranslation } from "core/session/translation";

export const formTranslation = {
  en: {
    loading: "Loading...",
    saving: "Saving...",
    messageCreated: "Successfully created",
    messageUpdated: "Successfully updated",
  },
  es: {
    loading: "Cargando...",
    saving: "Guardando...",
    messageCreated: "Creado con éxito",
    messageUpdated: "Actualizado con éxito",
  },
};

export const actionTranslation = {
  en: {
    saveAndStay: "Save and new",
    saveAndGo: "Save",
    reset: "Reset",
    back: "Back",
    stateFieldNameLabel: "Action",
  },
  de: {
    saveAndStay: "Speichern und neu",
    saveAndGo: "Speichern",
    reset: "Zurücksetzen",
    back: "Zurück",
    stateFieldNameLabel: "Aktion",
  },
  es: {
    saveAndStay: "Guardar y Crear nuevo",
    saveAndGo: "Guardar",
    back: "Volver",
    reset: "Reiniciar",
    stateFieldNameLabel: "Acción",
  },
};

export const errorsTranslation = {
  en: {
    alertTitle: "Error",
    formErrorsTitle: "Errors",
    uniqueViolation: "This value already exists - please choose another one",
    nullValue: "The field cannot be empty",
    requiredFieldsTitle: "Required fields are missing:",
  },
  de: {},
};

export const useFormTranslation = createUseTranslation(formTranslation);

export const useActionTranslation = createUseTranslation(actionTranslation);

export const useErrorsTranslation = createUseTranslation(errorsTranslation);
