import { memo } from "react";
import Typography from "@mui/material/Typography";

import { clampFractionValues } from "../common/clampFractionValues";
import { NON_BREAKING_SPACE } from "../common/utils";

import { PropsFromRedux } from "./container";
import { useStyles } from "./styles";

const DefaultNumberField = memo<PropsFromRedux>(
  ({
    value,
    highlight,
    element: {
      config: { precision, isPercentage, prefix, suffix },
    },
  }) => {
    const { classes, cx } = useStyles();
    const clampedPrecision = clampFractionValues(precision ?? 0);

    return (
      <Typography
        className={cx({ [classes.highlight]: highlight })}
        color={highlight ? "error" : undefined}
      >
        {prefix}
        {value !== null
          ? Intl.NumberFormat(undefined, {
              minimumFractionDigits:
                precision !== null ? clampedPrecision : undefined,
              maximumFractionDigits:
                precision !== null ? clampedPrecision : undefined,
              style: isPercentage ? "percent" : "decimal",
            }).format(value)
          : NON_BREAKING_SPACE}
        {suffix}
      </Typography>
    );
  },
);

export default DefaultNumberField;
