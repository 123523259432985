import { CSSProperties, Dispatch, MouseEvent, SetStateAction } from "react";
import {
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { IFixedRow, Language } from "core";
import { useQueryGroupsList } from "utils/hooks";
import {
  ModalContentType,
  QueryGroup,
  QueryObject,
  QueryTypeFilterOptions,
  QueryViewOptions,
  UIQueryGroups,
} from "../types";
import { useQueryGroupUtils } from "../utils.tsx";

const queryViewTypes = ["list", "tree"] as const;

export type Props = {
  queries: QueryObject[];
  showActions?: boolean;
  isLoading?: boolean;
};

export type TSelectedQuery = QueryObject & { group?: number };

export type ModalProps = {
  type: ModalContentType;
  selected?: TSelectedQuery;
  selectedQueryGroup?: QueryGroup;
  queries?: string[];
};

type TFetch = {
  onSuccess: () => void;
};

export type TQueryFetchUtils = {
  customQuery: {
    modal: ModalProps | null;
  };
  createQueryGroupParams: TFetch;
  deleteQueryGroupParams: TFetch;
  deleteQueryParams: TFetch;
  deleteQueriesParams: TFetch;
  saveDefaultQueryParams: TFetch;
};

export type TDialogProps = {
  findQueryGroupByKey: (
    key: keyof QueryGroup,
    value: string | number | null,
  ) => QueryGroup | undefined;
  handleClose: () => void;
  handleCreateQueryGroup: (data: Record<string, any>) => void;
  handleEditQuery: (data: Record<string, any>) => void;
  handleEditQueryGroupName: ({ i18n }: Record<string, any>) => void;
  handleQueryDelete: (name: string) => void;
  handleQueriesDelete: () => void;
  handleDeleteQueryGroup: () => void;
  language: Language;
  modal: ModalProps | null;
  queryGroups: QueryGroup[];
};

export type TQueryRow = {
  type: (typeof queryViewTypes)[number];
} & (
  | ({
      type: "list";
    } & IFixedRow<QueryObject>)
  | {
      type: "tree";
      index: number;
      query: QueryObject;
      provided: DraggableProvided;
      snapshot: DraggableStateSnapshot;
      style?: CSSProperties;
    }
);

export type TQueriesList = {
  listQueries: QueryObject[];
};

type TQueryConfigCommonProps = {
  handleTreeEditClick: (
    queryGroup?: QueryGroup,
  ) => (e: MouseEvent<HTMLButtonElement>) => void;
  handleTreeDeleteClick: (
    queryGroup?: QueryGroup,
  ) => (e: MouseEvent<HTMLButtonElement>) => void;
};

export type TTreeProps = TQueryConfigCommonProps & {
  filteredQueries: QueryObject[];
  filteredQueryGroups: UIQueryGroups;
};

type TCommonDroppableProps = {
  index: number;
  handleEditClick: (
    queryGroup?: QueryGroup,
  ) => (ev: MouseEvent<HTMLButtonElement>) => void;
  handleDeleteClick: (
    queryGroup?: QueryGroup,
  ) => (ev: MouseEvent<HTMLButtonElement>) => void;
  provided: DraggableProvided | DroppableProvided;
  snapshot: DroppableStateSnapshot;
  queryGroup?: QueryGroup;
  isDraggingGlobal: boolean;
};

export type TQueryGroupFullDroppable = TCommonDroppableProps & {
  groupName: string;
  groupQueries: QueryObject[];
};

export type TQueryGroupEmptyDroppable = TCommonDroppableProps & {
  label: string;
  id: number;
};

export type TQueriesPanelProvider = Props & {
  queryGroups: QueryGroup[];
  queryGroupsUtils: ReturnType<typeof useQueryGroupUtils> &
    Omit<ReturnType<typeof useQueryGroupsList>, "mappedQueries">;
  modalProps: {
    modal: ModalProps | null;
    setModalOpen: Dispatch<SetStateAction<ModalProps | null>>;
    handleCloseModal: () => void;
    handleOpenModal: (
      contentType: ModalContentType,
      nextSelected?: TSelectedQuery,
      nextSelectedQueryGroup?: QueryGroup,
    ) => void;
  };
  queryProps: {
    queryType: QueryTypeFilterOptions | null;
    setQueryType: Dispatch<SetStateAction<QueryTypeFilterOptions | null>>;
    queryView: QueryViewOptions | null;
    setQueryView: Dispatch<SetStateAction<QueryViewOptions | null>>;
    fetchQueryProps: TQueryFetchUtils;
  };
  queryRowProps: {
    handleOpenRowModal: (
      type: ModalContentType,
    ) => (item: TSelectedQuery) => void;
    handleEditRow: (isDefault: boolean, item: TSelectedQuery) => void;
  };
};

export const ITEM_SIZE = 44;
