import { PropsWithChildren, memo, useCallback, useState } from "react";

import { ArrowRight } from "@mui/icons-material";
import { Paper, Stack } from "@mui/material";
import classNames from "classnames";
import CookieConsent from "react-cookie-consent";

import { Helmet } from "react-helmet";

import { LayoutProps } from "core";
import { useTranslator } from "core/session/translation";
import { usePageIdentifierLabel } from "core/utils/usePageIdentifierLabel";

import { RoutePaths } from "staticPages/routes";
import { AppBar } from "../common/AppBar";
import { Footer } from "../common/Footer";
import { Menu, MenuProps } from "../common/Menu";

import { AdminEditorLayout } from "./AdminComponent";
import { mapDispatchToProps, mapStateToProps } from "./container";
import useStyles from "./styles";

type DefaultLayoutProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  LayoutProps &
  PropsWithChildren;

const DEFAULT_MENU_WIDTH = "240px";
const DEFAULT_TOOLBAR_VARIANT = "dense";

const COOKIE_CONSENT_MESSAGE =
  "I consent that this application uses cookies and similar technologies to save e.g. application-critical data.";

export const PaperComponent = ({
  editModeOn,
  children,
  menuWidth,
  logoUrl,
  openState,
}: {
  menuWidth: string;
  editModeOn?: boolean;
  logoUrl?: string;
  openState?: boolean;
} & PropsWithChildren) => {
  const { classes } = useStyles({
    menuWidth,
    logoUrl,
  });
  return (
    <Paper
      className={classNames(classes.content, classes["content-left"], {
        [classes.contentShift]: openState,
        [classes["contentShift-left"]]: openState,
        [classes.editMode]: editModeOn,
      })}
      square={true}
      elevation={0}
      component="main"
    >
      {children}
    </Paper>
  );
};

export const DefaultLayout = memo<DefaultLayoutProps>(
  ({
    appMetadata: {
      release: {
        definition: { layout, menu },
      },
    },
    children,
    page,
    ui,
    location,
    isAdminPage,
    isStaticPage,
    isAdmin,
    push,
  }) => {
    const { translate } = useTranslator();
    const menuWidth = layout.definition.menu?.width ?? DEFAULT_MENU_WIDTH;
    const toolbarVariant =
      layout.definition.toolbar?.variant ?? DEFAULT_TOOLBAR_VARIANT;
    const menuClosed = Boolean(layout.definition.menu?.defaultClosed);
    const { classes } = useStyles({
      menuWidth,
      logoUrl: layout.definition.logo?.fileName ?? layout.definition.logo?.path,
    });
    const [open, setOpen] = useState(!menuClosed);

    const menuHidden = Boolean(layout.definition.menu?.hidden);
    const headerButtonsColor =
      layout.definition.headerButtons?.color ?? "inherit";
    const openState = !menuHidden ? open : false;
    const menuProps: MenuProps = {
      openState,
      logo: layout.definition.logo,
      uiName: ui?.name ?? "",
      menu,
      classes,
      menuItemSelected: location.pathname,
    };

    const toggleOpen = useCallback(
      () => setOpen((prevOpen: boolean) => !prevOpen),
      [setOpen],
    );
    const handleLoginClick = useCallback(() => push(RoutePaths.Login), [push]);

    const i18n = ui?.i18n ?? {
      en: {
        label: "Cypex",
        description: "",
      },
    };

    const label = translate(i18n).label;
    const logoUrl =
      layout.definition.logo?.fileName ?? layout.definition.logo?.path;

    // render AdminEditorLayout if user is able to use layout builder
    const pageContent =
      isAdmin && !isStaticPage ? (
        <AdminEditorLayout
          {...{
            isAdminPage,
            isStaticPage,
            openState,
            menuWidth,
            logoUrl,
            toggleOpen,
          }}
        >
          {children}
        </AdminEditorLayout>
      ) : (
        <PaperComponent
          {...{
            openState,
            editModeOn: false,
            menuWidth,
            logoUrl,
          }}
        >
          {children}
        </PaperComponent>
      );

    const pageLabel = usePageIdentifierLabel(page?.identifierLabelExpression);

    const appbarLabel = (
      <Stack direction="row" alignItems="center" divider={<ArrowRight />}>
        <span>{label}</span>
        {page && (
          <span>{page.i18n ? translate(page.i18n).label : page.url}</span>
        )}

        {pageLabel !== null && pageLabel !== undefined && (
          <span>{pageLabel}</span>
        )}
      </Stack>
    );

    return (
      <>
        {layout.definition.customStyles && (
          <Helmet>
            <style>{layout.definition.customStyles}</style>
          </Helmet>
        )}
        <div className={classes.root}>
          <div className={classes.appFrame}>
            <AppBar
              label={appbarLabel}
              handleDrawerChange={toggleOpen}
              open={openState}
              hidden={menuHidden || isAdminPage}
              menuWidth={menuWidth}
              toolbarVariant={toolbarVariant}
              headerButtonsColor={headerButtonsColor}
              handleLoginClick={handleLoginClick}
            />

            {!menuHidden && !isAdminPage && <Menu {...menuProps} />}

            {pageContent}

            {Boolean(layout.definition.cookieBanner?.active) && (
              <CookieConsent
                location="bottom"
                cookieName="cookieBannerAccepted"
                disableStyles={true}
                sameSite="lax"
                containerClasses={classes.cookieConsentContainer}
                buttonClasses={classes.cookieConsentButton}
                buttonId="cookie-accept-button"
                buttonText={layout.definition.cookieBanner?.buttonText}
              >
                {layout.definition.cookieBanner?.message ??
                  COOKIE_CONSENT_MESSAGE}
              </CookieConsent>
            )}

            <Footer openState={openState} layout={layout} />
          </div>
        </div>
      </>
    );
  },
);

DefaultLayout.displayName = "DefaultLayout";
