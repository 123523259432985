import { memo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Edge } from "reactflow";
import { useSessionContext, useTranslation } from "core/session";
import { QueryKeys } from "queries/admin";
import { getApiError } from "queries/utils";
import { useLocation } from "utils/hooks";
import { useSnackbar } from "utils/hooks/useSnackbar";

import { LoadingComponent } from "../../../../../../layouts/common/Loading";
import {
  useDeleteStateTransition,
  useStateTransition,
  useUpdateStateTransition,
} from "../../../../../../queries/admin";
import { useHandleSelectElement } from "../utils";

import { StateTransitionForm } from "./StateTransitionForm";
import { useStateTransitionTranslations } from "./StateTransitionForm/translation";
import { UIStateTransitionForm } from "./StateTransitionForm/types";
import { EdgeData } from "./StateViewer/types";

const EditStateTransition = ({ edge: element }: { edge: Edge<EdgeData> }) => {
  const setSelectedElement = useHandleSelectElement();

  const transitionId = element?.id;
  const stateTransitionResponse = useStateTransition(
    {
      transitionId,
    },
    { enabled: !!transitionId },
  );

  const { success, successDelete } = useStateTransitionTranslations();
  const showSnackbar = useSnackbar();
  const location = useLocation();
  const queryClient = useQueryClient();
  const {
    language: { code: languageCode },
  } = useSessionContext();
  const { mutate } = useUpdateStateTransition({
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
    onSuccess: () => {
      showSnackbar(success, "success");
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.fetchWorkflow],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.fetchStateTransition],
      });
      setSelectedElement(null);
    },
  });

  const deleteTransition = useDeleteStateTransition({
    onSuccess: () => {
      setSelectedElement(null);
      showSnackbar(successDelete, "success");
      queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchWorkflow] });
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const { title, shortDescription } = useTranslation(
    stateTransitionResponse?.data?.i18n,
  );

  if (stateTransitionResponse.isError) {
    // TODO handle errors correctly
    return null;
  }

  if (stateTransitionResponse.isLoading) {
    return <LoadingComponent />;
  }

  const {
    id: stateTransitionId,
    preState,
    postState,
    acl,
  } = stateTransitionResponse.data;

  const edge: UIStateTransitionForm = {
    id: stateTransitionId,
    preState,
    postState,
    acl,
    title,
    shortDescription,
  } as UIStateTransitionForm;

  const onSubmit = (data: Record<string, unknown>) => {
    const {
      queries: { schema, table },
    } = location;
    const translated = {
      [languageCode]: {
        title: data.title,
        shortDescription: data.shortDescription,
      },
    };
    const stateTransitionData = {
      acl: (data.acl ?? []) as string[],
      i18n: translated,
    };

    mutate({
      transitionId: stateTransitionId,
      schema,
      table,
      stateTransitionData,
    });
  };

  const onDeleteTransition = () => {
    deleteTransition.mutate({ id: stateTransitionId });
  };

  return (
    <StateTransitionForm
      key={stateTransitionResponse.dataUpdatedAt}
      edge={edge}
      onSubmit={onSubmit}
      onDelete={onDeleteTransition}
    />
  );
};

const MemoizedEditStateTransition = memo(EditStateTransition);

export default MemoizedEditStateTransition;
