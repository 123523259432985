import * as L from "leaflet";
import { default as iconRetinaUrl } from "leaflet/dist/images/marker-icon-2x.png";
import { default as iconUrl } from "leaflet/dist/images/marker-icon.png";
import { default as shadowUrl } from "leaflet/dist/images/marker-shadow.png";

// monkey patch the leaflet marker icon

// https://stackoverflow.com/questions/49441600/react-leaflet-marker-files-not-found/51222271
// https://github.com/PaulLeCam/react-leaflet/issues/453
// https://github.com/Asymmetrik/ngx-leaflet/issues/175

delete (L.Icon.Default.prototype as any)._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  tooltipAnchor: [0, 0],
});
