import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  controlClass: {
    flexDirection: "row",
    marginBottom: theme.spacing(),

    "& [class*='MuiFormLabel-root']": {
      marginRight: theme.spacing(),
    },
  },
}));
