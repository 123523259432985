import { memo } from "react";
import type { ReactNode } from "react";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";

type Props = {
  value: string;
  config: Record<string, any>;
  onChange: (value: string) => void;
  label?: string | ReactNode;
};

export const AdvancedMode = memo<Props>(
  ({ value = "", config, onChange, label }) => (
    <CustomExpressionEditor
      value={value}
      config={config}
      onChange={onChange}
      disableSwitcher={true}
      label={label}
      disableGutters
    />
  ),
);
