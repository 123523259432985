import { memo, useState } from "react";
import { Box } from "@mui/material";
import { Section, useElementEditorContext } from "core/editor";
import { TranslationEditor } from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";

import { UntransformedDefaultModalDialogConfig } from "../../types";
import { useDefaultModalDialogTranslation } from "../translation";

export const TranslationComponent = memo(() => {
  const { translationSectionTitle } = useDefaultModalDialogTranslation();
  const {
    elementModel: { i18n },
    changeTranslation,
  } = useElementEditorContext<UntransformedDefaultModalDialogConfig>();
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);

  const changeLanguage = (l: Language) => setLang(l);

  return (
    <Section
      title={translationSectionTitle}
      wrapped={true}
      cardActions={
        <LanguageSwitch
          language={lang}
          changeLanguage={changeLanguage}
          colorVariant="dark"
          fullWidth={true}
        />
      }
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <TranslationEditor
          translationKey="openButtonLabel"
          i18n={i18n}
          changeTranslation={changeTranslation}
          language={language}
          label="Button Label"
        />
        <TranslationEditor
          translationKey="modalTitle"
          i18n={i18n}
          changeTranslation={changeTranslation}
          language={language}
          label="Modal Title"
        />
      </Box>
    </Section>
  );
});
