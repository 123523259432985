import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  root: {},
  inputBox: {
    width: "100%",
    height: "100%",

    "& fieldset": {
      border: "none",
    },
    "& [class*='MuiInputBase-root'], & [class*='MuiInputBase-input'], & [class*='MuiFormControl-root']":
      {
        height: "100%",
      },
  },
}));
