import { NumberField } from "../types";

export function buildSelectors(element: NumberField) {
  const value = (state: any) => element.config.value(state);
  const highlight = (state: any) => element.config.highlight(state);

  return {
    value,
    highlight,
  };
}
