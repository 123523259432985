import { Implementation, createElementInterface } from "core";
import { AnyFluxStandardAction } from "core/utils/redux";

interface ITableDataSource {
  updateRow: (oldData: any, data: any) => AnyFluxStandardAction;
  deleteRow: (data: any) => AnyFluxStandardAction;
  reload: () => AnyFluxStandardAction;
  enqueueSnackbar: (notification: any) => AnyFluxStandardAction;
  types: {
    DATA_READY: string;
  };
}

export const TableDataSource = createElementInterface<ITableDataSource>();

export type TableDataSourceImplementation = Implementation<
  typeof TableDataSource
>;
