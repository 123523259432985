import { IReduxModuleFactoryArgs } from "core";

import { getElementDisplay } from "core/utils/element-utils";
import { SubFormTable } from "../types";

import { controlledReduxModuleFactory } from "./controlledReduxModule";

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;

export function reduxModuleFactory({
  path,
  element,
}: IReduxModuleFactoryArgs<SubFormTable>) {
  if (!element.config.dataSource?.elementId) {
    throw new Error(
      `The Subform Table element needs to be connected to a Form element. In element: ${getElementDisplay(
        element,
      )}`,
    );
  }
  return controlledReduxModuleFactory(path, element);
}
