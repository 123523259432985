import { CSSProperties, MouseEvent, memo } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { getBooleanExpressionValue } from "core";
import IconButton from "elementTypes/common/IconButton";

import { useTableEditorTranslation } from "../../../translation";
import { TABLE_HEADER_NAME } from "../Actions/ActionsEditor";
import { useStyles } from "../styles";

import { ColumnDetails } from "./Columns";

type Props = {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  index: number;
  style?: CSSProperties;
  disabled?: boolean;
  onBtnClick: (
    type: "edit" | "delete" | "select",
    params: ColumnDetails | number,
  ) => void;
} & ColumnDetails;

export const ColumnConfig = memo<Props>(
  ({
    name,
    label,
    provided,
    snapshot,
    style,
    onBtnClick,
    isHidden,
    disabled,
    ...rest
  }) => {
    const {
      editColumnTooltip,
      deleteColumnTooltip,
      selectColumnTooltip,
      hiddenColumnTooltip,
      noEditorComponentTooltip,
    } = useTableEditorTranslation();
    const {
      classes: { draggingItem },
      cx,
    } = useStyles();
    const { isDragging } = snapshot;
    function handleClick(type: "edit" | "delete" | "select") {
      return function (e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        e.stopPropagation();
        !isDragging &&
          onBtnClick(
            type,
            type === "edit"
              ? ({ name, label, isHidden, ...rest } as ColumnDetails)
              : rest.index,
          );
      };
    }

    const isHiddenExpressionValue =
      getBooleanExpressionValue(isHidden) ?? false;

    return (
      <ListItemButton
        divider
        className={cx(
          { [draggingItem]: isDragging },
          "editor-table-section-columns-list-item",
        )}
        selected={isDragging}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          ...style,
          ...provided.draggableProps.style,
        }}
      >
        <ListItemText primary={label} />
        {name !== TABLE_HEADER_NAME && (
          <ListItemIcon>
            <IconButton
              icon="edit"
              className="editor-table-column-edit"
              onClick={handleClick("edit")}
              disabled={isDragging}
              tooltip={editColumnTooltip}
              size="small"
              placement="top"
            />
            <IconButton
              icon="touch_app"
              className="editor-table-column-select"
              onClick={handleClick("select")}
              disabled={isDragging || isHiddenExpressionValue || disabled}
              size="small"
              placement="top"
              tooltip={
                disabled
                  ? noEditorComponentTooltip
                  : isHiddenExpressionValue
                    ? hiddenColumnTooltip
                    : selectColumnTooltip
              }
            />
            <IconButton
              icon="delete_outline"
              className="editor-table-column-delete"
              onClick={handleClick("delete")}
              disabled={isDragging}
              tooltip={deleteColumnTooltip}
              size="small"
              placement="top"
              edge="end"
            />
          </ListItemIcon>
        )}
      </ListItemButton>
    );
  },
);
