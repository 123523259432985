import { createUseTranslation } from "core/session/translation";

export const commonStaticPagesTranslation = {
  // TODO: clean file; create separate files for pages
  en: {
    appTitle: "Applications",
    generateAppTitle: "Generate New Application",
    editAppTitle: "Edit Application",
    importAppTitle: "Import Application",
    SaveButton: "Save",
    DeleteButton: "Delete",
    cancelButton: "Cancel",
    deletedMessage: "App was deleted successfully",
    saveErrorMessage: "App was not saved. Error:",
    saveSuccessMessage: "App successfully saved",
    exportAppMessage: "App was correctly exported",
    createAppMessage: "Application was succesfully created",
    editAppMessage: "Application was succesfully edited",
    generateLabel: "GENERATE",
    createLabel: "IMPORT",
    languageLabel: "Language",
    noDataLabel: "No Available Queries",
    editTooltip: "Edit",
    startTooltip: "Start",
    exportTooltip: "Export",
    deleteTooltip: "Delete",
    descriptionTooltip: "Show Description",
    generateQueriesLabel: "Generate New Pages for Queries",
    dialogContentText: "Are you sure you want to delete the Application",
    dialogTitle: "Delete Application",
    generatePagesTitle: "Generate pages",
    queries: "Create default pages for queries",
    generatePagesMessage: "New pages were succesfully generated",
    noGeneratedPagesMessage: "Pages were not generated",
    generateSubmit: "Generate Pages",
    generateRefreshTooltip: "Refresh",
    generateSelectUnselectTooltip: "Select / Unselect all",
    applicationTitle: "Cypex applications",
    applicationSubtitle: "application(s) available in this release.",
    applicationName: "Name",
    applicationDescription: "Description",
    applicationVersion: "Version",
    applicationInstalledVersion: "Installed Version",
    applicationActions: "Actions",
    applicationLicense: "License",
    applicationInstall: "Install application",
    applicationUninstall: "Remove application",
    applicationDialogContentText:
      "You are about to unistall the Repository Application",
    uninstall: "Uninstall",
    uninstallTitle: "Uninstall Repository Application",
    applicationInstalled: "Repository Application installed successfully",
    applicationUninstalled: "Repository Application removed successfully",
    noApplicationInstalled:
      "No application found. Please check your repository configuration.",
    applicationOwner: "Application Owner",
    applicationRelease: "Release",
    applicationLatestRelease: "Latest Release",
    applicationInstalledTitle: "Installed",
    applicationClose: "Close",
    searchApplications: "Search",
    readmeTitle: "README",
    contentTitle: "CONTENT",
    installedAtText: "Installed at",
    notInstalled: "Not installed",
    extensionTitle: "SQL Catalogue - Cypex extensions",
    extensionSubtitle: "extension(s) available in this release.",
    extensionName: "Name",
    extensionDescription: "Description",
    extensionVersion: "Version",
    extensionInstalledVersion: "Installed Version",
    extensionActions: "License",
    extensionLicense: "Actions",
    extensionInstall: "Install extension",
    extensionUninstall: "Remove extension",
    extensionDialogContentText: "You are about to unistall the extension",
    extensionInstalled: "Extension installed successfully",
    extensionUninstalled: "Extension removed successfully",
    noExtensionInstalled: "The extension module is not installed.",
    applicationInstallError:
      "Unable to install the application. The most common cause is that some database objects already exist.",
    generalTitle: "General",
    layoutTitle: "Layout",
    installLatestVersion: "Install latest version",
    previewTooltip: "Preview",
    installTooltip: "Install",
    uninstallTooltip: "Uninstall",
    releases: "Releases",
    filterByOwnerLabel: "Filter by Owner...",
    trialPagesLimitLabel: "In the Trial Version, Pages are limited to",
    readonlyUITooltip: "UI Name is read-only.",
    generateHelperTooltip:
      "Generate new pages for queries that have not been added to the application before",
    appRoleWarning:
      "Before creating your application, please select an appropriate owner below. This Role is the owner of the Queries used to create the default layout for your application.",
  },
};

export const useCommonStaticPagesTranslation = createUseTranslation(
  commonStaticPagesTranslation,
);
