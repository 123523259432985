import { Type } from "./Type";
import { TypeError } from "./TypeError";
import { IAutocompleteKey } from "./types";
import { objectToType } from "./utils";

export class EnumType extends Type {
  public name = "enum";

  private readonly obj: any;

  private readonly values: Set<any>;

  constructor(obj: Record<string, unknown>, description?: string) {
    super(description);
    this.values = new Set(
      Object.keys(obj).reduce((acc, k) => [...acc, obj[k]], [] as any[]),
    );
  }

  public getAutocompleteRecord(): Record<string, IAutocompleteKey> {
    return Object.keys(this.obj).reduce(
      (acc, k) => ({
        ...acc,
        [k]: objectToType(this.obj[k]),
      }),
      {},
    );
  }

  public validate(value: any): true | TypeError {
    return this.values.has(value)
      ? true
      : new TypeError(value, this, "TODO: detailed error message");
  }
}
