import { memo, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { buildCustomExpressionValue } from "core";
import { Section, useElementEditorContext } from "core/editor";
import { Autocomplete } from "elementTypes/common/Autocomplete";

// eslint-disable-next-line import/order
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";

import { IAutocompleteValue } from "elementTypes/common/Autocomplete/types";
import IconButton from "elementTypes/common/IconButton";
import { UntransformedGeoJSONFieldConfig } from "../../../types";
import { useDefaultGeoJSONFieldEditorTranslation } from "../../translation";
import { useViewOptions } from "../../utils";

export const SelectedGeoElement = memo(() => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedGeoJSONFieldConfig>();

  const t = useDefaultGeoJSONFieldEditorTranslation();

  const viewName = config.dataSource?.viewName ?? "";
  const viewOptions = useViewOptions(viewName);

  let content = <Typography>{t.selectionWarning}</Typography>;

  const handleChange = useCallback(
    (key: string) => (nextVal: IAutocompleteValue, reason?: string) => {
      const nextValue =
        reason === "clear"
          ? undefined
          : {
              ...(config.selected ?? {}),
              [key]: nextVal,
            };

      changeConfigValue("selected", nextValue);
    },
    [changeConfigValue, config.selected],
  );

  const handleDelete = () => {
    changeConfigValue("selected", undefined);
  };

  if (viewName && viewOptions) {
    content = (
      <Box>
        <Autocomplete
          label={t.selectedColumnLabel}
          options={viewOptions}
          value={config.selected?.property ?? ""}
          onChange={handleChange("property")}
          isClearable
        />
        <CustomExpressionEditor
          label={t.selectedValueLabel}
          value={
            config.selected?.propertyValue ?? buildCustomExpressionValue("null")
          }
          config={config}
          onChange={handleChange("propertyValue")}
          disableSwitcher
        />
      </Box>
    );
  }

  return (
    <Section
      title={t.selectedTitle}
      wrapped={true}
      gap={1}
      headerAction={
        <IconButton
          tooltip={t.selectedDeleteLabel}
          icon="delete_outline"
          onClick={handleDelete}
        />
      }
    >
      {content}
    </Section>
  );
});
