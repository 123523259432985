import { createContext } from "react";

import { TElementModelWithPosition } from "core";

type ActionsContextType = {
  actionItems: TElementModelWithPosition[];
  deleteChecked: boolean;
  handleDelete: (id: string) => void;
  onActionSelect: (index: number) => void;
  onSwitchDeleteAction: () => void;
  onActionsRefresh: () => void;
};

export const ActionsContext = createContext<ActionsContextType | undefined>(
  undefined,
);

export const ActionsProvider = ActionsContext.Provider;
