import { Type } from "./Type";
import { TypeError } from "./TypeError";
import { IAutocompleteKey } from "./types";

export class OptionalType extends Type {
  public name: string;

  constructor(
    public readonly type: Type,
    description?: string,
  ) {
    super(description);
    this.name = this.generateTypeName();
  }

  private generateTypeName(): string {
    return `${this.type.name} | undefined`;
  }

  public validate(value: any): true | TypeError {
    return value === undefined ? true : this.type.validate(value);
  }

  public getKeyType(key: string): Type | undefined {
    return this.type.getKeyType(key);
  }

  public getAutocompleteRecord(): Record<string, IAutocompleteKey> {
    return this.type.getAutocompleteRecord();
  }
}
