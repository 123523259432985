import { IRawElementType } from "core";

import Component from "./container";
import { ActionButtonEditor, defaultElement, editorMetadata } from "./editor";
import { actionButtonSelectors, reduxModuleFactory } from "./reduxModule";
import { ActionButtonConfig, ActionButtonTranslationKeys } from "./types";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_action_button",
  component: Component,
  editable: true,
  configType: ActionButtonConfig,
  translationKeys: ActionButtonTranslationKeys,
  editorComponent: ActionButtonEditor,
  defaultElement,
  editorMetadata,
  selectorTypes: actionButtonSelectors,
};

export default elementType;
