import { Type } from "./Type";
import { TypeError } from "./TypeError";
import { IAutocompleteKey } from "./types";

export class DeferredTypeType extends Type {
  // TODO: make name be lazy so we can return the correct type name here
  public name = "deferred";

  private _type: Type | null = null;

  public get type(): Type {
    if (!this._type) {
      this._type = this.factory();
    }
    return this._type;
  }

  constructor(private readonly factory: () => Type) {
    super();
  }

  public validate(value: any): true | TypeError {
    return this.type.validate(value);
  }

  public getKeyType(key: string): Type | undefined {
    return this.type.getKeyType(key);
  }

  public getAutocompleteRecord(): Record<string, IAutocompleteKey> {
    return this.type.getAutocompleteRecord();
  }
}
