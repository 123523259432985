import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  root: {
    height: "100%",
    width: "100%",
  },
}));

export default useStyles;
