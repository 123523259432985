import { IRawElementType } from "core";

import Component from "./container";
import { TextFieldEditor, defaultElement, editorMetadata } from "./editor";
import { TextFieldConfig } from "./types";

const elementType: IRawElementType = {
  defaultElement,
  name: "default_text_field",
  component: Component,
  editable: true,
  configType: TextFieldConfig,
  editorComponent: TextFieldEditor,
  editorMetadata,
};

export default elementType;
