import { createUseTranslation } from "core/session/translation";

export const dateTimeFieldEditorTranslation = {
  en: {
    valueLabel: "Date Time",
    formatLabel: "Format",
    isRelativeLabel: "Is Relative",
    showDatePartLabel: "Date",
    showTimePartLabel: "Time",
    formatStringLabel: "Display Format",
    formatHelperText: "Leave empty for automatic format",
  },
};

export const useDateTimeFieldEditorTranslation = createUseTranslation(
  dateTimeFieldEditorTranslation,
);
