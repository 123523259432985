import { ElementGroup, IRawElementType } from "core";

import Component from "./container";
import { GeoJSONInputEditor, defaultElement, editorMetadata } from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import {
  GeoJSONInputConfig,
  GeoJSONInputTranslationKeys,
  geoJSONInputSelectors,
} from "./types";

const elementType: IRawElementType = {
  defaultElement,
  reduxModuleFactory,
  name: "default_geojson_input",
  group: ElementGroup.Inputs,
  component: Component,
  editable: true,
  configType: GeoJSONInputConfig,
  selectorTypes: geoJSONInputSelectors,
  translationKeys: GeoJSONInputTranslationKeys,
  editorComponent: GeoJSONInputEditor,
  editorMetadata,
};

export default elementType;
