import { IStaticRouteConfig } from "core/router/types";

import { RoutePaths } from "../../../routes";
import { AppsPage } from "./component";
import {
  CreateAppsPageRoute,
  EditAppsPageRoute,
  GenerateAppsPageRoute,
} from "./pages";

export const route: IStaticRouteConfig = {
  Component: AppsPage,
  auth: true,
  isAdmin: true,
  routes: {
    [RoutePaths.AppsCreate]: CreateAppsPageRoute,
    [RoutePaths.AppsGenerate]: GenerateAppsPageRoute,
    [RoutePaths.AppsEdit]: EditAppsPageRoute,
  },
};
