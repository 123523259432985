import { MouseEvent, memo, useCallback, useMemo, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TranslateIcon from "@mui/icons-material/Translate";
import { Button, ButtonProps, Menu, MenuItem, MenuList } from "@mui/material";
import classNames from "classnames";
import { RefCallBack } from "react-hook-form";
import { LANGUAGES, Language } from "core/types";

import { ColorVariant, useStyles } from "./styles";

type Props = {
  language: Language;
  changeLanguage: (lang: Language) => void;
  colorVariant?: ColorVariant;
  className?: string;
  fullWidth?: boolean;
  buttonVariant?: ButtonProps["variant"];
  innerRef?: RefCallBack;
};

export const Switcher = memo<Props>(
  ({
    language,
    className,
    fullWidth,
    colorVariant = "light",
    buttonVariant = "text",
    changeLanguage,
    innerRef,
  }) => {
    const { classes } = useStyles({ color: colorVariant });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChange = useCallback(
      (value: string) => () => {
        changeLanguage(LANGUAGES.find((l: Language) => l.code === value)!);
        handleClose();
      },
      [changeLanguage],
    );

    const options = useMemo(
      () =>
        // hide italian and spanish, since we don't really support them anyway
        LANGUAGES.slice(0, 2).map(
          ({ label: labelOption, code: valueOption }) => (
            <MenuItem key={valueOption} onClick={handleChange(valueOption)}>
              {labelOption.toUpperCase()}
            </MenuItem>
          ),
        ),
      [handleChange],
    );

    return (
      <>
        <Button
          variant={buttonVariant}
          onClick={handleClick}
          startIcon={<TranslateIcon />}
          endIcon={<ArrowDropDownIcon />}
          className={classNames(
            className,
            classes.button,
            "language-switch-switcher",
          )}
          fullWidth={fullWidth}
        >
          {language.label}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuList autoFocusItem={!!anchorEl} ref={innerRef}>
            {options}
          </MenuList>
        </Menu>
      </>
    );
  },
);
