import { memo } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import { LoadingComponent } from "layouts/common/Loading.tsx";
import {
  useFileGroupOptions,
  useFileTypeOptions,
} from "queries/admin/fileData.ts";
import { UploadZoneInput } from "staticPages/admin/pages/apps/pages/create/components";
import { useCommonStaticPagesTranslation } from "../translation.ts";
import { TUploadForm } from "../types.ts";

export const UploadForm = memo<TUploadForm>(
  ({ control, setValue, fileData, acceptedFiles, isLoading = false }) => {
    const { tableNameLabel, groupLabel, typeLabel } =
      useCommonStaticPagesTranslation();

    const fileOptions = useFileTypeOptions();

    const groupOptions = useFileGroupOptions();

    const handleOnFileUpload = (file: File) => setValue("realName", file.name);

    if (
      groupOptions.isInitialLoading ||
      fileOptions.isInitialLoading ||
      isLoading
    ) {
      return <LoadingComponent />;
    }

    if (!fileOptions.data || !groupOptions.data) {
      return null;
    }

    return (
      <>
        <Grid item xs={12}>
          <Controller
            name="realName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label={tableNameLabel}
                error={Boolean(error)}
                helperText={error?.message}
                fullWidth
                {...field}
              />
            )}
            defaultValue={fileData?.realName ?? ""}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="groupName"
            control={control}
            {...(!fileData && {
              rules: {
                required: true,
              },
            })}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                options={groupOptions.data}
                label={groupLabel}
                error={error?.type}
                innerRef={ref}
                variant="standard"
                isClearable={false}
              />
            )}
            defaultValue={fileData?.fileGroup.name}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="typeGroupName"
            control={control}
            {...(!fileData && {
              rules: {
                required: true,
              },
            })}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                options={fileOptions.data}
                {...field}
                innerRef={ref}
                label={typeLabel}
                error={error?.type}
                variant="standard"
                isClearable={false}
              />
            )}
            defaultValue={fileData?.typeGroup.typeName}
          />
        </Grid>
        {!fileData && (
          <Grid item xs={12}>
            <Controller
              render={(p) => (
                <UploadZoneInput
                  {...{ ...p }}
                  accept={acceptedFiles}
                  onUpload={handleOnFileUpload}
                />
              )}
              name="file"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Grid>
        )}
      </>
    );
  },
);
