import { PropsWithChildren, memo } from "react";

import { SessionContextProvider } from "../SessionContext";

import { mapStateToProps } from "./container";

type Props = ReturnType<typeof mapStateToProps> & PropsWithChildren;

export const SessionProvider = memo<Props>(({ currentLanguage, children }) => (
  <SessionContextProvider value={{ language: currentLanguage }}>
    {children}
  </SessionContextProvider>
));

SessionProvider.displayName = "SessionProvider";
