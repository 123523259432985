import adminService from "./admin";
import * as apiService from "./api";
import loader from "./loader";

export function buildServices() {
  return {
    loader,
    api: apiService,
    admin: adminService,
  };
}

export type Services = ReturnType<typeof buildServices>;
