import { Box, Typography } from "@mui/material";

import { Section, useElementEditorContext } from "core/editor";
import { ColorInput } from "elementTypes/common/ColorInput";
import IconButton from "elementTypes/common/IconButton";
import { useChartEditorTranslation } from "elementTypes/echarts_bar_chart/translation";
import { UntransformedPieChartConfig } from "elementTypes/echarts_pie_chart/types";

enum Styles {
  color = "color",
  borderColor = "borderColor",
}

type Style = keyof typeof Styles;

const DEFAULT_COLOR = "#ddd";
const TRANSPARENT = "transparent";
const defaultNoDataStyles = {
  color: DEFAULT_COLOR,
  borderColor: DEFAULT_COLOR,
};

export const NoDataStyles = () => {
  const t = useChartEditorTranslation();
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedPieChartConfig>();

  const noDataStyles = {
    ...defaultNoDataStyles,
    ...(config?.noDataStyles ?? {}),
  };

  const handleChangeTheme = (name: Style) => (value: string | null) =>
    changeConfigValue("noDataStyles", {
      ...noDataStyles,
      [name]: value ?? TRANSPARENT,
    });

  const handleRemoveColor = (name: Style) => () =>
    handleChangeTheme(name)(TRANSPARENT);

  const items = Object.values(Styles).map((customKey) => (
    <Box key={String(customKey)} px={2} py={1}>
      <Typography>{t[`${customKey}Label`]}</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <ColorInput
          value={noDataStyles?.[customKey] ?? DEFAULT_COLOR}
          label={noDataStyles?.[customKey] ?? DEFAULT_COLOR}
          changeValue={handleChangeTheme(customKey)}
        />
        <IconButton
          icon="delete_outline"
          tooltip={t.deleteTooltip}
          onClick={handleRemoveColor(customKey)}
          edge="end"
        />
      </Box>
    </Box>
  ));
  return <Section title={t.noDataTitle}>{items}</Section>;
};
