import { MouseEvent } from "react";
import { createTypeSafeContext } from "utils/createTypeSafeContext";

export type IDialogWrapperContext = {
  onSubmit: (e: MouseEvent) => void;
};

const { Provider, Consumer } = createTypeSafeContext<IDialogWrapperContext>();

export const DialogWrapperProvider = Provider;
export const DialogWrapperConsumer = Consumer;
