import { memo } from "react";
import { Section, useElementEditorContext } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { useEditorTranslation } from "core/editor/translation";
import { buildConfigFromFixedFilter } from "elementTypes/default_table/reduxModule/utils";
import { IFilterGroup } from "elementTypes/default_table/toolsPanel/types";

import { UntransformedEchartConfig } from "../EchartsContainer";
import { defaultExpressionValue } from "../utils";

export const Filter = memo(<
  UntransformedChartConfig extends UntransformedEchartConfig,
>() => {
  const {
    elementModel: {
      config,
      config: { filter },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedChartConfig>();
  const translation = useEditorTranslation();

  const handleFilterValueChange = (newFixedFilter?: string | IFilterGroup) => {
    changeConfigValue(
      "filter",
      typeof newFixedFilter === "string"
        ? newFixedFilter
        : buildConfigFromFixedFilter(newFixedFilter ?? null),
    );
  };

  const fixedFilterExpression = (filter ?? defaultExpressionValue) as string;

  return (
    <Section title={translation.filterTitle} wrapped={true}>
      <CustomExpressionEditor
        onChange={handleFilterValueChange}
        value={fixedFilterExpression}
        config={config}
        disableSwitcher={true}
      />
    </Section>
  );
});
