import { MutableRefObject, ReactNode, memo, useEffect, useState } from "react";
import { Stack, StackProps } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader, { CardHeaderProps } from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import IconButton from "elementTypes/common/IconButton";

import { useStyles } from "./styles";

type Props = {
  children: ReactNode | ReactNode[];
  title?: string;
  wrapped?: boolean;
  classes?: {
    root?: string;
    content?: string;
    title?: string;
  };
  headerAction?: CardHeaderProps["action"];
  cardActions?: ReactNode;
  innerRef?: MutableRefObject<HTMLDivElement | null>;
  defaultOpened?: boolean;
  collapsible?: boolean;
};

export const Section = memo<Props & StackProps>(
  ({
    title,
    children,
    wrapped,
    classes,
    headerAction,
    cardActions,
    innerRef,
    defaultOpened = true,
    collapsible = true,
    ...boxProps
  }) => {
    const { classes: ownClasses, cx } = useStyles();
    const [open, setOpen] = useState<boolean>(defaultOpened);
    const toggleContent = () => setOpen((prevOpen) => !prevOpen);
    const contentExists = Boolean(cardActions || children);

    useEffect(() => {
      if (!open && defaultOpened) {
        setOpen(defaultOpened);
      }
    }, [defaultOpened]);

    useEffect(() => {
      if (!contentExists && open) {
        setOpen(false);
      }
    }, [contentExists]);

    const childrenArray = (
      <Stack direction="column" {...boxProps}>
        {children}
      </Stack>
    );

    return (
      <Box
        marginBottom={0.5}
        className={cx(ownClasses.specificGap, classes?.root)}
      >
        <Card
          square
          variant="outlined"
          ref={innerRef}
          className={ownClasses.card}
        >
          <CardHeader
            {...(!!title?.length && { title })}
            titleTypographyProps={{ variant: "subtitle1" }}
            {...(collapsible && {
              action: (
                <Box display="flex" alignItems="center">
                  {headerAction}
                  <IconButton
                    icon={open ? "expand_less" : "expand_more"}
                    onClick={toggleContent}
                    disabled={!contentExists}
                    data-test-title={title}
                  />
                </Box>
              ),
            })}
            className={ownClasses.header}
            classes={{ action: ownClasses.action, title: classes?.title }}
          />
          <Collapse in={open} timeout="auto" unmountOnExit>
            {cardActions && <CardActions>{cardActions}</CardActions>}
            {wrapped ? (
              <CardContent className={cx(ownClasses.content, classes?.content)}>
                {childrenArray}
              </CardContent>
            ) : (
              childrenArray
            )}
          </Collapse>
        </Card>
      </Box>
    );
  },
);

Section.displayName = "Section";
