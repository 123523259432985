import { memo } from "react";
import { Box } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridRowModel,
} from "@mui/x-data-grid";

import { useCustomQueryTranslation } from "../customQuery/translation";

export const PreviewTable = memo<{
  rows: GridRowModel[];
  columns: GridColDef[];
  error?: string;
}>(({ rows, columns, error }) => {
  const translation = useCustomQueryTranslation();

  const components = {
    NoRowsOverlay: rows.length
      ? undefined
      : () => <GridOverlay>{translation.dataGridContent}</GridOverlay>,
  };
  return (
    <Box height={500} width="100%">
      <DataGrid
        rows={rows}
        columns={columns}
        components={components}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        error={error}
      />
    </Box>
  );
});
