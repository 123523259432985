import { memo } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import notFoundPath from "layouts/common/notFound.svg";

import useStyles from "./styles";
import { useNoAppsAvailablePageTranslation } from "./translation";

export const NoAppsAvailablePage = memo(() => {
  const {
    classes: { root },
  } = useStyles();
  const translation = useNoAppsAvailablePageTranslation();

  return (
    <>
      <Helmet>
        <title>{translation.noAppsAvailableText}</title>
      </Helmet>

      <Card className={root}>
        <CardHeader
          title={
            <Typography variant="h2" align="center">
              {translation.noAppsAvailableText}
            </Typography>
          }
        />
        <CardContent>
          <img src={notFoundPath} alt="Not Found" />
        </CardContent>
      </Card>
    </>
  );
});
