import { memo } from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

import { useRouterParamByName } from "core/router/reduxModule/utils";
import BackButton from "elementTypes/common/BackButton";
import { RoutePaths } from "staticPages/routes";
import { useRoles, useUser } from "../../../../../../queries/admin";
import { getRoleOptions } from "../../../../../../queries/admin/utils";

import { User } from "../../types";
import { Form } from "./components/Form";

export const EditUsersPage = memo(() => {
  const userId = useRouterParamByName("userId");

  const { data: roleOptions } = useRoles({
    select: getRoleOptions,
  });

  const user = useUser({ userId }, { enabled: !!userId });
  // TODO: refactor, so far we are not using anon role activly, so it means that User id deactivated
  const userRole = user?.data?.role === "anon" ? "" : user?.data?.role;
  const appUser = {
    ...(user?.data ?? {}),
    role: userRole,
  } as User;

  return (
    <>
      <Box display="flex" alignItems="center" gap={1}>
        <BackButton isIcon href={RoutePaths.Users} />
        <Typography variant="h5">Editing - {appUser?.email}</Typography>
      </Box>
      {user?.data && roleOptions && (
        <Form roleOptions={roleOptions} user={appUser} />
      )}
    </>
  );
});
