import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { BaseEdge, EdgeProps, getSmoothStepPath } from "reactflow";

export function AnimatedSVGEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}: EdgeProps) {
  const theme = useTheme();
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const duration = 3;

  const [startAnimation, setStartAnimation] = useState(false);

  const color = startAnimation ? theme.palette.secondary.main : "transparent";

  useEffect(() => {
    const delay = data?.delay ?? 0;
    const timer = setTimeout(() => {
      setStartAnimation(true);
    }, delay * 1000);

    return () => clearTimeout(timer);
  }, [data?.delay]);

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{ stroke: theme.palette.grey[500] }}
      />

      <circle r="5" fill={color} data-delay={`${data?.delay ?? 0}s`}>
        <animateMotion
          dur={`${duration}s`}
          repeatCount={1}
          path={edgePath}
          begin={`${data?.delay ?? 0}s`}
          keySplines="0.42 0 0.58 1"
          calcMode="linear"
          fill="freeze"
        />
      </circle>
    </>
  );
}
