import { IRawElementType } from "core";

import Component from "./container";
import {
  InternalLinkFieldEditor,
  defaultElement,
  editorMetadata,
} from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import { InternalLinkFieldConfig, internalLinkFieldSelectors } from "./types";
import { getQueriesFromConfig } from "./utils";

export const elementType: IRawElementType = {
  name: "default_internal_link_field",
  reduxModuleFactory,
  component: Component,
  editable: true,
  configType: InternalLinkFieldConfig,
  editorComponent: InternalLinkFieldEditor,
  selectorTypes: internalLinkFieldSelectors,
  defaultElement,
  editorMetadata,
  getQueriesFromConfig,
};

export default elementType;
