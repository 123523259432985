import { memo } from "react";
import { Colors } from "elementTypes/common/StyledTypography/utils";

import Button from "../common/Button";

import { ClipboardCopyButtonProps } from "./container";
import { useStyles } from "./style";

const DefaultClipboardCopyButtonComponent = memo<ClipboardCopyButtonProps>(
  ({
    element: {
      i18n: { label },
      config: { variant },
    },
    disabled,
    icon,
    color = "default",
    copy,
  }) => {
    const {
      classes: { root },
    } = useStyles();

    return (
      <Button
        onClick={copy}
        label={label}
        className={root}
        iconRight={icon}
        disabled={disabled}
        fullWidth
        color={Colors[color]}
        variant={variant}
      />
    );
  },
);

export default DefaultClipboardCopyButtonComponent;
