import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";
import { getDisabledValueFromConfig } from "elementTypes/common/utils.ts";

import { SliderInput } from "../../types";

export function buildSelectors(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
  element: SliderInput,
) {
  const value = (state: any) =>
    dataSource.createFieldValueSelector(fieldPath, {
      defaultValue: null,
    })(state);

  const disabled = (state?: any) =>
    dataSource.isReadOnly || getDisabledValueFromConfig(state, element.config);

  const required = () =>
    dataSource.createFieldRequiredSelector(fieldPath) ??
    element.config.nullable === false;

  return {
    value,
    disabled,
    required,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
