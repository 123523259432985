import * as lodash from "lodash";

import { InterfaceType } from "core/runtime-typing";
import { objectToType } from "core/runtime-typing/utils";

type LodashType = typeof lodash;

function getlodashType(lib: LodashType) {
  return new InterfaceType(
    Object.getOwnPropertyNames(lib).reduce(
      (acc, k) => ({
        ...acc,
        [k]: objectToType(lib[k as keyof typeof lib]),
      }),
      {},
    ),
    lib.VERSION,
  );
}

const lodashType = getlodashType(lodash);

export { lodashType, lodash };
