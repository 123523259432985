import { IElementComponentProps, PropsFromConnector } from "core";
import { connectElement } from "core/utils/react-redux";
import Component from "./component";
import { ReduxModule } from "./reduxModule";
import { DefaultModalDialog } from "./types";

const mapStateToProps = (
  state: any,
  { element }: IElementComponentProps<ReduxModule, DefaultModalDialog>,
) => {
  return {
    openButtonDisabled: element.config.openButtonDisabled?.(state) ?? false,
  };
};

const mapDispatchToProps = {};

const connector = connectElement<
  ReduxModule,
  DefaultModalDialog,
  typeof mapStateToProps,
  typeof mapDispatchToProps
>(mapStateToProps, mapDispatchToProps);

export type DefaultModalDialogProps = PropsFromConnector<typeof connector>;

export default connector(Component);
