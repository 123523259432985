import { memo } from "react";

import { IconNameType } from "elementTypes/common/MuiIcon";
import { Props } from "./container";
import DefaultDataDisplay from "./DataDisplay";

const DefaultDataDisplayComponent = memo<Props>(
  ({
    element: {
      i18n: { label },
      config: { icon, barPosition, variant, cardVariant },
    },
    formattedData,
    error,
    loading,
    load,
    color = "info",
  }) => (
    <DefaultDataDisplay
      label={label}
      icon={icon as IconNameType}
      barPosition={barPosition}
      formattedData={formattedData}
      error={error}
      loading={loading}
      load={load}
      color={color}
      variant={variant}
      cardVariant={cardVariant ?? "outlined"}
    />
  ),
);

export default DefaultDataDisplayComponent;
