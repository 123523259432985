import { FC, PropsWithChildren } from "react";
import { TCssGridConfig } from "core";
import { useStyles } from "./styles";

const GridCell: FC<TCssGridConfig & PropsWithChildren> = ({
  children,
  ...rest
}) => {
  const {
    classes: { root },
  } = useStyles(rest);
  return <div className={root}>{children}</div>;
};

GridCell.displayName = "GridCell";

export default GridCell;
