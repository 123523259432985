import * as t from "io-ts";
import { IElement, UntransformedConfig, customExpression } from "core/types";

export const ColorFieldConfig = t.intersection([
  t.type({
    value: customExpression(t.union([t.string, t.null])),
  }),
  t.partial({
    width: t.number,
    height: t.number,
    showLabel: t.boolean,
  }),
]);

export type ColorFieldConfig = t.TypeOf<typeof ColorFieldConfig>;

export type UntransformedColorFieldConfig =
  UntransformedConfig<ColorFieldConfig>;

export type ColorField = IElement<ColorFieldConfig>;
