import { selectors as sessionSelectors } from "core/session/reduxModule";

import { StateChangeDropDown } from "../types";

export function buildSelectors(element: StateChangeDropDown) {
  const value = (state: any) => element.config.value(state);
  const language = (state: any) => sessionSelectors.currentLanguage(state).code;

  return {
    value,
    language,
  };
}
