import { useState } from "react";
import { useSessionContext } from "core/session";
import { Language } from "core/types";

export const useLanguage = (language?: Language) => {
  const { language: sessionLanguage } = useSessionContext();

  const [lang, setLang] = useState<Language>(language ?? sessionLanguage);
  const changeLanguage = (l: Language) => setLang(l);

  return { lang, changeLanguage };
};
