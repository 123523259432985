import { IDefaultElement } from "core/types/element";

import { TextInputConfig } from "../types";

export const defaultElement: IDefaultElement<TextInputConfig> = {
  i18n: {
    en: {
      label: "",
      helperText: "",
    },
  },
};
