import { handleActions } from "core/utils/redux";

import { Tabs } from "../types";

import { IState, Types } from "./types";

export function buildReducer(types: Types, element: Tabs) {
  const INITIAL_STATE: IState = {
    indexSelected: element.config.default,
  };

  return handleActions<IState>(INITIAL_STATE, {
    [types.SELECT]: (state, action) => ({
      ...state,
      indexSelected: action.payload.index,
    }),
  });
}
