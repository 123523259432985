import { memo } from "react";
import Checkbox from "@mui/material/Checkbox";
import { IElementComponentProps } from "core";

import { useStyles } from "./style";

interface IProps extends IElementComponentProps {
  value: boolean | null;
}

const DefaultBoolField = memo<IProps>(({ value }) => {
  const {
    classes: { root },
  } = useStyles();
  return (
    <Checkbox
      disabled={true}
      indeterminate={value === null}
      checked={value ?? undefined}
      className={root}
    />
  );
});

export default DefaultBoolField;
