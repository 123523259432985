import { makeStyles } from "tss-react/mui";
import { DEFAULT_GRID_COLUMN_COUNT, GRID_SPACING_FACTOR } from "./StaticGrid";
import { SPACING_MULTIPLICATOR } from "./utils";

type Props = {
  row: number;
  column: number;
  width: number;
  height: number;
  isFormGrid: boolean;
};

const setEnd = (end: number) => `span ${end}`;

export const useStyles = makeStyles<Omit<Props, "isFormGrid">>()(
  (_theme, { row, column, width, height }) => ({
    root: {
      gridColumnStart: column,
      gridColumnEnd: setEnd(width),
      gridRowStart: row,
      gridRowEnd: setEnd(height),
    },
  }),
);

export const useStaticGridStyles = makeStyles<Pick<Props, "isFormGrid">>()(
  (theme, { isFormGrid }) => ({
    root: {
      display: "grid",
      // https://css-tricks.com/preventing-a-grid-blowout/
      gridTemplateColumns: `repeat(${DEFAULT_GRID_COLUMN_COUNT}, minmax(0, 1fr))`,
      gridAutoRows: theme.spacing(SPACING_MULTIPLICATOR(isFormGrid)),
      gridGap: theme.spacing(GRID_SPACING_FACTOR),
    },
  }),
);
