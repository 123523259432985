import { createUseTranslation } from "../../../session/translation";

export const labelEditorTranslation = {
  en: {
    label: "Label",
  },
};

export const useLabelEditorTranslation = createUseTranslation(
  labelEditorTranslation,
);
