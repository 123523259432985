import { selectorScoper } from "core/utils/redux";
import { getDisabledValueFromConfig } from "elementTypes/common/utils.ts";
import { FileInput } from "elementTypes/storage_file_input/types";

import { IState } from "../controlled/types";

export function buildSelectors(path: string[], element: FileInput) {
  const scopeSelector = selectorScoper<IState>(path);

  const errors = (state: any) => scopeSelector(state).errors;
  const file = (state: any) => scopeSelector(state).file || null;
  const loading = (state: any) => scopeSelector(state).loading;
  const metadata = (state: any) => scopeSelector(state).metadata;
  const metadataError = (state: any) => scopeSelector(state).metadataError;
  const required = () => element.config.nullable === false;
  const touched = (state: any) => scopeSelector(state).touched;
  const uploadError = (state: any) => scopeSelector(state).error;
  const value = (state: any) => scopeSelector(state).value;

  const disabled = (state?: any) =>
    getDisabledValueFromConfig(state, element.config);

  return {
    errors,
    file,
    loading,
    metadata,
    metadataError,
    required,
    touched,
    uploadError,
    value,
    disabled,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
