import { Type } from "./Type";

export class TypeError extends Error {
  constructor(
    public readonly value: any,
    public readonly type: Type,
    public readonly detailMessage?: string,
  ) {
    super(
      `Invalid value ${value}. Expected a value of type ${type.name}${
        detailMessage ? `\n${detailMessage}` : ""
      }`,
    );
  }
}
