import { memo } from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import BackButton from "elementTypes/common/BackButton";

import { RoutePaths } from "staticPages/routes";
import { LoadingComponent } from "../../../../../../layouts/common/Loading";
import { useLoginConfig } from "../../../../../../queries/admin";

import { Form } from "./components/Form";

export const EditSettingsPage = memo(() => {
  const { data: config, isInitialLoading } = useLoginConfig();

  return (
    <>
      <Box display="flex" alignItems="center" gap={1}>
        <BackButton isIcon href={RoutePaths.AdminSettings} />
        <Typography variant="h5">Edit Login Page Layout</Typography>
      </Box>
      {isInitialLoading && <LoadingComponent />}
      {config && <Form config={config} />}
    </>
  );
});
