import { UntransformedTableConfig } from "./types";

export const getQueriesFromConfig = (config: UntransformedTableConfig) => [
  config.dataSource.viewName,
  ...Object.values(config.dataSource.references || []).map(
    (ref) => ref.viewName,
  ),
];

export const getReferencesFromArray = <K extends string>(
  key: K,
  values: Record<string, unknown>[],
  referencedData: Record<K, Record<string, unknown>[] | null> | null,
  identifierName: string,
) => {
  const refArray = referencedData?.[key] ?? [];
  return refArray.length
    ? values.reduce(
        (acc, curr) => {
          const reference = refArray.find(
            (ref) => ref?.[identifierName] === curr,
          );
          return reference ? [...acc, reference] : acc;
        },
        [] as Record<string, unknown>[],
      )
    : [];
};
