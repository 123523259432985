export const essos = {
  version: 1,
  themeName: "essos",
  theme: {
    color: ["#893448", "#d95850", "#eb8146", "#ffb248", "#f2d643", "#ebdba4"],
    backgroundColor: "rgba(242,234,191,0.15)",
    textStyle: {},
    title: {
      textStyle: {
        color: "#893448",
      },
      subtextStyle: {
        color: "#d95850",
      },
    },
    line: {
      itemStyle: {
        borderWidth: "2",
      },
      lineStyle: {
        width: "2",
      },
      symbolSize: "6",
      symbol: "emptyCircle",
      smooth: true,
    },
    radar: {
      itemStyle: {
        borderWidth: "2",
      },
      lineStyle: {
        width: "2",
      },
      symbolSize: "6",
      symbol: "emptyCircle",
      smooth: true,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: "#ccc",
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#ccc",
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#ccc",
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#ccc",
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#ccc",
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#ccc",
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#ccc",
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#ccc",
      },
    },
    candlestick: {
      itemStyle: {
        color: "#eb8146",
        color0: "transparent",
        borderColor: "#d95850",
        borderColor0: "#58c470",
        borderWidth: "2",
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#ccc",
      },
      lineStyle: {
        width: 1,
        color: "#aaaaaa",
      },
      symbolSize: "6",
      symbol: "emptyCircle",
      smooth: true,
      color: ["#893448", "#d95850", "#eb8146", "#ffb248", "#f2d643", "#ebdba4"],
      label: {
        color: "#ffffff",
      },
    },
    map: {
      itemStyle: {
        areaColor: "#f3f3f3",
        borderColor: "#999999",
        borderWidth: 0.5,
      },
      label: {
        color: "#893448",
      },
      emphasis: {
        itemStyle: {
          areaColor: "#ffb248",
          borderColor: "#eb8146",
          borderWidth: 1,
        },
        label: {
          color: "#893448",
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: "#f3f3f3",
        borderColor: "#999999",
        borderWidth: 0.5,
      },
      label: {
        color: "#893448",
      },
      emphasis: {
        itemStyle: {
          areaColor: "#ffb248",
          borderColor: "#eb8146",
          borderWidth: 1,
        },
        label: {
          color: "#893448",
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: "#aaaaaa",
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: "#333",
        },
      },
      axisLabel: {
        show: true,
        color: "#999999",
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#e6e6e6"],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: "#aaaaaa",
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: "#333",
        },
      },
      axisLabel: {
        show: true,
        color: "#999999",
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#e6e6e6"],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: "#aaaaaa",
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: "#333",
        },
      },
      axisLabel: {
        show: true,
        color: "#999999",
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#e6e6e6"],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: "#aaaaaa",
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: "#333",
        },
      },
      axisLabel: {
        show: true,
        color: "#999999",
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#e6e6e6"],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
        },
      },
    },
    toolbox: {
      iconStyle: {
        borderColor: "#999999",
      },
      emphasis: {
        iconStyle: {
          borderColor: "#666666",
        },
      },
    },
    legend: {
      textStyle: {
        color: "#999999",
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: "#cccccc",
          width: 1,
        },
        crossStyle: {
          color: "#cccccc",
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: "#893448",
        width: 1,
      },
      itemStyle: {
        color: "#893448",
        borderWidth: 1,
      },
      controlStyle: {
        color: "#893448",
        borderColor: "#893448",
        borderWidth: 0.5,
      },
      checkpointStyle: {
        color: "#eb8146",
        borderColor: "#ffb248",
      },
      label: {
        color: "#893448",
      },
      emphasis: {
        itemStyle: {
          color: "#ffb248",
        },
        controlStyle: {
          color: "#893448",
          borderColor: "#893448",
          borderWidth: 0.5,
        },
        label: {
          color: "#893448",
        },
      },
    },
    visualMap: {
      color: [
        "#893448",
        "#d95850",
        "#eb8146",
        "#ffb248",
        "#f2d643",
        "rgb(247,238,173)",
      ],
    },
    dataZoom: {
      backgroundColor: "rgba(255,255,255,0)",
      dataBackgroundColor: "rgba(255,178,72,0.5)",
      fillerColor: "rgba(255,178,72,0.15)",
      handleColor: "#ffb248",
      handleSize: "100%",
      textStyle: {
        color: "#333",
      },
    },
    markPoint: {
      label: {
        color: "#ffffff",
      },
      emphasis: {
        label: {
          color: "#ffffff",
        },
      },
    },
  },
};
