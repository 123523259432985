import { memo } from "react";
import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "elementTypes/common/IconButton";
import { TableRow as CustomTableRow } from "staticPages/admin/common";
import { useViewsTableTranslation } from "../translation.ts";
import { TViewsTableRow } from "../types.ts";

export const ViewsTableRow = memo<TViewsTableRow>(
  ({
    id,
    title,
    isSelected,
    handleShowDialog,
    handleSelect,
    isSubtable = false,
  }) => {
    const { showDetailsLabel } = useViewsTableTranslation();

    const content = [
      <Checkbox
        key={`${id}-Checkbox-${isSubtable}`}
        checked={isSelected}
        onClick={handleSelect(id)}
      />,
      <Typography key={`${id}-title-${isSubtable}`} component="span">
        {title}
      </Typography>,
      <IconButton
        key={`${id}-actions-${isSubtable}`}
        icon="launch"
        onClick={handleShowDialog}
        tooltip={showDetailsLabel}
      />,
    ];

    const subTableRow = (
      <CustomTableRow rowId={id} key={id}>
        {...content}
      </CustomTableRow>
    );

    const row = (
      <CustomTableRow rowId={id} key={id}>
        <Typography component="span" width="5px" display="flex" />
        {...content}
      </CustomTableRow>
    );

    return isSubtable ? subTableRow : row;
  },
);
