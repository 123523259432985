import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  link: {
    cursor: "pointer",
  },
  disabled: {
    cursor: "not-allowed",
    color: theme.palette.grey[500],
  },
}));

export default useStyles;
