import * as t from "io-ts";
import { selectors } from "core/editor/reduxModule";
import { Type, buildObjectViewType, types } from "core/runtime-typing";
import {
  IElement,
  SelectorTypes,
  UntransformedConfig,
  customExpression,
} from "core/types";
import { FixedFilterGroup } from "elementTypes/default_table/toolsPanel";
import { ContentTypeConfig } from "services/api/types/ContentType";

export const MetaQueryConfig = t.type({
  dataSource: t.intersection([
    t.type({
      queryName: t.string,
    }),
    t.partial({
      responseFormat: ContentTypeConfig,
    }),
  ]),
  filter: customExpression(t.union([FixedFilterGroup, t.null])),
  initialLoad: t.boolean,
  loadOnChange: t.boolean,
});

export const metaQuerySelectors: SelectorTypes<MetaQueryConfig> = {
  loading: types.boolean(
    "Indicates if the data is in process of being loaded from the server",
  ),
  error: types.nullable(types.string()),
  data: ({ config, state }) => {
    let type: Type;
    const queryName = config.dataSource.queryName;
    const viewList = selectors.viewList(state);
    const responseType =
      typeof config.dataSource.responseFormat === "function"
        ? config.dataSource.responseFormat(state)
        : config.dataSource.responseFormat;
    if (!viewList) {
      // view list still loading, do not show any typing errors
      type = types.any();
    } else if (!queryName) {
      type = types.optional(types.null(), "no source query set");
    } else {
      if (responseType && ["geo", "text"].includes(responseType)) {
        // TODO: TYpe GEO response: https://postgrest.org/en/v12/how-tos/working-with-postgresql-data-types.html#postgis
        type = types.any();
      } else {
        const query = viewList.find((v) => v.name === queryName);
        if (!query) {
          throw new Error(`Invalid query ${queryName}`);
        }
        type = types.array(buildObjectViewType(query));
      }
    }
    return types.nullable(type);
  },
};

export type MetaQueryConfig = t.TypeOf<typeof MetaQueryConfig>;

export type UntransformedMetaQueryConfig = UntransformedConfig<MetaQueryConfig>;

export const MetaQueryTranslationKeys = ["label"] as const;

export type MetaQueryTranslationKeys =
  (typeof MetaQueryTranslationKeys)[number];

export type MetaQuery = IElement<MetaQueryConfig, {}, MetaQueryTranslationKeys>;
