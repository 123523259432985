import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Table",
      description: "Display data in a table grid",
    },
    de: {
      description: "Daten in tabularischer Form anzeigen",
    },
  },
  defaultSize: {
    width: 4,
    height: 4,
  },
  minSize: {
    width: 4,
    height: 4,
  },
};
