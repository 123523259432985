import * as t from "io-ts";
import { IElement, UntransformedConfig, customExpression } from "core/types";

export const BoolFieldConfig = t.type({
  value: customExpression(t.union([t.boolean, t.null])),
});

export type BoolFieldConfig = t.TypeOf<typeof BoolFieldConfig>;

export type UntransformedBoolFieldConfig = UntransformedConfig<BoolFieldConfig>;

export type BoolField = IElement<BoolFieldConfig>;
