import { createColorSelector } from "core";

import { ClipboardCopyButton } from "../types";

export function buildSelectors(element: ClipboardCopyButton) {
  const icon = (state: any) => element.config.icon?.(state);
  const color = createColorSelector(element.config.color);

  return {
    icon,
    color,
  };
}
