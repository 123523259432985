import { lazy, memo, useMemo, useRef, useState } from "react";
import { Typography } from "@mui/material";
import { Editor, EditorConfiguration } from "codemirror";
import merge from "lodash/merge";
import pick from "lodash/pick";
import { Controlled as CodeMirror, ICodeMirror } from "react-codemirror2";
import { RefCallBack } from "react-hook-form";

import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/display/fullscreen.css";
import "codemirror/addon/display/fullscreen";
import "codemirror/lib/codemirror.css";

import "codemirror/mode/css/css";
import "codemirror/mode/sql/sql";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/xml/xml";
import "codemirror/mode/python/python";
import "codemirror/mode/yaml/yaml";
import "codemirror/mode/markdown/markdown";

import { ASTERISK_SYMBOL } from "../utils";

import "./javascript-hint";

import { IAugmentedHint } from "./javascript-hint";
import { useStyles } from "./style";

import "./codemirror-mode-regex.ts";
import "./regex-styles.css";

const HintInfo = lazy(() =>
  import("./HintInfo").then((module) => ({
    default: module.HintInfo,
  })),
);

type CodeEditor = {
  value: string;
  language: string;
  onChange: (editor: any, data: any, value: string) => void;
  disableAutocomplete?: boolean;
  onAutoComplete?: string | false | ((cm: any) => void);
  theme?: string;
  className?: string;
  autofocus?: boolean;
  lineNumbers?: boolean;
  options?: Partial<EditorConfiguration>;
  label?: string;
  required?: boolean;
  stylesAsMuiInput?: boolean;
  bordered?: boolean;
  innerRef?: RefCallBack;
  error?: string;
} & ICodeMirror;

export const CodeEditor = memo<CodeEditor>(
  ({
    value,
    disableAutocomplete,
    onAutoComplete,
    language,
    theme = "default",
    className,
    autofocus,
    lineNumbers,
    options = {},
    label,
    stylesAsMuiInput,
    required,
    bordered = true,
    error,
    onChange,
    innerRef,
    ...rest
  }) => {
    const { classes, cx } = useStyles({ bordered });
    const hintRef = useRef<HTMLDivElement>(null);
    const [selectedHint, setSelectedHint] = useState<null | IAugmentedHint>();

    const [focused, setFocus] = useState(false);

    const extraKeys = useMemo(() => {
      const result: Record<string, unknown> = {
        F11: (cm: CodeMirror.Editor) => {
          cm.setOption("fullScreen", !cm.getOption("fullScreen"));
        },
        Esc: (cm: CodeMirror.Editor) => {
          if (cm.getOption("fullScreen")) {
            cm.setOption("fullScreen", false);
          }
        },
        ...(!disableAutocomplete &&
          onAutoComplete && {
            "Ctrl-Space": onAutoComplete,
          }),
      };

      return result;
    }, [disableAutocomplete, onAutoComplete]);

    /**
     * We avoid a real deep merge because there might be special proxy objects that simulate Arrays, and that breaks
     * lodash's merge
     */
    const mergeOptions = {
      mode: language,
      theme,
      lineNumbers,
      extraKeys,
      autofocus,
      tabSize: 2,
    };
    const fullOptions: EditorConfiguration = {
      ...options,
      ...merge(mergeOptions, pick(options, Object.keys(mergeOptions))),
      hintOptions: {
        ...options.hintOptions,
        completeSingle: false,
        select: (hint: any) => setSelectedHint(hint),
        close: () => setSelectedHint(null),
        container: hintRef.current,
        showHint: true,
      } as any,
    };

    const editorDidMount = (editor: Editor) => {
      setTimeout(() => editor.refresh(), 0);
    };

    const onKeyEvent = (editor: Editor, event: any) => {
      if (!disableAutocomplete && onAutoComplete) {
        setTimeout(() => editor.showHint(event), 0);
      }
    };

    return (
      <>
        <div
          className={cx("CodeEditor-hints-container", classes.hintsContainer)}
          ref={hintRef}
          style={{ position: "fixed", zIndex: 5000 }}
        />
        <div
          className={cx(classes.root, {
            [classes.adaptToMuiStyles]: stylesAsMuiInput,
          })}
        >
          <fieldset
            className={cx(classes.fieldset, {
              [classes.focused]: focused,
              [classes.hasError]: !!error,
            })}
          >
            {label && (
              <legend>
                <Typography
                  variant="caption"
                  component="label"
                  color={error ? "error" : focused ? "primary" : undefined}
                >
                  {label}
                  {required && ASTERISK_SYMBOL}
                </Typography>
              </legend>
            )}
          </fieldset>
          <CodeMirror
            ref={innerRef}
            {...rest}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            editorDidMount={editorDidMount}
            value={value}
            options={fullOptions}
            onBeforeChange={onChange}
            onChange={() => ({})}
            className={cx(className, "code-editor", classes.codeMirror)}
            onKeyPress={onKeyEvent}
          />
          <HintInfo
            hint={selectedHint}
            anchorEl={hintRef.current && (hintRef.current.firstChild as any)}
            bordered={bordered}
          />
        </div>
      </>
    );
  },
);
