import { createAction, createActionTypeScoper } from "core/utils/redux";

import { ValueObject } from "../../../common/Autocomplete/utils.tsx";

export const buildUncontrolledActions = (path: string[]) => {
  const scope = path.join(".");
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    LOAD_OPTIONS: scopeActionType("LOAD_OPTIONS"),
    LOAD_OPTIONS_SUCCESS: scopeActionType("LOAD_OPTIONS_SUCCESS"),
    LOAD_OPTIONS_ERROR: scopeActionType("LOAD_OPTIONS_ERROR"),
    SEARCH_INPUT_VALUE_CHANGE: scopeActionType("SEARCH_INPUT_VALUE_CHANGE"),
    SET_VALUE_OBJECT: scopeActionType("SET_VALUE_OBJECT"),
  };

  const actions = {
    changeSearchInputValue: createAction(
      types.SEARCH_INPUT_VALUE_CHANGE,
      (searchInputValue: string | null) => ({ searchInputValue }),
    ),
    loadOptions: createAction(
      types.LOAD_OPTIONS,
      (setInputValue?: boolean) => ({ setInputValue }),
    ),
    loadOptionsSuccess: createAction(
      types.LOAD_OPTIONS_SUCCESS,
      (
        options: Record<string, unknown>[][] | null,
        rawOptions: Record<string, unknown>[][] | null,
        moreDataAvailable = false,
      ) => ({
        options,
        rawOptions,
        moreDataAvailable,
      }),
    ),
    loadOptionsError: createAction(types.LOAD_OPTIONS_ERROR, (error: any) => ({
      error,
    })),
    setValueObject: createAction(
      types.SET_VALUE_OBJECT,
      (valueObject: ValueObject) => ({
        valueObject,
      }),
    ),
  };

  return { actions, types };
};
