import { createUseTranslation } from "core/session/translation";

export const circularProgressEditorTranslation = {
  en: {
    stylingTitle: "Styling",
    translationLabel: "Translation",
    labelLabel: "Label",
    sizeInputLabel: "Size",
    thicknessInputLabel: "Thickness",
    colorInputLabel: "Color",
    variantInputLabel: "Variant",
    valueLabel: "Value",
  },
};

export const useCircularProgressEditorTranslation = createUseTranslation(
  circularProgressEditorTranslation,
);
