import { ChangeEvent, memo, useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { Section, useElementEditorContext } from "core/editor";

import { UntransformedDateTimeFieldConfig } from "../../types";
import { useDateTimeFieldEditorTranslation } from "../translation";

export const FormattingComponent = memo(() => {
  const {
    elementModel: {
      config: { isRelative = false },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDateTimeFieldConfig>();
  const translation = useDateTimeFieldEditorTranslation();
  const handleIsRelativeInputChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: boolean) =>
      changeConfigValue("isRelative", newValue),
    [changeConfigValue],
  );

  return (
    <Section title={"Formatting"} wrapped={true}>
      <FormGroup row={true}>
        <FormControlLabel
          control={
            <Switch
              checked={isRelative}
              onChange={handleIsRelativeInputChange}
            />
          }
          label={translation.isRelativeLabel}
        />
      </FormGroup>
    </Section>
  );
});
