import { Box } from "@mui/material";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridColType,
} from "@mui/x-data-grid";
import { GeneralType } from "core";

import { CustomColumnProps } from "./component";
import { CustomField, CustomInput } from "./components";

export const getGridColumnType = (type: string): GridColType => {
  switch (type) {
    case "boolean":
    case "number":
    case "date":
    case "dateTime":
      return type;
    case "integer":
      return "number";
    default:
      return "string";
  }
};

export const getDefaultAlign = (index?: number) => ({
  align: index === 0 ? "left" : ("center" as GridAlignment),
  headerAlign: index === 0 ? "left" : ("center" as GridAlignment),
});

export const getCustomColumnProps = ({
  generalType,
  component,
  editComponent,
  index,
  ...rest
}: CustomColumnProps & { generalType: GeneralType }): Partial<GridColDef> &
  CustomColumnProps => ({
  type: getGridColumnType(generalType.type),
  ...((shouldRenderCustom(generalType) || component || editComponent) && {
    renderEditCell,
    renderCell,
  }),
  component,
  editComponent,
  generalType,
  sortable: !generalType?.isArray && generalType?.type !== "json",
  ...getDefaultAlign(index),
  ...rest,
});

export const shouldRenderCustom = (generalType: GeneralType) =>
  generalType.isArray || generalType.type !== "boolean";

export const renderCell = (params: GridCellParams) => (
  <CustomField {...params} />
);

export const renderEditCell = (params: GridCellParams) => (
  <Box px={2} width="100%" height="100%">
    <CustomInput {...params} />
  </Box>
);
