import { Theme } from "@mui/material";

import { makeStyles } from "tss-react/mui";

const commonQueryStyles = {
  height: "100%",
  minHeight: "0",
};

export const useStyles = makeStyles()((theme: Theme) => ({
  cardHeight: {
    flex: "1 1 50%",
  },
  card: {
    display: "flex",
    flexDirection: "column",
  },
  cardHeader: {
    paddingBlock: theme.spacing(0.5),
  },
  treeStyle: {
    ...commonQueryStyles,
    overflow: "auto",
  },
  listStyle: commonQueryStyles,
  row: {
    "&$oddRow": { backgroundColor: theme.palette.grey[100] },

    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  oddRow: {},
  deleteRow: {
    justifyContent: "flex-end",
  },
  textOverflow: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  queryHeaderAvatar: {
    marginRight: theme.spacing(0.5),
  },
  queryHeaderAction: {
    marginTop: 0,
  },
  scrollableCard: {
    overflowY: "auto",
  },
  iconContainer: {
    width: "0 !important",
  },
}));
