import {
  MouseEvent,
  Reducer,
  memo,
  useCallback,
  useMemo,
  useReducer,
} from "react";
import { Tip } from "common/elements/Tip.tsx";
import { DEFAULT_LANGUAGE_CODE, Translation } from "core";
import { IsTrialTooltipTitle } from "elementTypes/common/TrialLabel";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { DialogWrapperProps } from "elementTypes/helpers/HOC/DialogWrapper/component.tsx";
import {
  QueryKeys,
  useColumnAliasQuery,
  useCreateDefaultQueries,
  useCreateDefaultQuery,
  useHistoryTrackingSwitch,
  useQueryGroups,
  useWorkflowSetup,
} from "queries/admin";
import { getApiError } from "queries/utils.ts";
import { useQueryClient, useRoute, useSnackbar } from "utils/hooks";
import { StyledSchemaTableName } from "../../components/StyledSchemaTableName.tsx";
import { DatabasePanelType } from "../../components/types.ts";
import { AuditTableSetup } from "../../erd/AuditTableSetup.tsx";
import { GenerateQueriesComponent } from "../../erd/GenerateQueriesComponent.tsx";
import { KeyLookup } from "../../erd/KeyLookup.tsx";
import { QueryBuilder } from "../../erd/QueryBuilder.tsx";
import { useStyles } from "../../erd/styles.ts";
import { TableDetails } from "../../erd/TableDetails.tsx";
import { useERDTranslation } from "../../erd/translation.ts";
import { NodeData, WorkflowSetupAPIData } from "../../erd/types.ts";
import {
  sortAndFilterColumns,
  useNotAutogeneratedTables,
} from "../../erd/utils.ts";
import { ViewNodeData } from "../../erd/ViewNode.tsx";
import {
  WorkflowAlertComponent,
  MemoizedWorkflowSetup as WorkflowSetup,
} from "../../erd/WorkflowSetup.tsx";
import { DatabasePanelPermissionProvider } from "../databasePanelPermission";
import { useDatabasePanelContext } from "../databasePanelPermission/DatabasePanelContext.utils.ts";
import { usePermissionContext } from "../databasePanelPermission/PermissionContext.utils.ts";
import { ContextProps, NodeMenuDispatch, NodeMenuState } from "../types.ts";
import ContextMenu from "./components/ContextMenu.tsx";
import {
  DialogType,
  ERDActions,
  ERDInitialState,
  WORKFLOW_URL,
} from "./consts.ts";
import { ERDProvider } from "./ERDContext.utils.ts";
import { erdReducer } from "./utils.ts";

export const DatabaseProvider = memo<ContextProps>(
  ({ children, isInternal }) => (
    <DatabasePanelPermissionProvider>
      <Provider isInternal={isInternal}>{children}</Provider>
    </DatabasePanelPermissionProvider>
  ),
);

const Provider = memo<ContextProps>(({ children, isInternal }) => {
  const [{ nodeData, open, anchorEl }, dispatchAction] = useReducer<
    Reducer<NodeMenuState, NodeMenuDispatch>
  >(erdReducer, ERDInitialState);
  const { invalidateQueries, queryClient } = useQueryClient();
  const route = useRoute();
  const showSnackbar = useSnackbar();
  const translation = useERDTranslation();
  const { classes } = useStyles({ selected: false });

  const { setType: setDatabasePanelType } = useDatabasePanelContext();
  const { permissions, setPermissions } = usePermissionContext();

  const { data: queryGroups = [] } = useQueryGroups({ enabled: !isInternal });

  const handleError = (error: unknown) => {
    const msg = getApiError(error);
    showSnackbar(msg, "error");
  };

  const { mutate: mutateWorkflow } = useWorkflowSetup({
    onError: handleError,
    onSuccess: () => {
      handleOpenWorkflow();
    },
  });

  const createQuery = useCreateDefaultQuery({
    onSuccess: () => {
      showSnackbar(translation.createQuerySuccessMsg, "success");
      invalidateQueries([QueryKeys.fetchModel, QueryKeys.fetchQueries]);

      handleCloseDialog();
      setDatabasePanelType(null, DatabasePanelType.queries);
    },
    onError: handleError,
  });

  const createQueries = useCreateDefaultQueries({
    onSuccess: () => {
      showSnackbar(translation.createQueriesSuccessMsg, "success");
      invalidateQueries([QueryKeys.fetchModel, QueryKeys.fetchQueries]);

      handleCloseDialog();
      setDatabasePanelType(null, DatabasePanelType.queries);
    },
    onError: handleError,
  });

  const columnAliasQuery = useColumnAliasQuery({
    onSuccess: (res) => {
      showSnackbar(
        `"${res.labelColumn} "${translation.setLookupColumn}`,
        "success",
      );
      invalidateQueries([QueryKeys.fetchModel]);
      handleCloseDialog();
      setDatabasePanelType(null, DatabasePanelType.entities);
    },
    onError: handleError,
  });

  const { mutate: switchHistoryTracking } = useHistoryTrackingSwitch({
    onError: handleError,
    onSuccess: (_, variables) => {
      showSnackbar(
        variables.active
          ? translation.auditingEnabled
          : translation.auditingDisabled,
        "success",
      );
      invalidateQueries([QueryKeys.fetchModel]);

      handleCloseDialog();
      setDatabasePanelType(null, DatabasePanelType.entities);
    },
  });

  const handleOpenMenu = useCallback(
    (newNodeData: NodeData | ViewNodeData) =>
      (event?: MouseEvent<HTMLElement> | null) => {
        dispatchAction({
          type: ERDActions.OPEN_MENU,
          payload: {
            anchorEl: event?.currentTarget ? event?.currentTarget : anchorEl,
            nodeData: newNodeData,
          },
        });
      },
    [anchorEl],
  );

  const handleOpenDialog = useCallback(
    (type: DialogType, dialogData?: ViewNodeData | NodeData) =>
      (ev?: MouseEvent<HTMLElement>) => {
        dispatchAction({
          type: ERDActions.OPEN_DIALOG,
          payload: {
            open: type,
          },
        });
        if (dialogData) {
          handleOpenMenu(dialogData)(ev);
        }
      },
    [nodeData],
  );

  const isFetchingModel = Boolean(
    queryClient.isFetching([QueryKeys.fetchModel]) ||
      queryClient.isMutating({ mutationKey: [QueryKeys.deleteQuery] }),
  );

  const workflowConfigured = useMemo(
    () =>
      Boolean(nodeData && nodeData?.columns.find((e) => e.workflowConfigured)),
    [nodeData],
  );

  const handleCloseDialog = useCallback(() => {
    setPermissions({});
    dispatchAction({
      type: ERDActions.SET_INITIAL_STATE,
    });
  }, [setPermissions]);

  const handleOpenWorkflow = () => {
    if (!nodeData) {
      return;
    }
    const params = new URLSearchParams();
    params.set("schema", nodeData.schemaName);
    params.set("table", (nodeData as NodeData).tableName);

    const url = `${WORKFLOW_URL}?${params.toString()}`;
    route("push", url);

    handleCloseDialog();
  };

  const allowedWorkflowColumns = sortAndFilterColumns(nodeData?.columns ?? []);

  const onWorkflowSubmit = (workflowData: WorkflowSetupAPIData) => {
    if (!nodeData) {
      return;
    }
    // gkobluk: remove this once translation turns optional:
    const payload = {
      stateColumn: workflowData.stateColumn,
      transitionToAll: workflowData.transitionToAll,
      cleanUpPrevious: workflowData.cleanUpPrevious,
    } as WorkflowSetupAPIData;

    mutateWorkflow({
      schema: nodeData.schemaName,
      table: (nodeData as NodeData).tableName,
      workflowSetupAPIData: payload,
    });
  };

  const handleGenerateQuery = async (generateQueryData: {
    name: string;
    title: string;
  }) => {
    if (!nodeData) {
      return;
    }

    const title = generateQueryData.title.trim();

    const updated = {
      schema: nodeData.schemaName,
      table: (nodeData as NodeData).tableName,
      data: {
        viewName: generateQueryData.name,
        permissions: Object.entries(permissions).map(
          ([grantee, privileges]) => ({
            grantee,
            privileges,
          }),
        ),
        i18n: {
          [DEFAULT_LANGUAGE_CODE]: {
            title,
          },
        } as Translation<"title">,
        queryGroupId: null,
      },
    };

    createQuery.mutate(updated);
  };

  // tables that have no generated queries
  const filteredTables = useNotAutogeneratedTables();

  const handleGenerateQueries = () => {
    if (Object.keys(filteredTables ?? {}).length) {
      createQueries.mutateAsync({
        permissions: Object.entries(permissions).map(
          ([grantee, privileges]) => ({
            grantee,
            privileges,
          }),
        ),
        tables: Object.entries(filteredTables).reduce(
          (res, [schemaName, tableObj]) => ({
            ...res,
            [schemaName]: Object.values(tableObj).map((to) => to.table),
          }),
          {},
        ),
      });
    } else {
      handleCloseDialog();
    }
  };

  const handleColumnAliasQuery = (aliasQueryData: {
    defaultLookup: string;
  }) => {
    if (!nodeData) {
      return;
    }

    const updated = {
      schema: nodeData.schemaName,
      table: (nodeData as NodeData).tableName,
      column: aliasQueryData.defaultLookup,
    };

    columnAliasQuery.mutate(updated);
  };

  const handleSwitchHistoryTracking = () => {
    if (!nodeData) {
      return;
    }
    const updated = {
      schema: nodeData.schemaName,
      table: (nodeData as NodeData).tableName,
      active: !(nodeData as NodeData).historyTrackingConfigured,
    };

    switchHistoryTracking(updated);
  };

  const dialogsProps = {
    [DialogType.workflow]: {
      title: (
        <>
          {translation.workflowDialogTitle}
          <Tip text={translation.workflowSelectTooltip} />
        </>
      ),
      maxWidth: "sm",
      contentProps: {
        sx: { padding: 0, px: 0.5 },
      },
      submitTitle: null,
      ...(!!allowedWorkflowColumns.length && {
        isForm: true,
        handleSubmit: onWorkflowSubmit,
        submitTitle: translation.submitTitle,
      }),
    },
    [DialogType.generateQuery]: {
      title: translation.generateQueryTitle,
      contentText: nodeData ? (
        <StyledSchemaTableName
          schemaName={nodeData.schemaName}
          tableName={(nodeData as NodeData).tableName}
        />
      ) : null,
      isForm: true,
      handleSubmit: handleGenerateQuery,
    },
    [DialogType.generateDefaultQueries]: {
      title: translation.generateAllQueriesTitle,
      contentText: (
        <IsTrialTooltipTitle title={translation.generateAllQueriesSubTitle} />
      ),
      handleSubmit: handleGenerateQueries,
    },
    [DialogType.defineLookups]: {
      title: `${translation.defineKeyLookupsTitle} ${nodeData?.schemaName}.${
        (nodeData as NodeData)?.tableName
      }`,
      isForm: true,
      handleSubmit: handleColumnAliasQuery,
    },
    [DialogType.auditTable]: {
      actionsClass: classes.auditTableActions,
      title: translation.auditTableTitle,
      isForm: true,
      submitTitle: (nodeData as NodeData)?.historyTrackingConfigured
        ? translation.auditTableDisable
        : translation.auditTableEnable,
      submitButtonProps: (nodeData as NodeData)?.historyTrackingConfigured
        ? { className: classes.errorButton }
        : {},
      handleSubmit: handleSwitchHistoryTracking,
    },
    [DialogType.tableDetails]: {
      title: `${
        "viewName" in (nodeData ?? {})
          ? translation.ViewDetailsTitle
          : translation.TableDetailsTitle
      } ${nodeData?.schemaName}.${
        (nodeData as NodeData)?.tableName ??
        (nodeData as ViewNodeData)?.viewName
      }`,
      fullWidth: true,
      maxWidth: "md",
      submitTitle: undefined,
    },
  };

  const content = {
    [DialogType.workflow]: (
      <>
        <WorkflowAlertComponent isEmpty={!allowedWorkflowColumns.length} />
        {allowedWorkflowColumns.length ? (
          <WorkflowSetup
            nodeData={
              ({
                ...nodeData,
                columns: allowedWorkflowColumns,
                stateColumn: (nodeData as NodeData)?.stateColumn,
              } as NodeData)!
            }
          />
        ) : (
          <span />
        )}
      </>
    ),
    [DialogType.generateQuery]: (
      <QueryBuilder
        nodeData={(nodeData as NodeData)!}
        queryGroups={queryGroups}
      />
    ),
    [DialogType.generateDefaultQueries]: <GenerateQueriesComponent />,
    [DialogType.defineLookups]: (
      <KeyLookup nodeData={(nodeData as NodeData)!} />
    ),
    [DialogType.auditTable]: <AuditTableSetup />,
    [DialogType.tableDetails]: nodeData && <TableDetails nodeData={nodeData} />,
  };

  const dialogWrapperProps = {
    open: Boolean(open),
    keepMounted: false,
    cancelTitle: translation.cancelTitle,
    submitTitle: translation.submitTitle,
    fullWidth: true,
    maxWidth: "md",
    handleClose: handleCloseDialog,
    ...(open && { ...dialogsProps[open] }),
  } as DialogWrapperProps;

  const providerValue = useMemo(
    () => ({
      onMenuOpen: handleOpenMenu,
      onModalOpen: handleOpenDialog,
      menuData: nodeData,
      isInternal,
    }),
    [handleOpenMenu, handleOpenDialog, nodeData, isInternal],
  );

  return (
    <ERDProvider value={providerValue}>
      {children}
      <ContextMenu
        {...{
          anchorEl,
          nodeData,
          workflowConfigured,
          isFetchingModel,
          onClose: handleCloseDialog,
          onOpenDialog: handleOpenDialog,
          onOpenWorkflow: handleOpenWorkflow,
        }}
      />
      {open && (
        <DialogWrapper {...dialogWrapperProps}>{content[open]}</DialogWrapper>
      )}
    </ERDProvider>
  );
});
