import { IStaticRouteConfig } from "core";

import { route as adminRoute } from "../admin";
import { route as loginRoute } from "../login";
import { route as maintenanceRoute } from "../maintenance";
import { route as noAppsAvailableRoute } from "../noAppsAvailable";
import { route as settingsRoute } from "../settings";
import { RoutePaths } from "./routePaths";

export const routes: Record<string, IStaticRouteConfig> = {
  [RoutePaths.Admin]: adminRoute,
  [RoutePaths.Login]: loginRoute,
  [RoutePaths.Maintenance]: maintenanceRoute,
  [RoutePaths.NoAppsAvailable]: noAppsAvailableRoute,
  [RoutePaths.Settings]: settingsRoute,
};
