import { ConnectedProps } from "react-redux";
import { IElementComponentProps } from "core";
import {
  connectElement,
  connectErrorHandlerUtils,
} from "core/utils/react-redux";

import Component from "./component";
import { ReduxModule } from "./reduxModule";
import { GeoJSONField } from "./types";

const mapStateToProps = (
  state: any,
  {
    element: { config },
  }: IElementComponentProps<Record<string, unknown>, GeoJSONField>,
) => ({
  value: config.value(state),
  getStyle:
    typeof config.styleFunction === "function"
      ? config.styleFunction?.(state)
      : config.styleFunction,
  getTooltip:
    typeof config.tooltipFunction === "function"
      ? config.tooltipFunction?.(state)
      : config.tooltipFunction,
  getMarkerBackgroundColor:
    typeof config.markerBackgroundColorFunction === "string"
      ? config.markerBackgroundColorFunction
      : config.markerBackgroundColorFunction?.(state),
  getMarkerDisplayText:
    typeof config.markerDisplayTextFunction === "string"
      ? config.markerDisplayTextFunction
      : config.markerDisplayTextFunction?.(state),
  selected: {
    property: config.selected?.property,
    propertyValue: config.selected?.propertyValue?.(state),
  },
});

const connector = connectElement<
  ReduxModule,
  GeoJSONField,
  typeof mapStateToProps
>(mapStateToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(connectErrorHandlerUtils.enhanceComponent(Component));
