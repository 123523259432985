import { fold } from "fp-ts/Either";
import * as t from "io-ts";

import { ErrorsReport, processErrors } from "utils/io-ts/errorReporter";
import { ValidationErrorObject } from "utils/io-ts/validate";
import { EXPRESSION_TAG } from "../constants";

/**
 * Attempts to retrieve the result of an `io-ts` validation.
 * Throws an error if the validation failed.
 *
 * @param validation - The `io-ts` validation result to be checked.
 * @param getError - A function that receives the validation errors and returns an Error object.
 * @returns The validated data if validation was successful.
 * @throws The error produced by `getError` if validation failed.
 */

export function getOrThrow<T>(
  validation: t.Validation<T>,
  getError: (errors: Record<string, ErrorsReport>) => ValidationErrorObject,
): T {
  return fold<t.Errors, T, T>(
    // Handle failure: Use the provided `getError` function to generate and throw an error
    (errors) => {
      throw getError(processErrors(errors));
    },
    // Handle success: Return the validated data
    (result) => result,
  )(validation);
}

/**
 * Hack the io-ts string type to treat values that start with the expression tag as invalid.
 */
export function hackStringType() {
  (t.string as any).validate = (i: any, context: t.Context) =>
    typeof i === "string" && !i.startsWith(EXPRESSION_TAG)
      ? t.success(i)
      : t.failure(i, context);
}
