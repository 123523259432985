import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()({
  root: {
    width: "100% !important",
    height: "100% !important",
  },
  createDisabled: {
    "& .leaflet-pm-toolbar.leaflet-pm-draw": {
      pointerEvents: "none",
      filter: "contrast(22%) brightness(157%)",
    },
  },
});
