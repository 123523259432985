import { ReactNode } from "react";
import * as t from "io-ts";
import { types } from "core/runtime-typing";
import {
  ConnectedReduxModuleProps,
  IElement,
  UntransformedConfig,
  arrayChild,
} from "core/types";
import { ReduxModule } from "./reduxModule";

export const TabsConfig = t.intersection([
  t.type({
    default: t.number,
  }),
  t.partial({
    labels: t.union([t.undefined, t.array(t.string)]), //! Labels left here for migration purposes, Tabs are using I18n now
    variant: t.keyof({
      standard: null,
      scrollable: null,
      fullWidth: null,
    }),
  }),
]);

export const TabsChildren = t.type({
  content: arrayChild("*", { positioned: true }),
});

export type TabsConfig = t.TypeOf<typeof TabsConfig>;

export type UntransformedTabsConfig = UntransformedConfig<TabsConfig>;

export type TabsChildren = t.TypeOf<typeof TabsChildren>;

export type Tabs = IElement<TabsConfig, TabsChildren, string>;

export const selectorTypes = {
  indexSelected: types.number(),
};

export type TabProps = ConnectedReduxModuleProps<ReduxModule, Tabs>;

export type TabPanelProps = {
  index: number;
  value: number;
  className: string;
  children?: ReactNode;
};
