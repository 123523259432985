import { memo, useState } from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import upperFirst from "lodash/upperFirst";
import { useDispatch } from "react-redux";
import { actions as routerActions } from "core/router/reduxModule";
import { useRouterParamByName } from "core/router/reduxModule/utils";
import BackButton from "elementTypes/common/BackButton";
import { HookForm } from "elementTypes/common/HookForm";
import {
  useRepositoryConfiguration,
  useTestRepositoryConfiguration,
  useUpdateRepositoryConfiguration,
} from "queries/admin/extensionsData";
import { RepositoryConfiguration } from "queries/admin/types";
import { getApiError } from "queries/utils";
import { RoutePaths } from "staticPages/routes";
import { useSnackbar } from "utils/hooks/useSnackbar";
import { useRepositoryConfigurationTranslation } from "../translation";
import { FormButtons, FormFields } from "./FormComponents";

export const UpdateConfigurationComponent = memo(() => {
  const [saveConfig, setSaveConfig] = useState<boolean>(false);
  const showSnackbar = useSnackbar();
  const dispatch = useDispatch();
  const id = useRouterParamByName("id");
  const { data, isFetching } = useRepositoryConfiguration(
    { id },
    {
      enabled: id !== "new",
    },
  );

  const defaultData = data ?? {
    userName: "",
    personalAccessToken: "",
    repositoryPath: "",
    repositoryBranch: "",
    repositoryName: "",
    repositoryBaseUrl: "https://api.github.com/",
  };

  const t = useRepositoryConfigurationTranslation();

  const { mutate } = useUpdateRepositoryConfiguration({
    onSuccess: async () => {
      setSaveConfig(false);
      showSnackbar(t.saveSuccessMessage, "success");
      dispatch(routerActions.push(RoutePaths.RepositoryConfig));
    },
    onError: (error) => {
      const msg = getApiError(error);
      const errorDetails = (error as Record<"errors", Record<string, any>[]>)
        ?.errors
        ? `${(error as Record<"errors", Record<string, any>[]>)?.errors?.map(
            (err: Record<string, any>) => {
              return err?.children?.map(
                (fieldError: Record<string, Record<string, unknown>>) => {
                  return Object.values(
                    fieldError?.constraints ?? {},
                  ).toString();
                },
              );
            },
          )}`
        : "";

      showSnackbar(`${t.saveErrorMessage} ${msg} ${errorDetails}`, "error");
    },
  });

  const { mutate: testConnection, isLoading } = useTestRepositoryConfiguration({
    onSuccess: async (
      response: boolean,
      variables: RepositoryConfiguration,
    ) => {
      const res = response ? "success" : "warning";
      if (response && saveConfig) {
        mutate(variables);
      } else {
        showSnackbar(t[`test${upperFirst(res)}Message`], res);
      }
    },
    onError: (error) => {
      const msg = getApiError(error);
      const errorDetails = (error as Record<"errors", Record<string, any>[]>)
        ?.errors
        ? `${(error as Record<"errors", Record<string, any>[]>)?.errors?.map(
            (err: Record<string, any>) => {
              return err?.children?.map(
                (fieldError: Record<string, Record<string, unknown>>) => {
                  return Object.values(
                    fieldError?.constraints ?? {},
                  ).toString();
                },
              );
            },
          )}`
        : "";

      showSnackbar(`${t.testErrorMessage} ${msg} ${errorDetails}`, "error");
    },
  });

  const handleSubmit = (config: Record<string, unknown>) => {
    setSaveConfig(true);
    const formData = Object.entries(config).reduce(
      (res, [key, value]) => ({
        ...res,
        [key]:
          key === "id"
            ? value
              ? Number(value)
              : undefined
            : String(value).trim(),
      }),
      {
        id: data?.id,
      } as RepositoryConfiguration,
    );

    testConnection(formData);
  };

  const handleTest = (testConfig: RepositoryConfiguration) => {
    setSaveConfig(false);
    testConnection(testConfig);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Box display="flex" alignItems="center">
          <BackButton isIcon href={RoutePaths.RepositoryConfig} />
          <Typography variant="h5">
            {data?.id ? t.editConfigurationTitle : t.newConfigurationTitle}
          </Typography>
        </Box>
      </Grid>
      {!isFetching && (
        <HookForm
          defaultValues={defaultData}
          onSubmit={handleSubmit}
          formOptions={{ mode: "onChange" }}
        >
          <Paper variant="outlined">
            <FormFields />
            <Divider />
            <FormButtons testConnection={handleTest} isLoading={isLoading} />
          </Paper>
        </HookForm>
      )}
    </>
  );
});
