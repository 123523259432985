import {
  PropsWithChildren,
  memo,
  startTransition,
  useCallback,
  useState,
} from "react";
import { useLocation } from "utils/hooks/useLocation.ts";
import { DatabasePanelType } from "../../components/types.ts";
import { Permissions } from "../types.ts";
import { Provider as DatabasePanelProvider } from "./DatabasePanelContext.utils.ts";
import { Provider as PermissionProvider } from "./PermissionContext.utils.ts";

export const DatabasePanelPermissionProvider = memo<PropsWithChildren>(
  ({ children }) => {
    const location = useLocation();
    const defaultValue =
      (location?.queries?.dbPanel as DatabasePanelType) ??
      DatabasePanelType.entities;
    const [permissions, setPermissions] = useState<Permissions>({});
    const [databasePanelType, setDatabasePanelType] =
      useState<DatabasePanelType>(defaultValue);

    const handleSetDatabasePanel = useCallback(
      (_ev: unknown, nextType: DatabasePanelType | null) => {
        if (nextType) {
          startTransition(() => {
            setDatabasePanelType(nextType);
          });
        }
      },
      [],
    );

    return (
      <DatabasePanelProvider
        value={{ type: databasePanelType, setType: handleSetDatabasePanel }}
      >
        <PermissionProvider value={{ permissions, setPermissions }}>
          {children}
        </PermissionProvider>
      </DatabasePanelProvider>
    );
  },
);
