import { IReduxModuleFactoryArgs } from "core";
import { FormDataSource } from "elementInterfaces";

import { SubForm } from "../types";

import { buildActions } from "./actions";
import { buildSelectors } from "./selectors";

export function reduxModuleFactory({
  element,
}: IReduxModuleFactoryArgs<SubForm>) {
  const { elementId, fieldPath } = element.config.dataSource;
  const dataSource = FormDataSource.get(elementId);

  const { actions } = buildActions(fieldPath, dataSource);
  const selectors = buildSelectors(element);

  return {
    actions,
    selectors,
  };
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
