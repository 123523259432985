import * as t from "io-ts";
import { types } from "core/runtime-typing";
import {
  IElement,
  IElementArrayChild,
  IElementSingleChild,
  UntransformedConfig,
} from "core/types";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";

import { FormInputConfig } from "../common";

export const codeInputSelectorTypes = {
  value: types.nullable(types.string()),
  disabled: types.optional(types.boolean()),
};

export const CodeInputConfig = t.intersection([
  WithOptionalFieldDataSourceConfig(t.union([t.null, t.string])),
  FormInputConfig,
  t.type({
    language: t.string,
  }),
]);

export type CodeInputConfig = t.TypeOf<typeof CodeInputConfig>;

export type UntransformedCodeInputConfig = UntransformedConfig<CodeInputConfig>;

export const CodeInputTranslationKeys = ["label"] as const;

export type CodeInputTranslationKeys =
  (typeof CodeInputTranslationKeys)[number];

export type CodeInput = IElement<
  CodeInputConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  CodeInputTranslationKeys
>;
