import { memo } from "react";
import { Box, Typography } from "@mui/material";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

import { Draggable, DroppableProvided } from "react-beautiful-dnd";
import IconButton from "elementTypes/common/IconButton";
import { useStyles } from "staticPages/admin/pages/modelBuilder/components/styles.ts";
import {
  useQueriesPanelContext,
  useQueryGroupFullDroppableContext,
} from "staticPages/admin/pages/modelBuilder/context/queriesPanel/QueriesPanelContext.utils.ts";
import { useDatabaseTranslation } from "staticPages/admin/pages/modelBuilder/translation.ts";
import { useQueryGroupStyles } from "../../styles.ts";
import { generateDraggableId } from "../../utils/dragAndDrop.ts";
import { QueryRow } from "./VirtualizedQuery.tsx";

export const FullQueryGroup = memo(() => {
  const {
    handleEditClick,
    handleDeleteClick,
    groupName,
    index,
    queryGroup,
    groupQueries,
    provided,
    isDraggingGlobal,
    snapshot: { isDraggingOver },
  } = useQueryGroupFullDroppableContext();
  const { showActions } = useQueriesPanelContext();
  const { editQueryGroupTitle, deleteQueryGroupTitle } =
    useDatabaseTranslation();
  const {
    classes: { textOverflow },
  } = useStyles();
  const {
    classes: { droppableStyle, droppablePadding },
    cx,
  } = useQueryGroupStyles();

  const nodeId = `${queryGroup?.id}`;

  return (
    <>
      <span style={{ display: "none" }}>
        {(provided as DroppableProvided).placeholder}
      </span>
      <TreeItem
        itemId={nodeId}
        key={`${queryGroup?.id}-${index}-tree-item`}
        data-testid={`query-group-${nodeId}`}
        ref={provided.innerRef}
        label={
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
            className={cx({
              [droppablePadding]: isDraggingGlobal,
              [droppableStyle]: isDraggingOver,
            })}
          >
            <Typography
              noWrap
              className={textOverflow}
              sx={{
                py: 0,
                pl: 1,
                flex: 1,
              }}
            >
              {groupName}
            </Typography>
            <Box display="flex" flexDirection="row">
              {showActions && (
                <>
                  <IconButton
                    icon="edit"
                    onClick={handleEditClick(queryGroup)}
                    size="small"
                    tooltip={editQueryGroupTitle}
                  />
                  <IconButton
                    icon="delete_outline"
                    onClick={handleDeleteClick(queryGroup)}
                    size="small"
                    tooltip={deleteQueryGroupTitle}
                  />
                </>
              )}
            </Box>
          </Box>
        }
      >
        {groupQueries.map((query, childIndex) => (
          <Draggable
            key={query.id}
            draggableId={generateDraggableId(query.id)}
            index={childIndex}
          >
            {(draggableProvided, snapshot) => (
              <QueryRow
                type="tree"
                key={`${query.id}-${childIndex}-tree-item`}
                index={childIndex}
                query={query}
                provided={draggableProvided}
                snapshot={snapshot}
              />
            )}
          </Draggable>
        ))}
      </TreeItem>
    </>
  );
});
