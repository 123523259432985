import { createUseTranslation } from "core/session/translation";

export const multipleFileInputEditorTranslation = {
  en: {
    stylingTitle: "Styling",
    labelLabel: "Label",
    advancedTitle: "Advanced",
    accessGroupNameInputLabel: "Access Group Name",
    typeGroupNameInputLabel: "Type Group Name",
    acceptInputLabel: "Accept",
  },
};

export const useMultipleFileInputEditorTranslation = createUseTranslation(
  multipleFileInputEditorTranslation,
);
