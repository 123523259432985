import { lazy, memo } from "react";
import { TMagicType } from "elementTypes/common/Echarts/Echarts";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { LoadingComponent } from "layouts/common/Loading";

import { IOptionEchartProps } from "../common/Echarts";

import { EchartsCustomProps } from "./types";

export const Echarts = withLazyLoading(
  lazy(() => import("../common/Echarts/Echarts")),
  true,
);

const EchartsCustomChart = memo<EchartsCustomProps>(
  ({
    element: {
      id,
      i18n: { title },
      config,
    },
    option,
    loading,
    selected,
    selectValue,
  }) => {
    const echartsOption: IOptionEchartProps = {
      ...option,
      title: {
        text: title,
        x: "center",
        ...(typeof option.title === "object" ? option.title : {}),
      },
    };

    const chartType = (((option?.series as any[])?.[0]?.type as string) ??
      "custom") as TMagicType;

    if (loading) {
      return <LoadingComponent />;
    }

    const chartProps = {
      option: echartsOption,
      elementId: id,
      chartType,
      selected,
      selectValue,
    } as const;

    return <Echarts {...config} {...chartProps} />;
  },
);

export default EchartsCustomChart;
