import { Implementation, createElementInterface } from "core";
import { AnyFluxStandardAction } from "core/utils/redux";

interface IChartInterface {
  refresh: () => AnyFluxStandardAction;
}

export const ChartInterface = createElementInterface<IChartInterface>();

export type ChartInterfaceImplementation = Implementation<
  typeof ChartInterface
>;
