import dagre from "dagre";
import { Edge, MarkerType, Node, Position } from "reactflow";

import { LayoutOption, LayoutOptions } from "../../../component";
import { EdgeData, NodeData, State, StateChange, Workflow } from "./types";

export const getEditableState =
  (stateChanges: StateChange[]) => (state: State) =>
    !!stateChanges.find(
      (st) =>
        st.id === state.selfStateChangeId &&
        (state.id === st.target || state.id === st.source),
    );

export const getNodeStateData = (
  s: State,
  getIsEditable: (s: State) => boolean,
) => ({
  id: s.id,
  label: s.name,
  selfStateChangeId: s.selfStateChangeId,
  isEditable: getIsEditable(s),
});

const toNode = (s: State, stateChanges: StateChange[]) => {
  const isEditable = getEditableState(stateChanges);

  return {
    id: `${s.id}`,
    data: getNodeStateData(s, isEditable),
    type: "state",
    position: { x: 0, y: 0 },
    dragHandle: ".custom-drag-handle",
  } as Node<NodeData>;
};

const toEdge = (sc: StateChange): Edge<EdgeData> => ({
  id: `${sc.id}`,
  source: sc.source === null ? "start" : `${sc.source}`,
  target: sc.target === null ? "end" : `${sc.target}`,
  animated: false,
  hidden: false,
  type: "transition",
  data: {
    i18n: sc.i18n,
    id: sc.id,
    label: "",
    selfStateChangeId: null,
    isEditable: true,
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
  },
});

const startEndNodes: Node[] = [
  {
    id: "start",
    type: "start",
    position: { x: 0, y: 0 },
    dragHandle: ".custom-drag-handle",
  } as Node,
  {
    id: "end",
    type: "end",
    position: { x: window.innerWidth * 0.8, y: 0 },
    dragHandle: ".custom-drag-handle",
  } as Node,
];

export const workflowToReactFlowElements = (workflow: Workflow) => {
  const { states, stateChanges } = workflow;
  return {
    nodes: [
      ...startEndNodes,
      ...states.map((s: State) => toNode(s, stateChanges)),
    ],
    edges: stateChanges.reduce(
      (res, sc: StateChange) =>
        sc.source !== sc.target ? [...res, toEdge(sc)] : res,
      [] as Edge[],
    ),
  };
};

export const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  directionValue: LayoutOption,
  nodePositions: Record<string, { x: number; y: number }>,
) => {
  const isHorizontal = directionValue === LayoutOptions.horizontal;
  const dagreGraph = new dagre.graphlib.Graph({
    compound: true,
    directed: true,
  });
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: directionValue });

  nodes.forEach((el, index: number) => {
    // TODO: remove hardcoded width and height
    dagreGraph.setNode(el.id, {
      width: isHorizontal ? 300 : index % 2 === 0 ? 100 : 600,
      height: isHorizontal ? (index % 2 === 0 ? 100 : 600) : 300,
    });
  });

  edges.forEach((el) => dagreGraph.setEdge(el.source, el.target));

  dagre.layout(dagreGraph);

  return {
    nodes: nodes.map((el) => {
      const prevPosition = nodePositions?.[el.id];
      const nodeWithPosition = dagreGraph.node(el.id);

      const targetPosition = isHorizontal ? Position.Left : Position.Top;
      const sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

      const position = {
        x: prevPosition?.x ?? nodeWithPosition.x,
        y: prevPosition?.y ?? nodeWithPosition.y,
      };

      return {
        ...el,
        position,
        sourcePosition,
        targetPosition,
      };
    }),
    edges: edges.map((edge) => ({
      ...edge,
      arrowHeadType: MarkerType.ArrowClosed,
    })),
  };
};
