import { memo, useState } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";
import CopyToClipboard from "react-copy-to-clipboard";
import Markdown from "react-markdown";
import { useSelector } from "react-redux";

import { useTranslation } from "core/session";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { AlertBox } from "elementTypes/common/AlertBox";
import IconButton from "elementTypes/common/IconButton";
import { StyledTypography } from "elementTypes/common/StyledTypography";
import { gitInfo } from "utils/getGitInfo";

import useStyles from "./styles";

export const HelpButton = memo(() => {
  const { classes } = useStyles();

  const applicationInformation = useSelector(sessionSelectors.ui);
  const releaseInformation = useSelector(sessionSelectors.appMetadata);

  const gitInfoDate = gitInfo.GIT_DATE;

  const i18nTranslated = useTranslation(applicationInformation?.i18n);

  const [openDialog, setOpenDialog] = useState(false);

  const handleMenu = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);

  const label = useTranslation(applicationInformation?.i18n).label;

  if (!applicationInformation) {
    return null;
  }
  if (!releaseInformation) {
    return null;
  }

  const releaseDate = format(
    new Date(releaseInformation.release.createdAt),
    "yyyy-MM-dd hh:mm",
  );

  return (
    <>
      <IconButton
        sx={{ color: "inherit" }}
        onClick={handleMenu}
        icon="help_outline"
        aria-label="about cypex and current version"
        aria-controls="appBar"
        aria-haspopup="true"
        fontSize="large"
        data-testid="about-open-button"
      />
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="About"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="about-dialog"
          className={classes.dialogTitle}
          variant="h5"
        >
          <Typography>About</Typography>
          <IconButton
            color={"inherit"}
            onClick={handleClose}
            icon={"close"}
            aria-label="close dialog"
            aria-controls="appBar"
            aria-haspopup="true"
            fontSize="large"
            data-testid="close-dialog"
            style={{ margin: "10px" }}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogRoot} dividers>
          <Stack
            display="grid"
            gridTemplateColumns="repeat(2, max-content)"
            alignItems="center"
            gap={2}
            width={"100%"}
          >
            <Typography
              variant="h6"
              color={"inherit"}
              data-testid="dialog-application-name"
              noWrap
            >
              {"Application - "}
            </Typography>
            <StyledTypography
              text={label}
              typographyProps={{ variant: "h6" }}
              color={"info"}
            />
          </Stack>
          <Stack direction="row" gap={1} justifyContent={"space-between"}>
            <Box
              flex={1}
              width="100%"
              p={1}
              border="1px solid"
              borderColor="divider"
              borderRadius={1}
            >
              <Markdown>{i18nTranslated.description || ""}</Markdown>
            </Box>
            <Stack>
              <CopyToClipboard text={releaseInformation.release.name}>
                <Tooltip title="Copy to Clipboard" placement="top">
                  <Chip
                    color="info"
                    label={releaseInformation.release.name}
                    variant="outlined"
                  />
                </Tooltip>
              </CopyToClipboard>

              <Typography
                variant="overline"
                color={"inherit"}
                style={{ fontWeight: 400 }}
              >
                {releaseDate}
              </Typography>
            </Stack>
          </Stack>
          <Box pt={1}>
            <Typography variant="h6" align="left" color={"inherit"}>
              Changelog
            </Typography>
            <Typography variant="subtitle1" align="left" color={"inherit"}>
              {releaseInformation.release.description}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <AlertBox
            color="info"
            action={gitInfoDate}
            boxProps={{
              width: "100%",
            }}
            message={
              <Stack
                direction={"row"}
                gap={2}
                alignItems={"center"}
                p={0}
                my={-1}
              >
                <Tooltip title="CYPEX Core Version">
                  <Typography variant="body1">CYPEX</Typography>
                </Tooltip>
                <CopyToClipboard text={gitInfo.GIT_TAG ?? gitInfo.GIT_COMMIT}>
                  <Tooltip title="Copy to Clipboard" placement="top">
                    <Chip label={gitInfo.GIT_TAG ?? gitInfo.GIT_COMMIT} />
                  </Tooltip>
                </CopyToClipboard>
              </Stack>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  );
});
