import { createSelector } from "reselect";
import { selectorScoper } from "core/utils/redux";
import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";
import { getDisabledValueFromConfig } from "elementTypes/common/utils.ts";

import { AutocompleteInput } from "../../types";
import { getAutocompleteOptions, getRawValueObject } from "../utils.ts";

import { IState } from "./types";

export function buildSelectors(
  path: string[],
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
  element: AutocompleteInput,
) {
  const scopeSelector = selectorScoper<IState>(path);

  const { config } = element;
  const value = (state: any) =>
    dataSource.createFieldValueSelector(fieldPath, {
      defaultValue: null,
    })(state) as string | string[];

  const errors = (state: any) =>
    dataSource.createFieldErrorSelector(fieldPath)(state);

  const touched = (state: any) =>
    dataSource.createFieldTouchedSelector(fieldPath)(state);

  const { filter } = element.config;

  const fixedFilter = (state: any) => filter?.(state);

  const searchInputValue = (state: any) =>
    scopeSelector(state).searchInputValue;
  const loadingOptions = (state: any) => scopeSelector(state).loadingOptions;
  const allOptions = (state: any) => scopeSelector(state).options;
  const optionsError = (state: any) => scopeSelector(state).optionsError;
  const options = createSelector([allOptions, value], (all, val) =>
    getAutocompleteOptions(all, val, config),
  );
  const rawOptions = (state: any) => scopeSelector(state).rawOptions;
  const valueObject = (state: any) => scopeSelector(state).valueObject;
  const moreDataAvailable = (state: any) =>
    scopeSelector(state).moreDataAvailable;
  const disabled = (state?: any) =>
    dataSource.isReadOnly || getDisabledValueFromConfig(state, element.config);

  const rawValueObject = createSelector(
    [rawOptions, value],
    (raw, currentValue) => getRawValueObject(raw, currentValue, config),
  );

  const required = () =>
    dataSource.createFieldRequiredSelector(fieldPath) ??
    element.config.nullable === false;

  return {
    value,
    searchInputValue,
    options,
    loadingOptions,
    moreDataAvailable,
    optionsError,
    errors,
    touched,
    valueObject,
    rawOptions,
    rawValueObject,
    fixedFilter,
    disabled,
    required,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
