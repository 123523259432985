import { memo } from "react";
import { Section, TranslationEditor, useEditorTranslation } from "core/editor";
import { Language, Translation } from "core/types";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";

type TranslationComponentProps = {
  i18n: Translation<"label">;
  language: Language;
  handleChangeLanguage: (l: Language) => void;
  changeTranslation: (i18n: Translation<string>) => void;
};

export const TranslationComponent = memo<TranslationComponentProps>(
  ({ language, handleChangeLanguage, i18n, changeTranslation }) => {
    const editorTranslation = useEditorTranslation();

    return (
      <Section
        title={editorTranslation.translationTitle}
        wrapped={true}
        cardActions={
          <LanguageSwitch
            language={language}
            changeLanguage={handleChangeLanguage}
            colorVariant="dark"
            fullWidth={true}
          />
        }
      >
        <TranslationEditor
          translationKey="label"
          i18n={i18n}
          changeTranslation={changeTranslation}
          language={language}
        />
      </Section>
    );
  },
);
