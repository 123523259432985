import { memo, useCallback, useState } from "react";
import { ToggleButtonGroup } from "@mui/material";

// TODO: to validate **config.value**
//import gjv from "geojson-validation";

import { buildCustomExpressionValue } from "core";
import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";

import { ContentTypeAutocomplete } from "core/editor/common/ContentTypeAutocomplete";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import { DEFAULT_CONFIG } from "elementTypes/default_geojson_field/constants";
import { BuiltinContentType } from "services/api/types/ContentType";
import { useCustomExpression } from "utils/hooks";
import { UntransformedGeoJSONFieldConfig } from "../../types";
import { useDefaultGeoJSONFieldEditorTranslation } from "../translation";

enum Mode {
  query = "query",
  custom = "custom",
}

const currentLocation = (longitude: number, latitude: number) => ({
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [longitude, latitude],
      },
      properties: {
        prop0: "value0",
      },
    },
  ],
});

const defaultFeatureCollection = `{
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: { type: "Point", coordinates: [102.0, 0.5] },
      properties: { prop0: "value0" },
    },
    {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [
          [102.0, 0.0],
          [103.0, 1.0],
          [104.0, 0.0],
          [105.0, 1.0],
        ],
      },
      properties: {
        prop0: "value0",
        prop1: 0.0,
      },
    },
  ],
}`;

export const DataSourceEditor = memo(() => {
  const {
    elementModel: { id, config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedGeoJSONFieldConfig>();

  const t = useDefaultGeoJSONFieldEditorTranslation();
  const { dataSource } = config;

  const handleValueChange = (val: string | number | boolean) => {
    const nextValue = val as string;
    const shouldUpdate =
      nextValue.length && `${nextValue}`?.trim().length && nextValue !== "null";
    //remove viewName from dataSource, so data is provided through value
    if (shouldUpdate) {
      // TODO: create a popup to ask if default values must be set
      changeConfigValue({
        dataSource: null,
        styleFunction: undefined,
        markerDisplayTextFunction: undefined,
        value: nextValue,
        selected: undefined,
      });
    } else {
      changeConfigValue("value", nextValue);
    }
  };

  const { value } = useCustomExpression(config.value, handleValueChange);

  const [mode, setMode] = useState<Mode>(
    value?.trim()?.length && !["null", "undefined"].includes(value)
      ? Mode.custom
      : Mode.query,
  );

  const changeDataSource = useCallback(
    (key: "responseFormat" | "viewName") =>
      (sourceValue: string | undefined) => {
        changeConfigValue("dataSource", {
          ...(dataSource ?? {}),
          [key]: sourceValue?.length ? sourceValue : undefined,
        });
        if (key === "viewName") {
          if (sourceValue?.trim()?.length) {
            changeConfigValue("value", buildCustomExpressionValue(null));
          } else {
            let collection = defaultFeatureCollection;
            if ("geolocation" in navigator) {
              navigator.geolocation.getCurrentPosition((position) => {
                const loc = currentLocation(
                  position.coords.latitude,
                  position.coords.longitude,
                );
                collection = `${loc}`;
              });
            }
            changeConfigValue({
              value: buildCustomExpressionValue(collection),
              selected: undefined,
            });
            setMode(Mode.custom);
          }
        }
      },
    [changeConfigValue, dataSource],
  );

  const viewName = dataSource?.viewName ?? "";
  const responseFormat = dataSource?.responseFormat ?? "geo";

  const handleChange = (_: unknown, nextValue: boolean) =>
    changeConfigValue("updateMapView", !nextValue);

  const { dataSourceTitle, viewLabel } = useEditorTranslation();

  const modeButtons = Object.values(Mode).map((btnMode) => (
    <ToggleButton key={btnMode} value={btnMode} fullWidth>
      {t[`${btnMode}Label`]}
    </ToggleButton>
  ));

  const handleModeChange = (_: unknown, nextMode: Mode) => {
    if (nextMode) {
      setMode(nextMode);
    }
  };

  const component = {
    [Mode.query]: (
      <>
        <ViewAutocomplete
          viewValue={viewName}
          viewLabel={viewLabel}
          onViewNameChange={changeDataSource("viewName")}
        />

        <ContentTypeAutocomplete
          value={responseFormat as BuiltinContentType}
          onChange={changeDataSource("responseFormat")}
          recomended="geo"
        />
      </>
    ),
    [Mode.custom]: (
      <CustomExpressionEditor
        value={config.value}
        label={t.dataLabel}
        config={config}
        onChange={handleValueChange}
        disableSwitcher
        disableGutters
        labelTooltip={t.dataTooltip}
      />
    ),
  }[mode];

  return (
    <Section title={dataSourceTitle} wrapped={true} sx={{ gap: 2 }}>
      <ToggleButtonGroup
        exclusive
        value={mode}
        onChange={handleModeChange}
        size="small"
        fullWidth
        color="secondary"
      >
        {modeButtons}
      </ToggleButtonGroup>
      <TableColumnEditor
        id={id}
        value={config.value}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["json", "geo"]}
        onChange={handleValueChange}
      />
      {component}
      <ToggleButton
        value={config.updateMapView ?? DEFAULT_CONFIG.updateMapView}
        onChange={handleChange}
        selected={config.updateMapView ?? DEFAULT_CONFIG.updateMapView}
        size="small"
        color="secondary"
      >
        {t.updateMapViewLabel}
      </ToggleButton>
    </Section>
  );
});
