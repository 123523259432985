import { MouseEvent, lazy, memo, useEffect, useState } from "react";

import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/sql-hint";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/sql/sql";
import "codemirror/theme/neat.css";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Button, Typography } from "@mui/material";
import { Editor, Position, ShowHintOptions } from "codemirror";
import debounce from "lodash/debounce";

import { Controlled as CodeMirror } from "react-codemirror2";
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormClearErrors,
  UseFormStateReturn,
} from "react-hook-form";

import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";

import {
  AlertBox,
  AlertBoxProps,
} from "../../../../../../elementTypes/common/AlertBox";

import { HintOption } from "../../customQuery/erdHints";
import { useStyles } from "./styles";
import { useCustomQueryTranslation } from "./translation";

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

export type CodeControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<TFieldValues>;
};

type Props = CodeControllerProps & {
  clearError: UseFormClearErrors<any>;
  debouncedValidation?: ReturnType<typeof debounce>;
  isLoading?: boolean;
  hintList?: HintOption; // TODO: type??
  onSuccess?: {
    action: () => void;
    label: string;
  };
  selected?: string | null;
  defaultValue?: string;
};

export const ControlledCodeMirror = memo<Props>(
  ({
    field,
    fieldState,
    debouncedValidation,
    isLoading,
    hintList,
    onSuccess,
    selected,
    defaultValue,
    clearError,
  }) => {
    const { error, isDirty } = { ...fieldState };

    const hasError = error?.type;

    const { classes } = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [editorCursorPosition, setEditorCursorPosition] = useState<
      | Position
      | {
          line: null;
          ch: null;
        }
    >({
      line: null,
      ch: null,
    });

    useEffect(() => {
      if (
        editorCursorPosition.line !== null &&
        editorCursorPosition.ch !== null &&
        selected
      ) {
        const valuesArray = field.value.split("\n");
        const foundValue = valuesArray[editorCursorPosition.line];

        if (foundValue) {
          const newLine = `${foundValue.slice(
            0,
            editorCursorPosition.ch,
          )}${selected} ${foundValue.slice(editorCursorPosition.ch)}`;

          valuesArray[editorCursorPosition.line] = newLine;
          field.onChange(valuesArray.join("\n"));
        }
      }
    }, [selected]);

    const {
      queryEditorErrorDetails,
      queryEditorErrorTitle,
      queryEditorInfo,
      queryEditorLine,
      queryEditorLineDetailsPrefix,
      queryEditorLineErrorPrefix,
      queryEditorLoading,
    } = useCustomQueryTranslation();

    const handleChange = (_: any, __: any, newValue: string) => {
      field.onChange(newValue);
      debouncedValidation?.(newValue);
      if (!newValue.trim()) {
        clearError(field.name);
      }
    };

    const handleOpen = (e: MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(e.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const isEmpty = !field.value?.trim();

    const isValid = !isLoading && !isEmpty && !hasError;

    const alertProps: AlertBoxProps = {
      variant: "standard",
      ...((isEmpty || !isDirty) && isValid
        ? { color: "info", message: queryEditorInfo }
        : isLoading
          ? { color: "info", message: queryEditorLoading }
          : isValid
            ? {
                color: "success",
                message: "Valid",
                action: onSuccess ? (
                  <Button
                    style={{ marginRight: "8px" }}
                    endIcon={<PlayArrowIcon />}
                    onClick={onSuccess.action}
                  >
                    {onSuccess.label}
                  </Button>
                ) : undefined,
              }
            : { color: "error", message: error?.message ?? "Error" }),
    };

    const editorDidMount = (editor: Editor) => {
      setTimeout(() => {
        if (!field.value?.length && defaultValue?.length) {
          field.onChange(defaultValue);
        }
        editor.refresh();
      }, 0);
    };

    const onKeyEvent = (editor: Editor, event: any) => {
      setTimeout(() => editor.showHint(event), 0);
    };

    const onEditorCursor = (ed: Editor, data: Position) => {
      setEditorCursorPosition(data);
      ed.focus();
    };

    return (
      <Box>
        <Box border="1px solid" borderColor="divider" borderRadius={1}>
          <Box width="100%" height="350px" overflow="auto">
            <CodeMirror
              editorDidMount={editorDidMount}
              value={field.value}
              ref={field.ref}
              onBeforeChange={handleChange}
              onChange={() => ({})}
              onCursor={onEditorCursor}
              className={classes.codeClass}
              onKeyPress={onKeyEvent}
              options={{
                lineWrapping: true,
                lineNumbers: true,
                smartIndent: true,
                foldGutter: true,
                gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
                mode: "sql",
                theme: "neat",
                tabSize: 2,
                extraKeys: {
                  "Ctrl-Space": "autocomplete",
                },
                hintOptions: {
                  hintList,
                  completeSingle: false,
                  showHint: !!hintList?.length,
                } as unknown as ShowHintOptions,
              }}
            />
          </Box>
          <AlertBox
            classes={{ message: classes.alertOverflow }}
            {...alertProps}
            {...(!!error?.types?.errorHint &&
              !isLoading && {
                action: (
                  <Box mr={1}>
                    <Button
                      onClick={handleOpen}
                      color="inherit"
                      size="small"
                      variant="text"
                    >
                      {queryEditorErrorDetails}
                    </Button>
                  </Box>
                ),
              })}
          />
        </Box>
        {error?.types?.errorHint && (
          <Popover anchorEl={anchorEl} onClose={handleClose}>
            <Typography variant="subtitle1">{queryEditorErrorTitle}</Typography>
            <Typography variant="subtitle2">
              <Box component="span" style={{ textTransform: "uppercase" }}>
                {`${queryEditorLineErrorPrefix}: `}
              </Box>
              {error.message}
            </Typography>
            <Typography variant="caption">
              <Box component="span" style={{ textTransform: "uppercase" }}>
                {`${queryEditorLineDetailsPrefix}: `}
              </Box>
              {error.types?.errorHint}
              {` ${queryEditorLine} ${error?.types?.errorLine}`}
            </Typography>
          </Popover>
        )}
      </Box>
    );
  },
);
