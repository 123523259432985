import { memo } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { FixedSizeList } from "react-window";
import { Translated } from "core";
import { useElementEditorContext } from "core/editor";
import { useSessionContext, useTranslation } from "core/session";
import { getNewTranslation } from "core/session/translation/utils.ts";
import { TElementWithPosition } from "core/types/element";

import { getTabTranslationName } from "elementTypes/default_tabs/utils";
import { useElement, usePage } from "utils/hooks";
import { TabsChildren, UntransformedTabsConfig } from "../../types";

import { TabConfig } from "./TabConfig";
import { TabRow } from "./TabRow";
import { useTabsContentContext } from "./TabsContext";

function reorderList<T>(list: T[], startIndex: number, endIndex: number) {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const reorderTranslationObject = <T extends Translated<string>>(
  translation: T,
  startIndex: number,
  endIndex: number,
) => {
  const reorderedTranslation = reorderList(
    Object.entries(translation),
    startIndex,
    endIndex,
  ).map(([_, value], index) => [getTabTranslationName(index), value]);
  const newTranslation = Object.fromEntries(reorderedTranslation);

  return newTranslation;
};

const reorderElementList = (
  list: TElementWithPosition<any, any, any>[],
  startIndex: number,
  endIndex: number,
) => {
  const result = reorderList(list, startIndex, endIndex);

  return result.map((item, index) => ({
    ...item,
    position: {
      ...item.position,
      row: index + 1,
    },
  }));
};

export const TabsContent = memo(() => {
  const itemSize = 48;
  const {
    elementModel,
    elementModel: { config, i18n },
    changeTranslation,
  } = useElementEditorContext<UntransformedTabsConfig>();
  const { updateChildren } = useElement();

  const { language } = useSessionContext();

  const tabLabelsTranslation = useTranslation<string>(i18n);

  const page = usePage();
  const { handleTabButtonClick } = useTabsContentContext();

  const {
    content: { elements: tabs },
  } = elementModel.children as TabsChildren;

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination || source.index === destination.index) {
      return;
    }

    updateChildren(
      elementModel,
      reorderElementList(tabs, source.index, destination.index),
      page!,
      "content",
    );

    changeTranslation(
      getNewTranslation(
        i18n,
        language,
        reorderTranslationObject(
          tabLabelsTranslation,
          source.index,
          destination.index,
        ),
      ),
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        mode="virtual"
        renderClone={(provided, snapshot, { source: { index } }) => {
          const defaultTab = index === config.default;
          const { config: tabConfig } = tabs[index];

          return (
            <TabConfig
              config={tabConfig}
              provided={provided}
              snapshot={snapshot}
              onButtonClick={handleTabButtonClick}
              index={index}
              isNew={false}
              defaultTab={defaultTab}
            />
          );
        }}
      >
        {(provided) => (
          <FixedSizeList
            height={itemSize * Math.min(tabs.length, 5)}
            itemCount={tabs.length}
            itemSize={itemSize}
            width="100%"
            outerRef={provided.innerRef}
            itemData={tabs as any}
          >
            {TabRow}
          </FixedSizeList>
        )}
      </Droppable>
    </DragDropContext>
  );
});
