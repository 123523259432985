import { ChangeEvent, memo } from "react";
import TextField from "@mui/material/TextField";
import { useElementEditorContext } from "core/editor";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";

import { Autocomplete } from "elementTypes/common/Autocomplete";
import {
  DEFAULT_CONFIG,
  featureFunctionDefault,
} from "elementTypes/default_geojson_field/constants";
import {
  UntransformedGeoJSONFieldConfig,
  tooltipFunctionTyping,
} from "../../../types";
import { useDefaultGeoJSONFieldEditorTranslation } from "../../translation";
import { useViewOptions } from "../../utils";

export const MarkerTooltip = memo(() => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedGeoJSONFieldConfig>();

  const t = useDefaultGeoJSONFieldEditorTranslation();

  const value = config.tooltipFunction ?? DEFAULT_CONFIG.tooltipFunction;

  const handleChange = (nextvalue: string) => {
    changeConfigValue("tooltipFunction", nextvalue);
  };

  const viewName = config?.dataSource?.viewName ?? "";

  const nonExpressionEditor = (props: NonExpressionEditorProps) => {
    return (
      <ViewColumnOrTextEditor
        {...{ ...props, viewName, label: t.propertyLabel }}
      />
    );
  };

  return (
    <>
      <CustomExpressionEditor
        label={t.tooltipTitle}
        value={value}
        config={config}
        onChange={handleChange}
        additionalScope={tooltipFunctionTyping}
        nonExpressionEditor={nonExpressionEditor}
        onToggleMode={(isExpression) => {
          if (isExpression) {
            handleChange("");
          } else {
            handleChange(featureFunctionDefault(value));
          }
        }}
      />
      {}
    </>
  );
});

export const ViewColumnOrTextEditor = memo<
  NonExpressionEditorProps & { viewName: string; label: string }
>(({ viewName, value, label, onChange }) => {
  const viewOptions = useViewOptions(viewName);

  if (!viewOptions) {
    const handleChange = (ev: ChangeEvent<HTMLInputElement>) =>
      onChange(ev.target.value);
    return (
      <TextField
        label={label}
        value={value}
        onChange={handleChange}
        fullWidth
      />
    );
  }

  return (
    <Autocomplete
      label={label}
      options={viewOptions}
      value={value}
      onChange={onChange as any}
    />
  );
});
