import { teal } from "@mui/material/colors";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()({
  teal: {
    backgroundColor: teal[50],
    border: `1px solid ${teal[900]}`,
    color: teal[900],
  },
});
