import { MouseEvent, forwardRef, memo } from "react";
import MLink, { LinkProps as MLinkProps } from "@mui/material/Link";
import { useDispatch } from "react-redux";
import { actions as routerActions } from "core/router/reduxModule";

export type ILinkProps = {
  href?: string;
  component?: string;
  external?: boolean;
} & MLinkProps;

const CypexLink = memo(
  forwardRef<HTMLAnchorElement, ILinkProps>(
    ({ external = false, ...props }, ref) => {
      const dispatch = useDispatch();

      const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
        if (props.onClick) {
          props.onClick(event);
        }

        if (props.href) {
          if (external) {
            // If the link is external, allow the default behavior
            return;
          } else {
            // If the link is internal, prevent the default behavior and use Redux
            event.preventDefault();
            dispatch(routerActions.push(props.href));
          }
        }
      };

      return (
        <MLink
          {...props}
          onClick={handleClick}
          ref={ref}
          target={external ? "_blank" : undefined}
          rel={external ? "noopener noreferrer" : undefined}
        />
      );
    },
  ),
);

export const Link = forwardRef<HTMLAnchorElement, ILinkProps>((props, ref) => (
  <CypexLink ref={ref} {...props} />
));
