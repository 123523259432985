import * as t from "io-ts";
import { types } from "core/runtime-typing";
import { BtnColorConfig, IElement, UntransformedConfig } from "core/types";
import { SelectorTypes } from "core/types/element";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";

import { FormInputConfig } from "../common";

export const boolInputSelectors: SelectorTypes<BoolInputConfig> = {
  value: types.nullable(types.boolean()),
  disabled: types.optional(types.boolean()),
};

export const BoolInputConfig = t.intersection([
  WithOptionalFieldDataSourceConfig(t.union([t.null, t.boolean])),
  FormInputConfig,
  t.partial({
    placement: t.keyof({
      end: null,
      start: null,
      top: null,
      bottom: null,
    }),
    isSwitch: t.boolean,
    isButton: t.boolean,
    color: BtnColorConfig,
    fullWidth: t.boolean,
  }),
]);

export type BoolInputConfig = t.TypeOf<typeof BoolInputConfig>;

export type UntransformedBoolInputConfig = UntransformedConfig<BoolInputConfig>;

export const BoolInputTranslationKeys = [
  "labelLeft",
  "labelRight",
  "label",
] as const;

export type BoolInputTranslationKeys =
  (typeof BoolInputTranslationKeys)[number];

export type BoolInput = IElement<
  BoolInputConfig,
  Record<string, any>,
  BoolInputTranslationKeys
>;
