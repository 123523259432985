import { memo, useMemo } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { areEqual } from "react-window";
import { useTranslation } from "core/session";
import IconButton from "elementTypes/common/IconButton";
import { useStyles } from "staticPages/admin/pages/modelBuilder/components/styles.ts";
import { ModalContentType } from "staticPages/admin/pages/modelBuilder/components/types.ts";
import { useQueriesPanelContext } from "staticPages/admin/pages/modelBuilder/context/queriesPanel/QueriesPanelContext.utils.ts";
import { useDatabaseTranslation } from "staticPages/admin/pages/modelBuilder/translation.ts";
import { useQueryGroupStyles } from "../../styles.ts";
import { TQueryRow } from "../../types.ts";

export const QueryRow = memo<TQueryRow>((props) => {
  const { type, index } = props;

  const {
    showActions,
    queryRowProps: { handleOpenRowModal, handleEditRow },
  } = useQueriesPanelContext();

  const { classes, cx } = useStyles();
  const {
    classes: { draggingItem },
  } = useQueryGroupStyles();

  const theme = useTheme();
  const translation = useDatabaseTranslation();

  const selectedQuery = type === "list" ? props.data[index] : props.query;

  const { title } = useTranslation<"title">(selectedQuery?.i18n);

  const isDefault = useMemo(
    () => selectedQuery?.autogenerated ?? false,
    [selectedQuery],
  );

  const handleOpenRowDialog =
    (modalType: ModalContentType, isDragging?: boolean) => () =>
      !isDragging &&
      selectedQuery &&
      handleOpenRowModal(modalType)(selectedQuery);

  const handleEdit = (isDragging?: boolean) => () =>
    !isDragging && selectedQuery && handleEditRow(isDefault, selectedQuery);

  if (type === "list") {
    const { style } = props;
    return (
      <Box
        key={selectedQuery?.id}
        // We are using cx instead of CSS :nth-child because there is probably a bug within the
        // virtualization of :nth-child, which makes the list flaky by scrolling -> Ticket CN-451 for more Info.
        className={cx(classes.row, {
          [classes.oddRow]: Boolean(index % 2),
        })}
        style={style}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        gap={1}
        sx={{ overflow: "hidden" }}
        pr={1}
      >
        <Tooltip title={title}>
          <Typography
            noWrap
            className={classes.textOverflow}
            sx={{
              cursor: "pointer",
              py: 0,
              pl: 1,
              flex: 1,
            }}
            onClick={handleOpenRowDialog(ModalContentType.queryPreview)}
          >
            {title}
          </Typography>
        </Tooltip>
        <Box display="flex" flexDirection="row">
          {showActions && (
            <>
              <IconButton
                icon={"edit"}
                size="small"
                tooltip={
                  isDefault ? translation.defaultQuery : translation.editQuery
                }
                onClick={handleEdit(false)}
                edge="end"
              />
              <IconButton
                icon="delete_outline"
                onClick={handleOpenRowDialog(ModalContentType.queryDelete)}
                size="small"
                tooltip={translation.deleteQuery}
                edge="end"
              />
            </>
          )}
          {selectedQuery?.current_definition !==
            selectedQuery?.saved_definition && (
            <IconButton
              icon="error_outline"
              onClick={handleOpenRowDialog(ModalContentType.definitionDiff)}
              size="small"
              tooltip={translation.diffToolTip}
              style={{ color: theme.palette.secondary.light }}
              edge="end"
            />
          )}
        </Box>
      </Box>
    );
  }

  const {
    provided,
    snapshot: { isDragging },
    style,
  } = props;
  return (
    <TreeItem
      itemId={`${selectedQuery?.id}-${index}-tree-item`}
      key={`${selectedQuery?.id}-${index}-tree-item`}
      data-testid={`query-${selectedQuery?.id}`}
      className={cx({ [draggingItem]: isDragging })}
      ref={provided.innerRef}
      classes={{
        iconContainer: classes.iconContainer,
      }}
      style={{
        ...style,
        ...provided.draggableProps.style,
      }}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      label={
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            noWrap
            className={classes.textOverflow}
            sx={{
              cursor: "pointer",
              py: 0,
              pl: 1,
              flex: 1,
            }}
            onClick={handleOpenRowDialog(
              ModalContentType.queryPreview,
              isDragging,
            )}
          >
            {title ?? selectedQuery?.id}
          </Typography>
          <Box display="flex" flexDirection="row">
            {showActions && (
              <Box display="flex" mr={0.35} gap={0.35}>
                <IconButton
                  icon="edit"
                  size="small"
                  tooltip={
                    isDefault ? translation.defaultQuery : translation.editQuery
                  }
                  onClick={handleEdit(isDragging)}
                  edge="end"
                  placement="top"
                  data-testid={`edit-${selectedQuery?.id}`}
                />
                <IconButton
                  icon="delete_outline"
                  onClick={handleOpenRowDialog(
                    ModalContentType.queryDelete,
                    isDragging,
                  )}
                  size="small"
                  tooltip={translation.deleteQuery}
                  edge="end"
                />
              </Box>
            )}
            {selectedQuery?.current_definition !==
              selectedQuery?.saved_definition && (
              <IconButton
                icon="error_outline"
                onClick={handleOpenRowDialog(
                  ModalContentType.definitionDiff,
                  isDragging,
                )}
                size="small"
                tooltip={translation.diffToolTip}
                style={{ color: theme.palette.secondary.light }}
                edge="end"
              />
            )}
          </Box>
        </Box>
      }
    />
  );
}, areEqual);
