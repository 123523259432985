import { ILayouts } from "./types";
import { getDidYouMean } from "./utils/didYouMean";

/*
 * A factory to create a layout getter function. The function returned throws if the layout is not
 * found and makes a suggestion if a similar layout name exists, to help spot typeos.
 */
export function buildLayoutGetter(layouts: ILayouts) {
  const layoutNames = Object.keys(layouts);

  return function getLayout(name?: string) {
    if (!name) {
      throw new Error("Layout name isn't defined");
    }

    const layout = layouts[name];
    if (!layout) {
      const didYouMean = getDidYouMean(layoutNames, name);
      throw new Error(`Layout type "${name}" not supported.${didYouMean}`);
    }
    return layout;
  };
}
