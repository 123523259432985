import { Theme } from "@mui/material";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  listIconWidth: {
    minWidth: "44px",
  },
  listIcon: {
    color: theme.palette.divider,
    justifyContent: "center",
    marginLeft: "-10px",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  topMargin: {
    padding: 0,
    marginTop: theme.spacing(),
  },
  draggingItem: {
    border: `1px solid ${theme.palette.divider}`,
  },
  actionItemsText: {
    overflowWrap: "break-word",
  },
  actionsContent: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  titleSection: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    paddingLeft: 0,
  },
}));
