import { IDefaultElement, buildCustomExpressionValue } from "core";
import { defaultExpressionValue } from "elementTypes/common/Echarts/utils";

import { UntransformedCustomChartConfig } from "../types";

const defaultOption = {
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  yAxis: {
    type: "value",
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: "line",
      areaStyle: {},
    },
  ],
};

export const defaultElement: IDefaultElement<UntransformedCustomChartConfig> = {
  config: {
    dataSource: {
      viewName: "",
    },
    option: buildCustomExpressionValue(JSON.stringify(defaultOption)),
    filter: defaultExpressionValue,
  },
  i18n: {
    en: {
      title: "",
    },
  },
};
