import { RequiredConfigProperties } from "core/types";

import { OwnConfig } from "./types";

export const MODES = [
  "delete",
  "drag",
  "line",
  "polygon",
  "rectangle",
  "marker",
  "text",
] as const;

export const DEFAULT_CONFIG: RequiredConfigProperties<OwnConfig> = {
  geoJSONdefaultValue: "",
  latitude: 51.505,
  longitude: -0.09,
  zoom: 13,
  allowDelete: true,
  allowDrag: true,
  allowLine: true,
  allowPolygon: true,
  allowRectangle: true,
  allowMarker: true,
  allowText: true,
  tileLayerUrl: null,
  maximumFeatures: null,
};
