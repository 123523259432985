import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Circular Progress",
      description: "Show an circular progress indicator",
    },
  },
};
