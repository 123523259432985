import { memo, useCallback, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Controller } from "react-hook-form";
import { Language, Translation } from "core";
import { useElementTypesContext } from "core/context/ElementTypesContext";
import {
  TranslationEditor,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { useSessionContext, useTranslation } from "core/session";
import { TElementModelWithPosition } from "core/types/element";
import Button from "elementTypes/common/Button";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";
import { getTabTranslationName } from "elementTypes/default_tabs/utils";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";

import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";
import { TabsChildren, UntransformedTabsConfig } from "../../types";
import { useDefaultTabsEditorTranslation } from "../translation";

import { TabConfigProps } from "./TabConfig";
import { useTabsContentContext } from "./TabsContext";

type Props = TabConfigProps & {
  language: Language;
  i18n: Translation<string>;
  changeLanguage: (l: Language) => void;
  changeTranslation: (value: Translation<string>) => void;
};

export const TabDialog = memo(() => {
  const { createTabTitle, editTabTitle } = useDefaultTabsEditorTranslation();
  const { cancelButton, createButton, deleteButton, updateButton } =
    useEditorTranslation();
  const {
    tabDetails,
    handleDialogClose,
    deleteTab,
    createElement,
    updateChildren,
    page,
  } = useTabsContentContext();
  const {
    elementModel,
    elementModel: { i18n },
    changeConfigValue,
    changeTranslation,
  } = useElementEditorContext<UntransformedTabsConfig>();
  const { elementTypes } = useElementTypesContext();

  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);

  const changeLanguage = (l: Language) => setLang(l);

  const tabLabelsTranslation = useTranslation<string>(i18n);

  const {
    content: { elements: tabs },
  } = elementModel.children as TabsChildren;

  const onSubmit = useCallback(
    (data: Record<string, any>) => {
      if (tabDetails?.isNew) {
        createElement(
          elementTypes,
          elementTypes.default_grid,
          page!,
          {
            row: 1,
            column: 1,
            width: 12,
            height: (elementModel as TElementModelWithPosition).position.height,
          },
          elementModel,
          undefined,
          `${data.name}`,
        );
      } else {
        if (tabDetails) {
          const updatedTabs = tabs.map((tab, index) =>
            index === tabDetails.index
              ? {
                  ...tab,
                  name: tabLabelsTranslation[getTabTranslationName(index)],
                }
              : tab,
          );

          updateChildren(elementModel, updatedTabs, page!, "content");
        }
      }

      if (data.default && tabDetails) {
        changeConfigValue("default", tabDetails.index);
      }
      setLang(language);
      handleDialogClose();
    },
    [
      language,
      elementModel,
      elementTypes,
      page,
      tabDetails,
      tabLabelsTranslation,
      tabs,
      changeConfigValue,
      createElement,
      handleDialogClose,
      updateChildren,
    ],
  );

  const handleDeleteClick = () => {
    if (tabDetails) {
      deleteTab(tabDetails?.index);
      handleDialogClose();
    }
  };

  const dialogProps: Props = {
    ...(tabDetails ? { ...tabDetails } : ({} as TabConfigProps)),
    language: lang,
    i18n,
    changeTranslation,
    changeLanguage,
  } as const;

  return (
    <DialogWrapper
      isForm={true}
      keepMounted={false}
      open={Boolean(tabDetails)}
      title={tabDetails?.isNew ? createTabTitle : editTabTitle}
      submitTitle={tabDetails?.isNew ? createButton : updateButton}
      cancelTitle={cancelButton}
      handleClose={handleDialogClose}
      handleSubmit={onSubmit}
      submitDisabled={true}
      subActions={
        !tabDetails?.isNew && (
          <Button
            label={deleteButton}
            color="error"
            onClick={handleDeleteClick}
          />
        )
      }
    >
      {tabDetails && <TabDialogContent {...dialogProps} />}
    </DialogWrapper>
  );
});

const TabDialogContent = memo<Props>(
  ({
    defaultTab,
    index,
    i18n,
    changeTranslation,
    language,
    changeLanguage,
  }) => {
    const { nameLabel, defaultTabLabel } = useDefaultTabsEditorTranslation();
    const { control } = useHookFormContext();

    const translationKey = getTabTranslationName(index);

    return (
      <>
        <FormControl fullWidth={true}>
          <Controller
            render={({ field: { ref }, fieldState: { error } }) => (
              <>
                <LanguageSwitch
                  language={language}
                  changeLanguage={changeLanguage}
                  colorVariant="dark"
                  fullWidth
                />
                <TranslationEditor
                  translationKey={translationKey}
                  i18n={i18n}
                  changeTranslation={changeTranslation}
                  label={nameLabel}
                  language={language}
                  inputRef={ref}
                  error={Boolean(error)}
                />
              </>
            )}
            name="name"
            control={control}
          />
        </FormControl>
        <FormControl fullWidth={true}>
          <Controller
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox checked={field.value ?? false} />}
                label={defaultTabLabel}
                {...field}
              />
            )}
            name="default"
            control={control}
            defaultValue={defaultTab}
          />
        </FormControl>
      </>
    );
  },
);
