import { selectorScoper } from "core/utils/redux";
import { TextInput } from "elementTypes/default_text_input/types";

export function buildSelectors(path: string[]) {
  const scopeSelector =
    selectorScoper<TextInput["config"]["defaultValue"]>(path);

  const value = (state: any) => scopeSelector(state).value;
  const errors = (state: any) => scopeSelector(state).errors;
  const touched = (state: any) => scopeSelector(state).touched;

  return {
    value,
    errors,
    touched,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
