import { memo, useCallback } from "react";
import { Typography } from "@mui/material";

import { buildCustomExpressionValue } from "core";
import { Section, useElementEditorContext } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";

import { AlertBox } from "elementTypes/common/AlertBox";
import IconButton from "../../../common/IconButton";
import { UntransformedFormConfig } from "../../types";
import { useEditorFormTranslation } from "../translation";

const INITIAL_VALUE = buildCustomExpressionValue("true");

export const Validation = memo(() => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedFormConfig>();
  const { customValidationLabel, createValidation, removeValidation } =
    useEditorFormTranslation();

  const { validation } = config;

  const changeValue = useCallback(
    (newValidationExpression: UntransformedFormConfig["validation"]) => {
      changeConfigValue("validation", newValidationExpression);
    },
    [changeConfigValue],
  );

  const deleteValidation = () => changeValue(undefined);
  const addValidation = () => changeValue(INITIAL_VALUE);

  return (
    <Section
      wrapped={Boolean(validation)}
      title={customValidationLabel}
      headerAction={
        <IconButton
          icon={validation ? "delete_outline" : "add"}
          onClick={validation ? deleteValidation : addValidation}
          tooltip={validation ? removeValidation : createValidation}
        />
      }
    >
      {validation && (
        <>
          <CustomExpressionEditor
            value={validation}
            config={config}
            onChange={changeValue}
            disableSwitcher
          />
          <AlertBox
            color="info"
            message={
              <Typography variant="body2">
                The custom validation expression allowed to return text or{" "}
                <code>boolean</code> value. The value - <code>false</code>{" "}
                disables buttons, <code>true</code> value or empty string
                enables.
              </Typography>
            }
          />
        </>
      )}
    </Section>
  );
});
