import { memo } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { formatDistanceToNow, formatRelative } from "date-fns";
import { stringify } from "query-string";
import { DEFAULT_APP_URL } from "core/router/reduxModule/constants.ts";
import { useTranslation } from "core/session";
import IconButton from "elementTypes/common/IconButton";
import { TableRow } from "staticPages/admin/common";
import { useCommonStaticPagesTranslation } from "../translation.ts";
import { TApplicationRow } from "./types.ts";

export const ApplicationRow = memo<TApplicationRow>(
  ({ ui, onDialogOpen, handleExport }) => {
    const {
      id,
      name,
      i18n,
      lastUpdated,
      role,
      i18nPublished,
      publishedRelease,
      i18nLatest,
    } = ui;

    const translation = useCommonStaticPagesTranslation();

    const onExport = () => handleExport(name);
    const onDelete = () => onDialogOpen({ name });

    const publishedTranslated = useTranslation(i18nPublished);
    const latestTranslated = useTranslation(i18nLatest);
    const i18nTranslated = useTranslation(i18n);

    const publishedLabel = publishedTranslated.label;
    const latestLabel = latestTranslated.label;

    const publishedDescription = publishedTranslated.description;
    const latestDescription = latestTranslated.description;

    const description = i18nTranslated.description;

    const handlePreview = () =>
      onDialogOpen({
        publishedDescription,
        description,
        latestDescription,
      });

    const queryString = stringify({ ui: `"${ui.name}"` });
    const appUrlOpen = `${DEFAULT_APP_URL}?${queryString}`;

    return (
      <TableRow rowId={id}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <IconButton
            tooltip={translation.descriptionTooltip}
            icon="preview"
            color="secondary"
            data-testid="admin-apps-action-prewiev"
            placement="top"
            onClick={handlePreview}
          />
        </Box>

        <Typography component="span">
          {publishedLabel}
          {publishedLabel !== latestLabel ? (
            <Tooltip title={"Unpublished"} arrow>
              <Box
                component="span"
                color="text.disabled"
                fontWeight="fontWeightBold"
                fontSize={12}
                marginLeft={1}
              >
                {latestLabel}{" "}
              </Box>
            </Tooltip>
          ) : null}
        </Typography>

        <Typography component="span" color="text.disabled">
          {name}
        </Typography>

        <Tooltip title={formatRelative(new Date(lastUpdated), new Date())}>
          <Typography component="span">
            {formatDistanceToNow(new Date(lastUpdated), {
              addSuffix: true,
            })}
          </Typography>
        </Tooltip>

        <Typography>{role}</Typography>

        <Typography>{publishedRelease.versionName}</Typography>

        <Box display="flex" alignItems="center" justifyContent="center">
          <IconButton
            tooltip={translation.editTooltip}
            icon="create"
            color="primary"
            data-testid="admin-apps-action-edit"
            href={`/admin/apps/edit/${name}`}
            placement="top"
          />
          <IconButton
            tooltip={translation.startTooltip}
            icon="slideshow"
            color="primary"
            data-testid="admin-apps-action-start"
            placement="top"
            target="_blank"
            href={appUrlOpen}
          />
          <IconButton
            tooltip={translation.exportTooltip}
            icon="cloud_download"
            onClick={onExport}
            color="primary"
            data-testid="admin-apps-action-export"
            placement="top"
          />
          <IconButton
            tooltip={translation.deleteTooltip}
            icon="delete_outline"
            onClick={onDelete}
            color="error"
            data-testid="admin-apps-action-delete"
            placement="top"
          />
        </Box>
      </TableRow>
    );
  },
);
