import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  filters: {
    display: "inline-flex",
    justifyContent: "flex-end",
  },
  filter: {
    flex: 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  cellCentered: {
    textAlign: "center",
  },
  dialog: {
    width: "800px",
  },
}));

export default useStyles;
