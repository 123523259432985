import { createUseTranslation } from "core/session";

export const elementWrapperTranslation = {
  en: {
    dragHandleTooltip: "Drag to move",
    copyElementTooltip: "Double-click to copy this element",
  },
};

export const useElementWrapperTranslation = createUseTranslation(
  elementWrapperTranslation,
);
