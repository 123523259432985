import { memo, useCallback } from "react";
import { buildCustomExpressionValue } from "core";
import {
  IconAutocomplete,
  Section,
  useElementEditorContext,
} from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";

import { IconNameType } from "elementTypes/common/MuiIcon";
import { UntransformedActionButtonConfig } from "../../types";
import { useActionButtonEditorTranslation } from "../translation";

export const DisplayComponent = memo(() => {
  const { displaySectionTitle, disabledLabel } =
    useActionButtonEditorTranslation();
  const {
    elementModel: {
      config,
      config: { icon, disabled = buildCustomExpressionValue("false") },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedActionButtonConfig>();

  const changeIcon = (newIcon: UntransformedActionButtonConfig["icon"]) =>
    changeConfigValue("icon", newIcon);

  const wrappedChangePath = useCallback(
    (newValue: string) => {
      changeConfigValue("disabled", newValue);
    },
    [changeConfigValue],
  );

  return (
    <Section title={displaySectionTitle} wrapped={true}>
      <IconAutocomplete
        label="Icon"
        value={icon as IconNameType}
        onChange={changeIcon}
      />
      <CustomExpressionEditor
        value={disabled}
        config={config}
        label={disabledLabel}
        disableSwitcher
        onChange={wrappedChangePath}
      />
    </Section>
  );
});
