import { memo } from "react";
import { getDiff } from "recursive-diff";
import { useRouterParamByName } from "core/router/reduxModule/utils";
import { IAppMetadata } from "core/types";
import { HookForm } from "elementTypes/common/HookForm";
import { useEditApp, useMetadataUi } from "queries/admin/appData";
import { getApiError } from "queries/utils";
import { RoutePaths } from "staticPages/routes";
import { useRoute } from "utils/hooks";
import { useSnackbar } from "utils/hooks/useSnackbar";

import { useCommonStaticPagesTranslation } from "../../translation";
import { EditAppForm } from "./components/Form.tsx";

import { IUIEditForm } from "./types";

export const EditAppsPage = memo(() => {
  const showSnackbar = useSnackbar();
  const route = useRoute();
  const translation = useCommonStaticPagesTranslation();
  const uiName = useRouterParamByName("uiName");
  const { data: uiMetadata = {} as IAppMetadata, refetch } =
    useMetadataUi(uiName);

  const editUi = useEditApp({
    onSuccess: (data) => {
      if (data.newPagesNumber) {
        showSnackbar(
          `${data.newPagesNumber} - ${translation.generatePagesMessage}`,
          "success",
        );
      } else {
        showSnackbar(translation.noGeneratedPagesMessage, "warning");
      }

      refetch();
      showSnackbar(translation.editAppMessage, "success");
      route("push", RoutePaths.Apps);
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const submit = (formData: Record<string, any>) => {
    const editData = formData as IUIEditForm;

    const info = getDiff(
      editData?.i18n,
      uiMetadata.release?.definition?.i18n,
    ).reduce(
      (res, item) =>
        item?.path?.length > 1
          ? {
              ...res,
              [String(item.path[item.path.length - 1]).toUpperCase()]: "",
            }
          : res,
      {} as Record<string, string>,
    );
    const queries = (editData?.queries ?? []).map((v) => ({ id: v }));
    const i18nInfo = Object.keys(info).toString();
    const queriesInfo = queries.length ? ", NEW PAGES GENERATED" : "";

    editUi.mutate({
      name: uiName,
      editData: {
        i18n: editData.i18n,
        queries,
        description: `CHANGED: ${i18nInfo}${queriesInfo}`,
      },
    });
  };

  return (
    <HookForm onSubmit={submit} formOptions={{ mode: "onChange" }}>
      <EditAppForm uiMetadata={uiMetadata} uiName={uiName} />
    </HookForm>
  );
});
