import { makeStyles } from "tss-react/mui";
import { CSSObject } from "tss-react/types";
import { FILE_STORAGE_PREFIX } from "services/api/constants";

type Props = {
  menuWidth: string;
  logoUrl?: string;
};

const DRAWER_HEADER_HEIGHT = "48px";

const getMenuWidth = ({ menuWidth }: Pick<Props, "menuWidth">) => menuWidth;

const getLogoUrl = ({ logoUrl }: Pick<Props, "logoUrl">) =>
  `${FILE_STORAGE_PREFIX}${logoUrl}`;

const useStyles = makeStyles<Props>()((theme, { menuWidth, logoUrl }) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    position: "relative",
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    zIndex: 1,
  },
  content: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    willChange: "margin",
  },
  "content-left": {
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
    willChange: "margin",
  },
  "contentShift-left": {
    marginLeft: getMenuWidth({ menuWidth }),
  },
  drawerPaper: {
    width: getMenuWidth({ menuWidth }),
    scrollbarWidth: "none",
    MsOverflowStyle: "-ms-autohiding-scrollbar",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    willChange: "transform, visibility",
  },
  drawerHeader: {
    padding: "0 0px",
    ...(theme.mixins.toolbar as CSSObject),
    minHeight: `${DRAWER_HEADER_HEIGHT} !important`,
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
  },
  drawerLogo: {
    width: "100%",
    height: "100%",
    maxHeight: DRAWER_HEADER_HEIGHT,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    cursor: "pointer",
    overflow: "hidden",
    ...(logoUrl && {
      backgroundImage: `url(${getLogoUrl({ logoUrl })})`,
      minHeight: DRAWER_HEADER_HEIGHT,
      minWidth: "100%",
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    }),
    "& svg, & img": {
      transition: theme.transitions.create("filter", {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
      willChange: "filter",
    },
    "&:hover img": {
      filter: "brightness(1.15)",
    },
    "&:hover": {
      "& [class$='st0']": {
        filter: "brightness(1.25) contrast(103%)",
        transition: "filter 0.3s ease-in-out",
      },
      "& [class$='st1']": {
        filter: "brightness(1.25) contrast(103%)",
        transition: "filter 0.5s ease-in-out",
      },
    },
  },
  cookieConsentContainer: {
    alignItems: "baseline",
    background: "#353535",
    color: "white",
    display: "flex",
    flexWrap: "wrap" as const,
    justifyContent: "center",
    left: "0",
    position: "fixed" as const,
    width: "100%",
    zIndex: 999,
  },
  cookieConsentButton: {
    padding: "5px 10px",
    margin: "15px",
  },
  editMode: {
    backgroundColor: theme.palette.background.default,
    padding: 0,
  },
  rootMenu: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));

export type DefaultLayoutStyles = ReturnType<typeof useStyles>;

export default useStyles;
