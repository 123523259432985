import { memo, useLayoutEffect, useRef, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography, { TypographyProps } from "@mui/material/Typography";

function isOverflowing(element: HTMLSpanElement) {
  return element.scrollWidth > element.offsetWidth;
}

export const TruncatedTypography = memo<
  { title: string | number | boolean } & TypographyProps
>(({ title, ...rest }) => {
  const textRef = useRef<HTMLSpanElement | null>(null);
  const [truncated, setTruncated] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (textRef?.current && isOverflowing(textRef.current)) {
      setTruncated(true);
    }
  }, []);

  const component = (
    <Typography ref={textRef} {...rest} noWrap={truncated}>
      {title}
    </Typography>
  );

  return truncated ? (
    <Tooltip title={title} placement="left">
      {component}
    </Tooltip>
  ) : (
    component
  );
});
