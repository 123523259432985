import { Theme } from "@mui/material";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    maxWidth: 360,
    maxHeight: 275,
    minWidth: 300,
  },
  avatarColorDefault: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.getContrastText(theme.palette.text.primary),
  },
  avatar: {
    textTransform: "uppercase",
    fontWeight: "bold",
    border: "1px solid",
    borderColor: theme.palette.grey[500],
  },
}));

export default useStyles;
