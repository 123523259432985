import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Loadable, {
  LoadableComponent,
  LoadingComponentProps,
  OptionsWithoutRender,
} from "react-loadable";

export const LoadingComponent = ({
  error = false,
  retry = () => null,
  timedOut = false,
  pastDelay = true,
}: Partial<LoadingComponentProps>) => {
  if (error) {
    return (
      <div>
        Error! <button onClick={retry}>Retry</button>
      </div>
    );
  } else if (timedOut) {
    return (
      <div>
        Taking a long time... <button onClick={retry}>Retry</button>
      </div>
    );
  } else if (pastDelay) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return null;
  }
};

export default function Loading(
  opts: Partial<OptionsWithoutRender<any>> &
    Pick<OptionsWithoutRender<any>, "loader">,
): LoadableComponent {
  return Loadable(
    Object.assign(
      {
        delay: 500,
        loader: () => null,
        loading: LoadingComponent,
        timeout: 10000,
      },
      opts,
    ),
  );
}
