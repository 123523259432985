import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

type Props = {
  disableSpacing?: boolean;
};

const useStyles = makeStyles<Props>()((theme: Theme, { disableSpacing }) => ({
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: disableSpacing ? 0 : theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    margin: `${theme.spacing()} 0`,
  },
}));

export default useStyles;
