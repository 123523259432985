import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  dialogRoot: {
    padding: theme.spacing(2),
  },
  dialogTitle: {
    // fix title height 0 for unset title
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
  dialogContentPaper: {
    // height should be full height with 64px top and bottom spacing
    // necessary for grid element to be maximum size as well
    height: "calc(100% - 128px)",
  },
  dialogCloseIcon: {
    color: theme.palette.grey[500],
  },
  backdropRoot: {
    background: "transparent",
  },
  appBar: {
    position: "relative",
  },
}));
