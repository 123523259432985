import { stringify } from "query-string";
import request from "superagent";
import { IAppMetadata, IElementModel, IObjectView, IUi } from "core/types";
import { Notification } from "queries/app/types";
import { apiClient, onTokenExpired, withAuthHeader } from "utils/api";

import { getHeaders } from "utils/headersUtil";
import { getServerError } from "../../core/utils/api";

import { FILE_STORAGE_PREFIX } from "./constants";
import { BuiltinContentType } from "./types/ContentType";
import { StorageFileMetadata } from "./types/FileStorage";

export { onTokenExpired };

export const loadUIList = async (token: string): Promise<IUi[]> => {
  return await apiClient("GET", "/app/ui", token);
};

export const loadViewList = async (
  token: string,
  { role }: { role?: string },
): Promise<IObjectView[]> => {
  const query = role ? `?${stringify({ role })}` : "";
  return await apiClient("GET", "/app/view/" + query, token);
};

export const getViewMetadata = async (
  { token }: { token?: string } = {},
  params: {
    objectViewName: string;
    stateNames?: string[];
  },
) => {
  const query = `?${stringify(
    { stateNames: params.stateNames },
    { arrayFormat: "bracket" },
  )}`;
  return await apiClient(
    "GET",
    `/app/view/${params.objectViewName}/metadata/many${query}`,
    token,
  );
};

export const loadAppMetadata = async (
  token: string,
  { uiName, latest }: { uiName: string; latest?: boolean },
): Promise<IAppMetadata> => {
  const url = latest ? `/app/ui/${uiName}/latest` : `/app/ui/${uiName}`;
  return await apiClient("GET", url, token);
};

export const getUIReleases = async (token: string, uiName: string) => {
  return await apiClient("GET", `/app/ui/${uiName}/release`, token);
};

export const getUISavePoints = async (token: string, uiName: string) => {
  return await apiClient("GET", `/app/ui/${uiName}/save`, token);
};

export const generateUIElement = async (
  token: string,
  {
    uiName,
    elementName,
    element,
  }: { uiName: string; elementName: string; element?: IElementModel },
): Promise<IElementModel<{}, {}, never>> => {
  return await apiClient("POST", `/app/ui/${uiName}/generate/element`, token, {
    name: elementName,
    element,
  });
};

interface LoginBody {
  identifier: string;
  password: string;
}

export const login = async (payload: LoginBody): Promise<string> => {
  const res = await apiClient<{ jwt: string }>(
    "POST",
    `/app/auth/login`,
    undefined,
    payload,
    undefined,
  );
  return res?.jwt;
};

export const loadViewData = async (
  token: string,
  {
    viewName,
    params,
    responseFormat,
  }: {
    viewName: string;
    params?: Record<string, unknown>;
    responseFormat?: BuiltinContentType;
  },
) => {
  const queryString = params ? `?${stringify(params)}` : "";
  return await apiClient<Record<string, string | number>[]>(
    "GET",
    `/view/${viewName}${queryString}`,
    token,
    undefined,
    undefined,
    undefined,
    responseFormat,
  );
};

export const createViewData = async (
  token: string,
  viewName: string,
  data: any,
) => {
  const res = await apiClient<Record<string, unknown>[]>(
    "POST",
    `/view/${viewName}`,
    token,
    data,
    undefined,
    {
      Prefer: "return=representation",
    },
  );
  return res[0];
};

export const updateViewData = async (
  token: string,
  viewName: string,
  data: any,
  identifierName: string,
  identifierValue: any,
) => {
  const res = await apiClient<Record<string, unknown>[]>(
    "PATCH",
    `/view/${viewName}?${identifierName}=eq.${identifierValue}`,
    token,
    data,
    undefined,
    {
      Prefer: "return=representation",
    },
  );
  return res[0];
};

export const insertMultipleViewDataRows = async (
  token: string,
  viewName: string,
  data: any[],
) => {
  return await apiClient("POST", `/view/${viewName}`, token, data);
};

export const updateMultipleViewDataRows = async (
  token: string,
  viewName: string,
  data: any[],
) => {
  return await apiClient("POST", `/view/${viewName}`, token, data, undefined, {
    Prefer: "resolution=merge-duplicates",
  });
};

export const deleteMultipleViewDataRows = async (
  token: string,
  viewName: string,
  ids: any[],
  identifierName: string,
) => {
  return await apiClient(
    "DELETE",
    `/view/${viewName}?"${identifierName}"=in.(${ids
      .map((id) => `"${id}"`)
      .join(",")})`,
    token,
  );
};

export const deleteViewData = async (
  token: string,
  viewName: string,
  identifierName: string,
  identifierValue: any,
) => {
  return await apiClient(
    "DELETE",
    `/view/${viewName}?${identifierName}=eq.${identifierValue}`,
    token,
    undefined,
    undefined,
    { Prefer: "return=representation" },
  );
};

export type CurrentUserIntegrated = {
  type: "integrated";
  email: string;
  userName?: string;
};

export type CurrentUserOther = {
  type: "other";
  name: string;
};

export type AuthMeResponse = {
  id: string;
  role: string;
  language: string;
  additionalInformation: CurrentUserIntegrated | CurrentUserOther;
  isActive: boolean;
  isAdmin: boolean;
};

export const getUser = async (token: string): Promise<AuthMeResponse> => {
  return await apiClient("GET", "/app/auth/me", token);
};

export const updateUser = async (
  token: string,
  userData: Record<string, unknown>,
) => {
  return await apiClient<Record<string, unknown>>(
    "PUT",
    "/app/auth/me",
    token,
    userData,
  );
};

export * from "./types/stateTransition";

export const runProcedure = async (
  token: string | null,
  name: string,
  schema: string,
  args: any,
  format?: BuiltinContentType,
) => {
  let headers: Record<string, string> = {};

  if (format) {
    headers = getHeaders(format);
  }

  return await apiClient(
    "POST",
    `/${schema}/rpc/${name}`,
    token,
    args,
    undefined,
    headers,
  );
};

export const uploadFile = async (
  token: string,
  file: File,
  groupName: string,
  typeGroupName: string,
) => {
  try {
    const req = request.post(FILE_STORAGE_PREFIX);
    req.attach("file", file as never, file.name);
    req.field("groupName", groupName);
    req.field("typeGroupName", typeGroupName);
    const { body } = await withAuthHeader(req, token);

    return body as StorageFileMetadata;
  } catch (err) {
    throw getServerError(err);
  }
};

export const uploadMultipleFiles = async (
  token: string,
  files: File[],
  groupName: string,
  typeGroupName: string,
) => {
  try {
    let body: StorageFileMetadata[] = [];
    for (const file of files) {
      const uploadedFile = await uploadFile(
        token,
        file,
        groupName,
        typeGroupName,
      );
      body = [...body, uploadedFile];
    }

    return body;
  } catch (err) {
    throw getServerError(err);
  }
};

export const fileMetadata = async (
  token: string,
  { fileName }: { fileName: string },
): Promise<StorageFileMetadata | undefined> => {
  try {
    const req = request.get(`${FILE_STORAGE_PREFIX}${fileName}/details`);
    const { body } = await withAuthHeader(req, token);
    return body as StorageFileMetadata;
  } catch (err) {
    throw getServerError(err);
  }
};

export const multipleFileMetadata = async (
  token: string,
  fileNames: string[],
) => {
  try {
    let body: StorageFileMetadata[] = [];

    for (const fileName of fileNames) {
      const fileMdata = await fileMetadata(token, { fileName });
      if (fileMdata) {
        body = [...body, fileMdata];
      }
    }

    return body;
  } catch (err) {
    throw getServerError(err);
  }
};

export interface LoginConfig {
  logoPath: string | null;
  identifierInputTitle: string | null;
}

export const getLoginConfig = async (token: string): Promise<LoginConfig> => {
  return await apiClient("GET", "/app/auth/config/login", token);
};

export const getViewCreateStateChanges = async (
  token: string,
  objectViewName: string,
) => {
  return await apiClient(
    "GET",
    `/app/view/${objectViewName}/state-changes/create`,
    token,
  );
};

export const getUserNotifications = async (token: string) => {
  return await apiClient<Notification[]>(
    "GET",
    "/app/users/notifications",
    token,
  );
};

export const readUserNotifications = async (
  token: string,
  params: {
    notificationIds: number[];
  },
) => {
  const notifications = { ids: params.notificationIds };
  return await apiClient(
    "POST",
    "/app/users/update/notifications",
    token,
    notifications,
  );
};
