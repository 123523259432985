import { TProperties } from "./types.ts";

export const properties = ["scale", "rotate", "borderRadius"] as const;

export const propertyMarks = {
  scale: [
    {
      value: 0.05,
      label: "0.05x",
    },
    {
      value: 1,
      label: "1x",
    },
    {
      value: 10,
      label: "10x",
    },
  ],
  rotate: [
    {
      value: 0,
      label: "0°",
    },
    {
      value: 180,
      label: "180°",
    },
    {
      value: 360,
      label: "360°",
    },
  ],
  borderRadius: [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 50,
      label: "50%",
    },
  ],
};

export const maxValues: Record<TProperties, number> = {
  scale: 10,
  rotate: 360,
  borderRadius: 50,
};

export const stepValues: Record<TProperties, number> = {
  scale: 0.05,
  rotate: 10,
  borderRadius: 5,
};

export const defaultValues: Record<TProperties, number> = {
  scale: 1,
  rotate: 0,
  borderRadius: 0,
};

export const minValues: Record<TProperties, number> = {
  scale: 0.05,
  rotate: 0,
  borderRadius: 0,
};
