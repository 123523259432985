import { handleActions } from "core/utils/redux";

import { Types } from "./actions";
import { IState } from "./types";

const INITIAL_STATE: IState = {
  value: null,
};

export function buildReducer(types: Types) {
  return handleActions<IState>(INITIAL_STATE, {
    [types.VALUE_CHANGE]: (state, action: any) => ({
      ...state,
      value: action.payload.value,
    }),
  });
}
