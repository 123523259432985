import { connect } from "react-redux";

import { selectors as routerSelectors } from "../../../router/reduxModule";

import { PageSelector as PSComponent } from "./component";

export const mapStateToProps = (state: any) => ({
  pages: routerSelectors.allPages(state),
});

export const PageSelector = connect(mapStateToProps)(PSComponent);
