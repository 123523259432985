import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  grip: {
    display: "none",
  },
  toolbar: {
    flexWrap: "nowrap",
    overflow: "auto",
    height: theme.spacing(6),
  },
}));
