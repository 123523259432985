import { memo, useCallback } from "react";
import { Stack } from "@mui/material";
import capitalize from "lodash/capitalize";
import { Section, useElementEditorContext } from "core/editor";

import { Autocomplete } from "../../../common/Autocomplete";
import { IAutocompleteValue } from "../../../common/Autocomplete/types";
import { UntransformedSliderInputConfig } from "../../types";
import { useSliderInputEditorTranslation } from "../translation";

const colorOptions = ["primary", "secondary"];
const orientationOptions = ["vertical", "horizontal"];
const colorAutocompleteOptions = colorOptions.map((colorOption) => ({
  value: colorOption,
  label: capitalize(colorOption),
}));
const orientationAutocompleteOptions = orientationOptions.map(
  (orientationOption) => ({
    value: orientationOption,
    label: capitalize(orientationOption),
  }),
);

export const StylingComponent = memo(() => {
  const {
    elementModel: {
      config: { color, orientation },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedSliderInputConfig>();
  const translation = useSliderInputEditorTranslation();

  const handleChange = useCallback(
    (...params: Parameters<typeof changeConfigValue>) =>
      changeConfigValue(...params),
    [changeConfigValue],
  );

  const onChange =
    (inputName: "color" | "orientation") => (value: IAutocompleteValue) => {
      handleChange(inputName, value);
    };

  return (
    <Section title={translation.stylingTitle} wrapped={true}>
      <Stack spacing={2}>
        <Autocomplete
          options={colorAutocompleteOptions}
          onChange={onChange("color")}
          value={color}
          name="color"
          label={translation.colorInputLabel}
          isLoading={false}
          virtualizedList={true}
          isClearable={true}
        />
        <Autocomplete
          options={orientationAutocompleteOptions}
          onChange={onChange("orientation")}
          value={orientation}
          name="orientation"
          label={translation.orientationInputLabel}
          isLoading={false}
          virtualizedList={true}
          isClearable={true}
        />
      </Stack>
    </Section>
  );
});
