import { ChangeEvent, memo, useCallback } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Section, useElementEditorContext } from "core/editor";

import { UntransformedTabsConfig } from "../../types";
import { useDefaultTabsEditorTranslation } from "../translation";

enum Variants {
  standard = "standard",
  scrollable = "scrollable",
  fullWidth = "fullWidth",
}

export const StylingComponent = memo(() => {
  const {
    elementModel: {
      config: { variant = Variants.standard },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedTabsConfig>();
  const translation = useDefaultTabsEditorTranslation();

  const handleVariantChange = useCallback(
    (newValue: UntransformedTabsConfig["variant"]) =>
      changeConfigValue("variant", newValue),
    [changeConfigValue],
  );

  const handleChange = (_e: ChangeEvent, value: string) => {
    handleVariantChange(value as UntransformedTabsConfig["variant"]);
  };

  const variants = Object.keys(Variants).map((itemVariant) => (
    <FormControlLabel
      key={itemVariant}
      control={<Radio color="primary" />}
      label={translation[`${itemVariant}Label`]}
      value={itemVariant}
    />
  ));

  return (
    <Section title={translation.stylingTitle} wrapped={true}>
      <RadioGroup row={true} value={variant} onChange={handleChange}>
        {variants}
      </RadioGroup>
    </Section>
  );
});
