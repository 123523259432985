import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  codeClass: {
    minHeight: "350px",

    "& .CodeMirror": {
      minHeight: "100%",
      height: "unset",
    },
  },
  alertOverflow: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));
