import { ComponentType, memo } from "react";
import Element from "../../Element";
import StaticPage from "../../StaticPage";
import { RouterContextProvider } from "../RouterContext";
import { TStaticRoutesComponentsMap } from "../types";

import { MapStateToProps } from "./container";

type ErrorProps = {
  error: string;
};

type Props = ReturnType<MapStateToProps> & {
  loadingComponent: ComponentType;
  errorComponent: ComponentType<ErrorProps>;
  notFoundComponent: ComponentType;
};

export function createRouterComponent(
  staticRoutesComponentsMap: TStaticRoutesComponentsMap,
) {
  const Router = memo<Props>(
    ({
      loadAppMetadata,
      loadingPage,
      pageElement,
      staticPageId,
      notFound,
      location,
      errorComponent: Error,
      notFoundComponent: NotFound,
      loadingComponent: Loading,
      error: loadPageError,
    }) => {
      const error = loadPageError;

      if (error) {
        return <Error error={error} />;
      }

      if (loadAppMetadata.inProgress || loadingPage) {
        return <Loading />;
      }

      if (notFound) {
        return <NotFound />;
      }

      if (staticPageId !== null) {
        const staticPage = staticRoutesComponentsMap[staticPageId];

        return <StaticPage page={staticPage} />;
      }

      if (pageElement) {
        return (
          <RouterContextProvider value={{ location }}>
            <Element element={pageElement!} />
          </RouterContextProvider>
        );
      }

      return <Loading />;
    },
  );

  Router.displayName = "Router";

  return Router;
}
