import { createUseTranslation } from "core/session/translation";

export const useAppNotificationTranslation = createUseTranslation({
  en: {
    notifications: "User notifications",
    noUser: "System notification",
    noData: "No notifications yet",
    title: "Notifications",
    readNotificationTitle: "Mark as read",
    readAllNotificationsTitle: "Mark all as read",
    latestLabel: "Latest",
  },
  es: {
    notifications: "Notificaciones del usuario",
    noUser: "Notificación del sistema",
    readNotificationTitle: "Marcar como leído",
    readAllNotificationsTitle: "Marcar todo como leído",
  },
});
