import { NumberType } from "./NumberType";
import { Type } from "./Type";
import { TypeError } from "./TypeError";
import { IAutocompleteKey } from "./types";

export class ArrayType extends Type {
  public name: string;

  constructor(
    public readonly valueType: Type,
    description?: string,
  ) {
    super(description);
    this.name = this.generateTypeName();
  }

  private generateTypeName(): string {
    return `Array<${this.valueType.name}>`;
  }

  public validate(value: any): true | TypeError {
    if (!Array.isArray(value)) {
      return new TypeError(value, this, "TODO: detailed error message");
    }
    let result: true | TypeError;
    for (const el of value) {
      result = this.valueType.validate(el);
      if (result !== true) {
        return new TypeError(
          value,
          this,
          `Invalid value "${el}": ${result.message}`,
        );
      }
    }
    return true;
  }

  public getKeyType(key: string): Type | undefined {
    const numKey = Number.parseInt(key, 10);
    if (!isNaN(numKey)) {
      return this.valueType;
    }
    return super.getKeyType(key);
  }

  public getAutocompleteRecord(): Record<string, IAutocompleteKey> {
    return {
      ...super.getAutocompleteRecord(),
      length: {
        type: new NumberType("The amount of elements in the array"),
        isBuiltin: false,
      },
    };
  }
}
