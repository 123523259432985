import { memo } from "react";
import { InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { pathOr } from "ramda";
import { Controller } from "react-hook-form";
import { LayoutDefinition } from "core/types";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";
import IconButton from "../../IconButton";

import { useTranslation } from "../translation";

type Props = {
  name: string;
  layoutDefinition: LayoutDefinition | null;
  defaultValue?: string;
  descriptionText?: string;
};

export const InputText = memo<Props>(
  ({ name, layoutDefinition, defaultValue, descriptionText }) => {
    const translation = useTranslation();
    const { control } = useHookFormContext();

    const value = pathOr("", ["logo", name], layoutDefinition) as string;

    return (
      <Controller
        render={({ field, fieldState: { error } }) => (
          <TextField
            fullWidth={true}
            label={translation[`${name}Label`]}
            error={Boolean(error)}
            helperText={error?.message ?? translation[`${name}Hint`]}
            InputProps={
              descriptionText
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          icon="help_outline"
                          size="small"
                          tooltip={descriptionText}
                        />
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
            {...field}
          />
        )}
        name={`logo.${name}`}
        control={control}
        defaultValue={(value || defaultValue) ?? ""}
      />
    );
  },
);
