/* eslint-disable @typescript-eslint/no-shadow */
import { createSelector } from "reselect";

import { MODULE_NAME as EDITOR_MODULE_NAME } from "./editor/reduxModule/constants";
import { IState as IEditorState } from "./editor/reduxModule/types";
import {
  getActiveSavePointId,
  getIsLayoutChanged,
  getQueryUsedForPages,
  getUiOptions,
} from "./editor/reduxModule/utils.ts";
import { MODULE_NAME as ROUTER_MODULE_NAME } from "./router/reduxModule/constants";
import { ILocation, IState as IRouterState } from "./router/reduxModule/types";
import { MODULE_NAME as SESSION_MODULE_NAME } from "./session/reduxModule/constants";

import { IState as ISessionState } from "./session/reduxModule/types";
import { getUpdatedAppMetadata } from "./session/reduxModule/utils";
import { IPage, QueryToPagesMappingValue } from "./types";
import { selectorScoper } from "./utils/redux";

export const scopeRouterSelector = selectorScoper<IRouterState>([
  ROUTER_MODULE_NAME,
]);
export const scopeEditorSelector = selectorScoper<IEditorState>([
  EDITOR_MODULE_NAME,
]);
export const scopeSessionSelector = selectorScoper<ISessionState>([
  SESSION_MODULE_NAME,
]);

export const loadingPage = (state: any) =>
  scopeRouterSelector(state).loadingPage;
export const staticPageId = (state: any) =>
  scopeRouterSelector(state).staticPageId;
export const isAdminPage = (state: any) =>
  scopeRouterSelector(state).isAdminPage;
export const routerError = (state: any) => scopeRouterSelector(state).error;
export const notFound = (state: any) => scopeRouterSelector(state).notFound;
export const preventLocationChange = (state: any) =>
  scopeRouterSelector(state).preventLocationChange;
export const keysByKeyAliases = (state: any) =>
  scopeRouterSelector(state).keysByKeyAliases;
export const compiledRoutes = (state: any) =>
  scopeRouterSelector(state).compiledRoutes;

export const location = (state: any) => scopeRouterSelector(state).location;
export const params = (state: any) => scopeRouterSelector(state).params;
export const locationWithParams = createSelector(
  [location, params],
  (loc, par) =>
    ({
      ...loc,
      params: par,
    }) as ILocation,
);

export const isStaticPage = createSelector([staticPageId], (id) => id !== null);

export const newPages = (state: any) => scopeEditorSelector(state).newPages;
export const updatedElements = (state: any) =>
  scopeEditorSelector(state).updatedElements;
export const updatedElementById = (state: any, id: string) =>
  scopeEditorSelector(state).updatedElements[id];
export const updatedLayoutDefinition = (state: any) =>
  scopeEditorSelector(state).updatedLayoutDefinition;
export const updatedMenu = (state: any) =>
  scopeEditorSelector(state).updatedMenu;
export const updatedQueryToPagesMapping = (state: any) =>
  scopeEditorSelector(state).updatedQueryToPagesMapping;
export const newRoutes = (state: any) => scopeEditorSelector(state).newRoutes;

export const ui = (state: any) => scopeSessionSelector(state).ui;
export const uiList = (state: any) => scopeSessionSelector(state).uiList;

export const appMetadata = (state: any) =>
  getUpdatedAppMetadata(state, scopeSessionSelector);
export const initialAppMetadata = (state: any) =>
  scopeSessionSelector(state).appMetadata;
export const uiOptions = createSelector(ui, uiList, getUiOptions);
export const loadAppMetadata = (state: any) =>
  scopeSessionSelector(state).loadAppMetadata;
export const token = (state: any) => scopeSessionSelector(state).token;
export const sessionError = (state: any) => scopeSessionSelector(state).error;
export const loggingIn = (state: any) => scopeSessionSelector(state).loggingIn;
export const isLoggedIn = (state: any) =>
  Boolean(scopeSessionSelector(state).token);
export const notifications = (state: any) =>
  scopeSessionSelector(state).notifications;
export const currentLanguage = (state: any) =>
  scopeSessionSelector(state).currentLanguage;
export const isAdmin = (state: any) => scopeSessionSelector(state).isAdmin;
export const additionalInformation = (state: any) =>
  scopeSessionSelector(state).additionalInformation;

export const loadedAtByPage = (state: any) =>
  scopeRouterSelector(state).loadedAtByPage;

export const pageId = (state: any) => scopeRouterSelector(state).page;

export const page = createSelector(
  [pageId, appMetadata, newPages, loadedAtByPage, location],
  (id, appMetadata, newPages, loadedAtByPage, currentLocation) =>
    id && appMetadata
      ? {
          ...(newPages[id] ?? appMetadata.release.definition.pages[id] ?? {}),
          ...(currentLocation?.key && {
            loadedAt: loadedAtByPage[currentLocation.key],
          }),
        }
      : null,
);

export const pageElement = createSelector([page], (p) =>
  p ? p.element : null,
);

export const allCompiledRoutes = createSelector(
  [compiledRoutes, newRoutes],
  (routesCompiled, newRoutes) =>
    newRoutes?.length ? newRoutes : routesCompiled || [],
);

export const allPages = createSelector(
  [appMetadata, newPages],
  (appMetadata, newPages) =>
    Object.keys(newPages).length
      ? newPages
      : (appMetadata?.release.definition.pages as Record<string, IPage>),
);

export const queryToPagesMapping = createSelector(
  [appMetadata, updatedQueryToPagesMapping],
  (
    appMetadata,
    updatedQueryToPagesMapping,
  ): Record<string, QueryToPagesMappingValue> | undefined =>
    updatedQueryToPagesMapping ??
    appMetadata?.release.definition.queryToPagesMapping,
);

export const queryUsedForPages = createSelector(
  [queryToPagesMapping, allPages],
  (queryToPagesMapping, allPages) =>
    getQueryUsedForPages(queryToPagesMapping, allPages),
);

export const isLayoutChanged = createSelector(
  [
    initialAppMetadata,
    updatedElements,
    updatedLayoutDefinition,
    updatedMenu,
    newPages,
  ],
  (
    appMetadata,
    updatedElements,
    updatedLayoutDefinition,
    updatedMenu,
    newPages,
  ) =>
    getIsLayoutChanged(
      appMetadata,
      updatedElements,
      updatedLayoutDefinition,
      updatedMenu,
      newPages,
    ),
);

export const activeSavePointId = (state: any) => {
  const { uiSavePoints, uiReleases } = scopeEditorSelector(state);
  return getActiveSavePointId(uiSavePoints, uiReleases);
};

export const editModeOn = (state: any) => scopeEditorSelector(state).editModeOn;
export const selectedByPageId = (state: any) =>
  scopeEditorSelector(state).selectedByPageId;
export const draggableElementParams = (state: any) =>
  scopeEditorSelector(state).draggableElementParams;
export const isResizing = (state: any) => scopeEditorSelector(state).isResizing;
export const isDragging = (state: any) => scopeEditorSelector(state).isDragging;
export const selected = (state: any) => {
  const pageValue = page(state);
  return pageValue
    ? pageValue?.id
      ? scopeEditorSelector(state).selectedByPageId[pageValue?.id]
      : null
    : null;
};
export const highlighted = (state: any) => {
  const pageValue = page(state);
  return pageValue
    ? pageValue?.id
      ? scopeEditorSelector(state).highlightedByPageId[pageValue.id]
      : null
    : null;
};
export const activeGrid = (state: any) => {
  const pageValue = page(state);
  return pageValue
    ? pageValue?.id
      ? scopeEditorSelector(state).activeGrid[pageValue.id]
      : null
    : null;
};
export const nextElementId = (state: any) =>
  scopeEditorSelector(state).nextElementId;
export const uiReleases = (state: any) => scopeEditorSelector(state).uiReleases;
export const uiSavePoints = (state: any) =>
  scopeEditorSelector(state).uiSavePoints;
export const viewList = (state: any) => scopeEditorSelector(state).viewList;
export const editorErrors = (state: any) => scopeEditorSelector(state).errors;
export const saving = (state: any) => scopeEditorSelector(state).saving;
export const saveError = (state: any) => scopeEditorSelector(state).saveError;
export const releasing = (state: any) => scopeEditorSelector(state).releasing;
export const creatingPage = (state: any) =>
  scopeEditorSelector(state).creatingPage;
export const usedUrls = (state: any) => scopeEditorSelector(state).usedUrls;
export const copiedElements = (state: any) =>
  scopeEditorSelector(state).copiedElements;
