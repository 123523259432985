import { memo } from "react";
import { Box, Card, Typography } from "@mui/material";

import { Helmet } from "react-helmet";
import { ReactFlowProvider } from "reactflow";
import BackButton from "elementTypes/common/BackButton";
import "reactflow/dist/style.css";
import { RoutePaths } from "staticPages/routes";
import { DatabaseListPanel } from "../components/DatabaseListPanel";
import { ToggleDatabasePanel } from "../components/ToggleDatabasePanel.tsx";
import { DatabasePanelPermissionProvider } from "../context/databasePanelPermission";
import { QueryFilterProvider } from "../context/queryFilter";
import { useQueryFilterContext } from "../context/queryFilter/QueryFilterContext.utils.ts";
import { useERDTranslation } from "../erd/translation";
import { useDatabaseData } from "../hooks/databaseData";
import { useDatabaseTranslation } from "../translation";
import { CustomQuery } from "./customQuery";

export const CustomQueryPageComponent = () => (
  <DatabasePanelPermissionProvider>
    <QueryFilterProvider>
      <Component />
    </QueryFilterProvider>
  </DatabasePanelPermissionProvider>
);

const Component = memo(() => {
  const { roleFilter } = useQueryFilterContext();
  const { queryName, model, queries } = useDatabaseData(roleFilter);

  const translation = useDatabaseTranslation();
  const erdTranslation = useERDTranslation();

  return (
    <>
      <Helmet>
        <title>{translation.databasePageTitle}</title>
      </Helmet>
      <ReactFlowProvider>
        <Box
          width="100%"
          minHeight="100%"
          height="100%"
          display="grid"
          gap={1}
          gridTemplateColumns="3fr 1fr"
          gridTemplateRows="min-content 1fr"
        >
          <Box gridColumn="1 / span 1">
            <Card variant="outlined">
              <Box
                display="grid"
                alignItems="center"
                p={1}
                gap={1}
                gridTemplateColumns="3fr 1fr"
              >
                <Box display="flex" alignItems="center">
                  <BackButton isIcon={true} href={RoutePaths.Database} />
                  <Typography variant="h5">
                    {queryName
                      ? erdTranslation.customEditQueryTitle
                      : erdTranslation.customCreateQueryTitle}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Box>
          <ToggleDatabasePanel disable="queries" />
          <Box gridColumn="1 / 1">
            <CustomQuery />
          </Box>
          <DatabaseListPanel
            loading={{
              model: model.isInitialLoading,
              queries: queries.isInitialLoading,
            }}
            entities={model.data}
            queries={queries.data}
          />
        </Box>
      </ReactFlowProvider>
    </>
  );
});
