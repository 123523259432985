import { memo } from "react";
import TextField from "@mui/material/TextField";
import {
  NumberFormatValues,
  NumericFormat,
  PatternFormat,
} from "react-number-format";
import { ConnectedReduxModuleProps } from "core";

import { IconNameType } from "elementTypes/common/MuiIcon";
import {
  EndInputAdornment,
  StartInputAdornment,
} from "../common/InputAdornment";

import { ReduxModule } from "./reduxModule";
import { NumberInput } from "./types";

const DefaultNumberInput = memo<
  ConnectedReduxModuleProps<ReduxModule, NumberInput>
>(
  ({
    value,
    element: {
      i18n: { label },
      config: {
        startAdornment,
        startAdornmentIcon,
        endAdornment,
        endAdornmentIcon,
        precision,
        prefix,
        suffix,
        thousandSeparator,
        format,
      },
    },
    changeValue,
    changeTouched,
    disabled,
    errors,
    required,
  }) => {
    const hasStartAdornment = startAdornment || startAdornmentIcon;
    const getStartAdornment = () => (
      <StartInputAdornment
        icon={startAdornmentIcon as IconNameType}
        text={startAdornment}
      />
    );

    const hasEndAdornment = endAdornment || endAdornmentIcon;
    const getEndAdornment = () => (
      <EndInputAdornment
        icon={endAdornmentIcon as IconNameType}
        text={endAdornment}
      />
    );

    const handleValueChange = (values: NumberFormatValues) =>
      changeValue(values.value);

    const handleTouchedChange = () => {
      changeTouched(true);
    };

    const commonProps = {
      value: value ?? "",
      thousandSeparator,
      prefix,
      suffix,
      decimalScale: precision,
      label,
      disabled,
      helperText: errors,
      error: Boolean(errors),
      required,
      fullWidth: true,
      InputProps: {
        startAdornment: hasStartAdornment && getStartAdornment(),
        endAdornment: hasEndAdornment && getEndAdornment(),
      },
      onValueChange: handleValueChange,
      customInput: TextField,
      onBlur: handleTouchedChange,
    };

    return format ? (
      <PatternFormat
        {...commonProps}
        {...{
          format,
          mask: "_",
        }}
      />
    ) : (
      <NumericFormat {...commonProps} />
    );
  },
);

export default DefaultNumberInput;
