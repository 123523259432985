import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    overflowY: "auto",
  },
  content: {
    overflow: "visible",
  },
  formClass: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  handlerIcon: {
    cursor: "move",
    transform: "rotate(45deg)",
    margin: `0 ${theme.spacing(0.5)} 0 -${theme.spacing()}`,
    padding: theme.spacing(),
  },
  displayCenter: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
  },
}));
