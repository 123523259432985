import { ChangeEvent, memo } from "react";
import { Box, Button, MenuItem, TextField, Tooltip } from "@mui/material";

import { useTranslator } from "core/session/translation";
import { Translation } from "core/types/i18n";
import { TableMetadataStateChange } from "elementTypes/default_table/types";

import { Props as FormProps } from "../container";
import { useActionTranslation } from "../translation";
import { FormConfig, FormTypes } from "../types";
import { ErrorType, FormErrors } from "./FormErrors";

type Props = {
  handleSave: FormProps["save"];
  handleReset: FormProps["reset"];
  onStateChange: FormProps["setStateFieldValue"];
  goBack: () => void;
  isValid: boolean;
  hasChanges: boolean;
  loading: boolean;
  type: FormConfig["type"];
  allowedStateChanges: TableMetadataStateChange[];
  stateFieldValue: string | null;
  hideBackButton?: boolean;
  errors?: {
    load?: ErrorType;
    save?: ErrorType;
  };
};

export const FormActions = memo<Props>(
  ({
    hasChanges,
    isValid,
    loading,
    type,
    allowedStateChanges,
    stateFieldValue,
    hideBackButton,
    handleSave,
    goBack,
    onStateChange,
    handleReset,
    errors,
  }) => {
    const translation = useActionTranslation();
    const { translate } = useTranslator();

    const handleStateChange = (e: ChangeEvent<HTMLInputElement>) =>
      onStateChange(e.target.value);

    const flexBoxStyles = {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    };

    const allowedItems = allowedStateChanges.map(({ to, i18n }) => {
      const description = translate(
        i18n as Translation<"shortDescription">,
      )?.shortDescription;
      const title = translate(i18n as Translation<"title">)?.title;

      return (
        <MenuItem value={`${to}`} key={`${to}`}>
          {description?.length ? (
            <Tooltip title={description}>
              <span>{title ?? description}</span>
            </Tooltip>
          ) : (
            title
          )}
        </MenuItem>
      );
    });

    return (
      <Box {...flexBoxStyles}>
        <Box gap={1} {...flexBoxStyles}>
          {!hideBackButton && (
            <Button
              variant="text"
              onClick={goBack}
              data-testid="default_form_back_button"
            >
              {translation.back}
            </Button>
          )}

          <Button
            data-test-title="default_form_reset_button"
            variant="outlined"
            disabled={!hasChanges || loading}
            onClick={handleReset}
          >
            {translation.reset}
          </Button>
        </Box>
        {errors && Object.values(errors ?? {})?.length && (
          <FormErrors errors={errors} />
        )}
        <Box gap={1} {...flexBoxStyles}>
          {type === FormTypes.create &&
            stateFieldValue &&
            allowedStateChanges.length > 1 && (
              <TextField
                label={translation.stateFieldNameLabel}
                select
                onChange={handleStateChange}
                value={stateFieldValue}
                data-testid="state-picker"
              >
                {allowedItems}
              </TextField>
            )}
          <Button
            data-testid="default_form_save_button"
            color="primary"
            variant="contained"
            sx={{ color: "primary.contrastText" }}
            disabled={!isValid || loading}
            onClick={handleSave}
          >
            {translation.saveAndGo}
          </Button>
        </Box>
      </Box>
    );
  },
);
