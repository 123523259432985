import { buildCustomExpressionValue } from "core";
import { IDefaultElement } from "core/types/element";

import { UntransformedInternalLinkButtonConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedInternalLinkButtonConfig> =
  {
    i18n: {
      en: {
        label: "",
      },
    },
    config: {
      linkTo: {
        pageId: "",
        params: {},
      },
      disabled: buildCustomExpressionValue("false"),
      isIcon: false,
    },
  };
