import { createSelector } from "reselect";
import { buildSimpleUncontrolledInputModule } from "core/utils/buildSimpleUncontrolledInputModule";
import { selectorScoper } from "core/utils/redux";

import { AutocompleteInput } from "../../types";
import { getAutocompleteOptions, getRawValueObject } from "../utils.ts";

export const uncontrolledSelectors = (
  path: string[],
  element: AutocompleteInput,
  selectors: ReturnType<typeof buildSimpleUncontrolledInputModule>["selectors"],
) => {
  const { config } = element;
  const { filter } = element.config;

  const scopeSelector =
    selectorScoper<AutocompleteInput["config"]["defaultValue"]>(path);

  const fixedFilter = (state: any) => filter?.(state);
  const searchInputValue = (state: any) =>
    scopeSelector(state).searchInputValue;
  const loadingOptions = (state: any) => scopeSelector(state).loadingOptions;
  const allOptions = (state: any) => scopeSelector(state).options;
  const optionsError = (state: any) => scopeSelector(state).optionsError;
  const moreDataAvailable = (state: any) =>
    scopeSelector(state).moreDataAvailable;

  const options = createSelector(
    [allOptions, selectors.value],
    (all: string[] | null, val) => getAutocompleteOptions(all, val, config),
  );
  const rawOptions = (state: any) => scopeSelector(state).rawOptions;
  const valueObject = (state: any) => scopeSelector(state).valueObject;

  const rawValueObject = createSelector(
    [rawOptions, selectors.value],
    (raw: Record<string, unknown>[] | null, currentValue) =>
      getRawValueObject(raw, currentValue, config),
  );

  return {
    searchInputValue,
    options,
    loadingOptions,
    moreDataAvailable,
    optionsError,
    valueObject,
    rawOptions,
    rawValueObject,
    fixedFilter,
  };
};
