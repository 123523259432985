import { UseMutationOptions } from "@tanstack/react-query";
import { IAppMetadata, IObjectView, IUi, IUiSavePoint } from "core";
import {
  Query,
  useAuthenticatedMutation,
  useAuthenticatedQuery,
} from "queries/utils";
import AdminService from "services/admin";
import { loadAppMetadata, loadUIList, loadViewList } from "services/api";
import { UICreateAPIData } from "staticPages/admin/pages/apps/pages/create/types";
import { UiEditParams } from "staticPages/admin/pages/apps/pages/edit/types";
import { UiGenerate } from "staticPages/admin/pages/apps/pages/generate/types";
import { QueryKeys } from ".";

const services = AdminService.getInstance();

export const fetchUIList: Query<IUi[]> = {
  queryKey: QueryKeys.fetchUIList,
  queryFn: loadUIList,
};

export const fetchObjectViews: Query<IObjectView[], { role: string }> = {
  queryKey: QueryKeys.fetchObjectViews,
  queryFn: loadViewList,
};

export const fetchUiMetadata: Query<
  IAppMetadata,
  { uiName: string; latest?: boolean }
> = {
  queryKey: QueryKeys.fetchUiMetadata,
  queryFn: loadAppMetadata,
};

export const useDeleteApp = (
  options: UseMutationOptions<unknown, unknown, { uiName: string }>,
) => useAuthenticatedMutation(services.deleteUI, options);

export const useExportApp = (
  options: UseMutationOptions<IAppMetadata, unknown, { uiName: string }>,
) => useAuthenticatedMutation(loadAppMetadata, options);

export const useCreateApp = (
  options: UseMutationOptions<unknown, unknown, UICreateAPIData>,
) => useAuthenticatedMutation(services.createUi, options);

export const useGenerateApp = (
  options: UseMutationOptions<unknown, unknown, UiGenerate>,
) => useAuthenticatedMutation(services.generateUi, options);

export const useEditApp = (
  options: UseMutationOptions<
    IUiSavePoint & { newPagesNumber: number },
    unknown,
    UiEditParams
  >,
) => useAuthenticatedMutation(services.editUi, options);

export const useUIList = () => useAuthenticatedQuery(fetchUIList);

export const useMetadataUi = (uiName: string, enabled = true) =>
  useAuthenticatedQuery(
    fetchUiMetadata,
    {
      uiName,
      latest: true,
    },
    {
      enabled,
    },
  );
