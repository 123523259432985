import { connect } from "react-redux";
import {
  actions as routerActions,
  selectors as routerSelectors,
} from "core/router/reduxModule";
import { actions, selectors } from "core/session/reduxModule";

import { DefaultLayout as DLComponent } from "./component";

export const mapStateToProps = (state: any) => ({
  ui: selectors.ui(state),
  uiOptions: selectors.uiOptions(state),
  location: routerSelectors.location(state),
  isAdminPage: routerSelectors.isAdminPage(state),
  isStaticPage: !!routerSelectors.staticPageId(state),
  page: routerSelectors.page(state),
  isAdmin: selectors.isAdmin(state),
});

export const mapDispatchToProps = {
  ...actions,
  push: routerActions.push,
};

export const DefaultLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DLComponent);
