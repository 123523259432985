import { memo, useCallback } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Section } from "core/editor";

import { useTableEditorTranslation } from "../../translation";
import { TableModel, UntransformedTableConfig } from "../../types";
import { ROWS_PER_PAGE } from "./utils";

type Props = {
  elementModel: TableModel;
  changeConfigValue: (
    key: keyof UntransformedTableConfig,
    value: number,
  ) => any;
};

export const DefaultRowsPerPage = memo<Props>(
  ({ elementModel, changeConfigValue }) => {
    const translation = useTableEditorTranslation();

    const handleChange = useCallback(
      (event: SelectChangeEvent) => {
        changeConfigValue(
          "defaultRowsPerPage",
          parseInt(event.target.value, 10),
        );
      },
      [changeConfigValue],
    );

    const menuItems = (elementModel.config.rowsPerPage ?? ROWS_PER_PAGE).map(
      (value) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      ),
    );

    return (
      <Section
        title={translation.defaultRowsPerPageTitle}
        wrapped={true}
        defaultOpened={false}
      >
        <FormControl fullWidth>
          <Select
            value={
              elementModel.config.defaultRowsPerPage?.toString() ??
              ROWS_PER_PAGE[0].toString()
            }
            onChange={handleChange}
            size="small"
          >
            {menuItems}
          </Select>
        </FormControl>
      </Section>
    );
  },
);
