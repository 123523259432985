export const LANGUAGES = [
  { code: "en", label: "English", locale: "enUS" },
  { code: "de", label: "Deutsch", locale: "deDE" },
  { code: "es", label: "Español", locale: "esES" },
  { code: "it", label: "Italian", locale: "itIT" },
] as const;

export type Language = (typeof LANGUAGES)[number];

export type LanguageCode = Language["code"];

export type Text = string;

export const DEFAULT_LANGUAGE_CODE = "en";
export const DEFAULT_LANGUAGE = LANGUAGES[0];

export type Translation<Key extends keyof any = string> = Partial<
  Record<LanguageCode, Partial<Record<Key, Text>>>
> & { [DEFAULT_LANGUAGE_CODE]: Record<Key, Text> };

export type Translated<Keys extends keyof any = string> = Record<Keys, string>;

export type GetCurrentLanguage = (state: any) => Language;
