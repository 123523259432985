import { ElementGroup, IRawElementType } from "core";

import Component from "./container";
import { StorageImageEditor, defaultElement, editorMetadata } from "./editor";
import { StorageImageConfig } from "./types";

const elementType: IRawElementType = {
  defaultElement,
  name: "storage_image",
  group: ElementGroup.Basic,
  component: Component,
  editable: true,
  configType: StorageImageConfig,
  editorComponent: StorageImageEditor,
  editorMetadata,
};
export default elementType;
