import { memo } from "react";
import Icon from "@mui/icons-material/ZoomOutMap";
import { Box, Chip, Tooltip } from "@mui/material";
import classNames from "classnames";
import { Handle, NodeProps, Position } from "reactflow";

import { useStyles } from "./style";
import { NodeData } from "./types";

export const StateNode = memo<NodeProps<NodeData>>((props) => {
  const {
    data: { label },
    selected,
  } = props;
  const { classes } = useStyles();

  return (
    <Chip
      color={"secondary"}
      variant={selected ? "filled" : "outlined"}
      icon={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="custom-drag-handle"
          width="40px"
          height="40px"
        >
          <Tooltip title="Use handler to drag & drop state">
            <Icon fontSize="small" />
          </Tooltip>
        </Box>
      }
      label={
        <>
          {label}
          <Handle
            className={classes.handleClass}
            position={Position.Right}
            type="source"
            style={{ zIndex: 1 }}
            isValidConnection={(connection) =>
              connection.target !== connection.source
            }
          />
        </>
      }
      className={classNames(classes.node, {
        [classes.nodeBgColor]: !selected,
      })}
    />
  );
});
