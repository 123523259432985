import { memo, useMemo } from "react";
import { TextField } from "@mui/material";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { ConnectedReduxModuleProps } from "core";
import { getDateValue, getTotalTimezoneOffset } from "utils/date";

import { ASTERISK_SYMBOL } from "../common/utils";

import { ReduxModule } from "./reduxModule";
import { DateTimeInput } from "./types";
import { getDefaultFormat, getType, toMoment } from "./utils";

const DefaultDateTimeInput = memo<
  ConnectedReduxModuleProps<ReduxModule, DateTimeInput>
>(
  ({
    value,
    element: {
      config: { showDatePart = true, showTimePart = true },
      i18n: { label },
    },
    changeValue,
    disabled,
    errors,
    minDate,
    maxDate,
    timezone,
    required,
    formatString,
  }) => {
    const timezoneOverride =
      timezone !== null ? timezone : -(new Date().getTimezoneOffset() / 60);

    const type = useMemo(() => {
      return getType(showDatePart, showTimePart);
    }, [showDatePart, showTimePart]);
    const handleChange = (date: Date | null, keyboardInput?: string) => {
      if (keyboardInput) {
        if (formatString && keyboardInput.length === formatString.length) {
          changeValue(moment(keyboardInput, formatString).toISOString());
        } else if (keyboardInput.length === getDefaultFormat(type).length) {
          changeValue(
            moment(
              keyboardInput,
              toMoment(getDefaultFormat(type)),
            ).toISOString(),
          );
        }
      } else {
        if (date === null) {
          changeValue(null);
        } else {
          const dateWithRespectToTimezone = new Date(
            date.valueOf() - getTotalTimezoneOffset(date, timezoneOverride),
          );
          changeValue(dateWithRespectToTimezone.toISOString());
        }
      }
    };

    const errorProps = errors
      ? {
          error: true,
          helperText: errors,
        }
      : {};

    let actualValue = getDateValue(value);

    // `""` and `null` result in the picker not allowing changing the month and year
    // `undefined` works as expected, setting no restrictions
    // edit: actually, `undefined` sets the restrictions to be +- 100 years by default
    const minDateOrUndefined = getDateValue(minDate) || undefined;
    const maxDateOrUndefined = getDateValue(maxDate) || undefined;

    if (actualValue !== null) {
      const dateWithRespectToTimezone = new Date(
        actualValue.valueOf() +
          getTotalTimezoneOffset(actualValue, timezoneOverride),
      );

      actualValue = dateWithRespectToTimezone;
    }

    const inputLabel = (
      <>
        {label}
        {required && ASTERISK_SYMBOL}
      </>
    );

    if (showDatePart && showTimePart) {
      return (
        <DateTimePicker
          value={actualValue}
          label={inputLabel}
          onChange={handleChange}
          disabled={disabled}
          minDate={minDateOrUndefined}
          maxDate={maxDateOrUndefined}
          renderInput={(props) => <TextField fullWidth {...props} />}
          inputFormat={formatString ?? getDefaultFormat(type)}
          {...errorProps}
        />
      );
    }

    if (showDatePart) {
      return (
        <DatePicker
          value={actualValue}
          label={inputLabel}
          onChange={handleChange}
          disabled={disabled}
          minDate={minDateOrUndefined}
          maxDate={maxDateOrUndefined}
          renderInput={(props) => <TextField fullWidth {...props} />}
          inputFormat={formatString ?? getDefaultFormat(type)}
          {...errorProps}
        />
      );
    }

    return (
      <TimePicker
        value={actualValue}
        label={inputLabel}
        onChange={handleChange}
        disabled={disabled}
        renderInput={(props) => <TextField fullWidth {...props} />}
        inputFormat={formatString ?? getDefaultFormat(type)}
        {...errorProps}
      />
    );
  },
);

export default DefaultDateTimeInput;
