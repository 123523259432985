import CodeMirror from "codemirror";

const regexModeDefition = () => {
  // eslint-disable-next-line no-useless-escape
  const otherChar = /^[\^\$\.\+\?\*]/;
  let g = 0;

  const tokenBase = (stream: any) => {
    const ch = stream.next();

    if (ch === "\\" && stream.match(/./, false)) {
      switch (true) {
        case stream.match(/u\w{4}/):
          return "a";
        case stream.match(/u/):
          return "err";
        case stream.match(/x\w{2}/):
          return "a";
        case stream.match(/x/):
          return "err";
        case stream.match(/./):
          return "a";
        default:
          return "a";
      }
    }

    if (ch === "{") {
      if (stream.match(/(\d|\d,\d?)\}/)) {
        return "a";
      }
    }

    if (ch === "[" && stream.match(/[^\]]+\]/)) {
      return "b";
    }

    if (ch === "|") {
      return "g" + g;
    }

    if (ch === "(") {
      // eslint-disable-next-line no-useless-escape
      stream.match(/[\?\!\:]+/);
      return "g" + (++g % 5);
    }

    if (ch === ")") {
      if (g - 1 < 0) {
        return "err";
      }
      return "g" + (g-- % 5);
    }

    if (otherChar.test(ch)) {
      return "a";
    }
    return null;
  };

  return {
    startState: () => (g = 0),
    token: tokenBase,
  };
};

CodeMirror.defineMode("regex", regexModeDefition);

CodeMirror.defineMIME("text/x-regex", "regex");
