import { ChangeEvent, memo } from "react";
import { Box, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { adaptColor } from "elementTypes/common/StyledTypography/utils";
import { IColor } from "../../types";

import { useColorsTranslation } from "./translation";

type IColorSelect = {
  value: string;
  onChange: (value: string) => void;
  colorItems: IColor[];
  defaultValue: string;
  outlined?: boolean;
  background?: boolean;
  label?: string;
};

export const ColorSelect = memo<IColorSelect>(
  ({
    colorItems,
    defaultValue,
    outlined,
    value,
    label,
    background,
    onChange,
  }) => {
    const translation = useColorsTranslation();
    const isBgColor = background || defaultValue === "transparent";
    const items = colorItems.map((item) => (
      <MenuItem
        key={item}
        value={item}
        selected={item === value}
        sx={{
          px: 0,
          py: 0.1,
        }}
      >
        <Box
          width="100%"
          height="100%"
          sx={{
            bgcolor: isBgColor
              ? adaptColor(item)
              : item === "white"
                ? "black"
                : "default",
          }}
        >
          <Typography
            color={
              item === "transparent" || (isBgColor && !outlined)
                ? item === "white" || item === "transparent"
                  ? "black"
                  : "white"
                : adaptColor(item)
            }
            padding={1}
          >
            {translation[item]}
          </Typography>
        </Box>
      </MenuItem>
    ));
    const onColorChange = (e: ChangeEvent<HTMLInputElement>) => {
      const newVal = e.target.value;
      onChange(newVal === "transparent" ? "" : newVal);
    };

    return (
      <TextField
        select
        fullWidth
        value={value ?? defaultValue}
        onChange={onColorChange}
        size="small"
        label={label}
      >
        {items}
      </TextField>
    );
  },
);
