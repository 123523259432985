import { memo } from "react";
import { Translation } from "core";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { DialogWrapperProps } from "elementTypes/helpers/HOC/DialogWrapper/component.tsx";
import { humanize } from "utils/string.ts";
import { useEchartsTranslation } from "../../../translation.ts";
import { LegendTranslationEditor } from "./LegendTranslationEditor.tsx";
import { TDialogProps } from "./types.ts";

export const LegendLabelsDialog = memo<TDialogProps>(
  ({ columnName, handleCloseDialog, changeTranslation, i18n }) => {
    const { editLegendLabel, editButton, cancelButton } =
      useEchartsTranslation();

    const handleSubmit = (data: Record<string, unknown>) => {
      const { i18n: formI18n } = data as { i18n: Translation };
      changeTranslation(formI18n);

      handleCloseDialog();
    };

    const dialogProps: DialogWrapperProps = {
      isForm: true,
      keepMounted: false,
      open: !!columnName,
      handleClose: handleCloseDialog,
      cancelTitle: cancelButton,
      submitDisabled: true,
      maxWidth: "sm",
      fullWidth: true,
      title: `${editLegendLabel} ${humanize(columnName ?? "")}`,
      submitTitle: editButton,
      handleSubmit,
      children: <LegendTranslationEditor columnName={columnName} i18n={i18n} />,
    };

    return columnName ? <DialogWrapper {...dialogProps} /> : null;
  },
);
