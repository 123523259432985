import { createUseTranslation } from "core/session/translation";

export const placementEditorTranslation = {
  en: {
    title: "Placement",
    topLabel: "Top",
    bottomLabel: "Bottom",
    startLabel: "Start",
    endLabel: "End",
  },
};

export const usePlacementEditorTranslation = createUseTranslation(
  placementEditorTranslation,
);
