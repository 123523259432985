import {
  ChangeEvent,
  ComponentProps,
  PropsWithChildren,
  memo,
  useCallback,
} from "react";
import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { JSONSchema6 } from "json-schema";
import isBoolean from "lodash/isBoolean";
import { Section, useElementEditorContext } from "core/editor";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces";
import { FormInputConfig } from "elementTypes/common";

import {
  buildCustomExpressionValue,
  getBooleanExpressionValue,
} from "../../../types";
import { useParentForm } from "../../hooks/useParentForm";
import { useEditorTranslation } from "../../translation";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "../CustomExpressionEditor";

export const FormInputConfigEditorComponent = memo<PropsWithChildren>(
  ({ children }) => {
    const {
      elementModel: {
        config: { disabled = false, nullable, dataSource },
        config,
      },
      changeConfigValue,
    } = useElementEditorContext<
      FormInputConfig & WithOptionalFieldDataSourceConfig
    >();
    const editorTranslation = useEditorTranslation();

    const handleDisabledChange = useCallback(
      (newValue: unknown) => changeConfigValue("disabled", newValue),
      [changeConfigValue],
    );
    const handleNullableChange = useCallback(
      (newValue: FormInputConfig["nullable"]) =>
        changeConfigValue("nullable", newValue),
      [changeConfigValue],
    );

    const handleToggleModeDisabled = (isExpression: boolean) => {
      const nextVal = isExpression
        ? getBooleanExpressionValue(String(disabled))
        : buildCustomExpressionValue(String(disabled));
      handleDisabledChange(nextVal);
    };

    const handleNullableInputChange = () => handleNullableChange(!nullable);

    const { parentJSONSchema, parentIsReadOnly } = useParentForm();
    const requiredFields = (parentJSONSchema as JSONSchema6)?.required ?? [];

    // since we don't have nesting fields validation so far,
    // take the first field in the path
    const fieldName = dataSource?.fieldPath?.[0]?.toString() ?? "";
    const isRequiredInForm = requiredFields.includes(fieldName);

    const isNullable = nullable === false;

    const disabledNonExpressionEditor: ComponentProps<
      typeof CustomExpressionEditor
    >["nonExpressionEditor"] = useCallback(
      ({ value, onChange }: NonExpressionEditorProps<boolean>) => {
        const handleChange = (
          _event: ChangeEvent<HTMLInputElement>,
          checked: boolean,
        ) => onChange(checked);

        return (
          <FormControlLabel
            control={
              <Switch
                checked={
                  parentIsReadOnly ||
                  (isBoolean(value) ? value : value === "true")
                }
                onChange={handleChange}
              />
            }
            label={editorTranslation.disabledLabel}
          />
        );
      },
      [editorTranslation.disabledLabel, parentIsReadOnly],
    );

    return (
      <Section title={editorTranslation.inputConfigTitle} wrapped={true}>
        <Box display="flex" flexDirection="column" paddingTop={1} gap={1}>
          {children}
        </Box>
        <CustomExpressionEditor
          value={String(disabled)}
          config={config}
          nonExpressionEditor={disabledNonExpressionEditor}
          onToggleMode={handleToggleModeDisabled}
          onChange={handleDisabledChange}
          label={editorTranslation.disabledLabel}
          forceFlexChange
          isBoolean
        />
        {(!dataSource?.elementId || isRequiredInForm) && (
          <FormControlLabel
            control={
              <Switch
                checked={isRequiredInForm ? false : !isNullable}
                onChange={handleNullableInputChange}
              />
            }
            label={editorTranslation.nullableLabel}
            disabled={isRequiredInForm}
          />
        )}
      </Section>
    );
  },
);
