import { ChangeEvent, memo, useCallback } from "react";
import { Stack } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import capitalize from "lodash/capitalize";
import { Section, useElementEditorContext } from "core/editor";

import { Autocomplete } from "../../../common/Autocomplete";
import { IAutocompleteValue } from "../../../common/Autocomplete/types";
import { UntransformedSliderInputConfig } from "../../types";
import { useSliderInputEditorTranslation } from "../translation";

import { Marks } from "./Marks";
import { useStyles } from "./styles";

const valueLabelDisplayOptions = ["on", "off", "auto"];
const trackOptions = ["normal", "inverted", false];
const valueLabelDisplayAutocompleteOptions = valueLabelDisplayOptions.map(
  (valueLabelDisplayOption) => ({
    value: valueLabelDisplayOption,
    label: capitalize(valueLabelDisplayOption),
  }),
);
const trackAutocompleteOptions = trackOptions.map((trackOption) => ({
  value: trackOption,
  label: capitalize(String(trackOption)),
}));

export const AdvancedComponent = memo(() => {
  const {
    elementModel: {
      config: { min, max, marks, step, track, valueLabelDisplay },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedSliderInputConfig>();
  const translation = useSliderInputEditorTranslation();

  const { classes } = useStyles();

  const handleChange = useCallback(
    (...params: Parameters<typeof changeConfigValue>) =>
      changeConfigValue(...params),
    [changeConfigValue],
  );

  const handleNumberInputChange =
    (numberInputName: "min" | "max" | "step") =>
    ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) => {
      const value = Number(newValue);
      handleChange(numberInputName, value);
    };
  const handleValueLabelDisplayInputChange = (value: IAutocompleteValue) => {
    handleChange("valueLabelDisplay", value || (undefined as any));
  };
  const handleTrackInputChange = (value: IAutocompleteValue) => {
    handleChange("track", value || (undefined as any));
  };

  const handleMarksInputChange = () => handleChange("marks", !marks);

  return (
    <>
      <Section title={translation.advancedTitle} wrapped={true}>
        <Stack spacing={2}>
          <TextField
            value={min}
            name="min"
            type="number"
            label={translation.minInputLabel}
            fullWidth={true}
            onChange={handleNumberInputChange("min")}
          />
          <TextField
            className={classes.topMargin}
            value={max}
            name="max"
            type="number"
            label={translation.maxInputLabel}
            fullWidth={true}
            onChange={handleNumberInputChange("max")}
          />
          <TextField
            className={classes.topMargin}
            value={step}
            name="step"
            type="number"
            label={translation.stepInputLabel}
            fullWidth={true}
            onChange={handleNumberInputChange("step")}
          />
          <Autocomplete
            options={valueLabelDisplayAutocompleteOptions}
            onChange={handleValueLabelDisplayInputChange}
            value={valueLabelDisplay}
            name="valueLabelDisplay"
            label={translation.valueLabelDisplayInputLabel}
            isLoading={false}
            virtualizedList={true}
            isClearable={true}
          />
          <Autocomplete
            options={trackAutocompleteOptions}
            onChange={handleTrackInputChange}
            value={track}
            name="track"
            label={translation.trackInputLabel}
            isLoading={false}
            virtualizedList={true}
            isClearable={true}
          />
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(marks)}
                onChange={handleMarksInputChange}
              />
            }
            label={translation.marksLabel}
          />
        </Stack>
      </Section>
      {Boolean(marks) && <Marks />}
    </>
  );
});
