import { Theme, alpha, emphasize, lighten } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

interface StyleProps {
  size: number | string;
}

const formatSize = ({ size }: StyleProps) =>
  typeof size === "string" ? size : `${size}px`;

export const useStyles = makeStyles<StyleProps>()((theme: Theme, { size }) => ({
  root: {
    display: "inline-flex",
    position: "relative",
    width: formatSize({ size }),
    height: formatSize({ size }),
  },
  labelClass: {
    position: "absolute",
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  },
  progressCircle: {
    "&:not([class*='MuiCircularProgress-colorPrimary']):not([class*='MuiCircularProgress-colorSecondary'])":
      {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[800]
            : theme.palette.grey[300],
      },
    strokeLinecap: "round",
    zIndex: 1,
  },
  backgroundCircle: {
    position: "absolute",
    zIndex: 0,
    color: emphasize(
      theme.palette.mode === "light"
        ? theme.palette.grey[400]
        : theme.palette.grey[700],
      0.08,
    ),
    "&[class*='MuiCircularProgress-colorPrimary']": {
      color: lighten(alpha(theme.palette.primary.main, 1), 0.85),
    },
    "&[class*='MuiCircularProgress-colorSecondary']": {
      color: lighten(alpha(theme.palette.secondary.main, 1), 0.85),
    },
  },
}));
