import { FC, memo } from "react";
import { Box } from "@mui/material";
import { Alert } from "@mui/material";
import { HookForm } from "elementTypes/common/HookForm";

import { FormContent } from "./FormContent";
import { useStateFormTranslations } from "./translation";
import { UIStateForm } from "./types";

interface StateFormProps {
  onSubmit: (data: Record<string, unknown>) => void;
  onDelete?: () => void;
  node?: UIStateForm;
  stateChangesFound?: boolean;
  inProgress?: boolean;
}

const StateForm: FC<StateFormProps> = ({
  node,
  stateChangesFound,
  inProgress,
  onSubmit,
  onDelete,
}) => {
  const { noStateTransitions } = useStateFormTranslations();

  return (
    <HookForm
      onSubmit={onSubmit}
      boxProps={{ bgcolor: "background.paper", height: "100%" }}
    >
      <FormContent node={node} onDelete={onDelete} inProgress={inProgress} />
      {!!node?.state && !stateChangesFound && (
        <Box mt={2}>
          <Alert severity="warning">{noStateTransitions}</Alert>
        </Box>
      )}
    </HookForm>
  );
};

const MemoizedStateForm = memo(StateForm);

export default MemoizedStateForm;
