import { Translation } from "../types";

function createTranslation<Keys extends keyof any>(
  translation: Translation<Keys>,
) {
  return translation;
}

// Type hack to correctly type Translations
export const sessionTranslation = createTranslation({
  en: {
    loggedIn: "Logged in",
    loggedOut: "Logged out",
    success: "Success",
    error: "Error",
  },
});
