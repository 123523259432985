import { memo } from "react";
import Box from "@mui/material/Box";

import { StyledTypography } from "../../../../../elementTypes/common/StyledTypography";
import { useModelBuilderTranslation } from "./translation";

export const StyledSchemaTableName = memo<{
  schemaName: string;
  tableName: string;
  stateColumn?: string | null;
  color?: "error";
}>(({ schemaName, tableName, stateColumn, color }) => {
  const translation = useModelBuilderTranslation();
  return (
    <Box display="inline-flex">
      <StyledTypography
        fitContent={true}
        typographyProps={{ variant: "h6" }}
        text={schemaName}
        tooltip={translation.schemeTooltip}
        color={"primary"}
      />
      <StyledTypography
        fitContent={true}
        typographyProps={{ variant: "h6" }}
        text={`.${tableName}`}
        tooltip={translation.tableTooltip}
        color={"primary"}
      />
      {stateColumn ? (
        <StyledTypography
          fitContent={true}
          typographyProps={{ variant: "h6" }}
          text={`.${stateColumn}`}
          tooltip={`${color === "error" ? "Error: Column is not exist!" : translation.stateColumnTooltip}`}
          color={color ?? "primary"}
        />
      ) : null}
    </Box>
  );
});
