import { MouseEvent, lazy, memo, useMemo, useRef, useState } from "react";
import { Box, Card, Divider, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { ReactFlowProvider, useReactFlow } from "reactflow";
import "reactflow/dist/style.css";

import { actions as routerActions } from "core/router/reduxModule";
import BackButton from "elementTypes/common/BackButton";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { QueryKeys, useDeleteWorkflow, useWorkFlow } from "queries/admin";
import { getApiError } from "queries/utils";
import { useAdminContext } from "staticPages/admin/context";
import { RoutePaths } from "staticPages/routes";
import { useLocation, useSnackbar } from "utils/hooks";

import Button from "../../../../../elementTypes/common/Button";
import IconButton from "../../../../../elementTypes/common/IconButton";
import { LoadingComponent } from "../../../../../layouts/common/Loading";
import { LayoutOption, LayoutOptions } from "../component";
import { StyledSchemaTableName } from "../components/StyledSchemaTableName";
import { ToggleLayoutPanel } from "../components/ToggleLayoutPanel";

import { EnforceWorkflow } from "./components";
import { RightPanel } from "./components";
import { WorkflowViewer } from "./components";
import { SaveAsImageButton } from "./components/SaveAsImageButton";
import { State, Workflow } from "./components/StateViewer/types";
import {
  getEditableState,
  getNodeStateData,
} from "./components/StateViewer/utils";
import { useWorkflowTranslations } from "./translation";

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

const getStates = (workflow: Workflow) =>
  (workflow?.states ?? []).map((s: State) => {
    const isEditable = getEditableState(workflow?.stateChanges);
    return getNodeStateData(s, isEditable);
  });

export const WorkflowPageComponent = memo(() => (
  <ReactFlowProvider>
    <PageComponent />
  </ReactFlowProvider>
));

const PageComponent = memo(() => {
  const translation = useWorkflowTranslations();
  const [direction, setDirection] = useState<LayoutOption>(
    LayoutOptions.horizontal,
  );

  const location = useLocation();
  const {
    queries: { schema, table },
  } = location;

  const workflowResponse = useWorkFlow(
    {
      schema,
      table,
    },
    {
      enabled: !!schema && !!table,
    },
  );

  const workflow = workflowResponse?.data;

  const elementToImageRef = useRef<null | HTMLDivElement>(null);

  const getStateById = (id: string) =>
    workflow?.states?.find((state) => String(state.id) === id);

  const stateList = useMemo(
    () => (workflow ? getStates(workflow) : undefined),
    [workflow],
  );

  if (workflowResponse.isLoading) {
    return <LoadingComponent />;
  }

  const onChangeLayout = (_: any, nextValue: LayoutOption) =>
    nextValue && setDirection(nextValue);

  return (
    <Box
      width="100%"
      height="100%"
      maxHeight="100%"
      display="grid"
      gap={1}
      gridTemplateColumns="3fr minmax(300px, 1fr)"
      gridTemplateRows="min-content 1fr"
      p={0.5}
    >
      <Box gridColumn="1 / span 2">
        <Card variant="outlined">
          <Box display="flex" alignItems="center" p={1}>
            <BackButton isIcon={true} href={RoutePaths.Database} />
            <Box display="flex" flexDirection="column">
              <Typography variant="h5">{translation.workflowTitle}</Typography>
              <StyledSchemaTableName
                schemaName={schema}
                tableName={table}
                stateColumn={workflow?.stateColumn}
                color={workflow?.isValid ? undefined : "error"}
              />
            </Box>
            <Box
              flex="1"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <ToggleLayoutPanel onChange={onChangeLayout} value={direction} />
              {workflow ? (
                <>
                  <Divider orientation="vertical" flexItem={true} />
                  <SaveAsImageButton
                    fileName={`${schema}-${table}-${workflow?.stateColumn}`}
                    elementToImageRef={elementToImageRef}
                  />
                </>
              ) : null}
              <Divider orientation="vertical" flexItem={true} />
              <EnforceWorkflow value={!!workflow?.workflowActivated} />
              <Divider orientation="vertical" flexItem={true} />
              <CreateNewStateBtn />
              <Divider orientation="vertical" flexItem={true} />
              <DeleteWorkflowButton schema={schema} table={table} />
            </Box>
          </Box>
        </Card>
      </Box>
      <Box gridColumn="1 / 1" overflow="auto">
        {workflow ? (
          <WorkflowViewer
            directionValue={direction}
            {...{
              schema,
              table,
              getStateById,
              workflow,
              elementToImageRef,
            }}
          />
        ) : (
          <span />
        )}
      </Box>
      <Box
        gridColumn="2 / 2"
        gridRow="2"
        display="flex"
        flexDirection="column"
        gap={1}
        overflow="auto"
        height="100%"
      >
        <RightPanel list={stateList} />
      </Box>
    </Box>
  );
});

const newNode = {
  id: "newElement",
  data: { label: "New State" },
  type: "new",
  position: {
    x: 0,
    y: 0,
  },
  selected: true,
};

export const CreateNewStateBtn = memo<{ isIcon?: boolean }>(({ isIcon }) => {
  const translation = useWorkflowTranslations();
  const { addNodes } = useReactFlow();

  const handleClick = () => addNodes(newNode);

  return isIcon ? (
    <IconButton
      icon="add"
      onClick={handleClick}
      color="primary"
      tooltip={translation.newState}
    />
  ) : (
    <Button
      label={translation.newState}
      onClick={handleClick}
      iconRight="add"
      color="primary"
    />
  );
});

const DeleteWorkflowButton = memo<{ schema: string; table: string }>(
  ({ schema, table }) => {
    const translation = useWorkflowTranslations();
    const showSnackbar = useSnackbar();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { setWorkflowView } = useAdminContext();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const { mutate: deleteWorkFlow } = useDeleteWorkflow({
      onError: (error) => {
        const msg = getApiError(error);
        showSnackbar(msg, "error");
      },
      onSuccess: () => {
        showSnackbar(translation.workflowDeleted, "success");
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchModel] });
        dispatch(routerActions.push(RoutePaths.Database));

        setWorkflowView({
          path: [schema, table],
          value: null,
        });
      },
    });

    const handleClose = () => setAnchorEl(null);

    const handleDelete = () =>
      deleteWorkFlow({
        schema,
        table,
      });

    const handleOpen = (event: MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(event.currentTarget);

    return (
      <>
        <IconButton
          icon="delete_outline"
          tooltip={translation.deleteWorkflowTitle}
          onClick={handleOpen}
        />
        <Popover
          anchorEl={anchorEl}
          onClose={handleClose}
          actionsAlign="center"
          actions={
            <>
              <Button
                id="delete-element-pop-up-confirm"
                label={translation.deleteButton}
                color="error"
                onClick={handleDelete}
              />
              <Button
                label={translation.cancelButton}
                onClick={handleClose}
                id="delete-element-pop-up-cancel"
              />
            </>
          }
        >
          <Typography>{translation.deleteWorkflowConfirmation}</Typography>
        </Popover>
      </>
    );
  },
);
