import { Theme } from "@mui/material";

import { makeStyles } from "tss-react/mui";

import { Props } from "./component";

const useStyles = makeStyles<Pick<Props, "menuWidth">>()(
  (theme: Theme, { menuWidth }) => ({
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
      willChange: "margin, width",
    },
    appBarShift: {
      transition: theme.transitions.create(["margin", "width"], {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.easeOut,
      }),
      width: `calc(100% - ${menuWidth})`,
      marginLeft: menuWidth,
    },
    divider: {
      height: `calc(100% - ${theme.spacing(2)})`,
    },
  }),
);

export default useStyles;
