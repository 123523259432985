import { memo } from "react";
import { useCreateInitialGridElement } from "utils/hooks/useCreateInitialElement";

import { FormConfig } from "../types";

import { FormEditorComponent } from "./component";

export const FormEditor = memo(() => {
  useCreateInitialGridElement<FormConfig>("form_grid");

  return <FormEditorComponent />;
});
