import { createUseTranslation } from "core/session/translation";

export const useClipboardCopyButtonEditorTranslation = createUseTranslation({
  en: {
    valueLabel: "Value",
    iconLabel: "Icon",
    displaySectionTitle: "Display",
    stylingSectionTitle: "Styling",
    colorInputLabel: "Color",
    variantInputLabel: "Variant",
    primaryLabel: "Primary",
    secondaryLabel: "Secondary",
    defaultLabel: "Default",
    inheritLabel: "Inherit",
    containedLabel: "Contained",
    outlinedLabel: "Outlined",
    disabledLabel: "Show Disabled",
  },
});
