import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "File Input",
      description: "",
    },
  },
  minSize: {
    height: 1,
    width: 3,
  },
};
