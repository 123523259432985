import { RoutePaths } from "../../../routes";
import { RepositoryConfigurationPage } from "./component";
import { FormPageRoute } from "./Form";

export const route = {
  Component: RepositoryConfigurationPage,
  auth: true,
  isAdmin: true,
  routes: {
    [RoutePaths.RepositoryConfigGet]: FormPageRoute,
  },
};
