import { memo } from "react";
import MUITableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Element, IElement } from "core";

import { useHeaderStyles } from "../style";

type Props = {
  elements: IElement[];
};

export const TableHead = memo<Props>(({ elements }) => {
  const {
    classes: { head },
  } = useHeaderStyles();
  const headerItems = elements.map((col: IElement) => (
    <Element key={`${col.name}-${col.id}`} element={col} />
  ));

  return (
    <MUITableHead className={head}>
      <TableRow>{headerItems}</TableRow>
    </MUITableHead>
  );
});

TableHead.displayName = "TableHead";
