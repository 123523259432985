import { memo } from "react";
import {
  Filter,
  Legend,
  Limit,
  ToolboxAndColors,
} from "elementTypes/common/Echarts/editor";

import {
  ModeComponent,
  NoDataStyles,
  SetupComponent,
  SortComponent,
  TranslationComponent,
} from "./components";

export const EchartsPieChartEditor = memo(() => {
  return (
    <>
      <SetupComponent />
      <TranslationComponent />
      <SortComponent />
      <Limit />
      <Legend />
      <ToolboxAndColors>
        <NoDataStyles />
      </ToolboxAndColors>
      <ModeComponent />
      <Filter />
    </>
  );
});
