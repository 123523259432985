import {
  IElementType,
  buildElementTypeGetter,
  compileElementTypes,
} from "core";
import { elementTypes as rawElementTypes } from "./elements";

export const elementTypes = compileElementTypes(rawElementTypes);
const getElementByType = buildElementTypeGetter(elementTypes);
export const getElementType = getElementByType;

export const availableElementTypes = Object.keys(elementTypes).reduce(
  (a, n) => {
    const elementType = elementTypes[n];
    return elementType?.defaultElement &&
      elementType.editable !== false &&
      elementType.name !== "default_grid"
      ? { ...a, [n]: elementType }
      : a;
  },
  {} as Record<string, IElementType>,
);
