import { memo } from "react";

import { TableForm } from "../common/TableForm/component";
import { Props } from "./types";

const DefaultSubFormTable = memo<Props>((props) => {
  const {
    changeValue,
    value: data,
    element: {
      config: {
        fields,
        dataSource: { fieldPath },
      },
      i18n: { label },
    },
    errors,
    disabled,
    columnVisibilityModel,
    selected: { identifier },
    identifierFieldName,
    changeSelectionModel,
  } = props;

  if (!fields.length) {
    return null;
  }

  return (
    <TableForm
      title={label || (fieldPath[0] as string)}
      onDataUpdate={changeValue}
      {...{
        data,
        fields,
        errors,
        disabled,
        columnVisibilityModel,
        changeSelectionModel,
        selected: {
          field: identifierFieldName,
          value: identifier,
        },
      }}
    />
  );
});

export default DefaultSubFormTable;
