import { ChangeEvent, memo, useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { Section, useElementEditorContext } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";

import { UntransformedJsonFieldConfig } from "../types";

import { useJsonFieldEditorTranslation } from "./translation";

export const JsonFieldEditor = memo(() => {
  const {
    elementModel: {
      id,
      config,
      config: { value, collapsed, enableClipboard },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedJsonFieldConfig>();
  const translation = useJsonFieldEditorTranslation();

  const handleExpressionValueChange = useCallback(
    (newValue: string | number | boolean) =>
      changeConfigValue("value", String(newValue)),
    [changeConfigValue],
  );
  const handleCollapsedInputChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: boolean) =>
      changeConfigValue("collapsed", newValue),
    [changeConfigValue],
  );
  const handleEnableClipboardInputChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: boolean) =>
      changeConfigValue("enableClipboard", newValue),
    [changeConfigValue],
  );

  return (
    <>
      <Section title={translation.valueLabel} wrapped={true}>
        <TableColumnEditor
          id={id}
          value={value}
          onChange={handleExpressionValueChange}
          allowedDataTypeIsArray={false}
          allowedDataTypes={["json"]}
        />
        <CustomExpressionEditor
          value={value}
          config={config}
          onChange={handleExpressionValueChange}
          disableSwitcher
        />
      </Section>

      <Section title={"Advanced"} wrapped={true}>
        <FormGroup row={true}>
          <FormControlLabel
            control={
              <Switch
                checked={collapsed}
                onChange={handleCollapsedInputChange}
              />
            }
            label={translation.collapsedLabel}
          />
        </FormGroup>
        <FormGroup row={true}>
          <FormControlLabel
            control={
              <Switch
                checked={enableClipboard}
                onChange={handleEnableClipboardInputChange}
              />
            }
            label={translation.enableClipboardLabel}
          />
        </FormGroup>
      </Section>
    </>
  );
});
