import { Translated } from "core";

export const getTabTranslationName = (index: number) => `tab${index}`;

export const deleteTabTranslation = (
  translation: Translated<string>,
  index: number,
) => {
  const newTranslation = Object.keys(translation).reduce(
    (acc, key, currentIndex) => {
      if (currentIndex !== index) {
        const newIndex = currentIndex > index ? currentIndex - 1 : currentIndex;
        acc[`tab${newIndex}`] = translation[key];
      }
      return acc;
    },
    {} as Translated<string>,
  );

  return newTranslation;
};

export const getNewTabParams = (order: number) => ({
  name: "",
  defaultTab: false,
  isNew: true,
  index: order - 1,
  config: {},
});

export const migrateLabelsToTranslation = (labels: string[]) => {
  const translation = labels.reduce((acc, curr, index) => {
    acc[getTabTranslationName(index)] = curr;
    return acc;
  }, {} as Translated<string>);

  return translation;
};
