import { ChangeEvent, memo, useCallback, useMemo, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { dissocPath } from "ramda";
import { useSelector } from "react-redux";
import { IPage, UntransformedConfig } from "core";
import { getPageElementsByType, useEditorTranslation } from "core/editor";
import { PageSelector } from "core/editor/common/PageSelector/container";
import { selectors as editorSelectors } from "core/editor/reduxModule";
import IconButton from "elementTypes/common/IconButton";

import { usePage } from "utils/hooks";
import { capitalizeFirstLetter, kebabToCamelCase } from "utils/string";
import { Autocomplete } from "../Autocomplete";
import { AutocompleteOption, IAutocompleteValue } from "../Autocomplete/types";

import { useTranslation } from "./translation";
import {
  ActionConfigType,
  ActionConfigsType,
  ActionType,
  ElementsWithActionConfigType,
  NotificationVariant,
} from "./types";

type ActionConfigEntryProps = {
  entry: ActionConfigType;
  options: AutocompleteOption[];
  elementConfig: UntransformedConfig<ElementsWithActionConfigType>;
  onChange: (entry: ActionConfigType) => void;
  onDelete: () => void;
  divided?: boolean;
};

const getDefaultValues = {
  [ActionType.show_notification]: { notification: { message: "" } },
  [ActionType.navigate]: { linkTo: { pageId: "", params: {} } },
  [ActionType.go_back]: {},
} as const;

type ValueKey = "show_notification" | "navigate" | "go_back";

const ActionConfigEntry = memo(
  ({
    entry,
    options,
    divided,
    elementConfig,
    onChange,
    onDelete,
  }: ActionConfigEntryProps) => {
    const { typeLabel } = useEditorTranslation();
    const {
      notificationVariantLabel,
      notificationMessageLabel,
      notificationPersistLabel,
      notificationMessagePlaceholder,
      ...translation
    } = useTranslation();

    const [open, setOpen] = useState(
      entry.type === ActionType.show_notification ||
        Boolean(entry?.notification?.message),
    );

    const withElementIDType = !Object.keys(getDefaultValues).includes(
      entry.type,
    );

    const handleTypeChange = (value: IAutocompleteValue) => {
      const defaultAction = {
        ...entry,
        elementId: "elementId" in entry ? entry.elementId : "",
      };

      const key = value as ValueKey;

      const nextEntry = {
        ...(getDefaultValues[key] ?? defaultAction),
        notification: entry["notification"],
        type: value as ActionConfigType["type"],
      } as ActionConfigType;

      onChange(nextEntry);

      setOpen(
        value === ActionType.show_notification ||
          !!nextEntry?.notification?.message?.trim(),
      );
    };

    const actionTypeList = Object.keys(ActionType).map((value) => ({
      value,
      label: translation[`${kebabToCamelCase(value)}Label`],
    }));

    const notificationVariants = Object.keys(NotificationVariant).map(
      (notificationVariant) => ({
        value: notificationVariant,
        label:
          translation[
            `notification${capitalizeFirstLetter(notificationVariant)}Label`
          ],
      }),
    );

    const changeNotificationMessage = ({
      target: { value: newValue },
    }: ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...entry,
        notification: {
          ...entry.notification,
          message: newValue,
        },
      });
    };

    const changeNotificationVariant = (value: IAutocompleteValue) => {
      onChange({
        ...entry,
        notification: {
          ...entry.notification,
          message: entry.notification?.message ?? "",
          variant: value as NotificationVariant,
        },
      });
    };

    const togglePersist = (
      _: ChangeEvent<HTMLInputElement>,
      checked: boolean,
    ) => {
      onChange({
        ...entry,
        notification: {
          ...entry.notification,
          message: entry.notification?.message ?? "",
          persist: checked,
        },
      });
    };

    const handleChange = (value: IAutocompleteValue) => {
      onChange({
        ...entry,
        elementId: (value || "") as string,
      } as ActionConfigType);
    };

    const toggleNotification = (_: any, nextOpen: boolean) => {
      setOpen(nextOpen);

      if (!nextOpen) {
        onChange({
          ...entry,
          notification: undefined,
        } as ActionConfigType);
      }
    };

    const handlePageChange = useCallback(
      (page: IPage, newParams: any) => {
        if (entry.type !== ActionType.navigate) {
          return;
        }

        onChange({
          ...entry,
          linkTo: {
            pageId: page.id,
            params: newParams,
          },
        });
      },
      [entry, onChange],
    );

    const navigateConfig = entry.type === ActionType.navigate && (
      <Box py={0.5}>
        <PageSelector
          pageId={entry.linkTo.pageId}
          params={entry.linkTo.params}
          config={elementConfig}
          onChange={handlePageChange}
          label={translation.linkToLabel}
          firstPageSelected
        />
      </Box>
    );

    const notificationConfig = (
      <>
        <Box py={0.5} gap={1}>
          <FormControlLabel
            value={open}
            label={translation.setNotificationTitle}
            labelPlacement="start"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: 0,
            }}
            control={
              entry.type === ActionType.show_notification ? (
                <span />
              ) : (
                <Switch onChange={toggleNotification} checked={open} />
              )
            }
          />
        </Box>
        {open && (
          <Box display="flex" flexDirection="column" width="100%">
            <FormControl>
              <TextField
                label={notificationMessageLabel}
                value={entry?.notification?.message ?? ""}
                onChange={changeNotificationMessage}
                multiline
              />
              <Box
                display="flex"
                justifyContent="space-between"
                marginTop={1.5}
              >
                <Autocomplete
                  label={notificationVariantLabel}
                  options={notificationVariants}
                  value={
                    entry?.notification?.variant ?? NotificationVariant.default
                  }
                  isClearable={false}
                  onChange={changeNotificationVariant}
                />
                <FormControlLabel
                  label={notificationPersistLabel}
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={togglePersist}
                      checked={entry?.notification?.persist ?? false}
                    />
                  }
                />
              </Box>
            </FormControl>
          </Box>
        )}
      </>
    );

    return (
      <Box display="flex" flexDirection="column" gap={1}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <Autocomplete
            value={entry.type}
            onChange={handleTypeChange}
            options={actionTypeList}
            label={typeLabel}
            isClearable={false}
          />
          <IconButton icon="delete_outlined" color="error" onClick={onDelete} />
        </Box>
        {/* TODO: this will change as there are configs with other parameters */}
        {withElementIDType ? (
          <FormControl>
            <Autocomplete
              label={translation.elementLabel}
              value={(entry as { elementId: string }).elementId ?? ""}
              options={options}
              onChange={handleChange}
            />
          </FormControl>
        ) : null}
        {navigateConfig}
        {notificationConfig}
        {divided && (
          <Box py={0.5}>
            <Divider />
          </Box>
        )}
      </Box>
    );
  },
);

type ActionConfigEditorProps = {
  actionConfigs?: ActionConfigsType;
  onActionConfigsChange: (value: ActionConfigsType) => void;
  elementConfig: UntransformedConfig<ElementsWithActionConfigType>;
};

export const ActionConfigEditor = memo<ActionConfigEditorProps>(
  ({ actionConfigs = [], onActionConfigsChange, elementConfig }) => {
    const { onTriggerActionTitle, noTriggerActionLabel } = useTranslation();
    const updatedElements = useSelector(editorSelectors.updatedElements);
    const page = usePage();

    const rootElement = page!.element;

    const tableOptions = useMemo(() => {
      const tables = getPageElementsByType(
        rootElement,
        updatedElements,
        "default_table",
      );
      return tables.map((element) => ({
        value: element.id,
        label: element.id,
      }));
    }, [rootElement, updatedElements]);

    const elementOptions = useMemo(() => {
      const elements = getPageElementsByType(
        rootElement,
        updatedElements,
        "default_autocomplete_input",
      );
      return elements.map((element) => ({
        value: element.id,
        label: element.id,
      }));
    }, [rootElement, updatedElements]);

    const chartOptions = useMemo(() => {
      const charts = getPageElementsByType(rootElement, updatedElements, [
        "echarts_custom",
        "echarts_pie_chart",
        "echarts_line_chart",
        "echarts_bar_chart",
      ]);
      return charts.map((element) => ({
        value: element.id,
        label: element.id,
      }));
    }, [rootElement, updatedElements]);

    const formOptions = useMemo(() => {
      const forms = getPageElementsByType(
        rootElement,
        updatedElements,
        "default_form",
      );
      return forms.map((element) => ({
        value: element.id,
        label: element.id,
      }));
    }, [rootElement, updatedElements]);

    const dialogOptions = useMemo(() => {
      const dialogs = getPageElementsByType(
        rootElement,
        updatedElements,
        "default_modal_dialog",
      );
      return dialogs.map((element) => ({
        value: element.id,
        label: element.id,
      }));
    }, [rootElement, updatedElements]);

    const displyDataOptions = useMemo(() => {
      const displayDatas = getPageElementsByType(
        rootElement,
        updatedElements,
        "default_data_display",
      );
      return displayDatas.map((element) => ({
        value: element.id,
        label: element.id,
      }));
    }, [rootElement, updatedElements]);

    const geoJSONOptions = useMemo(() => {
      const displayDatas = getPageElementsByType(
        rootElement,
        updatedElements,
        "default_geojson_field",
      );
      return displayDatas.map((element) => ({
        value: element.id,
        label: element.id,
      }));
    }, [rootElement, updatedElements]);

    const handleActionConfigEntryChange =
      (index: number) => (entry: ActionConfigType) => {
        if (actionConfigs) {
          const newValue = actionConfigs.map((item, i) =>
            i === index ? entry : item,
          );
          onActionConfigsChange(newValue);
        }
      };

    const handleDelete = (index: number) => () => {
      if (actionConfigs) {
        onActionConfigsChange(dissocPath([Number(index)], actionConfigs));
      }
    };

    const handleAdd = () => {
      const newActionConfig = actionConfigs ? [...actionConfigs] : [];
      newActionConfig.push({
        type: ActionType.reload_table,
        elementId: "",
      });
      onActionConfigsChange(newActionConfig);
    };

    const actionConfigOptions = {
      reload_table: tableOptions,
      save_form: formOptions,
      refresh_chart: chartOptions,
      toggle_modal: dialogOptions,
      reset_input: elementOptions,
      show_notification: elementOptions,
      reload_form: formOptions,
      refresh_display_data: displyDataOptions,
      refresh_geojson_field: geoJSONOptions,
      navigate: [],
      go_back: [],
    };

    const items = actionConfigs?.map(
      (successAction, index) =>
        (
          <ActionConfigEntry
            key={index}
            onChange={handleActionConfigEntryChange(index)}
            onDelete={handleDelete(index)}
            entry={successAction}
            divided={index !== actionConfigs.length - 1}
            options={actionConfigOptions[successAction.type]}
            elementConfig={elementConfig}
          />
        ) || (
          <Typography key="noTriggerActionLabel" variant="subtitle1">
            {noTriggerActionLabel}
          </Typography>
        ),
    );

    return (
      <>
        <Box display="flex" justifyContent="space-between">
          <FormLabel component="p">{onTriggerActionTitle}</FormLabel>
          <IconButton icon="add" onClick={handleAdd} />
        </Box>
        {items}
      </>
    );
  },
);
