import { IRawElementType } from "../core";

import advanced_conditional from "./advanced_conditional";
import default_action_button from "./default_action_button";
import default_array_text_field from "./default_array_text_field";
import default_array_text_input from "./default_array_text_input";
import default_autocomplete_input from "./default_autocomplete_input";
import default_bool_field from "./default_bool_field";
import default_bool_input from "./default_bool_input";
import default_button_group from "./default_button_group";
import default_call_button from "./default_call_button";
import default_circular_progress from "./default_circular_progress";
import default_clipboard_copy_button from "./default_clipboard_copy_button";
import default_code_input from "./default_code_input";
import default_color_field from "./default_color_field";
import default_color_input from "./default_color_input";
import default_container from "./default_container";
import default_custom_text_field from "./default_custom_text_field";
import default_data_display from "./default_data_display";
import default_date_time_field from "./default_date_time_field";
import default_date_time_input from "./default_date_time_input";
import default_delete_button from "./default_delete_button";
import default_external_link_field from "./default_external_link_field";
import default_form from "./default_form";
import default_form_button from "./default_form_button";
import default_geojson_field from "./default_geojson_field";
import default_geojson_input from "./default_geojson_input";
import default_grid from "./default_grid";
import default_html_display from "./default_html_display";
import default_internal_link_button from "./default_internal_link_button";
import default_internal_link_field from "./default_internal_link_field";
import default_json_field from "./default_json_field";
import default_json_input from "./default_json_input";
import default_label_field from "./default_label_field";
import default_markdown_field from "./default_markdown_field";
import default_markdown_input from "./default_markdown_input";
import default_modal_dialog from "./default_modal_dialog";
import default_number_field from "./default_number_field";
import default_number_input from "./default_number_input";
import default_procedure_button from "./default_procedure_button";
import default_slider_input from "./default_slider_input";
import default_state_change_dropdown from "./default_state_change_dropdown";
import default_static_select from "./default_static_select";
import default_sub_form from "./default_sub_form";
import default_sub_form_table from "./default_sub_form_table";
import default_table from "./default_table";
import default_table_header_cell from "./default_table_header_cell";
import default_tabs from "./default_tabs";
import default_text_field from "./default_text_field";
import default_text_input from "./default_text_input";
import echarts_bar_chart from "./echarts_bar_chart";
import echarts_custom from "./echarts_custom";
import echarts_line_chart from "./echarts_line_chart";
import echarts_pie_chart from "./echarts_pie_chart";
import meta_query from "./meta_query";
import storage_file_input from "./storage_file_input";
import storage_image from "./storage_image";
import storage_multiple_files_input from "./storage_multiple_files_input";

export const elementTypes: Record<string, IRawElementType> = {
  advanced_conditional,
  default_action_button,
  default_array_text_field,
  default_array_text_input,
  default_autocomplete_input,
  default_bool_field,
  default_bool_input,
  default_button_group,
  default_call_button,
  default_circular_progress,
  default_clipboard_copy_button,
  default_code_input,
  default_color_field,
  default_color_input,
  default_container,
  default_custom_text_field,
  default_data_display,
  default_date_time_field,
  default_date_time_input,
  default_delete_button,
  default_external_link_field,
  default_form_button,
  default_form,
  default_geojson_field,
  default_geojson_input,
  default_grid,
  default_html_display,
  default_internal_link_button,
  default_internal_link_field,
  default_json_field,
  default_json_input,
  default_label_field,
  default_markdown_field,
  default_markdown_input,
  default_modal_dialog,
  default_number_field,
  default_number_input,
  default_procedure_button,
  default_slider_input,
  default_state_change_dropdown,
  default_static_select,
  default_sub_form_table,
  default_sub_form,
  default_table_header_cell,
  default_table,
  default_tabs,
  default_text_field,
  default_text_input,
  echarts_bar_chart,
  echarts_custom,
  echarts_line_chart,
  echarts_pie_chart,
  meta_query,
  storage_file_input,
  storage_image,
  storage_multiple_files_input,
};
