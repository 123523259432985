import { memo, useCallback } from "react";
import FormHelperText from "@mui/material/FormHelperText";

import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";

import { useCustomChartEditorTranslation } from "../../translation";
import { UntransformedCustomChartConfig } from "../../types";

export const DataSourceComponent = memo(() => {
  const {
    elementModel: {
      config: { dataSource },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCustomChartConfig>();

  const { viewName } = dataSource;

  const { viewLabel, valueColumnHelperText } =
    useCustomChartEditorTranslation();

  const changeDataSource = useCallback(
    (newDataSource: UntransformedCustomChartConfig["dataSource"]) =>
      changeConfigValue("dataSource", { ...dataSource, ...newDataSource }),
    [changeConfigValue, dataSource],
  );

  const handleViewNameChange = (newViewName: string) =>
    changeDataSource({
      viewName: newViewName,
    });

  const { configTitle } = useEditorTranslation();

  return (
    <>
      <Section title={configTitle} wrapped={true}>
        <ViewAutocomplete
          viewValue={viewName}
          viewLabel={viewLabel}
          onViewNameChange={handleViewNameChange}
        />
        <FormHelperText>{valueColumnHelperText}</FormHelperText>
      </Section>
    </>
  );
});
