export const getType = (date: boolean, time: boolean) => {
  if (date && time) {
    return "dateTime";
  }
  if (date) {
    return "date";
  }
  if (time) {
    return "time";
  }
  return "dateTime";
};

export const getDefaultFormat = (type: "dateTime" | "date" | "time") => {
  if (type === "dateTime") {
    return "dd-MM-yyyy HH:mm";
  } else if (type === "date") {
    return "dd-MM-yyyy";
  } else {
    return "HH:mm";
  }
};

export const toMoment = (type: "dd-MM-yyyy HH:mm" | "dd-MM-yyyy" | "HH:mm") => {
  if (type === "dd-MM-yyyy HH:mm") {
    return "DD-MM-YYYY hh:mm";
  } else if (type === "dd-MM-yyyy") {
    return type.toUpperCase();
  } else {
    return type;
  }
};

export const getDateOrUndefined = (input: Date | null) => {
  return input ? input : undefined;
};
