import { memo } from "react";
import {
  Filter,
  Limit,
  ToolboxAndColors,
} from "elementTypes/common/Echarts/editor";

import {
  DataSourceComponent,
  OptionComponent,
  SortComponent,
  TranslationComponent,
} from "./components";

export const EchartsCustomChartEditor = memo(() => (
  <>
    <DataSourceComponent />
    <TranslationComponent />
    <SortComponent />
    <Limit />
    <ToolboxAndColors />
    <Filter />
    <OptionComponent />
  </>
));
