import { memo } from "react";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import { Translation } from "core";
import { LanguageSection, TranslationEditor } from "core/editor";
import { useHookFormContext } from "elementTypes/common/HookForm/utils.ts";
import { TDialogContent } from "./types.ts";

export const LegendTranslationEditor = memo<TDialogContent>(
  ({ columnName, i18n }) => {
    const { setValue, watch } = useHookFormContext();

    const formI18n: Translation = watch("i18n") ?? i18n;
    const translationKey = `${columnName ?? ""}Label`;

    const handleChangeTranslation = (translation: Translation) =>
      setValue("i18n", translation);

    return (
      <>
        <FormControl fullWidth>
          <Controller
            render={({ field: { ref }, fieldState: { error } }) => (
              <LanguageSection
                showSection={false}
                wrapped={false}
                showTitle={false}
              >
                <TranslationEditor
                  translationKey={translationKey}
                  i18n={formI18n}
                  changeTranslation={handleChangeTranslation}
                  inputRef={ref}
                  error={Boolean(error)}
                />
              </LanguageSection>
            )}
            name="i18n"
            defaultValue={formI18n}
          />
        </FormControl>
      </>
    );
  },
);
