import { Type } from "./Type";
import { TypeError } from "./TypeError";

export class LiteralType extends Type {
  public name: string;

  constructor(
    public readonly value: any,
    description?: string,
  ) {
    super(description);
    this.name = this.generateTypeName();
  }

  private generateTypeName(): string {
    return this.value;
  }

  public validate(value: any): true | TypeError {
    return value === this.value ? true : new TypeError(value, this);
  }
}
