import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useTableStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,

    "& .Mui-error": {
      backgroundColor: `rgb(126,10,15, ${
        theme.palette.mode === "dark" ? 0 : 0.1
      })`,
      color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
    },
  },
}));
