import { memo } from "react";

import { ConfigEditor, Modes } from "./components";

export const GeoJSONInputEditor = memo(() => (
  <>
    <ConfigEditor />
    <Modes />
  </>
));
