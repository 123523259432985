import { MouseEvent, lazy, memo, useState } from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { useEditorMode } from "core/context/EditorMode/useEditorMode";
import { actions } from "core/editor/reduxModule";
import { useEditorTranslation } from "core/editor/translation";
import { BoundActions } from "core/types";
import Button from "elementTypes/common/Button";
import IconButton, { ButtonProps } from "elementTypes/common/IconButton";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";

import { mapStateToProps } from "./container";
import { useEditorSwitchTranslation } from "./translations";

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

type Props = ReturnType<typeof mapStateToProps> &
  BoundActions<typeof actions> &
  ButtonProps & { toggleDrawer: () => void; drawerOpen: boolean };

export const EditorSwitch = memo<Props>(
  ({ color, drawerOpen, layoutChanged, toggleDrawer }) => {
    const { isEditorModeEnabled, toggleEditorMode } = useEditorMode();
    const { exitLabel, label } = useEditorSwitchTranslation();
    const { cancelButton, exitConfirmation, previewBackButton, previewTitle } =
      useEditorTranslation();

    const cancelEditing = (event: MouseEvent<HTMLButtonElement>) => {
      if (isEditorModeEnabled && layoutChanged) {
        setAnchorEl(event.currentTarget);
      } else {
        toggleEditorMode();
        toggleDrawer();
      }
    };

    const enableEditing = () => {
      if (drawerOpen) {
        toggleDrawer();
        const drawerToggleDuration = 300;
        setTimeout(() => toggleEditorMode(), drawerToggleDuration);
      } else {
        toggleEditorMode();
      }
    };

    const buttonProps = {
      icon: isEditorModeEnabled ? "flip_to_front" : "border_inner",
      tooltip: isEditorModeEnabled ? exitLabel : label,
      color,
      onClick: isEditorModeEnabled ? cancelEditing : enableEditing,
      placement: "bottom",
      fontSize: "large",
      id: "toggle-editor",
    } as const;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleExit = () => {
      handleClose();
      toggleEditorMode(false);
      toggleDrawer();
    };

    const action = (
      <Button
        color="inherit"
        label={previewBackButton}
        size="small"
        onClick={enableEditing}
      />
    );

    return (
      <>
        {!isEditorModeEnabled && layoutChanged ? (
          <Snackbar
            open={layoutChanged}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              severity="warning"
              action={action}
            >
              {previewTitle}
            </Alert>
          </Snackbar>
        ) : (
          <IconButton {...buttonProps} sx={{ color: buttonProps.color }} />
        )}
        {isEditorModeEnabled && (
          <Popover
            onClose={handleClose}
            actionsAlign="center"
            actions={
              <>
                <Button
                  label={exitLabel}
                  color="secondary"
                  onClick={handleExit}
                />
                <Button label={cancelButton} onClick={handleClose} />
              </>
            }
            anchorEl={anchorEl}
          >
            <Typography align="center">{exitConfirmation}</Typography>
          </Popover>
        )}
      </>
    );
  },
);
