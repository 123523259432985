import { AutocompleteInput } from "../types.ts";

export const getAutocompleteOptions = (
  all: any[] | null,
  val: string | string[],
  config: AutocompleteInput["config"],
) =>
  all
    ? val
      ? config.isMulti
        ? all.filter((o) => !val?.includes(o))
        : all.filter((o) => o !== val)
      : all
    : [];

export const getRawValueObject = (
  raw: Record<string, unknown>[] | null,
  currentValue: string | string[],
  config: AutocompleteInput["config"],
) => {
  if (config.reference) {
    const arrayifiedCurrentValue = Array.isArray(currentValue)
      ? currentValue
      : [currentValue];
    const rawValueObjects = arrayifiedCurrentValue.map(
      (v) =>
        raw?.find((rawRow) => rawRow[config.reference?.columnName] === v) ??
        null,
    );
    return Array.isArray(currentValue) ? rawValueObjects : rawValueObjects[0];
  } else {
    return null;
  }
};
