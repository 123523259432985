import { actions as editorActions } from "core/editor/reduxModule";
import { IElementType, IObjectView, Language } from "core/types";

import { createTypeSafeContext } from "utils/createTypeSafeContext";
import { IColumn, IGenerateColumn } from "./ColumnEditor";
import { ColumnDetails } from "./Columns";

type ColumnsContextType = {
  columnDetails: ColumnDetails | null;
  language: Language;
  elementTypes: Record<string, IElementType<any>>;
  currentView?: IObjectView;
  hidden: any[];
  updateChildren: (
    ...params: Parameters<typeof editorActions.updateElementChildren>
  ) => void;
  setColumnDetails: (details: ColumnDetails | null) => void;
  deleteColumn: (index: number) => void;
  generateColumn: (newProps: IGenerateColumn) => void;
  selectElement: (
    ...params: Parameters<typeof editorActions.selectElement>
  ) => void;
  filteredColumns: IColumn[];
  searchValue: string;
};

const { Provider, useTypeSafeContext } =
  createTypeSafeContext<ColumnsContextType>();

export const ColumnsProvider = Provider;
export const useColumnsContext = useTypeSafeContext;
