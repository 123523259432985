import { memo, useCallback, useEffect, useMemo } from "react";

import debounce from "lodash/debounce";
import {
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
} from "react-hook-form";

import "./erdHints";
import { useModel, useTestCustomQuery } from "queries/admin";
import { getApiError } from "queries/utils";

import { useAdminContext } from "staticPages/admin/context";
import {
  CodeControllerProps,
  ControlledCodeMirror,
} from "../components/ControlledCodeMirror";
import { entitiesNamesBySchema } from "../erd/utils";

import { HintOption } from "./erdHints";
import { useCustomQueryTranslation } from "./translation";
import { TestCustomQueryResponse, UICustomQueryForm } from "./types";

type Props = CodeControllerProps & {
  onDataPreview: () => void;
  setError: UseFormSetError<UICustomQueryForm>;
  clearError: UseFormClearErrors<UICustomQueryForm>;
  getValues: UseFormGetValues<UICustomQueryForm>;
};

const defaultQuery = `-- Calculate the number of tables, views, and indexes in the current database schema
SELECT 
  (SELECT COUNT(*) FROM information_schema.tables WHERE table_schema = 'public') AS total_tables,
  (SELECT COUNT(*) FROM information_schema.views WHERE table_schema = 'public') AS total_views,
  (SELECT COUNT(*) FROM pg_indexes WHERE schemaname = 'public') AS total_indexes;
`;

const QueryEditor = memo<Props>(
  ({ onDataPreview, clearError, getValues, setError, ...rest }) => {
    const model = useModel();
    const dataModel = model.data;

    const { selectedQueryEntity, setSelectedQueryEntity } = useAdminContext();

    useEffect(() => {
      return () => {
        setSelectedQueryEntity(null);
      };
    }, [setSelectedQueryEntity]);

    const hintOption: HintOption =
      useMemo(
        () => dataModel && entitiesNamesBySchema(dataModel),
        [dataModel],
      ) ?? {};

    const handleServerValidation = (serverError: TestCustomQueryResponse) => {
      const { errorHint, errorLine } = serverError;
      if (serverError.error) {
        const manualError = {
          type: "server",
          types: {
            errorLine: `${errorLine}`,
            errorHint,
          },
          message: serverError.error,
        };
        setError("code", manualError);
      } else {
        clearError("code");
      }
    };

    const { queryEditorRun } = useCustomQueryTranslation();

    const validateQuery = useTestCustomQuery({
      onSuccess: (data: TestCustomQueryResponse) => {
        handleServerValidation(data);
      },
      onError: (validateError) => {
        handleServerValidation({ error: getApiError(validateError) });
      },
    });

    const handleValidateQuery = useCallback(
      (newCode: string) => {
        if (!newCode) {
          return;
        }
        const updated = {
          data: {
            // TODO remove "fresh" fallback, use randomly generated string instead
            viewName: getValues("name") || "fresh",
            code: newCode,
          },
        };
        validateQuery.mutate(updated);
      },
      [validateQuery, getValues],
    );

    const debouncedValidation = useMemo(
      () => debounce(handleValidateQuery, 512),
      [],
    );

    return (
      <ControlledCodeMirror
        {...(rest as any)}
        isLoading={validateQuery.isLoading}
        clearError={clearError}
        debouncedValidation={debouncedValidation}
        hintList={hintOption}
        onSuccess={{
          action: onDataPreview,
          label: queryEditorRun,
        }}
        selected={selectedQueryEntity}
        defaultValue={defaultQuery}
      />
    );
  },
);

export default QueryEditor;
