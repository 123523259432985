/**
 * move on item from one index to another index
 * does not modify the original data
 * @param list will not be modified
 * @param takeFromIndex current item index
 * @param moveToIndex new item index when item is already removed from list
 *
 * @example
 * const list = [1, 2, 3]
 * const result = moveItemInList(list, 0, 1)
 * // result === [2, 1, 3]
 *
 *
 * @example
 * const list = [1, 2, 3]
 * const result = moveItemInList(list, 2, 0)
 * // result === [3, 1, 2]
 *
 */
export function moveItemInList<Item>(
  list: readonly Item[],
  takeFromIndex: number,
  moveToIndex: number,
): Item[] {
  const result = Array.from(list);
  const [removed] = result.splice(takeFromIndex, 1);
  if (removed !== undefined) {
    result.splice(moveToIndex, 0, removed);
  }

  return result;
}
