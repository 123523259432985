import { Dispatch, SetStateAction } from "react";
import { actions as editorActions } from "core/editor/reduxModule";
import { IPage } from "core/types/app";
import { createTypeSafeContext } from "utils/createTypeSafeContext";

import { TabConfigProps } from "./TabConfig";

export type TabsContentContext = {
  tabDetails: TabConfigProps | null;
  page: IPage | null;
  setTabDetails: Dispatch<SetStateAction<TabConfigProps | null>>;
  handleDialogClose: () => void;
  handleTabButtonClick: (
    type: "edit" | "delete",
    params: TabConfigProps | number,
  ) => void;
  deleteTab: (index: number) => void;
  updateChildren: (
    ...params: Parameters<typeof editorActions.updateElementChildren>
  ) => void;
  createElement: (
    ...params: Parameters<typeof editorActions.createElement>
  ) => void;
};

const { Provider, Consumer, useTypeSafeContext } =
  createTypeSafeContext<TabsContentContext>();

export const useTabsContentContext = useTypeSafeContext;
export const TabsContentProvider = Provider;
export const TabsContentConsumer = Consumer;
