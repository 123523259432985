import { ElementGroup, IRawElementType } from "core";

import Component from "./container";
import { SubFormTableEditor, defaultElement, editorMetadata } from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import {
  SubFormTableConfig,
  SubFormTableTranslationKeys,
  subFormTableSelectors,
} from "./types";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_sub_form_table",
  group: ElementGroup.Inputs,
  component: Component,
  editable: true,
  configType: SubFormTableConfig,
  selectorTypes: subFormTableSelectors,
  translationKeys: SubFormTableTranslationKeys,
  editorComponent: SubFormTableEditor,
  defaultElement,
  editorMetadata,
};

export default elementType;
