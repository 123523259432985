import { memo } from "react";
import { ConnectedReduxModuleProps } from "core";

import { JsonView } from "../common/JsonView";
import { ASTERISK_SYMBOL } from "../common/utils";

import { ReduxModule } from "./reduxModule";
import { JsonInput } from "./types";

const DefaultJsonInput = memo<
  ConnectedReduxModuleProps<ReduxModule, JsonInput>
>(
  ({
    value,
    element: {
      config: { nullable, ...rest },
      i18n: { label },
    },
    errors,
    disabled,
    required,
    changeValue,
  }) => {
    const inputLabel = (
      <>
        {label}
        {required && ASTERISK_SYMBOL}
      </>
    );
    return (
      <JsonView
        {...rest}
        value={value}
        onValueChange={changeValue}
        label={inputLabel}
        nullable={nullable}
        error={errors}
        disabled={disabled}
      />
    );
  },
);

export default DefaultJsonInput;
