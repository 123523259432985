import { memo } from "react";
import {
  Box,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

import Button from "elementTypes/common/Button";
import { HookForm } from "elementTypes/common/HookForm";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";
import { useConfig, useUpdateConfig } from "queries/admin/configData";
import { getApiError } from "queries/utils";
import { useSnackbar } from "utils/hooks/useSnackbar";

const JWT_EXP_DEFAULT = "7d"; // default value for jwt expiration, also change in backend later
// apps/backend/src/api/services/auth.service.ts

/**
 * This component is responsible for changing the JWT token expiration.
 * If any other changes are required it t_config, the component must be refactored.
 */
export const ConfigPage = memo(() => {
  const showSnackbar = useSnackbar();
  const config = useConfig();
  const updateConfig = useUpdateConfig({
    onSuccess: (res) => {
      config.refetch();
      showSnackbar(
        `New JWT token expiration is set to: ${res?.value ?? JWT_EXP_DEFAULT}`,
        "info",
      );
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    const res = {
      key: "jwt_exp",
      value: formData.jwt_exp ?? JWT_EXP_DEFAULT,
    };
    updateConfig.mutate(res);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <Typography variant="h5">Configuration</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <HookForm onSubmit={onSubmit}>
            {config?.data?.length ? (
              <ConfigForm key={config.status} data={config?.data} />
            ) : (
              <span />
            )}
          </HookForm>
        </Paper>
      </Grid>
    </Grid>
  );
});

const ConfigForm = memo<{ data: Record<string, string>[] }>(({ data }) => {
  const {
    control,
    formState: { isSubmitting, isValid },
    reset,
  } = useHookFormContext();

  const jwtExp =
    data?.find((item) => item.key === "jwt_exp")?.value ?? JWT_EXP_DEFAULT;

  const handleClick = () => reset();

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Controller
        render={({ field: { ref, ...inputProps }, fieldState: { error } }) => (
          <TextField
            inputRef={ref}
            {...inputProps}
            label="JWT Token Expiration Time"
            fullWidth
            error={!!error}
            helperText={
              error
                ? error?.message
                : "Set the duration for which the JWT token remains valid (e.g., '7d' for 7 days, '24h' for 24 hours, or '60m' for 60 minutes)"
            }
          />
        )}
        name="jwt_exp"
        control={control}
        rules={{
          required: true,
        }}
        defaultValue={jwtExp}
      />
      <Divider sx={{ my: 1.5 }} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button
          disabled={isSubmitting}
          label="Reset"
          color="secondary"
          onClick={handleClick}
        />
        <Button
          color="primary"
          disabled={isSubmitting || !isValid}
          processing={isSubmitting}
          iconRight="forward"
          type="submit"
          label="Edit"
        />
      </Box>
    </Box>
  );
});
