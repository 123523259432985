import { memo } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";

import { useHookFormContext } from "../../../common/HookForm/utils";
import { useSliderInputEditorTranslation } from "../translation";

import { MarkDetails } from "./Marks";

type Props = MarkDetails;

export const DialogContent = memo<Props>(({ value }) => {
  const translation = useSliderInputEditorTranslation();
  const { control } = useHookFormContext();

  return (
    <FormControl fullWidth={true}>
      <Controller
        render={({ field, fieldState: { error } }) => (
          <TextField
            size="small"
            label={translation.valueLabel}
            {...field}
            type="number"
            error={Boolean(error)}
          />
        )}
        name="value"
        control={control}
        defaultValue={value}
        rules={{
          required: true,
        }}
      />
    </FormControl>
  );
});
