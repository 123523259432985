import { createUseTranslation } from "core/session/translation";

export const commonStaticPagesTranslation = {
  en: {
    pageTitle: "Files",
    SaveButton: "Save",
    DeleteButton: "Delete",
    CancelButton: "Cancel",
    deletedMessage: "File was deleted successfully",
    saveErrorMessage: "File was not uploaded. Error: ",
    saveSuccessMessage: "File successfully uploaded",
    tableNameLabel: "Name",
    tableFileTypeLabel: "File Type",
    tableUploadLabel: "Uploaded",
    tableTypeGroupLabel: "Type Group",
    tableAccessGroupLabel: "Access Group",
    tableActionsLabel: "Actions",
    dialogContentText: "Are you sure you want to delete the file",
    dialogTitle: "Delete file",
    groupLabel: "Group",
    uploadLabel: "Upload",
    typeLabel: "Type",
    updateSuccessMessage: "File successfully updated",
    updateErrorMessage: "File was not updated. Error: ",
    uploadFilesTitle: "Upload Files",
    editFileTitle: "Edit File",
    editLabel: "Edit",
    deleteButton: "Delete",
  },
};

export const useCommonStaticPagesTranslation = createUseTranslation(
  commonStaticPagesTranslation,
);
