import { Draggable } from "react-beautiful-dnd";
import { ListChildComponentProps } from "react-window";
import { getTranslatedTexts } from "core/utils/element-utils";

import { ColumnConfig } from "./ColumnConfig";
import { useColumnsContext } from "./ColumnsContext";

export function ColumnRow({ data, index, style }: ListChildComponentProps) {
  const { language, hidden } = useColumnsContext();

  const { columns, handleBtnClick, isDisabledColumn } = data;
  const { i18n, ...rest } = columns[index];
  const isHidden = hidden?.[index];
  const label = getTranslatedTexts(language, i18n).label;
  const disabled = isDisabledColumn(index);

  return (
    <Draggable key={rest.name} draggableId={rest.name} index={index}>
      {(draggableProvided, snapshot) => (
        <ColumnConfig
          {...rest}
          label={label}
          provided={draggableProvided}
          snapshot={snapshot}
          style={style}
          index={index}
          isHidden={isHidden}
          onBtnClick={handleBtnClick}
          disabled={disabled}
        />
      )}
    </Draggable>
  );
}
