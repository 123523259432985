import { IStaticRouteConfig } from "core/router/types";

import { RoutePaths } from "../../../routes";
import { TablesAuditPageRoute, UsersAuditPageRoute } from "./pages";
import { AuditsPage } from "./pages/tables/component";

export const route: IStaticRouteConfig = {
  Component: AuditsPage,
  auth: true,
  isAdmin: true,
  routes: {
    [RoutePaths.AuditsTables]: TablesAuditPageRoute,
    [RoutePaths.AuditUsers]: UsersAuditPageRoute,
  },
};
