import dagre from "dagre";
import isArray from "lodash/isArray";
import mergeWith from "lodash/mergeWith";
import omit from "ramda/es/omit";
import prop from "ramda/es/prop";
import sortBy from "ramda/es/sortBy";
import { Edge, MarkerType, Node, Position, isEdge, isNode } from "reactflow";
import { useModel } from "queries/admin";
import { useAdminContext } from "staticPages/admin/context";
import { LayoutOption, LayoutOptions } from "../component";
import { getFilteredTables } from "../components/utils";
import {
  MAX_COLUMNS_QUANTITY,
  TABLE_BODY_ROW_HEIGHT,
  TABLE_HEADER_HEIGHT,
  TABLE_WIDTH,
} from "./consts.ts";

import { edgeRawStyle } from "./styles";

import {
  Column,
  Constraint,
  DataModel,
  EdgeData,
  NodeData,
  Table,
  View,
} from "./types";

const sortByPosition = sortBy(prop("position"));

const MAX_NODES_QUANTITY = 301;

const getEntitiesList = (
  entities: Record<string, Record<string, Table | View>>,
  elementsCount: number,
) => {
  const elements = [];
  if (Object.values(entities).length) {
    for (const schema in entities) {
      const items = entities?.[schema] ?? {};
      if (Object.values(items).length) {
        for (const itemName in items) {
          const item = items?.[itemName];
          if (
            item &&
            typeof item === "object" &&
            elements.length + elementsCount < MAX_NODES_QUANTITY
          ) {
            const node = "view" in item ? toNodeView(item) : toNode(item);
            elements.push(node);
          }
        }
      }
    }
  }
  return elements;
};

const toNode = (table: Table) =>
  ({
    id: `${table.schema}.${table.table}`,
    data: {
      tableName: table.table,
      schemaName: table.schema,
      columns: table.columns,
      lookupLabelColumn: table.alias,
      historyTrackingConfigured: table.historyTrackingConfigured,
      workflowActivated: table.workflowActivated,
      fdw: table.fdw,
      autogenerated: table.autogenerated,
      stateColumn: table.stateColumn,
    },
    type: "table",
    position: { x: 0, y: 0 },
  }) as Node<NodeData>;

const toNodeView = (view: View) =>
  ({
    id: `${view.schema}.${view.view}`,
    data: {
      viewName: view.view,
      schemaName: view.schema,
      columns: view.columns,
      alias: view.alias,
    },
    type: "view",
    position: { x: 0, y: 0 },
  }) as Node<{
    viewName: string;
    schemaName: string;
    columns: Column[];
    alias: string;
  }>;

const toEdge = (c: Constraint) =>
  ({
    // id must be unique - constraint names are only unique scoped by table
    id: `${c.sourceSchema}.${c.sourceTable}-${c.name}`,
    data: { source_column: c.sourceColumn, target_column: c.targetColumn },
    source: `${c.sourceSchema}.${c.sourceTable}`,
    sourceHandle: `${c.sourceSchema}.${c.sourceTable}.${c.sourceColumn}`,
    target: `${c.targetSchema}.${c.targetTable}`,
    targetHandle: `${c.targetSchema}.${c.targetTable}.${c.targetColumn}`,
  }) as Edge<EdgeData>;

export const modelToReactFlowElements = (model: DataModel) => {
  let nodes: Node[] = [];

  // add tables
  nodes = [
    ...nodes,
    ...getEntitiesList(model?.tables ?? {}, nodes.length ?? 0),
  ];
  //add views
  nodes = [...nodes, ...getEntitiesList(model?.views ?? {}, nodes.length ?? 0)];

  const edges = model.constraints.map((c: Constraint) => toEdge(c));

  return {
    nodes,
    edges,
  };
};

export const sortAndFilterColumns = (columns: Column[]) => {
  const keysFirst = columns.reduce((res, c: Column, index: number) => {
    const hasConstraint =
      c.primaryKey ||
      c.unique ||
      c.isArray ||
      ["date", "jsonb"].includes(c.type);
    return hasConstraint
      ? res
      : [
          ...res,
          {
            ...c,
            position: c.foreignKey ? 0.1 + index / 100 : c.position,
          },
        ];
  }, [] as Column[]);

  return sortByPosition(keysFirst) as Column[];
};

export const sortColumns = (columns: Column[]) => {
  const keysFirst = columns.map((c: Column, index: number) => ({
    ...c,
    position: c.primaryKey ? 0 : c.foreignKey ? 0.1 + index / 100 : c.position,
  }));

  return sortByPosition(keysFirst) as Column[];
};

const getEntitiesNames = <T>(
  newObject: Record<string, Record<string, T>>,
  prevValue?: Record<string, string[]>,
): Record<string, string[]> => {
  return Object.entries(newObject).reduce(
    (res, [schema, entityObject]) => ({
      ...res,
      [schema]: [
        ...Object.keys(entityObject ?? {}),
        ...(prevValue?.[schema] ?? []),
      ],
    }),
    {},
  );
};

const customizeFn = (
  objValue: Record<string, string[]>,
  srcValue: Record<string, string[]>,
) => {
  if (isArray(objValue)) {
    return [...new Set(objValue.concat(srcValue))];
  }

  return;
};

export const entitiesNamesBySchema = (model: DataModel) => {
  const entities = getEntitiesNames(model.tables);
  const views = getEntitiesNames(model.views);

  return mergeWith(entities, views, customizeFn);
};

export const useNotAutogeneratedTables = (cachedSearchValue?: string) => {
  const {
    filter: { schema: shemaFilter },
  } = useAdminContext();

  const { data } = useModel();
  return getFilteredTables(
    omit(shemaFilter, data?.tables ?? {}),
    cachedSearchValue,
    {
      autogenerated: false,
    },
  );
};

export const titleToName = (value: string) =>
  value
    .replace(/[^a-zA-Z0-9-_]/g, "_")
    .replace(/[-_]+$/g, "")
    .replace(/[_]+/g, "_")
    .toLowerCase();

export const handleTitleValidate = (value: string) => {
  const val = value.toString().trim();
  return !!val;
};

export const handleNameValidate = (value: string) => {
  const val = value.toString().trim();
  // PostgreSQL identifiers must be less than 64 bytes long
  // using Blob to check actual byte size
  return !!val && new Blob([val]).size < 64;
};

export const getTableHeight = (columns: NodeData["columns"] = []) => {
  const getHeight = (columnsQuantity: number) =>
    columnsQuantity * TABLE_BODY_ROW_HEIGHT + TABLE_HEADER_HEIGHT + 2; // 2 === border height

  return getHeight(Math.min(columns.length, MAX_COLUMNS_QUANTITY));
};

export const getBottomY = (el: Node) =>
  el.position.y + getTableHeight(el.data?.columns);

export const getEdgeLabel = (nodes: Node[], edge: Edge): string | null => {
  const sourceNode = nodes.find((node) => node.id === edge.source)
    ?.data as NodeData;
  const targetNode = nodes.find((node) => node.id === edge.target)
    ?.data as NodeData;

  const sourceColumnKey = sourceNode?.columns.find(
    (column) => column.name === edge.data.source_column,
  );

  const targetColumnKey = targetNode?.columns.find(
    (column) => column.name === edge.data.target_column,
  );

  if (sourceColumnKey && targetColumnKey) {
    return `${sourceColumnKey.unique ? "1" : "n"}:${
      targetColumnKey.unique ? "1" : "n"
    }`;
  }

  return null;
};

const SCHEMA_PADDING = 10;

export const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  directionValue: LayoutOption,
) => {
  const isHorizontal = directionValue === LayoutOptions.horizontal;

  const dagreGraph = new dagre.graphlib.Graph({
    compound: true,
    directed: true,
  });
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  dagreGraph.setGraph({ rankdir: directionValue });

  const schemas: string[] = [];

  nodes.forEach((el: Node<NodeData>) => {
    if (isNode(el)) {
      const height = getTableHeight((el?.data as NodeData).columns);

      dagreGraph.setNode(el.id, {
        width: TABLE_WIDTH,
        height,
      });

      const schemaName = (el.data as unknown as NodeData).schemaName;
      if (!schemas.includes(schemaName)) {
        schemas.push(schemaName);

        dagreGraph.setNode(schemaName, {
          label: schemaName,
          clusterLabelPos: "top",
        });
      }
      dagreGraph.setParent(el.id, schemaName);
    }
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const tableNodes: Node[] = nodes.filter(isNode).map((el) => {
    const nodeWithPosition = dagreGraph.node(el.id);

    const targetPosition = isHorizontal ? Position.Left : Position.Top;
    const sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    const height = getTableHeight((el?.data as NodeData).columns);

    const position = {
      x: nodeWithPosition.x - TABLE_WIDTH / 2 + Math.random() / 1000,
      y: nodeWithPosition.y - height / 2,
    };

    return {
      ...el,
      position,
      sourcePosition,
      targetPosition,
    };
  });

  const edgeNodes = edges.reduce(
    (res, el) =>
      isEdge(el)
        ? [
            ...res,
            {
              ...el,
              labelStyle: edgeRawStyle,
              animated: false,
              style: { strokeWidth: 2 },
              type: "custom",
              label: getEdgeLabel(tableNodes, el),
              markerEnd: {
                type: MarkerType.ArrowClosed,
                strokeWidth: 1,
              },
            },
          ]
        : res,
    [] as Edge[], // TODO: check if it possible to do once in model.data convertion
  );

  const schemaNodes = schemas.map((schema) => {
    const tables = tableNodes.filter(
      (n) => (n.data as NodeData).schemaName === schema,
    );

    // the calculated position and size by dagre is wrong
    // calculating manually via table positions and sizes within the schema

    const minTableX = Math.min(...tables.map((t) => t.position.x));
    const minTableY = Math.min(...tables.map((t) => t.position.y));
    const maxTableX = Math.max(...tables.map((t) => t.position.x));

    const maxObject = tables.reduce((prev, current) => {
      const prevBottomY = getBottomY(prev);
      const currentBottomY = getBottomY(current);
      const condition = isHorizontal
        ? prev.position.y > current.position.y
        : prevBottomY > currentBottomY;

      return condition
        ? { ...prev, bottomY: prevBottomY }
        : { ...current, bottomY: currentBottomY };
    }, tables[0]) as Node & { bottomY: number };

    const position = {
      x: minTableX - SCHEMA_PADDING,
      y: minTableY - SCHEMA_PADDING,
    };

    const realWidth = maxTableX - minTableX + TABLE_WIDTH;
    const realHeight = maxObject.bottomY - minTableY;

    return {
      id: schema,
      type: "schema",
      position,
      data: {
        schema,
        width: realWidth + 2 * SCHEMA_PADDING,
        height: realHeight + 2 * SCHEMA_PADDING,
      },
      selectable: false,
    };
  });

  return {
    nodes: [...schemaNodes, ...tableNodes],
    edges: edgeNodes,
  };
};
