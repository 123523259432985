import { memo } from "react";
import { Box, Chip, CircularProgress, Typography } from "@mui/material";
import format from "date-fns/format";
import { useSessionContext } from "core/session/SessionContext.ts";
import { getTranslatedTexts } from "core/utils/element-utils.ts";
import { CodeBlock } from "elementTypes/common/CodeBlock";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { useGeneratedQuery } from "queries/admin/modelBuilderData.ts";
import { useViewsTableTranslation } from "../translation.ts";
import { TTableDialog } from "../types.ts";
import { FieldsTable } from "./FieldsTable.tsx";

export const Dialog = memo<TTableDialog>(({ modal, handleCloseDialog }) => {
  const { modalLabel, closeLabel } = useViewsTableTranslation();

  const { data, isFetching } = useGeneratedQuery(
    { viewName: modal?.name ?? "" },
    { enabled: !!modal?.name?.length },
  );

  const { language } = useSessionContext();

  const title = data?.i18n
    ? getTranslatedTexts(language, data.i18n)?.title
    : modal?.name;

  const createdDate = data?.createdAt
    ? format(new Date(data.createdAt), "yyyy-MM-dd hh:mm")
    : "";

  const dialogCommonProps = {
    open: Boolean(modal),
    fullWidth: true,
    maxWidth: "lg" as const,
    submitTitle: undefined,
    handleClose: handleCloseDialog,
    cancelTitle: closeLabel,
    title: modalLabel,
    children: isFetching ? (
      <Box
        width={"100%"}
        minHeight={"300px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress />
      </Box>
    ) : (
      <Box gap={1} display={"flex"} flexDirection={"column"}>
        <Box display={"flex"} justifyContent={"space-between"} gap={1}>
          <Typography sx={{ flex: 1 }}>{title}</Typography>
          <Chip
            color={data?.autogenerated ? "info" : "success"}
            label={data?.autogenerated ? "DEFAULT" : "CUSTOM"}
            variant="outlined"
          />
          <Typography variant="overline">{createdDate}</Typography>
        </Box>
        <CodeBlock value={data?.code ?? ""} language="sql" isCopy>
          {data?.code ?? ""}
        </CodeBlock>

        <FieldsTable fields={data?.fields ?? []} />
      </Box>
    ),
  };

  return modal ? (
    <DialogWrapper {...dialogCommonProps} keepMounted={false} />
  ) : null;
});
