import * as R from "ramda";

import {
  FixedFilterGroup,
  FixedFilterRule,
  IFilterGroup,
  IFilterRule,
} from "./types";

export function isFilterRule(
  ruleOrGroup: IFilterRule | IFilterGroup | FixedFilterGroup | FixedFilterRule,
): ruleOrGroup is IFilterRule | FixedFilterRule {
  return "field" in ruleOrGroup;
}

export function isFilterGroup(
  ruleOrGroup: IFilterRule | IFilterGroup | FixedFilterGroup | FixedFilterRule,
): ruleOrGroup is IFilterGroup | FixedFilterGroup {
  return "combinator" in ruleOrGroup;
}

export const getPathFromNestedName = (nestedName: string) =>
  nestedName.split(".").reduce(
    (res, p) => {
      const val = p && Number.isInteger(Number(p)) ? Number(p) : p;
      return val === "" ? res : [...res, val];
    },
    [] as (string | number)[],
  );

export const addFilter = (
  currentFilter: any,
  nestedName: string,
  filter: IFilterGroup | IFilterRule,
) => {
  const path = getPathFromNestedName(nestedName);
  const filterPath = [...path, "filters"];
  const filters = (R.path(filterPath, currentFilter) as any[]) ?? [];

  return R.assocPath(filterPath, [...filters, filter], currentFilter);
};

export const withNestedName = (name: string, nestedName?: string): string =>
  nestedName ? `${nestedName}.${name}` : name;

export const getFilterLength = (filter: IFilterGroup | null) =>
  filter?.filters?.filter((f: any) => !f.hidden).length ?? 0;

export const dataToCSV = (data: Record<string, unknown>[]): string | Error => {
  const headers = Object.keys(data[0]);

  const replacer = (_key: string, value: null) => (value === null ? "" : value); // specify how you want to handle null values here

  try {
    const csv = [
      headers.join(","), // header row first
      ...data.map((row) =>
        headers
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(","),
      ),
    ].join("\r\n");

    return csv;
  } catch (err) {
    return err as Error;
  }
};
