import { createUseTranslation } from "core/session/translation";

export const useAppSwitchTranslation = createUseTranslation({
  en: {
    switchApp: "Switch App",
  },
  es: {
    switchApp: "Cambiar Aplicación",
  },
});
