import { IRawElementType } from "core";

import Component from "./component";
import { defaultElement } from "./defaultElement";
import { TableHeaderCellConfig, TableHeaderCellTranslationKeys } from "./types";

const elementType: IRawElementType = {
  defaultElement,
  name: "default_table_header_cell",
  component: Component,
  editable: false,
  configType: TableHeaderCellConfig,
  translationKeys: TableHeaderCellTranslationKeys,
};

export default elementType;
