import { ElementGroup, IRawElementType } from "core";

import Component from "./container";
import { DateTimeInputEditor, defaultElement, editorMetadata } from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import {
  DateTimeInputConfig,
  DateTimeInputTranslationKeys,
  dateTimeInputSelectors,
} from "./types";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_date_time_input",
  group: ElementGroup.Inputs,
  component: Component,
  editable: true,
  configType: DateTimeInputConfig,
  selectorTypes: dateTimeInputSelectors,
  translationKeys: DateTimeInputTranslationKeys,
  editorComponent: DateTimeInputEditor,
  defaultElement,
  editorMetadata,
};

export default elementType;
