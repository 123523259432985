import { memo } from "react";
import { ConnectedReduxModuleProps, Element } from "core";

import Button from "../common/Button";

import { ReduxModule } from "./reduxModule";
import { SubForm } from "./types";

const DefaultSubForm = memo<ConnectedReduxModuleProps<ReduxModule, SubForm>>(
  ({ element, remove }) => {
    const handleRemove = () => remove();

    const items = element.children.content.elements.map((el) => (
      <Element key={el.id} element={el} />
    ));

    return (
      <div style={{ border: "1px solid black" }}>
        Sub Form
        <Button onClick={handleRemove} label="Remove" />
        <br />
        {items}
      </div>
    );
  },
);

export default DefaultSubForm;
