import { useMemo, useState } from "react";
import Fuse from "fuse.js";

interface IKeys {
  name: string;
  weight: number;
}

export const useSearchQuery = <T extends Record<string, unknown>>(
  searchTarget: ReadonlyArray<T>,
  keys: Array<IKeys>,
) => {
  const [searchValue, setSearchValue] = useState("");

  const fuse = useMemo(
    () =>
      new Fuse(searchTarget, {
        keys,
        includeScore: true,
      }),
    [searchTarget, keys],
  );

  const filteredResult = useMemo(
    () => fuse.search(searchValue).filter((result) => result.score! < 0.5),
    [searchValue, fuse],
  );

  return { filteredResult, searchValue, setSearchValue };
};
