import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Conditional Container",
      description: "Display elements conditionally",
    },
    de: {
      description: "",
    },
  },
  defaultSize: {
    width: 2,
    height: 2,
  },
};
