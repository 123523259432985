// createUserRole

import { UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";
import AdminService from "services/admin";

import { useAuthenticatedMutation, useAuthenticatedQuery } from "../utils";

import { QueryKeys } from "./queryKeys";
import { Role } from "./types";

const services = AdminService.getInstance();

export const fetchRoles = {
  queryKey: QueryKeys.fetchRoles,
  queryFn: services.getAllRoles,
};

export const useRoles = <SelectorResult = Role[]>(
  options?: UseQueryOptions<Role[], Error, SelectorResult>,
) => useAuthenticatedQuery(fetchRoles, undefined, options);

export const useCreateUserRole = (
  options: UseMutationOptions<Role, unknown, Role>,
) => useAuthenticatedMutation(services.createUserRole, options);

export const useDeleteRole = (
  options: UseMutationOptions<unknown, unknown, { roleName: string }>,
) => useAuthenticatedMutation(services.deleteRole, options);
