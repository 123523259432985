import { RoutePaths } from "../../../routes";
import { RolesPage } from "./component";
import { CreateRolePageRoute } from "./pages";

export const route = {
  Component: RolesPage,
  auth: true,
  isAdmin: true,
  routes: {
    [RoutePaths.RoleCreate]: CreateRolePageRoute,
  },
};
