import { createUseTranslation } from "core/session/translation";

const stateViewerTranslations = {
  en: {
    menuItemDelete: "Delete",
    menuItemEdit: "Edit",
    selfTransitionHint: "Rows in this state can be edited",
    successDelete: "Transition removed",
    successDeleteState: "Workflow removed",
    startStateHint:
      'The main purpose of this node is for registering the initial transition event, the action before the first state. Internally, the transition is represented by previous state: "NULL" to initial state "FIRST STATE". For example: the create user action will be a transition from "NULL" to "PENDING".',
    endStateHint:
      'The main purpose of this node is for registering the final transition event, the action after the last state. Internally, the transition is represented by the lasted state: "LAST STATE" to "NULL" For example: the delete user action will be a transition from "DEACTIVATED" to "NULL".',
  },
  es: {
    menuItemDelete: "Borrar",
    menuItemEdit: "Editar",
    selfTransitionHint: "Los registros en este estado pueden editarse",
    successDelete: "Transicion eliminada",
  },
};

export const useStateViewerTranslations = createUseTranslation(
  stateViewerTranslations,
);
