import { connect } from "react-redux";

import { selectors as sessionSelectors } from "../../session/reduxModule";
import { selectors } from "../reduxModule";
import { TStaticRoutesComponentsMap } from "../types";

import { createRouterComponent as createRComponent } from "./component";

const mapStateToProps = (state: any) => ({
  loadAppMetadata: sessionSelectors.loadAppMetadata(state),
  loadingPage: selectors.loadingPage(state),
  location: selectors.location(state),
  pageElement: selectors.pageElement(state),
  staticPageId: selectors.staticPageId(state),
  error: selectors.error(state),
  notFound: selectors.notFound(state),
});

export type MapStateToProps = typeof mapStateToProps;

export function createRouterComponent(
  staticRoutesComponentsMap: TStaticRoutesComponentsMap,
) {
  return connect(mapStateToProps)(createRComponent(staticRoutesComponentsMap));
}
