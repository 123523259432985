import { IRawElementType } from "core/types/element";

import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import {
  StaticSelectConfig,
  StaticSelectTranslationKeys,
  selectorTypes,
} from "./types";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_static_select",
  component: Component,
  configType: StaticSelectConfig,
  translationKeys: StaticSelectTranslationKeys,
  selectorTypes,
};

export default elementType;
