import { GeneralTypes } from "core";
import {
  AnyElement,
  IObjectView,
  IObjectViewField,
  Language,
  Translation,
} from "core/types";
import { getTranslatedText } from "core/utils/element-utils";
import { fuseFn } from "staticPages/admin/pages/modelBuilder/components/utils";
import { IColumn } from "./Columns/Columns";

const typeToOperatorMapping: Record<GeneralTypes, string[]> = {
  text: ["eq", "ilike", "is_null", "is_not_null"],
  boolean: ["is_true", "is_false", "is_null", "is_not_null"],
  number: ["eq", "lt", "gt", "is_null", "is_not_null"],
  date: ["eq", "lt", "gt", "is_null", "is_not_null"],
  time: ["eq", "lt", "gt", "is_null", "is_not_null"],
  dateTime: ["eq", "lt", "gt", "is_null", "is_not_null"],
  json: ["eq", "is_null", "is_not_null"],
  geo: ["eq", "is_null", "is_not_null"],
  fallback: ["is_null", "is_not_null"],
};

export const intervalSliderMarks = [0, 5, 10, 20, 30, 45, 60].map((v) => ({
  value: v,
  label: v.toString(),
}));

export const ROWS_PER_PAGE = [10, 25, 50, 100, 1000];

export const generateFilterFieldConfig = (
  fields: IObjectView["fields"],
  lang: Language,
) =>
  fields.map((field) => ({
    input: {
      type: field.generalType.type,
    },
    label: getTranslatedText(lang, field.i18n, "title") ?? field.name,
    name: field.name,
    operators: typeToOperatorMapping[field.generalType.type],
  }));

export const filterableObjectViewFields = (field: IObjectViewField) =>
  ["text", "number"].includes(field.generalType.type);

export const searchColumns = (columns: IColumn[], searchQuery: string) => {
  const searchData = Object.entries(columns).map(([key, column]) => {
    const { i18n } = column;

    return {
      ...column,
      key,
      allLang: {
        label: Object.values((i18n as Translation<"label">) ?? {}).reduce(
          (res, v) => (v?.label ? [...res, v.label] : res),
          [] as string[],
        ),
      },
    };
  });

  return fuseFn(searchData, [{ name: `allLang.label`, weight: 1 }])
    .search(`'${searchQuery}`)
    .map((queryObj) => queryObj.item) as IColumn[];
};

export const getReferencedColumnType = (
  fieldName: string,
  references: string[],
  tableBodyElements: AnyElement[],
  inferredType: string,
) => {
  const fieldNameFromReference = references.find((name) => name === fieldName);

  if (fieldNameFromReference) {
    const referencedColumn = tableBodyElements.find((element) =>
      element.name.includes(fieldNameFromReference),
    );

    if (referencedColumn?.type?.name !== inferredType) {
      return referencedColumn?.type?.name ?? inferredType;
    }
  }

  return null;
};
