type ColorAndContrast = {
  bgColor: string; // HEX format
  contrastTextColor: string; // HSL format for high contrast
};

function stringToHue(title: string): number {
  let hash = 0;
  for (let i = 0; i < title.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = title.charCodeAt(i) + ((hash << 5) - hash);
  }
  // Use the absolute value of hash % 360 to get a valid hue value between 0 and 359
  return Math.abs(hash % 360);
}

function hexToHsl(hex: string): [number, number, number] {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  // Then convert RGB to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h = 0;
  let s;
  const l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
    h *= 360; // Convert to degrees
  }

  return [Math.round(h), Math.round(s * 100), Math.round(l * 100)];
}

export function generateComplementaryColors(
  hexColor: string,
  title: string,
): ColorAndContrast {
  const titleHue = stringToHue(title);
  const [baseHue, saturation, lightness] = hexToHsl(hexColor);

  // Mix the base hue with the title hue to get a new, influenced hue
  const newHue = (baseHue + titleHue) % 360;
  const bgColor = `hsl(${newHue}, ${saturation}%, ${lightness}%)`;

  // Determine the contrast text color based on the new color's lightness
  const contrastTextColor =
    lightness > 50 ? "hsl(0, 0%, 10%)" : "hsl(0, 0%, 90%)";

  return { bgColor, contrastTextColor };
}
