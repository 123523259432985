import { assocPath, dissocPath, last } from "ramda";

import { Translated } from "../../../../core";

import { RoleMapping, ValidationModel, Value } from "./types";

export const objectHasValue = (obj?: Record<string, unknown>) =>
  Object.keys(obj ?? {}).length;

export const getValidationMsg = (
  name: string,
  value: Value,
  translation: Translated<string>,
): string | undefined => {
  const regexp = new RegExp("^(ldap(s?)://).");

  const newVal = value?.toString().trim();
  if (!newVal) {
    return translation.validationMessageRequired;
  }
  switch (name) {
    case "url":
      return regexp.test(newVal) ? undefined : translation.validationMessageUrl;
    default:
      return undefined;
  }
};

export const getErrors =
  (translation: Translated<string>) =>
  (
    initialErrors: ValidationModel,
    path: string[],
    value: Value,
    tableData: RoleMapping[],
  ): ValidationModel => {
    const name = last(path) as string;
    let errors = { ...initialErrors };
    let msg: string | undefined = translation.validationMessageRequired;

    switch (name) {
      case "roleMappings":
        errors = assocPath(["roleMappings"], {}, errors);
        if (value && Array.isArray(value)) {
          for (const [index, row] of value.entries()) {
            // use string rather than number so that `assocPath` builds an object instead of an array
            const indexString = index.toString();

            if (!row.ldapRole.trim()) {
              msg = translation.validationMessageLdapRoleRequired;
              errors = assocPath(
                [...path, indexString, "ldapRole"],
                msg,
                errors,
              );
            } else {
              const notUnique = tableData.some(
                (td, i) => td.ldapRole === row.ldapRole.trim() && i !== index,
              );

              if (notUnique) {
                msg = translation.validationMessageLdapRole;
                errors = assocPath(
                  [...path, indexString, "ldapRole"],
                  msg,
                  errors,
                );
              }
            }
          }
        }
        break;
      case "ldapConfiguration":
        if (typeof value === "object" && Object.keys(value ?? {}).length) {
          errors = assocPath(["ldapConfiguration"], {}, errors);
          for (const [key, val] of Object.entries(
            value as Record<string, unknown>,
          )) {
            if (typeof val === "string") {
              msg = getValidationMsg(key, val, translation);
              if (msg) {
                errors = assocPath([...path, key], msg, errors);
              }
            }
          }
        }
        break;
      default:
        msg = getValidationMsg(name, value, translation);
        if (msg) {
          errors = assocPath(path, msg, errors);
        } else {
          errors = dissocPath(path, errors);
        }
        break;
    }

    return errors;
  };
