import { ChangeEvent, ReactNode, memo, useMemo } from "react";
import { Checkbox } from "@mui/material";
import Paper from "@mui/material/Paper";
import { default as MuiTable, TableProps } from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { RefCallBack } from "react-hook-form";
import { renderRows } from "../../utils.ts";

import { EmptyRow } from "../EmptyRow";

import { TableContextProvider } from "./context";
import { TableFooter } from "./TableFooter";
import { useTableTranslation } from "./translation.ts";

export type Align = TableCellProps["align"];
type CellAlign = {
  name: string;
  align: Align;
};
type Props = {
  headers: {
    title: string;
    name: string;
  }[];
  onDataReload?: () => void;
  error?: string | null;
  loading?: boolean;
  rows?: ReactNode[];
  alignment?: CellAlign[];
  pagination?: {
    rowsPerPageOptions?: number[];
    rowsPerPage: number;
    currentPage: number;
    count: number;
    onPageChange: (event: unknown, newPage: number) => void;
    onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  };
  selection?: {
    field?: string;
    disabled: boolean;
    checked: boolean;
    onChange: (ev: unknown, checked: boolean) => void;
  };
  innerRef?: RefCallBack;
} & TableProps;

const getCellAlign = (
  cellName: string,
  alignment?: CellAlign[],
  index?: number,
): Align =>
  alignment?.find((cell) => cell.name === cellName)?.align ??
  (index === 0 ? "left" : "center");

export const Table = memo<Props>(
  ({
    rows,
    headers,
    onDataReload,
    alignment,
    loading,
    error,
    pagination,
    selection,
    innerRef,
    ...rest
  }) => {
    const { loadingTitle, noDataTitle } = useTableTranslation();

    const header = useMemo(() => {
      let cells: ReactNode[] = [];
      let cellsAlignment: Align[] = [];
      for (const [index, { title, name, ...headerRest }] of headers.entries()) {
        const align = getCellAlign(name, alignment, index);
        cells = [
          ...cells,
          <TableCell key={name} align={align} {...headerRest}>
            {!!selection && name === (selection.field ?? "checkbox") ? (
              <Checkbox
                disabled={selection.disabled}
                checked={selection.checked}
                onChange={selection.onChange}
              />
            ) : (
              title
            )}
          </TableCell>,
        ];
        cellsAlignment = [...cellsAlignment, align];
      }
      return { cells, cellsAlignment };
    }, [headers, alignment, selection]);

    const cellsAlignment = useMemo(
      () => header.cellsAlignment,
      [header.cellsAlignment],
    );

    const tableContent = useMemo(
      () =>
        renderRows(
          rows,
          pagination,
          <EmptyRow title={loading ? loadingTitle : noDataTitle} />,
        ),
      [loading, loadingTitle, noDataTitle, pagination, rows],
    );

    return (
      <TableContainer component={Paper} variant="outlined">
        <TableContextProvider value={{ alignment: cellsAlignment }}>
          <MuiTable size="small" ref={innerRef} {...rest}>
            <TableHead>
              <TableRow>{header.cells}</TableRow>
            </TableHead>
            <TableBody>{tableContent}</TableBody>
            {error && (
              <TableRow>
                <TableCell colSpan={headers.length}>
                  <Typography color="error" variant="h5">
                    {error}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {onDataReload && (
              <TableFooter
                colSpan={headers.length}
                onClick={onDataReload}
                loading={loading}
              />
            )}
          </MuiTable>
        </TableContextProvider>
        {pagination && (
          <TablePagination
            count={pagination.count}
            page={pagination.currentPage}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
            rowsPerPage={pagination.rowsPerPage}
            onPageChange={pagination.onPageChange}
            onRowsPerPageChange={pagination.onRowsPerPageChange}
            component="div"
          />
        )}
      </TableContainer>
    );
  },
);
