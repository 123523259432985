import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "inline-block",
  },
  btnClass: {
    cursor: "default",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& > [class*='MuiTouchRipple-root']": {
      display: "none",
    },
  },
  itemClass: {
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));
