import { handleActions } from "core/utils/redux";

import { Types } from "./types";

export const buildReducer = (types: Types) => {
  const INITIAL_STATE = {
    selected: {
      identifier: null,
      row: null,
    },
  };

  return handleActions(INITIAL_STATE, {
    [types.CHANGE_SELECTION_MODEL]: (state, action) => ({
      ...state,
      selected: {
        ...action.payload,
      },
    }),
  });
};
