import { createAction, createActionTypeScoper } from "core/utils/redux";
import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";
import { StorageFileMetadata } from "services/api/types/FileStorage";

export function buildActions(
  scope: string,
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    UPLOAD: scopeActionType("UPLOAD"),
    UPLOAD_SUCCESS: scopeActionType("UPLOAD_SUCCESS"),
    UPLOAD_ERROR: scopeActionType("UPLOAD_ERROR"),
    CLEAR: scopeActionType("CLEAR"),
    FETCH_METADATA: scopeActionType("FETCH_METADATA"),
    FETCH_METADATA_SUCCESS: scopeActionType("FETCH_METADATA_SUCCESS"),
    FETCH_METADATA_ERROR: scopeActionType("FETCH_METADATA_ERROR"),
  };

  const changeValue = (value: string | null) =>
    dataSource.changeFieldValue(fieldPath, value);

  const changeTouched = (value: boolean) =>
    dataSource.changeFieldTouched(fieldPath, value);

  const actions = {
    changeValue,
    changeTouched,
    upload: createAction(types.UPLOAD, (file: File | null) => ({
      file,
    })),
    uploadSuccess: createAction(
      types.UPLOAD_SUCCESS,
      (metadata: StorageFileMetadata) => ({
        metadata,
      }),
    ),
    uploadError: createAction(types.UPLOAD_ERROR, (error: any) => ({ error })),
    clear: createAction(types.CLEAR),
    fetchMetadata: createAction(types.FETCH_METADATA),
    fetchMetadataSuccess: createAction(
      types.FETCH_METADATA_SUCCESS,
      (metadata: StorageFileMetadata | null) => ({
        metadata,
      }),
    ),
    fetchMetadataError: createAction(
      types.FETCH_METADATA_ERROR,
      (error: any) => ({ error }),
    ),
  };

  return {
    actions,
    types,
  };
}

export type Actions = ReturnType<typeof buildActions>;
