import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";

export function buildActions(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
) {
  const types = {};

  const changeValue = (value: string | null) =>
    dataSource.changeFieldValue(fieldPath, value);

  const changeTouched = (value: boolean) =>
    dataSource.changeFieldTouched(fieldPath, value);

  const actions = {
    changeValue,
    changeTouched,
  };

  return {
    actions,
    types,
  };
}

export type Actions = ReturnType<typeof buildActions>;
