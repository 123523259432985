import * as t from "io-ts";
import { types } from "core/runtime-typing";
import { IElement } from "core/types";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";

import { FormInputConfig } from "../common";

export const SelectOption = t.type({
  value: t.union([t.string, t.number]),
  label: t.string,
});

export type SelectOption = t.TypeOf<typeof SelectOption>;

export const StaticSelectConfig = t.intersection([
  WithOptionalFieldDataSourceConfig(t.union([t.string, t.number, t.null])),
  FormInputConfig,
  t.type({
    options: t.array(t.union([SelectOption, t.null])),
  }),
]);

export const StaticSelectTranslationKeys = [
  "label",
  "emptyValueLabel",
] as const;

export type StaticSelectTranslationKeys =
  (typeof StaticSelectTranslationKeys)[number];

export type StaticSelectConfig = t.TypeOf<typeof StaticSelectConfig>;

export type StaticSelect = IElement<
  StaticSelectConfig,
  {},
  StaticSelectTranslationKeys
>;

export const selectorTypes = {
  value: types.nullable(types.string()),
};
