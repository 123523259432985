import { shallowEqual, useSelector } from "react-redux";
import { selectors as routerSelectors } from "core/router/reduxModule";
import { selectors as sessionSelectors } from "core/session/reduxModule";

export const usePage = () => useSelector(routerSelectors.page);

export const useQueryToPagesMapping = () => {
  const queryToPagesMapping = useSelector(
    sessionSelectors.queryToPagesMapping,
    shallowEqual,
  );

  const getPagesByQueryName = (queryName: string) =>
    queryToPagesMapping?.[queryName];

  return {
    queryToPagesMapping,
    getPagesByQueryName,
  };
};

export const useAllPages = () =>
  useSelector(routerSelectors.allPages, shallowEqual);
