import { Dispatch } from "react";
import { Node } from "reactflow";
import { NodeData } from "staticPages/admin/pages/modelBuilder/erd/types";
import { LayoutOption } from "../pages/modelBuilder/component";
import { ActionEnum } from "./actions.enum";
import { Action } from "./actions.types";
import { WorkflowViewArgs } from "./context.types";

export const getActionCreators = (dispatch: Dispatch<Action>) => ({
  setSelectedNode: (selectedNode: Node<NodeData> | null) =>
    dispatch({
      type: ActionEnum.SET_SELECTED_NODE,
      payload: {
        selectedNode,
      },
    }),
  onFilterChange: (key: string, value: string[]) =>
    dispatch({
      type: ActionEnum.ON_FILTER_CHANGE,
      payload: {
        key,
        value,
      },
    }),
  setEntitiesSearchValue: (entitiesSearchValue: string) =>
    dispatch({
      type: ActionEnum.SET_ENTITIES_SEARCH_VALUE,
      payload: {
        entitiesSearchValue,
      },
    }),
  setQueriesSearchValue: (queriesSearchValue: string) =>
    dispatch({
      type: ActionEnum.SET_QUERIES_SEARCH_VALUE,
      payload: {
        queriesSearchValue,
      },
    }),
  setTableViewFocus: (tableViewFocus: boolean) =>
    dispatch({
      type: ActionEnum.SET_TABLE_VIEW_FOCUS,
      payload: {
        tableViewFocus,
      },
    }),
  setTableViewDirection: (tableViewDirection: LayoutOption) =>
    dispatch({
      type: ActionEnum.SET_TABLE_VIEW_DIRECTION,
      payload: {
        tableViewDirection,
      },
    }),
  setExpandedTableList: (expandedTableList: string[]) =>
    dispatch({
      type: ActionEnum.SET_EXPANDED_TABLE_LIST,
      payload: {
        expandedTableList,
      },
    }),
  setExpandedQueryGroupList: (expandedQueryGroupList: string[]) =>
    dispatch({
      type: ActionEnum.SET_EXPANDED_QUERY_GROUP_LIST,
      payload: {
        expandedQueryGroupList,
      },
    }),
  setSelectedQueryEntity: (selectedQueryEntity: string | null) =>
    dispatch({
      type: ActionEnum.SET_SELECTED_QUERY_ENTITY,
      payload: {
        selectedQueryEntity,
      },
    }),
  setWorkflowView: (args: WorkflowViewArgs) =>
    dispatch({
      type: ActionEnum.SET_WORKFLOW_VIEW,
      payload: args,
    }),
});
