import * as t from "io-ts";
import { IElement, PROPS_ARE_INHERITED, arrayChild } from "core/types";

export const DefaultButtonGroupConfig = t.partial({
  style: t.UnknownRecord,
  size: t.keyof({
    small: null,
    medium: null,
    large: null,
  }),
  color: t.keyof({
    secondary: null,
    primary: null,
    inherit: null,
  }),
  fullWidth: t.boolean,
  disabled: t.boolean,
});

export const DefaultButtonGroupChildren = t.type({
  content: arrayChild("*", {
    positioned: true,
    propTypes: PROPS_ARE_INHERITED,
  }),
});

export type DefaultButtonGroupConfig = t.TypeOf<
  typeof DefaultButtonGroupConfig
>;

export type DefaultButtonGroupChildren = t.TypeOf<
  typeof DefaultButtonGroupChildren
>;

export type DefaultButtonGroup = IElement<
  DefaultButtonGroupConfig,
  DefaultButtonGroupChildren
>;
