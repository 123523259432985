import {
  ModalProps,
  TQueryFetchUtils,
} from "../../components/QueriesPanel/types.ts";
import { useDatabaseTranslation } from "../../translation.ts";

export const generateFetchQueryProps = (
  translation: ReturnType<typeof useDatabaseTranslation>,
  closeModal: () => void,
  showSnackbar: (message: string) => void,
  modal: ModalProps | null,
): TQueryFetchUtils => {
  const showMsg = (message: string) => {
    showSnackbar(message);
    closeModal();
  };

  return {
    customQuery: {
      modal,
    },
    createQueryGroupParams: {
      onSuccess: closeModal,
    },
    saveDefaultQueryParams: {
      onSuccess: () => showMsg(translation.successUpdate),
    },
    deleteQueryGroupParams: {
      onSuccess: () => showMsg(translation.successDeleteQueryGroup),
    },
    deleteQueryParams: {
      onSuccess: () => showMsg(translation.successDelete),
    },
    deleteQueriesParams: {
      onSuccess: () => showMsg(translation.successAllDelete),
    },
  };
};
