export function generateColumnName(name: string) {
  const value = name
    .replace("table_header_column_", "")
    .replace(/[^a-zA-Z0-9-_]/g, "_")
    .replace(/[-_]+$/g, "")
    .replace(/[_]+/g, "_")
    .toLowerCase();

  return `table_header_column_${value}`;
}
