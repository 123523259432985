import { createAction, createActionTypeScoper } from "core/utils/redux";

export function buildActions(scope: string) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    SELECT: scopeActionType("SELECT"),
  };

  const actions = {
    select: createAction(types.SELECT, (index: number) => ({ index })),
  };

  return { types, actions };
}
