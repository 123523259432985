import { memo } from "react";
import {
  LanguageSection,
  TranslationEditor,
  useElementEditorContext,
} from "core/editor";

import { UntransformedActionButtonConfig } from "../../types";

export const LanguageComponent = memo(() => {
  const { elementModel, changeTranslation } =
    useElementEditorContext<UntransformedActionButtonConfig>();

  return (
    <LanguageSection>
      <TranslationEditor
        changeTranslation={changeTranslation}
        i18n={elementModel.i18n}
        translationKey="label"
      />
    </LanguageSection>
  );
});
