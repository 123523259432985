import { NodeMenuDispatch, NodeMenuState } from "../types.ts";
import { ERDActions, ERDInitialState } from "./consts.ts";

export function erdReducer(
  state: NodeMenuState,
  { type, payload }: NodeMenuDispatch,
): NodeMenuState {
  switch (type) {
    case ERDActions.OPEN_MENU:
      return {
        ...state,
        ...payload,
      };
    case ERDActions.OPEN_DIALOG:
      return {
        ...state,
        ...payload,
        anchorEl: null,
      };
    case ERDActions.SET_INITIAL_STATE:
      return ERDInitialState;
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}
