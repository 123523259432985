import { IReduxModuleFactoryArgs } from "core";

import { ClipboardCopyButton } from "../types";

import { buildActions } from "./actions";
import { buildSaga } from "./saga";
import { buildSelectors } from "./selectors";

export function reduxModuleFactory({
  path,
  element,
}: IReduxModuleFactoryArgs<ClipboardCopyButton>) {
  const scope = path.join(".");

  const { actions, types } = buildActions(scope);
  const selectors = buildSelectors(element);
  const saga = buildSaga(types, element);

  return {
    actions,
    saga,
    selectors,
  };
}

export * from "./types";

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
