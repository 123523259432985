import { ElementGroup, IRawElementType } from "core";
import Component from "./container";
import {
  DefaultModalDialogEditor,
  defaultElement,
  editorMetadata,
} from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import { DefaultModalDialogChildren, DefaultModalDialogConfig } from "./types";

const elementType: IRawElementType = {
  reduxModuleFactory,
  defaultElement,
  name: "default_modal_dialog",
  group: ElementGroup.Various,
  component: Component,
  editable: true,
  configType: DefaultModalDialogConfig,
  childrenType: DefaultModalDialogChildren,
  editorComponent: DefaultModalDialogEditor,
  editorMetadata,
};
export default elementType;
