import { createUseTranslation } from "core/session/translation";

export const boolInputEditorTranslation = {
  en: {
    stylingTitle: "Styling",
    labelLabel: "Label",
    labelLeftLabel: "Label Left",
    labelRightLabel: "Label Right",
    isSwitchLabel: "Dispaly as Switch",
    isButtonLabel: "Dispaly as Button",
  },
};

export const useBoolInputEditorTranslation = createUseTranslation(
  boolInputEditorTranslation,
);
