import { MouseEvent, memo, useCallback, useMemo } from "react";
import { ToggleButtonGroup } from "@mui/material";
import { buildCustomExpressionValue } from "core";
import { Section, useElementEditorContext } from "core/editor";

import { ToggleButton } from "../../../common/ToggleButton";
import { FormConfig, FormTypes } from "../../types";
import { useEditorFormTranslation } from "../translation";

const types = Object.values(FormTypes);

export const TypeConfig = memo(() => {
  const {
    elementModel: {
      config: { type, dataSource },
    },
    changeConfigValue,
  } = useElementEditorContext<FormConfig>();

  const { typeLabel, formTypeLabel, ...labels } = useEditorFormTranslation();

  const handleValueChange = useCallback(
    (_: MouseEvent<HTMLElement>, value: keyof typeof FormTypes) => {
      changeConfigValue("type", value);

      if (value === FormTypes.create) {
        // Set identifier value as undefined
        changeConfigValue("dataSource", {
          ...dataSource,
          identifierValue: undefined,
        });
      } else {
        !dataSource.identifierValue &&
          dataSource.identifierName &&
          changeConfigValue("dataSource", {
            ...dataSource,
            identifierValue: buildCustomExpressionValue(null),
          });
      }
    },
    [changeConfigValue, dataSource],
  );

  const items = useMemo(
    () =>
      types.map((formType) => (
        <ToggleButton key={formType} value={formType}>
          {labels[`${formType}Label`]}
        </ToggleButton>
      )),
    [labels],
  );

  return (
    <Section title={formTypeLabel} wrapped collapsible={false}>
      <ToggleButtonGroup
        size="small"
        value={type}
        exclusive={true}
        onChange={handleValueChange}
        fullWidth
      >
        {items}
      </ToggleButtonGroup>
    </Section>
  );
});
