import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((_theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
}));
