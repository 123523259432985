import { memo } from "react";
import { Box } from "@mui/material";
import { Controller } from "react-hook-form";

import { Translation } from "core";
// import {
//   LanguageSection,
//   TranslationEditor,
//   useEditorTranslation,
// } from "core/editor";
// TODO: do not use editor elements!!!!
import { LanguageSection } from "core/editor/common/LanguageSection";
import { TranslationEditor } from "core/editor/common/TranslationEditor";

import Button from "elementTypes/common/Button";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";
import { useDatabaseTranslation } from "../../../translation";
import { TGroupForm } from "../../types";
import { buildEmptyI18n } from "../../utils";

export const MutateGroupForm = memo<TGroupForm>(({ i18n, onClose }) => {
  const { groupLabel, saveButton, cancelButton } = useDatabaseTranslation();
  const { control, setValue, watch } = useHookFormContext<TGroupForm>();
  const _i18n = watch("i18n") || i18n || buildEmptyI18n("label");
  const changeTranslation =
    (cb: (value: Translation<string>) => void) =>
    (value: Translation<string>) => {
      setValue("i18n", value as Translation<"label">);
      cb(value);
    };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Controller
        render={({ field: { ref, onChange, ...inputProps } }) => (
          <LanguageSection showSection={false} showTitle={false}>
            <TranslationEditor
              translationKey={"label" as const}
              i18n={_i18n}
              label={groupLabel}
              {...inputProps}
              inputRef={ref}
              changeTranslation={changeTranslation(onChange)}
              autoFocus
            />
          </LanguageSection>
        )}
        name="i18n"
        control={control}
        rules={{
          required: true,
        }}
        defaultValue={i18n}
      />
      <Box display="flex" justifyContent="flex-end" gap={1}>
        <Button onClick={onClose} color="secondary" label={cancelButton} />
        <Button type="submit" label={saveButton} />
      </Box>
    </Box>
  );
});
