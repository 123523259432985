import { Dispatch, SetStateAction, memo, useCallback } from "react";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { useEditorTranslation } from "core/editor";
import { Language, Translation } from "core/types";
import { getTranslatedTexts } from "core/utils/element-utils";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";
import { allowedCharactersPattern } from "utils/string";

import { useHookFormContext } from "../../../common/HookForm/utils";
import { useAutocompleteEditorTranslation } from "../translation";

import { OptionDetails } from "./Options";

type Props = OptionDetails & {
  i18n: Translation<"label">;
  language: Language;
  handleChangeLanguage: (lang: Language) => void;
  isValueUnique: (value: any) => boolean;
  setOptionDetails: Dispatch<SetStateAction<OptionDetails | null>>;
};

export const DialogContent = memo<Props>(
  ({
    value,
    i18n,
    language,
    handleChangeLanguage,
    setOptionDetails,
    isValueUnique,
  }) => {
    const translation = useAutocompleteEditorTranslation();
    const editorTranslation = useEditorTranslation();
    const { control, getValues, setValue } = useHookFormContext();

    const getLabel = (lang: Language) => getTranslatedTexts(lang, i18n)?.label;

    const label = getLabel(language);

    const onChange = (nextLang: Language) => {
      const values = getValues();

      setOptionDetails((prevOption) => ({
        ...prevOption!,
        value: values.value,
        i18n: {
          ...prevOption!.i18n,
          [language.code]: {
            label: values.label,
          },
        },
      }));
      handleChangeLanguage(nextLang);
      setValue("label", getLabel(nextLang));
    };

    const onValidation = (nextVal: any, field: "value" | "label") => {
      if (field === "value") {
        return !!nextVal.toString().trim().length && isValueUnique(nextVal);
      }

      return !!nextVal.toString().trim().length;
    };

    const getError = useCallback(
      (errorName?: { type: string }, fieldName?: string) => {
        switch (errorName?.type) {
          case "validate":
            return fieldName === "value"
              ? editorTranslation.notUniqueError
              : editorTranslation.invalidError;
          case "pattern":
            return editorTranslation.notMatchError;
          case "required":
            return editorTranslation.requiredError;
          default:
            return undefined;
        }
      },
      [editorTranslation],
    );

    return (
      <Box display="flex" flexDirection="column" gap={2} width={"20vw"}>
        <FormControl fullWidth={true}>
          <LanguageSwitch
            language={language}
            changeLanguage={onChange}
            colorVariant="dark"
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label={translation.labelLabel}
                helperText={getError(error)}
                error={Boolean(error)}
                sx={{ marginTop: 1 }}
                {...field}
              />
            )}
            name="label"
            control={control}
            defaultValue={label}
            rules={{
              required: true,
              validate: (val: any) => onValidation(val, "label"),
            }}
          />
        </FormControl>
        <FormControl fullWidth={true}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label={translation.valueLabel}
                helperText={getError(error, "value")}
                error={Boolean(error)}
                {...field}
              />
            )}
            name="value"
            control={control}
            defaultValue={value}
            rules={{
              required: true,
              validate: (val: any) => onValidation(val, "value"),
              pattern: allowedCharactersPattern,
            }}
          />
        </FormControl>
      </Box>
    );
  },
);
