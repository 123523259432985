import { createUseTranslation } from "core/session/translation";

export const sliderInputEditorTranslation = {
  en: {
    stylingTitle: "Styling",
    advancedTitle: "Advanced",
    elementIdLabel: "Element Id",
    fieldPathLabel: "Field Path",
    colorInputLabel: "Color",
    labelLabel: "Label",
    orientationInputLabel: "Orientation",
    valueLabelDisplayInputLabel: "Value Label Display",
    minInputLabel: "Min",
    maxInputLabel: "Max",
    stepInputLabel: "Step",
    trackInputLabel: "Track",
    marksLabel: "Enable Marks",
    marksTitle: "Marks",
    addMarkTooltip: "Add Mark",
    createMarkTitle: "Create Mark",
    editMarkTitle: "Edit Mark",
    valueLabel: "Value",
  },
};

export const useSliderInputEditorTranslation = createUseTranslation(
  sliderInputEditorTranslation,
);
