import { Implementation, createElementInterface } from "core";
import { AnyFluxStandardAction } from "core/utils/redux";

type GeoJSONInterface = {
  reload: () => AnyFluxStandardAction;
};

export const GeoJSONInterface = createElementInterface<GeoJSONInterface>();

export type GeoJSONInterfaceImplementation = Implementation<
  typeof GeoJSONInterface
>;
