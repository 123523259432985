import { IElement } from "core/types";
import { TableDataSourceImplementation } from "elementInterfaces";

export function buildActions(
  element: IElement,
  dataSource: TableDataSourceImplementation,
) {
  const types = {};

  const actions = {
    remove: () => dataSource.deleteRow(element.props.data),
  };

  return {
    actions,
    types,
  };
}

type R = ReturnType<typeof buildActions>;
export type Actions = R["actions"];
export type Types = R["types"];
