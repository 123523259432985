import { memo } from "react";
import { useElementEditorContext } from "core/editor";

import { UntransformedFormConfig } from "../types";

import {
  Config,
  DataSource,
  DefaultData,
  JsonSchema,
  MultiReference,
  Validation,
} from "./components";
import { TypeConfig } from "./components/TypeConfig";

export const FormEditorComponent = memo(() => {
  const {
    elementModel: {
      config: {
        type,
        dataSource: { identifierName },
      },
    },
  } = useElementEditorContext<UntransformedFormConfig>();

  return (
    <>
      <TypeConfig />
      <DataSource />
      {identifierName ? <MultiReference /> : null}
      <Config type={type} />
      <Validation />
      <JsonSchema />
      {type === "create" && <DefaultData />}
    </>
  );
});
