import { selectorScoper } from "core/utils/redux";

import { constants } from "./constants";
import { IState } from "./types";

/**
 * TODO:
 * "staticPages" should be removed from here. Static redux modules should be factory functions that take the path
 */
const scopeSelector = selectorScoper<IState>([
  "staticPages",
  constants.MODULE_NAME,
]);

export const selectors = {
  config: (state: any) => scopeSelector(state).config,
  error: (state: any) => scopeSelector(state).error,
  loading: (state: any) => scopeSelector(state).loading,
};
