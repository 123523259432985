import { NumberType } from "./NumberType";
import { StringType } from "./StringType";
import { Type } from "./Type";
import { TypeError } from "./TypeError";
import { IAutocompleteKey } from "./types";

export class FileType extends Type {
  public name = "file";

  public validate(value: any): true | TypeError {
    return Object.prototype.toString.call(value) === "[object File]"
      ? true
      : new TypeError(value, this);
  }

  public getAutocompleteRecord(): Record<string, IAutocompleteKey> {
    return {
      ...super.getAutocompleteRecord(),
      ...autocompleteRecord,
    };
  }
}

const autocompleteRecord = {
  name: { type: new StringType("The name of the file"), isBuiltin: false },
  size: {
    type: new NumberType("The size of the file in bytes"),
    isBuiltin: false,
  },
  type: { type: new StringType("The MIME type of the file"), isBuiltin: false },
};
