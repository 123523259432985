import { StaticSelect } from "../../types.ts";
import { buildActions } from "./actions";
import { buildReducer } from "./reducer";
import { buildSelectors } from "./selectors";

export function uncontrolledReduxModuleFactory(
  path: string[],
  element: StaticSelect,
) {
  const scope = path.join(".");
  const { actions, types } = buildActions(scope);
  const reducer = buildReducer(types);
  const selectors = buildSelectors(path, element);

  return {
    actions,
    reducer,
    selectors,
  };
}

export * from "./types";
