import { memo } from "react";
import { Chip, List } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useRouterParamByName } from "core/router/reduxModule/utils";
import BackButton from "elementTypes/common/BackButton";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { ErrorMessage } from "layouts/common/ErrorMessage";
import { LoadingComponent } from "layouts/common/Loading";

import { RoutePaths } from "staticPages/routes";
import { useUser } from "../../../../../../queries/admin";

import { UserInfo } from "./components";

export const ViewUsersPage = memo(() => {
  const userId = useRouterParamByName("userId");
  const {
    data: user,
    isLoading: loading,
    error,
  } = useUser({ userId }, { enabled: !!userId });

  if (error) {
    return (
      <Box pb={2} display="flex" alignItems="center" gap={1}>
        <BackButton isIcon href={RoutePaths.Users} />
        <ErrorMessage error={error.message} />
      </Box>
    );
  }

  if (loading || !user) {
    return <LoadingComponent />;
  }

  return (
    <Box mt={1}>
      <Box pb={2} display="flex" alignItems="center" gap={1}>
        <BackButton isIcon href={RoutePaths.Users} />
        <Typography variant="h5">{user.email}</Typography>
      </Box>

      <List sx={{ width: "100%", maxWidth: 560, bgcolor: "background.paper" }}>
        <UserInfo
          label="ID"
          icon="fingerprint"
          value={user.id}
          secondaryAction={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 1,
              }}
            >
              <Chip
                label={user.isActive ? "Active" : "Deactivated"}
                color={user.isActive ? "success" : "error"}
                variant="outlined"
                size="small"
              />
              {user.isAdmin ? (
                <MuiIcon color="info" icon="gpp_good" fontSize="medium" />
              ) : (
                <span />
              )}
            </Box>
          }
        />
        <UserInfo
          label="Username"
          icon="person_outline"
          value={`${user.userName ? user.userName : "-"}`}
        />
        <UserInfo label="Role" icon="portrait" value={user.role} />
        <UserInfo label="Language" icon="language" value={user.language} />
      </List>
    </Box>
  );
});
