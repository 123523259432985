import { memo, useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { areEqual } from "react-window";
import { IFixedRow } from "core";
import { useElementEditorContext } from "core/editor";
import { useTranslation } from "core/session";
import { getTabTranslationName } from "elementTypes/default_tabs/utils";

import { UntransformedTabsConfig } from "../../types";

import { TabConfig, TabConfigProps } from "./TabConfig";
import { useTabsContentContext } from "./TabsContext";

type IRow = IFixedRow<TabConfigProps>;

export const TabRow = memo<IRow>(({ data: items, index, style }) => {
  const { handleTabButtonClick } = useTabsContentContext();
  const {
    elementModel: { config, i18n },
  } = useElementEditorContext<UntransformedTabsConfig>();
  const { ...rest } = items[index];
  const defaultTab = index === config.default;

  const translation = useTranslation<string>(i18n);

  const name = useMemo(
    () => translation[getTabTranslationName(index)],
    [translation, index],
  );

  return (
    <Draggable key={name} draggableId={name} index={index}>
      {(provided, snapshot) => (
        <TabConfig
          {...rest}
          defaultTab={defaultTab}
          provided={provided}
          snapshot={snapshot}
          style={style}
          onButtonClick={handleTabButtonClick}
          index={index}
        />
      )}
    </Draggable>
  );
}, areEqual);
