import { createUseTranslation } from "core/session";

export const useModelBuilderTranslation = createUseTranslation({
  en: {
    stateTooltip: "State Value",
    schemeTooltip: "Schema Name",
    tableTooltip: "Table Name",
    stateColumnTooltip: "Workflow Column Name",
  },
});
