import { IColor } from "core/editor/types";
import { buildCustomExpressionValue } from "core/types";

export const featureFunctionDefault = (currentValue: string) =>
  buildCustomExpressionValue(`(feature) => {return "${currentValue}"}`);

export const DEFAULT_CONFIG_EXPRESSIONS = {
  dataSource: null,
  styleFunction: { color: "info" },
  tooltipFunction: "",
  markerDisplayTextFunction: undefined,
} as const;

export const DEFAULT_CONFIG = {
  tileLayerUrl: null,
  updateMapView: true,
  markerBackgroundColorFunction: "white",
  ...DEFAULT_CONFIG_EXPRESSIONS,
  latitude: 47.85,
  longitude: 16.19,
  zoom: 13,
} as const;

export const colorItems = [
  "white",
  "black",
  "primary",
  "secondary",
  "success",
  "info",
  "warning",
  "error",
] as IColor[];
