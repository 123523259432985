import { useCallback } from "react";
import { Node, useEdges, useNodes, useReactFlow, useStore } from "reactflow";
import { NodeData } from "./components/StateViewer/types";

export const getListWithIndex = <Entity extends { id: any }>(
  prevList: Entity[],
  list: Entity[],
) => {
  return list.reduce(
    (res, item, index) =>
      prevList.some((i) => i.id === item.id) ? res : { ...item, index },
    {},
  );
};

const getSelected = <T extends { selected?: boolean | undefined; id?: string }>(
  arr: T[],
): T | undefined => arr.find((el: T & { selected?: boolean }) => el?.selected);

export const removeNewNode = <
  T extends { selected?: boolean | undefined; id?: string },
>(
  arr: T[],
): T[] => arr.filter((item: T & { id?: string }) => item.id !== "newElement");

export const useHandleSelectElement = () => {
  const { addSelectedNodes, addSelectedEdges, unselectNodesAndEdges } =
    useStore((state) => ({
      addSelectedNodes: state.addSelectedNodes,
      addSelectedEdges: state.addSelectedEdges,
      unselectNodesAndEdges: state.unselectNodesAndEdges,
    }));

  return useCallback(
    (node: { type: "state" | "transition"; id: string } | null) => {
      if (node) {
        if (node.type === "state") {
          addSelectedNodes([node.id]);
        } else {
          addSelectedEdges([node.id]);
        }
      } else {
        unselectNodesAndEdges();
      }
    },
    [addSelectedNodes, addSelectedEdges, unselectNodesAndEdges],
  );
};

export const useSelectedState = () => {
  const nodes = useNodes();
  const edges = useEdges();
  const selectedNode = getSelected(nodes);
  const selectedEdge = getSelected(edges);

  return {
    selectedNode,
    selectedEdge,
    nodes,
  };
};

export const useFocusWorkflowNode = () => {
  const nodes = useNodes();
  const { setCenter } = useReactFlow();

  return useCallback(
    (nodeId: string | number) => {
      const focusNode = (nodes as Node<NodeData>[]).find(
        (n) => n.id && n.id === nodeId,
      );

      if (focusNode && focusNode.width && focusNode.height) {
        const x = focusNode.position.x + focusNode.width / 2;
        const y = focusNode.position.y + focusNode.height / 2;
        const zoom = 1.8;

        setCenter(x, y, { zoom });
      }
    },
    [nodes, setCenter],
  );
};

export const useFlowState = () => {
  const { selectedNode: node, selectedEdge: edge } = useSelectedState();
  const setSelectedElement = useHandleSelectElement();
  const focusState = useFocusWorkflowNode();

  return {
    node,
    edge,
    setSelectedElement,
    focusState,
  };
};
