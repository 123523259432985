import { UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";
import AdminService from "services/admin";
import { useAuthenticatedMutation, useAuthenticatedQuery } from "../utils";

import { QueryKeys } from "./queryKeys";
import {
  Extension,
  RepositoryBranch,
  RepositoryConfiguration,
  RepositoryData,
} from "./types";

const services = AdminService.getInstance();

export const fetchExtensions = {
  queryKey: QueryKeys.fetchExtensions,
  queryFn: services.getExtensions,
};

export const useExtensions = (config?: UseQueryOptions<Extension[], Error>) =>
  useAuthenticatedQuery(fetchExtensions, undefined, config);

export const useInstallExtension = (
  options: UseMutationOptions<
    { success: boolean },
    unknown,
    { name: string; version?: string }
  >,
) => useAuthenticatedMutation(services.installExtension, options);

export const useUninstallExtension = (
  options: UseMutationOptions<{ success: boolean }, unknown, { name: string }>,
) => useAuthenticatedMutation(services.uninstallExtension, options);

export const fetchRepositoryConfigurations = {
  queryKey: QueryKeys.fetchRepositoryConfigurations,
  queryFn: services.getRepositoryConfigurations,
};

export const fetchRepositoryConfiguration = {
  queryKey: QueryKeys.fetchRepositoryConfiguration,
  queryFn: services.getRepositoryConfiguration,
};

export const fetchRepositoriesContent = {
  queryKey: QueryKeys.fetchRepositoriesContent,
  queryFn: services.getRepositoriesContent,
};

export const getRepositoryResourceContent = {
  queryKey: QueryKeys.getRepositoryResourceContent,
  queryFn: services.getRepositoryResourceContent,
};

export const useRepositoryConfigurations = () =>
  useAuthenticatedQuery(fetchRepositoryConfigurations);

export const useRepositoryConfiguration = (
  params: { id: string },
  config?: UseQueryOptions<RepositoryConfiguration, Error>,
) => useAuthenticatedQuery(fetchRepositoryConfiguration, params, config);

export const useUpdateRepositoryConfiguration = (
  options: UseMutationOptions<
    RepositoryConfiguration,
    unknown,
    RepositoryConfiguration
  >,
) => useAuthenticatedMutation(services.updateRepositoryConfiguration, options);

export const useTestRepositoryConfiguration = (
  options: UseMutationOptions<boolean, unknown, RepositoryConfiguration>,
) => useAuthenticatedMutation(services.testRepositoryConfiguration, options);

export const useRepositoryBranches = (
  options: UseMutationOptions<
    RepositoryBranch[] | { message: string },
    unknown,
    Pick<
      RepositoryConfiguration,
      "repositoryPath" | "repositoryBaseUrl" | "personalAccessToken"
    >
  >,
) => useAuthenticatedMutation(services.getRepositoryBranches, options);

export const useDeleteRepositoryConfiguration = (
  options: UseMutationOptions<unknown, unknown, { id: number }>,
) => useAuthenticatedMutation(services.deleteRepositoryConfiguration, options);

export const useRepositoriesContent = (
  options?: UseQueryOptions<RepositoryData[], Error>,
) => useAuthenticatedQuery(fetchRepositoriesContent, undefined, options);

export const useRepositoryResourceContent = (
  params: {
    repositoryId: number;
    resourcePath: string;
  },
  config?: UseQueryOptions<string, Error, Record<string, unknown>[]>,
) => useAuthenticatedQuery(getRepositoryResourceContent, params, config);

export const useInstallApplication = (
  options: UseMutationOptions<
    { success: boolean },
    unknown,
    { repositoryId: number; name: string; version: string }
  >,
) => useAuthenticatedMutation(services.installApplication, options);

export const useUninstallApplication = (
  options: UseMutationOptions<
    { success: boolean },
    unknown,
    { repositoryId: number; name: string; version?: string }
  >,
) => useAuthenticatedMutation(services.uninstallApplication, options);
