import { memo } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { useStyles } from "./styles";
import { usePlacementEditorTranslation } from "./translation";

const placementOptions = ["start", "top", "bottom", "end"];

type LabelPlacement = Exclude<
  FormControlLabelProps["labelPlacement"],
  undefined
>;

type Props = {
  value: FormControlLabelProps["labelPlacement"];
  onChange: (value: LabelPlacement) => void;
  label?: string;
};

export const PlacementSelector = memo<Props>(
  ({ value = "end", label, onChange }) => {
    const translation = usePlacementEditorTranslation();
    const { classes } = useStyles();

    const options = placementOptions.map((option) => (
      <FormControlLabel
        key={option}
        value={option}
        control={<Radio color="primary" />}
        label={translation[`${option}Label`]}
        labelPlacement={option as LabelPlacement}
      />
    ));

    const handleChange = (_: any, newValue: string) =>
      onChange(newValue as LabelPlacement);

    return (
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend" className={classes.label}>
          {label ?? translation.title}
        </FormLabel>
        <RadioGroup
          row
          className={classes.group}
          aria-label="position"
          name="position"
          value={value}
          onChange={handleChange}
        >
          {options}
        </RadioGroup>
      </FormControl>
    );
  },
);
