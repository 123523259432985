import { createUseTranslation } from "core/session/translation";

export const editorTranslation = {
  en: {
    basicTitle: "Basic",
    tileLayerUrlLabel: "Tile Layer URL",
    tileLayerUrlDescription: "Leave empty to use OpenStreetMaps",
    updateMapViewLabel: "Update map view on data change",
    stylingFunctionLabel: "Fill Color",
    stylingFunctionDescription:
      "A Function defining the Path options for styling GeoJSON lines and polygons.",
    tooltipFunctionLabel: "Tooltip",
    markerDisplayTextFunctionLabel: "Icon or Label",
    markerDisplayTextFunctionDescription:
      "return the label for a marker; hint: you can use emojis ✨",
    markerBackgroundColorFunctionLabel: "Background Color",
    markerBackgroundColorFunctionDescription:
      "the background color of the marker",
    coordinatesTitle: "Coordinates",
    selectedTitle: "Selection",
    latitude: "Latitude",
    longitude: "Longitude",
    zoom: "Zoom",
    queryLabel: "Query",
    customLabel: "Custom",
    tooltipTitle: "Title",
    propertyLabel: "Column (Property)",
    dataLabel: "Data",
    dataTooltip: "Visit https://geojson.org/ to check a proper data type.",
    selectionWarning:
      "Selection Tools: Active Within the Query Data Source Only",
    selectedColumnLabel: "Property (Column)",
    selectedValueLabel: "Property Value",
    selectedDeleteLabel: "Remove Selection Conditions",
    markerStylesLabel: "Marker Styles",
    pathStylesLabel: "Path Styles",
    selectedPropertyLabel: "Selected Property",
  },
};

export const useDefaultGeoJSONFieldEditorTranslation =
  createUseTranslation(editorTranslation);
