import { PropsWithChildren, memo, useState } from "react";
import { Provider } from "./QueryFilterContext.utils.ts";

export const QueryFilterProvider = memo<PropsWithChildren>(({ children }) => {
  const [roleFilter, setRoleFilter] = useState<string | undefined>();

  return (
    <Provider value={{ roleFilter, onRoleFilterChange: setRoleFilter }}>
      {children}
    </Provider>
  );
});
