import { createUseTranslation } from "core/session/translation";

export const actionButtonEditorTranslation = {
  en: {
    callArgsLabel: "Arguments passed to function",
    stylingSectionTitle: "Styling",
    colorInputLabel: "Color",
    displaySectionTitle: "Display",
    variantInputLabel: "Variant",
    primaryLabel: "Primary",
    secondaryLabel: "Secondary",
    defaultLabel: "Default",
    inheritLabel: "Inherit",
    containedLabel: "Contained",
    outlinedLabel: "Outlined",
    showNotificationLabel: "Show Notification",
    disabledLabel: "Show Disabled",
    onActionTitle: "Configure actions",
  },
};

export const useActionButtonEditorTranslation = createUseTranslation(
  actionButtonEditorTranslation,
);
