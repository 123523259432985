import { memo, useMemo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { UseMutationResult } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import Link from "elementTypes/common/Link";

import { MuiIcon } from "elementTypes/common/MuiIcon";
import { RoutePaths } from "staticPages/routes";
import { LoadingComponent } from "../../../../layouts/common/Loading";
import { useDeleteUser, useUsers } from "../../../../queries/admin";
import { getApiError } from "../../../../queries/utils";
import { useSnackbar } from "../../../../utils/hooks/useSnackbar";
import { TableRow } from "../../common";
import { Table } from "../../common/components/Table";
import useStyles from "../../styles";

import { User } from "./types";

type RowProps = {
  user: User;
  deleteUser: UseMutationResult<unknown, unknown, { userId: string }>;
  isCurrentUser: boolean;
};

const Row = memo<RowProps>(({ user, deleteUser, isCurrentUser }) => {
  const onDeleteClick = () => {
    deleteUser.mutate({ userId: user.id });
  };

  return (
    <TableRow rowId={user.id}>
      <Link
        variant="body2"
        title="View user info"
        href={`/admin/users/view/${user.id}`}
        sx={{
          textDecoration: "none",
          ":hover": { textDecoration: "underline" },
        }}
      >
        {user.email}
      </Link>
      <Typography>{user.userName}</Typography>
      <Typography>{user.role}</Typography>
      <Typography>{user.language}</Typography>
      {user.isAdmin ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <MuiIcon color="info" icon="gpp_good" fontSize="medium" />
        </Box>
      ) : (
        <span />
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <MuiIcon
          color={user.isActive ? "success" : "error"}
          icon={user.isActive ? "check" : "not_interested"}
          fontSize="medium"
        />
      </Box>

      <>
        <Box display="flex" alignItems="center" justifyContent="center">
          <IconButton
            icon="create"
            color="primary"
            href={`/admin/users/edit/${user.id}`}
          />
          <IconButton
            icon="delete_outline"
            color="primary"
            onClick={onDeleteClick}
            disabled={isCurrentUser}
          />
        </Box>
      </>
    </TableRow>
  );
});

const titles = [
  "Email",
  "Username",
  "Role",
  "Language",
  "Admin",
  "Active",
  "Actions",
];

const headers = titles.map((title) => ({
  name: title.toLowerCase(),
  title,
}));

export const UsersPage = memo(() => {
  const additionalInformation = useSelector(
    sessionSelectors.additionalInformation,
  );
  const currentEmail =
    additionalInformation?.type === "integrated"
      ? additionalInformation.email
      : null;
  const showSnackbar = useSnackbar();
  const { data, refetch, isFetching, isInitialLoading, error } = useUsers();

  const deleteUser = useDeleteUser({
    onSuccess: () => {
      showSnackbar("User deleted successfully", "success");
      refetch();
    },
    onError: (er) => {
      const apiError = getApiError(er);
      showSnackbar(`User could not be deleted: ${apiError}`, "error");
    },
  });

  const rows = useMemo(
    () =>
      (data ?? []).map((user) => (
        <Row
          key={user.id}
          user={user}
          deleteUser={deleteUser}
          isCurrentUser={currentEmail === user.email}
        />
      )),
    [data, currentEmail, deleteUser],
  );
  const {
    classes: { horizontallyCenter },
  } = useStyles();

  return (
    <Grid container spacing={2} className={horizontallyCenter}>
      <Grid item xs={12} sm={8}>
        <Typography variant="h5">Users</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        style={{ display: "inline-flex", justifyContent: "flex-end" }}
      >
        <Button
          color="primary"
          href={RoutePaths.UsersCreate}
          iconLeft="add"
          label="Create"
        />
      </Grid>
      <Grid item xs={12}>
        {isInitialLoading ? (
          <LoadingComponent />
        ) : (
          <Table
            rows={rows}
            headers={headers}
            onDataReload={refetch}
            loading={isFetching}
            error={error?.message}
          />
        )}
      </Grid>
    </Grid>
  );
});
