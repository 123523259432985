import { createTheme } from "@mui/material/styles";
import { PALETTE } from "utils/ci-colors";
import { hexToHSLA } from "utils/colors";

const secondaryColor = PALETTE.SECONDARY;
const primaryColor = PALETTE.PRIMARY;

export const bgColor = hexToHSLA("#E1F1FE", 0.8);

const successColor = PALETTE.SUCCESS;
const errorColor = PALETTE.ERROR;
const warningColor = PALETTE.WARNING;

export const textPrimary = PALETTE.TEXT_PRIMARY;
export const textSecondary = PALETTE.TEXT_SECONDARY;

export const adminTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    success: {
      main: successColor,
    },
    warning: { main: warningColor },
    error: { main: errorColor },
    background: {
      default: bgColor,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    action: {
      selected: hexToHSLA(secondaryColor, 0.1),
      hover: hexToHSLA(secondaryColor, 0.15),
      focus: hexToHSLA(secondaryColor, 0.15),
    },
  },
  typography: {
    allVariants: {
      // fontFamily: "good times regular",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "outlined",
      },
      // styleOverrides: {
      //   root: {
      //     backgroundColor: alpha(textPrimary, 0.04),
      //     "&:hover": {
      //       boxShadow: `1px 1px 6px ${hexToHSLA(textPrimary, 0.15)}`,
      //       filter: "brightness(0.9)",
      //     },
      //   },
      //   outlinedPrimary: {
      //     border: `1px solid ${primaryColor}`,
      //     backgroundColor: alpha(primaryColor, 0.04),
      //     "&:hover": {
      //       boxShadow: `1px 1px 6px ${hexToHSLA(primaryColor, 0.2)}`,
      //       filter: "brightness(0.9)",
      //     },
      //   },
      //   outlinedSecondary: {
      //     border: `1px solid ${secondaryColor}`,
      //     backgroundColor: alpha(secondaryColor, 0.04),
      //     "&:hover": {
      //       boxShadow: `1px 1px 6px ${hexToHSLA(secondaryColor, 0.2)}`,
      //       filter: "brightness(0.9)",
      //     },
      //   },
      // },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
});
