import { ElementGroup, IRawElementType } from "core";

import Component from "./container";
import { FileInputEditor, defaultElement, editorMetadata } from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import {
  MultipleFileInputConfig,
  MultipleFileInputTranslationKeys,
  multipleFileInputSelectors,
} from "./types";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "storage_multiple_files_input",
  group: ElementGroup.Inputs,
  component: Component,
  editable: true,
  configType: MultipleFileInputConfig,
  selectorTypes: multipleFileInputSelectors,
  translationKeys: MultipleFileInputTranslationKeys,
  editorComponent: FileInputEditor,
  defaultElement,
  editorMetadata,
};

export default elementType;
