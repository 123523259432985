import { createUseTranslation } from "core/session";

export const translation = {
  en: {
    delete: "Delete",
    upload: "Upload",
    open: "Open",
    reload: "Reload",
    close: "Close",
    save: "Save",
    name: "Name",
    type: "Type",
    actions: "Actions",
    uploadTitle: "Upload Files",
    clearAllLabel: "Clear All",
    filesLabel: "Files",
  },
};

export const useMultipleFileInputTranslation =
  createUseTranslation(translation);
