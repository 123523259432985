import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  footer: {
    textAlign: "center",
  },
  dividerFooter: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(),
  },
  codeEditor: {
    "& .CodeMirror": {
      height: "150px",
    },
  },
}));

export default useStyles;
