import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Text Input",
      description: "Unformatted text input",
    },
  },
};
