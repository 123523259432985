import { selectorScoper } from "core/utils/redux";
import { MetaQuery } from "../types";
import { IState } from "./types";

export function buildSelectors(path: string[], element: MetaQuery) {
  const scopeSelector = selectorScoper<IState>(path);

  const loading = (state: any) => scopeSelector(state).loading;
  const data = (state: any) => scopeSelector(state).data;
  const error = (state: any) => scopeSelector(state).error;

  const filter = (state: any) => element.config.filter(state) ?? null;
  const responseFormat = (state: any) =>
    typeof element.config.dataSource.responseFormat === "function"
      ? element.config.dataSource.responseFormat(state)
      : element.config.dataSource.responseFormat;

  return {
    loading,
    data,
    error,
    filter,
    responseFormat,
  };
}
