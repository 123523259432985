import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  toggleBtnClass: {
    width: "100%",
    marginBottom: theme.spacing(),
  },
  marginTop: {
    marginTop: theme.spacing(),
  },
}));
