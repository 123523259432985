import { memo, useCallback, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import classNames from "classnames";

import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { useAppEdition } from "queries/admin";
import {
  useDeleteRepositoryConfiguration,
  useRepositoryConfigurations,
} from "queries/admin/extensionsData";
import { RepositoryConfiguration } from "queries/admin/types";
import { Align } from "staticPages/admin/common/components/Table";
import { RoutePaths } from "staticPages/routes";
import { useSnackbar } from "utils/hooks/useSnackbar";
import { getApiError as getError } from "../../../../queries/utils";
import { TableRow } from "../../common";
import { Table } from "../../common/components/Table";
import useStyles from "../../styles";
import { useRepositoryConfigurationTranslation } from "./translation";

const tableConfig = [
  { name: "repositoryName", title: "Title", align: "center" },
  { name: "userName", title: "User Name", align: "center" },
  { name: "repositoryPath", title: "Path", align: "center" },
  { name: "repositoryBranch", title: "Branch", align: "center" },
  {
    name: "personalAccessToken",
    title: "Personal Access Token",
    align: "center",
  },
  { name: "actions", title: "Actions", align: "center" },
] as Array<{ align: Align; title: string; name: string }>;

type RowType = {
  configuration: RepositoryConfiguration;
  handleDelete: (id: number) => void;
};

const Row = memo<RowType>(({ configuration, handleDelete }) => {
  const t = useRepositoryConfigurationTranslation();
  if (!configuration?.id) {
    return <span />;
  }
  const onDelete = () => handleDelete(configuration.id!);

  return (
    <TableRow rowId={configuration.id!}>
      <Typography>{configuration.repositoryName}</Typography>
      <Typography component="span">{configuration.userName}</Typography>
      <Typography>{configuration.repositoryPath}</Typography>
      <Typography>{configuration.repositoryBranch}</Typography>
      <TokenConfig value={configuration.personalAccessToken} />
      <Box display="flex" alignItems="right" justifyContent="right">
        <IconButton
          tooltip={t.contentTooltip}
          icon="video_library"
          color="secondary"
          data-testid="admin-configurations-visibility"
          href={`/admin/repositories/applications?repositoryId=${configuration.id!}`}
        />
        <IconButton
          tooltip={t.createTooltip}
          icon="create"
          color="default"
          data-testid="admin-configurations-edit"
          href={`/admin/repositories/configuration/${configuration.id}`}
        />
        <IconButton
          tooltip={t.deleteTooltip}
          icon="delete_outline"
          color="error"
          data-testid="admin-configurations-delete"
          onClick={onDelete}
        />
      </Box>
    </TableRow>
  );
});

export const TokenConfig = memo<{
  value: string;
}>(({ value }) => {
  const [toggleToken, setToggleToken] = useState<boolean>(true);

  const toggleVisibility = () =>
    setToggleToken((prevVisibility) => !prevVisibility);

  const {
    classes: { blur },
  } = useStyles();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Typography
        className={classNames({
          [blur]: toggleToken,
        })}
        variant="caption"
        width="100%"
        noWrap
      >
        {value}
      </Typography>
      <IconButton
        edge="end"
        size="small"
        onClick={toggleVisibility}
        icon={toggleToken ? "visibility" : "visibility-off"}
      />
    </Box>
  );
});

export const RepositoryConfigurationPage = memo(() => {
  const showSnackbar = useSnackbar();
  const { data: isEnterprise } = useAppEdition();
  const useConfig = useRepositoryConfigurations();
  const translation = useRepositoryConfigurationTranslation();
  const [deleteInfo, setDeleteInfo] = useState<number | null>(null);
  const {
    classes: { inlineAndEndFlex },
  } = useStyles();

  const { mutate } = useDeleteRepositoryConfiguration({
    onSuccess: () => {
      useConfig.refetch();
      showSnackbar(translation.deleteSuccessMessage, "info");
    },
    onError: (error) => {
      const msg = getError(error);
      showSnackbar(msg, "error");
    },
  });

  const handleDelete = useCallback(() => {
    if (deleteInfo) {
      mutate({ id: deleteInfo });
    }
    setDeleteInfo(null);
  }, [deleteInfo, mutate]);

  const handleCloseDialog = () => {
    setDeleteInfo(null);
  };

  const rows = useMemo(
    () =>
      useConfig.data?.map((configuration) => (
        <Row
          key={configuration.id}
          configuration={configuration}
          handleDelete={setDeleteInfo}
        />
      )),
    [useConfig.data],
  );

  if (!isEnterprise) {
    return null;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h5">{translation.title}</Typography>
        </Grid>
        <Grid item xs={12} sm={4} className={inlineAndEndFlex}>
          <Button
            color="primary"
            href={RoutePaths.RepositoryConfigNew}
            iconLeft="add"
            label={translation.newRepository}
          />
        </Grid>
      </Grid>
      <Box pt={1}>
        <Table
          alignment={tableConfig}
          rows={rows}
          headers={tableConfig}
          loading={useConfig.isLoading}
          error={useConfig.error?.toString()}
        />
        <DialogWrapper
          open={!!deleteInfo}
          title={translation.deleteRepositoryConfirmationTitle}
          cancelTitle={translation.cancelButton}
          contentText={`${translation.deleteRepositoryConfirmationText}`}
          submitTitle={translation.deleteButton}
          fullWidth={true}
          handleSubmit={handleDelete}
          handleClose={handleCloseDialog}
        />
      </Box>
    </>
  );
});
