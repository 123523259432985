import { memo, useCallback } from "react";
import { PageSelector, Section, useElementEditorContext } from "core/editor";
import { IPage } from "core/types";

import { UntransformedInternalLinkButtonConfig } from "../../types";
import { useInternalLinkButtonEditorTranslation } from "../translation";

export const LinkConfigComponent = memo(() => {
  const {
    elementModel: {
      config,
      config: {
        linkTo,
        linkTo: { pageId, params },
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedInternalLinkButtonConfig>();
  const translation = useInternalLinkButtonEditorTranslation();

  const handlePageChange = useCallback(
    (p: IPage, newParams: any) => {
      changeConfigValue("linkTo", {
        ...linkTo,
        pageId: p.id,
        params: newParams,
      });
    },
    [changeConfigValue, linkTo],
  );

  return (
    <Section wrapped title={translation.linkConfigSectionTitle}>
      <PageSelector
        pageId={pageId}
        config={config}
        params={params}
        onChange={handlePageChange}
        label={translation.pageSelectorLabel}
      />
    </Section>
  );
});
