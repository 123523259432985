import { connect } from "react-redux";
import { selectors as routerSelectors } from "core/router/reduxModule";
import { IElementComponentProps, createColorSelector } from "core/types";
import { connectErrorHandlerUtils } from "core/utils/react-redux";

import { DefaultInternalLinkButton as Component } from "./component";
import { InternalLinkButton } from "./types";

/*
 * TODO:
 * TAKE THE LOGIC FROM COMPONENT AND PUT IT HERE. THE LOCATION CHANGE COULD HAVE PARAMETERS
 * THAT MUST BE TAKEN FROM OTHER ELEMENTS' SELECTORS, THE SAME WAY THE DETAIL FORM WORKS. FORMALIZE A JSON
 * STRUCTURE FOR ELEMENTS' SELECTORS AND ACTIONS, THAT INCLUDES ID AND SELECTOR OR ACTION.
 */

export const mapStateToProps = (
  state: any,
  { element }: IElementComponentProps<{}, InternalLinkButton>,
) => {
  const { params } = element.config.linkTo;
  return {
    params: Object.keys(params).reduce(
      (p, k) => ({
        ...p,
        [k]: params[k](state),
      }),
      {},
    ),
    disabled: element.config.disabled?.(state),
    pages: routerSelectors.allPages(state),
    color: createColorSelector(element.config.color)(state),
  };
};

export type MapStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(
  connectErrorHandlerUtils.enhanceMapStateToProps(mapStateToProps),
)(connectErrorHandlerUtils.enhanceComponent(Component));
