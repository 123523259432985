import { memo } from "react";
import { Box } from "@mui/material";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";

import { IColor } from "../../types";
import { colors as defaultColors } from "../BackgroundSelector/consts";
import { ColorSelect } from "../ColorSelect";

import { allowedValuesToggleModeHandler } from "../CustomExpressionEditor/utils";
import { useColorSelectorTranslation } from "./translation";

type Props = {
  config: Record<string, any>;
  onChange: (value: string) => void;
  configKey?: string;
  colors?: IColor[];
  defaultValue?: IColor;
  label?: string;
};

export const ColorSelector = memo<Props>(
  ({
    config,
    configKey = "color",
    onChange,
    colors,
    defaultValue = "default",
    label,
  }) => {
    const allColors = colors ?? defaultColors;
    const { textColorLabel } = useColorSelectorTranslation();

    const customColorSelect = ({
      value: textValue,
      onChange: onColorChange,
    }: NonExpressionEditorProps) => (
      <ColorSelect
        value={textValue}
        onChange={onColorChange}
        defaultValue={defaultValue}
        colorItems={allColors}
      />
    );

    const handleToggle = (wasExpression: boolean) => {
      allowedValuesToggleModeHandler(allColors)(
        config[configKey] ?? allColors[0],
        onChange,
        allColors[0],
      )(wasExpression);
    };

    return (
      <Box>
        <CustomExpressionEditor
          label={label ?? textColorLabel}
          value={config[configKey]}
          config={config}
          onChange={onChange}
          nonExpressionEditor={customColorSelect}
          onToggleMode={handleToggle}
        />
      </Box>
    );
  },
);
