import { MouseEvent, ReactElement, memo } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardProps,
  List,
  ListItem,
  ListItemText,
  SvgIconProps,
  Typography,
  TypographyProps,
} from "@mui/material";

import { useRoute } from "utils/hooks";
import { AlertBox } from "../common/AlertBox";
import IconButton from "../common/IconButton";
import { IconNameType, MuiIcon } from "../common/MuiIcon";

import { useStyles } from "./style";
import { useDataDisplayTranslation } from "./translation";

const positionToDirection = {
  left: "row",
  top: "column",
  right: "row-reverse",
  bottom: "column-reverse",
} as const;

type BarPosition = "top" | "right" | "bottom" | "left";

export type DefaultDataDisplay = {
  label: string;
  formattedData: any;
  error: any;
  loading: boolean;
  load: () => void;
  color?: string;
  icon?: IconNameType;
  barPosition?: BarPosition | null;
  variant?: TypographyProps["variant"];
  href?: string;
  cardVariant?: CardProps["variant"];
};

const PrettifyData = ({
  value,
  space = 2,
}: {
  value: Record<string, unknown>;
  space?: number;
}) => {
  const items = Object.entries(value).map(([key, val]) => (
    <ListItem key={`${key}-key`}>
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">{key}</Typography>}
        secondary={
          val && typeof val === "object" ? (
            PrettifyData({ value: val as Record<string, unknown> })
          ) : (
            <Typography variant="body2">
              {JSON.stringify(val, undefined, space)}
            </Typography>
          )
        }
      />
    </ListItem>
  ));

  return (
    <List dense sx={{ m: 0, p: 0 }}>
      {items}
    </List>
  );
};

const DataDisplay = memo<DefaultDataDisplay>(
  ({
    label,
    icon,
    barPosition = null,
    formattedData,
    error,
    loading,
    color: colorConfig = "info",
    variant,
    href,
    cardVariant,
    load,
  }) => {
    const route = useRoute();
    const {
      classes: { root, dataDisplay },
    } = useStyles();

    const text = useDataDisplayTranslation();

    const hasBar = barPosition !== null;

    const sanitizedFormattedData =
      typeof formattedData === "object" && formattedData !== null ? (
        <PrettifyData value={formattedData} />
      ) : (
        <Typography
          variant={variant}
          className={dataDisplay}
          sx={{ pl: 1, pb: 1 }}
        >
          {formattedData}
        </Typography>
      );

    let strError;
    if (error) {
      try {
        strError = error.toString();
      } catch (e) {
        strError = "Unexpected Error";
      }
    }

    const handleReload = (ev: MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      load();
    };

    const inner = (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: icon ? "1fr minmax(70px, max-content)" : "1fr",
          gridTemplateRows: label ? "minmax(30px, max-content) 1fr" : "1fr",
          flexGrow: "1",
          gap: 1,
          maxWidth:
            barPosition && ["right", "left"].includes(barPosition)
              ? "calc(100% - 8px)"
              : "100%",
          overflow: "hidden",
        }}
      >
        {label && (
          <Typography
            variant="subtitle1"
            sx={{
              gridColumn: "1 / 2",
              gridRow: "1 / 1",
              pt: 1,
              pl: 1,
            }}
          >
            {label}
          </Typography>
        )}
        <Box
          className={root}
          sx={{
            gridColumn: "1 / 1",
            gridRow: label ? "2 / 3" : "1 /1",
            pt: label ? 0 : 1,
          }}
        >
          {sanitizedFormattedData}
          {error && strError && (
            <AlertBox alertTitle="Error" message={strError} />
          )}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          pt={3.5}
          pr={0.5}
          sx={{
            gridColumn: "2 / 2",
            gridRow: label ? "1 / 3" : "1 / 1",
          }}
        >
          {icon && (
            <MuiIcon
              icon={icon}
              color={colorConfig as SvgIconProps["color"]}
              sx={{ fontSize: 78 }}
            />
          )}
        </Box>
      </Box>
    );

    let wrapped: ReactElement<any, any>;
    if (hasBar) {
      wrapped = (
        <Box
          display="flex"
          flexDirection={positionToDirection[barPosition!]}
          flexWrap="nowrap"
          height={1}
        >
          {<Box bgcolor={`${colorConfig}.main`} padding={0.5} />}
          {inner}
        </Box>
      );
    } else {
      wrapped = inner;
    }

    const handleClick = () => {
      if (href) {
        route("push", href);
      }
    };

    return (
      <Card
        {...(cardVariant === "outlined"
          ? {
              variant: cardVariant,
            }
          : { raised: true })}
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        {href ? (
          <CardActionArea LinkComponent="div" onClick={handleClick}>
            {wrapped}
          </CardActionArea>
        ) : (
          wrapped
        )}
        <IconButton
          icon="refresh"
          tooltip={text.reload}
          onClick={handleReload}
          disabled={loading}
          processing={loading}
          color="inherit"
          size="small"
          sx={{
            position: "absolute",
            ...(barPosition === "top"
              ? {
                  bottom: -2,
                }
              : {
                  top: -2,
                }),

            right: barPosition === "right" ? 2 : -2,
          }}
        />
      </Card>
    );
  },
);

export default DataDisplay;
