import { createSelector } from "reselect";
import { selectorScoper } from "core/utils/redux";

import { Form } from "../types";

import { IState } from "./types";
import { formHasChanges, getDefaultData, isFormValid } from "./utils.ts";

export function buildSelectors(path: string[], element: Form) {
  const scopeSelector = selectorScoper<IState>(path);
  const { identifierValue } = element.config.dataSource;
  const validation = element.config.validation;

  const loadState = (state: any) => scopeSelector(state).load;
  const saveState = (state: any) => scopeSelector(state).save;
  const data = (state: any) => scopeSelector(state).data;
  const errors = (state: any) => scopeSelector(state).errors;
  const touched = (state: any) => scopeSelector(state).touched;
  const originalData = (state: any) => scopeSelector(state).originalData;
  const failedData = (state: any) => scopeSelector(state).failedData;
  const allowedStateChanges = (state: any) =>
    scopeSelector(state).allowedStateChanges;
  const stateFieldValue = (state: any) => scopeSelector(state).stateFieldValue;
  const saveAttemptsSinceReset = (state: any) =>
    scopeSelector(state).saveAttemptsSinceReset;

  const validExpression = (state: any) => validation?.(state);

  const hasChanges = createSelector([data, originalData], (d, o) =>
    formHasChanges(d, o),
  );

  // validExpression must be true/false/string or not set
  // false disables the buttons, true or "" enables the buttons, a non-empty string shows an error and disables the buttons.
  // no errors or no errors on touched fields
  const isValid = createSelector(
    [errors, validExpression, touched, saveAttemptsSinceReset],
    (err, validExpr, touchedFields, saveAttempts) =>
      isFormValid(err, validExpr, touchedFields, saveAttempts),
  );

  const identifier = (state: any) =>
    identifierValue ? identifierValue(state) : undefined;

  const defaultData = (state: any) => {
    const { defaultData: configDefaultData } = element.config;
    return getDefaultData(state, configDefaultData);
  };

  return {
    hasChanges,
    isValid,
    loadState,
    saveState,
    data,
    errors,
    touched,
    originalData,
    defaultData,
    validExpression,
    identifier,
    failedData,
    allowedStateChanges,
    stateFieldValue,
    saveAttemptsSinceReset,
  };
}
