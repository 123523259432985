import { MouseEvent } from "react";
import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Tip } from "common/elements/Tip";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import { QueryKeys, useWorkflowActivationSwitch } from "queries/admin";
import { getApiError } from "queries/utils";
import { useLocation } from "utils/hooks";
import { useSnackbar } from "utils/hooks/useSnackbar";

import { useWorkflowActivateTranslations } from "./translation";

export default function EnforceWorkflow({ value = false }: { value: boolean }) {
  const showSnackbar = useSnackbar();
  const location = useLocation();
  const {
    activationSuccess,
    deactivationSuccess,
    activateLabel,
    deactivateLabel,
    activateTooltip,
    deactivateTooltip,
    helperText,
  } = useWorkflowActivateTranslations();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useWorkflowActivationSwitch({
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
    onSuccess: (_result, variables) => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchWorkflow] });
      showSnackbar(
        variables.active ? activationSuccess : deactivationSuccess,
        "success",
      );
    },
  });

  const handleChange = (_e: MouseEvent<HTMLElement>, isChecked: boolean) => {
    const {
      queries: { schema, table },
    } = location;
    mutate({ schema, table, active: !isChecked });
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <ToggleButton
        value={value}
        selected={!!value}
        tooltip={value ? activateTooltip : deactivateTooltip}
        color="primary"
        onClick={handleChange}
        disabled={isLoading}
        size="small"
      >
        {value ? activateLabel : deactivateLabel}
      </ToggleButton>
      <Tip text={helperText} />
    </Box>
  );
}
