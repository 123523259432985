import { useCallback, useMemo } from "react";
import { Translation } from "core";
import { useObjectViewList } from "core/editor";
import { useSessionContext } from "core/session";
import { getTranslatedTexts } from "core/utils/element-utils";
import { GeoJSONSelected } from "../types";

export const useViewOptions = (viewName: string) => {
  const { getViewByName } = useObjectViewList();
  const view = getViewByName(viewName);
  const { language } = useSessionContext();
  const getTranslatedLabel = useCallback(
    (i18n: Translation<"title">) => getTranslatedTexts(language, i18n).title,
    [language],
  );

  const viewOptions =
    useMemo(
      () =>
        view?.fields?.map((field) => ({
          value: field.name,
          label: getTranslatedLabel(field.i18n) ?? field.name,
        })),
      [getTranslatedLabel, view?.fields],
    ) ?? [];

  return view ? viewOptions : undefined;
};

export const hasSelection = (selected?: GeoJSONSelected | null) => {
  if (!selected) {
    return false;
  } else {
    return !!(selected?.property?.length && selected?.propertyValue?.length);
  }
};
