import { HTMLAttributes, PropsWithChildren, memo } from "react";
import { Theme, styled } from "@mui/material/styles";
import {
  MaterialDesignContent,
  SnackbarProvider as NotistackProvider,
} from "notistack";

import { SnackbarDefaultAction } from "layouts/common/Snackbar";
import { SNACKBAR_ANCHOR_ORIGIN } from "layouts/common/Snackbar/utils";

// Style the MaterialDesignContent component
const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }: { theme: Theme }) => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: theme.palette.success.main,
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: theme.palette.error.main,
    },
    "&.notistack-MuiContent-info": {
      backgroundColor: theme.palette.info.main,
    },
    "&.notistack-MuiContent-warning": {
      backgroundColor: theme.palette.warning.main,
    },
  }),
);

// SnackbarProvider component
export const SnackbarProvider = memo<PropsWithChildren<{}>>(({ children }) => {
  const ownProps = {
    "data-testid": "snackbar",
  } as HTMLAttributes<HTMLDivElement>;

  return (
    <NotistackProvider
      maxSnack={5}
      dense
      anchorOrigin={SNACKBAR_ANCHOR_ORIGIN}
      preventDuplicate
      action={SnackbarDefaultAction}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
      SnackbarProps={ownProps}
    >
      {children}
    </NotistackProvider>
  );
});
