import * as t from "io-ts";
import { IElement, customExpression } from "core/types";
import { UntransformedConfig } from "core/types/react";

export const MarkdownFieldConfig = t.intersection([
  t.type({
    text: customExpression(t.union([t.string, t.null])),
  }),
  t.partial({
    overflow: t.keyof({
      hidden: null,
      auto: null,
      visible: null,
    }),
    align: t.keyof({
      left: null,
      center: null,
      right: null,
      justify: null,
    }),
    htmlAllowed: t.boolean,
  }),
]);

export type MarkdownFieldConfig = t.TypeOf<typeof MarkdownFieldConfig>;

export type UntransformedMarkdownFieldConfig =
  UntransformedConfig<MarkdownFieldConfig>;

export const MarkdownFieldTranslationKeys = ["text"] as const;

export type MarkdownFieldTranslationKeys =
  (typeof MarkdownFieldTranslationKeys)[number];

export type MarkdownField = IElement<
  MarkdownFieldConfig,
  {},
  MarkdownFieldTranslationKeys
>;
