import { memo } from "react";
import { Card, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useDatabasePanelContext } from "../context/databasePanelPermission/DatabasePanelContext.utils.ts";
import { useDatabaseTranslation } from "../translation.ts";
import { DatabasePanelType } from "./types.ts";

const panelTypes = Object.keys(DatabasePanelType);

export const ToggleDatabasePanel = memo(
  ({ disable }: { disable?: keyof typeof DatabasePanelType }) => {
    const { type, setType } = useDatabasePanelContext();
    const translation = useDatabaseTranslation();

    const panelTypeButtons = panelTypes.map((panelType) => (
      <ToggleButton
        value={panelType}
        key={panelType}
        data-testid={`database-panel-button-${panelType}`}
        disabled={disable === panelType}
      >
        {translation[panelType]}
      </ToggleButton>
    ));

    return (
      <Card
        variant="outlined"
        sx={{ gridColumn: "2 / span 1", borderColor: "transparent" }}
      >
        <ToggleButtonGroup
          value={type}
          onChange={setType}
          exclusive
          fullWidth
          sx={{ height: "100%" }}
          color="primary"
        >
          {panelTypeButtons}
        </ToggleButtonGroup>
      </Card>
    );
  },
);
