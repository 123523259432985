import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Line Chart",
      description: "A Line Chart",
    },
    de: {
      description: "Ein Liniendiagramm",
    },
  },
  minSize: {
    height: 3,
    width: 3,
  },
};
