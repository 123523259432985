import { IRawElementType } from "core/types/element";

import Component from "./container";
import { LabelFieldConfig } from "./types";

const elementType: IRawElementType = {
  name: "default_label_field",
  component: Component,
  editable: true,
  configType: LabelFieldConfig,
};

export default elementType;
