import { memo } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";

import { ArrayField } from "../ArrayField";
import { JsonView } from "../JsonView";
import { IExtendedColDef } from "./types";

export const CustomRenderCell = memo<GridRenderCellParams>(
  ({ colDef, value }) => {
    const columnDefinition = colDef as unknown as IExtendedColDef;

    if (columnDefinition.isArray) {
      return <ArrayField values={value ?? []} />;
    } else {
      return <JsonView value={value} fullWidth disabled embedded popup />;
    }
  },
);
