import { memo } from "react";
import { Divider, Stack, Typography } from "@mui/material";

import { ArrayField } from "elementTypes/common/ArrayField";
import CodeBlock from "elementTypes/common/CodeBlock/component.tsx";
import { StyledTypography } from "elementTypes/common/StyledTypography";
import { Modal } from "..";
import { PermissionAutocomplete } from "../../PermissionAutocomplete";
import { useProceduresTranslation } from "../translation";
import { Form } from "./Form";

type Props = {
  data: Modal;
};

export const DialogContent = memo<Props>(({ data }) => {
  const t = useProceduresTranslation();
  const code = data.item?.sourceCode ?? "";
  const acl = data.item?.acl;

  const content = {
    delete: (
      <>
        <Typography noWrap>{t.deleteModalContent}</Typography>
        {<StyledTypography color="info" text={data?.item?.name ?? ""} />}
      </>
    ),
    create: <Form />,
    view: (
      <>
        <Stack
          width={"100%"}
          pb={2}
          display="grid"
          gridTemplateColumns="1fr 2fr"
          gap={1}
        >
          <Typography>{t.nameLabel}</Typography>
          <StyledTypography color="info" text={data?.item?.name ?? ""} />
          <Divider />
          <Divider />
          <Typography>{t.createdByLabel}</Typography>
          <StyledTypography color="info" text={data?.item?.owner ?? ""} />
          {data?.item?.arguments?.length ? (
            <>
              <Divider />
              <Divider />
              <Typography>{t.argumentsLabel}</Typography>
              <ArrayField
                values={data.item.arguments.split(",")}
                color="info"
                size="small"
                variant="outlined"
              />
            </>
          ) : null}
          {data.item?.returnType?.length ? (
            <>
              <Divider />
              <Divider />
              <Typography>{t.returnTypeLabel}</Typography>
              <StyledTypography
                color="info"
                text={data.item?.returnType ?? ""}
              />
            </>
          ) : null}
        </Stack>
        <CodeBlock value={code} language="sql" isCopy>
          {code}
        </CodeBlock>
        <PermissionAutocomplete acl={acl} />
      </>
    ),
  };

  return content[data.type];
});
