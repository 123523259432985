import { ChangeEvent, memo } from "react";
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { Section, useElementEditorContext } from "core/editor";
import { UntransformedEchartConfig } from "../../EchartsContainer.ts";
import { useEchartsTranslation } from "../../translation.ts";

type LabelPlacement = FormControlLabelProps["labelPlacement"];

const placement = ["start", "top", "bottom", "end", "hidden"];
const orientation = ["horizontal", "vertical"];

export const Legend = memo(<
  UntransformedChartConfig extends UntransformedEchartConfig,
>() => {
  const {
    elementModel: {
      config: { legend = {} },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedChartConfig>();
  const translation = useEchartsTranslation();

  const itemsPlacement = placement.map((position) => (
    <FormControlLabel
      key={position}
      value={position}
      control={<Radio color="primary" />}
      label={translation[`${position}Label`]}
      {...(position === "hidden"
        ? { style: { flex: 1 } }
        : {
            labelPlacement: position as LabelPlacement,
            style: { margin: 0 },
          })}
    />
  ));

  const itemsOrient = orientation.map((orient) => (
    <FormControlLabel
      key={orient}
      value={orient}
      control={<Radio color="primary" />}
      label={translation[`${orient}Label`]}
    />
  ));

  const handleChange = (ev: ChangeEvent<HTMLInputElement>, value: string) =>
    changeConfigValue("legend", { ...legend, [ev.target.name]: value });

  return (
    <Section title={translation.legendShowLabel} wrapped={true}>
      <FormControl margin="dense">
        <FormLabel id="radio-buttons-placement">
          {translation.placementLabel}
        </FormLabel>
        <RadioGroup
          ria-labelledby="radio-buttons-placement"
          value={legend?.placement ?? "start"}
          row
          aria-label="position"
          name="placement"
          onChange={handleChange}
          style={{
            justifyContent: "space-between",
          }}
        >
          {itemsPlacement}
        </RadioGroup>
      </FormControl>
      <FormControl margin="dense" disabled={legend?.placement === "hidden"}>
        <FormLabel id="radio-buttons-orientation">
          {translation.orientationLabel}
        </FormLabel>
        <RadioGroup
          aria-labelledby="radio-buttons-orientation"
          value={legend?.orient ?? "horizontal"}
          name="orient"
          onChange={handleChange}
          row
        >
          {itemsOrient}
        </RadioGroup>
      </FormControl>
    </Section>
  );
});
