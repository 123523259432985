import { createUseTranslation } from "core/session/translation";

export const internalLinkButtonEditorTranslation = {
  en: {
    pageSelectorLabel: "Link To",
    linkConfigSectionTitle: "Referring Page",
    displaySectionTitle: "Display",
    stylingSectionTitle: "Styling",
    sizeInputLabel: "Size",
    primaryLabel: "Primary",
    secondaryLabel: "Secondary",
    defaultLabel: "Default",
    smallLabel: "Small",
    mediumLabel: "Medium",
    largeLabel: "Large",
    fullWidthInputLabel: "Full Width",
    disabledInputLabel: "Disabled",
  },
};

export const useInternalLinkButtonEditorTranslation = createUseTranslation(
  internalLinkButtonEditorTranslation,
);
