import { createTypeSafeContext } from "utils/createTypeSafeContext.ts";
import {
  TQueriesPanelProvider,
  TQueryGroupEmptyDroppable,
  TQueryGroupFullDroppable,
} from "../../components/QueriesPanel/types.ts";

export const {
  Provider: QueryGroupFullDroppableProvider,
  useTypeSafeContext: useQueryGroupFullDroppableContext,
} = createTypeSafeContext<TQueryGroupFullDroppable>();

export const {
  Provider: QueryGroupEmptyDroppableProvider,
  useTypeSafeContext: useQueryGroupEmptyDroppableContext,
} = createTypeSafeContext<TQueryGroupEmptyDroppable>();

export const { Provider, useTypeSafeContext: useQueriesPanelContext } =
  createTypeSafeContext<TQueriesPanelProvider>();
