import { createUseTranslation } from "core/session/translation";

export const useAppBarMenuTranslation = createUseTranslation({
  en: {
    logout: "Log out",
    settings: "Settings",
  },
  es: {
    logout: "Cerrar Sesión",
  },
});
