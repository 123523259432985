import { memo } from "react";
import { Helmet } from "react-helmet";

import { EditorModeProvider } from "core/context/EditorMode/EditorModeContext";
import { LoadingComponent } from "layouts/common/Loading";
import { useSessionContext } from "../SessionContext";
import { determineContent } from "./DetermineContent";
import { SessionLayoutProps } from "./types";
import { getPageTitle } from "./utils";

export const SessionLayout = memo<SessionLayoutProps>((props) => {
  const {
    loadAppMetadata: { inProgress },
  } = props;
  const { language } = useSessionContext();

  const pageTitle = getPageTitle({ ...props, language });
  const content = determineContent(props);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <EditorModeProvider>
        {inProgress ? <LoadingComponent /> : content}
      </EditorModeProvider>
    </>
  );
});

SessionLayout.displayName = "SessionLayout";
