import { RoutePaths } from "../../../routes";
import { FilesPageComponent as FPComponent } from "./component";
import { EditFilePageRoute, UploadFilesPageRoute } from "./pages";

export const route = {
  Component: FPComponent,
  auth: true,
  isAdmin: true,
  routes: {
    [RoutePaths.FilesUpload]: UploadFilesPageRoute,
    [RoutePaths.FileEdit]: EditFilePageRoute,
  },
};
