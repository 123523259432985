import { memo, useMemo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "elementTypes/common/Button";

import { RoutePaths } from "staticPages/routes";
import { useLoginConfig } from "../../../../queries/admin";
import { TableRow } from "../../common";
import { Table } from "../../common/components/Table";
import useStyles from "../../styles";

import { useSettingsTranslation } from "./translation";
import { SettingsConfig } from "./types";

type Row = {
  name: keyof SettingsConfig;
  config: SettingsConfig;
  label: string;
};

const Row = memo<Row>(({ name, config, label }) => (
  <TableRow rowId={name}>
    <Typography>{label}</Typography>
    <Typography>{config[name]}</Typography>
  </TableRow>
));

const titles = ["Name", "Value"];
const headers = titles.map((title) => ({
  name: title.toLowerCase(),
  title,
}));

export const SettingsPage = memo(() => {
  const {
    data: config,
    isLoading: loading,
    error,
    refetch,
    isFetching,
  } = useLoginConfig();

  const t = useSettingsTranslation();

  const rows = useMemo(
    () =>
      config
        ? [
            <Row
              key={"logoPath"}
              name={"logoPath"}
              config={config}
              label={t.logoPathLabel}
            />,
            <Row
              key={"identifierInputTitle"}
              name={"identifierInputTitle"}
              config={config}
              label={t.identifierInputTitleLabel}
            />,
          ]
        : [],
    [config, t],
  );
  const {
    classes: { horizontallyCenter },
  } = useStyles();

  return (
    <Grid container spacing={2} className={horizontallyCenter}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h5">Login Settings</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Page Layout</Typography>
          <Button
            color="primary"
            label="Edit"
            iconLeft="edit"
            href={RoutePaths.AdminSettingsEdit}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Table
          rows={rows}
          headers={headers}
          onDataReload={refetch}
          loading={loading || isFetching}
          error={error?.message}
        />
      </Grid>
    </Grid>
  );
});
