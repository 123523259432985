import { ReactNode, memo, useMemo, useReducer } from "react";
import { getActionCreators } from "./actionCreators";
import { initialState, reducer } from "./reducer";
import { AdminProvider as Provider } from "./utils";

export const AdminStateProvider = memo<{ children: ReactNode }>(
  ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const actionCreators = useMemo(() => getActionCreators(dispatch), []);

    return (
      <Provider
        value={{
          ...state,
          ...actionCreators,
        }}
      >
        <>{children}</>
      </Provider>
    );
  },
);
