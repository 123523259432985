import { lazy, memo, useMemo } from "react";

import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { TMagicType } from "./Echarts";
import { IEchartsContainerSingleProps } from "./EchartsContainer";
import { IOptionEchartProps } from "./types";

export const Echarts = withLazyLoading(
  lazy(() => import("./Echarts")),
  true,
);

/*
 * EchartsContainerOneSerie - Echarts Container for echarts that
 * contain only one serie
 *
 */
const EchartsContainerOneSerie = memo<IEchartsContainerSingleProps>(
  ({ dataSource, data, defaultOptions, type, title, ...rest }) => {
    const option: IOptionEchartProps = useMemo(
      () => ({
        ...defaultOptions,
        dataset: {
          dimensions: [
            dataSource.columns.labelColumnName,
            dataSource.columns.valueColumnName,
          ],
          source: data,
          sourceHeader: false,
        },
        series: [
          {
            ...defaultOptions.series?.[0],
            type,
          },
        ],
        legend: {
          bottom: 0,
          ...defaultOptions.legend,
        },
        title: title ? { text: title, x: "center" } : null,
      }),
      [data, defaultOptions, dataSource, type, title],
    );

    return <Echarts option={option} {...rest} chartType={type as TMagicType} />;
  },
);

export default EchartsContainerOneSerie;
