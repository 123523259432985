import { MouseEvent, memo } from "react";
import { Typography } from "@mui/material";

import { GridToolbarContainer } from "@mui/x-data-grid";
import { AlertBox } from "elementTypes/common/AlertBox";
import Button from "elementTypes/common/Button";
import { useTableHeaderTranslation } from "./translation";
import { EditToolbarProps } from "./types";

export const TableHeader = memo<EditToolbarProps>(
  ({ title, onRowAdd, disabled, errors }) => {
    const { addLabel } = useTableHeaderTranslation();

    const handleMouseDown = (event: MouseEvent) => {
      event.preventDefault();
    };

    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 1,
          paddingX: 2,
          paddingY: 1,
        }}
      >
        <Typography variant="h6">{title}</Typography>
        {errors && typeof errors === "string" && (
          <AlertBox message={errors} boxProps={{ flex: 1 }} />
        )}
        <Button
          onClick={onRowAdd}
          label={addLabel}
          onMouseDown={handleMouseDown}
          size="small"
          color="inherit"
          variant="default"
          iconLeft="add"
          disabled={disabled}
          data-testid="add-row-button"
        />
      </GridToolbarContainer>
    );
  },
);
