import { createUseTranslation } from "core/session/translation";

export const actionConfigTranslation = {
  en: {
    noTriggerActionLabel: "No actions configured",
    onTriggerActionTitle: "Actions triggers",
    refreshChartLabel: "Refresh chart",
    refreshDisplayDataLabel: "Refresh Data Display",
    reloadTableLabel: "Refresh table",
    saveFormLabel: "Save form",
    toggleModalLabel: "Toggle modal dialog",
    resetInputLabel: "Reset input",
    showNotificationLabel: "Show notification only",
    navigateLabel: "Navigate",
    linkToLabel: "Link to Page",
    goBackLabel: "Go Back",
    notificationMessagePlaceholder: "Leave empty not to show a message",
    notificationPersistLabel: "Persist",
    notificationVariantLabel: "Variant",
    notificationMessageLabel: "Message",
    notificationSuccessLabel: "Success",
    notificationErrorLabel: "Error",
    notificationDefaultLabel: "Default",
    notificationWarningLabel: "Warning",
    notificationInfoLabel: "Info",
    setNotificationTitle: "Set Notification",
    reloadFormLabel: "Reload form",
    elementLabel: "Element",
  },
};

export const useTranslation = createUseTranslation(actionConfigTranslation);
