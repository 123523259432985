import * as t from "io-ts";
import { IElement } from "core/types";

export const ProcedureButtonConfig = t.type({
  func_name: t.string,
  func_schema: t.string,
  func_args: t.any,
});

export type ProcedureButtonConfig = t.TypeOf<typeof ProcedureButtonConfig>;

export const ProcedureButtonTranslationKeys = ["label"] as const;

export type ProcedureButtonTranslationKeys =
  (typeof ProcedureButtonTranslationKeys)[number];

export type ProcedureButton = IElement<
  ProcedureButtonConfig,
  {},
  ProcedureButtonTranslationKeys
>;
