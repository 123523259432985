import { createUseTranslation } from "../../../core/session/translation";

export const hookFormErrorTranslation = {
  en: {
    requiredError: "Value is Required",
    invalidError: "Invalid Value",
    notMatchError:
      "Value can only contain numbers, letters, hyphens and underscores",
    notMatchPathError:
      "URL can only contain numbers, letters, hyphens, underscores and slashes",
    notUniqueError: "Value Should be Unique",
  },
  de: {},
  es: {},
};

export const useHookFormErrorTranslation = createUseTranslation(
  hookFormErrorTranslation,
);
