import { memo, useCallback, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Markdown from "react-markdown";
import { useDispatch } from "react-redux";
import { IAdminUi, IAppMetadata, IUi } from "core";
import { actions as sessionActions } from "core/session/reduxModule";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import { IAutocompleteValue } from "elementTypes/common/Autocomplete/types.ts";
import Button from "elementTypes/common/Button";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { useFilteredApps } from "queries/admin";
import {
  useDeleteApp,
  useExportApp,
  useMetadataUi,
  useUIList,
} from "queries/admin/appData";
import { useDeleteFile } from "queries/admin/fileData.ts";
import { getApiError as getError } from "queries/utils";
import { useLocationSearch } from "staticPages/admin/common/utils.ts";
import { RoutePaths } from "staticPages/routes";
import { useSnackbar } from "utils/hooks/useSnackbar";
import { exportToJsonFile } from "utils/json";

import { Table } from "../../common/components/Table";
import { Align } from "../../common/components/Table";
import useStyles from "../../styles";
import { useRoles } from "../modelBuilder/components/QueriesPanel/utils/useRoles.ts";
import { ApplicationRow } from "./components/ApplicationRow.tsx";
import {
  TApplicationRowProps,
  TDeleteType,
  TDescriptionType,
  TDialogState,
} from "./components/types.ts";

import { useCommonStaticPagesTranslation } from "./translation";

const tableConfig: Array<{ align: Align; title: string; name: string }> = (
  [
    { title: "", align: "left", padding: "checkbox" },
    { title: "Title", align: "left" },
    { title: "Name", align: "center" },
    { title: "Modified", align: "center" },
    { title: "Owner", align: "center" },
    { title: "Published release", align: "center" },
    { title: "Actions", align: "center", padding: "checkbox" },
  ] as const
).map((config) => ({ ...config, name: config.title.toLowerCase() }));

export const AppsPage = memo(() => {
  const { changeLocation, getQueryParam } = useLocationSearch({});

  const dispatch = useDispatch();
  const translation = useCommonStaticPagesTranslation();

  const showSnackbar = useSnackbar();
  const currentRole = getQueryParam("role");

  const ui = useUIList();
  const uiList = useMemo(() => (ui.data ?? []) as IUi[], [ui.data]);

  const apps = useFilteredApps({ role: currentRole });

  const { rolesListItems } = useRoles();

  const [dialogInfo, setDialogInfo] = useState<TDialogState | null>(null);

  const handleError = (error: unknown) => {
    const msg = getError(error);
    showSnackbar(msg, "error");
  };

  const { data: appMetadata = {} as IAppMetadata } = useMetadataUi(
    (dialogInfo as TDeleteType)?.name ?? "",
    !!(dialogInfo as TDeleteType)?.name?.length,
  );

  const deleteFile = useDeleteFile({
    onError: handleError,
  });

  const deleteUi = useDeleteApp({
    onSuccess: () => {
      apps.refetch();
      showSnackbar(translation.deletedMessage, "success");
    },
    onError: handleError,
  });

  const exportUi = useExportApp({
    onSuccess: (data, params) => {
      exportToJsonFile(data.release.definition, params.uiName + ".json");
      showSnackbar(translation.exportAppMessage, "success");
    },
    onError: handleError,
  });

  const {
    classes: { horizontallyCenter, inlineAndEndFlex, applicationsHeaderButton },
  } = useStyles();

  const handleAppClick = useCallback(
    (app: IAdminUi) => {
      const nextUi = uiList.find((uiItem) => uiItem.id === app.id);
      if (nextUi) {
        dispatch(sessionActions.changeUi(nextUi));
      }
    },
    [dispatch, uiList],
  );
  const handleDeleteApp = useCallback(() => {
    if (dialogInfo) {
      const customLogo =
        appMetadata?.release.definition.layout.definition.logo?.fileName ?? "";

      if (customLogo.length) {
        deleteFile.mutate({ fileName: customLogo });
      }

      deleteUi.mutate({ uiName: (dialogInfo as TDeleteType).name });
    }
    setDialogInfo(null);
  }, [dialogInfo, appMetadata, deleteUi, deleteFile]);

  const handleDataReload = () => apps.refetch();

  const handleExportJsonApp = useCallback(
    (uiName: string) => {
      exportUi.mutate({ uiName });
    },
    [exportUi],
  );

  const handleCloseDialog = () => setDialogInfo(null);

  const applicationRowProps = useMemo<TApplicationRowProps>(
    () => ({
      handleClick: handleAppClick,
      handleExport: handleExportJsonApp,
      onDialogOpen: setDialogInfo,
    }),
    [handleAppClick, handleExportJsonApp],
  );

  const rows = useMemo(
    () =>
      (apps?.data ?? []).map((app) => (
        <ApplicationRow key={app.id} ui={app} {...applicationRowProps} />
      )),
    [apps?.data, applicationRowProps],
  );

  const handleFilterChange = (newValue: IAutocompleteValue | null) => {
    const newQuery = {
      role: String(newValue ?? ""),
    };

    changeLocation(newQuery);
  };

  return (
    <Grid container spacing={2} className={horizontallyCenter}>
      <Grid item xs={12} sm={5}>
        <Typography variant="h5">{translation.appTitle}</Typography>
      </Grid>
      <Grid item xs={12} sm={7} className={inlineAndEndFlex}>
        <Box display="flex" width="50%" marginRight={1}>
          <Autocomplete
            options={rolesListItems}
            onChange={handleFilterChange}
            size="small"
            placeholder={translation.filterByOwnerLabel}
            value={currentRole ?? null}
          />
        </Box>
        <Button
          color="primary"
          href={RoutePaths.AppsGenerate}
          iconLeft="add"
          label={translation.generateLabel}
          className={applicationsHeaderButton}
        />
        <Button
          color="primary"
          href={RoutePaths.AppsCreate}
          iconLeft="cloud_upload"
          label={translation.createLabel}
        />
      </Grid>
      <Grid item xs={12}>
        <Table
          alignment={tableConfig}
          rows={rows}
          headers={tableConfig}
          onDataReload={handleDataReload}
          loading={apps.isLoading}
          error={apps.error?.toString()}
        />
      </Grid>
      <DialogWrapper
        open={!!dialogInfo}
        title="Description"
        cancelTitle={"Close"}
        handleClose={handleCloseDialog}
        fullWidth={true}
        maxWidth="md"
        {...(dialogInfo && {
          ...("name" in dialogInfo && {
            contentText: `${translation.dialogContentText} ${dialogInfo.name}?`,
            submitTitle: translation.DeleteButton,
            handleSubmit: handleDeleteApp,
            title: translation.dialogTitle,
            cancelTitle: translation.cancelButton,
            maxWidth: "sm",
          }),
        })}
      >
        {dialogInfo && !("name" in dialogInfo) && (
          <DescriptionContent {...dialogInfo} />
        )}
      </DialogWrapper>
    </Grid>
  );
});

const DescriptionContent = ({
  publishedDescription,
  description,
  latestDescription,
}: TDescriptionType) => (
  <>
    <Markdown>{publishedDescription || description || ""}</Markdown>
    {publishedDescription !== latestDescription && (
      <>
        <Typography sx={{ px: 1 }}>Unpublished</Typography>
        <Markdown>{latestDescription}</Markdown>
      </>
    )}
  </>
);
