import { ChangeEvent, KeyboardEvent, memo } from "react";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { GridCellParams, useGridApiContext } from "@mui/x-data-grid";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { FormattedDate, FormattedTime } from "react-intl";
import { getDateValue } from "utils/date";

import { ArrayField } from "../../ArrayField";
import IconButton from "../../IconButton";
import { JsonView } from "../../JsonView";

import { useStyles } from "./styles";

export const CustomInput = memo<GridCellParams>((params) => {
  const {
    colDef: { generalType, nullable, editComponent, editProps },
    field: name,
    value,
  } = params as any;

  const apiRef = useGridApiContext();

  const onChange = (nextVal: GridCellParams["value"]) => {
    apiRef.current.setEditCellValue({
      id: params.id,
      field: params.field,
      value: nextVal,
    });
  };

  const clearable = nullable !== false;

  const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) =>
    e.target.value !== null && e.target.value !== ""
      ? onChange(Number(e.target.value))
      : null;

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      !String.fromCharCode(e.which ?? e.keyCode).match(/^[0-9]+$/) &&
      e.key !== "Enter"
    ) {
      e.preventDefault();
    }
  };

  const handleDateChange = (inputDate: unknown) => {
    const date = inputDate as Date;
    onChange(date ? date.toISOString() : null);
  };

  const cleanValue = () => onChange("");

  const handleArrayChange = (_: any, newData: (string | string[])[]) => {
    const nextArray =
      newData && generalType.type === "number"
        ? [...newData].map((val) => Number(val))
        : newData;
    onChange(nextArray);
  };

  const handleValueChange = (ev: ChangeEvent<HTMLInputElement>) =>
    onChange(ev.target.value);

  const { classes } = useStyles();

  let inputProps = {
    name,
    className: classes.inputBox,
    margin: "none" as const,
    autoFocus: true,
    ...editProps,
  };

  if (editComponent) {
    const Component = editComponent;
    // adjust inputProps depending on component will be rendered
    // editComponent must have displayName property
    if (editComponent.displayName === "BaseAutocomplete") {
      inputProps = {
        ...inputProps,
        valueObject: (inputProps.options ?? [])?.find(
          (o: any) => o.value === value,
        ) ?? { label: value, value },
      };
    }

    return <Component value={value} onChange={onChange} {...inputProps} />;
  }

  if (generalType.isArray) {
    return (
      <Autocomplete
        renderInput={(inputParams: AutocompleteRenderInputParams) => (
          <TextField
            {...inputParams}
            type={generalType.type}
            {...(generalType.type === "number" && {
              onKeyPress,
            })}
            autoFocus
          />
        )}
        disableClearable={!clearable}
        onChange={handleArrayChange}
        value={(value ?? []) as string[]}
        multiple
        freeSolo
        options={[]}
        size="small"
        {...inputProps}
      />
    );
  } else {
    switch (generalType.type) {
      case "json":
        return (
          <JsonView
            {...inputProps}
            value={value as Record<string, unknown>}
            nullable={clearable}
            popup={true}
            embedded={true}
            onValueChange={onChange}
          />
        );
      case "number":
        return (
          <TextField
            type="number"
            {...inputProps}
            value={value ?? ""}
            onChange={handleNumberChange}
            onKeyPress={onKeyPress}
            {...(clearable && {
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      icon="close"
                      onClick={cleanValue}
                      edge="start"
                    />
                  </InputAdornment>
                ),
              },
            })}
          />
        );
      case "dateTime":
        return (
          <DateTimePicker
            {...inputProps}
            value={getDateValue(value as string | Date)}
            onChange={handleDateChange}
            renderInput={(props) => <TextField fullWidth {...props} />}
            clearable={clearable}
          />
        );
      case "date":
        return (
          <DatePicker
            {...inputProps}
            value={getDateValue(value as string | Date)}
            onChange={handleDateChange}
            renderInput={(props) => <TextField fullWidth {...props} />}
            clearable={clearable}
          />
        );
      case "time":
        return (
          <TimePicker
            {...inputProps}
            value={getDateValue(value as string | Date)}
            onChange={handleDateChange}
            renderInput={(props) => <TextField fullWidth {...props} />}
            clearable={clearable}
          />
        );
      default:
        return (
          <TextField
            {...inputProps}
            value={(value ?? "") as string}
            onChange={handleValueChange}
          />
        );
    }
  }
});

export const CustomField = memo<GridCellParams>(({ value, colDef }) => {
  const { generalType } = colDef as any;
  // TODO: Add ability to render component from the props
  // same as editComponent for the column input
  if (generalType.isArray) {
    return <ArrayField values={value as string[]} />;
  } else {
    switch (generalType.type) {
      case "json":
        return (
          <JsonView value={value as Record<string, unknown>} embedded={true} />
        );
      case "dateTime":
        return value ? (
          <>
            <FormattedDate value={value as Date} />{" "}
            <FormattedTime value={value as Date} />
          </>
        ) : null;
      case "date":
        return value ? <FormattedDate value={value as Date} /> : null;

      default:
        return <Typography>{value}</Typography>;
    }
  }
});
