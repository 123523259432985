import { createUseTranslation } from "core/session/translation";

export const numberFieldEditorTranslation = {
  en: {
    valueLabel: "Value",
    precisionLabel: "Precision",
    isPercentageLabel: "Is Percentage",
    prefixLabel: "Prefix",
    suffixLabel: "Suffix",
    highlightLabel: "Highlight",
    negativeValueLabel: "Negative Values",
    zeroValueLabel: "Zero",
    nullPrecisionLabel: "Precision without restriction",
  },
};

export const useNumberFieldEditorTranslation = createUseTranslation(
  numberFieldEditorTranslation,
);
