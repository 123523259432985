import FileSaver from "file-saver";

export const openSaveFileDialog = (
  data: string | Blob,
  filename?: string,
  mimetype?: string,
) => {
  if (!data) {
    return;
  }

  const blob =
    data.constructor !== Blob
      ? new Blob([data], { type: mimetype ?? "application/octet-stream" })
      : data;

  FileSaver.saveAs(blob, filename);
};
