import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Autocomplete Input",
      description: "",
    },
  },
  defaultSize: {
    width: 1,
    height: 1,
  },
};
