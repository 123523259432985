import { createUseTranslation } from "core/session/translation";

export const useExternalLinkFieldEditorTranslation = createUseTranslation({
  en: {
    disabledInputLabel: "Disabled",
    linkConfigTitle: "Configuration",
    linkLabel: "Destination",
    linkToTitle: "Link To",
    linkPlaceholder: "https://www.cybertec-postgresql.com",
    displayConfigTitle: "Display",
    isButtonLabel: "Display as button",
    linkHelperText:
      "You have to prefix the URL with a protocol - e.g. http:// or https://",
    examplesTitle: "Examples of usage",
    googleLabel: "Search in Google",
    copiedTooltip: "Copied",
    copyTooltip: "Click to copy the code below",
  },
});
