import { handleActions } from "core/utils/redux";

import { ActionTypes, IState, Types } from "./types";

const INITIAL_STATE: IState = {
  options: null,
  loadingOptions: false,
  optionsError: null,
  value: null,
  searchInputValue: null,
  errors: null,
  valueObject: [],
  rawOptions: null,
  touched: false,
  moreDataAvailable: false,
};

export function buildReducer(types: Types) {
  return handleActions<IState, ActionTypes>(INITIAL_STATE, {
    [types.LOAD_OPTIONS]: (state) => ({
      ...state,
      options: null,
      loadingOptions: true,
      optionsError: null,
    }),
    [types.LOAD_OPTIONS_SUCCESS]: (
      state,
      action: ActionTypes["loadOptionsSuccess"],
    ) => ({
      ...state,
      loadingOptions: false,
      options: action.payload.options,
      optionsError: null,
      rawOptions: action.payload.rawOptions,
      moreDataAvailable: action.payload.moreDataAvailable,
    }),
    [types.LOAD_OPTIONS_ERROR]: (
      state,
      action: ActionTypes["loadOptionsError"],
    ) => ({
      ...state,
      loadingOptions: false,
      optionsError:
        action.payload.error.message ?? action.payload.error.toString(),
    }),
    [types.SEARCH_INPUT_VALUE_CHANGE]: (
      state,
      action: ActionTypes["changeSearchInputValue"],
    ) => ({
      ...state,
      searchInputValue: action.payload.searchInputValue ?? null,
    }),
    [types.SET_VALUE_OBJECT]: (
      state,
      action: ActionTypes["setValueObject"],
    ) => ({
      ...state,
      valueObject: action.payload.valueObject,
    }),
  });
}
