import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  headerPaper: {
    padding: 8,
    marginBottom: theme.spacing(),
  },
  formPaper: {
    padding: 16,
  },
  footer: {
    textAlign: "center",
  },
  dividerFooter: {
    marginBottom: 16,
    marginTop: 8,
  },
}));

export default useStyles;
