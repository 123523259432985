import { ComponentType, memo } from "react";
import { MapDispatchToProps, MapStateToProps, connect } from "react-redux";
import { ActionCreatorsMapObject, Dispatch, bindActionCreators } from "redux";

import { REDUX_ERROR } from "core/constants";
import { DEBUG_MODE_ENABLED } from "core/debug";
import {
  BoundActions,
  IElement,
  IElementComponentProps,
  IElementModel,
  IReduxModule,
  SelectorValues,
  SelectorsMap,
} from "../types";

import { ElementWithError } from "./element";
import { Coalesce } from "./types";

type InferExtraStateProps<T> =
  T extends MapStateToProps<infer R, any, any> ? R : {};
type InferExtraActionProps<T> =
  T extends MapDispatchToProps<infer R, any> ? R : {};

type WithError<P> = P & { elementModel?: IElementModel } & {
  [REDUX_ERROR]?: Error;
};

/**
 * This utility function automatically maps the actions and selectors of your
 * ReduxModule.
 *
 * Additionally it accepts extra MapStateToProps and MapDispatchToProps
 *
 *
 * @example
 * const mapDispatchToProps = {
 *   goBack: routerActions.goBack,
 * };
 *
 * const connector = connectElement<
 *   ReduxModule,
 *   Form,
 *   undefined,
 *   typeof mapDispatchToProps
 * >(undefined, mapDispatchToProps);
 *
 * export type Props = PropsFromConnector<typeof connector>;
 *
 * export default connector(Component);
 */
export function connectElement<
  M extends IReduxModule = IReduxModule,
  E extends IElement = IElement,
  ExtraMapStateToPropsValue extends
    | MapStateToProps<any, IElementComponentProps<M, E>, any>
    | undefined = undefined,
  ExtraMapDispatchToPropsValue extends
    | MapDispatchToProps<any, IElementComponentProps<M, E>>
    | undefined = undefined,
>(
  extraMapStateToProps?: ExtraMapStateToPropsValue | null,
  extraMapDispatchToProps?: ExtraMapDispatchToPropsValue | null,
) {
  type OwnProps = IElementComponentProps<M, E>;
  type ExtraStateProps = InferExtraStateProps<
    Coalesce<ExtraMapStateToPropsValue, () => {}>
  >;
  type StateProps = SelectorValues<
    M["selectors"] extends SelectorsMap ? M["selectors"] : {}
  > &
    ExtraStateProps;

  type ExtraActionProps = InferExtraActionProps<
    Coalesce<ExtraMapDispatchToPropsValue, () => {}>
  >;

  type ActionProps = BoundActions<
    M["actions"] extends ActionCreatorsMapObject ? M["actions"] : {}
  > &
    ExtraActionProps;

  const mapStateToProps = (state: unknown, ownProps: OwnProps): StateProps => {
    const {
      module: { selectors = {} },
    } = ownProps;
    const baseStateProps = Object.keys(selectors).reduce(
      (props, key) => ({ ...props, [key]: selectors[key](state) }),
      {} as StateProps,
    );
    const extraStateProps = extraMapStateToProps
      ? extraMapStateToProps(state, ownProps)
      : {};
    return { ...baseStateProps, ...extraStateProps } as StateProps;
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) =>
    ({
      ...bindActionCreators(ownProps.module.actions || {}, dispatch),
      ...(typeof extraMapDispatchToProps === "function"
        ? extraMapDispatchToProps(dispatch, ownProps)
        : bindActionCreators(extraMapDispatchToProps || {}, dispatch)),
    }) as ActionProps;

  const connector = connect(
    connectErrorHandlerUtils.enhanceMapStateToProps(mapStateToProps),
    mapDispatchToProps,
  );

  const callback: typeof connector = (Component) =>
    connector(
      connectErrorHandlerUtils.enhanceComponent(
        Component,
      ) as unknown as typeof Component,
    );

  return callback;
}

export function buildMapStateToProps<S extends SelectorsMap>(selectors: S) {
  return (state: unknown): SelectorValues<S> =>
    Object.keys(selectors).reduce(
      (props, key) => ({ ...props, [key]: selectors[key](state) }),
      {} as SelectorValues<S>,
    );
}

/**
 * TODO:
 * This two functions could be merged into one function that wraps react-redux connect(...), and subsecuentially
 * wraps mapStateToProps and the connected Component. The problem is the typing might get tricky for handling
 * ALL the overloads for connect(...).
 */

export const connectErrorHandlerUtils = {
  /**
   * Enhancer for handling errors in mapStateToProps. The connected component should use the enhanceComponent HOC.
   */
  enhanceMapStateToProps<P, Args extends any[]>(
    mapStateToProps: (...a: Args) => P,
  ) {
    return function enhancedMapStateToProps(...args: Args) {
      try {
        return mapStateToProps(...args);
      } catch (error) {
        if (DEBUG_MODE_ENABLED) {
          // eslint-disable-next-line no-console
          console.debug("Error in mapStateToProps:", JSON.stringify(error));
        }
        return { [REDUX_ERROR]: error } as unknown as P;
      }
    };
  },

  /**
   * Component enhancer to handle errors caught in connect with `enhanceMapStateToProps`.
   */
  enhanceComponent<P extends object>(Component: ComponentType<P>) {
    return memo<WithError<P>>((props) => {
      const error = props[REDUX_ERROR];
      return error ? (
        // TODO: handle error, but to display component
        <ElementWithError error={error} elementModel={props.elementModel} />
      ) : (
        <Component {...(props as P)} />
      );
    });
  },
};

// TODO: why doesn't this work in typescript?
// export function connectReduxModule<M extends IReduxModule>(reduxModule: M) {
//   const { selectors = {}, actions = {} } = reduxModule;
//
//   type SelectorsProps = ConnectedStaticReduxModuleSelectorsProps<M>;
//   type ActionsProps = ConnectedStaticReduxModuleActionsProps<M>;
//   type Props = SelectorsProps & ActionsProps;
//
//   return <P extends Props>(Component: ComponentType<P>) => {
//     const mapStateToProps = (state: any) => Object.keys(reduxModule.selectors || {}).reduce(
//       (props, key) => ({ ...props, [key]: selectors[key](state) }),
//       {},
//     ) as SelectorsProps;
//
//     return connect<SelectorsProps, ActionsProps, Omit<P, keyof Props>, any>(
//       mapStateToProps,
//       actions as any,
//     )(Component);
//   }
// }
