import { memo, useCallback } from "react";
import { useElementEditorContext } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";

import { UntransformedInternalLinkFieldConfig } from "../types";

type Props = {
  name: keyof UntransformedInternalLinkFieldConfig;
};

export const ConfigField = memo<Props>(({ name }) => {
  const {
    elementModel: { id, config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedInternalLinkFieldConfig>();

  const value = config[name];

  const changeValue = useCallback(
    (newValue: string | number | boolean) =>
      changeConfigValue(name, String(newValue)),
    [changeConfigValue, name],
  );

  if (typeof value !== "string") {
    return null;
  }

  return (
    <>
      <TableColumnEditor
        id={id}
        value={value}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["text", "number", "date", "dateTime", "time"]}
        onChange={changeValue}
      />
      <CustomExpressionEditor
        value={value}
        config={config}
        onChange={changeValue}
        disableSwitcher
      />
    </>
  );
});
