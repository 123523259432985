import * as t from "io-ts";
import { selectors } from "core/editor/reduxModule";
import { getViewRowType } from "core/editor/types";
import { Type, buildReferencesType, types } from "core/runtime-typing";
import {
  ColorConfig,
  GeneralTypes,
  IElement,
  IElementModel,
  TypeFactory,
  UntransformedConfig,
  arrayChild,
  customExpression,
} from "core/types";
import { SelectorTypes } from "core/types/element";

import { MapDispatchToProps, MapStateToProps } from "./container";
import { orderType, tableParamsType } from "./reduxModule";
import {
  FixedFilterGroup,
  FixedFilterRule,
  filterGroupType,
} from "./toolsPanel";

export const CellAlignment = t.keyof({
  left: true,
  center: true,
  right: true,
});

export type CellAlignment = t.TypeOf<typeof CellAlignment>;

export const Reference = t.type({
  viewName: t.string,
  identifierName: t.string,
});

export type Reference = t.TypeOf<typeof Reference>;

const CreateButton = t.intersection([
  t.type({
    enabled: t.union([t.boolean, customExpression(t.boolean)]),
    linkTo: t.type({
      pageId: t.string,
      params: t.record(t.string, customExpression(t.unknown)),
    }),
  }),
  t.partial({
    color: ColorConfig,
    size: t.keyof({
      small: null,
      medium: null,
      large: null,
    }),
    icon: t.string,
    disabled: customExpression(t.boolean),
    variant: t.keyof({
      outlined: null,
      contained: null,
    }),
    isIcon: t.boolean,
  }),
]);

export const TableConfig = t.intersection([
  t.type({
    dataSource: t.intersection([
      t.type({
        viewName: t.string,
      }),
      t.partial({
        identifierName: t.string,
        stateColumnName: t.string,
        references: t.record(t.string, Reference),
      }),
    ]),
    filter: t.type({
      fields: t.array(
        t.type({
          name: t.string,
          label: t.string,
          operators: t.array(t.string),
          input: t.type({
            type: GeneralTypes,
          }),
        }),
      ),
    }),
  }),
  t.partial({
    fixedFilter: t.union([
      customExpression(t.union([FixedFilterGroup, FixedFilterRule, t.null])),
      FixedFilterGroup,
    ]),
    elementsFilter: t.array(FixedFilterRule),
    defaultRowsPerPage: t.number,
    rowsPerPage: t.array(t.number),
    defaultSort: t.array(
      t.type({
        fieldName: t.string,
        asc: t.boolean,
        hidden: t.boolean,
      }),
    ),
    simpleFilter: t.array(
      t.type({
        name: t.string,
        type: t.union([t.literal("number"), t.literal("text")]),
        isArray: t.boolean,
      }),
    ),
    hideSimpleFilter: t.boolean,
    fullTextSearch: t.boolean,
    interval: t.number,
    canSelectRow: t.boolean,
    firstRowSelected: t.boolean,
    hidden: t.array(t.union([customExpression(t.boolean), t.null])),
    createButton: CreateButton,
  }),
]);

const getReferencesType =
  ({ isArray = false, isNullable = false } = {}): TypeFactory<TableConfig> =>
  ({ config, state }) => {
    let type: Type;
    const { references = {} } = config.dataSource;
    const viewList = selectors.viewList(state);
    if (!viewList) {
      // view list still loading, do not show any typing errors
      type = types.any();
    } else if (!Object.keys(references).length) {
      type = types.optional(types.null(), "No references in the table");
    } else {
      type = types.nullable(
        buildReferencesType(
          references!,
          viewList,
          isNullable,
          isArray,
          "The table's reference list",
        ),
        "The table's reference list",
      );
    }

    return type;
  };

export const TableChildren = t.type({
  header: arrayChild("default_table_header_cell"),
  body: arrayChild("*", {
    propTypes: {
      data: getViewRowType(),
      references: getReferencesType({ isNullable: true }),
      key: types.union(
        [types.string(), types.number()],
        "A unique identifier for the row",
      ),
      metadata: types.interface({
        canUpdate: types.boolean("Can the row be updated"),
        canDelete: types.boolean("Can the row be deleted"),
      }),
    },
  }),
});

export const TableMetadataStateChangeI18n = t.partial({
  shortDescription: t.string,
  title: t.string,
});

/**
 * TODO:
 * We can make TableMetadataStateChangeI18n be an io-ts type and have a io-ts-to-selector-types helper function, to avoid duplication
 */
const tableMetadataStateChangeI18nType = types.interface({
  shortDescription: types.optional(types.string()),
  title: types.optional(types.string()),
});

export const TableMetadataStateChange = t.type({
  to: t.union([t.string, t.null]),
  i18n: t.record(t.string, TableMetadataStateChangeI18n),
});

/**
 * TODO:
 * We can make TableMetadataStateChange be an io-ts type and have a io-ts-to-selector-types helper function, to avoid duplication
 */
const tableMetadataStateChangeType = types.interface({
  to: types.nullable(types.string()),
  i18n: types.record(types.string(), tableMetadataStateChangeI18nType),
});

export const TableMetadataRow = t.intersection([
  t.type({
    canUpdate: t.boolean,
    canDelete: t.boolean,
  }),
  t.partial({
    stateChanges: t.array(TableMetadataStateChange),
    stateName: t.string,
    currentStateI18n: t.record(t.string, TableMetadataStateChangeI18n),
  }),
]);

/**
 * TODO:
 * We can make TableMetadataRow be an io-ts type and have a io-ts-to-selector-types helper function, to avoid duplication
 */
const tableMetadataRowType = types.interface({
  canUpdate: types.boolean(),
  canDelete: types.boolean(),
  stateChanges: types.optional(types.array(tableMetadataStateChangeType)),
  stateName: types.optional(types.string()),
  currentStateI18n: types.record(
    types.string(),
    tableMetadataStateChangeI18nType,
  ),
});

export type TableMetadata = {
  rows: Record<string, TableMetadataRow>;
  canUpdate: boolean;
  canDelete: boolean;
};

/**
 * TODO:
 * We can make TableMetadata be an io-ts type and have a io-ts-to-selector-types helper function, to avoid duplication
 */
const tableMetadataType = types.nullable(
  types.interface({
    rows: types.record(types.string(), tableMetadataRowType),
    canUpdate: types.boolean(),
    canDelete: types.boolean(),
  }),
);

/**
 * TODO:
 * We can make ISelectedRow be an io-ts type and have a io-ts-to-selector-types helper function, to avoid duplication
 */
const selectedRowType: TypeFactory<TableConfig> = (params) => {
  if (params.config.canSelectRow === true) {
    // even if canSelectRow and firstRowSelected are true, the row can still be
    // null if no data is available
    return types.interface({
      identifier: types.nullable(
        types.union([types.string(), types.number()]),
        "the identifier of the selected row",
      ),
      row: getViewRowType({ isNullable: true })(params),
    });
  } else {
    const message = "row selection is disabled";
    return types.interface({
      identifier: types.null(message),
      row: types.null(message),
    });
  }
};

export const tableSelectors: SelectorTypes<TableConfig> = {
  loading: types.boolean(),
  data: getViewRowType({ isArray: true, isNullable: true }),
  references: getReferencesType({ isArray: true, isNullable: true }),
  params: tableParamsType,
  loadingParams: types.nullable(
    types.interface(
      Object.keys(tableParamsType.fields).reduce(
        (acc, k) => ({
          ...acc,
          [k]: types.optional(tableParamsType.fields[k]),
        }),
        {},
      ),
    ),
  ),
  offset: tableParamsType.fields.offset,
  limit: tableParamsType.fields.limit,
  order: tableParamsType.fields.order,
  orderIndexed: types.record(
    types.string(),
    types.interface({ ...orderType.fields, pos: types.number() }),
  ),
  error: types.nullable(types.string()),
  nextFilter: filterGroupType,
  nextPageAvailable: types.boolean(),
  searchInputValue: types.string(),
  metadata: tableMetadataType,
  selected: selectedRowType,
};

type TCreateButton = t.TypeOf<typeof CreateButton>;

export type CreateButtonProps = Omit<
  TCreateButton,
  "color" | "disabled" | "enabled"
> &
  MapStateToProps["createButtonState"] & {
    label: string;
    navigate: MapDispatchToProps["navigate"];
  };

export type TableConfig = t.TypeOf<typeof TableConfig>;

export type UntransformedTableConfig = UntransformedConfig<TableConfig>;

export type TableChildren = t.TypeOf<typeof TableChildren>;

export type TableMetadataRow = t.TypeOf<typeof TableMetadataRow>;

export type TableMetadataStateChange = t.TypeOf<
  typeof TableMetadataStateChange
>;

export type Table = IElement<TableConfig, TableChildren, TTableTranslationKeys>;

export type TableModel = IElementModel<UntransformedTableConfig, TableChildren>;

export const TableTranslationKeys = ["createButtonLabel"] as const;

export type TTableTranslationKeys = (typeof TableTranslationKeys)[number];
