import {
  ComponentClass,
  ComponentType,
  FunctionComponent,
  createElement,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import CircularProgress from "@mui/material/CircularProgress";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { icons } from "core/editor/common/IconAutocomplete/icons";
import { useIsMounted } from "utils/hooks";
import { toUpperCamelCase } from "utils/string";

import iconMap from "./iconMap";

type Icon = (typeof icons)[number];
type TIconMap = keyof typeof iconMap;

export type IconNameType = keyof typeof iconMap | Icon;

export type IMuiIconProps = {
  icon: IconNameType;
  className?: string;
  color?: SvgIconProps["color"];
} & Partial<SvgIconProps>;

export const MuiIcon = memo<IMuiIconProps>(({ icon, ...rest }) => {
  const [iconComponent, setComponent] = useState<
    FunctionComponent | ComponentClass | null
  >(null);
  const isMounted = useIsMounted();

  const getIcon = useCallback(async () => {
    try {
      const allIcons = await import("@mui/icons-material");

      if (isMounted()) {
        const _icon = (
          Object.keys(iconMap).includes(icon)
            ? iconMap[icon as TIconMap]
            : toUpperCamelCase(icon)
        ) as keyof typeof allIcons;

        setComponent((allIcons[_icon] || ErrorIcon) as ComponentType);
      }
    } catch (error) {
      if (isMounted()) {
        setComponent(ErrorIcon);
      }
    }
  }, [icon, isMounted]);

  useEffect(() => {
    getIcon();
  }, [getIcon]);

  if (iconComponent) {
    iconComponent.displayName = "IconComponent";
    return createElement(iconComponent, {
      fontSize: rest.fontSize ?? "inherit",
      ...rest,
    });
  }

  return <CircularProgress size={24} className={rest.className} />;
});

MuiIcon.displayName = "MuiIcon";
