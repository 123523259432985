import { MouseEvent, memo, useCallback, useMemo, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import {
  GridCellParams,
  GridColDef,
  GridToolbarContainer,
} from "@mui/x-data-grid";

import { GeneralType } from "core";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import { DataGridTable, useStyles } from "elementTypes/common/DataGridTable";
import { getCustomColumnProps } from "elementTypes/common/DataGridTable/utils";
import IconButton from "elementTypes/common/IconButton";
import { useLDAPContext } from "../context";
import { useCommonStaticPagesTranslation } from "../translation";
import { objectHasValue } from "../utils";

const ACTIONS_NAME = "@actions";
const CELLS = ["ldapRole", "postgresRole"];

export const Toolbar = memo<{
  title: string;
  toolTipTitle: string;
  onRowAdd: () => void;
}>(({ title, toolTipTitle, onRowAdd }) => (
  <GridToolbarContainer>
    <Box flex="1" pl={1}>
      <Typography variant="h6">{title}</Typography>
    </Box>
    <IconButton
      icon="add"
      onClick={onRowAdd}
      tooltip={toolTipTitle}
      data-test-id="addRowTest"
    />
  </GridToolbarContainer>
));

export const RoleMappingTable = memo(() => {
  const translation = useCommonStaticPagesTranslation();
  const { classes, cx } = useStyles();

  const {
    roleMappings: data,
    roleOptions: options,
    errors: validationErrors,
    onRowAdd,
    onRowDelete,
    onCommitChange,
  } = useLDAPContext();

  const errors = objectHasValue(validationErrors?.roleMappings ?? {})
    ? validationErrors?.roleMappings
    : null;

  // create actions column
  const renderActionsCell = (params: GridCellParams) => (
    <IconButton
      icon="delete_outline"
      tooltip={translation.deleteTooltip}
      onClick={onRowDelete(params.id)}
      data-row-delete={params.id}
    />
  );

  const actionColumn: GridColDef = {
    field: ACTIONS_NAME,
    headerName: translation.actionColumnTitle,
    width: 100,
    sortable: false,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center",
    renderCell: renderActionsCell,
  };
  const generalType = {
    isArray: false,
    type: "text",
  } as GeneralType;

  const columns = [
    ...CELLS.map((cell) => {
      const customProps = getCustomColumnProps({
        generalType,
        ...(cell === "postgresRole" && {
          editComponent: Autocomplete,
          editProps: {
            disablePortal: false,
            options,
          },
        }),
      });

      return {
        field: cell,
        headerName: translation[`${cell}ColumnTitle`],
        flex: 1,
        editable: true,
        nullable: false,
        ...customProps,
        ...(cell === "ldapRole" && {
          cellClassName: (params: GridCellParams) => {
            const hasError = errors?.[params.id] ?? {};

            return cx({
              [classes.errorTextField]: !!Object.values(hasError).length,
            });
          },
        }),
        disableColumnMenu: true,
      };
    }),
    actionColumn,
  ];

  const GridToolbar = useCallback(
    () => (
      <Toolbar
        title={translation.tableTitle}
        toolTipTitle={translation.addTooltip}
        onRowAdd={onRowAdd}
      />
    ),
    [onRowAdd, translation.tableTitle, translation.addTooltip],
  );

  const rows = useMemo(
    () => data.map((row, i: number) => ({ id: i, ...row })),
    [data],
  );

  const [cellError, setCellError] = useState<{
    anchorEl: HTMLElement;
    msg: string;
  } | null>(null);

  const handleCellEnter = useCallback(
    (ev: MouseEvent<HTMLElement>) => {
      const field = ev.currentTarget.dataset.field!;
      const id = ev.currentTarget.parentElement!.dataset.id!;
      const errorMsg = errors?.[id]?.[field];

      if (errorMsg) {
        setCellError({ anchorEl: ev.currentTarget, msg: errorMsg });
      } else {
        setCellError(null);
      }
    },
    [errors],
  );

  const handleCellLeave = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const field = event.currentTarget.dataset.field!;
      const id = event.currentTarget.parentElement!.dataset.id!;
      const errorMsg = errors?.[id]?.[field];
      if (cellError && errorMsg) {
        setCellError(null);
      }
    },
    [cellError, errors],
  );

  return (
    <>
      <DataGridTable
        columns={columns}
        rows={rows}
        onCellEditCommit={onCommitChange}
        components={{
          Toolbar: GridToolbar,
        }}
        hideFooterSelectedRowCount={true}
        componentsProps={{
          cell: {
            onMouseEnter: handleCellEnter,
            onMouseLeave: handleCellLeave,
          },
        }}
      />
      <Tooltip
        title={cellError?.msg}
        open={!!cellError?.anchorEl}
        PopperProps={{
          anchorEl: cellError?.anchorEl,
          sx: {
            position: "absolute",
            display: "flex",
          },
          disablePortal: true,
        }}
        placement="top"
      >
        <span />
      </Tooltip>
    </>
  );
});
