import { memo } from "react";
import { ConnectedReduxModuleProps } from "core";
import { AlertBox } from "elementTypes/common/AlertBox";
import { LoadingComponent } from "layouts/common/Loading";

import { IOptionEchartProps } from "../common/Echarts";
import { IEchartsContainerMultiProps } from "../common/Echarts";
import EchartsContainerDataset from "../common/Echarts/EchartsContainerDataset";
import { useEchartsTranslation } from "../common/Echarts/translation.ts";

import { ReduxModule } from "./reduxModule";
import { LineChart } from "./types";

type Props = ConnectedReduxModuleProps<ReduxModule, LineChart>;

const EchartsLineChart = memo<Props>(
  ({
    element: {
      id,
      config: {
        dataSource,
        hideLabel,
        hideXAxis,
        hideYAxis,
        isArea,
        legend,
        ...rest
      },
      i18n: { title, ...translation },
    },
    error,
    data,
    selected,
    selectValue,
  }) => {
    const propsEchartsContainer = {
      title,
      type: "line",
      defaultOptions: {
        title: {
          x: "center",
        },
        xAxis: { show: !hideXAxis },
        yAxis: { show: !hideYAxis },
        series: [
          {
            avoidLabelOverlap: true,
            label: {
              show: !hideLabel,
            },
            ...(isArea && { areaStyle: {} }),
          },
        ],
      } as IOptionEchartProps,
      ...rest,
    } as IEchartsContainerMultiProps;

    const { configErrorTitle, errorTitle, queryNameError } =
      useEchartsTranslation();

    if (!dataSource.viewName) {
      return <AlertBox title={configErrorTitle} message={queryNameError} />;
    }

    if (error) {
      return <AlertBox title={errorTitle} message={error} />;
    }

    if (!data) {
      return <LoadingComponent />;
    }

    const chartProps = {
      elementId: id,
      dataSource,
      data,
      legend,
      selected,
      i18n: translation,
      selectValue,
    };

    return (
      <EchartsContainerDataset {...propsEchartsContainer} {...chartProps} />
    );
  },
);

export default EchartsLineChart;
