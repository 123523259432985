import { Type } from "./Type";
import { TypeError } from "./TypeError";

export class AnyType extends Type {
  public name = "any";

  public getKeyType(key: string): Type | undefined {
    return super.getKeyType(key) || new AnyType();
  }

  public validate(_value: any): true | TypeError {
    return true;
  }
}
