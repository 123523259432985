export enum ActionEnum {
  SET_SELECTED_NODE = "setSelectedNode",
  ON_FILTER_CHANGE = "onFilterChange",
  SET_ENTITIES_SEARCH_VALUE = "setEntitiesSearchValue",
  SET_QUERIES_SEARCH_VALUE = "setQueriesSearchValue",
  SET_TABLE_VIEW_FOCUS = "setTableViewFocus",
  SET_TABLE_VIEW_DIRECTION = "setTableViewDirection",
  SET_EXPANDED_TABLE_LIST = "setExpandedTableList",
  SET_SELECTED_QUERY_ENTITY = "setSelectedQueryEntity",
  SET_WORKFLOW_VIEW = "setWorkflowView",
  SET_EXPANDED_QUERY_GROUP_LIST = "setExpandedQueryGroupList",
}
