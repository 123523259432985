import { createUseTranslation } from "core/session/translation";

const msg = `
When creating a new user role with admin access, it’s important to understand that this role will have complete control over the system.

Admin roles inherit permissions from the internal **cypex_admin** role, meaning they can:

- Manage critical system configurations.

- Access all sensitive client and application data.

- Modify and delete essential database structures.
`;

export const rolePagesTranslation = {
  en: {
    pageTitle: "Roles",
    createBtnLabel: "Create",
    createPageTitle: "Create Role",
    inputNameLabel: "Role Name",
    successMsg: "Role * has ben create",
    deletedMessage: "Role was deleted successfully",
    DeleteButton: "Delete",
    CancelButton: "Cancel",
    dialogTitle: "Delete Role",
    tableNameLabel: "Role Name",
    tableActionLabel: "Actions",
    dialogContentText: "Are you sure you want to delete the role",
    deleteTooltip: "Delete",
    isAdmin: "Admin Access",
    titleAlert: "Understanding Admin Access:",
    contentAlert: msg,
  },
};

export const useRolePagesTranslation =
  createUseTranslation(rolePagesTranslation);
