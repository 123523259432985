import { handleActions } from "core/utils/redux";
import { IState, Types } from "./types";

const INITIAL_STATE: IState = {
  modalOpen: false,
};

export function buildReducer(types: Types) {
  return handleActions<IState>(INITIAL_STATE, {
    [types.TOGGLE_MODAL]: (state) => {
      return {
        ...state,
        modalOpen: !state.modalOpen,
      };
    },
  });
}
