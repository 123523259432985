import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";
import { AsideState } from "./Panel";

export const LEFT_ASIDE = "250px";
export const RIGHT_ASIDE = "300px";

const ASIDE_TRANSFORM = "all 0.2s linear";

export const useGridStyles = makeStyles<AsideState>()(
  (theme: Theme, { left, right }) => ({
    root: {
      width: "100%",
      display: "flex",
      // total height - top bar - footer - 2x padding
      height: `calc(100vh - ${theme.spacing(6)} - ${theme.spacing(5)})`,
      flexDirection: "column",
    },
    topBlock: {
      height: theme.spacing(6),
      width: "100%",
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    asideLeft: left
      ? {
          gridArea: "left",
          display: "flex",
          flexDirection: "column",
          width: LEFT_ASIDE,
          marginLeft: 0,
          transition: ASIDE_TRANSFORM,
          borderRight: `1px solid ${theme.palette.divider}`,
          willChange: "width opacity",
          opacity: 1,
        }
      : { width: 0, opacity: 0 },
    asideRight: right
      ? {
          gridArea: "right",
          display: "flex",
          flexDirection: "column",
          width: RIGHT_ASIDE,
          marginRight: 0,
          transition: ASIDE_TRANSFORM,
          borderLeft: `1px solid ${theme.palette.divider}`,
          willChange: "width opacity",
          // allow user to interact while default_modal_dialog is opened
          zIndex: theme.zIndex.modal,
          opacity: 1,
        }
      : { width: 0, opacity: 0 },
  }),
);

export const useStyles = makeStyles()((theme: Theme) => ({
  contentSection: {
    gridArea: "content",
    overflow: "auto",
    scrollbarWidth: "thin",
    flexGrow: 1,
    "&::-webkit-scrollbar": {
      width: 6,
      background: "#f0f0f0",
      opacity: 0,
    },
    background: theme.palette.background.default,
  },
  overflowContent: {
    height: "100%",
  },
  paddingBox: {
    padding: theme.spacing(),
  },
  tabRoot: {
    // without !important, this class is overwritten by the default MuiTab class
    minWidth: "unset !important",
  },
  panelContent: {
    scrollbarWidth: "thin",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 6,
      background: "#f0f0f0",
      opacity: 0,
    },
    "&:hover::-webkit-scrollbar": {
      opacity: 1,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#cdcdcd",
    },
  },
  topButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  pageField: {
    padding: `0 ${theme.spacing()} ${theme.spacing(0.5)}`,
  },
  flexBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  positionsControl: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    gridGap: theme.spacing(2),
    alignItems: "center",
  },
  marginBlock: {
    margin: `${theme.spacing(0.5)} 0 ${theme.spacing()}`,
  },
  inputClass: {
    "& input::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "& input": {
      MozAppearance: "textfield",
      appearance: "none",
    },
  },
  infoTitle: {
    paddingBottom: `${theme.spacing(1)} !important`,
  },
  infoCard: {
    borderLeft: "unset",
    borderRight: "unset",
  },
  cursor: {
    cursor: "pointer",
  },
  infoTexts: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  flexColumn: {
    minHeight: "100%",
    "& > [class*='MuiCard-root']:last-child": {
      flex: 1,
    },
  },
  rotateIcon: {
    "& [class*='MuiSvgIcon-root']": {
      transform: "rotate(180deg) translateY(-1px)",
    },
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    minWidth: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const useViewPanelStyles = makeStyles()(() => ({
  dialogPaper: { width: "600px", height: "600px" },
  listItemText: {
    overflow: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  text: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  queryTypeText: {
    textTransform: "capitalize",
  },
}));

export default useStyles;
