import { IReduxModuleFactoryArgs } from "core";

import { FileInput } from "../types";

import { controlledReduxModuleFactory } from "./controlled";
import { uncontrolledReduxModuleFactory } from "./uncontrolled";

export function reduxModuleFactory({
  path,
  element,
}: IReduxModuleFactoryArgs<FileInput>) {
  return element.config.dataSource
    ? controlledReduxModuleFactory({
        path,
        element,
      } as IReduxModuleFactoryArgs<FileInput>)
    : uncontrolledReduxModuleFactory({
        path,
        element,
      } as IReduxModuleFactoryArgs<FileInput>);
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
