import { ChangeEvent, ComponentProps, memo, useCallback } from "react";
import { Box, RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Switch from "@mui/material/Switch";
import { isBoolean } from "lodash";
import { buildCustomExpressionValue, getBooleanExpressionValue } from "core";
import {
  ColorSelector,
  IconAutocomplete,
  Section,
  useElementEditorContext,
} from "core/editor";

import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import { IconNameType } from "elementTypes/common/MuiIcon";
import { UntransformedExternalLinkFieldConfig } from "../../types";
import { useExternalLinkFieldEditorTranslation } from "../translation";

enum Variants {
  contained = "contained",
  outlined = "outlined",
}

export const Display = memo(() => {
  const { displayConfigTitle, isButtonLabel, disabledInputLabel } =
    useExternalLinkFieldEditorTranslation();

  const {
    elementModel: {
      config: {
        isButton = false,
        icon,
        variant = "outlined",
        disabled = false,
      },
      config,
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedExternalLinkFieldConfig>();

  const toggleIsButton = () => changeConfigValue("isButton", !isButton);

  const changeIcon = (newIcon: UntransformedExternalLinkFieldConfig["icon"]) =>
    changeConfigValue("icon", newIcon);

  const handleColorChange = (newColor: string) =>
    changeConfigValue("color", newColor);

  const handleVariantChange = (_e: ChangeEvent, value: string) => {
    changeConfigValue("variant", value);
  };

  const handleDisabledChange = (value: unknown) =>
    changeConfigValue("disabled", value);

  const handleToggleModeDisabled = (isExpression: boolean) => {
    const nextVal = isExpression
      ? getBooleanExpressionValue(String(disabled))
      : buildCustomExpressionValue(String(disabled));
    handleDisabledChange(nextVal);
  };

  const disabledNonExpressionEditor: ComponentProps<
    typeof CustomExpressionEditor
  >["nonExpressionEditor"] = useCallback(
    ({ value, onChange }: NonExpressionEditorProps<boolean>) => {
      const handleValueChange = (
        _event: ChangeEvent<HTMLInputElement>,
        checked: boolean,
      ) => onChange(checked);

      return (
        <FormControlLabel
          control={
            <Switch
              checked={isBoolean(value) ? value : value === "true"}
              onChange={handleValueChange}
            />
          }
          label={disabledInputLabel}
        />
      );
    },
    [disabledInputLabel],
  );

  const variants = Object.keys(Variants).map((itemVariant) => (
    <FormControlLabel
      key={itemVariant}
      control={<Radio color="primary" />}
      label={itemVariant}
      value={itemVariant}
    />
  ));

  return (
    <Section title={displayConfigTitle} wrapped={true}>
      <ColorSelector config={config} onChange={handleColorChange} />
      <FormControlLabel
        control={<Switch checked={isButton} onChange={toggleIsButton} />}
        label={isButtonLabel}
      />

      {isButton && (
        <Box mt={1}>
          <IconAutocomplete
            label="Icon"
            value={icon as IconNameType}
            onChange={changeIcon}
          />

          <RadioGroup row={true} value={variant} onChange={handleVariantChange}>
            {variants}
          </RadioGroup>
        </Box>
      )}
      <CustomExpressionEditor
        value={String(disabled)}
        config={config}
        nonExpressionEditor={disabledNonExpressionEditor}
        onToggleMode={handleToggleModeDisabled}
        onChange={handleDisabledChange}
        label={disabledInputLabel}
        forceFlexChange
        isBoolean
      />
    </Section>
  );
});
