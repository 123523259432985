import { KeyboardEvent, memo } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import {
  GridCellParams,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";

import { JsonView } from "elementTypes/common/JsonView";
import { IExtendedColDef } from "./types";

export const CustomEditCell = memo<GridRenderEditCellParams>(
  ({ colDef: columnDefinition, ...rest }) => {
    const { id, field, error, value } = rest;

    const colDef = columnDefinition as unknown as IExtendedColDef;
    const clearable = !colDef.nullable;

    const apiRef = useGridApiContext();

    const onChange = (newValue: GridCellParams["value"]) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
      if (
        !String.fromCharCode(e.which ?? e.keyCode).match(/^[0-9]+$/) &&
        e.key !== "Enter"
      ) {
        e.preventDefault();
      }
    };

    const handleArrayChange = (_: any, newData: string[] | number[] | null) => {
      const nextArray =
        newData && colDef.type === "array-number"
          ? (newData ?? []).map((val) => Number(val))
          : newData;

      onChange(nextArray);
    };

    if (colDef.isArray) {
      const type = colDef.type?.replace("array-", "");
      const defaultValue = !value?.length ? [] : value;
      return (
        <Autocomplete
          disableClearable={!clearable}
          onChange={handleArrayChange}
          value={defaultValue}
          options={[]}
          multiple
          freeSolo
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              fullWidth
              error={Boolean(error)}
              name={field}
              {...(type === "number" && {
                onKeyPress,
              })}
            />
          )}
        />
      );
    } else {
      const jsonComponent = (
        <JsonView
          value={value}
          onValueChange={onChange}
          nullable={clearable}
          error={error}
          embedded
          popup
        />
      );
      return error ? (
        <Box
          width="100%"
          height="100%"
          border="1px solid"
          borderColor="error.main"
        >
          {jsonComponent}
        </Box>
      ) : (
        jsonComponent
      );
    }
  },
);
