import { JSONSchema6 } from "json-schema";
import { path } from "ramda";
import { createSelector } from "reselect";
import { FieldPath, FormDataSource } from "elementInterfaces";

import { Form } from "../types";

import { Actions, Selectors, Types } from "./types";

export function buildDataSourceInterface(
  actions: Actions,
  types: Types,
  selectors: Selectors,
  element: Form,
) {
  const {
    config: {
      type,
      dataSource: { multiReference },
      jsonSchema,
    },
  } = element;

  const createFieldValueSelector = (
    fieldPath: FieldPath,
    { defaultValue = null } = {},
  ) =>
    createSelector(
      [selectors.data],
      (data) => path(fieldPath, data) ?? defaultValue,
    );

  const createFieldOriginalValueSelector = (
    fieldPath: FieldPath,
    { defaultValue = null } = {},
  ) =>
    createSelector(
      [selectors.originalData],
      (data) => path(fieldPath, data) ?? defaultValue,
    );

  const createFieldErrorSelector = (fieldPath: FieldPath) =>
    createSelector(
      [selectors.errors, selectors.touched, selectors.saveAttemptsSinceReset],
      (errors, touched, saveAttempts) => {
        let error = path(fieldPath, errors);

        if (!error) {
          for (const key in errors) {
            // TODO refactor this to cover the case that is described here -> https://github.com/cybertec-postgresql/cypex-gui/pull/1017#discussion_r525948881
            if (
              key
                .split(",")
                .map((k) => k.trim())
                .includes(String(fieldPath[0]))
            ) {
              error = errors[key];
            }
          }
        }

        const fieldTouched = path(fieldPath, touched);

        if (!fieldTouched && saveAttempts === 0) {
          return undefined;
        }

        return error;
      },
    );

  const createFieldTouchedSelector = (fieldPath: FieldPath) =>
    createSelector(
      [selectors.touched],
      (touched) => path(fieldPath, touched) ?? false,
    );

  const requiredFields = (jsonSchema as JSONSchema6)?.required ?? [];

  const createFieldRequiredSelector = (fieldPath: FieldPath) => {
    // since we don't have nesting fields validation so far,
    // take the first field in the path
    const fieldName = fieldPath[0].toString();
    return requiredFields.includes(fieldName);
  };

  return FormDataSource.implement({
    createFieldValueSelector,
    createFieldOriginalValueSelector,
    createFieldErrorSelector,
    createFieldTouchedSelector,
    createFieldRequiredSelector,
    changeFieldValue: actions.changeFieldValue,
    changeFieldTouched: actions.changeFieldTouched,
    addSubForm: actions.addSubForm,
    removeSubForm: actions.removeSubForm,
    save: actions.save,
    reload: actions.load,
    reset: actions.reset,
    hasChanges: selectors.hasChanges,
    isValid: selectors.isValid,
    types: {
      DATA_READY: types.LOAD_SUCCESS,
    },
    isReadOnly: type === "detail",
    references: multiReference ?? {},
  });
}
