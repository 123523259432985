import { IReduxModuleFactoryArgs } from "core/types";

import { FormButton } from "../types";

import { resetReduxModuleFactory } from "./reset";
import { submitReduxModuleFactory } from "./submit";

export function reduxModuleFactory({
  element,
}: IReduxModuleFactoryArgs<FormButton>) {
  const { type } = element.config;

  if (type === "submit") {
    return submitReduxModuleFactory(element);
  }
  if (type === "reset") {
    return resetReduxModuleFactory(element);
  }

  throw new Error(`Form button type "${type}" not supported`);
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
