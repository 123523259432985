import { useCallback, useMemo } from "react";
import { useTranslator } from "core/session/translation";
import { Align } from "staticPages/admin/common/components/Table";
import { QueryObject } from "staticPages/admin/pages/modelBuilder/components/types.ts";
import { fuseFn } from "staticPages/admin/pages/modelBuilder/components/utils.tsx";
import { useQueryGroupsList } from "utils/hooks";
import {
  isFullyContainedArray,
  isPartiallyContainedArray,
} from "utils/other.ts";
import { useViewsTableTranslation } from "../translation.ts";
import { TViewsTableContent } from "../types.ts";
import { tableHeaderAlignment, tableHeaders } from "./consts.ts";

export const generateTableConfig = (
  translation: ReturnType<typeof useViewsTableTranslation>,
  isSubtable: boolean | undefined = false,
) => {
  const filteredHeaders = isSubtable
    ? tableHeaders.filter((h) => h !== "expand")
    : tableHeaders;

  return filteredHeaders.map((header) => ({
    name: header,
    title: translation?.[`${header}Label`] ?? "",
    align: (tableHeaderAlignment?.[header] ?? "center") as Align,
    padding: header !== "title" ? "checkbox" : undefined,
  }));
};

export const useViewsTableContent = ({
  selectedQueries,
  queryGroups,
  queries,
  searchValue,
  selectCallback,
}: TViewsTableContent) => {
  const { translate } = useTranslator();

  const translation = useViewsTableTranslation();

  const tableConfig = useMemo(
    () => generateTableConfig(translation, true),
    [translation],
  );

  const handleSelectQuery = useCallback(
    (id: string | string[]) => () => {
      const idArray = Array.isArray(id) ? id : [id];

      const newQueries = isPartiallyContainedArray(selectedQueries, idArray)
        ? selectedQueries.filter((a) => !idArray.includes(a))
        : [...selectedQueries, ...idArray];

      selectCallback(newQueries);
    },
    [selectCallback, selectedQueries],
  );

  const searchedQueries = useMemo(() => {
    const searchData = queries.map((view) => {
      const group = queryGroups.find((qg) =>
        qg.queries.includes(Number(view.id)),
      );

      return {
        ...view,
        key: view.id,
        allLang: {
          queryName: translate(view.i18n).title ?? "",
          queryGroup: group ? translate(group.i18n).label ?? "" : null,
        },
      };
    });

    return fuseFn(searchData, [
      { name: `allLang.queryName`, weight: 1 },
      { name: `allLang.queryGroup`, weight: 2 },
    ])
      .search(`'${searchValue}`)
      .map((queryObj) => queryObj.item) as QueryObject[];
  }, [queries, queryGroups, searchValue, translate]);

  const filteredQueries = useMemo(
    () => (searchValue.trim() ? searchedQueries : queries),
    [queries, searchValue, searchedQueries],
  );

  const { translatedQueryGroups, mappedQueries } =
    useQueryGroupsList<QueryObject>({
      queries: filteredQueries,
      queryGroups,
      queryType: null,
      showEmptyQueryGroups: false,
    });

  const checkboxChecked =
    !!filteredQueries.length &&
    isFullyContainedArray(selectedQueries, filteredQueries, "id");

  const idsArray = useMemo(
    () =>
      searchValue.trim()
        ? filteredQueries.map(({ id }) => id)
        : queries.map(({ id }) => id),
    [filteredQueries, queries, searchValue],
  );

  const handleSelectionChange = useCallback(
    (_: unknown, checked: boolean) => {
      selectCallback(checked ? idsArray : []);
    },
    [idsArray, selectCallback],
  );

  return {
    mappedQueries,
    translatedQueryGroups,
    tableConfig,
    filteredQueries,
    checkboxChecked,
    handleSelectQuery,
    handleSelectionChange,
  };
};
