import { memo, useCallback } from "react";
import {
  Section,
  useElementEditorContext,
  useObjectViewList,
} from "core/editor";
import IconButton from "elementTypes/common/IconButton";
import { JsonView } from "elementTypes/common/JsonView";

import { UntransformedFormConfig } from "../../types";
import { useEditorFormTranslation } from "../translation";

export const DefaultData = memo(() => {
  const {
    elementModel: {
      config: {
        dataSource: { viewName },
        defaultData,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedFormConfig>();
  const {
    defaultDataTitle,
    createDefaultData,
    removeDefaultData,
    defaultDataLabel,
  } = useEditorFormTranslation();

  const { getViewByName } = useObjectViewList();
  const currentView = getViewByName(viewName);
  const viewFields = currentView?.fields;

  const changeDefaultDataValue = useCallback(
    (newValidationExpression: UntransformedFormConfig["defaultData"]) => {
      changeConfigValue(
        "defaultData",
        newValidationExpression === null ? undefined : newValidationExpression,
      );
    },
    [changeConfigValue],
  );

  const deleteDefaultData = () => changeDefaultDataValue(undefined);
  const addDefaultData = () => {
    const defaultDataValue = viewFields?.reduce(
      (acc, curr) => ({
        ...(acc ?? {}),
        [curr.name]: undefined,
      }),
      {} as UntransformedFormConfig["defaultData"],
    );

    changeDefaultDataValue(defaultDataValue);
  };

  const data =
    viewFields?.reduce(
      (acc, curr) => ({
        ...(acc ?? {}),
        [curr.name]: defaultData?.[curr.name] ?? undefined,
      }),
      {} as UntransformedFormConfig["defaultData"],
    ) ?? defaultData;

  return (
    <Section
      wrapped={Boolean(defaultData)}
      title={defaultDataTitle}
      headerAction={
        <IconButton
          icon={defaultData ? "delete_outline" : "add"}
          onClick={defaultData ? deleteDefaultData : addDefaultData}
          tooltip={defaultData ? removeDefaultData : createDefaultData}
        />
      }
    >
      {defaultData && (
        <JsonView
          value={data as Record<string, unknown>}
          onValueChange={changeDefaultDataValue}
          label={defaultDataLabel}
          onAdd={false}
          onDelete={false}
        />
      )}
    </Section>
  );
});
