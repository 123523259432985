import { memo } from "react";

import { TElementWithPosition } from "core";
import { MarkdownInput } from "elementTypes/common/MarkdownInput";
import { DefaultMarkdownInputProps } from "./types";

const DefaultMarkdownInput = memo<DefaultMarkdownInputProps>(
  ({
    value,
    element: {
      i18n: { label },
    },
    element,
    required,
    changeValue,
    disabled,
  }) => {
    const positionHeight =
      "position" in element
        ? (element as unknown as TElementWithPosition).position.height
        : null;

    return (
      <MarkdownInput
        {...{
          label,
          value,
          disabled,
          positionHeight,
          required,
          onChange: changeValue,
        }}
      />
    );
  },
);

export default DefaultMarkdownInput;
