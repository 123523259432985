import { lazy, memo, useCallback, useMemo, useState } from "react";
import { dissoc } from "ramda";
import { FixedSizeList, areEqual } from "react-window";
import { IFixedRow, Translation } from "core";
import {
  Section,
  useEditorTranslation,
  useElementEditorContext,
  useObjectViewList,
} from "core/editor";
import { useSessionContext } from "core/session";
import { getTranslatedText } from "core/utils/element-utils";

import Button from "../../../common/Button";
import IconButton from "../../../common/IconButton";
import { withLazyLoading } from "../../../helpers/HOC/LazyLoading";
import { UntransformedFormConfig } from "../../types";

import { ReferenceItem } from "./ReferenceItem";

const initialReference = {
  targetView: "",
  identifierFieldName: "",
  referencingFieldName: "",
};

export type Reference = typeof initialReference & { isNew?: boolean };

type IRow = IFixedRow<Reference>;

const DialogWrapper = withLazyLoading(
  lazy(() => import("../../../helpers/HOC/DialogWrapper")),
);
const ReferenceDialogContent = withLazyLoading(
  lazy(() => import("./ReferenceDialogContent")),
);

export const MultiReference = memo(() => {
  const {
    elementModel: {
      config: { dataSource },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedFormConfig>();
  const { cancelButton, createButton, updateButton, deleteButton } =
    useEditorTranslation();
  const { language } = useSessionContext();
  const { multiReference = {}, viewName } = dataSource;

  const [reference, setReference] = useState<Reference | null>(null);
  const handleClose = () => setReference(null);
  const { getViewByName, viewList } = useObjectViewList();

  const changeMultiReference = useCallback(
    (
      newMultiReference: UntransformedFormConfig["dataSource"]["multiReference"],
    ) =>
      changeConfigValue("dataSource", {
        ...dataSource,
        multiReference: newMultiReference,
      }),
    [changeConfigValue, dataSource],
  );

  const onSubmit = (data: Record<string, any>) => {
    changeMultiReference({
      ...(reference?.targetView === data.targetView
        ? { ...multiReference }
        : { ...dissoc(reference?.targetView ?? "", multiReference) }),
      [data.targetView]: {
        viewName: data.targetView,
        identifierFieldName: data.identifierFieldName,
        referencingFieldName: data.referencingFieldName,
      },
    });

    handleClose();
  };

  const handleOpenDialog = () =>
    setReference({ ...initialReference, isNew: true });

  const onItemClick = (refDetails: Reference) => setReference(refDetails);

  const handleReferenceDelete = () =>
    reference && onItemDelete(reference.targetView);

  const onItemDelete = (targetView: string) => {
    const nextMultiReference = dissoc(
      targetView,
      multiReference,
    ) as UntransformedFormConfig["dataSource"]["multiReference"];
    changeMultiReference(
      nextMultiReference && Object.keys(nextMultiReference).length
        ? nextMultiReference
        : undefined,
    );
    reference && handleClose();
  };

  const translateTitle = (i18n: Translation<"title">) =>
    getTranslatedText(language, i18n, "title");

  const items = useMemo(
    () =>
      Object.values(multiReference).map(
        ({ viewName: targetView, ...rest }) => ({
          targetView,
          ...rest,
        }),
      ),
    [multiReference],
  );

  const Row = memo<IRow>(({ data, index, style }) => {
    const item = data[index];

    return (
      <ReferenceItem
        {...item}
        style={style}
        onClick={onItemClick}
        onDelete={onItemDelete}
      />
    );
  }, areEqual);

  const itemSize = 65;

  return (
    <Section
      title={"References"}
      wrapped={false}
      headerAction={
        <IconButton
          id="editor-form-reference-add"
          icon="add"
          tooltip="Add Reference"
          onClick={handleOpenDialog}
        />
      }
    >
      {!!items.length && (
        <FixedSizeList
          height={itemSize * Math.min(items.length, 10)}
          itemCount={items.length}
          itemSize={itemSize}
          width="100%"
          itemData={items}
        >
          {Row}
        </FixedSizeList>
      )}

      <DialogWrapper
        isForm={true}
        keepMounted={false}
        open={Boolean(reference)}
        title={reference?.isNew ? "Create" : "Edit"}
        submitTitle={reference?.isNew ? createButton : updateButton}
        cancelTitle={cancelButton}
        handleClose={handleClose}
        handleSubmit={onSubmit}
        submitDisabled={true}
        fullWidth
        subActions={
          <>
            {!reference?.isNew && (
              <Button label={deleteButton} onClick={handleReferenceDelete} />
            )}
          </>
        }
      >
        {reference && (
          <ReferenceDialogContent
            {...reference}
            getView={getViewByName}
            viewList={viewList?.filter((view) => view.name !== viewName) ?? []}
            translateTitle={translateTitle}
          />
        )}
      </DialogWrapper>
    </Section>
  );
});
