import { memo } from "react";
import { Section, useElementEditorContext } from "core/editor";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import { IAutocompleteValue } from "elementTypes/common/Autocomplete/types";
import { UntransformedBarChartConfig } from "elementTypes/echarts_bar_chart/types";
import { useEchartsTranslation } from "../translation";

const items = [5, 10, 20, 30, 50, 100];

export const Limit = memo(() => {
  const {
    elementModel: {
      config: { limit },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedBarChartConfig>();

  const t = useEchartsTranslation();

  const handleChange = (nextValue: IAutocompleteValue) => {
    const newLimit = Number(nextValue);
    changeConfigValue("limit", newLimit > 0 ? newLimit : undefined);
  };

  const dataLimitOptions = items.map((item) => ({
    value: item,
    label: `${item} ${t.limitItemLabel}`,
  }));

  return (
    <Section title={t.limitTitle} wrapped={true} defaultOpened={false}>
      <Autocomplete
        value={limit ?? ""}
        options={dataLimitOptions}
        onChange={handleChange}
        label={t.limitLabel}
      />
    </Section>
  );
});
