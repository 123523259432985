import { ChangeEvent, memo, useCallback, useMemo } from "react";
import { Box, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import {
  buildCustomExpressionValue,
  getExpression,
  isCustomExpression,
} from "core";
import {
  Section,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { ContentTypeAutocomplete } from "core/editor/common/ContentTypeAutocomplete";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import { FUNCTIONS_URL } from "core/postgREST";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import ActionConfigEditor from "elementTypes/common/ActionConfig";
import {
  ActionConfigType,
  ActionConfigsType,
} from "elementTypes/common/ActionConfig/types";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import { IAutocompleteValue } from "elementTypes/common/Autocomplete/types";
import { JsonView } from "elementTypes/common/JsonView";
import { uniqueFunctionName } from "elementTypes/default_call_button/utils";
import { useFunctionOptions } from "queries/admin";

import { BuiltinContentType } from "services/api/types/ContentType";
import { UntransformedCallButtonConfig } from "../../types";
import { useCallButtonEditorTranslation } from "../translation";

type ConfigType = "callArgs" | "callName";

export const ConfigComponent = memo(() => {
  const {
    elementModel: {
      config,
      config: {
        callName,
        callArgs,
        argumentDataTypes,
        onSuccess,
        contentTypeFormat,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCallButtonConfig>();

  // We add this logic in order to be able to parse the argument names using callArgs
  // for older UI definitions, and then display the argument editor properly.
  const argsNames = useMemo(
    () =>
      !argumentDataTypes
        ? Object.keys(
            callArgs && isCustomExpression(callArgs) ? {} : callArgs || {},
          )
        : Object.keys(argumentDataTypes ?? {}),

    [argumentDataTypes],
  );

  // The arguments taken from callArgs will be treated as string:
  const argumentsTypes =
    argumentDataTypes ??
    argsNames.reduce(
      (res, argName) => ({ ...res, [argName]: "string" }),
      {} as Record<string, string>,
    );

  const uniqueCallName = uniqueFunctionName(callName, argsNames);
  const { configTitle } = useEditorTranslation();
  const translation = useCallButtonEditorTranslation();

  const appUser = useSelector(sessionSelectors.ui);
  const role = appUser?.role ?? "";

  const { data, isInitialLoading } = useFunctionOptions(role);

  const handleExpressionValueChange = useCallback(
    (configType: ConfigType) => (newValue: string | object) => {
      let configName = newValue;
      if (configType === "callName") {
        const option = data?.find((func) => func?.value === newValue);
        const argNamesOfType = Object.keys(option?.argumentDataTypes ?? {});
        if (option) {
          const name = option.label;
          configName = name;
          changeConfigValue(
            "argumentDataTypes",
            option.argumentDataTypes ?? {},
          );
          changeConfigValue(
            "callArgs",
            argNamesOfType?.length
              ? argNamesOfType.reduce(
                  (res, argName) => ({ ...res, [argName]: "" }),
                  {} as Record<string, unknown>,
                )
              : {},
          );
        }
      }
      changeConfigValue(configType, configName);
    },
    [changeConfigValue, data],
  );

  const handleChange = useCallback(
    (...params: Parameters<typeof changeConfigValue>) =>
      changeConfigValue(...params),
    [changeConfigValue],
  );

  const handleContentTypeHeaderChange = (value: string) =>
    handleChange("contentTypeFormat", value);

  const customAutocomplete = ({
    onChange: onTextChange,
  }: NonExpressionEditorProps) => (
    <Autocomplete
      options={data}
      onChange={
        onTextChange as (
          value: IAutocompleteValue,
          reason?: string | undefined,
        ) => void
      }
      label={translation[`${callName}InputLabel`]}
      isLoading={isInitialLoading}
      value={uniqueCallName}
    />
  );

  const args = data?.find((func) => func?.value === uniqueCallName);
  const argNames = Object.keys(args?.argumentDataTypes ?? {});

  const customArgEditor = (argsProps: NonExpressionEditorProps) => {
    const argsValue = (callArgs ?? {}) as Record<string, unknown>;

    const handleArgChange = (ev: ChangeEvent<HTMLInputElement>) => {
      argsProps.onChange({
        ...argsValue,
        [ev.target.name]: ev.target.value,
      } as any);
    };

    const handleArgJsonChange = (name: string) => (value: any) =>
      argsProps.onChange({
        ...argsValue,
        [name]: value,
      } as any);

    return argNames.map((arg, index) => (
      <Box key={arg ?? index} pb={1}>
        {["json", "jsonb"].includes((argumentsTypes ?? {})[arg] ?? "") ? (
          <JsonView
            name={arg}
            label={arg}
            value={((argsValue ?? {})[arg] ?? {}) as Record<string, unknown>}
            onValueChange={handleArgJsonChange(arg)}
          />
        ) : (
          <TextField
            label={arg}
            name={arg}
            value={argsValue?.[arg] ?? ""}
            onChange={handleArgChange}
            fullWidth
          />
        )}
      </Box>
    ));
  };

  const handleToggleArgsMode = (isExpression: boolean) => {
    const nextVal =
      isExpression && typeof callArgs === "string"
        ? JSON.parse(getExpression(callArgs))
        : buildCustomExpressionValue(JSON.stringify(callArgs, null, 2));

    handleExpressionValueChange("callArgs")(nextVal);
  };

  const handleToggleNameMode = (isExpression: boolean) => {
    const nextVal = isExpression
      ? ""
      : buildCustomExpressionValue(`"${callName}"`);

    handleExpressionValueChange("callName")(nextVal);
  };

  const handleOnSuccessChange = (newValue: ActionConfigType[]) =>
    changeConfigValue("onSuccess", newValue);

  return (
    <Section title={configTitle} wrapped={true} gap={1}>
      <CustomExpressionEditor
        label={translation.callNameLabel}
        value={callName}
        config={config}
        onChange={handleExpressionValueChange("callName")}
        nonExpressionEditor={customAutocomplete}
        onToggleMode={handleToggleNameMode}
      />
      {(argNames.length || isCustomExpression(callName)) && (
        <CustomExpressionEditor
          label={translation.callArgsLabel}
          value={
            isCustomExpression(callArgs)
              ? callArgs
              : isCustomExpression(callName)
                ? buildCustomExpressionValue(JSON.stringify(callArgs, null, 2))
                : JSON.stringify(callArgs, null, 2)
          }
          config={config}
          onChange={handleExpressionValueChange("callArgs")}
          disableSwitcher={!callName || isCustomExpression(callName)}
          nonExpressionEditor={customArgEditor as any}
          onToggleMode={handleToggleArgsMode}
        />
      )}
      <ContentTypeAutocomplete
        value={contentTypeFormat as BuiltinContentType}
        onChange={handleContentTypeHeaderChange}
        href={FUNCTIONS_URL}
        label="Request Headers"
      />
      <ActionConfigEditor
        onActionConfigsChange={handleOnSuccessChange}
        actionConfigs={onSuccess as ActionConfigsType}
        elementConfig={config}
      />
    </Section>
  );
});
