import { memo } from "react";
import { ConnectedReduxModuleProps } from "core";
import { AlertBox } from "elementTypes/common/AlertBox";
import { LoadingComponent } from "layouts/common/Loading";

import { IOptionEchartProps } from "../common/Echarts";
import { IEchartsContainerSingleProps } from "../common/Echarts";
import EchartsContainerOneSerie from "../common/Echarts/EchartsContainerOneSerie";
import { useEchartsTranslation } from "../common/Echarts/translation.ts";

import { ReduxModule } from "./reduxModule";
import { PieChart } from "./types";

type Props = ConnectedReduxModuleProps<ReduxModule, PieChart>;

const EchartsPieChart = memo<Props>(
  ({
    element: {
      id,
      config: { hideLabel, isDonut, dataSource, legend, noDataStyles, ...rest },
      i18n,
    },
    error,
    data,
    selected,
    selectValue,
  }) => {
    const { title } = i18n;
    const propsEchartsContainer: Omit<
      IEchartsContainerSingleProps,
      "data" | "dataSource" | "elementId"
    > = {
      type: "pie",
      title,
      defaultOptions: {
        title: {
          x: "center",
        },
        tooltip: {
          trigger: "item",
          // TODO: set translated tooltip
          // formatter: "{b}: {c} ({d}%)",
        },
        series: [
          {
            avoidLabelOverlap: true,
            label: {
              show: !hideLabel,
            },
            radius: ["0%", "70%"],
            ...(Boolean(isDonut) && {
              // have same max radius similar to the pie chart
              radius: ["50%", "70%"],
            }),
            emptyCircleStyle: {
              // change the style if no data is available
              color: "#ddd",
              borderColor: "#ddd",
              ...(noDataStyles ?? {}),
              borderWidth: 1,
            },
          },
        ],
      } as IOptionEchartProps,
      ...rest,
    };

    const { configErrorTitle, errorTitle, queryNameError } =
      useEchartsTranslation();

    if (!dataSource.viewName) {
      return <AlertBox title={configErrorTitle} message={queryNameError} />;
    }

    if (error) {
      return <AlertBox title={errorTitle} message={error} />;
    }

    if (!data) {
      return <LoadingComponent />;
    }

    const chartProps = {
      elementId: id,
      dataSource,
      data,
      legend,
      selected,
      selectValue,
      i18n,
    };

    return (
      <EchartsContainerOneSerie {...propsEchartsContainer} {...chartProps} />
    );
  },
);

export default EchartsPieChart;
