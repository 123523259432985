import { SyntheticEvent, memo, useMemo } from "react";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import { Element } from "core";

import { useStyles } from "./style";
import { TabPanelProps, TabProps } from "./types";
import { getTabTranslationName } from "./utils";

/*
  --- CN-1444 ---

  Due to the labels not supporting translation, we decided to move the labels completely to
  the translation object, and sort-of migrate the existing labels to the translation object.
*/

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Paper square={true} variant="outlined" className={other.className}>
          {children}
        </Paper>
      )}
    </div>
  );
}

const DefaultTabs = memo<TabProps>(
  ({ select, indexSelected, element: { config, children, i18n } }) => {
    const {
      classes: {
        fullSize,
        tabsClass,
        tabRoot,
        tabButtonContainer,
        tabContentContainer,
      },
      cx,
    } = useStyles();

    const onChange = (_event: SyntheticEvent<Element, Event>, value: number) =>
      select(value);

    const tabsProps = {
      ...(config.variant === "standard" && { centered: true }),
      ...(config.variant === "scrollable" && {
        scrollButtons: "auto" as "auto" | boolean | undefined,
      }),
    };

    const panels = useMemo(
      () =>
        children.content.elements.map((element, i) => (
          <TabPanel
            value={indexSelected}
            index={i}
            key={i}
            className={cx(fullSize, tabContentContainer)}
          >
            <Element key={element.id} element={element} />
          </TabPanel>
        )),
      [
        children.content.elements,
        indexSelected,
        cx,
        fullSize,
        tabContentContainer,
      ],
    );

    const labels = useMemo(
      () =>
        panels.map((_, index) => (
          <Tab
            label={
              i18n[getTabTranslationName(index)] || config.labels?.[index] || ""
            }
            key={index}
            data-testid="test-tab"
          />
        )),
      [panels, i18n, config.labels],
    );

    return (
      <div className={cx(tabRoot, fullSize)}>
        <Paper className={tabButtonContainer} square={true} variant="outlined">
          <MuiTabs
            value={indexSelected}
            onChange={onChange}
            indicatorColor="primary"
            textColor="primary"
            variant={config.variant}
            {...tabsProps}
          >
            {labels}
          </MuiTabs>
        </Paper>
        <div className={cx(tabsClass, fullSize)}>{panels}</div>
      </div>
    );
  },
);

export default DefaultTabs;
