import { ChangeEvent, memo, useCallback } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { buildCustomExpressionValue, getPureExpression } from "core";
import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { ContentTypeAutocomplete } from "core/editor/common/ContentTypeAutocomplete";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";

import { Autocomplete } from "elementTypes/common/Autocomplete";
import { IAutocompleteValue } from "elementTypes/common/Autocomplete/types";
import { useIdentifierData } from "queries/app/identifierData";
import { BuiltinContentType } from "services/api/types/ContentType";
import { UntransformedDataDisplayConfig } from "../types";

import { useEditorDataDisplayTranslation } from "./translation";

enum Modes {
  firstRow = "firstRow",
  allData = "allData",
}

export const DataSourceEditor = memo(() => {
  const {
    elementModel: {
      config,
      config: { dataSource, identifier },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDataDisplayConfig>();

  const changeDataSource = useCallback(
    (newDataSource: UntransformedDataDisplayConfig["dataSource"]) =>
      changeConfigValue("dataSource", newDataSource),
    [changeConfigValue],
  );

  const {
    viewName = "",
    identifierName = "",
    setAllData = false,
    responseFormat = "json",
  } = dataSource;

  const {
    data: autoCompleteOptions,
    isFetching,
    isInitialLoading,
  } = useIdentifierData(
    { viewName },
    { identifierName },
    { enabled: !!identifierName },
  );

  const loading = isFetching || isInitialLoading;

  const changeIdentifier = useCallback(
    (newIdentifier?: string) => {
      changeConfigValue(
        "identifier",
        newIdentifier?.trim().length ? newIdentifier : undefined,
      );
    },
    [changeConfigValue],
  );

  const handleViewNameChange = (newViewName: string) => {
    if (identifier?.length) {
      changeIdentifier(undefined);
    }
    changeDataSource({
      viewName: newViewName,
      identifierName: undefined,
      responseFormat,
    });
  };

  const handleFieldChange = (
    _: string,
    fieldValue: string[] | string | boolean | number | number[] | null,
  ) => {
    identifier?.length && changeIdentifier(undefined);
    changeDataSource({
      ...dataSource,
      identifierName: fieldValue as string,
    });
  };

  const handleSetAllDataChange = (
    _: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => changeDataSource({ ...dataSource, setAllData: value === Modes.allData });

  const handleResponseFormatChange = (newFormat: BuiltinContentType) => {
    changeDataSource({
      ...dataSource,
      responseFormat: newFormat,
    });
  };

  const {
    dataSourceTitle,
    identifierValueLabel,
    identifierNameLabel,
    viewLabel,
  } = useEditorTranslation();
  const translation = useEditorDataDisplayTranslation();

  const fields = [
    {
      label: identifierNameLabel,
      value: identifierName,
      name: "identifierName",
      isClearable: true,
    },
  ];

  const customIdentifierInput = ({
    value: identifierValue,
    onChange: onIdentifierChange,
  }: {
    value: string;
    onChange: (value: string) => void;
  }) => {
    const onCustomChange = (val: IAutocompleteValue) => {
      onIdentifierChange(val ? String(val) : "");
    };

    return (
      <Autocomplete
        options={autoCompleteOptions}
        disabled={!identifierName}
        value={identifierValue}
        onChange={onCustomChange}
        virtualizedList={true}
        isLoading={loading}
      />
    );
  };

  const modes = Object.values(Modes).map((mode) => (
    <FormControlLabel
      key={mode}
      control={<Radio color="primary" />}
      label={translation[`${mode}Label`]}
      value={mode}
    />
  ));

  const handleToggleMode = (isExpression: boolean) => {
    const nextVal = isExpression
      ? getPureExpression(identifier! ?? "")
      : buildCustomExpressionValue(identifier ? `"${identifier}"` : "");
    changeIdentifier(nextVal);
  };

  return (
    <Section title={dataSourceTitle} wrapped={true}>
      <ViewAutocomplete
        viewValue={viewName}
        viewLabel={viewLabel}
        onViewNameChange={handleViewNameChange}
        onViewFieldChange={handleFieldChange}
        fields={fields}
      />
      <CustomExpressionEditor
        value={identifier ?? ""}
        config={config}
        onChange={changeIdentifier}
        label={identifierValueLabel}
        nonExpressionEditor={customIdentifierInput}
        switcherDisabled={!identifierName}
        onToggleMode={handleToggleMode}
      />
      <FormControl fullWidth={true}>
        <ContentTypeAutocomplete
          value={responseFormat as BuiltinContentType}
          onChange={handleResponseFormatChange}
        />
      </FormControl>
      <FormControl fullWidth={true}>
        <FormLabel component="p">{translation.modeTitle}</FormLabel>
        <RadioGroup
          row={true}
          value={setAllData ? Modes.allData : Modes.firstRow}
          onChange={handleSetAllDataChange}
        >
          {modes}
        </RadioGroup>
      </FormControl>
    </Section>
  );
});
