export const NotificationLevelIcons = {
  info: "info",
  error: "error",
  warning: "warning",
  success: "check_circle",
} as const;

export type NotificationLevel = keyof typeof NotificationLevelIcons;

export type Notification = {
  id: number;
  level: NotificationLevel;
  message: string;
  createdAt: Date;
  readAt: null | Date;
  createdBy: null | { role: string };
};
