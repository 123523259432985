import {
  MouseEvent,
  lazy,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Typography } from "@mui/material";
import { omit } from "ramda";
import { useSelector } from "react-redux";
import { IElementModel, IUi, buildCustomExpressionValue } from "core";
import {
  IField,
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
  useObjectViewList,
} from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { selectors as editorSelectors } from "core/editor/reduxModule";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { useGenerateUiElement } from "queries/app/generateElement";
import { usePage, useSession } from "utils/hooks";
import { useElement } from "utils/hooks/useElement";
import { withLazyLoading } from "../../../helpers/HOC/LazyLoading";
import { FormTypes, UntransformedFormConfig } from "../../types";
import { useEditorFormTranslation } from "../translation";

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

export const DataSource = memo(() => {
  const {
    elementModel,
    elementModel: {
      config,
      config: { dataSource, type },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedFormConfig>();

  const selected = useSelector(editorSelectors.selected);

  const {
    viewName = "",
    identifierName = "",
    identifierValue = "",
    stateFieldName = "",
    multiReference,
  } = dataSource;

  const { getViewByName } = useObjectViewList();
  const hasIdentifier = useMemo(() => type !== "create", [type]);

  const {
    dataSourceTitle,
    identifierNameLabel,
    viewLabel,
    identifierValueLabel,
    cancelButton,
  } = useEditorTranslation();

  const {
    stateFieldNameLabel,
    generateFieldsLabel,
    generateButtonLabel,
    generateFieldsMessage,
    changeViewButton,
    changeViewConfirmation,
    changeViewButtonNoColumnRefresh,
  } = useEditorFormTranslation();

  const { updateElement } = useElement();

  const page = usePage();
  const ui = useSession("ui") as IUi;

  const [state, setState] = useState<{
    viewName: string;
    anchorEl: HTMLDivElement | HTMLButtonElement | null;
    regenerate?: boolean;
  } | null>(null);

  const handlePopupClose = useCallback(() => {
    setState(null);
  }, []);

  const sectionRef = useRef<HTMLDivElement | null>(null);

  const getDefaultIdentifier = useCallback(
    (newViewName: string) => {
      const view = getViewByName(newViewName);
      return view?.identifyingField?.name ?? view?.fields[0]?.name;
    },
    [getViewByName],
  );

  const changeDataSource = useCallback(
    (newDataSource: UntransformedFormConfig["dataSource"]) =>
      changeConfigValue("dataSource", newDataSource),
    [changeConfigValue],
  );

  const generateElement = useGenerateUiElement({
    onSuccess: (data) => {
      const updtedElement = {
        ...elementModel,
        children: data.children,
        config: {
          ...elementModel.config,
          jsonSchema: undefined,
          validation: undefined,
          ...data.config,
        },
      } as IElementModel;

      updateElement(
        {
          [data.id]: updtedElement,
        },
        { ...selected!, element: updtedElement },
        page!,
      );
    },
  });

  const getNextDataSource = useCallback(
    (nextView: string) => ({
      viewName: nextView,
      identifierName: hasIdentifier
        ? getDefaultIdentifier(nextView)
        : undefined,
      identifierValue: hasIdentifier
        ? buildCustomExpressionValue(null)
        : undefined,
    }),
    [getDefaultIdentifier, hasIdentifier],
  );

  const handleGenerateFields = useCallback(
    (newViewName: string) => {
      if (newViewName && ui) {
        const nextDataSource = getNextDataSource(newViewName);

        const prevElement = {
          ...(omit(["children"], elementModel) as IElementModel),
          children: {},
          config: {
            ...(elementModel?.config ?? {}),
            dataSource: nextDataSource,
          },
        };
        generateElement.mutate({
          uiName: (ui as IUi).name,
          elementName: prevElement.name,
          element: prevElement,
        });
      }
    },
    [getNextDataSource, ui, elementModel, generateElement],
  );

  const onViewNameChange = useCallback(
    (newViewName: string) => {
      const nextDataSource = getNextDataSource(newViewName);
      changeDataSource(nextDataSource);

      if (state?.anchorEl) {
        handlePopupClose();
      }
    },
    [changeDataSource, getNextDataSource, handlePopupClose, state?.anchorEl],
  );

  const handleFieldChange = (
    fieldName: string,
    fieldValue: string[] | string | boolean | number | number[] | null,
  ) => {
    // reset multiReference if identifier is unset
    const multiReferenceConfig =
      fieldName === "identifier" && !fieldValue ? undefined : multiReference;
    changeDataSource({
      ...dataSource,
      [fieldName]: (fieldValue ?? "") as string,
      multiReference: multiReferenceConfig,
    });
  };
  const handleIdentifierValueChange = (newValue?: string) =>
    changeDataSource({
      ...dataSource,
      identifierValue: newValue,
    });

  const setDefaultIdentifierName = useCallback(() => {
    changeDataSource({
      ...dataSource,
      viewName,
      identifierName: getDefaultIdentifier(viewName),
      identifierValue: buildCustomExpressionValue(null),
    });
  }, [changeDataSource, dataSource, getDefaultIdentifier, viewName]);

  const handleViewNameChange = useCallback(
    (newViewName: string) => {
      if (!viewName.length) {
        onViewNameChange(newViewName);
        handleGenerateFields(newViewName);
      } else {
        setState({
          viewName: newViewName,
          anchorEl: sectionRef.current,
        });
      }
    },
    [handleGenerateFields, onViewNameChange, viewName],
  );

  const handleOnGenerateClick = (e: MouseEvent<HTMLButtonElement>) =>
    setState({ viewName: "", anchorEl: e.currentTarget, regenerate: true });

  const handleClick = (shouldGenerateFields: boolean) => () => {
    if (state?.viewName) {
      onViewNameChange(state.viewName);

      if (shouldGenerateFields) {
        handleGenerateFields(state.viewName);
      }
    }
  };

  const handleGenerateButton = () => {
    handleGenerateFields(viewName);
    handlePopupClose();
  };

  useEffect(() => {
    if (hasIdentifier && !identifierName?.length && !!viewName.length) {
      setDefaultIdentifierName();
    }
  }, [
    hasIdentifier,
    type,
    identifierName,
    viewName.length,
    setDefaultIdentifierName,
  ]);

  const fields: IField[] = [
    {
      label: identifierNameLabel,
      value: identifierName ?? "",
      name: "identifierName",
      isClearable: type === FormTypes.create,
    },
  ];

  if (type === FormTypes.create) {
    fields.push({
      label: stateFieldNameLabel,
      value: stateFieldName ?? "",
      name: "stateFieldName",
      isClearable: true,
    });
  }

  return (
    <>
      <Section
        title={dataSourceTitle}
        wrapped={true}
        innerRef={sectionRef}
        headerAction={
          <IconButton
            icon="filter_none"
            color="primary"
            tooltip={generateFieldsLabel}
            onClick={handleOnGenerateClick}
            disabled={!viewName}
          />
        }
      >
        <ViewAutocomplete
          viewValue={viewName ?? ""}
          viewLabel={viewLabel ?? ""}
          onViewNameChange={handleViewNameChange}
          onViewFieldChange={handleFieldChange}
          fields={fields}
          isClearable={false}
        />
        {/* Disable for "create" type. Issue #747 */}
        {hasIdentifier && (
          <CustomExpressionEditor
            label={identifierValueLabel}
            value={identifierValue ?? ""}
            config={config}
            onChange={handleIdentifierValueChange}
            disableSwitcher
          />
        )}
        {generateElement?.isError ? (
          <Typography color="error">
            {JSON.stringify(generateElement?.error ?? {})}
          </Typography>
        ) : null}
      </Section>
      <Popover
        anchorEl={state?.anchorEl ?? null}
        onClose={handlePopupClose}
        actionsAlign="center"
        actions={
          state?.regenerate ? (
            <>
              <Button
                label={generateButtonLabel}
                color="primary"
                onClick={handleGenerateButton}
              />
              <Button
                label={cancelButton}
                onClick={handlePopupClose}
                color="secondary"
              />
            </>
          ) : (
            <>
              <Button
                label={changeViewButton}
                color="secondary"
                onClick={handleClick(true)}
              />
              <Button
                label={changeViewButtonNoColumnRefresh}
                onClick={handleClick(false)}
              />
              <Button label={cancelButton} onClick={handlePopupClose} />
            </>
          )
        }
      >
        <Typography variant="body1">
          {state?.regenerate ? generateFieldsMessage : changeViewConfirmation}
        </Typography>
      </Popover>
    </>
  );
});
