import { memo, useMemo } from "react";
import { Clear, Done } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTranslator } from "core/session/translation";
import { useFieldsTableTranslation } from "../translation.ts";
import { TFieldsTable, TFieldsTableRow } from "../types.ts";

export const FieldsTable = memo<TFieldsTable>(({ fields }) => {
  const { nameLabel, typeLabel, isArrayLabel, isNullableLabel } =
    useFieldsTableTranslation();
  const { translate } = useTranslator();

  const columnDefinition: GridColDef[] = [
    {
      field: "name",
      headerName: nameLabel,
      flex: 0.5,
      disableColumnMenu: true,
    },
    {
      field: "generalType",
      headerName: typeLabel,
      flex: 0.5,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Tooltip title={row.type} placement="left-start">
          <Typography>{row.generalType}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "isNullable",
      headerName: isNullableLabel,
      flex: 0.25,
      disableColumnMenu: true,
      renderCell: ({ row }) =>
        row.isNullable ? <Done color="primary" /> : <Clear color="secondary" />,
    },
    {
      field: "isArray",
      headerName: isArrayLabel,
      flex: 0.25,
      disableColumnMenu: true,
      renderCell: ({ row }) =>
        row.isArray ? <Done color="primary" /> : <Clear color="secondary" />,
    },
  ];

  const rows = useMemo<TFieldsTableRow[]>(
    () =>
      fields.map((field, key) => ({
        id: key,
        name: translate(field.i18n)?.title ?? field.name,
        type: field.type,
        generalType: field.generalType.type,
        isNullable: field.nullable,
        isArray: field.generalType.isArray,
      })),
    [fields, translate],
  );

  return (
    <DataGrid
      autoHeight
      columns={columnDefinition}
      rows={rows}
      disableSelectionOnClick
      hideFooterPagination
    />
  );
});
