import { createUseTranslation } from "core/session/translation";

export const autocompleteEditorTranslation = {
  en: {
    stylingTitle: "Value Styling",
    advancedTitle: "Advanced",
    labelLabel: "Label",
    sizeInputLabel: "Chip Size",
    variantInputLabel: "Variant",
    chipVariantInputLabel: "Chip Variant",
    isMultiLabel: "Multiple selections",
    isClearableLabel: "Can Be Cleared",
    fullTextSearchLabel: "Full Text Search",
    autoSuggestHighlightLabel: "Autosuggest Highlight",
    optionsListSorted: "Options List Sorted",
    virtualizedListLabel: "Virtualized List",
    withCheckboxLabel: "With Checkbox",
    allowSameValueLabel: "Allow Same Value",
    referenceLabel: "Query",
    optionsLabel: "Static Options",
    optionsTitle: "Options Source",
    addOptionTitle: "Add an option",
    addOptionTooltip: "Add an option",
    valueLabel: "Value",
    createOptionTitle: "Create",
    editOptionTitle: "Edit",
    defaultLabel: "Default",
    outlinedLabel: "Outlined",
    smallLabel: "Small",
    mediumLabel: "Medium",
    filterTitle: "Filter",
    createFilter: "Create Filter",
    removeFilter: "Remove Filter",
    nullableFlagHelperText: "Only available when the input is nullable",
    isMultiFlagHelperText:
      "Only available when the autocomplete allow multiple selections",
    multiSelectionTitle: "Multi Selection",
    firstOptionSelectedLabel: "Set Default Option",
  },
};

export const useAutocompleteEditorTranslation = createUseTranslation(
  autocompleteEditorTranslation,
);
